import { Input } from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';
import { formatDate } from '../../../utils';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
const mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export function RenderDateInput({
  value,
  placeholder,
  onChange,
  isDisabled,
}: {
  value?: Date;
  placeholder?: string;
  onChange: (date: Date | null) => void;
  isDisabled?: boolean;
}) {
  return (
    <>
      <MaskedInput
        keepCharPositions
        pipe={autoCorrectedDatePipe}
        mask={mask}
        value={formatDate(value?.toUTCString())}
        placeholder={placeholder}
        onChange={(e) => {
          const asDate = new Date(e.target.value);
          //   if the date is invalid it will return "invalid Date"
          const invalid = asDate.toString().includes('Invalid');
          if (invalid) {
            onChange(null);
          } else {
            onChange(asDate);
          }
        }}
        render={(ref, props) => (
          <Input
            ref={ref as any}
            {...props}
            isDisabled={isDisabled}
            width='100%'
            borderRightRadius={0}
            focusBorderColor='brand.500'
          />
        )}
      />
    </>
  );
}
