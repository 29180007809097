import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ProviderUpdateOrderableItemReviewInput } from '../__generated__/graphql';

export const initialSidebarState: ProviderUpdateOrderableItemReviewInput = {
  notes: '',
  internalNotes: '',
  assignedToUserID: 0,
  result: '',
  // init this with undefined so we
  // can check if the update's been applied
  // or not
  highPriority: undefined,
  reviewed: false,
};

export const initialItemState: ProviderUpdateOrderableItemReviewInput = initialSidebarState;

// only the propoerties we want to save changes to should be added to the providerencounterstate
export type UpdatedItems = Record<number, Partial<ProviderUpdateOrderableItemReviewInput>>;

export interface ProviderEncounterState {
  sidebarState: {
    currentValues: ProviderUpdateOrderableItemReviewInput;
    appliedValues: ProviderUpdateOrderableItemReviewInput;
  };
  updatedItems: UpdatedItems;
}

const initialProviderEncounterState: ProviderEncounterState = {
  sidebarState: {
    currentValues: initialSidebarState,
    appliedValues: initialSidebarState,
  },
  updatedItems: {},
};

export const providerEncounterSlice = createSlice({
  name: 'encounterDetailsNotes',
  initialState: initialProviderEncounterState,
  reducers: {
    resetSidebar() {
      return initialProviderEncounterState;
    },
    updateSidebar(state, action: PayloadAction<ProviderUpdateOrderableItemReviewInput>) {
      return {
        ...state,
        sidebarState: {
          appliedValues: state.sidebarState.appliedValues,
          currentValues: action.payload,
        },
      };
    },
    applySidebarChanges(state) {
      return {
        ...state,
        sidebarState: {
          appliedValues: state.sidebarState.currentValues,
          currentValues: state.sidebarState.currentValues,
        },
      };
    },
    updateItem(state, action: PayloadAction<UpdatedItems>) {
      const updatedItems = { ...state.updatedItems };

      Object.entries(action.payload).forEach(([orderableReportID, vals]) => {
        updatedItems[Number(orderableReportID)] = vals;
      });

      return {
        ...state,
        updatedItems,
      };
    },
  },
});

export const providerEncounterActions = providerEncounterSlice.actions;

export default providerEncounterSlice.reducer;
