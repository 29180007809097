import React from 'react';
import { FormControl, FormLabel, Select } from '../../components';
import {
  appointmentsActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../state-management';
import { type AlertId } from '../../types';

interface SelectOrderableItemProps {
  alertId: AlertId;
  // optionsData: OrderableItemOption[];
}

export function SelectOrderableItem({
  alertId,
}: // optionsData,
SelectOrderableItemProps) {
  const dispatch = useAppDispatch();
  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const { orderableItemOptions } = editedOrder.alert;
  const selectedOrderableItemId = editedOrder.selectedOrderableItem?.orderableItemId;

  function handleOrderableItemChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const val: string = e.target.value;
    const selected = orderableItemOptions?.find((d) => d?.orderableItemId === Number(val));

    if (selected?.orderableItemId !== null && selected?.orderableItemName !== null) {
      // for some reason typescript wasn't recognizing that selected is defined, if the above test passes
      const selectedImmunization = {
        orderableItemId: selected?.orderableItemId || -1,
        orderableItemName: selected?.orderableItemName || '',
      };
      dispatch(
        appointmentsActions.changeOrderableItem({
          alertId,
          selectedImmunization,
        }),
      );
    }
  }

  return orderableItemOptions != null ? (
    <FormControl>
      <FormLabel>Immunization Name</FormLabel>
      <Select
        placeholder='Select immunization name'
        value={selectedOrderableItemId}
        onChange={handleOrderableItemChange}
      >
        {orderableItemOptions.map((data) => {
          const val = data?.orderableItemId;
          return (
            <option key={val || ''} value={val || ''}>
              {data?.orderableItemName}
            </option>
          );
        })}
      </Select>
    </FormControl>
  ) : null;
}
