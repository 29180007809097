import { Tab, TabList, Tabs } from '@chakra-ui/react';
import {
  getSelectedTab,
  providerWorkflowActions,
  useAppDispatch,
  useAppSelector,
} from '../../../../../state-management';
import { type EncounterTabOption } from '../../../../../types';

interface Props {
  toBeReviewed: number;
  totalToBeReviewed: number;
  outstandingEncounters: number;
  totalOutstandingEncounters: number;
  openTEs: number;
  reviewed: number;
  onChange?: (tab: string) => void;
  totalReviewed: number;
  totalTEs: number;
}

export function ResultsTabs({
  toBeReviewed,
  outstandingEncounters,
  reviewed,
  openTEs,
  totalTEs,
  totalOutstandingEncounters,
  totalReviewed,
  totalToBeReviewed,
}: Props) {
  const tabs = [
    { label: 'To Be Reviewed', items: toBeReviewed, total: totalToBeReviewed },
    { label: 'Outstanding', items: outstandingEncounters, total: totalOutstandingEncounters },
    { label: 'Reviewed', items: reviewed, total: totalReviewed },
    { label: 'Open TEs', items: openTEs, total: totalTEs },
  ];

  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(getSelectedTab);

  return (
    <Tabs
      width='65%'
      isFitted
      index={tabs.findIndex((tab) => tab.label === selectedTab)}
      colorScheme='brand'
      display='flex'
      flexDirection='column'
      overflow='auto'
      size='sm'>
      <TabList>
        {tabs.map((tab, i) => (
          <Tab
            onClick={() => {
              dispatch(providerWorkflowActions.onTabChange(tab.label as EncounterTabOption));
            }}
            key={i}>
            <span>
              {tab.label} {tab.items > 0 && `\u2022 ${tab.items}`}
            </span>
            {!!tab.total && <span style={{ opacity: 0.75 }}>/{tab.total?.toLocaleString()}</span>}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}
