import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { useAppUI, useSocialHistory, useToast } from '../../../hooks';
import { useEffect } from 'react';
import { uiActions, useAppDispatch } from '../../../state-management';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { SocialHistoryPanel } from './SocialHistoryPanel';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';

export function SocialHistoryContent({ nextStep }: { nextStep: () => void }) {
  const dispatch = useAppDispatch();
  const {
    currentCategoryId,
    isLoadingGetSocialHistoryCategories,
    dataGetSocialHistoryCategories,
    isLoadingUpdateSocialHistory,
    isSuccessUpdateSocialHistory,
    isErrorUpdateSocialHistory,
    handleUpdateSocialHistory,
  } = useSocialHistory();
  const { markCurrentStepComplete } = useAppUI();
  const validSocialHistoryCategories = dataGetSocialHistoryCategories?.filter(
    (c) => c.socialInfoItems.length > 0,
  );
  // Setup toasts
  useToast({
    id: 'isSuccessUpdateSocialHistory',
    title: 'Success',
    description: 'Your submission was successful.',
    show: isSuccessUpdateSocialHistory,
    status: 'success',
  });

  useToast({
    id: 'isErrorUpdateSocialHistory',
    title: 'Error',
    description: 'Failed to submit.',
    show: isErrorUpdateSocialHistory,
    status: 'error',
  });

  useEffect(() => {
    if (isSuccessUpdateSocialHistory) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isSuccessUpdateSocialHistory, markCurrentStepComplete]);

  const currentTabIndex = validSocialHistoryCategories?.findIndex(
    (c) => c.socialHistoryCategoryID === currentCategoryId,
  );

  return (
    <WaitUntilLoaded loading={isLoadingGetSocialHistoryCategories}>
      <MAWorkFlowContentContainer>
        <VStack spacing='lg' alignItems='flex-start'>
          <MAWorkFlowHeader>Social History</MAWorkFlowHeader>
          <Tabs
            colorScheme='brand'
            isLazy
            orientation='vertical'
            w='full'
            index={currentTabIndex}
            onChange={(i) => {
              const newCategoryId = validSocialHistoryCategories?.[i]?.socialHistoryCategoryID;

              if (newCategoryId) {
                dispatch(uiActions.onChangeSocialHistoryCategory(newCategoryId));
              }
            }}>
            <TabList
              // minW="fit-content"
              minW='180px'
              marginRight='lg '
              borderRight='1px solid'
              borderRightColor='gray.100'>
              {validSocialHistoryCategories?.map((c) => {
                const name = c.name;
                return (
                  <Tab key={name} justifyContent='flex-start'>
                    {name}
                  </Tab>
                );
              })}
            </TabList>

            <TabPanels>
              {validSocialHistoryCategories?.map((c) => {
                const id = c.socialHistoryCategoryID;
                return (
                  <TabPanel pt='0' key={id}>
                    <SocialHistoryPanel />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </VStack>
        <SaveAndWorklistButtons
          isLoading={isLoadingUpdateSocialHistory}
          onClick={handleUpdateSocialHistory}>
          Save / Verify Social History
        </SaveAndWorklistButtons>
      </MAWorkFlowContentContainer>
    </WaitUntilLoaded>
  );
}
