import {
  Box,
  Button,
  Drawer,
  Flex,
  Skeleton,
  Tab,
  TabList,
  Tabs,
  useDisclosure,
  Switch,
  Text,
} from '@chakra-ui/react';
import { EncounterHeader } from '../components';
import { Sidebar } from './components/Sidebar';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { EditPanel } from './components/EditPanel';
import { useEffect, useState } from 'react';
import { ResultsContainer } from './components/ResultsContainer';
import { getProviderEncounterState, providerEncounterActions } from '../../../state-management';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks';
import { useProviderEncounterDetails } from '../hooks/useProviderEncounterDetails';
import { useHandleBulkUpdates } from './hooks/useHandleBulkUpdates';

export function EncounterScreen() {
  const { encounterId } = useParams();
  const [onlyAssignedToMe, setOnlyAssignedToMe] = useState(false);
  const { items, loading } = useProviderEncounterDetails({ encounterId });
  const [searchParams] = useSearchParams();
  const patientId = searchParams.get('patientId');

  const { user } = useAuth();

  const tabs = [
    { key: 'Lab', label: 'Labs', count: 0, reviewedCount: 0 },
    { key: 'Diagnostic Imaging', label: 'Imaging', count: 0, reviewedCount: 0 },
    { key: 'Procedure', label: 'Procedures', count: 0, reviewedCount: 0 },
  ];

  const dispatch = useDispatch();

  // reset the notesstate when we load the encounter items
  // there could be residual notes from a previous encounter
  const [isReset, setIsReset] = useState(false);
  useEffect(() => {
    if (isReset) return;
    if (!!items && !loading) {
      setIsReset(true);
      dispatch(providerEncounterActions.resetSidebar());
    }
  }, [items, loading]);

  // change the active tab if the default tab
  // has no items but another item does
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!items?.length) return;
    if (init) return;
    const nonEmptyTab = tabs.findIndex((t) => t.count > 0);
    if (tabs[nonEmptyTab]) {
      setSelectedTab(tabs[nonEmptyTab]);
    }
    setInit(true);
  }, [init, items]);

  // build the tabs counts
  items.forEach(({ orderableItemType, reviewed }) => {
    const k = orderableItemType as (typeof tabs)[0]['key'];
    const i = tabs.findIndex((t) => t.key === k);
    if (i > -1) {
      if (reviewed) {
        tabs[i].reviewedCount += 1;
      } else {
        tabs[i].count += 1;
      }
    }
  });

  const [editingItem, setEditingItem] = useState<(typeof items)[0] | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const nav = useNavigate();
  const notesState = useSelector(getProviderEncounterState);
  const [updating, setUpdating] = useState(false);

  const assignedToMe = (items || []).filter((item) => {
    if (onlyAssignedToMe) {
      const localUpdate = item.orderableReportID
        ? notesState.updatedItems[Number(item.orderableReportID)]
        : null;
      if (localUpdate?.assignedToUserID) {
        return `${localUpdate.assignedToUserID}` === user?.ecwId;
      }
      return item.assignedToUserID === user?.ecwId;
    }
    return item;
  });

  const { handleSave } = useHandleBulkUpdates({
    encounterId,
    setUpdating,
    patientId,
  });

  return (
    <Box padding={5} height='100vh' position='relative' overflow='hidden'>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} isFullHeight size='full'>
        {editingItem && <EditPanel onClose={onClose} item={editingItem} />}
      </Drawer>
      <Box height='100%'>
        <EncounterHeader patientId={patientId ? Number(patientId) : undefined} />
        <Flex height='100%'>
          <Sidebar />
          <Box height='100%' width='100%'>
            <Box zIndex={0} paddingX={4}>
              <Flex marginTop={4} gap={4}>
                <Tabs colorScheme='brand' index={tabs.findIndex((t) => t.key === selectedTab?.key)}>
                  <TabList>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.key}
                        textAlign='left'
                        width={200}
                        onClick={() => {
                          setSelectedTab(tab);
                        }}>
                        {tab.label} &#x2022; {tab.count}{' '}
                        {!!tab.reviewedCount && `(${tab.reviewedCount})`}
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
                <Box>
                  <Button
                    variant='outline'
                    onClick={() => {
                      nav(`/providers/${patientId}/cumulative-report`);
                    }}>
                    Cumulative
                  </Button>
                </Box>
                <Flex alignItems={'center'} justifyContent='end' width='100%'>
                  <Text fontWeight='semibold' whiteSpace='nowrap' fontSize='sm' mr={3}>
                    Only Assigned to me
                  </Text>
                  <Switch
                    checked={onlyAssignedToMe}
                    onChange={() => {
                      setOnlyAssignedToMe(!onlyAssignedToMe);
                    }}
                  />
                </Flex>
              </Flex>
            </Box>
            <Box position='relative' pb={20} height='100%'>
              <Box height='100%' paddingBottom='120px' overflowY='auto'>
                {loading &&
                  Array.from(Array(12)).map((_, index) => {
                    return (
                      <Skeleton key={index} width='98%' mx='auto' height={12} mt={!index ? 2 : 1} />
                    );
                  })}
                {[...assignedToMe]
                  .sort(
                    (a, b) => a.orderableItemName?.localeCompare(b.orderableItemName || '') || 1,
                  )
                  .sort((a) => (a.reviewed ? 1 : -1))
                  .map((item, index) => {
                    if (item.orderableItemType === selectedTab?.key) {
                      return (
                        <ResultsContainer
                          key={`results-container-${index}`}
                          orderableItem={item}
                          onOpen={onOpen}
                          setItem={setEditingItem}
                        />
                      );
                    } else return null;
                  })}
              </Box>
              <Button
                isLoading={updating}
                onClick={() => {
                  handleSave();
                }}
                disabled={loading}
                bottom='131px'
                width='95%'
                left={4}
                position='absolute'>
                Save
              </Button>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
