export function getEncounterOrders() {
  return {
    encounterOrderableItems: [
      {
        orderableReportID: 999998,
        labItemId: 44444,
        orderableItemName: '(Future Order Test) Lab - Hemoglobin A1C',
        orderableItemTypeId: 0, // LAB
      },
      {
        orderableReportID: 999999,
        orderDate: '2024-02-26T04:02:30.691Z',
        // labItemId: 285267, // Glucose
        // labItemId: 245214, // Strep A
        // labItemId: 237287, // Flu A/B
        // labItemId: 224118, // Pregnancy Test, Urine HCG
        // labItemId: 229159, // Hemoccult -IFOBT
        // labItemId: 285289, // Drug Screen
        // labItemId: 224354, // Mono
        // labItemId: 285266, // Glucose, whole blood (venous draw)
        labItemId: 582403, // Rapid COVID
        orderableItemName:
          '(Constellation TEST) Rapid COVID Antigen -Coronavirus, (COVID-19) (SARS, CoV-2) - In Clinic',
        result: 'Negative',
        resultDate: '2024-02-26T04:02:30.691Z',
        orderReceived: false,
        orderableItemResults: [],
        orderableItemTypeId: 0, // LAB
        orderType: 'ACCEPTED',
        futureOrder: false,
        collectionSource: '',
        collectionVolume: '',
        collectionUnits: '',
        clinicalInfo: '',
        internalNotes: '',
        actualFasting: 0,
        reviewedByUserId: 590618,
      },
      {
        labStatus: 'unsubmitted',
        orderableReportID: 7918985,
        sourceEncounterId: 7791538,
        sourcePatientId: 581228,
        isAdHoc: false,
        orderType: 'ACCEPTED',
        declineReasonCode: null,
        orderingProviderID: 626451,
        orderingProviderName: 'Richardson,Anna',
        orderingProviderLastName: 'Richardson',
        orderingProviderNPI: '1639583776',
        facilityID: 11,
        facility: 'Conyers',
        assignedToUserID: 207605,
        reviewedByUserId: 590618,
        assignedTo: 'Hooper,Dedra',
        categoryID: 29,
        categoryName: 'Diabetes',
        orderDate: '2023-12-12T05:00:00.000Z',
        reviewedDateTime: '1902-01-01T05:00:00.000Z',
        enteredByUserID: 606997,
        futureOrderDate: null,
        collectedDate: '1901-01-01T05:00:00.000Z',
        resultDate: null,
        result: '',
        labItemId: 529171,
        orderableItemName: '(Non-Future Order Test) Lab - Hemoglobin A1C',
        reason: '',
        displayOrder: 0,
        notes: '',
        orderableItemTypeId: 0,
        orderableItemType: 'Lab',
        reviewed: false,
        highPriority: false,
        futureOrder: false,
        cancelled: false,
        doNotPublishToPortal: false,
        orderReceived: false,
        inHouse: false,
        specimenSource: '',
        clinicalInfo: '',
        internalNotes: '',
        primaryDiagnosis: null,
        isDeleted: false,
        orderEncounterId: 7791538,
        assessementItemId: null,
        orderInstructions: '',
        collectionDescription: '',
        collectionSource: '',
        collectionVolume: '',
        collectionUnits: '',
        fasting: false,
        actualFasting: 0,
      },
      {
        labStatus: 'unsubmitted',
        orderableReportID: 7918985_2,
        sourceEncounterId: 7791538,
        sourcePatientId: 581228,
        isAdHoc: false,
        orderType: 'ACCEPTED',
        declineReasonCode: null,
        orderingProviderID: 626451,
        orderingProviderName: 'Richardson,Anna',
        orderingProviderLastName: 'Richardson',
        orderingProviderNPI: '1639583776',
        facilityID: 11,
        facility: 'Conyers',
        assignedToUserID: 207605,
        reviewedByUserId: 590618,
        assignedTo: 'Hooper,Dedra',
        categoryID: 29,
        categoryName: 'Diabetes',
        orderDate: '2023-12-12T05:00:00.000Z',
        reviewedDateTime: '1902-01-01T05:00:00.000Z',
        enteredByUserID: 606997,
        futureOrderDate: null,
        collectedDate: '1901-01-01T05:00:00.000Z',
        resultDate: null,
        result: '',
        labItemId: 529171_2,
        orderableItemName: '(Non-Future Order Test 2) Lab - Hemoglobin A1C',
        reason: '',
        displayOrder: 0,
        notes: '',
        orderableItemTypeId: 0,
        orderableItemType: 'Lab',
        reviewed: false,
        highPriority: false,
        futureOrder: false,
        cancelled: false,
        doNotPublishToPortal: false,
        orderReceived: false,
        inHouse: false,
        specimenSource: '',
        clinicalInfo: '',
        internalNotes: '',
        primaryDiagnosis: null,
        isDeleted: false,
        orderEncounterId: 7791538,
        assessementItemId: null,
        orderInstructions: '',
        collectionDescription: '',
        collectionSource: '',
        collectionVolume: '',
        collectionUnits: '',
        fasting: false,
        actualFasting: 0,
      },
    ],
    encounterImmunizationInjectionItems: [
      {
        sourcePatientId: 581228,
        sourceEncounterId: 7791538,
        immunizationId: 798025,
        immunizationName: 'Fluarix (Quad) Prefill syringe',
        immunizationItemId: 285235,
        injectionLocation: 'Right Lower Forearm',
        dateTimeAdministered: '2023-12-12T19:53:00.000Z',
        administeredByUserId: 639706,
        administeredByUserName: 'Ball, Tyeshia',
        route: '',
        dose: '99',
        doseNumber: '55',
        statusDescription: 'Administered',
        statusId: 1,
        // statusDescription: 'Not Administered',
        // statusDescription: 'Refused', // DELINED
        vaccinationGivenInThePast: true,
        sourceOfHistory: '',
        documentingFacilityId: 11,
        documentingFacilityName: 'Conyers',
        patientImmunizationInjectionType: 'Immunization',
        lotId: 123,
        lotNumber: 789,
        vfcCode: 'V05',
        dateVISGiven: '2023-12-12T19:53:00.000Z',
        dateOnVIS: '2023-12-12T19:53:00.000Z',
      },
      {
        sourcePatientId: 581228,
        sourceEncounterId: 7791538,
        immunizationId: 798026,
        immunizationName: 'Pneumovax PPSV23',
        immunizationItemId: 285243,
        injectionLocation: '',
        dateTimeAdministered: '2023-12-12T19:53:00.000Z',
        administeredByUserId: 0,
        administeredByUserName: '',
        route: '',
        dose: '',
        doseNumber: null,
        statusDescription: 'Not Administered',
        vaccinationGivenInThePast: true,
        sourceOfHistory: '',
        documentingFacilityId: 11,
        documentingFacilityName: 'Conyers',
        patientImmunizationInjectionType: 'Immunization',
        lotId: null,
        lotNumber: null,
        vfcCode: null,
        dateVISGiven: null,
        dateOnVIS: null,
      },
      {
        sourcePatientId: 581228,
        sourceEncounterId: 7791538,
        immunizationId: 798027,
        immunizationName: 'Shingrix',
        immunizationItemId: 529944,
        injectionLocation: '',
        dateTimeAdministered: '2023-12-12T19:53:00.000Z',
        administeredByUserId: 0,
        administeredByUserName: '',
        route: '',
        dose: '',
        doseNumber: null,
        statusDescription: 'Not Administered',
        vaccinationGivenInThePast: true,
        sourceOfHistory: '',
        documentingFacilityId: 11,
        documentingFacilityName: 'Conyers',
        patientImmunizationInjectionType: 'Immunization',
        lotId: null,
        lotNumber: null,
        vfcCode: null,
        dateVISGiven: null,
        dateOnVIS: null,
      },
    ],
  };
}
