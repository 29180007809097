import React from 'react';
import { type GetCumulativeReportQuery } from '../../../../../__generated__/graphql';
import { Td, Tr } from '@chakra-ui/react';

type EncounterOrderableItemAssessment = NonNullable<
  NonNullable<NonNullable<GetCumulativeReportQuery['getCumulativeReport'][0]>['assessments']>[0]
>;

interface AssessmentRow {
  label: string;
  key: keyof EncounterOrderableItemAssessment;
}

const resultRows: AssessmentRow[] = [
  {
    label: 'Assessment Name',
    key: 'assessmentName',
  },
  {
    label: 'Assessment Value',
    key: 'assessmentValue',
  },
];

export function AssessmentRows({ assessment }: { assessment: EncounterOrderableItemAssessment }) {
  return (
    <React.Fragment key={`${assessment.assessmentName}-${assessment.assessmentValue}`}>
      {resultRows.map((row, j) => {
        return (
          <Tr
            _hover={{
              color: 'brand.500',
            }}
            borderBottom='1.5px solid gray.100'
            key={j}>
            <Td paddingY={2.5}>{row.label}</Td>
            <Td paddingY={2.5}>{assessment[row.key] !== '' && assessment[row.key]}</Td>
          </Tr>
        );
      })}
    </React.Fragment>
  );
}
