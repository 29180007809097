import { Center, Box, LogoWord, Button } from '../../components';
import { useAuth } from '../../hooks';

export function LoginScreen() {
  const { handleLogin } = useAuth();

  return (
    <Center mt='240'>
      <Box width='50%'>
        <Center mb='lg'>
          <LogoWord />
        </Center>
        <Button w='full' onClick={handleLogin}>
          Login with SSO
        </Button>
      </Box>
    </Center>
  );
}
