import { Tab, TabList, Text } from '@chakra-ui/react';

const tabList = ['Encounters', 'Allergies', 'Problem List', 'Med. Log'];

export function AllTabs() {
  return (
    <TabList>
      {tabList.map((label) => {
        return (
          <Tab key={label} _selected={{ color: 'primary' }} paddingLeft='40px' paddingRight='40px'>
            <Text fontSize='lg'>{label}</Text>
          </Tab>
        );
      })}
    </TabList>
  );
}
