import { Card, Flex } from '../../../components';
import { Box, Button, Icon, IconButton, SimpleGrid, Text, Tooltip } from '@chakra-ui/react';
import { formatDate } from '../../../utils';
import { SelectStatus } from './SelectStatus';
import { AiFillEdit as EditIcon } from 'react-icons/ai';
import { useUpdateMedItems } from './hooks/useUpdateMedItems';
import { useGetRxData } from './PastRx/hooks/useGetRxData';

interface Props {
  item: MedicationItemUI;
  children?: React.ReactNode;
}

export const MedicationRow = ({ item, children }: Props) => {
  const { handleSetEditedMedication, handleClickEditMedication, handleRemoveMedication } =
    useUpdateMedItems();

  const rxData = useGetRxData();
  const nonRemovable = rxData.encounters.some((encounter) =>
    encounter.meds.some((m) => m.uiMed.id === item.id),
  );

  return (
    <Card
      size='md'
      key={item.id}
      mb='sm'
      _hover={{
        backgroundColor: 'peach',
      }}>
      <Flex alignItems='center' width='100%'>
        <Tooltip label={item.medicationItem}>
          <Text variant='body2-b' noOfLines={1} width='20%'>
            {item.medicationItem}
          </Text>
        </Tooltip>

        <SimpleGrid columns={4} alignItems='center' spacing={2} width='56%' margin='0 2%'>
          <Tooltip
            label={`${item.medicationStrength}(${item.medicationFormulation}) ${item.medicationTake} ${item.medicationRoute} ${item.medicationFrequency}`}>
            <Text fontSize='sm' noOfLines={2}>
              {item.medicationStrength}
              {item.medicationFormulation && `(${item.medicationFormulation})`}
              {item.medicationTake && ` ${item.medicationTake}`}
              {item.medicationRoute && ` ${item.medicationRoute}`}
              {item.medicationFrequency && ` ${item.medicationFrequency}`}
            </Text>
          </Tooltip>

          {item.medicationStartDate && item.medicationStopDate ? (
            <Text fontSize='sm' as='b'>
              {formatDate(item.medicationStartDate)}-{formatDate(item.medicationStopDate)}
            </Text>
          ) : (
            <Button
              variant='link'
              justifyContent='flex-start'
              onClick={() => {
                handleClickEditMedication(item.id);
              }}>
              Add Start/End Date
            </Button>
          )}

          {item.rxNotes ? (
            <Tooltip label={item.rxNotes}>
              <Text fontSize='sm' noOfLines={2} textColor='gray'>
                {item.rxNotes}
              </Text>
            </Tooltip>
          ) : (
            <Button
              variant='link'
              justifyContent='flex-start'
              onClick={() => {
                handleClickEditMedication(item.id);
              }}>
              Add Note
            </Button>
          )}

          {item.rxSource ? (
            <Tooltip label={item.rxSource}>
              <Text fontSize='sm' as='b'>
                {item.rxSource}
              </Text>
            </Tooltip>
          ) : (
            <Button
              variant='link'
              justifyContent='flex-start'
              onClick={() => {
                handleClickEditMedication(item.id);
              }}>
              Add Source
            </Button>
          )}
        </SimpleGrid>

        {!children && (
          <Flex justifyContent='space-between' width='20%'>
            <SelectStatus
              value={item?.newStatus as MedStatus}
              onChange={(val) => {
                handleSetEditedMedication({ ...item, newStatus: val });
              }}
            />

            <IconButton
              aria-label='Info'
              variant='ghost'
              icon={<Icon boxSize={5} color='primary' as={EditIcon} />}
              onClick={() => {
                handleClickEditMedication(item.id);
              }}
            />

            {nonRemovable ? (
              // preserve the alignment of the edit button
              <Box w='full' />
            ) : (
              <Button
                variant='ghost'
                onClick={() => {
                  handleRemoveMedication(item.id);
                }}>
                Remove
              </Button>
            )}
          </Flex>
        )}
        {children && <Box w='20%'>{children}</Box>}
      </Flex>
    </Card>
  );
};
