export const getAllergyTypes = [
  {
    id: 1,
    name: 'Allergy',
  },
  {
    id: 4,
    name: 'Contraindication',
  },
  {
    id: 5,
    name: 'Lack of Therapeutic Effect',
  },
  {
    id: 3,
    name: 'Side Effects',
  },
];
