import {
  Box,
  Button,
  Card,
  Drawer,
  Flex,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { EncounterHeader } from '../components';
import { EditPanel } from './components/EditPanel';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTelephoneEncountersQuery } from '../../../__generated__/graphql';
import { formatDate, formatTime } from '../../../utils';
import { NewTEForm } from './NewTEForm';
import { ChevronDownIcon } from '@chakra-ui/icons';

export function OpenTEScreen() {
  const { patientId } = useParams();
  const [collapsed, setCollapsed] = useState(false);

  const params = new URLSearchParams(window.location.search);

  const { data, loading, refetch } = useGetTelephoneEncountersQuery({
    variables: {
      patientIds: [patientId ? Number(patientId) : 0],
      status: 'open',
    },
    skip: !patientId,
  });

  const openTEs = data?.getTelephoneEncounters?.items || [];
  const created = params.get('created');
  const selectedTEId = params.get('telephoneEncounterId');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const nav = useNavigate();

  useEffect(() => {
    if (selectedTEId) {
      onOpen();
    }
  }, [selectedTEId]);

  const handleClose = () => {
    params.delete('telephoneEncounterId');
    nav(`/providers/${patientId}/open?${params}`, { replace: true });
  };

  return (
    <Box padding={5} height='100vh' overflow='scroll'>
      <Box
        // Extra space for button
        paddingBottom='48px'>
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          onEsc={handleClose}
          isFullHeight
          size='full'>
          <EditPanel
            onClose={() => {
              onClose();
              handleClose();
            }}
            onSave={refetch}
            selectedTEId={selectedTEId ?? undefined}
          />
        </Drawer>

        <EncounterHeader patientId={patientId ? Number(patientId) : undefined} />

        {!created && (
          <Flex margin='24px 0'>
            <Button
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              variant='outline'
              justifyContent='start'
              border='none'
              textColor='black'
              display='flex'>
              <Text pr={8} fontSize='lg' as='b'>
                Create Telephone Encounter
              </Text>
              <ChevronDownIcon
                w={6}
                h={6}
                color='brand.500'
                transition='.25s'
                transform={!collapsed ? '' : 'rotate(-90deg)'}
              />
            </Button>
          </Flex>
        )}

        {!collapsed && !created && (
          <Box position='relative'>
            {patientId && <NewTEForm refetch={refetch} patientId={Number(patientId)} />}
          </Box>
        )}

        {created && <Box marginTop='48px' />}

        {!loading && !!data?.getTelephoneEncounters?.items?.length && (
          <Box>
            <Text fontSize='lg' as='b'>
              Open TEs
            </Text>
          </Box>
        )}

        {openTEs?.map((telephoneEncounter, index) => {
          return (
            <Card
              key={telephoneEncounter.ticketId}
              marginTop='12px'
              marginBottom='24px'
              padding='14px'
              height='84px'>
              <SimpleGrid columns={6} gap={4} height='100%'>
                <Flex flexDirection='column' justifyContent='space-between'>
                  <Text fontSize='md' color='#ED4924'>
                    {formatDate(telephoneEncounter.encounterStartDateTime || '')}{' '}
                    {formatTime(telephoneEncounter.encounterStartDateTime || '')}
                  </Text>

                  <Tooltip
                    label={`${telephoneEncounter.patientFirstName} ${telephoneEncounter.patientLastName}`}>
                    <Text noOfLines={1} fontSize='md' as='b'>
                      {telephoneEncounter.patientFirstName} {telephoneEncounter.patientLastName}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex flexDirection='column' justifyContent='space-between'>
                  <Tooltip label={telephoneEncounter.assignedToUserName}>
                    <Text noOfLines={1} fontSize='sm'>
                      Assignee: {telephoneEncounter.assignedToUserName ?? 'N/A'}
                    </Text>
                  </Tooltip>

                  <Tooltip label={telephoneEncounter.answeredByUserName}>
                    <Text noOfLines={1} fontSize='sm'>
                      Answered: {telephoneEncounter.answeredByUserName ?? 'N/A'}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex flexDirection='column' justifyContent='space-between'>
                  <Tooltip label={telephoneEncounter.reason}>
                    <Text noOfLines={1} fontSize='sm'>
                      Reason: {telephoneEncounter.reason ?? 'N/A'}
                    </Text>
                  </Tooltip>

                  <Tooltip label={telephoneEncounter.providerName}>
                    <Text noOfLines={1} fontSize='sm'>
                      Provider: {telephoneEncounter.providerName ?? 'N/A'}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex flexDirection='column' justifyContent='space-between'>
                  <Tooltip label={telephoneEncounter.facilityName}>
                    <Text noOfLines={1} fontSize='sm'>
                      Facility: {telephoneEncounter.facilityName ?? 'N/A'}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex flexDirection='column' justifyContent='space-between'>
                  <Tooltip label={telephoneEncounter.message}>
                    <Text noOfLines={1} fontSize='sm'>
                      Message: {telephoneEncounter.message ?? 'N/A'}
                    </Text>
                  </Tooltip>

                  <Tooltip label={telephoneEncounter.actionTaken}>
                    <Text noOfLines={1} fontSize='sm'>
                      Action Taken: {telephoneEncounter.actionTaken ?? 'N/A'}
                    </Text>
                  </Tooltip>
                </Flex>

                <Flex width='100%' justifyContent='flex-end' alignItems='center'>
                  <Button
                    height='100%'
                    width='75%'
                    onClick={() => {
                      params.append(
                        'telephoneEncounterId',
                        `${telephoneEncounter.sourceEncounterId}`,
                      );
                      nav(`/providers/${patientId}/open?${params}`);
                    }}>
                    Edit
                  </Button>
                </Flex>
              </SimpleGrid>
            </Card>
          );
        })}
      </Box>

      <Box position='absolute' bottom='0' marginBottom='12px' width='97%'>
        <Button
          width='100%'
          backgroundColor='#7E90CF'
          _hover={{ backgroundColor: '#4C5E9B' }}
          onClick={() => {
            nav(`/providers`);
          }}>
          No Telephone Encounter Needed
        </Button>
      </Box>
    </Box>
  );
}
