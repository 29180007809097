import { MdChecklist } from 'react-icons/md';
import { Button, Icon } from '@chakra-ui/react';
import { useModal } from '../../../../hooks';

export function WorklistButton() {
  const { showModal } = useModal();

  return (
    <Button
      leftIcon={<Icon as={MdChecklist} boxSize='5' />}
      onClick={() => {
        showModal({
          modalType: 'WorklistModal',
          chakraModalProps: { size: '6xl' },
          modalProps: { showFinalize: false },
        });
      }}>
      Checklist
    </Button>
  );
}
