import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  HStack,
  InputGroup,
  Text,
  type TextProps,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { AllergiesEmptyStateIllustration } from './AllergiesEmptyStateIllustration';
import { isEmpty } from 'lodash';
import { AsyncSelectField } from '../../../features';
import { type AllergyUI } from '../../../types';
import { useDebounce, useMAWorkflow, useToast } from '../../../hooks';
import { useEffect, useState } from 'react';
import { formatISODate } from '../../../utils';
import { modalActions, uiActions, useAppDispatch } from '../../../state-management';
import { DEBOUNCE_MILLISECONDS } from '../../../constants';
import { NKDA } from './NKDA';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { useGetAllergySourcesQuery } from '../../../__generated__/graphql';

export function AllergiesContent({ nextStep }: { nextStep: () => void }) {
  const {
    patientAllergyItems,
    onUpdateAllergies,
    markCurrentStepComplete,
    isUpdateAllergiesLoading,
    isUpdateAllergiesSuccess,
    isLoadingPatientAllergies,
    maWorkFlowState,
  } = useMAWorkflow();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);
  const { data: fetchedAllergySourcesData, loading: isLoadingGetAllergySources } =
    useGetAllergySourcesQuery({
      variables: { contains: debouncedSearchText },
      skip: !debouncedSearchText,
    });
  const fetchedAllergySources = fetchedAllergySourcesData?.getAllergySources;

  const options = fetchedAllergySources
    ?.filter((item) => {
      return !patientAllergyItems?.some(
        (allergyItem) => allergyItem.source.agentSubstance === item?.agentSubstance,
      );
    })
    .map((item) => {
      return {
        value: item?.id.toString(),
        label: item?.agentSubstance,
      };
    });

  function handleOptionSelect(option: DropdownOption | undefined | null) {
    if (option != null) {
      const id = Number(option.value);
      dispatch(
        uiActions.setSelectedAllergyItem({
          id,
          allergySourceId: id,
          source: {
            id,
            agentSubstance: option.label,
          },
          // Default to active status for AddAllergy modal
          status: {
            id: 1,
            name: 'Active',
          },
        }),
      );
      dispatch(
        modalActions.showModal({
          modalType: 'AddAllergyPromptModal',
          chakraModalProps: { size: '4xl' },
        }),
      );
    }
  }

  function handleRemoveAllergyItem(id: AllergyUI['id']) {
    dispatch(uiActions.removeAllergyItem(id));
  }

  function handleEditAllergyItem(allergyItem: AllergyUI) {
    return () => {
      dispatch(uiActions.setSelectedAllergyItem(allergyItem));
      dispatch(
        modalActions.showModal({
          modalType: 'AddAllergyPromptModal',
          modalProps: { isEdit: true },
          chakraModalProps: { size: '4xl' },
        }),
      );
    };
  }

  // Setup toasts
  useToast({
    id: 'submit-allergies-success',
    title: 'Success',
    description: 'Your submission was successful.',
    show: isUpdateAllergiesSuccess,
    status: 'success',
  });

  // TODO: When exactly is it complete?
  useEffect(() => {
    if (isUpdateAllergiesSuccess) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isUpdateAllergiesSuccess, markCurrentStepComplete]);

  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg' alignItems='stretch'>
        <Flex justifyContent='space-between' w='full'>
          <MAWorkFlowHeader>Allergies</MAWorkFlowHeader>
          <Box alignSelf='end'>
            <NKDA />
          </Box>
        </Flex>
        <HStack width='full'>
          <InputGroup>
            <AsyncSelectField
              isDisabled={maWorkFlowState.isNKDA}
              handleOptionSelect={handleOptionSelect}
              inputProps={{
                placeholder: 'Add allergy',
                autoFocus: true,
              }}
              dropdownItems={options}
              handleInputChange={(val) => {
                if (val) {
                  setSearchText(val);
                }
              }}
              loading={isLoadingGetAllergySources}
            />
          </InputGroup>
        </HStack>
        <WaitUntilLoaded loading={isLoadingPatientAllergies}>
          {isEmpty(patientAllergyItems) ? (
            <Center mt='8' w='full'>
              <VStack spacing='md'>
                <AllergiesEmptyStateIllustration />
                <Text color='secondary'>
                  To get started, search and add items to the allergies list.
                </Text>
              </VStack>
            </Center>
          ) : (
            <VStack spacing='sm' w='full'>
              <Flex justifyContent='space-between' alignItems='center' w='full' p='md'>
                <AllergyHeader flex='2'>Agent Substance</AllergyHeader>
                <AllergyHeader flex='2'>Reaction(s)</AllergyHeader>
                <AllergyHeader flex='2'>Type</AllergyHeader>
                <AllergyHeader flex='1'>Status</AllergyHeader>
                <AllergyHeader flex='1'>Criticality</AllergyHeader>
                <AllergyHeader flex='1'>Onset Date</AllergyHeader>
                <AllergyHeader flex='1.5' />
              </Flex>
              {patientAllergyItems.map((item) => {
                return (
                  <Card key={item.id} p='md' w='full' onClick={handleEditAllergyItem(item)}>
                    <Flex justifyContent='space-between' alignItems='center'>
                      <Text flex='2'>{item.source.agentSubstance}</Text>
                      <Text flex='2'>{item.reaction?.reaction}</Text>
                      <Text flex='2'>{item.type?.name}</Text>
                      <Text flex='1'>{item.status?.name}</Text>
                      <Text flex='1'>{item.criticality?.name}</Text>
                      <Text flex='1'>{formatISODate(item.onSetDate)}</Text>
                      <Box flex='1.5'>
                        <Button
                          variant='ghost'
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveAllergyItem(item.id);
                          }}>
                          Remove
                        </Button>
                        <Button variant='ghost' onClick={handleEditAllergyItem(item)}>
                          Edit
                        </Button>
                      </Box>
                    </Flex>
                  </Card>
                );
              })}
            </VStack>
          )}
        </WaitUntilLoaded>
        <Box height='50px' />
      </VStack>
      <SaveAndWorklistButtons
        onClick={onUpdateAllergies}
        isLoading={isUpdateAllergiesLoading}
        isDisabled={isLoadingPatientAllergies}>
        Save / Verify Allergies
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}

function AllergyHeader(props: TextProps) {
  return <Text variant='body-b' {...props} />;
}
