import React, { useEffect, useState } from 'react';
import { Box, Flex, HStack, Icon, Input, Text, VStack, WaitUntilLoaded } from '../../../components';
import { AiOutlinePlus as AddIcon } from 'react-icons/ai';
import { uiActions, useAppDispatch } from '../../../state-management';
import { useDebounce, useMAWorkflow } from '../../../hooks';
import { useGetRosCategoryLookupsQuery } from '../../../__generated__/graphql';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { ROSExistingEncounterSymptomsPanel } from './ROSExistingEncounterSymptomsPanel';

export function ROSCategories() {
  const dispatch = useAppDispatch();
  const {
    ros: { showingCategoryIds, rosData },
  } = useMAWorkflow();
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText);

  const { data, loading: isLoading } = useGetRosCategoryLookupsQuery({
    variables: {
      contains: debouncedSearchText || '',
    },
  });
  useEffect(() => {
    if (data && rosData) {
      rosData.encounterROSDataItems.forEach((encounterRosData) => {
        const category = data.getROSCategoryLookups?.find(
          (lookup) => lookup.rosCategoryID === encounterRosData.rosCategoryID,
        );
        dispatch(
          uiActions.addRosDataItem({
            rosCategoryId: category?.rosCategoryID ?? -1,
            rosCategoryName: category?.name ?? '',
          }),
        );
      });
    }
  }, [rosData, data]);

  return (
    <VStack spacing='md' w='full' h='full' overflow='hidden' pr='md'>
      <Flex w='full'>
        <Input
          size='lg'
          borderColor='secondary'
          borderWidth='2px'
          autoFocus
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          mb='sm'
          placeholder='Search'
          _focus={{
            boxShadow: 'primary',
            borderColor: 'brand.500',
          }}
        />
      </Flex>

      <VStack w='full' align='flex-start' overflow='auto' justify='flex-start' mb='80px'>
        <WaitUntilLoaded loading={isLoading}>
          {searchText || !rosData.encounterROSDataItems.length ? (
            data?.getROSCategoryLookups?.map((d) => {
              const isDisabled = showingCategoryIds?.some(
                (c) => c.rosCategoryId === d?.rosCategoryID,
              );
              return (
                <Box pl='sm' key={d?.rosCategoryID} w='full'>
                  <Box
                    opacity={isDisabled ? 0.5 : 1}
                    onClick={() => {
                      if (d?.rosCategoryID && !isDisabled) {
                        dispatch(uiActions.setROSSelectedCategoryId(d?.rosCategoryID));
                        dispatch(
                          uiActions.addRosDataItem({
                            rosCategoryId: d?.rosCategoryID,
                            rosCategoryName: d?.name ?? '',
                          }),
                        );
                        setSearchText('');
                      }
                    }}
                    textAlign='left'>
                    <Box
                      role='group'
                      _hover={{ color: !isDisabled ? 'primary' : '' }}
                      cursor={isDisabled ? 'default' : 'pointer'}
                      mb={3}>
                      <VStack spacing={0}>
                        <HStack spacing='sm' justifyContent='flex-start' w='full'>
                          <Text as='b' whiteSpace='nowrap' w='full' textAlign='left'>
                            {d?.name}
                          </Text>
                          <Icon boxSize={6} as={AddIcon} color='brand.500' />
                        </HStack>
                        {!!d?.rosSymptoms?.length && searchText && (
                          <Box
                            w='full'
                            color='secondary'
                            _groupHover={{ color: !isDisabled ? 'primary' : '' }}>
                            <Text fontSize='sm' as='sub' pr={1} w='full' textAlign='left'>
                              Symptoms <ChevronRightIcon />
                            </Text>
                            <Text fontSize='sm' as='sub' w='full' textAlign='left'>
                              {d?.rosSymptoms?.map((symptom) => symptom?.symptomName).join(', ')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <ROSExistingEncounterSymptomsPanel
              categoryEncounterData={rosData}
              clickHandler={(categoryId: number, symptomId?: number) => {
                dispatch(uiActions.setROSSelectedCategoryId(categoryId));
                dispatch(uiActions.setROSSelectedSymptomId(symptomId));
              }}
            />
          )}
        </WaitUntilLoaded>
      </VStack>
    </VStack>
  );
}
