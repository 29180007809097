export function getLocations() {
  return {
    locations: [
      {
        locationID: 33,
        locationName: 'Aylo Health Canton',
      },
      {
        locationID: 11,
        locationName: 'Aylo Health Conyers',
      },
      {
        locationID: 14,
        locationName: 'Aylo Health Ellenwood',
      },
      {
        locationID: 28,
        locationName: 'Aylo Health Endocrinology Stockbridge',
      },
      {
        locationID: 13,
        locationName: 'Aylo Health Hampton',
      },
      {
        locationID: 18,
        locationName: 'Aylo Health Hwy 155',
      },
      {
        locationID: 10,
        locationName: 'Aylo Health Hwy 81',
      },
      {
        locationID: 32,
        locationName: 'Aylo Health Imaging Locust Grove',
      },
      {
        locationID: 7,
        locationName: 'Aylo Health Imaging Stockbridge',
      },
      {
        locationID: 8,
        locationName: 'Aylo Health Jackson',
      },
      {
        locationID: 12,
        locationName: 'Aylo Health Kelly Road',
      },
      {
        locationID: 34,
        locationName: 'Aylo Health Kennesaw',
      },
      {
        locationID: 6,
        locationName: 'Aylo Health Locust Grove',
      },
      {
        locationID: 29,
        locationName: 'Aylo Health Pediatrics Ellenwood',
      },
      {
        locationID: 27,
        locationName: 'Aylo Health Sleep Center McDonough',
      },
      {
        locationID: 9,
        locationName: 'Aylo Health Stockbridge',
      },
    ],
  };
}
