import { useMemo, useState } from 'react';
import { useGetProvidersAndStaffQuery } from '../../__generated__/graphql';
import { type ComboBoxOption } from '../../types';
import { Box, ComboBox } from '../../components';
import { IS_LOCAL } from '../../constants';
import { useAuth, useDebounce } from '../../hooks';

interface Props {
  selectionCallback: (provider: ComboBoxOption | undefined) => void;
  selected?: ComboBoxOption;
  label?: string;
  // allows for pre-selecting an option by id
  // in case you don't have access to the full
  // object in the parent
  selectedId?: number;
  // use to set a test assignee
  placeholder?: string;
  isDisabled?: boolean;
}

export function ProviderAndStaffComboBox({
  selectionCallback,
  selected,
  selectedId,
  placeholder,
  isDisabled,
  label,
}: Props) {
  const { user } = useAuth();

  const [search, setSearch] = useState('');
  // use loaded as the key to update the combobox
  // and show the initial value since this combobox
  // will not update when defaultValue is updated
  const [loaded, setLoaded] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const { data, loading } = useGetProvidersAndStaffQuery({
    variables: { limit: 10, contains: debouncedSearch, id: selectedId },
  });

  if (data && !loaded) {
    setLoaded(true);
  }

  const assignees = useMemo(() => {
    const assignees = [
      ...(data?.getProviders?.providers?.map((provider) => ({
        label: `${provider.providerName}`,
        value: `${provider.providerID}`,
      })) ?? []),
      ...(data?.getStaff?.staff?.map((staff) => ({
        label: `${staff.staffName}`,
        value: `${staff.staffId}`,
      })) ?? []),
    ].sort((a, b) => a.label.localeCompare(b.label));

    if (IS_LOCAL && user) {
      const me = {
        label: `${user.firstName} ${user.lastName} - Me`,
        value: user.ecwId ?? '',
      };

      assignees.unshift(me);
    }

    return assignees;
  }, [data, user, IS_LOCAL]);

  const _selected = useMemo(() => {
    if (selected) return selected;
    if (selectedId) {
      return assignees.find((a) => `${a.value}` === `${selectedId}`);
    }
  }, [selected, assignees, selectedId]);

  return (
    <Box width='100%'>
      <ComboBox
        label={label}
        isDisabled={isDisabled}
        key={`${loaded}`}
        isLoading={loading}
        // we're filtering on the server so
        // override any client-side filtering
        filterOption={() => true}
        onInputChange={setSearch}
        placeholder={placeholder ?? ''}
        options={assignees}
        onSelection={(selection) => {
          selectionCallback(selection);
        }}
        defaultValue={_selected}
      />
    </Box>
  );
}
