import { useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, Center, Text } from '../../../components';
import { VitalsPanel } from './VitalsPanel';
import { VitalsHistoryPanel } from './VitalsHistoryPanel';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { useActiveEncounter, useAppUI, useCustomToast, usePatient } from '../../../hooks';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import {
  type EncounterVital,
  useGetVitalsQuery,
  useUpdateEncounterVitalsMutation,
} from '../../../__generated__/graphql';
import { CircleIcon } from '../../../components/svgs/Circle';
import { VitalValidationResult } from './VitalValidationResult';
import { Box, Button, Flex, HStack, SimpleGrid } from '@chakra-ui/react';
import { nowISODate } from '../../../utils';
import { modalActions, useAppDispatch } from '../../../state-management';

export function VitalsContent({ nextStep }: { nextStep: () => void }) {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { activeEncounterId } = useActiveEncounter();
  const { patientId } = usePatient();
  const { markCurrentStepComplete } = useAppUI();
  const toast = useCustomToast();

  const {
    data: vitalsData,
    loading: isLoadingVitals,
    refetch: refetchVitals,
  } = useGetVitalsQuery({ variables: { patientId }, skip: !patientId });
  const vitals = vitalsData?.getVitals;

  const [onSaveVitals, { loading: isSavingVitals }] = useUpdateEncounterVitalsMutation();

  const saveVitalsHandler = async (vitals: EncounterVital[]): Promise<void> => {
    if (patientId && activeEncounterId) {
      await onSaveVitals({
        variables: {
          input: {
            encounterId: activeEncounterId,
            patientId,
            encounterDate: nowISODate(),
            vitals: vitals.map((v) => ({
              ...v,
              value: v.qualifier ? `${v.qualifier}:${v.value}` : v.value,
            })),
          },
        },
        onError: () => {
          toast({
            id: 'submit-vitals-fail',
            title: 'Error',
            description: 'Something went wrong.',
            status: 'error',
          });
        },
        onCompleted: async () => {
          await refetchVitals();
          markCurrentStepComplete();
          nextStep();
          toast({
            id: 'submit-vitals-success',
            title: 'Success',
            description: 'Your submission was successful.',
            status: 'success',
          });
        },
      });
    } else {
      toast({
        id: 'submit-vitals-fail',
        title: 'Error',
        description: 'Something went wrong.',
        status: 'error',
      });
    }
  };

  return (
    <MAWorkFlowContentContainer>
      <Tabs index={activeTabIndex} onChange={setActiveTabIndex} colorScheme='brand'>
        <SimpleGrid columns={3}>
          <MAWorkFlowHeader>Vitals</MAWorkFlowHeader>
          <TabList>
            <Tab w='50%'>New Entry</Tab>
            <Tab w='50%'>Vitals History</Tab>
          </TabList>
          {/* Only show Growth Chart button when patientId exists */}
          {!!patientId && (
            <Flex justifyContent='end'>
              <Button
                colorScheme='brand'
                variant='solid'
                size='sm'
                onClick={() => {
                  dispatch(
                    modalActions.showModal({
                      modalType: 'GrowthChartModal',
                      chakraModalProps: { size: 'full' },
                    }),
                  );
                }}>
                Growth Chart
              </Button>
            </Flex>
          )}
        </SimpleGrid>
        <Center w='full'>
          <HStack ml={'auto'}>
            <Text color={VitalValidationResult.WARNING_COLOR}>
              <CircleIcon boxSize={3} mr={1} />
              May Require Attention
            </Text>
            <Text color={VitalValidationResult.ERROR_COLOR}>
              <CircleIcon boxSize={3} mr={1} />
              Outside Range
            </Text>
          </HStack>
        </Center>

        <Box maxW='100vw' overflowX={'auto'}>
          <TabPanels>
            <TabPanel>
              <VitalsPanel saveVitalsHandler={saveVitalsHandler} isSavingVitals={isSavingVitals} />
            </TabPanel>
            <TabPanel>
              <VitalsHistoryPanel vitals={vitals || []} isLoading={isLoadingVitals} />
            </TabPanel>
          </TabPanels>
        </Box>
      </Tabs>
    </MAWorkFlowContentContainer>
  );
}
