import { Box, HStack, useRadio } from '@chakra-ui/react';
import React, { useState } from 'react';
import { options } from './SelectStatus';

export function RadioCard({
  label,
  value,
  selected,
  onClick,
}: {
  label: string;
  value: string;
  selected?: boolean;
  onClick?: (value: string) => void;
}) {
  const { getInputProps } = useRadio({});

  const input = getInputProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        cursor='pointer'
        borderWidth='2px'
        borderRadius='md'
        borderColor='secondary'
        color={selected ? 'white' : 'secondary'}
        backgroundColor={selected ? 'secondary' : 'inherit'}
        boxShadow={selected ? 'outline' : 'md'}
        _hover={{
          boxShadow: 'outline',
        }}
        px='2'
        py='1'
        onClick={(e) => {
          onClick?.(value);
          e.preventDefault();
        }}>
        {label}
      </Box>
    </Box>
  );
}

export function MedicationStatusMarkAll({
  onChange,
  value,
}: {
  onChange: (selectedValue: string, groupLabel: string) => void;
  value: MedStatus;
}) {
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const onClickHandler = (val: string) => {
    setSelectedValue(val);
    onChange(val, value);
  };
  return (
    <HStack spacing='xs'>
      {Object.entries(options).map(([label, val]) => {
        return (
          <RadioCard
            key={val}
            label={label}
            value={val}
            onClick={onClickHandler}
            selected={val === selectedValue}
          />
        );
      })}
    </HStack>
  );
}
