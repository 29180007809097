import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {
  useActiveEncounter,
  useCustomToast,
  useModal,
  useNavigation,
  usePatient,
} from '../../../hooks';
import { selectors, useAppSelector } from '../../../state-management';
import { useUpdateEncounterMutation } from '../../../__generated__/graphql';
import { useState } from 'react';

interface FinishButtonModalProps {
  showFinalize: boolean;
}

export function FinishButtonModal({ showFinalize }: FinishButtonModalProps) {
  const { isOpen, onOpen: onConfirmationPrompt, onClose: closePromptModal } = useDisclosure();
  const [displayFinalize, setDisplayFinalize] = useState(showFinalize);
  // Re-opens confirmation prompt when FinishButtonModal is re-opened
  // after successful completion of ClinicalStatusSelectModal
  if (displayFinalize) {
    onConfirmationPrompt();
    setDisplayFinalize(false);
  }

  const { activeEncounterId, activeEncounter } = useActiveEncounter();
  const { patientId } = usePatient();
  const { showModal, hideModal: closeWorklistModal } = useModal();
  const orderingProviderID = useAppSelector(selectors.getActiveProviderID) || -1;
  const toast = useCustomToast();

  const [handleUpdateEncounter, { loading: isUpdatingEncounter }] = useUpdateEncounterMutation();
  const { toEncountersPage } = useNavigation();

  function handleSubmit() {
    handleUpdateEncounter({
      variables: {
        encounterId: activeEncounterId,
        encounterInput: {
          sourceEncounterId: activeEncounterId ?? 0,
          sourcePatientId: patientId,
          providerId: orderingProviderID,
          clinicalStatusAfterCheckIn: activeEncounter?.clinicalStatusAfterCheckIn ?? 'Ready',
        },
      },
      onCompleted: () => {
        toast({
          id: 'submission-success-toast-checklist',
          title: 'Success',
          description: 'Successful update',
          status: 'success',
        });
        handleCloseAllModalsAndNavigateToHome();
      },
      onError: () => {
        toast({
          id: 'submission-error-toast-checklist',
          title: 'Error',
          description: 'Failed to update encounter status',
          status: 'error',
          duration: null,
        });
      },
    });
  }

  function handleCloseAllModalsAndNavigateToHome() {
    closeWorklistModal();
    toEncountersPage();
  }

  return (
    <>
      <Button
        isLoading={isUpdatingEncounter}
        onClick={() => {
          // Open ClinicalStatusSelectModal when encounter has clinical status
          // Defaults to 'Ready'
          activeEncounter?.clinicalStatusAfterCheckIn.length
            ? showModal({
                modalType: 'ClinicalStatusSelectModal',
                chakraModalProps: {
                  size: 'full',
                  backgroundColor: 'brand.500',
                },
                modalProps: {
                  clinicalStatusAfterCheckIn: activeEncounter?.clinicalStatusAfterCheckIn,
                  updateClinicalStatus: async (clinicalStatusAfterCheckIn: string) => {
                    handleUpdateEncounter({
                      variables: {
                        encounterId: activeEncounterId,
                        encounterInput: {
                          sourceEncounterId: activeEncounterId ?? 0,
                          sourcePatientId: patientId,
                          providerId: orderingProviderID,
                          clinicalStatusAfterCheckIn,
                        },
                      },
                    });
                  },
                  navigateModalType: 'WorklistModal',
                  isFullscreen: true,
                },
              })
            : onConfirmationPrompt();
        }}>
        Finish (Go to Encounters)
      </Button>

      <Modal isOpen={isOpen} onClose={closePromptModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to finalize?</ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={2} onClick={closePromptModal}>
              No
            </Button>
            <Button onClick={handleSubmit} isLoading={isUpdatingEncounter}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
