export const HL7LabResult = `<?xml version="1.0"?>
<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
<xsl:output method="html" encoding="ISO-8859-1" doctype-public="-//W3C//DTD HTML 4.01//EN" doctype-system="http://www.w3.org/TR/html4/strict.dtd"/>
<xsl:preserve-space elements="notes" />
<xsl:preserve-space elements="value" />
<xsl:preserve-space elements="invalidHl7Message" />
 <xsl:template name="string-replace-all">
    <xsl:param name="text" />
    <xsl:param name="replace" />
    <xsl:param name="by" />
    <xsl:choose>
      <xsl:when test="contains($text, $replace)">
        <xsl:value-of select="substring-before($text,$replace)" />
        <xsl:value-of select="$by" />
        <xsl:call-template name="string-replace-all">
          <xsl:with-param name="text"
          select="substring-after($text,$replace)" />
          <xsl:with-param name="replace" select="$replace" />
          <xsl:with-param name="by" select="$by" />
        </xsl:call-template>
      </xsl:when>
      <xsl:otherwise>
        <xsl:value-of select="$text" />
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>
  
  
<xsl:template match="/">
<xsl:variable name="smallcase" select="'abcdefghijklmnopqrstuvwxyz'" />
<xsl:variable name="uppercase" select="'ABCDEFGHIJKLMNOPQRSTUVWXYZ'" />
<xsl:variable name="gender" select="translate(substring(//labResult/ptsex,1,1), $smallcase, $uppercase)"/>
<xsl:variable name="patientidentifier" select="concat(//labResult/ptName,'   |   ', //labResult/ptDob,'  |  ', $gender)"/>
	<xsl:variable name="newLine">"&#xA;"</xsl:variable>
	<xsl:variable name="newLineXML">"&#xd;"</xsl:variable>
<HTML>
<!--[if lt IE 7]>      <html class="no-js lt-ie9 lt-ie8 lt-ie7"> <![endif]-->
<!--[if IE 7]>         <html class="no-js lt-ie9 lt-ie8"> <![endif]-->
<!--[if IE 8]>         <html class="no-js lt-ie9"> <![endif]-->
<!--[if gt IE 8]>      <html class="no-js"> <!<![endif]-->
<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <meta name="description" content="" />
    <meta name="viewport" content="width=device-width" />
    <link rel="stylesheet" href="/mobiledoc/jsp/catalog/xml/hl7/css/style.css" />
</head>
<style>
	@media screen {
	thead {display: table-header-group; }
	tfoot {display: table-row-group; }
	}
	.wordWrapLabNotes{font-size:12px;word-wrap: break-word;margin:0px;line-height:20px}  <!-- wrap notes -->
  	#disclaimer tr:first-child td{border: 2px solid red;}
	.warningMessage {border: 2px solid red}
	.unsupported-characters{
		background-color: #ffbbbb;
		font-weight: bold;
	}
</style>
<BODY>
<div class="page">
	<table class="table">
		<xsl:if test="//return/ShowLabXrayHeadingForReferral = 'Referral_LAB_Report'"> <h2 align="center"> Lab Report </h2> </xsl:if>
		<xsl:choose>
		<xsl:when test="not(//return/labResult/invalidHl7Message)">
									
		<tbody>
		 <xsl:if test="//labResult/hl7PtName">
			<tr class="noPrint"><td colspan="2">The patient demographics received in electronic result is <xsl:value-of select="//labResult/hl7PtName"/></td></tr>
		</xsl:if>
			<xsl:if test="//labResultWarning/warningMessage">

				<tr>
					<xsl:if test="translate(//labResultWarning/warningMessage, $newLineXML ,$newLine)">
						<td id="InvalidMessageNote" colspan="2" class="warningMessage"><pre class="prewordwrap"><span style ="font-weight:bold;" class= "red">Note: </span><xsl:value-of select="//labResultWarning/warningMessage"/></pre></td>
					</xsl:if>
				</tr>
			</xsl:if>

			<tr>
            	<td class="nobotpadding">
                	<div class="per-details">
                        <h1><xsl:value-of select="//labResult/ptName"/>, <small><xsl:value-of select="$gender"/>, <xsl:value-of select="//labResult/ptDob" /></small></h1>
                        <span class="med-text blue mr10"><xsl:if test="//labResult/ptPhone !=''"><img src="/mobiledoc/jsp/catalog/xml/hl7/img/phone.jpg" alt="" width="14" height="14" class="i-phone" /><xsl:value-of select="//labResult/ptPhone"/></xsl:if></span> 
                        <xsl:if test="//labResult/AcctNum !=''">
							<span class="med-text blue"><b>MRN:</b>&#160;<xsl:value-of select="//labResult/AcctNum"/></span>
						</xsl:if>
                    </div>
                </td>
                <td class="nobot-right-padding nobotpadding">
                	 <div class="text-right add-details">
                	 <xsl:if test="//facilityInfo/custSentence">
							 <span ><xsl:value-of select="//facilityInfo/custSentence"/></span>
						</xsl:if>
                       <h4 class="blue"><xsl:value-of select="//facilityInfo/facName"/></h4>
                       <p class="med-text"><xsl:value-of select="//facilityInfo/facAddr1"/>, <xsl:value-of select="//facilityInfo/facAddr3"/><br />
                       <span><img src="/mobiledoc/jsp/catalog/xml/hl7/img/phone.jpg" alt="" width="14" height="14" class="i-phone" /><xsl:value-of select="//facilityInfo/facPhone"/></span></p>
                     </div>
                     
                     <div class="final-result">
                     	<h3 class="text-right">
							<xsl:choose>
							    <xsl:when test="//return/labResult/labResultStatus = 'P' or //return/labResult/labResultStatus = 'PRELIM'">
									PRELIMINARY RESULT
							    </xsl:when>
							    <xsl:when test="//return/labResult/labResultStatus = 'F' or //return/labResult/labResultStatus = 'FINAL'">
									FINAL RESULT
							    </xsl:when>
							    <xsl:when test="//return/labResult/labResultStatus = 'C' or //return/labResult/labResultStatus = 'Corrected'">
									CORRECTED RESULT
							    </xsl:when>
							    <xsl:when test="//return/labResult/labResultStatus">
									<xsl:value-of select="//return/labResult/labResultStatus" />
							    </xsl:when>
							</xsl:choose>
                        </h3>
                     </div>
                     <div class="spacer"></div>
                </td>
            </tr>
			<tr>
               	<td colspan="2" class="nopadding">
                    <div class="blue-border"></div>
                    <div class="clear"></div>
                </td>
            </tr>	
            <tr>
                <td colspan="2" class="nopadding">
                    <div class="padded20-side">
                	    <table class="table blue-table">
                    		<tr>
			                      <td width="33.3%" class="med-text nopadding grey">Accession ID: <span class="black"><xsl:value-of select="//labResult/SessionId"/></span>
				                      <xsl:if test="//labResult/labCollSource != ''">
				                     	 <br /> Specimen Source: <span class="black"><xsl:value-of select="//labResult/labCollSource"/></span>
				                      </xsl:if>
									  <xsl:if test="//labResult/FastingInfo">
			                      	 	 <br /> Fasting Info: <span class="black"><xsl:value-of select="//labResult/FastingInfo"/></span>
				                      </xsl:if>								    
			                      </td>
			                      <td width="33.3%" class="med-text nopadding grey">Lab Ref ID: <span class="black"><xsl:value-of select="//labResult/labrefID"/></span>
				                      <xsl:if test="//labResult/labCollDescription != ''">
				                      	<br /> Specimen Description: <span class="black"><xsl:value-of select="//labResult/labCollDescription"/></span>
				                      </xsl:if>
									  <xsl:if test="//labResult/collVolume and //labResult/collVolume != ''">
										  <br /> Collection Volume: <span class="black"><xsl:value-of select="//labResult/collVolume"/></span>
									  </xsl:if>
			                      </td>
			                      <td class="med-text nopadding grey">
			                      	  <xsl:if test="//labResult/clinicalInfo">Clinical Info: <span class="black"><xsl:value-of select="//labResult/clinicalInfo"/></span></xsl:if>
			                     	 <xsl:if test="//labResult/VisitType">
				                      	<br /> Visit Type: <span class="black"><xsl:value-of select="//labResult/VisitType"/></span>
				                      </xsl:if>
			                      </td>
	                   		</tr>
                    	</table>
                    </div>
                    <div class="blue-border-bottom"></div>
                </td>
            </tr>
			<tr>
				<td colspan="2" class="nopadding">
					<div class="padded20-all grey-borderd">
						<table class="table blue-table">
							<tr>
								<td width="33.3%" class="med-text nopadding grey padd-print">
									<p>Order Date:&#160;<span class="black"><xsl:value-of select="//labResult/orderDate"/></span></p>
									<p>Coll. Date:&#160;<span class="black"><xsl:value-of select="//labResult/collDate"/>&#160;<xsl:value-of select="//labResult/colltime"/></span></p>
								</td>
								<td width="33.3%" class="med-text nopadding grey padd-print">
									<xsl:if test="//labResult/repDate">
										<p>Result Recd:&#160;<span class="black"><xsl:value-of select="//labResult/repDate"/>
										<xsl:if test="//labResult/repTime">&#160;<xsl:value-of select="//labResult/repTime"/>
										</xsl:if>
										</span></p>
									</xsl:if>
									<xsl:if test="//labResult/HL7ResultDate">
										<p>Report:&#160;<span class="black"><xsl:value-of select="//labResult/HL7ResultDate"/>
										<xsl:if test="//labResult/HL7ResultTime">
											&#160;<xsl:value-of select="//labResult/HL7ResultTime"/>
										</xsl:if>
										</span></p>
									</xsl:if>
								</td>
								<td class="med-text nopadding grey padd-print">
									<xsl:if test="//labResult/observationDate">
										<p>Observation:&#160;<span class="black"><xsl:value-of select="//labResult/observationDate"/>
											<xsl:if test="//labResult/observationTime">
												&#160;<xsl:value-of select="//labResult/observationTime"/>
											</xsl:if>
										</span></p>
									</xsl:if>
									<xsl:if test="//labResult/SpecimenRecDate">
										<p>Spec Recd:&#160;<span class="black"><xsl:value-of select="//labResult/SpecimenRecDate"/>
										<xsl:if test="//labResult/SpecimenRecTime">
											&#160;<xsl:value-of select="//labResult/SpecimenRecTime"/>
										</xsl:if>
										</span></p>
									</xsl:if>
								</td>
							</tr>
						</table>
					</div>
				</td>
			</tr>
            <tr>
				<td colspan="2" class="nopadding">
					<div class="padded20-side">
						<table class="table blue-table">
							<tr>
								<td width="33.3%" class="med-text nopadding lh30 grey">
									<xsl:choose>
										<xsl:when test="//labResult/ReqProvCaption"><xsl:value-of select="//labResult/ReqProvCaption"/>:&#160;</xsl:when>
										<xsl:otherwise> Requesting Physician:&#160;</xsl:otherwise>
									</xsl:choose>
									<span class="black"><xsl:value-of select="//labResult/doctor"/></span>
								</td>
								<td width="33.3%" class="med-text nopadding lh30 grey">
									<xsl:if test="//labResult/refPhy">
									<xsl:choose>
										<xsl:when test="//labResult/ordProvCaption"><xsl:value-of select="//labResult/ordProvCaption"/>:&#160;</xsl:when>
										<xsl:otherwise> Ordering Physician:&#160;</xsl:otherwise>
									</xsl:choose>
									<span class="black"> <xsl:value-of select="//labResult/refPhy"/></span>
									</xsl:if>
								</td>
								<td class="med-text nopadding lh30 grey">
									<xsl:if test="//labResult/CCDoctors"> Copied:
									<span class="black"><xsl:value-of select="//labResult/CCDoctors"/></span></xsl:if>
								</td>
							</tr>
						</table>
					</div>
					<div class="blue-border-bottom"></div>
				</td>
			</tr>
			<tr>
				<td colspan="2" class="nopadding">
					<div class="pad-bot30"></div>
					<div class="clear"></div>
				</td>
			</tr>
			<tr>
                <td colspan="2" class="nopadding">
                	<table class="table  blue-table">
                    	<tr>
                        	<td class="nopadding padd-print">
                            	<h3 class="blue"><xsl:value-of select="//labResult/labName"/></h3>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
				<td colspan="2" class="nopadding">
					<table class="table main-table">
						<thead>
							<xsl:for-each select="//labResult/resultInfo/ReferenceRange">
							</xsl:for-each>
							  <tr>
								<xsl:if test="not(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')">	<!-- no ref range -->
									<th width="2%" class="med-text"></th>
									<xsl:choose>
									<xsl:when test="//labResult/resultInfo/LabName/NamePresent">
										<xsl:if test="(//labResult/resultInfo/LabName/NamePresent='true')">
											<xsl:choose>
											<xsl:when test="((//return/labResult/labResultStatus = 'C' or //return/labResult/labResultStatus = 'Corrected') and //labResult/resultInfo/LabName/NamePresent='true') and not(//labResult/resultInfo/correctedValue/correctedValuePresent='true') and not(//labResult/resultInfo/PerformingSite/PerformingSitePresent='true')">
												<th width="50%" class="med-text">NAME</th>
											<th width="50%" class="med-text">VALUE</th>
											</xsl:when>
											<xsl:otherwise>
												<th width="35%" class="med-text">NAME</th>
												<th width="45%" class="med-text">VALUE</th>
											</xsl:otherwise>
											</xsl:choose>
										</xsl:if>
										<xsl:if test="not(//labResult/resultInfo/LabName/NamePresent='true')">
											<th width="7%" class="med-text">NAME</th>
											<th width="65%" class="med-text">VALUE</th>
										</xsl:if>
									</xsl:when>
									<xsl:otherwise>
										<th width="28%" class="med-text">NAME</th>
										<th width="70%" class="med-text">VALUE</th>
									</xsl:otherwise>
									</xsl:choose>
									<xsl:if test="//labResult/resultInfo/correctedValue/correctedValuePresent='true'">
										<th width="15%" class="med-text">PREV VALUE</th>
									</xsl:if>
									<xsl:if test="//labResult/resultInfo/PerformingSite/PerformingSitePresent='true'">
										<th width="8%" class="med-text">LAB</th>
									</xsl:if>
								</xsl:if>
								<xsl:if test="(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')">	<!-- ref range present-->
									<th width="2%" class="med-text"></th>
									<xsl:choose>
									<xsl:when test="//labResult/resultInfo/LabName/NamePresent">
										<xsl:if test="(//labResult/resultInfo/LabName/NamePresent='true')">
											<xsl:choose>
											<xsl:when test="((//return/labResult/labResultStatus = 'C' or //return/labResult/labResultStatus = 'Corrected') and //labResult/resultInfo/LabName/NamePresent='true') and not(//labResult/resultInfo/correctedValue/correctedValuePresent='true') and not(//labResult/resultInfo/PerformingSite/PerformingSitePresent='true')">
												<th width="30%" class="med-text">NAME</th>
												<th width="33%" class="med-text">VALUE</th>
											</xsl:when>
											<xsl:otherwise>
												<th width="20%" class="med-text">NAME</th>
											<th width="20%" class="med-text">VALUE</th>
											</xsl:otherwise>
											</xsl:choose>
										</xsl:if>
										<xsl:if test="not(//labResult/resultInfo/LabName/NamePresent='true')">
											<th width="8%" class="med-text">NAME</th>
											<th width="70%" class="med-text">VALUE</th>
										</xsl:if>
									</xsl:when>
									<xsl:otherwise>
										<th width="28%" class="med-text">NAME</th>
										<th width="30%" class="med-text">VALUE</th>
									</xsl:otherwise>
									</xsl:choose>
									<xsl:if test="//labResult/resultInfo/correctedValue/correctedValuePresent='true'">
										<th width="15%" class="med-text">PREV VALUE</th>
									</xsl:if>
									<xsl:choose>
										<xsl:when test="((//return/labResult/labResultStatus = 'C' or //return/labResult/labResultStatus = 'Corrected') and //labResult/resultInfo/LabName/NamePresent='true') and not(//labResult/resultInfo/correctedValue/correctedValuePresent='true') and not(//labResult/resultInfo/PerformingSite/PerformingSitePresent='true')">
											<th width="33%" class="med-text">REFERENCE RANGE</th>
										</xsl:when>
										<xsl:otherwise>
									<th width="28%" class="med-text">REFERENCE RANGE</th>
										</xsl:otherwise>
									</xsl:choose>
									<xsl:if test="//labResult/resultInfo/PerformingSite/PerformingSitePresent='true'">
										<th width="8%" class="med-text">LAB</th>
									</xsl:if>
								</xsl:if>
							</tr>
						</thead>
						<tbody class="print-labpp-table">
							<xsl:for-each select="//labResult/resultInfo/resultParams/resultParam">
								<xsl:variable name="bold-class"><xsl:if test="Highlight">bold</xsl:if></xsl:variable>
								<xsl:variable name="red-class"><xsl:if test="Highlight">red</xsl:if></xsl:variable>
							
								<tr class="{$bold-class}">
								 <xsl:if test="not(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')">
								 	<td class="NoRefRangePresentClass">
										<xsl:choose>
											<xsl:when test="ComponentResultStatus= 'PRELIM'">P</xsl:when>
								            <xsl:when test="ComponentResultStatus= 'FINAL'">F</xsl:when>
											<xsl:when test="ComponentResultStatus"><xsl:value-of select="ComponentResultStatus" /></xsl:when>
										    <xsl:otherwise></xsl:otherwise>
									 	</xsl:choose>
									</td>
									<xsl:choose>
										<xsl:when test="value='**********************CORRECTED REPORT **********************'">
											<td colspan="4"  class="NoRefRangePresentClass"><center><xsl:value-of select="value"/></center></td>
										</xsl:when>
										<xsl:otherwise>
											<td class="{$red-class} NoRefRangePresentClass">
												<xsl:value-of select="name"/>
											</td>
											<td class="{$red-class} NoRefRangePresentClass">
												&#160;<pre   class="{$red-class} prewordwrap3wrap"><xsl:value-of select="value"/></pre>
													&#160;<span class="{$red-class}"><xsl:value-of select="units"/></span>
												<xsl:if test="flag and not((translate(flag, $smallcase, $uppercase) = 'N' or translate(flag, $smallcase, $uppercase) = 'NORMAL'))">
													&#160;<span class="{$red-class}"><xsl:value-of select="flag"/></span>
												</xsl:if>
											</td>
											<xsl:if test="//labResult/resultInfo/correctedValue/correctedValuePresent='true'">
												<td class="NoRefRangePresentClass"><pre class="prewordwrap2wrap"><xsl:value-of select="prevvalue"/></pre></td>
											</xsl:if>
											<xsl:if test="//labResult/resultInfo/PerformingSite/PerformingSitePresent='true'">
												<td class="{$red-class} NoRefRangePresentClass">&#160;&#160;<xsl:value-of select="PerformingSiteId"/></td>
											</xsl:if>
										</xsl:otherwise>
									</xsl:choose>
									<xsl:for-each select="paramNote">
										<xsl:choose>
											<xsl:when test="notes='@#$%$#@'"><tr><td colspan="3" class="no-bod"><pre><br/></pre></td></tr></xsl:when>
											<xsl:when test="notes != ''">
												<tr><td colspan="3" class="no-bod">
													<p class="wordWrapLabNotes"><pre class="prewordwrap2wrap"><xsl:value-of select="notes"/></pre></p>
												</td></tr>
											</xsl:when>
										</xsl:choose>
									</xsl:for-each>
								</xsl:if>
									
									
								 <xsl:if test="(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')">
									<td>
										<xsl:choose>
											<xsl:when test="ComponentResultStatus= 'PRELIM'">P</xsl:when>
								            <xsl:when test="ComponentResultStatus= 'FINAL'">F</xsl:when>
											<xsl:when test="ComponentResultStatus"><xsl:value-of select="ComponentResultStatus" /></xsl:when>
										    <xsl:otherwise></xsl:otherwise>
									 	</xsl:choose>
									</td>
									<xsl:choose>
										<xsl:when test="value='**********************CORRECTED REPORT **********************'">
											<td colspan="4"><center><xsl:value-of select="value"/></center></td>
										</xsl:when>
										<xsl:otherwise>
											<td class="{$red-class}">
												<xsl:value-of select="name"/>
											</td>
											<td class="{$red-class}">
												&#160;<xsl:if test="not(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')"><pre class="{$red-class} prewordwrap5"><xsl:value-of select="value"/></pre></xsl:if>
												<xsl:if test="(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')"><pre class="{$red-class} prewordwrap5"><xsl:value-of select="value"/></pre></xsl:if>
												<xsl:if test="not(//labResult/resultInfo/ReferenceRange/RefRangePresent='true')">
													&#160;<span class="{$red-class}"><xsl:value-of select="units"/></span>
												</xsl:if>
												<xsl:if test="flag">
													&#160;
													<xsl:if test="Highlight">
														<span class=" {$red-class} box-pad"><xsl:value-of select="flag"/></span>
													</xsl:if>
													<xsl:if test="not(Highlight)">
														<span><xsl:value-of select="flag"/></span>
													</xsl:if>
												</xsl:if>
											</td>
											<xsl:if test="//labResult/resultInfo/correctedValue/correctedValuePresent='true'">
												<td><pre class="prewordwrap2wrap">&#160;<xsl:value-of select="prevvalue"/></pre></td>
											</xsl:if>
											<xsl:if test="//labResult/resultInfo/ReferenceRange/RefRangePresent='true'">
												<td class="{$red-class}">&#160;<xsl:value-of select="range"/>&#160;<xsl:value-of select="units"/></td>
											</xsl:if>
											<xsl:if test="//labResult/resultInfo/PerformingSite/PerformingSitePresent='true'">
												<td class="{$red-class}">&#160;&#160;<xsl:value-of select="PerformingSiteId"/></td>
											</xsl:if>
										</xsl:otherwise>
									</xsl:choose>
									<xsl:for-each select="paramNote">
										<xsl:choose>
											<xsl:when test="notes='@#$%$#@'"><tr><td colspan="4" class="no-bod"></td></tr></xsl:when>
											<xsl:when test="notes != ''">
												<tr><td colspan="4" class="no-bod">
													<p  class="wordWrapLabNotes"><pre class="prewordwrap2wrap"><xsl:value-of select="notes"/></pre></p>
												</td></tr>
											</xsl:when>
										</xsl:choose>
									</xsl:for-each>
								 </xsl:if>
								</tr>
								
								
								
								<xsl:if test="//labResult/resultInfo/AppendCorrReport/AppendCorrReportPresent='true'">
									<xsl:for-each select="HeaderNote">
										<xsl:choose>
											<xsl:when test="headernotes='@#$%$#@'">
												<tr><td colspan="4"><strong>Additional Notes</strong></td></tr>
											</xsl:when>
										</xsl:choose>
									</xsl:for-each>
									<xsl:for-each select="OBRparamNote">
										<xsl:choose>
											<xsl:when test="OBRnotes='@#$%$#@'"><tr><td colspan="4"><br/></td></tr></xsl:when>
											<xsl:otherwise>
												<p><tr><td colspan="4"><p class="wordWrapLabNotes"><pre class="prewordwrap2wrap"><strong>- </strong><xsl:value-of select="OBRnotes"/></pre></p></td></tr></p>
											</xsl:otherwise>
										</xsl:choose>
									</xsl:for-each>
									</xsl:if>
							</xsl:for-each>
						</tbody>
					</table>
				</td>
			</tr>
			<xsl:if test="not(//labResult/resultInfo/AppendCorrReport/AppendCorrReportPresent='true')">
	            <tr>
		            <xsl:for-each select="//labResult/resultInfo/resultParams/resultNote">
		            <xsl:if test="notes != ''">
		            	<tr>
		            		<td colspan="2" class="sections no-bot-border med-text">
								<xsl:choose>
									<xsl:when test="notes='@#$%$#@'"> <br/></xsl:when>
									<xsl:otherwise><pre style="white-space:pre-wrap;"><xsl:value-of select="notes"/></pre></xsl:otherwise>
								</xsl:choose>
							</td>
						</tr>
					</xsl:if>
					</xsl:for-each>
	            </tr>          
            </xsl:if>
            
            <xsl:if test="//labResult/EmbeddedPDF">
				<tr><td colspan="2" class="sections no-bot-border med-text"><xsl:value-of select="//labResult/EmbeddedPDF" /></td></tr>
			</xsl:if>
	
			<xsl:if test="//labResult/Disclaimer">
				<tr><td colspan="2" class="sections no-bot-border med-text"><xsl:value-of select="//labResult/Disclaimer"/></td></tr>
			</xsl:if>
            
		<xsl:if test="//labResult/URLLink">
			<tr class="noPrint"><td colspan="2" class="sections no-bot-border med-text">
				<xsl:variable name="link"><xsl:value-of select="//labResult/URLLink"/></xsl:variable>
				<xsl:variable name="openNewBrowser"><xsl:if test="//labResult/URLLinkInNewBrowserWindow">_blank</xsl:if></xsl:variable>
				<a href="{$link}" target="{$openNewBrowser}" ><B><FONT COLOR="Blue"><xsl:value-of select="//labResult/URLLinkCaption"/></FONT></B></a>
			</td></tr>
		</xsl:if>

 		</tbody>
            <tfoot>
            	<tr>
                	<td colspan="2" class="nopadding">
                    	<table class="table blue-bg footer">
                        	<tr>
                            	<td class="med-text"><xsl:value-of select="$patientidentifier" /></td>
                                <td class="med-text"><div class="text-right">Accession ID: <xsl:value-of select="//labResult/SessionId" /></div></td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tfoot>
            </xsl:when>
          	 <xsl:otherwise> 
          		<xsl:variable name="invalidHl7Message" select="translate(//return/labResult/invalidHl7Message, $newLineXML ,$newLine)"/>
          	
 
          	 	<thead>
          	 		<table class="table  blue-table">
          	 			<tr>
							<td colspan="2" class="nopadding">
								<div class="pad-bot30"></div>
								<div class="clear"></div>
							</td>
						</tr>
                    	<tr>
                        	<td class="nopadding padd-print">
                            	<h3 class="blue"><xsl:value-of select="'Invalid HL7 Message'"/></h3>
                            </td>
                        </tr>
                        
                    </table>
                    
          	 	</thead>
				<tbody>
				<table class="table main-table" id="disclaimer">
						<thead>
							<tr>
								<th width="100%" class="med-text"></th>
							</tr>						
						</thead>
						<tbody class="print-labpp-table">
							<tr>
								<xsl:if test="translate(//return/labResult/Disclaimer, $newLineXML ,$newLine)">
									<td id="InvalidMessageNote" colspan="2" class="sections no-bot-border med-text "><pre class="prewordwrap"><span style ="font-weight:bold;" class= "red">Note: </span><xsl:value-of select="//return/labResult/Disclaimer"/></pre></td>
								</xsl:if>
							</tr>
							<tr>
								<td colspan="2" class="nopadding">
									<div class="pad-bot30"></div>
									<div class="clear"></div>
								</td>
							</tr>
							<tr>
								<xsl:if test="//return/labResult/unsupportedCharacters">
									<td colspan="2" class="sections no-bot-border med-text">
										<pre class ="prewordwrap unsupported-characters"><xsl:value-of select="//return/labResult/unsupportedCharacters" /></pre>
									</td>
								</xsl:if>
							</tr>
							<tr>
            					<xsl:if test="//return/labResult/invalidHl7Message">
									<td colspan="2" class="sections no-bot-border med-text">
										<pre class ="prewordwrap"><xsl:value-of select="$invalidHl7Message" /></pre>
									</td>
								</xsl:if>
							</tr>
							<tr>
								<td colspan="2" class="nopadding">
									<div class="pad-bot30"></div>
									<div class="clear"></div>
								</td>
							</tr>
							
						</tbody>
					</table>
            	</tbody>
			</xsl:otherwise> 
	</xsl:choose>  
        </table>

		</div>
</BODY>
</HTML>
</xsl:template>
</xsl:stylesheet>`;
