import {
  Box,
  Button,
  Checkbox,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  type GetCumulativeReportQuery,
  useGetProviderEncounterDetailsQuery,
} from '../../../../__generated__/graphql';
import { type OrderableItemUI } from '../../../../types';

interface Props {
  newOrders: OrderableItemUI[];
  editNewOrders: (newOrders: OrderableItemUI[]) => void;
  encounterId: string;
}

export function LabDIOrdersTable({ encounterId, newOrders, editNewOrders }: Props) {
  const { data } = useGetProviderEncounterDetailsQuery({
    skip: !encounterId,
    variables: {
      encounterId: Number(encounterId),
    },
  });

  if (!data?.getProviderEncounterDetails?.items?.length && !newOrders?.length) return <></>;

  return (
    <Box>
      <Text fontSize='lg' as='b'>
        Lab/DI Orders
      </Text>
      <Table width='100%'>
        <Thead fontWeight='semibold'>
          <Tr>
            <Td> Date </Td>
            <Td> Name </Td>
            <Td> Reason </Td>
            <Td width='45%' />
          </Tr>
        </Thead>

        <Tbody>
          {newOrders.map((o, i) => (
            <NewItemRow
              key={i}
              row={o}
              edit={(order) => {
                const newOrdersCopy = [...newOrders];
                newOrdersCopy[i] = order;
                editNewOrders(newOrdersCopy);
              }}
              remove={() => {
                const newOrdersCopy = [...newOrders];
                newOrdersCopy.splice(i, 1);
                editNewOrders(newOrdersCopy);
              }}
            />
          ))}
          {data?.getProviderEncounterDetails?.items?.map((o, i) => (
            <ExtantItemRow key={i} row={o} />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

const NewItemRow = ({
  row,
  edit,
  remove,
}: {
  row: OrderableItemUI;
  edit: (o: OrderableItemUI) => void;
  remove: () => void;
}) => {
  return (
    <Tr>
      <Td>
        <Text as='b'>{new Date(row.orderDate ?? Date.now()).toLocaleDateString()}</Text>
      </Td>
      <Td>{row.orderableItemName}</Td>
      <Td>{row.reason}</Td>
      <Td>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Stack spacing={2} direction='row'>
            <Text fontSize='xs'>High Priority</Text>

            <Checkbox
              onChange={() => {
                edit({
                  ...row,
                  highPriority: !row.highPriority,
                });
              }}
              colorScheme='brand'
              size='lg'
              isChecked={!!row.highPriority}
            />
          </Stack>

          <Button onClick={remove} variant='ghost' marginLeft='12px'>
            Remove
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

const ExtantItemRow = ({ row }: { row: GetCumulativeReportQuery['getCumulativeReport'][0] }) => {
  return (
    <Tr>
      <Td>
        <Text as='b'>{new Date(row.orderDate).toLocaleDateString()}</Text>
      </Td>
      <Td>{row.orderableItemName}</Td>
      <Td>{row.reason}</Td>
      <Td>
        <Flex justifyContent='flex-end' alignItems='center'>
          <Stack spacing={2} direction='row'>
            <Text fontSize='xs'>High Priority</Text>

            <Checkbox disabled colorScheme='brand' size='lg' isChecked={!!row.highPriority} />
          </Stack>
        </Flex>
      </Td>
    </Tr>
  );
};
