export function getProviders() {
  return {
    providers: [
      {
        providerName: 'Adekemi Ishola, MD',
        providerID: 632600,
      },
      {
        providerName: 'Aesha Patel, MD',
        providerID: 600682,
      },
      {
        providerName: 'Angela Cain, FNP-C',
        providerID: 608904,
      },
      {
        providerName: 'Anna Richardson, FNP-BC',
        providerID: 626451,
      },
      {
        providerName: 'Arshiya Momin, FNP-C',
        providerID: 588642,
      },
      {
        providerName: 'Audris Pinkerton, MD',
        providerID: 621690,
      },
      {
        providerName: 'Bradley Gaydos, FNP-C',
        providerID: 623355,
      },
      {
        providerName: 'Chiamaka Ngwudike, MD',
        providerID: 628300,
      },
      {
        providerName: 'Christina Price-Jackson, DO',
        providerID: 533361,
      },
      {
        providerName: 'Claudine Caesar, FNP-C',
        providerID: 493621,
      },
      {
        providerName: 'Cynthia Richardson, APRN, FNP-C',
        providerID: 9176,
      },
      {
        providerName: 'Dagmawit Mekuria, MD',
        providerID: 635525,
      },
      {
        providerName: 'DaKoyoia Billie, FNP-C',
        providerID: 613077,
      },
      {
        providerName: 'Derquis Johnson',
        providerID: 645428,
      },
      {
        providerName: 'Evelyn Redding, APRN, FNP-C',
        providerID: 9156,
      },
      {
        providerName: 'Gregory Lawson, MD',
        providerID: 647853,
      },
      {
        providerName: 'Heather Cameron',
        providerID: 567806,
      },
      {
        providerName: 'Immanuel Hsu, , DO',
        providerID: 568567,
      },
      {
        providerName: 'Jennifer Thompson, APRN, FNP-C',
        providerID: 9170,
      },
      {
        providerName: 'Jodi Pesce, FNP-C',
        providerID: 564428,
      },
      {
        providerName: 'Karen Richard-Reynolds, APRN, FNP-C',
        providerID: 9134,
      },
      {
        providerName: 'Kathryn Murphy, FNP-C',
        providerID: 499356,
      },
      {
        providerName: 'Kristin Lewis, MD',
        providerID: 565960,
      },
      {
        providerName: 'Latora Williams, MD',
        providerID: 646749,
      },
      {
        providerName: 'Latosha Hobbs, FNP-C',
        providerID: 615262,
      },
      {
        providerName: 'Leslie Chiang, DO',
        providerID: 619010,
      },
      {
        providerName: 'Liliana Llopart Herrera',
        providerID: 638244,
      },
      {
        providerName: 'Lily Phan, FNP-C',
        providerID: 569374,
      },
      {
        providerName: 'Lindsey Lavin, FNP-C',
        providerID: 573955,
      },
      {
        providerName: 'Malinda Conrad, FNP-BC',
        providerID: 636693,
      },
      {
        providerName: 'Maloma Greene, FNP-C',
        providerID: 642693,
      },
      {
        providerName: 'Marjorie Sewannyana, FNP-C',
        providerID: 634758,
      },
      {
        providerName: 'Melissa Perrin-Hernandez, MD',
        providerID: 532476,
      },
      {
        providerName: 'Michael Avidano, MD',
        providerID: 503447,
      },
      {
        providerName: 'Michelle Bush, FNP-C',
        providerID: 469684,
      },
      {
        providerName: 'Mitzi Clayton, MD',
        providerID: 9128,
      },
      {
        providerName: 'Monika Bartoli, FNP-C',
        providerID: 537224,
      },
      {
        providerName: 'Nadine Opong, FNP-C',
        providerID: 640316,
      },
      {
        providerName: 'Nancy Bellinger, FNP-C',
        providerID: 619613,
      },
      {
        providerName: 'Neda Jackson Lawson, FNP-C',
        providerID: 633926,
      },
      {
        providerName: 'Philomise Moncion, MD',
        providerID: 619469,
      },
      {
        providerName: 'Priya Gulati Shah, MD',
        providerID: 506468,
      },
      {
        providerName: 'Provisions, JoeTest',
        providerID: 649053,
      },
      {
        providerName: 'Provisions, Test',
        providerID: 649054,
      },
      {
        providerName: 'Raquel D. Parker, FNP-C',
        providerID: 632301,
      },
      {
        providerName: 'Regan Frake, MD',
        providerID: 570319,
      },
      {
        providerName: 'Rhonda Cartagena, FNP-C',
        providerID: 627559,
      },
      {
        providerName: 'Rosalynn Comer, FNP-C',
        providerID: 636669,
      },
      {
        providerName: 'Shehnaz Makhani, MD',
        providerID: 417150,
      },
      {
        providerName: 'Tamaria Dunn, FNP-C',
        providerID: 646657,
      },
      {
        providerName: 'Tamaria Dunn, FNP-C',
        providerID: 427186,
      },
      {
        providerName: 'Tami Hall, FNP-BC',
        providerID: 614076,
      },
      {
        providerName: 'Testing, Upsert',
        providerID: 649055,
      },
      {
        providerName: 'Wanda Mcdaniel, APRN, FNP-C',
        providerID: 9137,
      },
      {
        providerName: 'Xiaohui Brotzge, PA-C',
        providerID: 612193,
      },
    ],
  };
}

export function getStaff() {
  return {
    staff: [
      {
        staffName: 'Adams, Debra',
        staffId: 590618,
      },
      {
        staffName: 'Admins, IT',
        staffId: 527554,
      },
      {
        staffName: 'Akunji, Akiekum',
        staffId: 632118,
      },
      {
        staffName: 'Aldridge, Darcel',
        staffId: 579114,
      },
      {
        staffName: 'Alexander, LaDream',
        staffId: 605664,
      },
      {
        staffName: 'Alfaro, Jasmine',
        staffId: 615949,
      },
      {
        staffName: 'Allen, April',
        staffId: 635481,
      },
      {
        staffName: 'Allen, Clarice',
        staffId: 614284,
      },
      {
        staffName: 'Allen, Diedrea',
        staffId: 593509,
      },
      {
        staffName: 'Allen, Michelle',
        staffId: 642062,
      },
      {
        staffName: 'Allread, Emma',
        staffId: 645978,
      },
      {
        staffName: 'Anderson, Carla',
        staffId: 457610,
      },
      {
        staffName: 'Anderson, Michelle',
        staffId: 207586,
      },
      {
        staffName: 'Anderson, Paula',
        staffId: 559135,
      },
      {
        staffName: 'Anglin, Elizabeth',
        staffId: 579054,
      },
      {
        staffName: 'Arnold, Kendra',
        staffId: 636548,
      },
      {
        staffName: 'Ashford, Serrina',
        staffId: 648662,
      },
      {
        staffName: 'Bailey, Tracy',
        staffId: 496877,
      },
      {
        staffName: 'Baker, Jonea',
        staffId: 605665,
      },
      {
        staffName: 'Ball, Tyeshia',
        staffId: 639706,
      },
      {
        staffName: 'Banks, Ashleigh',
        staffId: 583176,
      },
      {
        staffName: 'Banner, Trakashia',
        staffId: 627953,
      },
      {
        staffName: 'Barlow, Brittny',
        staffId: 562191,
      },
      {
        staffName: 'Beal, Jenia',
        staffId: 476896,
      },
      {
        staffName: 'Bell, Tiffany',
        staffId: 648667,
      },
      {
        staffName: 'Bennett, Michelle',
        staffId: 603962,
      },
      {
        staffName: 'Benson, Esther',
        staffId: 648746,
      },
      {
        staffName: 'Berry, Casey',
        staffId: 642525,
      },
      {
        staffName: 'Billingsley, Crystal',
        staffId: 603481,
      },
      {
        staffName: 'Binion, Lamika',
        staffId: 636540,
      },
      {
        staffName: 'Binns, Janierra',
        staffId: 638433,
      },
      {
        staffName: 'Biopsy, Orders',
        staffId: 507213,
      },
      {
        staffName: 'Black, Katie',
        staffId: 564782,
      },
      {
        staffName: 'Blanchard, Lindsay',
        staffId: 604900,
      },
      {
        staffName: 'Blanks, Nikki',
        staffId: 644284,
      },
      {
        staffName: 'Blanton, Shanay',
        staffId: 412274,
      },
      {
        staffName: 'Bonner, Benigheon',
        staffId: 612290,
      },
      {
        staffName: 'Boone, Alexis',
        staffId: 647975,
      },
      {
        staffName: 'Borders, Denise',
        staffId: 516026,
      },
      {
        staffName: 'Bowie, Chessica',
        staffId: 615502,
      },
      {
        staffName: 'Bragg, Damaris',
        staffId: 590940,
      },
      {
        staffName: 'Branch, Kathryn',
        staffId: 598816,
      },
      {
        staffName: 'Brannon, Christy',
        staffId: 440167,
      },
      {
        staffName: 'Brewer, Whitney',
        staffId: 411243,
      },
      {
        staffName: 'Brewster, Samantha',
        staffId: 637057,
      },
      {
        staffName: 'Brewster, Zoe',
        staffId: 636542,
      },
      {
        staffName: 'Brewton, Yvonne',
        staffId: 632439,
      },
      {
        staffName: 'Brooks, Ramona',
        staffId: 622173,
      },
      {
        staffName: 'Browning, Abigail',
        staffId: 626640,
      },
      {
        staffName: 'Brown, Jamecia',
        staffId: 633512,
      },
      {
        staffName: 'Brown, Julie',
        staffId: 636609,
      },
      {
        staffName: 'Brown, LeonDriah',
        staffId: 615162,
      },
      {
        staffName: 'Brown, Shantell',
        staffId: 630769,
      },
      {
        staffName: 'Brown, Shauntae',
        staffId: 644459,
      },
      {
        staffName: 'Brown, Tamara',
        staffId: 522789,
      },
      {
        staffName: 'Bruce, Kali',
        staffId: 594666,
      },
      {
        staffName: 'Bryant, Aniya',
        staffId: 582274,
      },
      {
        staffName: 'Buchanan, Jamia',
        staffId: 645784,
      },
      {
        staffName: 'Burdick, Liz',
        staffId: 207676,
      },
      {
        staffName: 'Burmingham, Tusonah',
        staffId: 454251,
      },
      {
        staffName: 'Burns, Kimberly',
        staffId: 637276,
      },
      {
        staffName: 'Cain, Angela',
        staffId: 611934,
      },
      {
        staffName: 'Cain, Missy',
        staffId: 207688,
      },
      {
        staffName: 'Callahan, Hannah',
        staffId: 508139,
      },
      {
        staffName: 'Callahan, Tamra',
        staffId: 579067,
      },
      {
        staffName: 'Camarena, Azumi',
        staffId: 586544,
      },
      {
        staffName: 'Campbell, Danielle',
        staffId: 416114,
      },
      {
        staffName: 'Capps-Cowan, Alison',
        staffId: 630332,
      },
      {
        staffName: 'Carlton, Ronda',
        staffId: 543459,
      },
      {
        staffName: 'Carmon, Tamika',
        staffId: 644674,
      },
      {
        staffName: 'Carpenter, Tiawan',
        staffId: 519374,
      },
      {
        staffName: 'Carter, Jazmyn',
        staffId: 648665,
      },
      {
        staffName: 'Cason, Troy',
        staffId: 615950,
      },
      {
        staffName: 'Castro, Isabel',
        staffId: 588701,
      },
      {
        staffName: 'Cave, Jessica',
        staffId: 621234,
      },
      {
        staffName: 'Chambers, Christopher',
        staffId: 615516,
      },
      {
        staffName: 'Chambers, Ketra',
        staffId: 615435,
      },
      {
        staffName: 'Chancey, Rachel',
        staffId: 207722,
      },
      {
        staffName: 'Chandra, Ravi',
        staffId: 596925,
      },
      {
        staffName: 'Chaney, Terrilyn',
        staffId: 630333,
      },
      {
        staffName: 'Chapman, Teresa',
        staffId: 207767,
      },
      {
        staffName: 'Chart, Span',
        staffId: 463888,
      },
      {
        staffName: 'Chartspan - Callum, Latecia',
        staffId: 634196,
      },
      {
        staffName: 'Chartspan - Champion, Meg',
        staffId: 634199,
      },
      {
        staffName: 'Chartspan - Gilliland, Michelle',
        staffId: 634198,
      },
      {
        staffName: 'Chartspan - Holley Roberts, Melody',
        staffId: 634194,
      },
      {
        staffName: 'Chartspan - Whitaker, Danelle',
        staffId: 629643,
      },
      {
        staffName: 'Cheatham, Deanna',
        staffId: 207654,
      },
      {
        staffName: 'Check, Out',
        staffId: 404000,
      },
      {
        staffName: 'Chestnut, Qima',
        staffId: 462445,
      },
      {
        staffName: 'Choi, Brian',
        staffId: 605028,
      },
      {
        staffName: 'Chovdurov, Baburbek',
        staffId: 616391,
      },
      {
        staffName: 'Chrisholm, Nequvar',
        staffId: 484860,
      },
      {
        staffName: 'Chrisholm, Shannon',
        staffId: 561115,
      },
      {
        staffName: 'Chugani, Shirley',
        staffId: 645966,
      },
      {
        staffName: 'Cianciaolo, Daniel',
        staffId: 634285,
      },
      {
        staffName: 'Clark, Gabrielle',
        staffId: 508259,
      },
      {
        staffName: 'Clark, Morgan',
        staffId: 594665,
      },
      {
        staffName: 'Clark (OBC), Kimberly',
        staffId: 507398,
      },
      {
        staffName: 'Clark, Tanisha',
        staffId: 648368,
      },
      {
        staffName: 'Clayton, Kenyada',
        staffId: 625818,
      },
      {
        staffName: 'Cobb, Kerry',
        staffId: 502720,
      },
      {
        staffName: 'Colbert, Jamisha',
        staffId: 632936,
      },
      {
        staffName: 'Coleman, Brittany',
        staffId: 630766,
      },
      {
        staffName: 'Collier, Emily',
        staffId: 564415,
      },
      {
        staffName: 'Collins, Katilin',
        staffId: 603478,
      },
      {
        staffName: 'Collins, Shawntra',
        staffId: 645396,
      },
      {
        staffName: 'Conley, Keira',
        staffId: 589238,
      },
      {
        staffName: 'Cooks, Alayzia',
        staffId: 629375,
      },
      {
        staffName: 'Cotton, Bre',
        staffId: 620823,
      },
      {
        staffName: 'Cox, Tiffany',
        staffId: 610798,
      },
      {
        staffName: 'Crane, Melissa',
        staffId: 462274,
      },
      {
        staffName: 'Crawford-Carter, Anita',
        staffId: 583866,
      },
      {
        staffName: 'Crawford, Makeba',
        staffId: 544550,
      },
      {
        staffName: 'Creasman, Kaeley',
        staffId: 629824,
      },
      {
        staffName: 'CT, Orders',
        staffId: 498802,
      },
      {
        staffName: 'Cuyler, Shakara',
        staffId: 629379,
      },
      {
        staffName: 'Damra, Philip',
        staffId: 649323,
      },
      {
        staffName: 'Danekes, Molly',
        staffId: 207689,
      },
      {
        staffName: 'Daniel, Adrienne',
        staffId: 520997,
      },
      {
        staffName: 'Danielle, Heberling',
        staffId: 649226,
      },
      {
        staffName: 'Daniels, Keisha',
        staffId: 524189,
      },
      {
        staffName: 'DASH, Radix',
        staffId: 518529,
      },
      {
        staffName: 'DataMarshall - Krishna, Sai',
        staffId: 625683,
      },
      {
        staffName: 'DataMarshall - Rawat, Laxman',
        staffId: 625685,
      },
      {
        staffName: 'Davenport, LaToya',
        staffId: 481200,
      },
      {
        staffName: 'Davis, Beth',
        staffId: 473121,
      },
      {
        staffName: 'Davis, Keisha',
        staffId: 609963,
      },
      {
        staffName: 'Davis, Kendra',
        staffId: 615161,
      },
      {
        staffName: 'Davis, Sebrina',
        staffId: 606207,
      },
      {
        staffName: 'Dean, Destiny',
        staffId: 533747,
      },
      {
        staffName: 'Dean, Guernica',
        staffId: 602817,
      },
      {
        staffName: 'DeBardelaben, Elizabeth',
        staffId: 605491,
      },
      {
        staffName: 'Deloach, Miyah',
        staffId: 634562,
      },
      {
        staffName: 'Delrosario, Chelsea',
        staffId: 612231,
      },
      {
        staffName: 'Dewalt, Jamee',
        staffId: 632933,
      },
      {
        staffName: 'Dial, Christina',
        staffId: 207741,
      },
      {
        staffName: 'Dodson, Lacey',
        staffId: 530951,
      },
      {
        staffName: 'Dodson, Treva',
        staffId: 207589,
      },
      {
        staffName: 'Doe, Patricia',
        staffId: 447521,
      },
      {
        staffName: 'Dorris, Krystal',
        staffId: 577887,
      },
      {
        staffName: 'Dorsainvill, Kaina',
        staffId: 412676,
      },
      {
        staffName: 'Douglas, Josh',
        staffId: 623367,
      },
      {
        staffName: 'Doumbia, Chebrah',
        staffId: 624734,
      },
      {
        staffName: 'Downing, Jessica',
        staffId: 626790,
      },
      {
        staffName: 'Drake, Tequila',
        staffId: 621237,
      },
      {
        staffName: 'Dryka, Jasmine',
        staffId: 625514,
      },
      {
        staffName: 'DuVall, Kasey',
        staffId: 463466,
      },
      {
        staffName: 'Easley, Sharon',
        staffId: 207724,
      },
      {
        staffName: 'Eccles, April',
        staffId: 603482,
      },
      {
        staffName: 'ECHO, Order',
        staffId: 504957,
      },
      {
        staffName: 'Ector, Corettia',
        staffId: 621231,
      },
      {
        staffName: 'Edelen, Karen',
        staffId: 648669,
      },
      {
        staffName: 'Edwards, Bridget',
        staffId: 629376,
      },
      {
        staffName: 'Edwards, Vickie',
        staffId: 207772,
      },
      {
        staffName: 'Ellwanger, Marco',
        staffId: 649321,
      },
      {
        staffName: 'Epperson, Alexis',
        staffId: 560232,
      },
      {
        staffName: 'Epps, Arlene',
        staffId: 626636,
      },
      {
        staffName: 'ERO-Behrens, Jamie',
        staffId: 600799,
      },
      {
        staffName: 'Evans, Colunda',
        staffId: 498000,
      },
      {
        staffName: 'Ezell, Brett',
        staffId: 578308,
      },
      {
        staffName: 'Falcomata, Joe',
        staffId: 524039,
      },
      {
        staffName: 'Farley, Mikayla',
        staffId: 647024,
      },
      {
        staffName: 'Farley, Olivia',
        staffId: 631029,
      },
      {
        staffName: 'Favela, Connie',
        staffId: 578528,
      },
      {
        staffName: 'Finley, Ayanna',
        staffId: 645980,
      },
      {
        staffName: 'Flekenstein, Angela',
        staffId: 648879,
      },
      {
        staffName: 'Flippo, Kristen',
        staffId: 645967,
      },
      {
        staffName: 'Flores, Mallery',
        staffId: 505041,
      },
      {
        staffName: 'Floyd, Lane',
        staffId: 618184,
      },
      {
        staffName: 'Floyd, Mary Lane',
        staffId: 618194,
      },
      {
        staffName: 'Folds, Kelly',
        staffId: 639423,
      },
      {
        staffName: 'Fonfrias, Rebecca',
        staffId: 642355,
      },
      {
        staffName: 'Forbes, Yaurishka',
        staffId: 637581,
      },
      {
        staffName: 'Forward, Jodi',
        staffId: 505596,
      },
      {
        staffName: 'Fosselman, Darienn',
        staffId: 579487,
      },
      {
        staffName: 'Foster, Ayanna',
        staffId: 601644,
      },
      {
        staffName: 'Foster, Sabrina',
        staffId: 647337,
      },
      {
        staffName: 'Foust, Devyn',
        staffId: 626792,
      },
      {
        staffName: 'Franklin, Destinee',
        staffId: 647086,
      },
      {
        staffName: 'Freeman, Amanda',
        staffId: 498772,
      },
      {
        staffName: 'Freeman, Charity',
        staffId: 626860,
      },
      {
        staffName: 'Freeman, Krista',
        staffId: 646201,
      },
      {
        staffName: 'French, Jasmine',
        staffId: 626655,
      },
      {
        staffName: 'French, Phylicia',
        staffId: 630790,
      },
      {
        staffName: 'Fronfrias, Becca',
        staffId: 636035,
      },
      {
        staffName: 'Fuerte, Leslie',
        staffId: 637303,
      },
      {
        staffName: 'Gantt, Kolita',
        staffId: 629804,
      },
      {
        staffName: 'Garrett, Jonathan',
        staffId: 582720,
      },
      {
        staffName: 'Gary, Charity',
        staffId: 648369,
      },
    ],
  };
}
