import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { AddAllergyPrompt } from './AddAllergyPrompt';

export interface AllergyModalProps {
  isEdit: boolean;
}

export function AddAllergyPromptModal(props: AllergyModalProps) {
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <AddAllergyPrompt {...props} />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
}
