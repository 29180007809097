import { uiActions, useAppDispatch } from '../../state-management';

export const useSetDirty = () => {
  const dispatch = useAppDispatch();
  const setDirty = (id: OrderFlowSteps, isDirty: boolean) => {
    dispatch(
      uiActions.setStepIsDirty({
        id,
        isDirty,
      }),
    );
  };

  return setDirty;
};
