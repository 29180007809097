export const getChiefComplaints = [
  'acid reflux',
  'acne',
  'allergic reaction',
  'asthma exacerbation',
  'attention deficit hyperactivity disorder',
  "boxer's fracture",
  'cardiac catheterization follow-up',
  'clavicle fracture',
  'cluster headache',
  'contraception',
  'contraceptive counselling',
  'cramps, stomach',
  'distractibility',
  'earache, left',
  'earache, right',
  'edema, sacral',
  'elbow fracture, left',
  'elbow fracture, right',
  'face numbness',
  'facial',
  'facial droop',
  'facial pain',
  'facial rejuvination options',
  'facial weakness',
  'finger fracture',
  'finger laceration',
  'follow-up Accutane',
  'follow-up actinic keratosis',
  'follow-up age-related macular degeneration',
  'follow-up humerus fracture',
  'follow-up retinal detachment repair',
  'foot fracture, left',
  'foot fracture, right',
  'forearm fracture',
  'foster care placement',
  'fotofacial chest',
  'fotofacial face',
  'fotofacial face and chest',
  'fotofacial face and neck',
  'fotofacial hands',
  'fotofacial neck and chest',
  'fracture',
  'fracture, compression',
  'fracture, foot [left]',
  'fracture, foot [right]',
  'fracture, humerus [left]',
  'fracture, humerus [right]',
  'fracture, pubic ramus',
  'fracture, radial head [left elbow]',
  'fracture, radial head [right elbow]',
  'fracture, sacral',
  'fracture, toe',
  'galactorrhea',
  'headache',
  'history of retinal detachment',
  'hormone replacement therapy (HRT)',
  'humerus fracture',
  'hyperactivity',
  'indwelling Broviac catheter',
  'injury, back',
  'injury, neck/back prior',
  'jejunostomy replacement',
  'laceration',
  'laceration, face',
  'laceration, liver',
  'laceration, scalp',
  'Low Back Pain',
  'macromastia',
  'Meningococcal Vaccination',
  'microdermabrasion (package)',
  'motor vehicle accident',
  'muscle aches',
  'new to practice',
  'overactive bladder',
  'pacemaker check',
  'pain, back',
  'pain, back [chronic history of]',
  'pain, back [lower]',
  'pain, back [right low]',
  'pain, facial',
  'pain, sacral',
  'pain, stomach',
  'panic attacks',
  'Peds: Attention deficit hyperactivity disorder monitoring medications',
  'persistent trachel stoma',
  'Photodynamic therapy (PDT) for acne',
  'Photodynamic therapy (PDT) for actinic keratoses (AKs)',
  'racing thoughts',
  'recurrent urinary tract infections',
  'retractile testicles',
  'rosacea',
  'sacral dimple',
  'sebaceous adenoma',
  'severe acne',
  'stiffness, back',
  'stomach ache',
  'stomach cramps',
  'stomach spasms',
  'swelling, facial',
  'teaching',
];
