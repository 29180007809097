import { DeclineReasonModal } from './DeclineReasonModal';
import { LocationSelectorModal } from './LocationSelectorModal';
import { ProviderSelectorModal } from './ProviderSelectorModal';
import { FamilyHistoryModal } from './FamilyHistoryModal';
import { AddAllergyPromptModal } from './AddAllergyPromptModal';
import { SocialHistoryEditModal } from './SocialHistoryEditModal';
import { EditMedicationsModal } from './EditMedicationsModal';
import { EncounterHPIDataItemModal } from './EncounterHPIDataItemModal';
import { HPIFollowUpModal } from './HPIFollowUpModal copy';
import { HPISmartFormModal } from './HPISmartFormModal';
import { EditROSModal } from './EditROSModal';
import { EditGynSymptomNoteModal } from './EditGynSymptomNoteModal';
import { EditOBSymptomNoteModal } from './EditOBSymptomNoteModal';
import { GYNStructedDataFormModal } from './GYNStructedDataFormModal';
import { HospitalizationModal, SurgeryModal } from './EditSurgeriesModal';
import { WorklistModal } from './WorklistModal';
import { ConstellationRecommendationModal } from './ConstellationRecommendationModal';
import { AdministerVaccineFormModal } from './AdministerVaccineFormModal';
import { PendingVaccinesModal } from './PendingVaccinesModal';
import { AddAdHocOrderModal } from './AddAdHocOrderModal';
import { LabCollectionFormModal, AOEQuestionsAndLabFormsModal } from './LabCollectionFormModal';
import { ChecklistConstellationModal } from './ChecklistConstellationModal';
import { GrowthChartModal } from './GrowthChartModal';
import { ClinicalStatusSelectModal } from './ClinicalStatusSelectModal';
import { ConfirmationModal } from './ConfirmationModal';

type ModalComponents = Record<ModalTypes, (props: any) => JSX.Element>;

export const MODAL_COMPONENTS: ModalComponents = {
  ConfirmationModal,
  WorklistModal,
  AdministerVaccineFormModal,
  PendingVaccinesModal,
  DeclineReasonModal,
  LocationSelectorModal,
  ProviderSelectorModal,
  FamilyHistoryModal,
  AddAllergyPromptModal,
  SocialHistoryEditModal,
  HPIFollowUpModal,
  HPISmartFormModal,
  EncounterHPIDataItemModal,
  EditMedicationsModal,
  EditROSModal,
  ConstellationRecommendationModal,
  SurgeryModal,
  HospitalizationModal,
  EditGynSymptomNoteModal,
  EditOBSymptomNoteModal,
  GYNStructedDataFormModal,
  AddAdHocOrderModal,
  LabCollectionFormModal,
  ChecklistConstellationModal,
  GrowthChartModal,
  AOEQuestionsAndLabFormsModal,
  ClinicalStatusSelectModal,
};
