import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { Spinner } from '../../../components';
import { AsyncSelectField } from '../../../features';
import { useDebounce } from '../../../hooks';
import { useRef, useState } from 'react';
import { DEBOUNCE_MILLISECONDS } from '../../../constants';
import { isEmpty } from 'lodash';
import { MedicationsEmptyStateIllustration } from './MedicationsEmptyStateIllustration';
import {
  type MedicationLookup,
  useGetMedicationLookupsQuery,
} from '../../../__generated__/graphql';
import { MedicationGroup } from './MedicationGroup';
import { MedStatusValues } from '../../../types';
import { useMedicationState } from './hooks/useMedicationState';
import { useUpdateMedItems } from './hooks/useUpdateMedItems';

const medStatuses = [
  MedStatusValues.T.toString(),
  MedStatusValues.U.toString(),
  MedStatusValues.N.toString(),
  MedStatusValues.D.toString(),
] as MedStatus[];

export function MedicationsContent() {
  const ref = useRef<{ reset: () => void } | undefined>();
  const { addedMedications } = useMedicationState();

  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);

  const { data: dropDownMedicationsData, loading: isLoadingGetMedicationLookups } =
    useGetMedicationLookupsQuery({
      variables: { contains: debouncedSearchText },
      skip: !debouncedSearchText,
    });
  const dropDownMedications = dropDownMedicationsData?.getMedicationLookups;

  const options = dropDownMedications?.map((entity: MedicationLookup) => {
    const name = entity?.name;
    return {
      label: name,
      value: name,
      entity,
    };
  });

  const medicationState = useMedicationState();

  const groupedMeds = Object.values(MedStatusValues).reduce<Record<string, MedicationItemUI[]>>(
    (accum, medStatus) => {
      const medStatusValue = medStatus.toString();
      const meds = addedMedications.filter((med) => {
        // check if this item is a duplicate. if it is, only return the duplicate with the
        // highest rxId.
        // we want to check this here instead of the store, bc we want all items to be passed
        // back into the update mutation
        const matchingItems = addedMedications.filter(
          (added) => added.medicationItem === med.medicationItem,
        );

        if (matchingItems.length > 1) {
          // is it NOT the highest rxId?
          // if so, short circuit and return false
          // so we can present the other, higher rxId item
          const isHighestRxId = matchingItems.every((m) => (m.rxId ?? 0) <= (med.rxId ?? 0));
          if (!isHighestRxId) {
            return false;
          }
        }

        if (medStatus === MedStatusValues.U && med.status === null) {
          return true;
        }
        return med.status === medStatusValue;
      });
      if (meds.length) {
        accum[medStatusValue] = meds;
      }
      return accum;
    },
    {},
  );

  const { handleOptionSelect } = useUpdateMedItems();

  return (
    <>
      <AsyncSelectField
        ref={ref}
        handleOptionSelect={(option) => {
          handleOptionSelect(
            addedMedications,
            () => {
              ref.current?.reset();
              setSearchText('');
            },
            option as DropdownOption<MedicationLookup>,
          );
        }}
        inputProps={{
          placeholder: 'Add Medication',
          autoFocus: true,
        }}
        dropdownItems={options}
        handleInputChange={(val) => {
          if (val) {
            setSearchText(val);
          }
        }}
        loading={isLoadingGetMedicationLookups}
      />
      <VStack spacing='sm' w='full'>
        {medicationState.medsQuery.loading ? (
          <Spinner />
        ) : isEmpty(addedMedications) ? (
          <Center mt='8'>
            <VStack spacing='md'>
              <MedicationsEmptyStateIllustration />
              <Text color='secondary'>
                To get started, search and add items to the medications list.
              </Text>
            </VStack>
          </Center>
        ) : (
          medStatuses.map((label) => {
            if (groupedMeds[label]) {
              return <MedicationGroup key={label} title={label} items={groupedMeds[label]} />;
            }
            return null;
          })
        )}
      </VStack>
      <Box height='50px' />
    </>
  );
}
