import { Box, Button, Checkbox, Flex, SimpleGrid, Stack, Text, useToast } from '@chakra-ui/react';
import { EncounterHeader } from '../components';
import { DatePicker } from '../../../components';
import { LabDIOrdersTable } from './components/LabDIOrdersTable';
import { Facilities, LabProviders, LabReasons, Labs } from '../components/ComboBoxes';
import { useState } from 'react';
import { type OrderableItemUI } from '../../../types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  OrderableItemOrderType,
  useAddOrderableItemMutation,
  useGetTelephoneEncounterBySourceEncounterIdQuery,
} from '../../../__generated__/graphql';
import { IS_LOCAL } from '../../../constants';
import { apolloClient } from '../../../api/apollo-api';
import { useAuth } from '../../../hooks';

export const initialLab: OrderableItemUI = {
  orderDate: new Date(),
  labType: undefined,
  orderableItemName: undefined,
  labItemId: undefined,
  reason: undefined,
  assigneeId: undefined,
  facilityID: undefined,
  orderedFasting: false,
  highPriority: false,
  futureOrder: false,
  inHouse: false,
};

export function OrderLabsScreen() {
  const { encounterId } = useParams();
  const [saving, setSaving] = useState(false);
  const { user } = useAuth();

  const nav = useNavigate();

  const [addedLabs, setAddedLabs] = useState<OrderableItemUI[]>([]);
  const [lab, setLab] = useState<OrderableItemUI>(initialLab);

  const res = useGetTelephoneEncounterBySourceEncounterIdQuery({
    skip: !encounterId,
    variables: { sourceEncounterId: Number(encounterId) },
  });

  const patientId = res.data?.getTelephoneEncounterBySourceEncounterId?.sourcePatientId;

  const required = [
    lab.orderableItemName,
    lab.labItemId,
    lab.reason,
    lab.assigneeId,
    lab.facilityID,
  ];

  const valid = required.every((value) => !!value);
  const [save] = useAddOrderableItemMutation();
  const toast = useToast();

  const handleSave = async () => {
    try {
      setSaving(true);
      const updates: Array<Promise<any>> = [];
      if (!patientId) throw new Error('No patient selected');
      if (!res) return;
      for (const lab of addedLabs) {
        updates.push(
          save({
            variables: {
              assessmentItemId: Number(lab.labItemId),
              labItemId: Number(lab.labItemId),
              assignedToUserEmail: IS_LOCAL ? 'tdale@elfp.com' : 'jbaker@elfp.com',
              assignedToUserID: Number(lab.assigneeId),
              orderableItemName: lab.orderableItemName,
              // testing this with a hardcoded non-te encounterId
              encounterID: Number(encounterId),
              orderDate: lab.orderDate?.toISOString(),
              orderableItemTypeId: lab.labType!.typeId,
              orderingProviderID:
                res.data?.getTelephoneEncounterBySourceEncounterId?.assignedToUserId ||
                Number(user.ecwId),
              patientId,
              facilityID: Number(lab.facilityID!),
              orderableItemOrderType: OrderableItemOrderType.Adhoc,
              highPriority: !!lab.highPriority,
              reason: lab.reason,
              futureOrder: !!lab.futureOrder,
              inHouse: !!lab.inHouse,
              fasting: !!lab.orderedFasting,
            },
          }),
        );
      }

      await Promise.all(updates);
      await apolloClient.refetchQueries({
        include: ['GetProviderEncounterListItems'],
      });
      await apolloClient.resetStore();
      await apolloClient.reFetchObservableQueries();

      nav(`/providers/${patientId}/open`);
    } catch (e: any) {
      console.error(e);
      const title = e?.message === 'No patient selected' ? e.message : 'Error saving lab/di orders';

      toast({
        title,
        status: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box key={addedLabs?.length} padding={5} height='100vh' overflow='scroll'>
      <Box
        // Extra space for button
        paddingBottom='48px'>
        <EncounterHeader patientId={patientId} />
        <Text color='white'>test hardcoded encounterID: 7657423 </Text>

        <SimpleGrid columns={2} gap={4} margin='24px 0'>
          <Labs
            selectionCallback={(vals) => {
              setLab({
                ...lab,
                ...vals,
              });
            }}
          />

          <LabReasons
            selectionCallback={(reason) => {
              setLab({
                ...lab,
                reason,
              });
            }}
          />
        </SimpleGrid>

        <Flex justifyContent='space-between' alignItems='center' marginBottom='24px'>
          <SimpleGrid columns={3} gap={4} flexGrow={1}>
            <LabProviders
              selectionCallback={(assigneeId) => {
                setLab({
                  ...lab,
                  assigneeId,
                });
              }}
            />
            <Facilities
              selectionCallback={(facilityID) => {
                setLab({
                  ...lab,
                  facilityID,
                });
              }}
            />
            <DatePicker
              value={lab.orderDate ? new Date(lab.orderDate) : undefined}
              onChange={(orderDate) => {
                setLab({
                  ...lab,
                  orderDate: orderDate ?? undefined,
                });
              }}
              placeholder='Order Date'
            />
          </SimpleGrid>
        </Flex>

        <Flex justifyContent='flex-start' marginBottom='24px'>
          <Stack spacing={2} direction='row'>
            <Text fontSize='xs'>Ordered Fasting?</Text>

            <Checkbox
              colorScheme='brand'
              size='lg'
              isChecked={!!lab.orderedFasting}
              onChange={(e) => {
                const orderedFasting = e.target.checked;
                setLab({
                  ...lab,
                  orderedFasting,
                });
              }}
            />
          </Stack>
          <Stack spacing={2} direction='row' marginLeft='24px'>
            <Text fontSize='xs'>High Priority</Text>

            <Checkbox
              colorScheme='brand'
              size='lg'
              isChecked={!!lab.highPriority}
              onChange={(e) => {
                const highPriority = e.target.checked;
                setLab({
                  ...lab,
                  highPriority,
                });
              }}
            />
          </Stack>

          <Stack spacing={2} direction='row' marginLeft='24px'>
            <Text fontSize='xs'>Future Order</Text>

            <Checkbox
              colorScheme='brand'
              size='lg'
              isChecked={!!lab.futureOrder}
              onChange={(e) => {
                const futureOrder = e.target.checked;
                setLab({
                  ...lab,
                  futureOrder,
                });
              }}
            />
          </Stack>

          <Stack spacing={2} direction='row' marginLeft='24px'>
            <Text fontSize='xs'>In-House</Text>

            <Checkbox
              colorScheme='brand'
              size='lg'
              isChecked={!!lab.inHouse}
              onChange={(e) => {
                const inHouse = e.target.checked;
                setLab({
                  ...lab,
                  inHouse,
                });
              }}
            />
          </Stack>
        </Flex>

        <Button
          onClick={() => {
            if (!valid) return;
            setLab(initialLab);
            setAddedLabs([...addedLabs, lab]);
          }}
          width='100%'
          opacity={!valid ? 0.5 : 1}
          marginBottom='24px'
          variant='outline'>
          Add Lab/DI Order
        </Button>

        <LabDIOrdersTable
          encounterId={encounterId || ''}
          editNewOrders={setAddedLabs}
          newOrders={addedLabs}
        />

        <Flex position='absolute' bottom='0' marginBottom='12px' width='97%'>
          <Button
            width='100%'
            disabled={!addedLabs?.length}
            isLoading={saving || res.loading}
            onClick={handleSave}>
            Save Lab/DI Orders
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
