import { VitalType } from '../__generated__/graphql';

export const vitalTypeToHumanString = (vitalType: VitalType): string => {
  switch (vitalType) {
    case VitalType.BloodPress:
      return 'Blood pressure';
    case VitalType.Temp:
      return 'Temperature';
    case VitalType.Lmp:
      return 'LMP';
    case VitalType.O2:
      return 'O2 saturation';
    case VitalType.Pain:
      return 'Pain scale';
    case VitalType.Bmi:
      return 'BMI';
    case VitalType.WaistCirc:
      return 'Waist circum.';
    case VitalType.RespiratoryRate:
      return 'Resp. rate';
    case VitalType.Height:
    case VitalType.HeightCm:
      return 'Height';
    case VitalType.HeartRate:
      return 'Heart rate';
    case VitalType.Weight:
    case VitalType.WeightKg:
      return 'Weight';
  }
  return '';
};
