import { useState } from 'react';
import { useGetOrderableItemsLookupQuery } from '../../../../__generated__/graphql';
import { ComboBox } from '../../../../components';
import { useDebounce } from '../../../../hooks';

interface Props {
  selectionCallback: (providerId: any) => void;
}

export function Labs({ selectionCallback }: Props) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { data, loading } = useGetOrderableItemsLookupQuery({
    variables: {
      filter: {
        name: debouncedSearch,
      },
    },
  });

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Labs/DI'
      options={data?.orderableItemsLookup
        .filter((i) => ['Lab', 'Diagnostic Imaging'].includes(i.type))
        .map((orderableItem) => ({
          label: `${orderableItem?.name}`,
          value: `${orderableItem?.sourceId}`,
        }))}
      onInputChange={(input) => {
        setSearch(input);
      }}
      onSelection={(selection) => {
        if (!selection) {
          selectionCallback(selection);
        } else {
          const lab = data?.orderableItemsLookup.find((i) => i.sourceId === selection.value);
          if (lab) {
            selectionCallback({
              labItemId: selection?.value,
              orderableItemName: selection?.label,
              labType: {
                type: lab.type,
                typeId: Number(lab.typeId),
              },
            });
          }
        }
      }}
    />
  );
}
