import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import { LabInfo } from './LabInfo';
import { useLabCollectionForm } from './useLabCollectionForm';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';
import { formatDate } from '../../../utils';
import { isLabSubmissionInputValid } from './lab-collection-form-modal.helpers';

export function LabTable() {
  const { formValues, handleToggleLabItemSelection, handleToggleSelectAllLabItems } =
    useLabCollectionForm();
  const dispatch = useAppDispatch();
  const selectAllLabs = useAppSelector((state) => state.worklistTodo.selectAllLabs);

  useEffect(() => {
    if (formValues) {
      dispatch(worklistTodoActions.initializeLabItems());
    }
  }, []);

  return (
    <div>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Lab</Th>
              <Th>Send To</Th>
              <Th>Collection Date / Time</Th>
              <Th>
                <Flex gap='sm' justifyContent='flex-end'>
                  Select All
                  <Checkbox
                    size={'lg'}
                    isChecked={selectAllLabs}
                    onChange={handleToggleSelectAllLabItems}
                  />
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {formValues?.map((lab) => {
              const id = lab.orderableReportID;
              const name = lab.orderableItemName;
              const labSubmissionInputs = lab?.labSubmissionInputs;

              return (
                <Tr key={id}>
                  <Td>
                    <Text isTruncated maxW='480px'>
                      {name}
                    </Text>
                  </Td>
                  <Td>
                    <Text>Orchard</Text>
                  </Td>
                  <Td>
                    {labSubmissionInputs?.collectionDateTime
                      ? formatDate(labSubmissionInputs.collectionDateTime)
                      : null}
                  </Td>
                  <Td>
                    <Flex gap='xs' justifyContent='flex-end'>
                      <Text fontSize={'xs'} color='green.500' alignSelf={'center'}>
                        {isLabSubmissionInputValid(labSubmissionInputs) ? 'Data Applied' : ''}
                      </Text>
                      <LabInfo lab={lab} />
                      <Checkbox
                        isChecked={lab.isSelected}
                        size={'lg'}
                        onChange={() => {
                          handleToggleLabItemSelection(id);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}
