import {
  Box,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Switch,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import {
  type DevelopmentStatistic,
  DevelopmentStatisticGender,
  useGetWhoDataQuery,
  Gender,
  useGetGrowthChartQuery,
} from '../../../__generated__/graphql';
import { Chart as ChartJS } from 'chart.js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from '../../../state-management';
import { Spinner } from '../../data-display';
import { GrowthChartTable } from './GrowthChartTable';
import { GrowthChartLineChart } from './GrowthChartLineChart';
import { formatWHOData, tabMetadata } from './util';

export function GrowthChartModal() {
  const [isBaby, setIsBaby] = useState(true);
  const [tab, setTab] = useState(tabMetadata[0]);
  const [tabIndex, setTabIndex] = useState(0);

  const patientId = useSelector(selectors.getPatientId);
  const patientGender =
    useSelector(selectors.getGender) === Gender.Male
      ? DevelopmentStatisticGender.Male
      : DevelopmentStatisticGender.Female;
  const patientBirthday = useSelector(selectors.getBirthday);

  const { data: WHOQueryData, loading: WHOQueryLoading } = useGetWhoDataQuery({
    variables: {
      stat: getTabItem(tab.dataType) as DevelopmentStatistic,
      gender: patientGender,
      minAge: getTabItem(tab.min) as number,
      maxAge: getTabItem(tab.max) as number,
    },
  });

  const { data: growthChartData, loading: growthChartDataLoading } = useGetGrowthChartQuery({
    variables: {
      sourcePatientId: patientId ?? '0',
      // Appeasing TypeScript, this component is inaccessible if
      // patientBirthday is falsy
      dateOfBirth: patientBirthday ?? '01/01/2001',
      vitalItemName: getTabItem(tab.dataType) as DevelopmentStatistic,
      minAge: getTabItem(tab.min) as number,
      maxAge: getTabItem(tab.max) as number,
    },
    // Skip query if patientId falsy
    skip: !patientId,
  });

  const WHOData = useMemo(() => {
    if (!WHOQueryLoading && WHOQueryData) {
      return formatWHOData(WHOQueryData);
    }
  }, [WHOQueryData, WHOQueryLoading]);

  // Resize chart to fit print window
  useEffect(() => {
    window.addEventListener('beforeprint', () => {
      for (const id in ChartJS.instances) {
        ChartJS.instances[id].resize(750, 600);
      }
    });
    window.addEventListener('afterprint', () => {
      for (const id in ChartJS.instances) {
        ChartJS.instances[id].resize();
      }
    });
  });

  function getTabItem(item: string[] | number[]) {
    return isBaby ? item[0] : item.slice().reverse()[0];
  }

  return (
    <>
      <ModalHeader />
      <ModalCloseButton sx={{ '@media print': { display: 'none' } }} />
      <ModalBody>
        <Box width='100%' height='100%' overflow='hidden'>
          <Text fontSize='xl' as='b' sx={{ '@media print': { display: 'none' } }}>
            Growth Chart
          </Text>

          <Flex
            justifyContent='space-between'
            alignItems='center'
            margin='24px 0'
            sx={{ '@media print': { display: 'none' } }}>
            <Tabs
              defaultIndex={0}
              variant='unstyled'
              onChange={(index) => {
                setTabIndex(index);
                setTab(tabMetadata[index]);
                if (index === 3) {
                  setIsBaby(true);
                }
              }}>
              <TabList>
                {tabMetadata.slice(0, isBaby ? 4 : 3).map((tab, index) => {
                  return (
                    <Tab
                      key={index}
                      _selected={{ color: 'primary' }}
                      paddingLeft='40px'
                      paddingRight='40px'
                      borderBottom={tabIndex === index ? '2px solid #ED4924' : 'none'}>
                      <Text fontSize='lg'>{getTabItem(tab.label)}</Text>
                    </Tab>
                  );
                })}
              </TabList>
            </Tabs>

            <Flex alignItems='center' gap={2}>
              <Text fontSize='lg'>{'<'}2 Years Old</Text>
              <Switch
                isChecked={isBaby}
                isDisabled={tabIndex === 3}
                onChange={() => {
                  setIsBaby(!isBaby);
                  setTab(tabMetadata[tabIndex]);
                }}
                size='lg'
              />
            </Flex>
          </Flex>

          {growthChartDataLoading ? (
            <Spinner />
          ) : growthChartData?.getGrowthChart.tableData.length ? (
            <Flex alignItems='flex-start' height='70vh'>
              <GrowthChartLineChart
                WHOData={WHOData}
                growthChartData={growthChartData.getGrowthChart.chartData}
                isBaby={isBaby}
                max={getTabItem(tab.max)}
                min={getTabItem(tab.min)}
                xAxis={getTabItem(tab.xAxis)}
                xAxisUnit={getTabItem(tab.xAxisUnit)}
                annotationXOffset={getTabItem(tab.annotationXOffset)}
                yAxis={getTabItem(tab.yAxis)}
                yAxisUnit={getTabItem(tab.yAxisUnit)}
                annotationPadding={getTabItem(tab.annotationPadding)}
              />

              <GrowthChartTable
                xAxis={getTabItem(tab.xAxis)}
                yAxis={getTabItem(tab.yAxis)}
                data={growthChartData.getGrowthChart.tableData}
                isBaby={isBaby}
              />
            </Flex>
          ) : (
            <Flex height='70vh' alignItems='center' justifyContent='center'>
              <Text fontSize='lg'>No Patient {getTabItem(tab.label)} Data Available</Text>
            </Flex>
          )}
        </Box>
      </ModalBody>
    </>
  );
}
