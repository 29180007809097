import React from 'react';
import { Box, VStack } from '../../layout';
import { Button } from '../../form';
import { Text } from '../../typography';
import { useDispatch } from 'react-redux';
import { appointmentsActions, selectors, useAppSelector } from '../../../state-management';
import { type AlertId } from '../../../types';

// TODO: Extends props of "SpecifcModal"
interface AlreadyOrderedImmunizationModalProps {
  alertId: AlertId;
  onCloseModal: () => void;
}
export function AlreadyOrderedImmunizationModal({
  alertId,
  onCloseModal,
}: AlreadyOrderedImmunizationModalProps) {
  const dispatch = useDispatch();

  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const editing = editedOrder.status === 'NO_EDITING_DECLINE_REASON';
  const declineReason = editedOrder?.declineReason;
  const renderContent = null;

  function handleAddDeclineReason() {
    onCloseModal();
    if (declineReason != null) {
      dispatch(appointmentsActions.addDeclineReasonComplete({ alertId }));
    }
  }

  function handleCancel() {
    onCloseModal();

    if (editing) {
      dispatch(appointmentsActions.cancelOrderLineItemEdit({ alertId }));
    }
  }

  return (
    <Box p='md'>
      <VStack spacing='lg'>
        <Text variant='h6-b' textAlign='center'>
          {declineReason?.name}
        </Text>
        {/* TODO: When decline reason is canned "already-ordered", bring special inputs */}
        {renderContent}
        <Text textAlign='center'>
          Please enter the immunization documentation on the patient's chart in the EMR.
        </Text>
        <VStack spacing='md' w='full'>
          <Button onClick={handleAddDeclineReason} w='full'>
            Add this reason
          </Button>
          <Button variant='outline' onClick={handleCancel} w='full'>
            Cancel
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}
