import React from 'react';
import { Badge, Box, Card, Flex, HStack, Icon, Text } from '../../../../components';
import { type AlertId, type PatientAlert } from '../../../../types';
import { FiLock as LockIcon } from 'react-icons/fi';
import { selectors, useAppSelector } from '../../../../state-management';

export function ReadOnlyOrderLinteItemCard({
  recommendationId,
  orderItem,
}: {
  recommendationId?: AlertId;
  orderItem?: Pick<PatientAlert, 'shortDescription'>;
}) {
  const editedOrder = useAppSelector(
    selectors.editedOrdersSelectors.selectById(recommendationId ?? 0),
  );
  const recommendation = editedOrder?.alert || orderItem;
  return (
    <Card bg='orange.50'>
      <Box>
        <Flex justifyContent='flex-end' mb='sm'>
          <HStack spacing='sm'>
            <Icon as={LockIcon} />
            <Badge colorScheme='blue' rounded='full' px='sm'>
              Read only
            </Badge>
          </HStack>
        </Flex>
        <Text variant='h6-b'>{recommendation?.shortDescription}</Text>
      </Box>
    </Card>
  );
}
