import { type HpiStructuredData } from '../../../../../__generated__/graphql';
import { HpiCalculator } from './base';

export class Phq9ScoreInterpretation extends HpiCalculator {
  componentFields = [2895];
  computedField = 2896;

  formula = (responses: HpiStructuredData[]) => {
    const value = responses.filter((r) =>
      this.componentFields.includes(r.hpiStructuredDataDetailId!),
    )?.[0]?.value;

    if (!value) return;

    const options = this.smartForms.find(
      (f) => f.structureDataDetailID === this.computedField,
    )?.smartFormSurveyStructureOptions;
    if (!options?.length) return;

    const score = parseInt(value, 10);

    // there are 5 options. if the score is 1-4, the rounded score is 0
    // if the score is 5-9, the rounded score is 1  etc
    const rounded = Math.min(Math.floor(score / options.length), options.length - 1);
    const option = options.find((o) => o.displayIndex === rounded);
    return option?.structDataOptionID ?? undefined;
  };
}
