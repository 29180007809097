import { v4 } from 'uuid';
import { type GetMedicationsQuery } from '../../../../__generated__/graphql';

type QueryMedication = NonNullable<GetMedicationsQuery['getMedications']>[0];

interface QueryMedWithSourceEncounter extends QueryMedication {
  sourceEncounterId?: number;
}

export const convertMedsDataToUiData = (
  meds?: QueryMedWithSourceEncounter[] | null,
): MedicationItemUI[] => {
  if (!meds) return [];
  return meds.map(({ __typename, ...med }) => {
    return {
      ...med,
      // if a new medication is added we will assign a uuid
      // this will be used for upserting when we save to papi
      // it also gives us an id to reference when we are
      // updating the medication locally in the UI
      id: med?.id ?? v4(),
      newStatus: med.status,
    };
  });
};
