import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface OrderLabsDIState {
  encounterId: number | null;
  patientId: number | null;
}

const initialState: OrderLabsDIState = {
  encounterId: null,
  patientId: null,
};

export const orderLabsDISlice = createSlice({
  name: 'orderLabsDI',
  initialState,
  reducers: {
    onStateChange(state, action: PayloadAction<Partial<OrderLabsDIState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const orderLabsDIActions = orderLabsDISlice.actions;

export default orderLabsDISlice.reducer;
