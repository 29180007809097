import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface RefetchDataState {
  encounterTemplateLastApplyTime: Date | null;
}

const initialState: RefetchDataState = {
  encounterTemplateLastApplyTime: null,
};

export const refetchDataSlice = createSlice({
  name: 'refetchData',
  initialState,
  reducers: {
    setEncounterTemplateLastApplyTime: (
      state,
      action: PayloadAction<RefetchDataState['encounterTemplateLastApplyTime']>,
    ) => {
      state.encounterTemplateLastApplyTime = action.payload;
    },
  },
});

export const refetchDataActions = refetchDataSlice.actions;

export default refetchDataSlice.reducer;
