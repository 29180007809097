import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ProviderState {
  selectedProviderIDs: number[];
}

const initialState: ProviderState = {
  // selectedProviderID: 491917,
  selectedProviderIDs: [],
};

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setSelectedProviderIds(state, action: PayloadAction<{ selectedProviderIDs: number[] }>) {
      state.selectedProviderIDs = action.payload.selectedProviderIDs;
    },
  },
});

export const providerActions = providerSlice.actions;

export default providerSlice.reducer;
