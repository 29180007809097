import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
  type UpdateImmunizationInjectionMutation,
  type SubmitImmunizationInjectionInput,
  type AddOrderableItemResultInput,
  type AoeAnswerInput,
} from '../__generated__/graphql';
import { isEmpty } from 'lodash';

export interface PendingVaccine {
  id: number;
  text: string;
  completed: boolean;
  immunization: UpdateImmunizationInjectionMutation['updateImmunizationInjection'];
}

interface WorklistState {
  constellationItems: Record<ConstellationWorklistItem['id'], ConstellationWorklistItem>;
  pendingVaccineTodos: PendingVaccine[];
  selectedPendingVaccine?: PendingVaccine;
  administerVaccineForm?: VaccineForm;
  // LAB / AOE
  labForms: LabItem[];
  selectAllLabs?: boolean;
  isLabCollectionCompleted?: boolean;
  labGlobalForm?: LabGlobalForm;
}

const initialState: WorklistState = {
  constellationItems: {},
  pendingVaccineTodos: [],
  administerVaccineForm: {
    administeredByUserId: null,
    administeredByUserName: null,
    dateTimeAdministered: null,
    documentingFacilityId: null,
    documentingFacilityName: null,
    dose: null,
    immunizationId: null,
    immunizationItemId: 0,
    immunizationName: null,
    injectionLocation: null,
    lotId: null,
    patientImmunizationInjectionType: null,
    route: null,
    sourceEncounterId: 0,
    sourceOfHistory: null,
    sourcePatientId: 0,
    statusDescription: null,
    statusId: 0,
    vaccinationGivenInThePast: null,
  },
  selectAllLabs: true,
  labForms: [],
  isLabCollectionCompleted: false,
};

export const worklistTodoSlice = createSlice({
  name: 'worklistTodo',
  initialState,
  reducers: {
    setConstellationItem(state, action: PayloadAction<ConstellationWorklistItem>) {
      const item = action.payload;
      state.constellationItems[item.id] = item;
    },
    addOrderableItemResultFormFields(state, action: PayloadAction<AddOrderableItemResultInput[]>) {
      const items = action.payload;

      items.forEach((item) => {
        const attributeID = item.attributeID;
        const orderableReportID = item.orderableReportID;

        if (orderableReportID && attributeID) {
          state.constellationItems[orderableReportID].orderableItemResultFormFields[attributeID] =
            item;
        }
      });
    },
    onOrderableItemResultFormFieldChange(
      state,
      action: PayloadAction<AddOrderableItemResultInput>,
    ) {
      const item = action.payload;
      const attributeID = item.attributeID;
      const orderableReportID = item.orderableReportID;

      if (orderableReportID && attributeID) {
        state.constellationItems[orderableReportID].orderableItemResultFormFields[attributeID] =
          item;
      }
    },

    markConstellationItemComplete: (
      state,
      action: PayloadAction<ConstellationWorklistItem['id']>,
    ) => {
      const id = action.payload;
      const item = state.constellationItems[id];
      if (item) {
        item.completed = true;
      }
    },

    addPendingVaccine: (state, action: PayloadAction<PendingVaccine>) => {
      const updatedItem = action.payload;

      if (!isEmpty(updatedItem)) {
        // Find the index of the item with the same id in the state array
        const existingItemIndex = state.pendingVaccineTodos.findIndex(
          (item) => item.id === updatedItem.id,
        );

        if (existingItemIndex !== -1) {
          // If an item with the same id exists, update it
          state.pendingVaccineTodos[existingItemIndex] = {
            ...state.pendingVaccineTodos[existingItemIndex],
            ...updatedItem,
          };
        } else {
          // If not, add the new item to the array
          state.pendingVaccineTodos.push(updatedItem);
        }
      }
    },

    markPendingVaccineComplete: (state, action: PayloadAction<PendingVaccine['id']>) => {
      const id = action.payload;
      const pendingVaccine = state.pendingVaccineTodos?.find((v) => v.id === id);
      if (pendingVaccine) {
        pendingVaccine.completed = true;
      }
    },

    setSelectedPendingVaccine: (state, action: PayloadAction<PendingVaccine>) => {
      state.selectedPendingVaccine = action.payload;
    },
    updateAdministerVaccineForm: (
      state,
      action: PayloadAction<Partial<SubmitImmunizationInjectionInput>>,
    ) => {
      state.administerVaccineForm = { ...state.administerVaccineForm, ...action.payload };
    },

    markLabCollectionComplete(state) {
      state.isLabCollectionCompleted = true;
    },

    resetAdministerVaccineForm(state) {
      state.administerVaccineForm = initialState.administerVaccineForm;
    },

    updateGlobalLabForm(state, action: PayloadAction<WorklistState['labGlobalForm']>) {
      state.labGlobalForm = { ...state.labGlobalForm, ...action.payload };
    },

    removeLabItems(state, action: PayloadAction<LabItemId[]>) {
      const labIds = action.payload;

      state.labForms = state.labForms.filter((item) => !labIds.includes(item.orderableReportID));
    },

    toggleLabItemSelection(state, action: PayloadAction<LabItemId>) {
      // Get lab (TODO: Make consistent with other reducers that need to find the lab item first)
      const lab = state.labForms.find((item) => item.orderableReportID === action.payload);

      // Toggle the selected state of the lab item
      if (lab) {
        lab.isSelected = !lab.isSelected;
      }

      // Check "Select All" if all lab items are selected
      state.selectAllLabs = state.labForms.every((item) => item.isSelected);
    },
    toggleSelectAllLabItems(state) {
      state.selectAllLabs = !state.selectAllLabs;
      state.labForms = state.labForms.map((item) => ({
        ...item,
        isSelected: state.selectAllLabs,
      }));
    },
    initializeLabItems(state) {
      state.labForms = state.labForms.map((item) => ({
        ...item,
        isSelected: state.selectAllLabs,
        labSubmissionInputs: {
          ...item.labSubmissionInputs,
          // Keep TS happy till API is ready.
          reportId: item.orderableReportID,
          specimenCollected: !!item?.labSubmissionInputs?.specimenCollected,
          collectionDateTime:
            // TODO: When we fetch from server, this should be the server time
            item?.labSubmissionInputs?.collectionDateTime ?? new Date().toISOString(),
        },
      }));
    },
    batchUpdateLabItems(state, action: PayloadAction<WorklistState['labForms']>) {
      state.labForms = action.payload;
    },

    updateAOEAnswer(state, action: PayloadAction<Partial<AoeAnswerInput>>) {
      const updatedItem = action.payload;
      // Get the lab item with the same orderableReportID in the labs array
      const lab = state.labForms.find((item) => item.orderableReportID === updatedItem.reportId);

      if (lab) {
        if (lab.aoeAnswers) {
          // has user answered this questionId
          const hasAnswer = lab.aoeAnswers.some(
            (answer) => answer.questionId === updatedItem.questionId,
          );

          if (hasAnswer) {
            // Edit prexisting answer
            lab.aoeAnswers = lab.aoeAnswers.map((answer) => {
              if (answer.questionId === updatedItem.questionId) {
                return {
                  ...answer,
                  ...updatedItem,
                };
              }

              return answer;
            });
          } else {
            // Add new answer
            lab.aoeAnswers.push(updatedItem);
          }
        } else {
          lab.aoeAnswers = [updatedItem];
        }
      }
    },

    updateLabItem: (state, action: PayloadAction<LabItem>) => {
      const updatedItem = action.payload;

      if (!isEmpty(updatedItem)) {
        // Find the index of the item with the same orderableReportID in the labCollectionForm array
        const existingItemIndex = state.labForms.findIndex(
          (item) => item.orderableReportID === updatedItem.orderableReportID,
        );

        if (existingItemIndex !== -1) {
          // If an item with the same orderableReportID exists, update it
          state.labForms[existingItemIndex] = {
            ...state.labForms[existingItemIndex],
            ...updatedItem,
          };
        } else {
          // If not, add the new item to the array
          state.labForms.push(updatedItem);
        }
      }
    },

    resetWorklist() {
      return initialState;
    },
  },
});

export const worklistTodoActions = worklistTodoSlice.actions;
