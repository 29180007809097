import { modalActions, type ModalState, useAppDispatch } from '../state-management';

export function useModal() {
  const dispatch = useAppDispatch();

  function showModal(params: ModalState) {
    dispatch(modalActions.showModal(params));
  }

  function hideModal() {
    dispatch(modalActions.hideModal());
  }

  function openConfirmationModal(confirmationModalProps: ConfirmationModalProps) {
    showModal({
      modalType: 'ConfirmationModal',
      modalProps: confirmationModalProps,
    });
  }

  return {
    showModal,
    hideModal,
    openConfirmationModal,
  };
}
