import {
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  RadioGroup,
  Radio,
  Textarea,
} from '../../../components';

export function HPIFollowUpModalContent() {
  return (
    <VStack spacing='lg'>
      <SimpleGrid columns={3} spacing='md' w='full'>
        <FormControl>
          <FormLabel>Location/Radiation</FormLabel>
          <Select placeholder='Select'>
            <option>Buttocks</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Onset</FormLabel>
          <Select placeholder='Select'>
            <option>Days Ago</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Severity</FormLabel>
          <Select placeholder='Select'>
            <option>Mild</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Location/Nature</FormLabel>
          <Select placeholder='Select'>
            <option>Better</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Aggravated By</FormLabel>
          <Select placeholder='Select'>
            <option>Anxiety</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Relieved By</FormLabel>
          <Select placeholder='Select'>
            <option>Anxiety</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Associated Symptoms</FormLabel>
          <Select placeholder='Select'>
            <option>Ankle Swelling</option>
          </Select>
        </FormControl>
      </SimpleGrid>

      <VStack w='full' spacing='md'>
        <HStack w='full'>
          <Select value='2-3' placeholder='Select' w='full'>
            <option value='2-3'>2-3</option>
            <option value='4-5'>4-5</option>
          </Select>

          <HStack>
            <RadioGroup
              onChange={() => {}}
              // size="lg"
              colorScheme='brand'
              value={undefined}
              defaultValue='days'
            >
              <HStack spacing='md'>
                <Radio value='days'>Days</Radio>
                <Radio value='weeks'>Weeks</Radio>
                <Radio value='months'>Months</Radio>
                <Radio value='years'>Years</Radio>
              </HStack>
            </RadioGroup>
          </HStack>
        </HStack>

        <Textarea rows={4} placeholder='Notes' />
      </VStack>
    </VStack>
  );
}
