import { Grid } from '@chakra-ui/react';
import { Facilities } from './Facilities';
import { CallerName } from './CallerName';
import { DateFilter } from './Date';
import { Assignees } from '../EncounterFilters/Assignees';

export function TEFilters() {
  return (
    <Grid templateColumns='repeat(4, 1fr)' gap={4}>
      <DateFilter />
      <CallerName />
      <Facilities />
      <Assignees />
    </Grid>
  );
}
