import React from 'react';
import { Box, VStack } from '../../layout';
import { Button, DatePicker, Input, Textarea } from '../../form';
import { Text } from '../../typography';
import { useDispatch } from 'react-redux';
import { appointmentsActions, selectors, useAppSelector } from '../../../state-management';
import { isRecommendationInjectionOrImmunization } from '../../../utils';
import { AlreadyOrderedImmunizationModal } from './AlreadyOrderedImmunizationModal';
import { DeclineReasonPatientDecline } from './DeclineReasonPatientDecline';
import { type AlertId } from '../../../types';

// TODO: Extends props of "SpecifcModal"
interface DeclineReasonModalProps {
  alertId: AlertId;
  onCloseModal: () => void;
}
export function DeclineReasonModal({ alertId, onCloseModal }: DeclineReasonModalProps) {
  const dispatch = useDispatch();

  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const editing = editedOrder.status === 'NO_EDITING_DECLINE_REASON';
  const declineReason = editedOrder?.declineReason;
  let renderContent = null;
  const uiDeclineCode = editedOrder.declineReason?.uiDeclineReasonCode;
  const immunizationOrInjection = isRecommendationInjectionOrImmunization(editedOrder);

  const alreadyOrderedDate = editedOrder.declineReason?.dateTimeOrdered
    ? new Date(editedOrder.declineReason?.dateTimeOrdered)
    : new Date();

  function handleAlreadyOrderedDateChange(newDate: Date | null) {
    dispatch(
      appointmentsActions.changeDeclineReason({
        alertId,
        dateTimeOrdered: newDate != null ? newDate.toISOString() : new Date().toISOString(),
      }),
    );
  }

  function handleLocationChange(e: React.ChangeEvent<HTMLInputElement>) {
    const location = e.target.value;

    dispatch(
      appointmentsActions.changeDeclineReason({
        alertId,
        location,
      }),
    );
  }

  function handleResultChange(e: React.ChangeEvent<HTMLInputElement>) {
    const result = e.target.value;

    dispatch(
      appointmentsActions.changeDeclineReason({
        alertId,
        result,
      }),
    );
  }

  function handleAddDeclineReason() {
    onCloseModal();
    if (declineReason != null) {
      dispatch(appointmentsActions.addDeclineReasonComplete({ alertId }));
    }
  }

  function handleAdditionalDetailsChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const additionalDetails = e.target.value;

    dispatch(
      appointmentsActions.changeDeclineReason({
        alertId,
        additionalDetails,
      }),
    );
  }

  function handleCancel() {
    onCloseModal();

    if (editing) {
      dispatch(appointmentsActions.cancelOrderLineItemEdit({ alertId }));
    }
  }

  if (immunizationOrInjection) {
    if (uiDeclineCode === 'ALREADY_ORDERED') {
      // TODO: Refactor all DeclineReason sub-modals to be stand-alone like AlreadyOrderedImmunizationModal
      return <AlreadyOrderedImmunizationModal onCloseModal={onCloseModal} alertId={alertId} />;
    }

    if (uiDeclineCode === 'PATIENT_DECLINE') {
      return <DeclineReasonPatientDecline onCloseModal={onCloseModal} alertId={alertId} />;
    }
  }

  if (uiDeclineCode === 'ALREADY_ORDERED') {
    renderContent = (
      <VStack spacing='md' w='full'>
        <Input
          placeholder='Order location'
          value={editedOrder?.declineReason?.location}
          onChange={handleLocationChange}
        />
        <DatePicker
          w='full'
          value={alreadyOrderedDate}
          onChange={handleAlreadyOrderedDateChange}
          placeholder='Order Date'
        />
      </VStack>
    );
  }

  return (
    <Box p='md' minWidth='450px'>
      <Box mb='md'>
        <Text variant='h6-b' textAlign='center'>
          {declineReason?.name}
        </Text>
      </Box>
      {/* TODO: When decline reason is canned "already-ordered", bring special inputs */}
      <VStack spacing='md'>
        {renderContent}
        {/* TODO: Refactor all declines similar to Immunization. Too much logic in this one. Just compose each individual modal  */}
        <Input
          placeholder={uiDeclineCode === 'PATIENT_DECLINE' ? 'Reason' : 'Result'}
          value={editedOrder?.declineReason?.result}
          onChange={handleResultChange}
        />

        <Textarea
          onChange={handleAdditionalDetailsChange}
          value={editedOrder?.declineReason?.additionalDetails}
          placeholder='Additional notes'
        />

        <Button onClick={handleAddDeclineReason} w='full'>
          Add this reason
        </Button>
        <Button variant='outline' onClick={handleCancel} w='full'>
          Cancel
        </Button>
      </VStack>
    </Box>
  );
}
