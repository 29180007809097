import { useEffect, useState } from 'react';
import { useSetDirty } from './useSetDirty';

interface Props<T> {
  state: T;
  step: OrderFlowSteps;
  disabled?: boolean;
}

// COMPARES UDPATED STATE TO THE INITIALLY SET STATE
export function useStateComparison<T>({ state, step, disabled }: Props<T>) {
  const [initialState, setInitialState] = useState<T | undefined>(undefined);
  const setDirty = useSetDirty();

  useEffect(() => {
    // use disabled to prevent evaluating until ready
    // for example, wait for a query to resolve and populate state
    // before comparing initial state to updated state
    if (disabled) return;

    if (!initialState && state) {
      setInitialState(state);
    }

    if (!!initialState && !!state) {
      const match = JSON.stringify(initialState) === JSON.stringify(state);
      setDirty(step, !match);
    }
  }, [state, initialState, step, disabled]);
}
