import { Box, List, Text, ListItem } from '@chakra-ui/react';
import { useGetImmunizationInjectionPatientQuery } from '../../../__generated__/graphql';
import { useModal, usePatient } from '../../../hooks';
import { Flex, WaitUntilLoaded } from '../../layout';
import { formatDate } from '../../../utils';
import { isEmpty } from 'lodash';

interface Props {
  type: 'Vaccination' | 'Therapeutic Injection';
}

export function PastVaccines({ type }: Props) {
  const { showModal } = useModal();
  const { patientId } = usePatient();
  const { data, loading } = useGetImmunizationInjectionPatientQuery({
    variables: {
      patientId,
    },
  });
  const vaccines = data?.getImmunizationInjectionPatient
    ?.filter((item) => {
      // remove empty rows from showing in page
      if (!item.immunizationName) {
        return false;
      }

      // Filter out past items that don't align with type
      if (type === 'Vaccination') {
        return item.patientImmunizationInjectionType === 'Immunization';
      } else {
        return item.patientImmunizationInjectionType === 'Theraputic Injection';
      }
    })
    ?.sort((a, b) => {
      if (b.dateTimeAdministered && a.dateTimeAdministered) {
        return (
          new Date(b.dateTimeAdministered).getTime() - new Date(a.dateTimeAdministered).getTime()
        );
      }
      return 0;
    });

  return (
    <WaitUntilLoaded loading={loading}>
      {isEmpty(vaccines) ? (
        <Text>No data</Text>
      ) : (
        <List>
          {vaccines?.map((v, i) => {
            return (
              <ListItem
                key={`vaccine-${i}`}
                _hover={{
                  cursor: 'pointer',
                  bgColor: 'gray.50',
                }}
                onClick={() => {
                  showModal({
                    modalType: 'AdministerVaccineFormModal',
                    chakraModalProps: { size: '6xl' },
                    modalProps: { pastVaccineDetail: v, isDisabled: true },
                  });
                }}>
                <Box borderBottom={'1px solid'} borderBottomColor={'blue.200'}>
                  <Flex py='sm'>
                    <Text flex={3}>{v.immunizationName}</Text>
                    <Text flex={1}>{v.dose}</Text>
                    <Text flex={1}>
                      {v.dateTimeAdministered ? formatDate(v.dateTimeAdministered) : ''}
                    </Text>
                    <Text flex={1}>{v.route}</Text>
                    <Text flex={1}>{v.lotId ? `Lot: ${v.lotId}` : ''}</Text>
                  </Flex>
                </Box>
              </ListItem>
            );
          })}
        </List>
      )}
    </WaitUntilLoaded>
  );
}
