import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth } from './hooks';

export function LaunchDarklyInit() {
  const ldClient = useLDClient();
  const { roles, user } = useAuth();
  const flagKeys = `${user.email}:${roles}`;

  useEffect(() => {
    if (ldClient != null && flagKeys) {
      ldClient.identify({ key: flagKeys });
    }
  }, [ldClient, flagKeys]);

  return null;
}
