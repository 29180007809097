import { useDisclosure, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tag,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  PercentBar,
  Spinner,
} from '../../../../components';
import { type MAMetricsTabs } from '../../../../types';
import { type SubmissionRate, useGetSubmissionRateQuery } from '../../../../__generated__/graphql';

const tabConfig: MAMetricsTabs[] = ['Today', 'This Week', 'This Month'];

export function MAMetrics() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [, setSelectedTab] = useState<MAMetricsTabs>('Today');

  const { data: todaySubmissionRate, loading: isTodaySubmissionRateLoading } =
    useGetSubmissionRateQuery({ variables: { days: 1 } });

  const { data: thisWeekSubmissionRate, loading: isThisWeekSubmissionRateLoading } =
    useGetSubmissionRateQuery({ variables: { days: 7 } });

  const { data: thisMonthSubmissionRate, loading: isThisMonthSubmissionRateLoading } =
    useGetSubmissionRateQuery({ variables: { days: 30 } });

  function handleTableChange(index: number) {
    const activeTab = tabConfig[index];
    setSelectedTab(activeTab);
  }

  function renderOverviewBar() {
    return (
      <Box p={0} bgColor='white' borderTopWidth='1px'>
        <HStack w='full' divider={<StackDivider />}>
          <HStack flexGrow={1} justifyContent='center'>
            <Text variant='body2-b'>Submission Rate</Text>
            {!isTodaySubmissionRateLoading ? (
              <Tag borderRadius='full' variant='solid' colorScheme='brand'>
                <Text variant='body2-b'>{todaySubmissionRate?.getSubmissionRate?.userRate}%</Text>
              </Tag>
            ) : (
              <Spinner />
            )}
          </HStack>

          <HStack flexGrow={1} justifyContent='center'>
            <Text variant='body2-b'>Team Benchmark</Text>
            {!isTodaySubmissionRateLoading ? (
              <Tag borderRadius='full' variant='solid' colorScheme='brand'>
                <Text variant='body2-b'>{todaySubmissionRate?.getSubmissionRate?.teamRate}%</Text>
              </Tag>
            ) : (
              <Spinner />
            )}
          </HStack>

          <Flex flexGrow={1} justifyContent='center' p={1.5}>
            <Button onClick={onOpen} w='full'>
              Performance Metrics
            </Button>
          </Flex>
        </HStack>
      </Box>
    );
  }

  function renderDetailsDrawer() {
    return (
      <Drawer isOpen={isOpen} placement='bottom' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Box p={5} bgColor='white'>
              <Tabs isFitted colorScheme='brand' onChange={handleTableChange}>
                <TabList>
                  {tabConfig.map((t) => {
                    return (
                      <Tab key={t}>
                        <Text variant='body2-b'>{t}</Text>
                      </Tab>
                    );
                  })}
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {renderTabContent(
                      todaySubmissionRate?.getSubmissionRate,
                      isTodaySubmissionRateLoading,
                    )}
                  </TabPanel>
                  <TabPanel>
                    {renderTabContent(
                      thisWeekSubmissionRate?.getSubmissionRate,
                      isThisWeekSubmissionRateLoading,
                    )}
                  </TabPanel>
                  <TabPanel>
                    {renderTabContent(
                      thisMonthSubmissionRate?.getSubmissionRate,
                      isThisMonthSubmissionRateLoading,
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  function renderTabContent(submissionRate: SubmissionRate | null | undefined, isLoading: boolean) {
    return (
      <Box py={5}>
        <VStack w='full' spacing={8}>
          <HStack w='full' alignItems='flex-end'>
            <Text variant='body2-b' width='200px'>
              Submission Rate
            </Text>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {submissionRate?.userRate !== undefined && (
                  <PercentBar value={submissionRate?.userRate ?? 0} />
                )}
              </>
            )}
          </HStack>
          <HStack w='full' alignItems='flex-end'>
            <Text variant='body2-b' width='200px'>
              Team Benchmark
            </Text>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {submissionRate?.teamRate !== undefined && (
                  <PercentBar value={submissionRate?.teamRate ?? 0} />
                )}
              </>
            )}
          </HStack>
        </VStack>
      </Box>
    );
  }

  return (
    <Box position='fixed' bottom='0px' left='0px' w='full'>
      <>
        {renderDetailsDrawer()}
        {renderOverviewBar()}
      </>
    </Box>
  );
}
