import { VitalType } from '../../../__generated__/graphql';
import { vitalTypeToHumanString } from '../../../utils/vitalTypeUtil';

export class VitalValidationResult {
  color: string;
  isWarning: boolean;
  enteredValue: string;
  vitalType: VitalType;
  warningAboveMax?: number[] | null;
  warningBelowMin?: number[] | null;
  errorAboveMax?: number[] | null;
  errorBelowMin?: number[] | null;

  static WARNING_COLOR = 'orange.300';
  static ERROR_COLOR = 'red.500';

  constructor(
    vitalType: VitalType,
    isWarning: boolean,
    enteredValue: string,
    warningAboveMax?: number[] | null,
    warningBelowMin?: number[] | null,
    errorAboveMax?: number[] | null,
    errorBelowMin?: number[] | null,
  ) {
    this.vitalType = vitalType;
    this.isWarning = isWarning;
    this.enteredValue = enteredValue;
    if (isWarning) {
      this.color = VitalValidationResult.WARNING_COLOR;
    } else {
      this.color = VitalValidationResult.ERROR_COLOR;
    }
    this.warningAboveMax = warningAboveMax;
    this.warningBelowMin = warningBelowMin;
    this.errorAboveMax = errorAboveMax;
    this.errorBelowMin = errorBelowMin;
  }

  getErrorMessage(): string {
    const warningAddendum =
      this.isWarning && this.vitalType === VitalType.BloodPress
        ? `. Retake after 5 minutes. Verify patient's feet are flat on the floor with legs not crossed and that the cuff is placed directly above the bend of the elbow.`
        : '';

    if (this.warningAboveMax) {
      return `${vitalTypeToHumanString(
        this.vitalType,
      )} is usually below ${this.warningAboveMax.join('/')}${warningAddendum}`;
    }
    if (this.warningBelowMin) {
      return `${vitalTypeToHumanString(
        this.vitalType,
      )} is usually above ${this.warningBelowMin.join('/')}${warningAddendum}`;
    }
    if (this.errorAboveMax) {
      return `${vitalTypeToHumanString(
        this.vitalType,
      )} cannot be above, or equal, ${this.errorAboveMax.join('/')}`;
    }
    if (this.errorBelowMin) {
      return `${vitalTypeToHumanString(
        this.vitalType,
      )} cannot be below, or equal, ${this.errorBelowMin.join('/')}`;
    }

    return '';
  }
}
