import { Select } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

const options = [
  'anal',
  'blood',
  'blood, whole',
  'breath',
  'hair',
  'nail',
  'nasal',
  'skin',
  'stool',
  'throat',
  'tongue',
  'urine',
  'vaginal',
  'wound',
];

export function CollectionSourceSelect() {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  return (
    <Select
      placeholder='Collection Source'
      value={globalLabForm.specimenSource ?? undefined}
      onChange={(e) => {
        handleUpdateGlobalLabForm({ specimenSource: e.target.value });
      }}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}
