import { type VitalQualifier } from '../../types';

export const defaultQualifiers: VitalQualifier[] = [
  'sitting',
  'standing',
  'supine',
  'repeat',
  'left',
  'right',
];
