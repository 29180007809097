import { Box, Button, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import { type GetCumulativeReportQuery } from '../../../../__generated__/graphql';
import { TestResults } from './TestResults';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface Props {
  data?: GetCumulativeReportQuery;
}

export function OrderableItemsContainer({ data }: Props) {
  const orderableItemsArr = [...(data?.getCumulativeReport || [])]?.sort((a, b) => {
    return new Date(a.collectedDate).getTime() - new Date(b.collectedDate).getTime();
  });

  type Mapped = NonNullable<typeof orderableItemsArr>;
  const groupedByOrderName = new Map<string, Mapped>([]);

  orderableItemsArr?.forEach((item) => {
    if (!item.orderableItemName) return;
    if (!groupedByOrderName.has(item.orderableItemName)) {
      groupedByOrderName.set(item.orderableItemName, [item]);
    } else {
      const items = [...(groupedByOrderName.get(item.orderableItemName) || []), item];
      items.sort((a, b) => -1 * a.collectedDate.localeCompare(b.collectedDate));
      groupedByOrderName.set(item.orderableItemName, items);
    }
  });

  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);

  const toggleCollapsedRow = (id: number) => {
    if (collapsedRows.includes(id)) {
      setCollapsedRows(collapsedRows.filter((i) => i !== id));
    } else {
      setCollapsedRows([...collapsedRows, id]);
    }
  };

  return (
    <Box>
      {Array.from(groupedByOrderName).map(([orderName, orderableItems], i) => {
        const isCollapsed = collapsedRows.includes(i);

        return (
          <Box mt={12} key={orderName}>
            <Button
              onClick={() => {
                toggleCollapsedRow(i);
              }}
              width='100%'
              variant='outline'
              justifyContent='start'
              border='none'
              textColor='black'
              display='flex'
              pl={0}>
              <Text fontSize='lg' as='b'>
                {orderName}
              </Text>
              <ChevronDownIcon
                ml={12}
                transition='.25s'
                transform={!isCollapsed ? '' : 'rotate(-90deg)'}
              />
            </Button>

            {!isCollapsed && (
              <Box overflowX='auto' width='100%'>
                <Table mt={8} size='sm'>
                  <Thead>
                    <Tr>
                      <Th minWidth='300px'>
                        <Text as='b' fontSize='sm'>
                          Collection Date
                        </Text>
                      </Th>
                      {orderableItems.map((item, i) => (
                        <Th key={i}>
                          <Text as='b' fontSize='sm'>
                            {new Date(item.collectedDate).toLocaleDateString()}
                          </Text>
                        </Th>
                      ))}
                      <Th width='100%' />
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr>
                      <Td>Order Date</Td>
                      {orderableItems.map((item, i) => (
                        <Td key={i}>
                          <Text fontSize='sm'>{new Date(item.orderDate).toLocaleDateString()}</Text>
                        </Td>
                      ))}
                      <Td />
                      <Td width='100%' />
                    </Tr>

                    <Tr>
                      <Td>Result Date</Td>
                      {orderableItems.map((item, i) => (
                        <Td key={i}>
                          <Text fontSize='sm'>{new Date(item.orderDate).toLocaleDateString()}</Text>
                        </Td>
                      ))}
                      <Td />
                      <Td width='100%' />
                    </Tr>

                    <Tr>
                      <Td>Ordering Physician</Td>
                      {orderableItems.map((item, i) => (
                        <Td key={i}>
                          <Text fontSize='sm'>
                            {item.orderingProviderName?.split(',').join(', ')}
                          </Text>
                        </Td>
                      ))}
                      <Td />
                      <Td width='100%' />
                    </Tr>

                    <TestResults orderableItems={orderableItems} />
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
