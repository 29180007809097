import { type BoxProps } from '@chakra-ui/react';
import { type ReactNode, forwardRef, useRef } from 'react';
import ReactDatePicker, { type ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // should this be here or somewhere global?
import { Box, Button, IconButton, Icon, Flex } from '../..';

import { isNil, range } from 'lodash';
import { getMonth, getYear } from 'date-fns';
import {
  MdChevronLeft as CalendarLeftArrow,
  MdChevronRight as CalendarRightArrow,
} from 'react-icons/md';
import { type Omit } from '@chakra-ui/utils';
import { RenderDateInput } from './CustomInput';
import { AiOutlineCalendar } from 'react-icons/ai';

interface DatePickerProps extends Omit<BoxProps, 'children' | 'onChange' | 'value'> {
  value: Date | null | undefined;
  onChange: (date: Date | null) => void;
  placeholder?: string;
  showYearPicker?: boolean;
  maxDate?: Date;
  minDate?: Date;
  isDisabled?: boolean;
}

export function DatePicker({
  value,
  onChange,
  placeholder,
  showYearPicker = false,
  maxDate,
  minDate,
  isDisabled,
  ...rest
}: DatePickerProps) {
  const datePickerRef = useRef<any>();
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps): ReactNode => {
    return (
      <Box m='md'>
        <Box display='flex' mb='md' justifyContent='space-between'>
          <Button
            variant='ghost'
            onClick={() => {
              onChange(null);
              datePickerRef?.current?.setOpen(false);
            }}
            disabled={isNil(value)}>
            Clear
          </Button>
          <Button
            variant='ghost'
            onClick={() => {
              datePickerRef?.current?.setOpen(false);
            }}>
            Close
          </Button>
        </Box>
        <Box display='flex'>
          <IconButton
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            aria-label='Previous month'
            colorScheme='transparent'
            icon={<Icon color='primary' boxSize={10} as={CalendarLeftArrow} />}
          />

          <Box display='flex' justifyContent='space-between' flexGrow={1} px='md'>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => {
                changeYear(Number(value));
              }}
              style={{ width: '48%', height: '100%' }}>
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
              style={{ width: '48%', height: '100%' }}>
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </Box>

          <IconButton
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            aria-label='Next month'
            colorScheme='transparent'
            icon={<Icon color='primary' boxSize={10} as={CalendarRightArrow} />}
          />
        </Box>
      </Box>
    );
  };

  const daySize: string = '3.5rem';
  const overrideSx = {
    '& .react-datepicker': {
      fontFamily: 'inherit',
    },

    '& .react-datepicker__day, & .react-datepicker__day-name': {
      width: daySize,
      lineHeight: daySize,
    },

    '& .react-datepicker__day--keyboard-selected, & .react-datepicker__day--selected': {
      backgroundColor: 'primary',
    },

    '& .react-datepicker__header': {
      borderBottom: 'none',
    },
  };

  const DatePickerCustomInput = forwardRef<HTMLButtonElement>(
    // eslint-disable-next-line react/prop-types
    ({ onClick }: any, ref: React.Ref<HTMLButtonElement>) => (
      <IconButton
        isDisabled={isDisabled}
        onClick={onClick}
        ref={ref}
        aria-label='activate-date-picker'
        variant='ghost'
        borderColor='gray.200'
        borderWidth={1}
        borderLeftRadius={0}
        borderLeft={0}
        width={1}>
        <Icon color='black' boxSize={5} as={AiOutlineCalendar} />
      </IconButton>
    ),
  );

  return (
    <Flex width='100%' sx={overrideSx} {...rest}>
      <RenderDateInput
        isDisabled={isDisabled}
        onChange={onChange}
        value={value ?? undefined}
        {...{
          placeholder,
        }}
      />
      <ReactDatePicker
        ref={datePickerRef}
        selected={value}
        onChange={onChange}
        renderCustomHeader={renderCustomHeader}
        customInput={<DatePickerCustomInput />}
        withPortal
        maxDate={maxDate}
        minDate={minDate}

        // // RANGE
        // selectsRange={true}
        // startDate={new Date()}
        // endDate={new Date()}

        // // YEAR PICKER
        // showYearPicker
        // {...dpProps}
      />
    </Flex>
  );
}
