import { useAppDispatch, useAppSelector, uiSlice } from '../state-management';

export function useLeftNav() {
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector((state) => state.ui.isLeftNavOpen);

  function onClose() {
    dispatch(uiSlice.actions.closeLeftNav());
  }

  function onOpen() {
    dispatch(uiSlice.actions.openLeftNav());
  }

  return {
    onClose,
    onOpen,
    isOpen,
  };
}
