// Copy-Paste from https://stackoverflow.com/questions/34743264/how-to-set-iframe-content-of-a-react-component
// This component allows explicit setting of children of an IFrame

import React, { type ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

export const IFrame = ({
  children,
  props,
}: {
  children: ReactNode;
  props: React.ComponentProps<'iframe'>;
}) => {
  const [contentRef, setContentRef] = useState<any>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;

  return (
    <iframe {...props} ref={setContentRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
};
