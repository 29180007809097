import { VitalType } from '../../../__generated__/graphql';
import {
  type VitalsValidationData,
  type VitalsValidationDataMap,
  type VitalValidationItemsMap,
} from './vitals.validation.types';

export const vitalValidationData: VitalsValidationData[] = [
  {
    ageStart: 18,
    ageEnd: 64,
    gender: 'B',
    vitalItems: [
      {
        vitalName: VitalType.Bmi,
        low: [18.5],
        high: [24.9],
        invalidLow: [5],
        invalidHigh: [80],
      },
      {
        vitalName: VitalType.BloodPress,
        low: [70, 30],
        high: [139, 89],
        invalidLow: [20, 10],
        invalidHigh: [300, 200],
      },
      {
        vitalName: VitalType.HeartRate,
        low: [60],
        high: [100],
        invalidLow: [10],
        invalidHigh: [300],
      },
      {
        vitalName: VitalType.Height,
        low: null,
        high: null,
        invalidLow: [10],
        invalidHigh: [100],
      },
      {
        vitalName: VitalType.Lmp,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
      {
        vitalName: VitalType.O2,
        low: [96],
        high: [100],
        invalidLow: [40],
        invalidHigh: [101],
      },
      {
        vitalName: VitalType.Pain,
        low: [0],
        high: [6],
        invalidLow: [-1],
        invalidHigh: [11],
      },
      {
        vitalName: VitalType.RespiratoryRate,
        low: [6],
        high: [22],
        invalidLow: [2],
        invalidHigh: [80],
      },
      {
        vitalName: VitalType.Temp,
        low: [95],
        high: [100.3],
        invalidLow: [85],
        invalidHigh: [108],
      },
      {
        vitalName: VitalType.Weight,
        low: [60],
        high: [300],
        invalidLow: [20],
        invalidHigh: [500],
      },
    ],
  },
  {
    ageStart: 65,
    ageEnd: 120,
    gender: 'B',
    vitalItems: [
      {
        vitalName: VitalType.Bmi,
        low: [23],
        high: [30],
        invalidLow: [5],
        invalidHigh: [80],
      },

      {
        vitalName: VitalType.BloodPress,
        low: [70, 30],
        high: [139, 89],
        invalidLow: [20, 10],
        invalidHigh: [300, 200],
      },

      {
        vitalName: VitalType.HeartRate,
        low: [60],
        high: [100],
        invalidLow: [10],
        invalidHigh: [300],
      },
      {
        vitalName: VitalType.Height,
        low: null,
        high: null,
        invalidLow: [10],
        invalidHigh: [100],
      },

      {
        vitalName: VitalType.Lmp,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
      {
        vitalName: VitalType.O2,
        low: [96],
        high: [100],
        invalidLow: [40],
        invalidHigh: [101],
      },
      {
        vitalName: VitalType.Pain,
        low: [0],
        high: [6],
        invalidLow: [-1],
        invalidHigh: [11],
      },

      {
        vitalName: VitalType.Temp,
        low: [95],
        high: [100.3],
        invalidLow: [85],
        invalidHigh: [108],
      },

      {
        vitalName: VitalType.Weight,
        low: [60],
        high: [300],
        invalidLow: [20],
        invalidHigh: [500],
      },
    ],
  },
  {
    ageStart: 65,
    ageEnd: 120,
    gender: 'B',
    vitalItems: [
      {
        vitalName: VitalType.Bmi,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },

      {
        vitalName: VitalType.BloodPress,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },

      {
        vitalName: VitalType.HeartRate,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
      {
        vitalName: VitalType.Height,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },

      {
        vitalName: VitalType.Lmp,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
      {
        vitalName: VitalType.O2,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
      {
        vitalName: VitalType.Pain,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },

      {
        vitalName: VitalType.Temp,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },

      {
        vitalName: VitalType.Weight,
        low: null,
        high: null,
        invalidLow: null,
        invalidHigh: null,
      },
    ],
  },
];

let _convertedValidationData: VitalsValidationDataMap[];
export const getValidationDataMap = (): VitalsValidationDataMap[] => {
  if (!_convertedValidationData) {
    _convertedValidationData = convertValidationData();
  }
  return _convertedValidationData;
};

const convertValidationData = () => {
  return vitalValidationData.reduce<VitalsValidationDataMap[]>((accum, data) => {
    accum.push({
      ageStart: data.ageStart,
      ageEnd: data.ageEnd,
      gender: data.gender,
      vitalItems: data.vitalItems.reduce<VitalValidationItemsMap>((acc, vitalItem) => {
        acc[vitalItem.vitalName] = {
          low: vitalItem.low,
          high: vitalItem.high,
          invalidLow: vitalItem.invalidLow,
          invalidHigh: vitalItem.invalidHigh,
        };
        return acc;
      }, {}),
    });
    return accum;
  }, []);
};
