import { Select } from 'chakra-react-select';
import { Box, FormLabel } from '@chakra-ui/react';
import { type ComboBoxOption } from '../../../types';
import { type ChakraStylesConfig, type SizeProp } from 'chakra-react-select/dist/types/types';
import { type SelectComponentsConfig } from 'react-select/dist/declarations/src/components';

interface BaseProps {
  isLoading: boolean;
  placeholder: string;
  onInputChange?: (input: string) => void;
  label?: string;
  required?: boolean;
  options?: ComboBoxOption[];
  isDisabled?: boolean;
  filterOption?: () => boolean;
  size?: SizeProp;
  components?: SelectComponentsConfig<ComboBoxOption, boolean, any>;
  chakraStyles?: ChakraStylesConfig<ComboBoxOption, boolean, any>;
}

interface MultiSelectProps extends BaseProps {
  isMulti: true;
  isFixed?: boolean;
  defaultValue?: ComboBoxOption[];
  onSelection: (selection: ComboBoxOption[] | undefined) => void;
}

export interface SingleSelectProps extends BaseProps {
  isMulti?: false;
  defaultValue?: ComboBoxOption;
  isFixed?: boolean;
  onSelection: (selection: ComboBoxOption | undefined) => void;
}

type Props = MultiSelectProps | SingleSelectProps;
export type ComboBoxProps = Props;

export function ComboBox({
  isLoading,
  placeholder,
  defaultValue,
  onInputChange,
  label,
  options,
  required = false,
  onSelection,
  isMulti,
  isFixed = false,
  isDisabled,
  // if we're filtering on the server, override any client-side filtering
  filterOption = () => true,
  size,
  components,
  chakraStyles,
}: Props) {
  return (
    <Box>
      {label && (
        <FormLabel fontWeight='medium' size='xs'>
          {label}
        </FormLabel>
      )}
      <Select
        size={size}
        colorScheme='brand'
        focusBorderColor='brand.500'
        isClearable={!required}
        isMulti={isMulti}
        defaultValue={defaultValue}
        filterOption={filterOption}
        useBasicStyles
        menuPosition={isFixed ? 'fixed' : 'absolute'}
        options={options ?? []}
        selectedOptionStyle='check'
        selectedOptionColorScheme='brand.500'
        placeholder={placeholder}
        closeMenuOnSelect={true}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={(selection) => {
          // typescript can't descriminate the type of the callback's parameter
          // from the union, but it is safe since 'selection' will either be
          // ComboBoxOption[] or ComboBoxOption or undefined depending on
          // wether isMulti is true or false
          onSelection(selection as ComboBoxOption & ComboBoxOption[]);
        }}
        onInputChange={(input) => {
          onInputChange?.(input);
        }}
        chakraStyles={{
          ...chakraStyles,
          container: (provided, state) => ({
            ...provided,
            width: '100%',
            ...(chakraStyles?.container ? chakraStyles?.container(provided, state) : {}),
          }),
          loadingIndicator: (provided, state) => ({
            ...provided,
            color: 'primary',
            ...(chakraStyles?.loadingIndicator
              ? chakraStyles?.loadingIndicator(provided, state)
              : {}),
            ...chakraStyles?.loadingIndicator,
          }),
        }}
        components={components}
      />
    </Box>
  );
}
