export function getVitals() {
  return [
    {
      date: '2024-04-26',
      encounter: 7669107,
      vitals: {
        WEIGHT: {
          value: '200',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '70',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '99',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '99',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '100/60',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: {
          value: '33',
          isDefault: false,
          qualifier: '',
        },
        BMI: {
          value: '29',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '13',
          isDefault: false,
          qualifier: '',
        },
        PAIN: {
          value: '2',
          isDefault: false,
          qualifier: '',
        },
        LMP: null,
        O2: {
          value: '100',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT_CM: null,
        WEIGHT_KG: null,
      },
    },
    {
      date: '2024-04-26',
      encounter: 7669107,
      vitals: {
        WEIGHT: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        HEIGHT: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        TEMP: null,
        HEART_RATE: null,
        BLOOD_PRESS: null,
        WAIST_CIRC: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        BMI: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        PAIN: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        LMP: null,
        O2: {
          value: '',
          isDefault: true,
          qualifier: '',
        },
        HEIGHT_CM: null,
        WEIGHT_KG: null,
      },
    },
    {
      date: '2023-04-25',
      encounter: 7613446,
      vitals: {
        WEIGHT: {
          value: '206.8',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.9',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '57',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '140/96',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '32.39',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: null,
        LMP: {
          value: 'MALE',
          isDefault: false,
          qualifier: '',
        },
        O2: {
          value: '98',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '93.8',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2023-04-25',
      encounter: 7613446,
      vitals: {
        WEIGHT: null,
        HEIGHT: null,
        TEMP: null,
        HEART_RATE: null,
        BLOOD_PRESS: null,
        WAIST_CIRC: null,
        BMI: null,
        RESPIRATORY_RATE: null,
        PAIN: null,
        LMP: {
          value: 'male',
          isDefault: true,
          qualifier: '',
        },
        O2: null,
        HEIGHT_CM: null,
        WEIGHT_KG: null,
      },
    },
    {
      date: '2022-11-08',
      encounter: 7218085,
      vitals: {
        WEIGHT: {
          value: '209.0',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '96.8',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '60',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '120/74',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '32.73',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '14',
          isDefault: false,
          qualifier: '',
        },
        PAIN: {
          value: '0',
          isDefault: false,
          qualifier: '',
        },
        LMP: {
          value: 'male',
          isDefault: false,
          qualifier: '',
        },
        O2: {
          value: '99',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '94.8',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2022-01-13',
      encounter: 6441658,
      vitals: {
        WEIGHT: {
          value: '207.6',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.4',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '72',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '118/82',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '32.51',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: null,
        LMP: {
          value: 'male',
          isDefault: false,
          qualifier: '',
        },
        O2: null,
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '94.17',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2021-10-25',
      encounter: 6304263,
      vitals: {
        WEIGHT: {
          value: '206.0',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.1',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '68',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '124/80',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '32.26',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: null,
        LMP: {
          value: 'male',
          isDefault: false,
          qualifier: '',
        },
        O2: null,
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '93.44',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2021-02-26',
      encounter: 5669154,
      vitals: {
        WEIGHT: {
          value: '200.8',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '96.8',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '70',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '112/70',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '31.45',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: null,
        LMP: {
          value: 'male',
          isDefault: false,
          qualifier: '',
        },
        O2: null,
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '91.08',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2020-09-23',
      encounter: 5274657,
      vitals: {
        WEIGHT: {
          value: '191 lb 6 oz',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.8',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '73',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '116/80',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '29.97',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: {
          value: '7',
          isDefault: false,
          qualifier: '',
        },
        LMP: null,
        O2: {
          value: '98',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '86.81',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2019-09-24',
      encounter: 4327796,
      vitals: {
        WEIGHT: {
          value: '191.6',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.2',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '64',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '108/70',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '30.01',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '14',
          isDefault: false,
          qualifier: '',
        },
        PAIN: {
          value: '6',
          isDefault: false,
          qualifier: '',
        },
        LMP: null,
        O2: null,
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '86.91',
          isDefault: false,
          qualifier: '',
        },
      },
    },
    {
      date: '2018-05-17',
      encounter: 3135618,
      vitals: {
        WEIGHT: {
          value: '197.8',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT: {
          value: '67',
          isDefault: false,
          qualifier: '',
        },
        TEMP: {
          value: '98.3',
          isDefault: false,
          qualifier: '',
        },
        HEART_RATE: {
          value: '65',
          isDefault: false,
          qualifier: '',
        },
        BLOOD_PRESS: {
          value: '126/78',
          isDefault: false,
          qualifier: '',
        },
        WAIST_CIRC: null,
        BMI: {
          value: '30.98',
          isDefault: false,
          qualifier: '',
        },
        RESPIRATORY_RATE: {
          value: '16',
          isDefault: false,
          qualifier: '',
        },
        PAIN: null,
        LMP: {
          value: 'male',
          isDefault: false,
          qualifier: '',
        },
        O2: {
          value: '98',
          isDefault: false,
          qualifier: '',
        },
        HEIGHT_CM: {
          value: '170.18',
          isDefault: false,
          qualifier: '',
        },
        WEIGHT_KG: {
          value: '89.72',
          isDefault: false,
          qualifier: '',
        },
      },
    },
  ];
}
