import {
  Table,
  Thead,
  Tbody,
  Tr,
  TableContainer,
  Switch,
  FormControl,
  Box,
  Td,
  SkeletonText,
  Button,
} from '@chakra-ui/react';
import { ChipCell, UserCell, HeadCell } from './Cells';
import { useState } from 'react';
import {
  type OrderableItemReviewedStatus,
  type GetProviderEncounterListItemsQuery,
  type GetProviderEncounterListItemsQueryVariables,
} from '../../../../../__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  encounterFilterActions,
  getEncounterFilterState,
  getEncounterSort,
  useAppSelector,
} from '../../../../../state-management';
import { type ApolloQueryResult, type FetchMoreQueryOptions } from '@apollo/client';
import { Spinner } from '../../../../../components';
import { type EncounterTableHeadCell } from '../../../../../types';

const headCells: EncounterTableHeadCell[] = [
  {
    label: 'Patient Name',
    value: 'patientName',
    isAsc: true,
  },
  {
    label: 'Encounter Date',
    value: 'encounterDate',
  },
  {
    label: 'Labs',
    value: 'totalLabs',
  },
  {
    label: 'Imaging',
    value: 'totalImaging',
  },
  {
    label: 'Procedures',
    value: 'totalProcedures',
  },
];

// create typing for the fetch more
// stackoverflow.com/questions/68413130/typescript-type-for-the-fetchmore
export interface GetProviderEncounterListItemsFetchMore<
  TData = GetProviderEncounterListItemsQuery,
  TVariables = GetProviderEncounterListItemsQueryVariables,
> {
  fetchMore<TFetchData = TData, TFetchVars = TVariables>(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
      updateQuery?: (
        previousQueryResult: TData,
        options: {
          fetchMoreResult: TFetchData;
          variables: TFetchVars;
        },
      ) => TData;
    },
  ): Promise<ApolloQueryResult<TFetchData>>;
}

interface Props {
  data: NonNullable<GetProviderEncounterListItemsQuery['getProviderEncounterListItems']>['items'];
  status: OrderableItemReviewedStatus;
  loading: boolean;
  fetchingMore: boolean;
  setFetchingMore: React.Dispatch<React.SetStateAction<boolean>>;
  totalRecordsAvailable: number;
  fetchMore: GetProviderEncounterListItemsFetchMore['fetchMore'];
}

export function EncounterTable({
  data,
  status,
  loading,
  fetchingMore,
  setFetchingMore,
  totalRecordsAvailable,
  fetchMore,
}: Props) {
  const { onlyDelinquent } = useAppSelector(getEncounterFilterState);
  const encounterSort = useAppSelector(getEncounterSort);
  const dispatch = useDispatch();

  return (
    <Box>
      <FormControl
        color='gray.600'
        fontSize='sm'
        fontWeight='semibold'
        justifyContent='end'
        mb={4}
        display='flex'
        gap={2}
        alignItems='center'>
        <label htmlFor='delinquent'>Only delinquent Results</label>
        <Switch
          checked={onlyDelinquent}
          onChange={(e: any) => {
            dispatch(
              encounterFilterActions.onFilterStateChange({ onlyDelinquent: e.target.checked }),
            );
          }}
          colorScheme='brand'
          id='delinquent'
        />
      </FormControl>
      <TableContainer minHeight={500}>
        <SkeletonText isLoaded={!loading} noOfLines={12} skeletonHeight={5} width='100%'>
          <Table fontSize='sm'>
            <Thead>
              <Tr>
                {headCells.map((cell) => (
                  <HeadCell key={cell.label} cell={cell} encounterSort={encounterSort} />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((encounter, i) => (
                <EncounterTableRow status={status} key={i} encounter={{ ...encounter }} />
              ))}
            </Tbody>
          </Table>
          {data?.length < totalRecordsAvailable && (
            <Button
              variant='ghost'
              width='100%'
              // Height of EncounterTableRow
              height='105px'
              onClick={() => {
                setFetchingMore(true);
                fetchMore({
                  variables: {
                    offset: data?.length,
                  },
                  updateQuery(previousQueryResult, { fetchMoreResult }) {
                    return {
                      getProviderEncounterListItems: {
                        items: [
                          ...(previousQueryResult?.getProviderEncounterListItems?.items || []),
                          ...(fetchMoreResult?.getProviderEncounterListItems?.items || []),
                        ],
                        total: fetchMoreResult?.getProviderEncounterListItems?.total || 0,
                      },
                    };
                  },
                });
              }}>
              {fetchingMore ? <Spinner /> : 'Load More'}
            </Button>
          )}
        </SkeletonText>
      </TableContainer>
    </Box>
  );
}

function EncounterTableRow({
  encounter,
  status,
}: {
  status: OrderableItemReviewedStatus;
  encounter: Props['data'][0];
}) {
  const [rowHovered, setRowHovered] = useState(false);
  const [tagHovered, setTagHovered] = useState<boolean>(false);
  const showHover = rowHovered && !tagHovered;

  const nav = useNavigate();

  return (
    <Tr
      onClick={() => {
        nav(
          `/providers/${encounter.encounterId}?status=${status}&patientId=${encounter.patientId}`,
        );
      }}
      cursor={rowHovered ? 'pointer' : 'default'}
      onMouseEnter={() => {
        setRowHovered(true);
      }}
      onMouseLeave={() => {
        setRowHovered(false);
      }}
      bg={showHover ? 'gray.100' : ''}
      key={encounter.encounterId}>
      <UserCell {...encounter} tagHovered={tagHovered} setTagHovered={setTagHovered} />
      <Td>{encounter.encounterDate?.split('T')?.[0]}</Td>
      <ChipCell {...encounter.labs} />
      <ChipCell {...encounter.imaging} />
      <ChipCell {...encounter.procedures} />
    </Tr>
  );
}
