import { type Allergy } from '../../../__generated__/graphql';

/**
 * Converts 
      allergies: [
        { allergyName: "Antibiotics", reaction: "", severity: "" },
        { allergyName: "Peanuts", reaction: "", severity: "severe" },
      ],

      TO

      "Allergies: Antibiotics, Peanuts (severe)"
 * @param allergies 
 * @returns 
 */
export function formatAllergies(allergies: Allergy[]) {
  const formattedAllergies = allergies.reduce((acc, { agentSubstance, criticality }, i) => {
    const isLastIndex = allergies.length - 1 === i;
    const criticalityText = `${criticality ? `(${criticality})` : ''}`;
    const allergyText = `${agentSubstance ? `${agentSubstance}${isLastIndex ? '' : ','}` : ''}`;
    return `${acc}${allergyText} ${criticalityText}`;
  }, '');

  return `Allergies: ${formattedAllergies || 'None'}`;
}
