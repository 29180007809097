import { Box, Card, Flex } from '../../components';
import React, { useState } from 'react';
import { Spinner, Text } from '@chakra-ui/react';
import { IoIosHeartEmpty, IoMdHeart } from 'react-icons/io';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { type EncounterTemplateMetaDataUI } from '../../types';

type Props = {
  template: EncounterTemplateMetaDataUI;
  isSelected?: boolean;
  isDisabled?: boolean;
  favoriteClickHandler: (templateId: number) => Promise<void>;
  rowClickHandler: (templateId: number) => void;
  previewClickHandler: (templateId: number) => void;
};

export const EncounterTemplateRow = ({
  template,
  isSelected,
  isDisabled,
  favoriteClickHandler,
  rowClickHandler,
  previewClickHandler,
}: Props) => {
  const [isSavingFavorites, setIsSavingFavorites] = useState(false);
  return (
    <Card
      mb='sm'
      padding='sm'
      paddingY='md'
      paddingX='md'
      borderRadius='lg'
      display='flex'
      flexDir='row'
      justifyContent='space-between'
      onClick={() => {
        if (isDisabled) return;
        rowClickHandler(template.templateId);
      }}
      _hover={{
        textColor: 'brand.500',
        cursor: 'pointer',
      }}
      alignItems='center'>
      <Text fontWeight={600}>{template.name}</Text>
      <Flex alignItems='center'>
        <Text
          color='brand.500'
          cursor='pointer'
          _disabled={{ opacity: 0.5, cursor: 'initial' }}
          aria-disabled={isDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDisabled) return;
            previewClickHandler(template.templateId);
          }}>
          Preview
        </Text>
        <Box ml='sm'>
          {isSavingFavorites ? (
            <Box ml='7px'>
              <Spinner color='secondary' style={{ width: 28, height: 28 }} />
            </Box>
          ) : template.isFavorite ? (
            <IoMdHeart
              opacity={isDisabled ? 0.5 : 1}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (isDisabled) return;
                setIsSavingFavorites(true);
                favoriteClickHandler(template.templateId).finally(() => {
                  setIsSavingFavorites(false);
                });
                e.stopPropagation();
              }}
              cursor='pointer'
              fill='#F26628'
              size={35}
            />
          ) : (
            <IoIosHeartEmpty
              opacity={isDisabled ? 0.5 : 1}
              onClick={(e) => {
                if (isDisabled) return;
                setIsSavingFavorites(true);
                favoriteClickHandler(template.templateId).finally(() => {
                  setIsSavingFavorites(false);
                });
                e.stopPropagation();
              }}
              cursor='pointer'
              fill='#7E90CF'
              size={35}
            />
          )}
        </Box>
        <Box ml='sm'>
          <BsFillCheckCircleFill
            opacity={isDisabled ? 0.5 : 1}
            color={isSelected ? '#F26628' : 'rgba(126, 144, 207, .3)'}
            size={35}
          />
        </Box>
      </Flex>
    </Card>
  );
};
