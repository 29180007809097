import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  useCallbackRef,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HL7LabResult } from './assets/HL7LabResultStylesheet';
import Phone from './assets/phone.jpg';
import { style } from './assets/style';
import { IFrame } from '../../../../../../components/general/IFrame/IFrame';

interface Props {
  xml: string;
}

export function ReportPanel({ xml }: Props) {
  return (
    <DrawerContent>
      <DrawerBody
        style={{
          padding: 0,
        }}>
        <DrawerCloseButton sx={{ '@media print': { display: 'none' } }} />

        <Box height='100%' width='100%'>
          <Flex>
            <Box
              minHeight='100vh'
              overflowY='auto'
              width='300px'
              backgroundColor='#ED4924'
              sx={{ '@media print': { display: 'none' } }}
            />
            <Flex width='100%' sx={{ '@media print': { width: '100%' } }} marginTop='50px'>
              <DetailedReport xml={xml} />
            </Flex>
          </Flex>
        </Box>
      </DrawerBody>
    </DrawerContent>
  );
}

export function DetailedReport({ xml }: Props) {
  const [xmlHtml, setXmlHtml] = useState<any>(null);

  // Parse XML and apply XSL stylesheet
  useEffect(() => {
    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(new DOMParser().parseFromString(HL7LabResult, 'text/xml'));
    const resultDocument = xsltProcessor.transformToFragment(
      new DOMParser().parseFromString(xml, 'text/xml'),
      document,
    );

    // Create fragment from parsed and styled XML
    const rootElement = document.createElement('div');
    const frag = document.createDocumentFragment();
    frag.appendChild(resultDocument);
    rootElement.appendChild(frag);

    setXmlHtml(`${rootElement.innerHTML}\n<style>${style}</style`);
  }, []);

  // This ref fixes <img> src attributes phone images
  const htmlRef = useCallbackRef((node) => {
    if (node) {
      const iFrame = document.getElementById('report-iframe') as any;
      Array.from(iFrame.contentWindow.document.body.getElementsByClassName('i-phone')).forEach(
        (img: any) => {
          img.src = Phone;
        },
      );
    }
  });

  return (
    xmlHtml && (
      <Box paddingBottom='60px' width='98%'>
        <IFrame props={{ style: { height: '100vh', width: '100%' }, id: 'report-iframe' }}>
          {/* Inject XML into a DOM element */}
          <div dangerouslySetInnerHTML={{ __html: xmlHtml }} ref={htmlRef} />
        </IFrame>

        <Flex
          justifyContent='center'
          position='absolute'
          bottom={0}
          marginBottom='12px'
          width='80%'
          sx={{ '@media print': { display: 'none' } }}>
          <Button
            variant='solid'
            width='94%'
            marginRight='24px'
            onClick={() => {
              window.print();
            }}>
            Print Report
          </Button>
        </Flex>
      </Box>
    )
  );
}
