import { Box, HStack, type RadioGroupProps, useRadio, useRadioGroup } from '@chakra-ui/react';
import React from 'react';

// export default function SelectStatus() {
//   return (
//     <div>SelectStatus</div>
//   )
// }

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='2px'
        borderRadius='md'
        boxShadow='md'
        borderColor='secondary'
        color='secondary'
        _checked={{
          bg: 'secondary',
          color: 'white',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px='2'
        py='1'>
        {props.children}
      </Box>
    </Box>
  );
}

export const options: SelectStatusOptions = {
  T: 'Taking',
  N: 'Not-Taking/PRN',
  U: 'Unknown',
  D: 'Discontinued',
};

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export function SelectStatus({
  onChange,
  value,
  defaultValue,
}: {
  onChange: RadioGroupProps['onChange'];
  value?: MedStatus;
  defaultValue?: MedStatus;
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'medication-status',
    value,
    defaultValue,
    onChange,
  });

  const group = getRootProps();
  return (
    <HStack {...group} spacing='xs'>
      {Object.keys(options).map((label) => {
        const value = options[label as keyof SelectStatusOptions];
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {label}
          </RadioCard>
        );
      })}
    </HStack>
  );
}
