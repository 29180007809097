import { Text, Button, SimpleGrid, Box, Card, Flex, Tooltip } from '@chakra-ui/react';

import { formatDate, formatTime } from '../../../../../utils';
import useOpenTEsQuery from '../../../hooks/useOpenTEsQuery';
import { useState } from 'react';
import { Spinner } from '../../../../../components';
import { useNavigate } from 'react-router-dom';

export function OpenTETable() {
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);
  const { data, fetchMore } = useOpenTEsQuery(() => {
    setFetchingMore(false);
  });

  const openTEs = data?.getTelephoneEncounters?.items || [];

  const nav = useNavigate();

  return (
    <Box minHeight={500}>
      {openTEs.map((telephoneEncounter, index) => {
        return (
          <Card
            key={telephoneEncounter.sourceEncounterId}
            marginTop='12px'
            marginBottom='24px'
            padding='14px'
            height='84px'>
            <SimpleGrid columns={6} gap={4} height='100%'>
              <Flex flexDirection='column' justifyContent='space-between'>
                <Text fontSize='md' color='#ED4924'>
                  {formatDate(telephoneEncounter.encounterStartDateTime || '')}{' '}
                  {formatTime(telephoneEncounter.encounterStartDateTime || '')}
                </Text>

                <Tooltip
                  label={`${telephoneEncounter.patientFirstName} ${telephoneEncounter.patientLastName}`}>
                  <Text noOfLines={1} fontSize='md' as='b'>
                    {/* Use real patient returned from TE */}
                    {telephoneEncounter.patientFirstName} {telephoneEncounter.patientLastName}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex flexDirection='column' justifyContent='space-between'>
                <Tooltip label={telephoneEncounter.assignedToUserName}>
                  <Text noOfLines={1} fontSize='sm'>
                    Assignee: {telephoneEncounter.assignedToUserName ?? 'N/A'}
                  </Text>
                </Tooltip>

                <Tooltip label={telephoneEncounter.answeredByUserName}>
                  <Text noOfLines={1} fontSize='sm'>
                    Answered: {telephoneEncounter.answeredByUserName ?? 'N/A'}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex flexDirection='column' justifyContent='space-between'>
                <Tooltip label={telephoneEncounter.reason}>
                  <Text noOfLines={1} fontSize='sm'>
                    Reason: {telephoneEncounter.reason ?? 'N/A'}
                  </Text>
                </Tooltip>

                <Tooltip label={telephoneEncounter.providerName}>
                  <Text noOfLines={1} fontSize='sm'>
                    Provider: {telephoneEncounter.providerName ?? 'N/A'}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex flexDirection='column' justifyContent='space-between'>
                <Tooltip label={telephoneEncounter.facilityName}>
                  <Text noOfLines={1} fontSize='sm'>
                    Facility: {telephoneEncounter.facilityName ?? 'N/A'}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex flexDirection='column' justifyContent='space-between'>
                <Tooltip label={telephoneEncounter.message}>
                  <Text noOfLines={1} fontSize='sm'>
                    Message: {telephoneEncounter.message ?? 'N/A'}
                  </Text>
                </Tooltip>

                <Tooltip label={telephoneEncounter.actionTaken}>
                  <Text noOfLines={1} fontSize='sm'>
                    Action Taken: {telephoneEncounter.actionTaken ?? 'N/A'}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex width='100%' justifyContent='flex-end' alignItems='center'>
                <Button
                  height='100%'
                  width='75%'
                  onClick={() => {
                    nav(`/providers/?telephoneEncounterId=${telephoneEncounter.sourceEncounterId}`);
                  }}>
                  Edit
                </Button>
              </Flex>
            </SimpleGrid>
          </Card>
        );
      })}
      {(openTEs?.length ?? 0) < (data?.getTelephoneEncounters?.total ?? 0) && (
        <Button
          variant='ghost'
          width='100%'
          // Height of TE row
          height='70px'
          marginTop='12px'
          marginBottom='24px'
          onClick={() => {
            setFetchingMore(true);
            fetchMore({
              variables: {
                offset: openTEs?.length,
              },
              updateQuery(previousQueryResult, options) {
                return {
                  getTelephoneEncounters: {
                    items: [
                      ...(previousQueryResult?.getTelephoneEncounters?.items || []),
                      ...(options.fetchMoreResult?.getTelephoneEncounters?.items || []),
                    ],
                    total: options.fetchMoreResult?.getTelephoneEncounters?.total || 0,
                  },
                };
              },
            });
          }}>
          {fetchingMore ? <Spinner /> : 'Load More'}
        </Button>
      )}
    </Box>
  );
}
