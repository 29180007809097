import React, { useEffect } from 'react';
import { FiActivity as VitalsIcon } from 'react-icons/fi';
import { CDSSContent } from './CDSSContent';
import { VitalsContent } from './VitalsContent';
import { ChiefComplaintsPanel } from './ChiefComplaintsPanel';
import { Page, UserInfo, Icon, Flex, Box, HStack, Text } from '../../components';
import { SimpleNavigatorItem } from '../../features';
import { getOrderFlow, uiActions, useAppDispatch, useAppSelector } from '../../state-management';
import { MedicalHistoryContent, MedicalHistoryIcon } from './MedicalHistoryContent';
import { AllergiesContent, AllergiesIcon } from './AllergiesContent';
import { ChiefComplaintIcon } from './ChiefComplaintsPanel/ChiefComplaintIcon';
import { MedicationsIcon } from './MedicationsContent';
import { SurgeriesContent, SurgeriesIcon } from './SurgeriesContent';
import { FamilyHistoryContent, FamilyHistoryIcon } from './FamilyHistoryContent';
import { ROSContent } from './ROS';
import { SocialHistoryContent, SocialHistoryIcon } from './SocialHistory';
import { HPIContent } from './HPI';
import { OBGYNContent } from './OBGYN';
import { useEncounter, usePatient, useWatchDirtySteps } from '../../hooks';
import { Gender } from '../../__generated__/graphql';
import { CdssIcon } from './CDSSContent/CDSSIcon';
import { EllipseMenu } from '../../components/general/EllipseMenu';
import { localStorageService } from '../../services/localStorageService';
import { MedicationsContainer } from './MedicationsContent/MedicationsContainer';

function getNavItemText(text: string) {
  return (
    <Text fontWeight='bold' fontSize='xs'>
      {text}
    </Text>
  );
}

function getNavItemIcon(icon: any) {
  return <Icon as={icon} boxSize='60%' />;
}

function getRenderConfig(
  stepId: OrderFlowSteps,
  handleStepChange: (step: OrderFlowSteps) => () => void,
  gender: Gender = Gender.Male,
) {
  const config: {
    panel: React.ReactNode;
    navItemContent: React.ReactNode;
  } = {
    panel: null,
    navItemContent: null,
  };

  switch (stepId) {
    case 'vitals': {
      config.navItemContent = getNavItemIcon(VitalsIcon);
      config.panel = <VitalsContent nextStep={handleStepChange('chief-complaint')} />;
      break;
    }
    case 'chief-complaint': {
      config.navItemContent = getNavItemIcon(ChiefComplaintIcon);
      config.panel = <ChiefComplaintsPanel nextStep={handleStepChange('hpi')} />;
      break;
    }
    case 'medications': {
      config.navItemContent = getNavItemIcon(MedicationsIcon);
      config.panel = <MedicationsContainer nextStep={handleStepChange('medical-history')} />;
      break;
    }
    case 'medical-history': {
      config.navItemContent = getNavItemIcon(MedicalHistoryIcon);
      config.panel = <MedicalHistoryContent nextStep={handleStepChange('allergies')} />;
      break;
    }
    case 'allergies': {
      config.navItemContent = getNavItemIcon(AllergiesIcon);
      config.panel = (
        <AllergiesContent
          nextStep={handleStepChange(gender === Gender.Male ? 'surgeries' : 'obgyn')}
        />
      );
      break;
    }
    case 'surgeries': {
      config.navItemContent = getNavItemIcon(SurgeriesIcon);
      config.panel = <SurgeriesContent nextStep={handleStepChange('family-history')} />;
      break;
    }
    case 'family-history': {
      config.navItemContent = getNavItemIcon(FamilyHistoryIcon);
      config.panel = <FamilyHistoryContent nextStep={handleStepChange('social-history')} />;
      break;
    }
    case 'social-history': {
      config.navItemContent = getNavItemIcon(SocialHistoryIcon);
      config.panel = <SocialHistoryContent nextStep={handleStepChange('ros')} />;
      break;
    }
    case 'obgyn': {
      config.navItemContent = getNavItemText('GYN/OB');
      config.panel = <OBGYNContent nextStep={handleStepChange('surgeries')} />;
      break;
    }
    case 'ros': {
      config.navItemContent = getNavItemText('ROS');
      config.panel = <ROSContent nextStep={handleStepChange('cdss')} />;
      break;
    }
    case 'hpi': {
      config.navItemContent = getNavItemText('HPI');
      config.panel = <HPIContent nextStep={handleStepChange('medications')} />;
      break;
    }
    case 'cdss': {
      config.navItemContent = getNavItemIcon(CdssIcon);
      config.panel = <CDSSContent />;
      break;
    }
  }

  return config;
}

export function OrderFlowScreen() {
  const orderFlow = useAppSelector(getOrderFlow);
  const { patient } = usePatient();
  const { activeEncounterID } = useEncounter();
  const currentStep = orderFlow.currentStep;
  const steps =
    patient?.gender === Gender.Male
      ? orderFlow.steps.filter((step) => step.id !== 'obgyn')
      : orderFlow.steps;
  const dispatch = useAppDispatch();

  useWatchDirtySteps();
  function handleStepChange(step: OrderFlowSteps) {
    return () => {
      localStorageService.setEncounterLastActivePage(activeEncounterID, step);
      dispatch(uiActions.onOrderFlowStepChange(step));
    };
  }

  const lastPage = localStorageService.getEncounterLastActivePage(activeEncounterID);
  useEffect(() => {
    if (lastPage) {
      dispatch(uiActions.onOrderFlowStepChange(lastPage));
    }
  }, [lastPage]);
  return (
    <Page pb='xl'>
      <Flex alignItems='center' mb='sm'>
        <Box pr='4'>
          <UserInfo />
        </Box>
        <HStack spacing={0.5} w='100%' justifyContent='space-between'>
          <HStack spacing={0.5}>
            {steps.map((step) => {
              const stepId = step.id;
              const navItemContent = getRenderConfig(stepId, handleStepChange)?.navItemContent;
              return (
                <SimpleNavigatorItem
                  width='100%'
                  active={currentStep === stepId}
                  onClick={handleStepChange(stepId)}
                  completed={step.completed}
                  key={stepId}
                  dirty={step.isDirty}>
                  {navItemContent}
                </SimpleNavigatorItem>
              );
            })}
          </HStack>
          <EllipseMenu />
        </HStack>
      </Flex>
      {getRenderConfig(currentStep, handleStepChange, patient?.gender)?.panel}
    </Page>
  );
}
