export function submitImmunizationInjection() {
  return {
    sourcePatientId: 600754,
    sourceEncounterId: 7811422,
    immunizationId: 797753,
    immunizationName: null,
    immunizationItemId: 529944,
    injectionLocation: null,
    dateTimeAdministered: null,
    administeredByUserId: null,
    administeredByUserName: null,
    route: null,
    dose: null,
    statusDescription: null,
    vaccinationGivenInThePast: null,
    sourceOfHistory: null,
    documentingFacilityId: null,
    documentingFacilityName: null,
    patientImmunizationInjectionType: 'IMMUNIZATION',
    lotId: null,
    lotNumber: null,
    vfcCode: null,
    dateVISGiven: null,
    dateOnVIS: null,
  };
}
