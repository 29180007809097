import { useEffect } from 'react';
import { Grid, GridItem, VStack } from '../../../components';
import { type UpdateEncounterHPIInput } from '../../../types';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { HPICategories } from './HPICategories';
import { HPIPanel } from './HPIPanel';
import {
  useAppUI,
  useCustomToast,
  useEncounter,
  useMAWorkflow,
  usePatient,
  useSteps,
} from '../../../hooks';
import { uiActions, useAppDispatch, useAppSelector } from '../../../state-management';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import {
  type HpiCategoryDataInput,
  useGetEncounterHpiQuery,
  useUpdateEncounterHpiMutation,
} from '../../../__generated__/graphql';
import { AddItemEmptyStatePlaceholderLeft } from '../../../components/general/AddItemEmptyStatePlaceholderLeft';

export function HPIContent({ nextStep }: { nextStep: () => void }) {
  const dispatch = useAppDispatch();
  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();
  const toast = useCustomToast();
  const {
    hpi: { selectedEncounterCategory, selectedEncounterHPICategories },
  } = useMAWorkflow();
  const [handleUpdateEncounterHPI, { loading: isLoadingUpdate }] = useUpdateEncounterHpiMutation();
  const { markCurrentStepComplete } = useAppUI();
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);
  const {
    data: encounterHPIData,
    error,
    refetch: refetchHpiData,
  } = useGetEncounterHpiQuery({
    variables: { encounterId: activeEncounterID, patientId },
    skip: !activeEncounterID || !patientId,
  });
  const encounterHPI = encounterHPIData?.getEncounterHPI;
  useEffect(() => {
    if (!error) {
      dispatch(uiActions.setHasLoadedInitialData('hpi'));
    }
  }, [error]);

  const { steps } = useSteps();

  useEffect(() => {
    const isDirty = steps.hpi.isDirty; // If items exist in Redux, don't overwrite them
    if (encounterHPI && !isDirty) {
      dispatch(uiActions.setInitialEncounterHPIState(encounterHPI));
      dispatch(uiActions.setHPISelectedEncounterCategories(encounterHPI?.hpiCategoryData || []));
      if (encounterHPI?.hpiCategoryData && encounterHPI?.hpiCategoryData.length) {
        dispatch(uiActions.setHPISelectedEncounterCategory(encounterHPI?.hpiCategoryData[0]));
        dispatch(
          uiActions.setHPISelectedCategoryId(encounterHPI?.hpiCategoryData[0].hpiCategoryId || -1),
        );
      }
    }
  }, [encounterHPI]);

  useEffect(() => {
    void refetchHpiData();
  }, [lastUpdate]);

  function save() {
    const updateEncounterHPI: UpdateEncounterHPIInput = {
      encounterId: activeEncounterID,
      patientId,
      hpiCategoryData:
        selectedEncounterHPICategories?.map((categoryDataItem) => {
          // assign everything but the name, the name is not needed in the update query and causes error
          const item: HpiCategoryDataInput = {
            hpiCategoryId: categoryDataItem.hpiCategoryId,
            hpiCategoryNotesHeader: categoryDataItem.hpiCategoryNotesHeader,
            encounterHPIDataItems:
              categoryDataItem?.encounterHPIDataItems?.map((dataItem) => {
                return {
                  hpiCategoryId: dataItem.hpiCategoryId || -1,
                  hpiSymptomId: dataItem.hpiSymptomId || -1,
                  complainsDenies: dataItem.complainsDenies,
                  duration: dataItem.duration || '',
                  symptomNotes: dataItem.symptomNotes || '',
                  hpiStructuredData: dataItem.hpiStructuredData || [],
                };
              }) || [],
          };
          return item;
        }) || [],
    };

    void handleUpdateEncounterHPI({
      variables: updateEncounterHPI,
      onCompleted: () => {
        toast({
          id: 'isSuccessUpdate',
          title: 'Success',
          description: 'Your submission was successful.',
          status: 'success',
        });
        markCurrentStepComplete();
        nextStep();
      },
      onError: () => {
        toast({
          id: 'isErrorUpdate',
          title: 'Error',
          description: 'Something went wrong.',
          status: 'error',
        });
      },
    });
  }

  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg' alignItems='flex-start' flexGrow={1} overflow='auto'>
        <Grid
          w='full'
          templateColumns={{ md: 'repeat(12, 1fr)' }}
          gap={4}
          flexGrow={1}
          overflow='auto'>
          <GridItem colSpan={{ md: 3 }} h='full' overflow='hidden'>
            <MAWorkFlowHeader mb='md'>HPI</MAWorkFlowHeader>
            <HPICategories categoryData={selectedEncounterHPICategories} />
          </GridItem>

          <GridItem colSpan={{ md: 9 }}>
            {selectedEncounterCategory ? (
              <HPIPanel
                hpiCategoryDataItem={selectedEncounterCategory}
                setHpiCategoryDataItem={(updatedHpiCategoryDataItem) => {
                  // update the category in the selected categories list
                  dispatch(
                    uiActions.updateHPISelectedEncounterCategory(updatedHpiCategoryDataItem),
                  );
                  // updating the selected one
                  dispatch(uiActions.setHPISelectedEncounterCategory(updatedHpiCategoryDataItem));
                }}
              />
            ) : (
              <AddItemEmptyStatePlaceholderLeft text='To get started, search and add items to the HPI.' />
            )}
          </GridItem>
        </Grid>
      </VStack>
      <SaveAndWorklistButtons onClick={save} isLoading={isLoadingUpdate}>
        Save / Verify HPI
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}
