import { type GetWhoDataQuery } from '../../../__generated__/graphql';
import { type GrowthChartTabMetadata } from '../../../types';

export function formatWHOData(data: GetWhoDataQuery) {
  const formattedData: Record<string, number[]> = {};

  data?.getWHOData?.data.forEach((dataPoint) => {
    if (dataPoint.percentile in formattedData) {
      formattedData[dataPoint.percentile].push(dataPoint.value);
    } else {
      formattedData[dataPoint.percentile] = [dataPoint.value];
    }
  });

  return formattedData;
}

export const tabMetadata: GrowthChartTabMetadata[] = [
  {
    label: ['Weight'],
    dataType: ['weight_baby', 'weight_adolescent'],
    xAxis: ['Age'],
    xAxisUnit: ['mo.', 'yr.'],
    yAxis: ['Weight'],
    yAxisUnit: ['lb.'],
    min: [0, 24],
    max: [24, 240],
    annotationXOffset: [0.3, 2.5],
    annotationPadding: [1, 4],
  },
  {
    label: ['Length', 'Stature'],
    dataType: ['length', 'stature'],
    xAxis: ['Age'],
    xAxisUnit: ['mo.', 'yr.'],
    yAxis: ['Length', 'Stature'],
    yAxisUnit: ['in.', 'in.'],
    min: [0, 24],
    max: [24, 240],
    annotationXOffset: [0.3, 2.5],
    annotationPadding: [1, 4],
  },
  {
    label: ['Weight for Length', 'BMI'],
    dataType: ['weight_for_length', 'bmi'],
    xAxis: ['Length', 'Age'],
    xAxisUnit: ['in.', 'yr.'],
    yAxis: ['Weight', 'BMI'],
    yAxisUnit: ['lb.'],
    min: [18, 24],
    max: [43, 240],
    annotationXOffset: [0.3, 2.5],
    annotationPadding: [2, 4],
  },
  {
    label: ['Head Circumference'],
    dataType: ['head_circ'],
    xAxis: ['Age'],
    xAxisUnit: ['mo.', 'yr.'],
    yAxis: ['Circ.'],
    yAxisUnit: ['in.'],
    min: [0],
    max: [24],
    annotationXOffset: [0.3, 1.3],
    annotationPadding: [1, 4],
  },
];
