import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SelectDiagnosis, Card, Spinner } from '../../../components';
import {
  useUpdateOrderableItemAndResultsMutation,
  type UpdateOrderableItemInput,
  type EncounterOrderableItem,
  useDeleteOrderableItemMutation,
} from '../../../__generated__/graphql';
import { useAuth, useCustomToast } from '../../../hooks';
import { useState } from 'react';
import { useAppDispatch, worklistTodoActions } from '../../../state-management';

// Eventually this would be gotten from the backend
const defaultDiagnosisList = [
  {
    // Colorectal & Hemoccult
    orderableItemId: 229159,
    defaultIcdCode: 472176,
  },
  {
    // Dexa
    orderableItemId: 195029,
    defaultIcdCode: 472200,
  },
  {
    // Hep C Screen
    orderableItemId: 303868,
    defaultIcdCode: 474233,
  },
  {
    // Mammogram
    orderableItemId: 237349,
    defaultIcdCode: 471982,
  },
  {
    // PSA
    orderableItemId: 529173,
    defaultIcdCode: 487195,
  },
];

export function TreatmentPlanOrderCard({ order }: { order?: EncounterOrderableItem | null }) {
  const [isEditing, setIsEditing] = useState(false);
  const toast = useCustomToast();
  const [updateOrderableItemAndResults, { loading }] = useUpdateOrderableItemAndResultsMutation();
  // TODO: Should we use this for update orderable item?
  const ecwId = useAuth().user?.ecwId;
  const reviewedByUserId = ecwId ? Number(ecwId) : null;
  const renderDeleteButton = <DeleteButton order={order} />;
  const orderHasDefaultDiagnosis = defaultDiagnosisList.some(
    (d) => d.orderableItemId === order?.labItemId,
  );

  if (!order) {
    return null;
  }

  return (
    <Card>
      <Box mb='md'>
        <Text variant='h6-b'>{order?.orderableItemName}</Text>
      </Box>
      <Box>
        {isEditing ? (
          <Flex alignItems='flex-end' gap='sm'>
            {loading ? (
              <Box flex='1' alignSelf='center'>
                <Spinner />
              </Box>
            ) : (
              <SelectDiagnosis
                showLabel={false}
                value={order?.primaryDiagnosisId ?? undefined}
                onChange={(diagnosis) => {
                  if (order?.orderableReportID && reviewedByUserId) {
                    const input: UpdateOrderableItemInput = {
                      orderableReportID: order?.orderableReportID,
                      fasting: order?.fasting ?? false,
                      reviewedByUserId,
                      assessementItemId: diagnosis?.assessmentItemId,
                    };

                    updateOrderableItemAndResults({
                      variables: {
                        orderableItem: input,
                      },
                      update: (cache) => {
                        // Invalidate cache
                        cache.evict({
                          fieldName: 'getEncounterOrders',
                        });
                      },
                      onError: (error) => {
                        toast({
                          id: 'update-oi-toast-error',
                          title: 'Error',
                          description: error.message,
                          status: 'error',
                        });
                      },
                      onCompleted: () => {
                        toast({
                          id: 'update-oi-toast-success',
                          title: 'Success',
                          description: 'Changed sucessfully',
                          status: 'success',
                        });
                        setIsEditing(false);
                      },
                    });
                  }
                }}
              />
            )}
            <Button
              variant='ghost'
              onClick={() => {
                setIsEditing(false);
              }}>
              Cancel Edit
            </Button>
            {renderDeleteButton}
          </Flex>
        ) : (
          <Flex justifyContent='space-between' alignItems={'center'} width='full'>
            <Text>Diagnosis: {order?.primaryDiagnosis ? order.primaryDiagnosis : 'None'}</Text>
            <Flex gap='sm'>
              {orderHasDefaultDiagnosis ? null : (
                <Button
                  variant='outline'
                  onClick={() => {
                    setIsEditing(true);
                  }}>
                  Edit
                </Button>
              )}
              {renderDeleteButton}
            </Flex>
          </Flex>
        )}
      </Box>
    </Card>
  );
}

function DeleteButton({ order }: { order?: EncounterOrderableItem | null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderName = order?.orderableItemName;
  const toast = useCustomToast();
  const dispatch = useAppDispatch();

  const [deleteOrderableItem, { loading: isLoadingDeleteOrderableItem }] =
    useDeleteOrderableItemMutation();

  function handleDelete() {
    if (!order) {
      return;
    }

    deleteOrderableItem({
      variables: { orderableReportId: order.orderableReportID ?? -1 },
      update: (cache) => {
        // Invalidate cache
        cache.evict({
          fieldName: 'getEncounterOrders',
        });
      },
      onCompleted: () => {
        toast({
          id: 'delete-order-error',
          title: 'Success',
          description: 'Item Deleted',
          status: 'success',
        });
        dispatch(worklistTodoActions.removeLabItems([order.orderableReportID || -1]));
        onClose();
      },
      onError: (error) => {
        toast({
          id: 'delete-order-success',
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
        });
      },
    });
  }

  return (
    <>
      <Button variant='outline' onClick={onOpen}>
        Delete
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Remove ${orderName} From The Treatment Plan?`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{`Are you sure you want to remove ${orderName} from the Treatment Plan?`}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={'sm'} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete} isLoading={isLoadingDeleteOrderableItem}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
