import { useState } from 'react';
import { Box, Flex, HStack, Icon, Input, Text, VStack, WaitUntilLoaded } from '../../../components';
import { DEBOUNCE_MILLISECONDS } from '../../../constants';
import { useDebounce } from '../../../hooks';
import { AiOutlinePlus as AddIcon } from 'react-icons/ai';
import { useGetHpiCategoriesQuery } from '../../../__generated__/graphql';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { type HPICategoryDataItem } from '../../../types';
import { HPIExistingEncounterSymptomsPanel } from './HPIExistingEncounterSymptomsPanel';
import { uiActions, useAppDispatch } from '../../../state-management';

interface HPICategoriesProps {
  categoryData?: HPICategoryDataItem[] | null;
}

export function HPICategories({ categoryData }: HPICategoriesProps) {
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);
  const dispatch = useAppDispatch();
  const { data: hpiCategoriesData, loading: isLoading } = useGetHpiCategoriesQuery({
    variables: { contains: debouncedSearchText },
  });
  const hpiCategories = hpiCategoriesData?.getHPICategories || [];

  const clickHandler = (encounterCategory: HPICategoryDataItem, symptomId?: number) => {
    dispatch(uiActions.setHPISelectedCategoryId(encounterCategory.hpiCategoryId || -1));
    dispatch(uiActions.setHPISelectedSymptomId(symptomId || -1));
    dispatch(uiActions.setHPISelectedEncounterCategory(encounterCategory));
    dispatch(uiActions.addHPISelectedEncounterCategory(encounterCategory));
  };
  return (
    <VStack spacing='md' w='full' h='full' overflow='hidden' pr='md'>
      <Flex w='full'>
        <Input
          size='lg'
          borderColor='secondary'
          borderWidth='2px'
          autoFocus
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          mb='sm'
          placeholder='Search'
          _focus={{
            boxShadow: 'primary',
            borderColor: 'brand.500',
          }}
        />
      </Flex>

      <VStack w='full' align='flex-start' h='full' overflow='auto' justify='flex-start' mb='75px'>
        <WaitUntilLoaded loading={isLoading}>
          {searchText || categoryData === undefined || categoryData?.length === 0 ? (
            hpiCategories?.map((hpiCategory) => {
              const isDisabled = categoryData
                ?.map((category) => category.hpiCategoryId)
                .includes(hpiCategory?.hpiCategoryID || -1);

              return (
                <Box pl='sm' key={hpiCategory?.hpiCategoryID} w='full'>
                  <Box
                    opacity={isDisabled ? 0.5 : 1}
                    onClick={() => {
                      if (hpiCategory && !isDisabled) {
                        const newCategoryData = {
                          hpiCategoryId: hpiCategory.hpiCategoryID || null,
                          name: hpiCategory.name || null,
                          encounterHPIDataItems: [],
                          hpiCategoryNotesHeader: '',
                        };
                        clickHandler(newCategoryData);
                        setSearchText('');
                      }
                    }}
                    textAlign='left'>
                    <Box
                      role='group'
                      _hover={{ color: !isDisabled ? 'primary' : '' }}
                      cursor={isDisabled ? 'default' : 'pointer'}
                      mb={3}>
                      <VStack spacing={0}>
                        <HStack spacing='sm' justifyContent='flex-start' w='full'>
                          <Text as='b' whiteSpace='nowrap' w='full' textAlign='left'>
                            {hpiCategory?.name}
                          </Text>
                          <Icon boxSize={6} as={AddIcon} color='brand.500' />
                        </HStack>
                        {!!hpiCategory?.hpiSymptoms?.length && searchText && (
                          <Box
                            w='full'
                            color='secondary'
                            _groupHover={{ color: !isDisabled ? 'primary' : '' }}>
                            <Text fontSize='sm' as='sub' pr={1} w='full' textAlign='left'>
                              Symptoms <ChevronRightIcon />
                            </Text>
                            <Text fontSize='sm' as='sub' w='full' textAlign='left'>
                              {hpiCategory?.hpiSymptoms?.map((symptom) => symptom?.name).join(', ')}
                            </Text>
                          </Box>
                        )}
                      </VStack>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <HPIExistingEncounterSymptomsPanel
              dataItems={categoryData}
              clickHandler={clickHandler}
            />
          )}
        </WaitUntilLoaded>
      </VStack>
    </VStack>
  );
}
