// Main: https://github.com/chakra-ui/chakra-ui/issues/708#issuecomment-639113629
// Original: https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9

// Remove blue focus rings when using mouse
import 'focus-visible/dist/focus-visible';

import { Global, css } from '@emotion/react';

const globalStyles = css`
  /*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export function GlobalStylesToHideFocusRings() {
  return <Global styles={globalStyles} />;
}
