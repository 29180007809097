import {
  Button,
  Center,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { LogoWordWhite } from '../../../../../components';
import { useAuth, useNavigation } from '../../../../../hooks';

export function Menu() {
  const nav = useNavigation();
  const { handleLogout } = useAuth();

  return (
    <>
      <DrawerOverlay />
      <DrawerContent bg='brand.500' borderRadius='10px 0 0 10px'>
        <DrawerHeader padding='48px'>
          <Center>
            <LogoWordWhite width='50%' />
          </Center>
        </DrawerHeader>

        <DrawerBody>
          <Button
            onClick={nav.toFeedbackPage}
            variant='ghost'
            textColor='white'
            width='100%'
            _hover={{ bg: 'brand.400' }}>
            Feedback
          </Button>

          <Button
            onClick={handleLogout}
            variant='ghost'
            textColor='white'
            width='100%'
            _hover={{ bg: 'brand.400' }}>
            Log Out
          </Button>
        </DrawerBody>
      </DrawerContent>
    </>
  );
}
