import {
  createEntityAdapter,
  createSlice,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit';
import {
  type AllergyUI,
  type EncounterHPI,
  type HPICategoryDataItem,
  type PatientSearchScreenTabs,
} from '../types';
import { type RootState } from './hooks';
import { IS_LOCAL } from '../constants';
import { type g } from '../api';
import { isBoolean, isString } from 'lodash';
import { type EncounterVital } from '../__generated__/graphql';

// CONST
const LOCAL_CURRENT_STEP: OrderFlowSteps = 'vitals';

// ADAPTERS
const surgeriesAdapter = createEntityAdapter<SurgeryUI>({
  selectId: (item) => item.tempId, // Assume IDs are stored in a field other than `item.id`
});

const hospitalizationsAdapter = createEntityAdapter<HospitalizationUI>({
  selectId: (item) => item.tempId, // Assume IDs are stored in a field other than `item.id`
});

const medicationsAdapter = createEntityAdapter<MedicationItemUI>({
  selectId: (item) => item.id, // Assume IDs are stored in a field other than `item.id`
});

const allergiesAdapter = createEntityAdapter<AllergyUI>({
  selectId: (item) => item.id, // Assume IDs are stored in a field other than `item.id`
});

const chiefComplaintsAdapter = createEntityAdapter<string>({
  selectId: (item) => item, // Assume IDs are stored in a field other than `item.id`
});

const medicalHistoryAdapter = createEntityAdapter<MedicalHistoryViewItem>({
  selectId: (item) => item.id, // Assume IDs are stored in a field other than `item.id`
});

const existingPatientMedicalHistoryAdapter = createEntityAdapter<MedicalHistoryViewItem>({
  selectId: (item) => item.id, // Assume IDs are stored in a field other than `item.id`
});

const modifiableExistingPatientMedicalHistoryAdapter = createEntityAdapter<MedicalHistoryViewItem>({
  selectId: (item) => item.id, // Assume IDs are stored in a field other than `item.id`
});

const socialHistoryAdapter = createEntityAdapter<SocialHistory>({
  selectId: (item) => item.observationId || '', // Assume IDs are stored in a field other than `item.id`
});

const rosCategoriesAdapter = createEntityAdapter<EncounterRosDataItemInputUI>({
  selectId: (item) => item.rosCategoryID, // Assume IDs are stored in a field other than `item.id`
});

export const familyMembersAdapter = createEntityAdapter<FamilyMemberUI>({
  selectId: (item) => item.tempId, // Assume IDs are stored in a field other than `item.id`
});

const gynHistoryAdapter = createEntityAdapter<g.GynHistory>({
  selectId: (item) => item.gynSymptomID ?? 0,
});

const obHistoryAdapter = createEntityAdapter<g.ObHistory>({
  selectId: (item) => item.obSymptomID ?? 0,
});

// EXTERNAL SELECTORS
export const surgeriesSelectors = surgeriesAdapter.getSelectors();
export const hospitalizationsSelectors = hospitalizationsAdapter.getSelectors();
export const medicationsSelectors = medicationsAdapter.getSelectors();
export const medicalHistorySelectors = medicalHistoryAdapter.getSelectors();
export const existingPatientMedicalHistorySelectors =
  existingPatientMedicalHistoryAdapter.getSelectors();
export const modifiableExistingPatientMedicalHistorySelectors =
  modifiableExistingPatientMedicalHistoryAdapter.getSelectors();
export const allergySelectors = allergiesAdapter.getSelectors();
export const familyMembersSelectors = familyMembersAdapter.getSelectors();
export const rosCategoriesSelectors = rosCategoriesAdapter.getSelectors();
export const chiefComplaintSelectors = chiefComplaintsAdapter.getSelectors(
  (state: RootState) => state.ui.orderFlowNavigation.addedChiefComplaints,
);
export const socialHistorySelectors = socialHistoryAdapter.getSelectors(
  (state: RootState) => state.ui.orderFlowNavigation.socialHistory.socialHistoryState,
);
// export const gynHistorySelectors = gynHistoryAdapter.getSelectors(
//   (state: RootState) => state.ui.orderFlowNavigation.obgyn.gynHistory
// );

// LOCAL SELECTORS
const localMedicationsSelector = (state: UIState) => state.orderFlowNavigation.medications;

const localSurgeriesSelector = (state: UIState) => state.orderFlowNavigation.surgeries;

const localHospitalizationsSelector = (state: UIState) =>
  state.orderFlowNavigation.hospitalizations;

const localChiefComplaintSelector = (state: UIState) =>
  state.orderFlowNavigation.addedChiefComplaints;

const localAllergySelector = (state: UIState) => state.orderFlowNavigation.addedAllergies;

const localAddedMedicalHistorySelector = (state: UIState) =>
  state.orderFlowNavigation.addedMedicalHistory;

const localExistingPatientMedicalHistorySelector = (state: UIState) =>
  state.orderFlowNavigation.existingPatientMedicalHistory;

const localModifiableExistingPatientMedicalHistorySelector = (state: UIState) =>
  state.orderFlowNavigation.modifiableExistingPatientMedicalHistory;

const localSocialHistorySelector = (state: UIState) => state.orderFlowNavigation.socialHistory;

const localROSSelector = (state: UIState) => state.orderFlowNavigation.ros;

const localOBGYNSelector = (state: UIState) => state.orderFlowNavigation.obgyn;

const localHPISelector = (state: UIState) => state.orderFlowNavigation.hpi;

const localAddedFamilyMembersSelector = (state: UIState) =>
  state.orderFlowNavigation.familyHistory.familyMembersHistory;

// TYPES
interface MedicationsSlice {
  addedMedications: EntityState<MedicationItemUI>;
  editedMedicationId?: TempId;
}
interface SurgeriesSlice {
  addedSurgeries: EntityState<SurgeryUI>;
  editedSurgeryId?: TempId;
}

interface HospitalizationsSlice {
  addedHospitalizations: EntityState<HospitalizationUI>;
  editedHospitalizationId?: TempId;
}

const vitalsInitialState: {
  heartRateVitals: EncounterVital[];
  bloodPressureVitals: EncounterVital[];
  tempVitals: EncounterVital[];
  weightVitals?: EncounterVital;
  heightVitals?: EncounterVital;
  waistCircVitals?: EncounterVital;
  bmiVitals?: EncounterVital;
  restRateVitals?: EncounterVital;
  painScaleVitals?: EncounterVital;
  lmpVitals?: EncounterVital;
  o2Sat?: EncounterVital;
} = { heartRateVitals: [], bloodPressureVitals: [], tempVitals: [] };

export interface UIState {
  isLeftNavOpen: boolean;
  patientSearchScreen: {
    currentTab: PatientSearchScreenTabs;
    providerIds: number[];
  };
  orderFlowNavigation: {
    openDrawer: 'problems' | 'labs' | undefined;
    drawerLocation: 'workflow' | 'labSubmission' | 'aoe' | 'labsTab';
    steps: Step[];
    vitals: typeof vitalsInitialState;
    currentStep: OrderFlowSteps;
    addedChiefComplaints: EntityState<string>;
    addedMedicalHistory: EntityState<MedicalHistoryViewItem>;
    existingPatientMedicalHistory: EntityState<MedicalHistoryViewItem>;
    modifiableExistingPatientMedicalHistory: EntityState<MedicalHistoryViewItem>;
    enteredChiefComplaint: string;
    chiefComplaintDebouncedSearchText: string;
    // MEDICAL HISTORY
    debouncedICDSearchText: string;
    rawICDSearchText: string;
    debouncedMedHistoryDescriptionSearchText: string;
    rawMedHistoryDescriptionSearchText: string;
    debouncedMedHistoryNameSearchText: string;
    rawMedHistoryNameSearchText: string;
    // ALLERGIES
    selectedAllergyItem?: AllergyUI;
    addedAllergies: EntityState<AllergyUI>;
    isNKDA: boolean;
    // FAMILY HISTORY
    familyHistory: FamilyHistoryUI;
    // MEDICATIONS
    medications: MedicationsSlice;
    // SURGICAL HISTORY & HOSPITALIZATION
    surgeries: SurgeriesSlice;
    hospitalizations: HospitalizationsSlice;

    // SOCIAL HISTORY
    socialHistory: {
      currentCategoryId?: SocialHistory['categoryId'];
      socialHistoryState: EntityState<SocialHistory>;
    };
    // ROS
    ros: {
      rosData: EncounterRosDataUI;
      showingCategories?: RosTab[];

      selectedROSCategoryId: number;
      selectedROSSymptomId: number | undefined;
      selectedEncounterROSCategory: EncounterRosDataItemInputUI | undefined; // TODO: Check, this might not be needed
    };
    // HPI
    hpi: {
      encounterHPIState: EncounterHPI | undefined;
      selectedHPICategoryId: number;
      selectedHPISymptomId: number | undefined;
      selectedEncounterCategory: HPICategoryDataItem | undefined;
      selectedEncounterHPICategories: HPICategoryDataItem[];
    };
    // OBGYN
    obgyn: {
      gynHistory: EntityState<g.GynHistory>;
      obHistory: EntityState<g.ObHistory>;
    };
    lastSaved: {
      vitals: typeof vitalsInitialState | null;
    };
  };
}
const initialState: UIState = {
  isLeftNavOpen: false,
  patientSearchScreen: {
    currentTab: 'To-Do',
    providerIds: [],
  },
  orderFlowNavigation: {
    openDrawer: undefined,
    drawerLocation: 'workflow',
    vitals: vitalsInitialState,
    currentStep: IS_LOCAL ? LOCAL_CURRENT_STEP : 'vitals',
    steps: [
      {
        completed: false,
        id: 'vitals',
      },
      {
        completed: false,
        id: 'chief-complaint',
      },
      {
        completed: false,
        id: 'hpi',
      },
      {
        completed: false,
        id: 'medications',
      },
      {
        completed: false,
        id: 'medical-history',
      },
      {
        completed: false,
        id: 'allergies',
      },
      {
        completed: false,
        id: 'obgyn',
      },
      {
        completed: false,
        id: 'surgeries',
      },
      {
        completed: false,
        id: 'family-history',
      },
      {
        completed: false,
        id: 'social-history',
      },

      {
        completed: false,
        id: 'ros',
      },

      {
        completed: false,
        id: 'cdss',
      },
    ],
    addedChiefComplaints: chiefComplaintsAdapter.getInitialState(),
    addedMedicalHistory: medicalHistoryAdapter.getInitialState(),
    existingPatientMedicalHistory: existingPatientMedicalHistoryAdapter.getInitialState(),
    modifiableExistingPatientMedicalHistory:
      modifiableExistingPatientMedicalHistoryAdapter.getInitialState(),
    enteredChiefComplaint: '',
    chiefComplaintDebouncedSearchText: '',
    // MEDICAL HISTORY
    debouncedICDSearchText: '',
    rawICDSearchText: '',
    debouncedMedHistoryDescriptionSearchText: '',
    rawMedHistoryDescriptionSearchText: '',
    debouncedMedHistoryNameSearchText: '',
    rawMedHistoryNameSearchText: '',
    // ALLERGIES
    addedAllergies: allergiesAdapter.getInitialState(),
    isNKDA: false,
    // FAMILY HISTORY
    // familyHistory: USE_LOCAL_MOCK ? initialFamilyHistoryTEST : {}, // TODO: Replace with real code below.
    familyHistory: {
      familyMembersHistory: familyMembersAdapter.getInitialState(),
    }, // TODO: Replace with real code below.
    // familyHistory: {
    //   // Other fields are optional
    //   familyMembersHistory: familyMembersAdapter.getInitialState(),
    // },
    // MEDICATIONS
    medications: {
      addedMedications: medicationsAdapter.getInitialState(),
    },
    // SURGICAL HISTORY & HOSPITALIZATION
    surgeries: {
      addedSurgeries: surgeriesAdapter.getInitialState(),
    },
    hospitalizations: {
      addedHospitalizations: hospitalizationsAdapter.getInitialState(),
    },
    // SOCIAL HISTORY
    socialHistory: {
      socialHistoryState: socialHistoryAdapter.getInitialState(),
    },
    // ROS
    ros: {
      rosData: {
        notes: '',
        encounterROSDataItems: [],
      },
      showingCategories: [],
      selectedROSCategoryId: -1,
      selectedROSSymptomId: -1,
      selectedEncounterROSCategory: undefined,
    },
    // HPI
    hpi: {
      encounterHPIState: { hpiCategoryData: [] },
      selectedHPICategoryId: -1,
      selectedHPISymptomId: -1,
      selectedEncounterCategory: undefined,
      selectedEncounterHPICategories: [],
    },
    // OBGYN
    obgyn: {
      gynHistory: gynHistoryAdapter.getInitialState(),
      obHistory: obHistoryAdapter.getInitialState(),
    },
    lastSaved: {
      vitals: null,
    },
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    onDrawerChange(state, action: PayloadAction<'problems' | 'labs' | undefined>) {
      state.orderFlowNavigation.openDrawer = action.payload;
    },
    onDrawerLocationChange(
      state,
      action: PayloadAction<'workflow' | 'aoe' | 'labSubmission' | 'labsTab'>,
    ) {
      state.orderFlowNavigation.drawerLocation = action.payload;
    },
    onTabChange(state, action: PayloadAction<{ selectedTab: PatientSearchScreenTabs }>) {
      const { selectedTab } = action.payload;
      state.patientSearchScreen.currentTab = selectedTab;
    },
    setProviderIds(state, action: PayloadAction<number[]>) {
      state.patientSearchScreen.providerIds = action.payload;
    },
    openLeftNav(state) {
      state.isLeftNavOpen = true;
    },
    closeLeftNav(state) {
      state.isLeftNavOpen = false;
    },
    onOrderFlowStepChange(state, action: PayloadAction<OrderFlowSteps>) {
      state.orderFlowNavigation.currentStep = action.payload;
    },
    markCurrentStepComplete(state) {
      const currentStepId = state.orderFlowNavigation.currentStep;
      const currentStep = state.orderFlowNavigation.steps.find((step) => step.id === currentStepId);
      if (typeof currentStep?.completed === 'boolean') {
        currentStep.completed = true;
      }
    },
    onChiefComplaintChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.enteredChiefComplaint = action.payload;
    },
    onChiefComplaintDebouncedSearchTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.chiefComplaintDebouncedSearchText = action.payload;
    },
    onICDCodeDebouncedSearchTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.debouncedICDSearchText = action.payload;
    },
    onRawICDSearchTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.rawICDSearchText = action.payload;
    },
    onMedHistoryNameDebouncedTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.debouncedMedHistoryNameSearchText = action.payload;
    },
    onRawMedHistoryNameTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.rawMedHistoryNameSearchText = action.payload;
    },
    onMedHistoryDescriptionDebouncedTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.debouncedMedHistoryDescriptionSearchText = action.payload;
    },
    onRawMedicalHistoryDescriptionTextChange(state, action: PayloadAction<string>) {
      state.orderFlowNavigation.rawMedHistoryDescriptionSearchText = action.payload;
    },
    addMedicalHistoryItem(state, action: PayloadAction<MedicalHistoryViewItem>) {
      medicalHistoryAdapter.addOne(localAddedMedicalHistorySelector(state), action.payload);
    },
    setExistingPatientMedicalHistoryItems(state, action: PayloadAction<MedicalHistoryViewItem[]>) {
      existingPatientMedicalHistoryAdapter.setAll(
        localExistingPatientMedicalHistorySelector(state),
        action.payload,
      );
    },
    setModifiableExistingPatientMedicalHistoryItems(
      state,
      action: PayloadAction<MedicalHistoryViewItem[]>,
    ) {
      modifiableExistingPatientMedicalHistoryAdapter.setAll(
        localModifiableExistingPatientMedicalHistorySelector(state),
        action.payload,
      );
    },
    setMedicalHistoryItems(state, action: PayloadAction<MedicalHistoryViewItem[]>) {
      medicalHistoryAdapter.setAll(localAddedMedicalHistorySelector(state), action.payload);
    },
    toggleFamilyMemberCondition(
      state,
      action: PayloadAction<{
        tempId: FamilyMemberTempId;
        icdItemId: ICDItemId;
      }>,
    ) {
      const { icdItemId, tempId } = action.payload;
      const fam = localAddedFamilyMembersSelector(state);
      if (fam != null) {
        const familyMember = familyMembersSelectors.selectById(fam, tempId);

        const familyMemberDiagnosisList = familyMember?.familyMemberDiagnosisList;

        if (familyMemberDiagnosisList != null) {
          const newFamDiagnosis = familyMemberDiagnosisList.map((condition) => {
            if (condition.icdItemId === icdItemId) {
              // update
              return { ...condition, isChecked: !condition.isChecked };
            }

            return condition;
          });

          // If the item doesn't exist in the diagnosis list, then add it and check it
          const diagnosis = familyMemberDiagnosisList.find((d) => d.icdItemId === icdItemId);
          if (diagnosis == null) {
            newFamDiagnosis.push({ icdItemId, isChecked: true });
          }

          if (fam) {
            familyMembersAdapter.updateOne(fam, {
              id: tempId,
              changes: { familyMemberDiagnosisList: newFamDiagnosis },
            });
          }
        }
      }
    },
    setFamilyMember(
      state,
      action: PayloadAction<{
        familyMember: FamilyMemberUI;
      }>,
    ) {
      const { familyMember } = action.payload;
      const fam = localAddedFamilyMembersSelector(state);
      if (fam != null) {
        familyMembersAdapter.setOne(fam, familyMember);
      }
    },
    setFamilyHistory(state, action: PayloadAction<FamilyHistoryUI>) {
      state.orderFlowNavigation.familyHistory = action.payload;
    },
    removeMedicalHistoryItem(state, action: PayloadAction<MedicalHistoryViewItem['id']>) {
      medicalHistoryAdapter.removeOne(localAddedMedicalHistorySelector(state), action.payload);
    },
    removeExistingPatientMedicalHistoryItem(
      state,
      action: PayloadAction<MedicalHistoryViewItem['id']>,
    ) {
      modifiableExistingPatientMedicalHistoryAdapter.removeOne(
        localModifiableExistingPatientMedicalHistorySelector(state),
        action.payload,
      );
    },
    addMedHistoryItemToProblemList(state, action: PayloadAction<MedicalHistoryViewItem['id']>) {
      const modifiableItem = modifiableExistingPatientMedicalHistorySelectors.selectById(
        localAddedMedicalHistorySelector(state),
        action.payload,
      );

      const existingItem = existingPatientMedicalHistorySelectors.selectById(
        localExistingPatientMedicalHistorySelector(state),
        action.payload,
      );

      const medHistoryItem = modifiableItem || existingItem;

      if (medHistoryItem) {
        const newMedHistoryItem: MedicalHistoryViewItem = {
          ...medHistoryItem,
          icdItemID: modifiableItem ? Number(modifiableItem.id) : existingItem?.icdItemID,
          addToPatientProblemList: true,
        };

        modifiableExistingPatientMedicalHistoryAdapter.setOne(
          localModifiableExistingPatientMedicalHistorySelector(state),
          newMedHistoryItem,
        );

        // Remove the item from the added medical history list
        // or else there will be 2 instances of the same item in the list
        medicalHistoryAdapter.removeOne(localAddedMedicalHistorySelector(state), medHistoryItem.id);
      }
    },
    removeMedHistoryItemFromProblemList(
      state,
      action: PayloadAction<MedicalHistoryViewItem['id']>,
    ) {
      const medHistoryItem = modifiableExistingPatientMedicalHistorySelectors.selectById(
        localModifiableExistingPatientMedicalHistorySelector(state),
        action.payload,
      );

      if (medHistoryItem != null) {
        const newMedHistoryItem: MedicalHistoryViewItem = {
          ...medHistoryItem,
          addToPatientProblemList: false,
        };

        modifiableExistingPatientMedicalHistoryAdapter.setOne(
          localModifiableExistingPatientMedicalHistorySelector(state),
          newMedHistoryItem,
        );
      }
    },
    addChiefComplaint(state, action: PayloadAction<string>) {
      chiefComplaintsAdapter.addOne(localChiefComplaintSelector(state), action.payload);
      state.orderFlowNavigation.chiefComplaintDebouncedSearchText = '';
      state.orderFlowNavigation.enteredChiefComplaint = '';
    },
    setChiefComplaints(state, action: PayloadAction<string[]>) {
      chiefComplaintsAdapter.setAll(localChiefComplaintSelector(state), action.payload);
    },
    removeChiefComplaint(state, action: PayloadAction<string>) {
      chiefComplaintsAdapter.removeOne(localChiefComplaintSelector(state), action.payload);
    },
    setSelectedAllergyItem(state, action: PayloadAction<AllergyUI>) {
      state.orderFlowNavigation.selectedAllergyItem = action.payload;
    },
    editSelectedAllergyItem(state, action: PayloadAction<Partial<AllergyUI>>) {
      const selectedAllergyItem = state.orderFlowNavigation.selectedAllergyItem;
      const updateItems = action.payload;
      if (selectedAllergyItem != null && updateItems) {
        state.orderFlowNavigation.selectedAllergyItem = {
          ...selectedAllergyItem,
          ...action.payload,
        };
      }
    },
    toggleNKDA(state) {
      state.orderFlowNavigation.isNKDA = !state.orderFlowNavigation.isNKDA;
    },
    setNKDA(state, action: PayloadAction<boolean>) {
      state.orderFlowNavigation.isNKDA = action.payload;
    },
    saveSelectedAllergyItem(state) {
      const allergyItem = state.orderFlowNavigation.selectedAllergyItem;
      if (allergyItem != null) {
        allergiesAdapter.addOne(localAllergySelector(state), allergyItem);
      }
    },
    replaceAllergyItem(state, action: PayloadAction<AllergyUI['id']>) {
      const allergyItem = state.orderFlowNavigation.selectedAllergyItem;
      const patientAllergies = state.orderFlowNavigation.addedAllergies;
      const patientAllergyItems = allergySelectors.selectAll(patientAllergies);
      const index = patientAllergyItems.findIndex((item) => item.id === allergyItem?.id);
      if (index > -1 && allergyItem != null) {
        const updatedAllergyItems = [
          ...patientAllergyItems.slice(0, index),
          allergyItem,
          ...patientAllergyItems.slice(index + 1),
        ];
        allergiesAdapter.setAll(localAllergySelector(state), updatedAllergyItems);
      }
    },
    setPatientAllergyItems(state, action: PayloadAction<AllergyUI[]>) {
      allergiesAdapter.setAll(localAllergySelector(state), action.payload);
    },
    removeAllergyItem(state, action: PayloadAction<AllergyUI['id']>) {
      allergiesAdapter.removeOne(localAllergySelector(state), action.payload);
    },
    resetSelectedAllergy(state) {
      state.orderFlowNavigation.selectedAllergyItem =
        initialState.orderFlowNavigation.selectedAllergyItem;
    },
    resetOrderFlow(state) {
      state.orderFlowNavigation = initialState.orderFlowNavigation;
    },

    // MEDICATIONS
    setAddedMedications(state, action: PayloadAction<MedicationItemUI[]>) {
      medicationsAdapter.setAll(localMedicationsSelector(state).addedMedications, action.payload);
    },
    startEditMedication(state, action: PayloadAction<string>) {
      localMedicationsSelector(state).editedMedicationId = action.payload;
    },
    cancelEditMedication(state, action: PayloadAction<string>) {
      delete localMedicationsSelector(state).editedMedicationId;
    },
    removeMedication(state, action: PayloadAction<string>) {
      medicationsAdapter.removeOne(
        localMedicationsSelector(state).addedMedications,
        action.payload,
      );
    },
    removeMedications(state, action: PayloadAction<string[]>) {
      medicationsAdapter.removeMany(
        localMedicationsSelector(state).addedMedications,
        action.payload,
      );
    },
    addMedication(state, action: PayloadAction<MedicationItemUI>) {
      medicationsAdapter.addOne(localMedicationsSelector(state).addedMedications, action.payload);
    },
    setEditedMedication(state, action: PayloadAction<MedicationItemUI>) {
      medicationsAdapter.updateOne(localMedicationsSelector(state).addedMedications, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    upsertEditedMedication(state, action: PayloadAction<MedicationItemUI[]>) {
      const addedMeds = localMedicationsSelector(state).addedMedications;
      medicationsAdapter.upsertMany(addedMeds, action.payload);
    },
    // SURGICAL HISTORY & HOSPITALIZATION
    setAddedSurgeries(state, action: PayloadAction<SurgeryUI[]>) {
      surgeriesAdapter.setAll(localSurgeriesSelector(state).addedSurgeries, action.payload);
    },
    addSurgery(state, action: PayloadAction<SurgeryUI>) {
      surgeriesAdapter.addOne(localSurgeriesSelector(state).addedSurgeries, action.payload);
    },
    removeSurgery(state, action: PayloadAction<SurgeryUI['tempId']>) {
      surgeriesAdapter.removeOne(localSurgeriesSelector(state).addedSurgeries, action.payload);
    },
    startEditSurgery(state, action: PayloadAction<TempId>) {
      localSurgeriesSelector(state).editedSurgeryId = action.payload;
    },
    cancelEditSurgery(state) {
      delete localSurgeriesSelector(state).editedSurgeryId;
    },
    setEditedSurgery(state, action: PayloadAction<SurgeryUI>) {
      surgeriesAdapter.updateOne(localSurgeriesSelector(state).addedSurgeries, {
        id: action.payload.tempId,
        changes: action.payload,
      });
    },

    setAddedHospitalizations(state, action: PayloadAction<HospitalizationUI[]>) {
      hospitalizationsAdapter.setAll(
        localHospitalizationsSelector(state).addedHospitalizations,
        action.payload,
      );
    },
    addHospitalization(state, action: PayloadAction<HospitalizationUI>) {
      hospitalizationsAdapter.addOne(
        localHospitalizationsSelector(state).addedHospitalizations,
        action.payload,
      );
    },
    removeHospitalization(state, action: PayloadAction<HospitalizationUI['tempId']>) {
      hospitalizationsAdapter.removeOne(
        localHospitalizationsSelector(state).addedHospitalizations,
        action.payload,
      );
    },
    startEditHospitalization(state, action: PayloadAction<TempId>) {
      localHospitalizationsSelector(state).editedHospitalizationId = action.payload;
    },
    cancelEditHospitalization(state) {
      delete localHospitalizationsSelector(state).editedHospitalizationId;
    },
    setEditedHospitalization(state, action: PayloadAction<HospitalizationUI>) {
      hospitalizationsAdapter.updateOne(
        localHospitalizationsSelector(state).addedHospitalizations,
        {
          id: action.payload.tempId,
          changes: action.payload,
        },
      );
    },

    // SOCIAL HISTORY
    onChangeSocialHistoryCategory(state, action: PayloadAction<SocialHistory['categoryId']>) {
      localSocialHistorySelector(state).currentCategoryId = action.payload;
    },
    addSocialHistoryList(state, action: PayloadAction<Array<Partial<SocialHistory>>>) {
      socialHistoryAdapter.setMany(
        localSocialHistorySelector(state).socialHistoryState,
        action.payload as any, // TODO: Type properly
      );
    },

    // ROS
    addRosDataItem(state, action: PayloadAction<RosTab>) {
      const rosState = localROSSelector(state);
      const { showingCategories } = rosState;
      const { rosCategoryId } = action.payload;

      if (
        showingCategories != null &&
        !showingCategories.some((c) => c.rosCategoryId === rosCategoryId)
      ) {
        const remainingCategories = showingCategories.filter((tab) =>
          rosState.rosData.encounterROSDataItems.find((s) => s.rosCategoryID === tab.rosCategoryId),
        );
        rosState.showingCategories = [...remainingCategories, action.payload].filter(
          (c) => c.rosCategoryId !== -1,
        );
      }
    },
    setRosData(state, action: PayloadAction<EncounterRosDataUI>) {
      const rosState = localROSSelector(state);
      rosState.rosData = action.payload;
    },
    setRosDataNotes(state, action: PayloadAction<string>) {
      const rosState = localROSSelector(state);
      rosState.rosData.notes = action.payload;
    },
    setAllRosDataItems(state, action: PayloadAction<EncounterRosDataItemInputUI[]>) {
      const rosState = localROSSelector(state);
      rosState.rosData.encounterROSDataItems = action.payload;
    },
    setROSSelectedCategoryId(state, action: PayloadAction<number>) {
      localROSSelector(state).selectedROSCategoryId = action.payload;
    },
    setROSSelectedSymptomId(state, action: PayloadAction<number | undefined>) {
      localROSSelector(state).selectedROSSymptomId = action.payload;
    },
    setROSSelectedEncounterCategory(state, action: PayloadAction<EncounterRosDataItemInputUI>) {
      localROSSelector(state).selectedEncounterROSCategory = action.payload;
    },
    upsertRosDataItem(state, action: PayloadAction<EncounterRosDataItemInputUI>) {
      const rosState = localROSSelector(state);
      const update = action.payload;

      const index2 = rosState.rosData.encounterROSDataItems?.findIndex(
        (x) => x.rosSymptomID === update.rosSymptomID,
      );
      if (index2 !== undefined && index2 > -1) {
        rosState.rosData.encounterROSDataItems[index2] = update;
      } else {
        rosState.rosData.encounterROSDataItems.push(update);
      }
    },
    clearAllRosCategoryNotes(state) {
      const rosState = localROSSelector(state);
      rosState.rosData.encounterROSDataItems = rosState.rosData.encounterROSDataItems.map(
        (item) => {
          return {
            ...item,
            // Clear all notes
            symptomNote: '',
          };
        },
      );
    },
    // HPI
    setEncounterHPIState(state, action: PayloadAction<EncounterHPI | undefined | null>) {
      localHPISelector(state).encounterHPIState = action.payload;
    },
    setInitialEncounterHPIState(state, action: PayloadAction<EncounterHPI | undefined | null>) {
      localHPISelector(state).encounterHPIState = action.payload;
    },
    setHPISelectedCategoryId(state, action: PayloadAction<number>) {
      localHPISelector(state).selectedHPICategoryId = action.payload;
    },
    setHPISelectedSymptomId(state, action: PayloadAction<number | undefined>) {
      localHPISelector(state).selectedHPISymptomId = action.payload;
    },
    setHPISelectedEncounterCategory(state, action: PayloadAction<HPICategoryDataItem>) {
      localHPISelector(state).selectedEncounterCategory = action.payload;
    },
    setHPISelectedEncounterCategories(state, action: PayloadAction<HPICategoryDataItem[]>) {
      localHPISelector(state).selectedEncounterHPICategories = action.payload;
    },
    addHPISelectedEncounterCategory(state, action: PayloadAction<HPICategoryDataItem>) {
      const found = localHPISelector(state).selectedEncounterHPICategories.find(
        (category) => category.hpiCategoryId === action.payload.hpiCategoryId,
      );
      if (!found) {
        localHPISelector(state).selectedEncounterHPICategories = [
          ...localHPISelector(state).selectedEncounterHPICategories,
          action.payload,
        ];
      }
    },
    updateHPISelectedEncounterCategory(state, action: PayloadAction<HPICategoryDataItem>) {
      const copy = [...localHPISelector(state).selectedEncounterHPICategories];
      const index = copy.findIndex(
        (category) => category.hpiCategoryId === action.payload.hpiCategoryId,
      );
      copy[index] = action.payload;
      localHPISelector(state).selectedEncounterHPICategories = copy;
    },
    // OBGYN
    upsertGynDataItem(state, action: PayloadAction<UpsertGYNHistoryItem>) {
      const item = action.payload;
      // if entry isNew, default values that are undefined; otherwise, update to server fails
      if (item.isNew) {
        delete item.isNew;
        if (!isBoolean(item.denies)) {
          item.denies = false;
        }
        if (!isBoolean(item.history)) {
          item.history = false;
        }
        if (!isString(item.symptomNotes)) {
          item.symptomNotes = '';
        }
      }
      gynHistoryAdapter.upsertOne(localOBGYNSelector(state).gynHistory, item);
    },
    addManyGynHistoryItems(state, action: PayloadAction<g.GynHistory[]>) {
      gynHistoryAdapter.addMany(localOBGYNSelector(state).gynHistory, action.payload);
    },
    upsertObDataItem(state, action: PayloadAction<g.ObHistory>) {
      obHistoryAdapter.upsertOne(localOBGYNSelector(state).obHistory, action.payload);
    },
    addManyObHistoryItems(state, action: PayloadAction<g.ObHistory[]>) {
      obHistoryAdapter.addMany(localOBGYNSelector(state).obHistory, action.payload);
    },

    // GENERAL MA WORKFLOW
    setHasLoadedInitialData(state, action: PayloadAction<OrderFlowSteps>) {
      const currentStep = state.orderFlowNavigation.steps.find(
        (step) => action.payload === step.id,
      );
      if (currentStep != null) {
        currentStep.hasLoadedInitialData = true;
      }
    },

    setStepIsDirty(state, action: PayloadAction<{ id: OrderFlowSteps; isDirty: boolean }>) {
      const { steps } = state.orderFlowNavigation;
      const stepIndex = steps.findIndex((s) => s.id === action.payload.id);
      const step = steps[stepIndex];
      if (!step || step.completed) return;
      state.orderFlowNavigation.steps[stepIndex].isDirty = action.payload.isDirty;
    },

    // VITALS PANEL
    addHeartRateVitals(state, action: PayloadAction<EncounterVital[]>) {
      state.orderFlowNavigation.vitals.heartRateVitals = action.payload;
    },
    addBloodPressureVitals(state, action: PayloadAction<EncounterVital[]>) {
      state.orderFlowNavigation.vitals.bloodPressureVitals = action.payload;
    },
    addTempVitals(state, action: PayloadAction<EncounterVital[]>) {
      state.orderFlowNavigation.vitals.tempVitals = action.payload;
    },
    setWeightVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.weightVitals = action.payload;
    },
    setHeightVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.heightVitals = action.payload;
    },
    setWaistCircVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.waistCircVitals = action.payload;
    },
    setBMIVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.bmiVitals = action.payload;
    },
    setRestRateVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.restRateVitals = action.payload;
    },
    setPainScaleVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.painScaleVitals = action.payload;
    },
    setLMPVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.lmpVitals = action.payload;
    },
    setO2SatVitals(state, action: PayloadAction<EncounterVital>) {
      state.orderFlowNavigation.vitals.o2Sat = action.payload;
    },
    resetVitals: (state) => {
      state.orderFlowNavigation.vitals = vitalsInitialState;
    },
    resetLastSavedVitals: (state) => {
      state.orderFlowNavigation.lastSaved.vitals = vitalsInitialState;
    },
    setLastSavedVitals: (state) => {
      state.orderFlowNavigation.lastSaved.vitals = state.orderFlowNavigation.vitals;
    },
  },
});

// function upsertItem({ obHistory, obgyn, update }: any) {
//   const index = obHistory?.findIndex(
//     (x) => x.obSymptomID === update.obSymptomID
//   );

//   if (index !== undefined && index > -1) {
//     obgyn.obHistory[index] = update;
//   } else {
//     obgyn.obHistory.push(update);
//   }
// }

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
