import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface PatientSearchState {
  searchQuery: string;
  selectedIds: Array<{ label: string; value: string }>;
}

const initialState: PatientSearchState = {
  searchQuery: '',
  selectedIds: [],
};

export const patientSearchSlice = createSlice({
  name: 'patientSearch',
  initialState,
  reducers: {
    setPatients(state, action: PayloadAction<PatientSearchState['selectedIds']>) {
      state.selectedIds = action.payload;
    },
    setSearch(state, action: PayloadAction<PatientSearchState['searchQuery']>) {
      state.searchQuery = action.payload;
    },
  },
});

export const patientSearchActions = patientSearchSlice.actions;

export default patientSearchSlice.reducer;
