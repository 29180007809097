import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type EncounterSort, type EncounterTabOption } from '../types';

export interface ProviderWorkflowState {
  selectedTab: EncounterTabOption;
  encounterSort: EncounterSort | null;
}

const initialState: ProviderWorkflowState = {
  selectedTab: 'To Be Reviewed',
  encounterSort: null,
};

export const providerWorkflowSlice = createSlice({
  name: 'providerWorkflow',
  initialState,
  reducers: {
    onTabChange(state, action: PayloadAction<ProviderWorkflowState['selectedTab']>) {
      state.selectedTab = action.payload;
    },
    setSort(state, action: PayloadAction<ProviderWorkflowState['encounterSort']>) {
      state.encounterSort = action.payload;
    },
    toggleSortDirection(state) {
      if (state.encounterSort?.orderDirection) {
        state.encounterSort = {
          ...state.encounterSort,
          orderDirection: state.encounterSort.orderDirection === 'ASC' ? 'DESC' : 'ASC',
        };
      }
    },
  },
});

export const providerWorkflowActions = providerWorkflowSlice.actions;

export default providerWorkflowSlice.reducer;
