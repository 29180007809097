import { useEffect, useMemo } from 'react';
import {
  OrderableItemReviewedStatus,
  useGetProviderEncounterDetailsQuery,
} from '../../../__generated__/graphql';
import { getEncounterFilterState, useAppSelector } from '../../../state-management';
import { type ComboBoxOption } from '../../../types';
import { useToast } from '@chakra-ui/react';

export function useProviderEncounterDetails({ encounterId }: { encounterId?: string | number }) {
  const params = new URLSearchParams(window.location.search);
  const statusParam = params.get('status') as OrderableItemReviewedStatus;
  const orderableReportIds = params.get('orderableReportIds')?.split(',');

  const viableStatus = [
    OrderableItemReviewedStatus.All,
    OrderableItemReviewedStatus.Reviewed,
    OrderableItemReviewedStatus.Delinquent,
    OrderableItemReviewedStatus.Outstanding,
    OrderableItemReviewedStatus.ToBeReviewed,
    OrderableItemReviewedStatus.ToBeReviewedAndReviewed,
  ];

  let status = viableStatus.includes(statusParam) ? statusParam : OrderableItemReviewedStatus.All;

  // swap toBeReviewed for toBeReviewedAndReviewed.
  // we want to show all items with results regardless of reviewed status
  if (status === OrderableItemReviewedStatus.ToBeReviewed) {
    status = OrderableItemReviewedStatus.ToBeReviewedAndReviewed;
  }

  const toast = useToast();

  const { data, loading, refetch, error } = useGetProviderEncounterDetailsQuery({
    skip: !encounterId,
    variables: {
      encounterId: Number(encounterId),
      orderableItemReviewedStatus: status,
    },
  });

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error loading items',
        status: 'error',
        id: 'error-loading-items',
      });
    }
  }, [error]);

  const items = data?.getProviderEncounterDetails?.items || [];

  const filterState = useAppSelector(getEncounterFilterState);

  // this is intentionally deployed
  console.log({ filterState });

  const itemFilters: Array<{
    filterItems?: ComboBoxOption[];
    key: keyof (typeof items)[0];
  }> = [
    {
      filterItems: filterState.assignees,
      key: 'assignedToUserID',
    },
    {
      filterItems: filterState.locations,
      key: 'facilityID',
    },
    {
      filterItems: filterState.procedures,
      key: 'labItemId',
    },
    {
      filterItems: filterState.providers,
      key: 'orderingProviderID',
    },
  ];

  const patientId = data?.getProviderEncounterDetails?.items?.[0]?.sourcePatientId;

  // filter by the filterState and orderableReportIds
  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      if (orderableReportIds?.length) {
        const match = orderableReportIds.some((x) => x === item.orderableReportID);
        if (!match) {
          return false;
        }
      }
      return itemFilters.every(({ filterItems, key }) => {
        if (filterItems?.length) {
          const match = filterItems.some((x) => x.value === item[key]?.toString());
          if (!match) {
            return false;
          }
        }
        return true;
      });
    });
  }, [
    // this can pretty easily cause an infinite rerender
    // watching the lengths to reduce the number of state updates
    items?.length,
    filterState.assignees?.length,
    filterState.locations?.length,
    filterState.procedures?.length,
    filterState.providers?.length,
    orderableReportIds?.length,
  ]);

  return {
    refetch,
    // we want to show all items with results if the user selected 'toBeReviewed'
    items: status === OrderableItemReviewedStatus.ToBeReviewedAndReviewed ? items : filteredItems,
    patientId,
    loading,
    baseEncounter: data?.getProviderEncounterDetails?.baseEncounter,
  };
}
