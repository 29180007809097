export const orderableItemHistory = [
  {
    orderDate: '2015-11-19T05:00:00.000+00:00',
    primaryDiagnosis: 'Post-menopausal',
    orderableItemName: 'DEXA: Dual Energy Xray Absorptiometry Bone Density Study',
  },
  {
    orderDate: '2015-11-30T05:00:00.000+00:00',
    primaryDiagnosis: 'Colon cancer screening',
    orderableItemName: 'Colonoscopy, Screen (use procedure order)',
  },
  {
    orderDate: '2016-07-08T04:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesteremia',
    orderableItemName: 'EKG (Electrocardiogram)',
  },
  {
    orderDate: '2016-07-15T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for colorectal cancer',
    orderableItemName: 'COLONOSCOPY, Screening',
  },
  {
    orderDate: '2017-02-07T05:00:00.000+00:00',
    primaryDiagnosis: null,
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2018-02-07T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2018-02-07T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2018-02-07T05:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2018-02-07T05:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2018-02-07T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for lipid disorders',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for thyroid disorder',
    orderableItemName: 'TSH',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2018-08-10T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2018-04-10T04:00:00.000+00:00',
    primaryDiagnosis: null,
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, (Fingerstick)',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2019-02-12T05:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2019-02-19T05:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for screening mammogram for breast cancer',
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Hypercholesterolemia',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Chronic lymphocytic leukemia of B-cell type not having achieved remission',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Hematuria, unspecified type',
    orderableItemName: 'Cytology, Urine/Misc. Fluid',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Chronic lymphocytic leukemia of B-cell type not having achieved remission',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Chronic lymphocytic leukemia of B-cell type not having achieved remission',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2019-08-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Hematuria, unspecified type',
    orderableItemName: 'Urine Culture, Routine w/ Rflx to Sensitivty',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Screening for lipid disorders',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Screening for thyroid disorder',
    orderableItemName: 'TSH',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2020-02-25T05:00:00.000+00:00',
    primaryDiagnosis: 'Screening for breast cancer',
    orderableItemName: 'Mammogram : Screening Bilateral',
  },
  {
    orderDate: '2020-06-08T04:00:00.000+00:00',
    primaryDiagnosis: 'Breast cancer screening',
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'GERD (gastroesophageal reflux disease)',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'GERD (gastroesophageal reflux disease)',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'GERD (gastroesophageal reflux disease)',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2020-09-03T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2020-09-08T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for colorectal cancer',
    orderableItemName: 'Occult Blood, Fecal, IA (IFOBT, Hemoccult, Fecal Immunochemistry)',
  },
  {
    orderDate: '2020-11-17T05:00:00.000+00:00',
    primaryDiagnosis: null,
    orderableItemName: 'COLONOSCOPY, Screening',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for lipid disorders',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Screening for thyroid disorder',
    orderableItemName: 'TSH',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2021-03-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for general adult medical examination without abnormal findings',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2021-03-24T04:00:00.000+00:00',
    primaryDiagnosis: 'Encounter for screening mammogram for breast cancer',
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Other hyperlipidemia',
    orderableItemName: 'CBC With Differential/Platelet - in office',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Other hyperlipidemia',
    orderableItemName: 'Lipid Panel with LDL/HDL Ratio',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Other hyperlipidemia',
    orderableItemName: 'Comp. Metabolic Panel (CMP) (14)',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Glucose, whole blood (venous draw)',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Hemoglobin A1c',
  },
  {
    orderDate: '2021-09-17T04:00:00.000+00:00',
    primaryDiagnosis: 'Pre-diabetes',
    orderableItemName: 'Urinalysis, Routine- In office',
  },
  {
    orderDate: '2021-07-01T04:00:00.000+00:00',
    primaryDiagnosis: null,
    orderableItemName: 'Mammogram : Screening Bilateral w/ 3D Tomo',
  },
  {
    orderDate: '2021-12-16T05:00:00.000+00:00',
    primaryDiagnosis: 'Screening for colorectal cancer',
    orderableItemName: 'Occult Blood, Fecal, IA (IFOBT, Hemoccult, Fecal Immunochemistry)',
  },
  {
    orderDate: '2021-09-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Elevated LFTs',
    orderableItemName: 'GGT (Gamma Glutamyl Transpeptidase)',
  },
  {
    orderDate: '2021-09-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Elevated LFTs',
    orderableItemName: 'Ferritin, Serum',
  },
  {
    orderDate: '2021-09-20T04:00:00.000+00:00',
    primaryDiagnosis: 'Elevated LFTs',
    orderableItemName: 'Hepatitis Panel (4)',
  },
];
