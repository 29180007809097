type LabItem = {
  itemId: number;
  itemName: string;
  itemType: string;
  parentLabId: number;
  displayOrder: number;
};

const NON_CONSTELLATION_LAB_FIELDS: Record<string, LabItem[]> = {
  '285266': [
    {
      itemId: 551897,
      itemName: 'Repeat Glucose',
      itemType: 'I',
      parentLabId: 285266,
      displayOrder: 3,
    },
    {
      itemId: 285296,
      itemName: 'Glucose using Reagent Strip',
      itemType: 'I',
      parentLabId: 285266,
      displayOrder: 2,
    },
  ],
  '285267': [
    {
      itemId: 285295,
      itemName: 'Glucose using Reagent Strip',
      itemType: 'I',
      parentLabId: 285267,
      displayOrder: 2,
    },
    {
      itemId: 551896,
      itemName: 'Repeat Glucose',
      itemType: 'I',
      parentLabId: 285267,
      displayOrder: 3,
    },
  ],
  '224118': [
    {
      itemId: 541818,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 224118,
      displayOrder: 3,
    },
    {
      itemId: 224119,
      itemName: 'Pregnancy Test, Urine',
      itemType: 'I',
      parentLabId: 224118,
      displayOrder: 2,
    },
  ],
  '229159': [
    {
      itemId: 541817,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 229159,
      displayOrder: 5,
    },
    {
      itemId: 308098,
      itemName: 'Occult Blood 2',
      itemType: 'I',
      parentLabId: 229159,
      displayOrder: 3,
    },
    {
      itemId: 308099,
      itemName: 'Occult Blood 3',
      itemType: 'I',
      parentLabId: 229159,
      displayOrder: 4,
    },
    {
      itemId: 229160,
      itemName: 'Occult Blood, Fecal, IA',
      itemType: 'I',
      parentLabId: 229159,
      displayOrder: 2,
    },
  ],
  '285289': [
    {
      itemId: 378792,
      itemName: 'AMP',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 3,
    },
    {
      itemId: 378793,
      itemName: 'COC',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 4,
    },
    {
      itemId: 378790,
      itemName: 'Control Line 1',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 2,
    },
    {
      itemId: 378795,
      itemName: 'Control Line 2',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 6,
    },
    {
      itemId: 378796,
      itemName: 'OPI',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 7,
    },
    {
      itemId: 378797,
      itemName: 'PCP',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 8,
    },
    {
      itemId: 378794,
      itemName: 'THC',
      itemType: 'I',
      parentLabId: 285289,
      displayOrder: 5,
    },
  ],
};
/**
 * These fields come from /api/v1/Lookup/LabAttributeList
 *
 * For now, we are manually using some of these fields, which pertain to
 * OrderableItem.labItemId for the constellation of symptoms orders.
 */
export const LAB_FORM_FIELDS: Record<string, LabItem[]> = {
  ...NON_CONSTELLATION_LAB_FIELDS,
  '223955': [
    {
      itemId: 223958,
      itemName: 'Appearance',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 13,
    },
    {
      itemId: 223964,
      itemName: 'Bilirubin',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 3,
    },
    {
      itemId: 223961,
      itemName: 'Glucose',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 2,
    },
    {
      itemId: 223967,
      itemName: 'Ketones',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 4,
    },
    {
      itemId: 223956,
      itemName: 'Microscopic Examination',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 15,
    },
    {
      itemId: 223966,
      itemName: 'Nitrite, Urine',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 10,
    },
    {
      itemId: 223963,
      itemName: 'Occult Blood',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 6,
    },
    {
      itemId: 223960,
      itemName: 'pH',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 7,
    },
    {
      itemId: 223962,
      itemName: 'Protein',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 8,
    },
    {
      itemId: 223959,
      itemName: 'Specific Gravity',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 5,
    },
    {
      itemId: 223969,
      itemName: 'Urinalysis Gross Exam',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 14,
    },
    {
      itemId: 223957,
      itemName: 'Urine-Color',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 12,
    },
    {
      itemId: 223965,
      itemName: 'Urobilinogen,Semi-Qn',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 9,
    },
    {
      itemId: 223968,
      itemName: 'WBC Esterase',
      itemType: 'I',
      parentLabId: 223955,
      displayOrder: 11,
    },
  ],
  '224354': [
    {
      itemId: 541816,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 224354,
      displayOrder: 3,
    },
    {
      itemId: 224355,
      itemName: 'Mononucleosis Test, Qual',
      itemType: 'I',
      parentLabId: 224354,
      displayOrder: 2,
    },
  ],
  '237287': [
    {
      itemId: 285351,
      itemName: 'Flu  A',
      itemType: 'I',
      parentLabId: 237287,
      displayOrder: 2,
    },
    {
      itemId: 285352,
      itemName: 'Flu  B',
      itemType: 'I',
      parentLabId: 237287,
      displayOrder: 3,
    },
    {
      itemId: 541812,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 237287,
      displayOrder: 4,
    },
  ],
  '245214': [
    {
      itemId: 541819,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 245214,
      displayOrder: 3,
    },
    {
      itemId: 285336,
      itemName: 'Strep A',
      itemType: 'I',
      parentLabId: 245214,
      displayOrder: 2,
    },
  ],
  '582403': [
    {
      itemId: 582674,
      itemName: 'COVID19 Ag',
      itemType: 'I',
      parentLabId: 582403,
      displayOrder: 1,
    },
    {
      itemId: 582675,
      itemName: 'Internal Control',
      itemType: 'I',
      parentLabId: 582403,
      displayOrder: 2,
    },
  ],
};

export const RESULT_OPTIONS = [
  '',
  'Abnormal',
  'Critical high',
  'Critical low',
  'High',
  'Low',
  'Negative',
  'Normal',
  'Osteoarthritis',
  'Positive',
  'Rheumatoid Arthritis',
  'Stable',
];
