import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'brand',
  },
  // baseStyle: {
  //   minHeight: "55px", // Figma's button height is 62px but button looks too big relative to fontsize: change fontsize?
  // },
};
