import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  List,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useAppDispatch } from '../../../state-management';
import { usePendingVaccines, useModal } from '../../../hooks';
import { BiChevronRight } from 'react-icons/bi';
import { worklistTodoActions } from '../../../state-management/worklistTodoSlice';
import { PastVaccines } from './PastVaccines';

interface Props {
  type: 'Vaccination' | 'Therapeutic Injection';
}

function PendingVaccines({ type }: Props) {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const { pendingVaccines, pendingTherapeuticInjections } = usePendingVaccines();

  const items = type === 'Vaccination' ? pendingVaccines : pendingTherapeuticInjections;

  return (
    <VStack alignItems={'stretch'} spacing='lg'>
      <VStack alignItems={'stretch'}>
        <Box mb='sm'>
          <Text variant='h6-b'>
            Pending {type === 'Vaccination' ? 'Vaccines' : 'Therapeutic Injections'}
          </Text>
        </Box>
        <List>
          {items?.map((todo, i) => (
            <ListItem
              key={`vaccination-item-${i}`}
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(worklistTodoActions.setSelectedPendingVaccine(todo));
                showModal({
                  modalType: 'AdministerVaccineFormModal',
                  chakraModalProps: { size: '6xl' },
                  modalProps: {
                    pastVaccineDetail: todo.immunization,
                    isDisabled: false,
                    type,
                  },
                });
              }}>
              <Flex
                alignItems='center'
                borderBottom={'1px solid'}
                borderColor={'blue.200'}
                py='xs'
                justifyContent={'space-between'}>
                {/* <Text>{todo.text}</Text> */}
                <Checkbox isChecked={todo.completed}>{todo.text}</Checkbox>
                <IconButton
                  aria-label='show LocationSelectorModal modal'
                  size='sm'
                  variant='ghost'
                  icon={<Icon boxSize={5} as={BiChevronRight} />}
                />
              </Flex>
            </ListItem>
          ))}
        </List>
      </VStack>

      <VStack alignItems={'stretch'}>
        <Box mb='sm'>
          <Text variant='h6-b'>
            Past {type === 'Vaccination' ? 'Vaccines' : 'Therapeutic Injections'}
          </Text>
        </Box>
        <PastVaccines type={type} />
      </VStack>
    </VStack>
  );
}

export function PendingVaccinesModal({ type }: Props) {
  const { showModal } = useModal();

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box h='70vh'>
          <PendingVaccines type={type} />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          variant='ghost'
          onClick={() => {
            showModal({
              modalType: 'WorklistModal',
              chakraModalProps: { size: '6xl' },
            });
          }}>
          Back
        </Button>
      </ModalFooter>
    </>
  );
}
