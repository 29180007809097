import React, { useState } from 'react';
import { Box, FlatList, Flex, Text } from '../../../../components';
import { usePatient } from '../../../../hooks';
import { type Allergy, type Problem } from '../../../../__generated__/graphql';

interface TabProps {
  active?: boolean;
  text: string;
  onClick: () => void;
}

function Tab({ active, onClick, text }: TabProps) {
  const activeContainerStyles = active
    ? {
        borderRightWidth: '2.4px', // TODO: Used 2.4px to match Tabs. Change all to 4px.
        borderColor: 'primary',
        color: 'primary',
      }
    : {};
  const containerStyles = {
    ...activeContainerStyles,
    py: 'sm',
  };

  return (
    <Box {...containerStyles} onClick={onClick}>
      <Text color={active ? 'primary' : ''} variant='body2-b'>
        {text}
      </Text>
    </Box>
  );
}

function ProblemsTabPanel() {
  const { patient } = usePatient();

  function renderItem({ item }: { item: Problem }) {
    return (
      <Box mb='sm' key={item.diagnosisCode}>
        <Text>{item.problemName}</Text>
      </Box>
    );
  }

  if (!patient?.problems?.length) {
    return <Text>None</Text>;
  }

  return (
    <FlatList
      data={patient?.problems}
      renderItem={renderItem}
      keyExtractor={(item: Problem) => item.itemID}
    />
  );
}

function AllergiesTabPanel() {
  const { patient } = usePatient();
  const allergies = patient?.allergies;

  // TODO: Remove unused selectors!
  // const allergies = useAppSelector(selectors.getPatientAllergies);

  function renderItem({ item }: { item: Allergy }) {
    return (
      <Box mb='sm' key={item.agentSubstance}>
        <Text>{item.agentSubstance}</Text>
      </Box>
    );
  }

  if (!allergies?.length) {
    return <Text>None</Text>;
  }

  return (
    <FlatList
      data={allergies}
      renderItem={renderItem}
      keyExtractor={(item: Allergy) => item.agentSubstance}
    />
  );
}

export function VerticalTabs() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabTitles = ['Problems', 'Allergies'];
  const tabPanels = [
    <ProblemsTabPanel key='vertical_tabs_panel_1' />,
    <AllergiesTabPanel key='vertical_tabs_panel_2' />,
  ];

  return (
    <Flex mt='lg' justifyContent='space-between'>
      {/* Tablist */}
      <Box flex='1'>
        {tabTitles.map((title, i) => {
          return (
            <Tab
              key={title}
              active={activeIndex === i}
              text={title}
              onClick={() => {
                setActiveIndex(i);
              }}
            />
          );
        })}
      </Box>

      {/* Tab panels */}
      <Box flex='5' p='sm' pl='lg'>
        <Box>{tabPanels[activeIndex]}</Box>
      </Box>
    </Flex>
  );
}
