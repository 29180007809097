export const getEncounterOrders = {
  encounterOrders: [
    {
      alreadyOrderedLocation: null,
      alreadyOrderedNotes: null,
      alreadyOrderedResult: null,
      assessmentItemId: 475499,
      assignedToUserEmail: 'jbaker@elfp.com',
      assignedToUserID: null,
      declineNotes: null,
      declineReasonCode: null,
      declineReason: null,
      encounterID: 6296059,
      documentingFacilityID: 18,
      futureOrder: false,
      immunizationItemID: null,
      notes: '',
      orderableItemId: 195037,
      orderableReportID: 10766762,
      orderDate: '2022-10-22T01:19:08.334Z',
      orderingProviderID: 428727,
      orderType: 'ACCEPTED',
      patientID: 497793,
      patientImmunizationInjectionType: null,
    },
    // {
    //   alreadyOrderedLocation: "Clinic Overthere",
    //   alreadyOrderedNotes: "These are some additional notes",
    //   alreadyOrderedResult: "123abc",
    //   assessmentItemId: null,
    //   assignedToUserEmail: "jbaker@elfp.com",
    //   assignedToUserID: null,
    //   declineNotes: null,
    //   declineReasonCode: "ALREADY_ORDERED",
    //   declineReason: null,
    //   encounterID: 6296059,
    //   documentingFacilityID: 18,
    //   futureOrder: false,
    //   immunizationItemID: null,
    //   notes:
    //     "Performed on unknown date at Clinic Overthere. Result: 123abc. Notes: These are some additional notes",
    //   orderableItemId: 203493,
    //   orderableReportID: 10766761,
    //   orderDate: "2022-10-22T01:19:08.337Z",
    //   orderingProviderID: null,
    //   orderType: "DECLINED",
    //   patientID: 497793,
    //   patientImmunizationInjectionType: null,
    // },
    // {
    //   alreadyOrderedLocation: "unknown location",
    //   alreadyOrderedNotes: "I told them that doesn't make sense",
    //   alreadyOrderedResult: "Patient has phobia",
    //   assessmentItemId: null,
    //   assignedToUserEmail: "jbaker@elfp.com",
    //   assignedToUserID: null,
    //   declineNotes: null,
    //   declineReasonCode: "PARENTAL_DECISION",
    //   declineReason: null,
    //   encounterID: 6296059,
    //   documentingFacilityID: 18,
    //   futureOrder: false,
    //   immunizationItemID: null,
    //   notes: "",
    //   orderableItemId: 237591,
    //   orderableReportID: 0,
    //   orderDate: "2022-10-22T01:19:08.338Z",
    //   orderingProviderID: null,
    //   orderType: "DECLINED",
    //   patientID: 497793,
    //   patientImmunizationInjectionType: null,
    // },
    {
      alreadyOrderedLocation: null,
      alreadyOrderedNotes: null,
      alreadyOrderedResult: null,
      assessmentItemId: null,
      assignedToUserEmail: 'jbaker@elfp.com',
      assignedToUserID: null,
      declineNotes: null,
      declineReasonCode: 'PATIENT_DECISION',
      declineReason: null,
      encounterID: 6296059,
      documentingFacilityID: 18,
      futureOrder: false,
      immunizationItemID: 522450,
      notes: '',
      orderableItemId: null,
      orderableReportID: 608872,
      orderDate: '2022-10-22T01:19:11.153Z',
      orderingProviderID: null,
      orderType: 'DECLINED',
      patientID: 497793,
      patientImmunizationInjectionType: 'IMMUNIZATION',
    },
    {
      alreadyOrderedLocation: null,
      alreadyOrderedNotes: null,
      alreadyOrderedResult: null,
      assessmentItemId: null,
      assignedToUserEmail: 'jbaker@elfp.com',
      assignedToUserID: null,
      declineNotes: null,
      declineReasonCode: null,
      declineReason: null,
      encounterID: 6296059,
      documentingFacilityID: 18,
      futureOrder: false,
      immunizationItemID: 285235,
      notes: '',
      orderableItemId: null,
      orderableReportID: 608871,
      orderDate: '2022-10-22T01:19:11.183Z',
      orderingProviderID: null,
      orderType: 'ACCEPTED',
      patientID: 497793,
      patientImmunizationInjectionType: 'IMMUNIZATION',
    },
  ],
  nextToken: null,
};
