export const getMedicationList = [
  {
    rxid: 268225,
    name: 'BD Q-Style Access Device Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '8290-385100',
    drugNameID: 56538,
  },
  {
    rxid: 268226,
    name: 'BD Q-Style Extension Set Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '8290-385102',
    drugNameID: 56537,
  },
  {
    rxid: 268227,
    name: 'BD Q-Style Vial Adapter Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '8290-385108',
    drugNameID: 56536,
  },
  {
    rxid: 239316,
    name: 'Compat 10FR N/G WTD Tip/Stylet Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '43900-83106',
    drugNameID: 19687,
  },
  {
    rxid: 239317,
    name: 'Compat 12FR N/G WTD Tip/Stylet Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '43900-83105',
    drugNameID: 19675,
  },
  {
    rxid: 239319,
    name: 'Compat 8FR N/G WTD Tip/Stylet Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '43900-83104',
    drugNameID: 19686,
  },
  {
    rxid: 284919,
    name: 'Face Mask Earloop-Style Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '11917-11434',
    drugNameID: 80780,
  },
  {
    rxid: 277290,
    name: 'Fast Freeze Pro Style Therapy External Gel 3.5 %',
    strength: '3.5 %',
    formulation: 'Gel',
    take: 'Apply 1 application as needed',
    route: 'External',
    frequency: '3',
    duration: 0,
    dispense: 0,
    controlLevel: '0',
    ndcCode: '38481-00961',
    drugNameID: 65129,
  },
  {
    rxid: 277290,
    name: 'Fast Freeze Pro Style Therapy External Liquid 0.2-3.5 %',
    strength: '0.2-3.5 %',
    formulation: 'Liquid',
    take: '   ',
    route: 'External',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '38481-00960',
    drugNameID: 65129,
  },
  {
    rxid: 277290,
    name: 'Fast Freeze Pro Style Therapy External Liquid 3.5 %',
    strength: '3.5 %',
    formulation: 'Liquid',
    take: '   ',
    route: 'External',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '38481-00963',
    drugNameID: 65129,
  },
  {
    rxid: 240507,
    name: 'FreeStyle Control Solution In Vitro Liquid',
    strength: '- ',
    formulation: 'Liquid',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-14002',
    drugNameID: 21571,
  },
  {
    rxid: 398183,
    name: 'Freestyle Double Breastpump Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '20451-67060',
    drugNameID: 88801,
  },
  {
    rxid: 240508,
    name: 'FreeStyle Flash System Kit',
    strength: '- ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-17001',
    drugNameID: 28772,
  },
  {
    rxid: 240509,
    name: 'FreeStyle Freedom Kit',
    strength: '- ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70847',
    drugNameID: 49375,
  },
  {
    rxid: 240510,
    name: 'FreeStyle Freedom Lite Kit w/Device',
    strength: 'w/Device ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70914',
    drugNameID: 54092,
  },
  {
    rxid: 282991,
    name: 'FreeStyle InsuLinx System Kit w/Device',
    strength: 'w/Device ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71143',
    drugNameID: 78459,
  },
  {
    rxid: 282992,
    name: 'FreeStyle InsuLinx Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71227',
    drugNameID: 79138,
  },
  {
    rxid: 284135,
    name: 'FreeStyle InsuLinx Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71227',
    drugNameID: 79138,
  },
  {
    rxid: 240511,
    name: 'FreeStyle Lancets Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-13001',
    drugNameID: 21570,
  },
  {
    rxid: 537512,
    name: 'FreeStyle Libre 14 Day Reader Device',
    strength: '- ',
    formulation: 'Device',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-0002-00',
    drugNameID: 97652,
  },
  {
    rxid: 537513,
    name: 'FreeStyle Libre 14 Day Sensor Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-0001-01',
    drugNameID: 97651,
  },
  {
    rxid: 528607,
    name: 'FreeStyle Libre Reader Device',
    strength: '- ',
    formulation: 'Device',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-0000-21',
    drugNameID: 95947,
  },
  {
    rxid: 528608,
    name: 'FreeStyle Libre Sensor System Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-0000-19',
    drugNameID: 95853,
  },
  {
    rxid: 212797,
    name: 'FreeStyle Lite Device',
    strength: '- ',
    formulation: 'Device',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70804',
    drugNameID: 51225,
  },
  {
    rxid: 240512,
    name: 'FreeStyle Lite Device',
    strength: '- ',
    formulation: 'Device',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70804',
    drugNameID: 51225,
  },
  {
    rxid: 386212,
    name: 'FreeStyle Lite Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70819',
    drugNameID: 57505,
  },
  {
    rxid: 277327,
    name: 'FreeStyle Lite Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70819',
    drugNameID: 57505,
  },
  {
    rxid: 272288,
    name: 'FreeStyle Lite Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-70819',
    drugNameID: 57505,
  },
  {
    rxid: 278319,
    name: 'FreeStyle Precision Ins Syr Miscellaneous 30G X 5/16" 0.5 ML',
    strength: '30G X 5/16" 0.5 ML',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71098',
    drugNameID: 63365,
  },
  {
    rxid: 278319,
    name: 'FreeStyle Precision Ins Syr Miscellaneous 30G X 5/16" 1 ML',
    strength: '30G X 5/16" 1 ML',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71097',
    drugNameID: 63365,
  },
  {
    rxid: 278319,
    name: 'FreeStyle Precision Ins Syr Miscellaneous 31G X 5/16" 0.5 ML',
    strength: '31G X 5/16" 0.5 ML',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71099',
    drugNameID: 63365,
  },
  {
    rxid: 278319,
    name: 'FreeStyle Precision Ins Syr Miscellaneous 31G X 5/16" 1 ML',
    strength: '31G X 5/16" 1 ML',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-71100',
    drugNameID: 63365,
  },
  {
    rxid: 497752,
    name: 'FreeStyle Precision Neo System Kit w/Device',
    strength: 'w/Device ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-5175-01',
    drugNameID: 91494,
  },
  {
    rxid: 497109,
    name: 'FreeStyle Precision Neo Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-1577-01',
    drugNameID: 91493,
  },
  {
    rxid: 240513,
    name: 'FreeStyle Sidekick II Kit',
    strength: '- ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-12051',
    drugNameID: 26479,
  },
  {
    rxid: 240514,
    name: 'FreeStyle System Kit',
    strength: '- ',
    formulation: 'Kit',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-11001',
    drugNameID: 21568,
  },
  {
    rxid: 277328,
    name: 'FreeStyle Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-12050',
    drugNameID: 21569,
  },
  {
    rxid: 212798,
    name: 'FreeStyle Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-12050',
    drugNameID: 21569,
  },
  {
    rxid: 284136,
    name: 'FreeStyle Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-12050',
    drugNameID: 21569,
  },
  {
    rxid: 268420,
    name: 'FreeStyle Test In Vitro Strip',
    strength: '- ',
    formulation: 'Strip',
    take: '   ',
    route: 'In Vitro',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '99073-12050',
    drugNameID: 21569,
  },
  {
    rxid: 240515,
    name: 'FreeStyle Unistick II Lancets Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '57599-8746-05',
    drugNameID: 51244,
  },
  {
    rxid: 240844,
    name: 'Home Style Bed Rails Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '23601-58700',
    drugNameID: 6450,
  },
  {
    rxid: 385695,
    name: 'Kangaroo Ped NG Stylet 20" Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '8080-700702',
    drugNameID: 82797,
  },
  {
    rxid: 385696,
    name: 'Kangaroo Ped NG Stylet 36" Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '8080-700744',
    drugNameID: 82796,
  },
  {
    rxid: 544251,
    name: 'LifestyleComfort Vaporizer Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '50632-00739',
    drugNameID: 99518,
  },
  {
    rxid: 241407,
    name: 'LifeStyles Assorted Colors Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-02803',
    drugNameID: 19701,
  },
  {
    rxid: 241408,
    name: 'LifeStyles Extra Strength Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-02103',
    drugNameID: 13247,
  },
  {
    rxid: 241409,
    name: 'LifeStyles Form Fitting Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01903',
    drugNameID: 13268,
  },
  {
    rxid: 241410,
    name: 'LifeStyles Lubricated Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01503',
    drugNameID: 19702,
  },
  {
    rxid: 241411,
    name: 'LifeStyles Ribbed Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-04146',
    drugNameID: 53336,
  },
  {
    rxid: 393458,
    name: 'LifeStyles Skyn Original Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-27312',
    drugNameID: 87357,
  },
  {
    rxid: 241412,
    name: 'LifeStyles Spermicidal Lube Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01803',
    drugNameID: 19698,
  },
  {
    rxid: 241413,
    name: 'LifeStyles Studded Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-02403',
    drugNameID: 19700,
  },
  {
    rxid: 241414,
    name: 'LifeStyles Ultra Sensitive Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01703',
    drugNameID: 19699,
  },
  {
    rxid: 241415,
    name: 'LifeStyles Vibra-Ribbed Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01403',
    drugNameID: 19697,
  },
  {
    rxid: 241416,
    name: 'LifeStyles Xtra Pleasure Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '70907-01303',
    drugNameID: 19696,
  },
  {
    rxid: 537608,
    name: 'Medela Pump In Style Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '20451-35077',
    drugNameID: 97657,
  },
  {
    rxid: 215818,
    name: 'Metabo-Style Oral Tablet',
    strength: '- ',
    formulation: 'Tablet',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '54629-0361-20',
    drugNameID: 23181,
  },
  {
    rxid: 385853,
    name: 'Pump in Style Advanced Miscellaneous',
    strength: '- ',
    formulation: 'Miscellaneous',
    take: '   ',
    route: '',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '20451-57062',
    drugNameID: 83625,
  },
  {
    rxid: 497575,
    name: 'Tylenol 8 Hour Arthritis Pain Oral Tablet Extended Release 650 MG',
    strength: '650 MG',
    formulation: 'Tablet Extended Release',
    take: 'Take 2 tablets as needed',
    route: 'Oral',
    frequency: '3',
    duration: 0,
    dispense: 180,
    controlLevel: '0',
    ndcCode: '00045-0838-21',
    drugNameID: 92039,
  },
  {
    rxid: 222472,
    name: 'Tylenol 8 Hour Oral Tablet Extended Release 650 MG',
    strength: '650 MG',
    formulation: 'Tablet Extended Release',
    take: 'Take 2 tablets as needed',
    route: 'Oral',
    frequency: '3',
    duration: 0,
    dispense: 180,
    controlLevel: '0',
    ndcCode: '00045-0297-21',
    drugNameID: 27962,
  },
  {
    rxid: 537394,
    name: 'Tylenol Childrens Chewables Oral Tablet Chewable 160 MG',
    strength: '160 MG',
    formulation: 'Tablet Chewable',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0518-24',
    drugNameID: 96986,
  },
  {
    rxid: 528515,
    name: 'Tylenol Childrens Cld+Cgh Oral Suspension 5-160-1 MG/5ML',
    strength: '5-160-1 MG/5ML',
    formulation: 'Suspension',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0161-04',
    drugNameID: 95688,
  },
  {
    rxid: 222479,
    name: 'Tylenol Childrens Cold/Cough Oral Suspension 160-5 MG/5ML',
    strength: '160-5 MG/5ML',
    formulation: 'Suspension',
    take: 'Take 10 ml every 4 hours as needed',
    route: 'Oral',
    frequency: '5',
    duration: 0,
    dispense: 1500,
    controlLevel: '0',
    ndcCode: '00045-0169-04',
    drugNameID: 4265,
  },
  {
    rxid: 556336,
    name: 'Tylenol Childrens Cold/Flu Oral Suspension 2.5-1-5-160 MG/5ML',
    strength: '2.5-1-5-160 MG/5ML',
    formulation: 'Suspension',
    take: 'Take 10 ml every 4 hours as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 1200,
    controlLevel: '0',
    ndcCode: '00045-0386-05',
    drugNameID: 101775,
  },
  {
    rxid: 222478,
    name: 'Tylenol Childrens Oral Suspension 160 MG/5ML',
    strength: '160 MG/5ML',
    formulation: 'Suspension',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0123-04',
    drugNameID: 4264,
  },
  {
    rxid: 222478,
    name: 'Tylenol Childrens Oral Therapy Pack 160 & 160 MG &MG/5ML',
    strength: '160 & 160 MG &MG/5ML',
    formulation: 'Therapy Pack',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '50580-0460-01',
    drugNameID: 4264,
  },
  {
    rxid: 551686,
    name: 'Tylenol Childrens Pain + Fever Oral Packet 160 MG',
    strength: '160 MG',
    formulation: 'Packet',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0416-18',
    drugNameID: 100264,
  },
  {
    rxid: 222484,
    name: 'Tylenol Childrens Plus MS Cold Oral Suspension 2.5-1-5-160 MG/5ML',
    strength: '2.5-1-5-160 MG/5ML',
    formulation: 'Suspension',
    take: 'Take 10 ml every 4 hours as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 1200,
    controlLevel: '0',
    ndcCode: '00045-0391-05',
    drugNameID: 49380,
  },
  {
    rxid: 551687,
    name: 'Tylenol Cold/Flu/Cough Night Oral Liquid 5-6.25-10-325 MG/15ML',
    strength: '5-6.25-10-325 MG/15ML',
    formulation: 'Liquid',
    take: 'Take 30 ml as needed',
    route: 'Oral',
    frequency: '6',
    duration: 0,
    dispense: 5400,
    controlLevel: '0',
    ndcCode: '00045-0562-08',
    drugNameID: 100439,
  },
  {
    rxid: 548077,
    name: 'Tylenol Cold & Flu Day/Night Oral Liquid Therapy Pack',
    strength: '- ',
    formulation: 'Liquid Therapy Pack',
    take: 'Take 30 ml ',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 3600,
    controlLevel: '0',
    ndcCode: '00045-0305-15',
    drugNameID: 99856,
  },
  {
    rxid: 281368,
    name: 'Tylenol Cold/Flu Severe Oral Tablet 5-10-200-325 MG',
    strength: '5-10-200-325 MG',
    formulation: 'Tablet',
    take: 'Take 2 tablets may be given every 4 hours as needed',
    route: 'Oral',
    frequency: '5',
    duration: 0,
    dispense: 300,
    controlLevel: '0',
    ndcCode: '00045-0270-26',
    drugNameID: 30374,
  },
  {
    rxid: 281368,
    name: 'Tylenol Cold/Flu Severe Oral Tablet Therapy Pack',
    strength: '- ',
    formulation: 'Tablet Therapy Pack',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0550-24',
    drugNameID: 30374,
  },
  {
    rxid: 528516,
    name: 'Tylenol Cold & Head Oral Tablet 5-325-200 MG',
    strength: '5-325-200 MG',
    formulation: 'Tablet',
    take: 'Take 2 tablets may be given every 4 hours as needed',
    route: 'Oral',
    frequency: '5',
    duration: 0,
    dispense: 300,
    controlLevel: '0',
    ndcCode: '00045-0261-25',
    drugNameID: 95284,
  },
  {
    rxid: 510182,
    name: 'Tylenol Cold Max Oral Liquid 10-5-325 MG/15ML',
    strength: '10-5-325 MG/15ML',
    formulation: 'Liquid',
    take: 'Take 15 ml every 4 hours as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 1800,
    controlLevel: '0',
    ndcCode: '00045-0257-08',
    drugNameID: 92445,
  },
  {
    rxid: 510182,
    name: 'Tylenol Cold Max Oral Tablet 10-5-325 MG',
    strength: '10-5-325 MG',
    formulation: 'Tablet',
    take: 'Take 2 tablets as needed',
    route: 'Oral',
    frequency: '6',
    duration: 0,
    dispense: 360,
    controlLevel: '0',
    ndcCode: '00045-0271-25',
    drugNameID: 92445,
  },
  {
    rxid: 222490,
    name: 'Tylenol Cold Multi-Symptom Day Oral Liquid 5-10-200-325 MG/15ML',
    strength: '5-10-200-325 MG/15ML',
    formulation: 'Liquid',
    take: 'Take 30 ml as needed',
    route: 'Oral',
    frequency: '6',
    duration: 0,
    dispense: 5400,
    controlLevel: '0',
    ndcCode: '00045-0521-08',
    drugNameID: 49149,
  },
  {
    rxid: 222499,
    name: 'Tylenol Extra Strength Oral Tablet 500 MG',
    strength: '500 MG',
    formulation: 'Tablet',
    take: 'Take 1 tablet as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 120,
    controlLevel: '0',
    ndcCode: '00045-0444-06',
    drugNameID: 4266,
  },
  {
    rxid: 222502,
    name: 'Tylenol Infants Oral Suspension 160 MG/5ML',
    strength: '160 MG/5ML',
    formulation: 'Suspension',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0186-60',
    drugNameID: 30399,
  },
  {
    rxid: 497576,
    name: 'Tylenol Infants Pain+Fever Oral Suspension 160 MG/5ML',
    strength: '160 MG/5ML',
    formulation: 'Suspension',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0122-30',
    drugNameID: 91460,
  },
  {
    rxid: 222471,
    name: 'Tylenol Oral Capsule 325 MG',
    strength: '325 MG',
    formulation: 'Capsule',
    take: 'Take 1 capsule as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 120,
    controlLevel: '0',
    ndcCode: '00045-0487-20',
    drugNameID: 8291,
  },
  {
    rxid: 222471,
    name: 'Tylenol Oral Tablet 325 MG',
    strength: '325 MG',
    formulation: 'Tablet',
    take: 'Take 1 tablet as needed',
    route: 'Oral',
    frequency: '6',
    duration: 0,
    dispense: 180,
    controlLevel: '0',
    ndcCode: '00045-0496-60',
    drugNameID: 8291,
  },
  {
    rxid: 222507,
    name: 'Tylenol PM Extra Strength Oral Liquid 1000-50 MG/30ML',
    strength: '1000-50 MG/30ML',
    formulation: 'Liquid',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '00045-0421-08',
    drugNameID: 7653,
  },
  {
    rxid: 222507,
    name: 'Tylenol PM Extra Strength Oral Tablet 500-25 MG',
    strength: '500-25 MG',
    formulation: 'Tablet',
    take: 'Take 1 tablet at bedtime as needed',
    route: 'Oral',
    frequency: '1',
    duration: 30,
    dispense: 30,
    controlLevel: '0',
    ndcCode: '00045-0482-11',
    drugNameID: 7653,
  },
  {
    rxid: 537395,
    name: 'Tylenol Sinus+Headache Oral Tablet 5-325 MG',
    strength: '5-325 MG',
    formulation: 'Tablet',
    take: 'Take 2 tablets as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 240,
    controlLevel: '0',
    ndcCode: '00045-0275-25',
    drugNameID: 96407,
  },
  {
    rxid: 510183,
    name: 'Tylenol Sinus Severe Oral Tablet 5-325-200 MG',
    strength: '5-325-200 MG',
    formulation: 'Tablet',
    take: 'Take 2 tablets may be given every 4 hours as needed',
    route: 'Oral',
    frequency: '5',
    duration: 0,
    dispense: 300,
    controlLevel: '0',
    ndcCode: '00045-0262-25',
    drugNameID: 92418,
  },
  {
    rxid: 278045,
    name: 'Tylenol Warming Cough/Congest Oral Liquid 5-10-200-325 MG/15ML',
    strength: '5-10-200-325 MG/15ML',
    formulation: 'Liquid',
    take: 'Take 30 ml as needed',
    route: 'Oral',
    frequency: '6',
    duration: 0,
    dispense: 5400,
    controlLevel: '0',
    ndcCode: '00045-0525-08',
    drugNameID: 62826,
  },
  {
    rxid: 222516,
    name: 'Tylenol with Codeine #3 Oral Tablet 300-30 MG',
    strength: '300-30 MG',
    formulation: 'Tablet',
    take: 'Take 1 tablet as needed',
    route: 'Oral',
    frequency: '4',
    duration: 0,
    dispense: 0,
    controlLevel: '3',
    ndcCode: '50090-0006-02',
    drugNameID: 4270,
  },
  {
    rxid: 281369,
    name: 'Tyler Eskimo-3 Oral Capsule 500 MG',
    strength: '500 MG',
    formulation: 'Capsule',
    take: 'Take 1 capsule ',
    route: 'Oral',
    frequency: '2',
    duration: 30,
    dispense: 60,
    controlLevel: '0',
    ndcCode: '71791-00025',
    drugNameID: 78226,
  },
  {
    rxid: 281369,
    name: 'Tyler Eskimo-3 Oral Liquid',
    strength: '- ',
    formulation: 'Liquid',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00026',
    drugNameID: 78226,
  },
  {
    rxid: 281370,
    name: 'Tyler Indolplex Oral Capsule',
    strength: '- ',
    formulation: 'Capsule',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00047',
    drugNameID: 78232,
  },
  {
    rxid: 281371,
    name: 'Tyler Lipoic Acid Oral Capsule 100 MG',
    strength: '100 MG',
    formulation: 'Capsule',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00153',
    drugNameID: 78221,
  },
  {
    rxid: 281372,
    name: 'Tyler Panplex 2-Phase Oral Tablet',
    strength: '- ',
    formulation: 'Tablet',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00178',
    drugNameID: 78256,
  },
  {
    rxid: 281373,
    name: 'Tyler Similase Oral Capsule',
    strength: '- ',
    formulation: 'Capsule',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00059',
    drugNameID: 78266,
  },
  {
    rxid: 281374,
    name: 'Tyler Similase Sensitive Oral Capsule',
    strength: '- ',
    formulation: 'Capsule',
    take: '   ',
    route: 'Oral',
    frequency: '',
    duration: null,
    dispense: null,
    controlLevel: '0',
    ndcCode: '71791-00125',
    drugNameID: 78269,
  },
];
