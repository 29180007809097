import { useMemo, useState } from 'react';
import { useGetMedicationsWithEncounterQuery } from '../../../../../__generated__/graphql';
import { convertMedsDataToUiData } from '../../utils/convert';
import { selectors, useAppSelector } from '../../../../../state-management';
import { useMedicationState } from '../../hooks/useMedicationState';
import { groupBy } from 'lodash';

export function useGetRxData() {
  const [search, setSearch] = useState('');
  const encounterID = useAppSelector(selectors.encounterSelectors.getActiveEncounterID);
  const { patientId, addedMedications } = useMedicationState();

  const medsQuery = useGetMedicationsWithEncounterQuery({
    variables: {
      patientId,
      // this will include previous encounters
      includePastEncounters: true,
    },
    skip: !patientId,
  });

  const medsData = medsQuery.data?.getMedications;
  // group meds by encounters
  const encounters = useMemo(() => {
    // filter medications by the search filter
    // sort by startTime
    // batch into a set of unique ids
    const allPreviousMeds = [...(medsData ?? [])]
      .filter((med) => med.medicationItem?.toLowerCase().includes(search))
      .sort(
        (a, b) => a.baseEncounter?.encounterStartDateTime - b.baseEncounter?.encounterStartDateTime,
      );

    const encounterIds = allPreviousMeds
      .map((m) => m.baseEncounter?.sourceEncounterId)
      .filter((encId) => {
        return encId && encId !== encounterID;
      });

    const uniqueEncounterIds = Array.from(new Set(encounterIds));

    return uniqueEncounterIds.map((id) => {
      let encounterMeds = allPreviousMeds.filter(
        (med) => med.baseEncounter?.sourceEncounterId === id,
      );

      // Deduplicate medications with the same medicationItem
      // prefer the one with the highest rxId
      encounterMeds = Object.values(groupBy(encounterMeds, 'medicationItem')).map((group) =>
        group.reduce((prev, current) => ((prev.rxId ?? 0) > (current.rxId ?? 0) ? prev : current)),
      );

      const baseEncounter = encounterMeds[0].baseEncounter!;

      const dateObj = new Date(baseEncounter.encounterStartDateTime);
      const date = dateObj.toLocaleDateString();
      // format the time to use hh:mm with no seconds
      const time = dateObj.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      const dateTime = `${date} ${time}`;

      const addedMeds = Object.values(addedMedications);
      // remove the baseEncounter from the meds
      // so we can pass it into the 'save' mutation
      const meds = encounterMeds.map(({ baseEncounter, ...med }) => {
        const addedMed = addedMeds.find(
          (added) => added?.medicationItemId === med.medicationItemId,
        );

        let uiMed = convertMedsDataToUiData([
          {
            ...med,
            // we should only present the status as "taking" if there is a matching
            // medication in the addedMedications state
            status: addedMed?.newStatus === 'Taking' ? 'Taking' : 'NotTaking',
            sourceEncounterId: baseEncounter?.sourceEncounterId,
          },
        ])[0];

        // does this med exist in the addedMedications state?
        // if so, let's use that for our values, since it will hold changes to
        // the medication status and other values like startDate, notes, etc.
        if (addedMed) {
          uiMed = {
            ...uiMed,
            ...addedMed,
            status: uiMed.status,
            sourceEncounterId: baseEncounter?.sourceEncounterId,
          };
        }

        return {
          // pass back both the previousStatus and the 'uiMed'
          // this way we can reset the status when the user toggles "taking"
          previousStatus: med.status,
          uiMed,
        };
      });

      return {
        ...baseEncounter,
        dateTime,
        allMedsAreTaking: meds.every((med) => med.uiMed.status === 'Taking'),
        meds,
      };
    });
  }, [medsData, search, addedMedications]);

  return {
    encounters,
    isLoading: medsQuery.loading,
    refetch: medsQuery.refetch,
    search,
    setSearch,
  };
}
