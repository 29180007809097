import type { ComponentStyleConfig } from '@chakra-ui/react';

// not working
export const InputRightElement: ComponentStyleConfig = {
  defaultProps: {
    mr: 10,
  },
  baseStyle: {
    paddingRight: 2,
  },
};
