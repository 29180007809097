import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Text,
  VStack,
  WaitUntilLoaded,
} from '../../../components';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { MedicalHistoryEmptyStateIllustration } from './MedicalHistoryEmptyStateIllustration';
import {
  BiChevronDown as ChevronDownIcon,
  BiCheck as CheckIcon,
  BiPlus as PlusIcon,
} from 'react-icons/bi';
import { isEmpty } from 'lodash';
import { useAppUI } from '../../../hooks';
import { useEffect, useMemo, useState } from 'react';
import { AsyncICDDescriptionSearchField } from './AsyncICDDescriptionSearchField';
import { uiActions, useAppDispatch } from '../../../state-management';
import { useMedicalHistory } from './useMedicalHistory';
import { AsyncNameSearchField } from './AsyncNameSearchField';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';

interface AddedMedicalHistoryListProps {
  items: MedicalHistoryViewItem[];
}

type SearchType = 'name' | 'icdOrDescription';

export function MedicalHistoryContent({ nextStep }: { nextStep: () => void }) {
  const dispatch = useAppDispatch();
  const {
    addedMedicalHistoryItems,
    medicalHistoryItems,
    handleUpdateMedicalHistory,
    isSuccessUpdateMedicalHistory,
    isLoadingUpdateMedicalHistory,
    fetchedMedHistoryItemsByName,
    modifiablePatientMedicalHistory,
    isLoadingPatientMedicalHistory,
  } = useMedicalHistory();

  const { markCurrentStepComplete } = useAppUI();
  const [searchType, setSearchType] = useState<SearchType>('name');
  const isNameSearch = searchType === 'name';

  useEffect(() => {
    if (isSuccessUpdateMedicalHistory) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isSuccessUpdateMedicalHistory, markCurrentStepComplete]);

  function handleOptionSelect(option: MedicalHistoryViewItem | undefined | null) {
    if (option != null) {
      dispatch(uiActions.addMedicalHistoryItem(option));
    }
  }

  function handleSearchTypeChange(val: SearchType) {
    setSearchType(val);
  }
  const combinedMedicalHistoryItems = useMemo(
    () => [...modifiablePatientMedicalHistory, ...addedMedicalHistoryItems],
    [modifiablePatientMedicalHistory, addedMedicalHistoryItems],
  );

  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg' alignItems='stretch'>
        <MAWorkFlowHeader>Medical History</MAWorkFlowHeader>
        <HStack zIndex={1} width='full'>
          <InputGroup>
            <InputLeftAddon bgColor='white'>
              <RadioGroup onChange={handleSearchTypeChange} value={searchType}>
                <HStack spacing='lg'>
                  <Radio value='name'>By Name</Radio>
                  <Radio value='icdOrDescription'>ICD / Desc.</Radio>
                </HStack>
              </RadioGroup>
            </InputLeftAddon>
            {isNameSearch ? (
              <AsyncNameSearchField
                autoFocus
                handleOptionSelect={handleOptionSelect}
                dropdownItems={fetchedMedHistoryItemsByName}
              />
            ) : (
              <AsyncICDDescriptionSearchField
                autoFocus
                handleOptionSelect={handleOptionSelect}
                dropdownItems={medicalHistoryItems}
              />
            )}
          </InputGroup>
        </HStack>

        {/* Illustration */}
        <WaitUntilLoaded loading={isLoadingPatientMedicalHistory}>
          {isEmpty(combinedMedicalHistoryItems) ? (
            <Center mt='8'>
              <VStack spacing='md'>
                <MedicalHistoryEmptyStateIllustration />
                <Text color='secondary'>
                  To get started, search and add items to the medical history.
                </Text>
              </VStack>
            </Center>
          ) : (
            <AddedMedicalHistoryList items={combinedMedicalHistoryItems} />
          )}
        </WaitUntilLoaded>
      </VStack>
      <SaveAndWorklistButtons
        onClick={() => {
          handleUpdateMedicalHistory();
        }}
        isLoading={isLoadingUpdateMedicalHistory}>
        Save / Verify Medical History
      </SaveAndWorklistButtons>
    </MAWorkFlowContentContainer>
  );
}

function AddedMedicalHistoryList({ items: medicalHistoryItems }: AddedMedicalHistoryListProps) {
  const dispatch = useAppDispatch();
  function handleRemoveAddedMedicalHistoryItem(id: MedicalHistoryItemId) {
    dispatch(uiActions.removeMedicalHistoryItem(id));
  }
  function handleRemoveExistingPatientMedicalHistoryItem(id: MedicalHistoryItemId) {
    dispatch(uiActions.removeExistingPatientMedicalHistoryItem(id));
  }
  function handleAddToProblemList(id: MedicalHistoryItemId) {
    dispatch(uiActions.addMedHistoryItemToProblemList(id));
  }
  function handleRemoveFromProblemList(id: MedicalHistoryItemId) {
    dispatch(uiActions.removeMedHistoryItemFromProblemList(id));
  }

  return (
    <VStack spacing='sm' w='full'>
      {medicalHistoryItems.map((item) => {
        const id = item.id;
        return (
          <Card key={item.id} p='md' w='full'>
            <Flex justifyContent='space-between' alignItems='center'>
              <Box flex='4'>
                <Text>{item.name}</Text>
              </Box>
              <Box flex='1' pl='sm'>
                <Text>{item.icdCode}</Text>
              </Box>
              <HStack spacing='sm'>
                <Button
                  variant='ghost'
                  onClick={() => {
                    handleRemoveAddedMedicalHistoryItem(id);
                    handleRemoveExistingPatientMedicalHistoryItem(id);
                  }}>
                  Delete
                </Button>

                {!item.isByName && (
                  <Box minW='228px'>
                    {item.addToPatientProblemList ? (
                      <RemoveFromProblemList
                        handleRemoveFromProblemList={() => {
                          handleRemoveFromProblemList(id);
                        }}
                      />
                    ) : (
                      <AddToProblemList
                        handleAddToProblemListClick={() => {
                          handleAddToProblemList(id);
                        }}
                      />
                    )}
                  </Box>
                )}
              </HStack>
            </Flex>
          </Card>
        );
      })}
    </VStack>
  );
}

function RemoveFromProblemList({
  handleRemoveFromProblemList,
}: {
  handleRemoveFromProblemList: () => void;
}) {
  return (
    <ButtonGroup isAttached variant='solid' w='full'>
      <Button w='full' leftIcon={<Icon boxSize={5} as={CheckIcon} />}>
        On Problem List
      </Button>

      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label='On problem list'
            icon={<Icon boxSize={5} as={ChevronDownIcon} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <Button variant='ghost' onClick={handleRemoveFromProblemList}>
            Remove
          </Button>
        </PopoverContent>
      </Popover>
    </ButtonGroup>
  );
}

function AddToProblemList({
  handleAddToProblemListClick,
}: {
  handleAddToProblemListClick: () => void;
}) {
  return (
    <ButtonGroup isAttached variant='outline' w='full'>
      <Button
        onClick={handleAddToProblemListClick}
        w='full'
        leftIcon={<Icon boxSize={5} as={PlusIcon} />}>
        To Problem List
      </Button>
    </ButtonGroup>
  );
}
