import React, { useEffect, useState } from 'react';
import { Button, ModalCloseButton, ModalHeader } from '../../index';
import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { ConstellationRecommendationRow } from './ConstellationRecommendationRow';
import { useModal } from '../../../hooks';
import { type ConstellationRecommendationUI } from '../../../types';

interface ConstellationRecommendationModalProps {
  constellationRecommendations: ConstellationRecommendationUI[];
  saveHandler: (orderableItems: ConstellationRecommendationUI[]) => Promise<void>;
}

export function ConstellationRecommendationModal({
  constellationRecommendations,
  saveHandler,
}: ConstellationRecommendationModalProps) {
  const { hideModal } = useModal();
  const [recommendations, setRecommendations] = useState<ConstellationRecommendationUI[]>([]);
  useEffect(() => {
    setRecommendations(constellationRecommendations.map((r) => ({ ...r })));
  }, [constellationRecommendations]);

  const orderChangeHandler = (order: ConstellationRecommendationUI) => {
    const temp = recommendations.map((rec) => {
      if (rec.id === order.id) {
        return {
          ...rec,
          shouldDecline: order.shouldDecline,
          declineReason: order.declineReason,
        };
      }
      return rec;
    });
    setRecommendations(temp);
  };
  return (
    <>
      <ModalHeader>Administer Tests</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {recommendations.map((rec) => {
          return (
            <ConstellationRecommendationRow
              orderChangeHandler={orderChangeHandler}
              key={rec.id}
              rec={rec}
            />
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button
          ml='auto'
          onClick={() => {
            void saveHandler(recommendations);
            hideModal();
          }}
        >
          Submit {constellationRecommendations.length} order
          {constellationRecommendations.length > 1 ? 's' : ''}
        </Button>
      </ModalFooter>
    </>
  );
}
