import { type VitalValidationResult } from './VitalValidationResult';

export class VitalValidation {
  vitalValidations: VitalValidationResult[] = [];
  first: VitalValidationResult | undefined;

  getValidationResultWithGivenValue(value: string) {
    return this.vitalValidations.find((validation) => validation.enteredValue === value);
  }

  addVitalValidationResult(vitalValidation: VitalValidationResult): void {
    this.vitalValidations.push(vitalValidation);
    this.first = this.vitalValidations[0];
  }

  numItems() {
    return this.vitalValidations.length;
  }
}
