import { Page, VStack } from '../../../components';
import {
  EncounterFilters,
  EncounterTable,
  OpenTETable,
  ResultsTabs,
  TEFilters,
} from './components';
import { Header } from '../components';
import useOpenTEsQuery from '../hooks/useOpenTEsQuery';
import {
  OrderableItemReviewedStatus,
  type ProviderEncounterListItem,
  ProviderEncounterStatus,
  useGetProviderEncounterListItemsQuery,
} from '../../../__generated__/graphql';
import {
  getEncounterFilterState,
  getEncounterSort,
  getPatientSearchState,
  getSelectedTab,
  useAppSelector,
} from '../../../state-management';
import { type EncounterTabOption } from '../../../types';
import { PROVIDER_WORKFLOW_ENCOUNTER_PAGE_SIZE } from '../../../constants';
import { useState, useEffect } from 'react';
import { Drawer, useDisclosure, useToast } from '@chakra-ui/react';
import { EditPanel } from '../OpenTEScreen/components/EditPanel';
import { apolloClient } from '../../../api/apollo-api';
import { useNavigate } from 'react-router-dom';

export function ListEncountersScreen() {
  const filters = useAppSelector(getEncounterFilterState);

  const patientSearch = useAppSelector(getPatientSearchState);
  const selectedTab = useAppSelector(getSelectedTab);
  const encounterSort = useAppSelector(getEncounterSort);

  const variables = {
    assigneeIds: filters.assignees?.map((assignee) => Number(assignee.value)),
    facilityIds: filters.locations?.map((location) => Number(location.value)),
    providerIds: filters.providers?.map((provider) => Number(provider.value)),
    labImagingProcedureIds: filters.procedures?.map((procedure) => Number(procedure.value)),
    patientIds: patientSearch.selectedIds?.map((x) => Number(x.value)),
    limit: PROVIDER_WORKFLOW_ENCOUNTER_PAGE_SIZE,
    onlyDelinquent: filters.onlyDelinquent,
    // provide an initial offset of 0
    // this is updated via the 'fetchMore' prop
    // returned by apollo query
    offset: 0,
    orderByColumn: encounterSort?.orderByColumn,
    orderDirection: encounterSort?.orderDirection,
  };

  const {
    data: reviewedData,
    loading: reviewedLoading,
    fetchMore: fetchMoreReviewed,
  } = useGetProviderEncounterListItemsQuery({
    variables: {
      ...variables,
      status: ProviderEncounterStatus.Reviewed,
    },
    onCompleted: () => {
      setFetchingMore(false);
    },
  });
  const reviewed = reviewedData?.getProviderEncounterListItems?.items || [];

  const {
    data: outstandingData,
    loading: oustandingLoading,
    fetchMore: fetchMoreOutstanding,
  } = useGetProviderEncounterListItemsQuery({
    variables: {
      ...variables,
      status: ProviderEncounterStatus.Outstanding,
    },
    onCompleted: () => {
      setFetchingMore(false);
    },
  });
  const outstanding = outstandingData?.getProviderEncounterListItems?.items || [];

  const {
    data: toBeReviewedData,
    loading: toBeReviewedLoading,
    fetchMore: fetchMoreToBeReviewed,
  } = useGetProviderEncounterListItemsQuery({
    variables: {
      ...variables,
      status: ProviderEncounterStatus.ToBeReviewed,
    },
    onCompleted: () => {
      setFetchingMore(false);
    },
  });
  const toBeReviewed = toBeReviewedData?.getProviderEncounterListItems?.items || [];

  const { data: openTEData } = useOpenTEsQuery();

  const [fetchingMore, setFetchingMore] = useState<boolean>(false);
  const selectedTEId = new URLSearchParams(window.location.search).get('telephoneEncounterId');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const nav = useNavigate();

  const handleSave = async () => {
    try {
      await apolloClient.refetchQueries({
        include: ['GetTelephoneEncounters'],
      });
    } catch (e: any) {
      console.error(e);

      toast({
        title: e.message,
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (selectedTEId) {
      onOpen();
    }
  }, [selectedTEId]);

  return (
    <Page>
      <Header />
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        isFullHeight
        size='full'
        onEsc={() => {
          nav('/providers', { replace: true });
        }}>
        <EditPanel
          onClose={() => {
            onClose();
            nav('/providers', { replace: true });
          }}
          onSave={handleSave}
          selectedTEId={selectedTEId ?? undefined}
        />
      </Drawer>
      <VStack spacing={6} marginTop={6} align='stretch'>
        <ResultsTabs
          reviewed={reviewed?.length}
          toBeReviewed={toBeReviewed?.length}
          outstandingEncounters={outstanding?.length}
          totalOutstandingEncounters={outstandingData?.getProviderEncounterListItems?.total ?? 0}
          totalReviewed={reviewedData?.getProviderEncounterListItems?.total ?? 0}
          totalToBeReviewed={toBeReviewedData?.getProviderEncounterListItems?.total ?? 0}
          openTEs={openTEData?.getTelephoneEncounters?.items?.length ?? 0}
          totalTEs={openTEData?.getTelephoneEncounters?.total ?? 0}
        />

        {!(selectedTab === 'Open TEs') ? <EncounterFilters /> : <TEFilters />}
        <RenderTable
          loading={reviewedLoading || toBeReviewedLoading || oustandingLoading}
          fetchingMore={fetchingMore}
          setFetchingMore={setFetchingMore}
          {...{
            toBeReviewedTotal: toBeReviewedData?.getProviderEncounterListItems?.total ?? 0,
            outstandingTotal: outstandingData?.getProviderEncounterListItems?.total ?? 0,
            reviewedTotal: reviewedData?.getProviderEncounterListItems?.total ?? 0,
            outstandingData: outstanding || [],
            reviewedData: reviewed || [],
            toBeReviewedData: toBeReviewed || [],
            selectedTab,
            fetchMoreReviewed,
            fetchMoreOutstanding,
            fetchMoreToBeReviewed,
          }}
        />
      </VStack>
    </Page>
  );
}

const RenderTable = ({
  selectedTab,
  outstandingData,
  toBeReviewedData,
  reviewedData,
  loading,
  fetchingMore,
  setFetchingMore,
  outstandingTotal,
  reviewedTotal,
  toBeReviewedTotal,
  fetchMoreReviewed,
  fetchMoreOutstanding,
  fetchMoreToBeReviewed,
}: {
  selectedTab: EncounterTabOption;
  outstandingData: ProviderEncounterListItem[];
  outstandingTotal: number;
  toBeReviewedData: ProviderEncounterListItem[];
  toBeReviewedTotal: number;
  reviewedData: ProviderEncounterListItem[];
  reviewedTotal: number;
  loading: boolean;
  fetchingMore: boolean;
  setFetchingMore: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMoreReviewed?: any;
  fetchMoreOutstanding?: any;
  fetchMoreToBeReviewed?: any;
}) => {
  switch (selectedTab) {
    case 'To Be Reviewed':
      return (
        <EncounterTable
          totalRecordsAvailable={toBeReviewedTotal}
          status={OrderableItemReviewedStatus.ToBeReviewed}
          loading={loading}
          fetchingMore={fetchingMore}
          setFetchingMore={setFetchingMore}
          data={toBeReviewedData || []}
          fetchMore={fetchMoreToBeReviewed}
        />
      );
    case 'Outstanding':
      return (
        <EncounterTable
          totalRecordsAvailable={outstandingTotal}
          status={OrderableItemReviewedStatus.Outstanding}
          loading={loading}
          fetchingMore={fetchingMore}
          setFetchingMore={setFetchingMore}
          data={outstandingData || []}
          fetchMore={fetchMoreOutstanding}
        />
      );
    case 'Reviewed':
      return (
        <EncounterTable
          totalRecordsAvailable={reviewedTotal}
          status={OrderableItemReviewedStatus.Reviewed}
          loading={loading}
          fetchingMore={fetchingMore}
          setFetchingMore={setFetchingMore}
          data={reviewedData || []}
          fetchMore={fetchMoreReviewed}
        />
      );
    case 'Open TEs':
      return <OpenTETable />;
    default:
      return <></>;
  }
};
