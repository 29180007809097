import { RecommendationIcon, SavedOrderLineItemCard, VStack } from '../../../../components';
import { selectors, useAppSelector } from '../../../../state-management';
import { isReadOnlyEditedOrder } from '../../../../utils';
import { AddingOrderLineItemCard } from './AddingOrderLineItemCard';
import { ReadOnlyOrderLinteItemCard } from './ReadOnlyOrderLinteItemCard';

interface ReviewOrderCardProps {
  alertId: number;
}

export function ReviewOrderCard({ alertId }: ReviewOrderCardProps) {
  const order = useAppSelector((state) => selectors.getOrderByAlertId(state, alertId));

  const isAddingOrderLineItem =
    order?.status === 'YES_ADDING_DIAGNOSIS' || order?.status === 'NO_ADDING_DECLINE_REASON';

  if (isReadOnlyEditedOrder(order)) {
    return <ReadOnlyOrderLinteItemCard recommendationId={alertId} />;
  }

  return (
    <VStack spacing='md' w='full'>
      {order?.alert && <RecommendationIcon alert={order.alert} />}
      {isAddingOrderLineItem ? (
        <AddingOrderLineItemCard alertId={alertId} /> /** Adding */
      ) : (
        <SavedOrderLineItemCard alertId={alertId} /> /** Saved or Editing */
      )}
    </VStack>
  );
}
