export const getMedication = [
  {
    rxId: 26415121,
    currentMedication: true,
    medicationStartDate: '2021-05-18T00:00:00-04:00',
    medicationStopDate: null,
    medicationItem: 'Ambien',
    medicationStrength: '10 MG',
    medicationFormulation: 'Tablet',
    medicationTake: '1 tablet at bedtime as needed - LONGER TEXT TEST',
    medicationRoute: 'Orally',
    medicationFrequency: 'Once a day',
    medicationDuration: 0,
    medicationDispense: 1200,
    medicationRefills: '',
    status: 'T',
    rxNotes: 'some note',
    rxSource: 'some source',
    drugNameId: 60680,
  },
  {
    rxId: 26415122,
    currentMedication: true,
    medicationStartDate: null,
    medicationStopDate: null,
    medicationItem: 'Eliquis',
    medicationStrength: '5 Mg',
    medicationFormulation: 'Capsule',
    medicationTake: '1 capsule',
    medicationRoute: 'Orally',
    medicationFrequency: '',
    medicationDuration: '',
    medicationDispense: '',
    medicationRefills: '',
    status: 'U',
    rxNotes: 'some note 2',
    rxSource: 'some source 2',
    drugNameId: 60680,
  },
];
