import {
  createConstellationRecommendation,
  hasAnyMatch,
  isEqualOrLargerThen,
  vitalTest,
} from './constellation-recommendations.helpers';

import {
  icdBodyAches,
  icdCough,
  icdDiarrhea,
  icdFluLikeSymptoms,
  icdSoreThroat,
  icdSwollenGlands,
  icdUrinationProblem,
  mono,
  rapidCOVID,
  rapidFluAB,
  rapidStrepA,
  urinalysis,
} from './constellation-recommendations.data';
import { type ConstellationRecommendation } from './constellation-recommendations.types';
import { type EncounterVital } from '../../__generated__/graphql';
import { calculateAge } from '../../utils';

/**
 * This function was created to mirror the API of the generated GraphQL hooks.
 * The reason this was done is to make it easy to migrate back to the version
 * where the business logic lives in the backend.
 */
export const getConstellationRecommendations = async (options: {
  variables: { vitals: EncounterVital[]; chiefComplaints: string[]; dateOfBirth: string };
  onCompleted: (data: { getConstellationRecommendations: ConstellationRecommendation[] }) => void;
}) => {
  _getConstellationRecommendations(
    options.variables.vitals,
    options.variables.chiefComplaints,
    options.variables.dateOfBirth,
  ).then((results) => {
    options.onCompleted({ getConstellationRecommendations: results });
  });
};

export const _getConstellationRecommendations = async (
  vitals: EncounterVital[],
  chiefComplaints: string[],
  dateOfBirth: string,
): Promise<ConstellationRecommendation[]> => {
  const recs: ConstellationRecommendation[] = [];
  const age = calculateAge(dateOfBirth);
  const tempEqualHigherThan99: boolean = vitalTest(vitals, 'temp', isEqualOrLargerThen, 99);
  const chiefComplaintSoreThroat: boolean = hasAnyMatch(chiefComplaints, [icdSoreThroat]);
  const chiefComplaintSwollenLymphNodes: boolean = hasAnyMatch(chiefComplaints, [icdSwollenGlands]);

  if (tempEqualHigherThan99 && chiefComplaintSoreThroat)
    recs.push(createConstellationRecommendation(rapidStrepA, icdSoreThroat));

  if (tempEqualHigherThan99) {
    [icdCough, icdSoreThroat, icdDiarrhea, icdBodyAches, icdFluLikeSymptoms].forEach((icd) => {
      if (hasAnyMatch(chiefComplaints, [icd])) {
        recs.push(createConstellationRecommendation(rapidCOVID, icd));
        recs.push(createConstellationRecommendation(rapidFluAB, icd));
      }
    });
  }

  const chiefComplaintUrination: boolean = hasAnyMatch(chiefComplaints, [icdUrinationProblem]);
  if (chiefComplaintUrination) {
    recs.push(createConstellationRecommendation(urinalysis, icdUrinationProblem));
  }
  if (age < 18 && tempEqualHigherThan99 && chiefComplaintSoreThroat) {
    recs.push(createConstellationRecommendation(mono, icdSoreThroat));
  }
  if (age < 18 && tempEqualHigherThan99 && chiefComplaintSwollenLymphNodes) {
    recs.push(createConstellationRecommendation(mono, icdSwollenGlands));
  }

  return recs;
};
