import { Button, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import { Card, Flex } from '../../../components';
import { AsyncSelectField } from '../../../features';
import { AiFillEdit as EditIcon } from 'react-icons/ai';
import {
  useDebounce,
  useEncounter,
  useMAWorkflow,
  useModal,
  usePatient,
  useToast,
} from '../../../hooks';
import {
  hospitalizationsSelectors,
  surgeriesSelectors,
  uiActions,
  useAppDispatch,
} from '../../../state-management';
import { MAWorkFlowHeader } from '../MAWorkFlowHeader';
import { SurgeriesEmptyStateIllustration } from './SurgeriesEmptyStateIllustration';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import {
  type HospitalizationLookup,
  type SurgeryLookup,
  useGetHospitalizationLookupsQuery,
  useGetSurgeryAndHospitalizationHistoryQuery,
  useGetSurgeryLookupsQuery,
  useUpdateHospitalizationsMutation,
  useUpdateSurgeriesMutation,
} from '../../../__generated__/graphql';

export function SurgeriesContent({ nextStep }: { nextStep: () => void }) {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const { activeEncounterID } = useEncounter();
  const { maWorkFlowState, markCurrentStepComplete, getHasLoadedInitialData } = useMAWorkflow();
  const hasLoadedInitialData = getHasLoadedInitialData('surgeries');
  const [isSuccessUpdateHospitalizations, setIsSuccessUpdateHospitalizations] = useState(false);
  const [isSuccessUpdateSurgeries, setIsSuccessUpdateSurgeries] = useState(false);

  const { patientId } = usePatient();
  const { addedSurgeries } = maWorkFlowState.surgeries;
  const { addedHospitalizations } = maWorkFlowState.hospitalizations;
  const surgicalHistoryItems = surgeriesSelectors.selectAll(addedSurgeries);
  const hospitalizationItems = hospitalizationsSelectors.selectAll(addedHospitalizations);

  // Search terms
  const [surgerySearchText, setSurgerySearchText] = useState<string>('');
  const debouncedSurgerySearchText = useDebounce(surgerySearchText);
  const [hospitalizationSearchText, setHospitalizationSearchText] = useState<string>('');
  const debouncedHospitalizationSearchText = useDebounce(hospitalizationSearchText);

  const {
    data: dataGetSurgeryLookupsData,
    loading: isLoadingGetSurgeryLookups,
    error: errorGetSurgeryLookups,
  } = useGetSurgeryLookupsQuery({
    variables: { contains: debouncedSurgerySearchText },
    skip: !debouncedSurgerySearchText,
  });

  const dataGetSurgeryLookups = dataGetSurgeryLookupsData?.getSurgeryLookups;
  const isErrorGetSurgeryLookups = !!errorGetSurgeryLookups;

  const {
    data: dataGetHospitalizationLookupsData,
    error: errorGetHospitalizationLookups,
    loading: isLoadingGetHospitalizationLookups,
  } = useGetHospitalizationLookupsQuery({
    variables: { contains: debouncedHospitalizationSearchText },
    skip: !debouncedHospitalizationSearchText,
  });

  const dataGetHospitalizationLookups =
    dataGetHospitalizationLookupsData?.getHospitalizationLookups;

  const isErrorGetHospitalizationLookups = !!errorGetHospitalizationLookups;

  // Update surgeries
  const [
    updateSurgeries,
    {
      loading: isLoadingUpdateSurgeries,
      error: errorUpdateSurgeries,
      called: calledUpdateSurgeries,
      data: updateSurgeriesData,
    },
  ] = useUpdateSurgeriesMutation();

  const isErrorUpdateSurgeries =
    calledUpdateSurgeries && !!errorUpdateSurgeries && !updateSurgeriesData;

  const [
    updateHospitalizations,
    { loading: isLoadingUpdateHospitalizations, error: errorUpdateHospitalizations },
  ] = useUpdateHospitalizationsMutation();

  const isErrorUpdateHospitalizations = !!errorUpdateHospitalizations;

  const {
    data: dataGetSurgeryAndHospitalizationHistory,
    error: errorGetSurgeryAndHospitalizationHistory,
  } = useGetSurgeryAndHospitalizationHistoryQuery({
    variables: { patientId },
    skip: !patientId,
  });

  // TODO: move away from this pattern of calling an update mutation then looking at an external success/error
  // variable for status of the mutation. The codegened mutation hook provides an onComplete & onError option callback.
  const isErrorGetSurgeryAndHospitalizationHistory = !!errorGetSurgeryAndHospitalizationHistory;
  const isSuccessGetSurgeryAndHospitalizationHistory = !!dataGetSurgeryAndHospitalizationHistory;

  const fetchedSurgeries = dataGetSurgeryAndHospitalizationHistory?.getSurgeries;
  const fetchedHospitalizations = dataGetSurgeryAndHospitalizationHistory?.getHospitalizations;

  const hasNoItems = isEmpty(hospitalizationItems) && isEmpty(surgicalHistoryItems);
  const isLoading = isLoadingUpdateSurgeries && isLoadingUpdateHospitalizations;
  const isSuccess = isSuccessUpdateSurgeries && isSuccessUpdateHospitalizations;
  // const isError = isErrorUpdateSurgeries && isErrorUpdateHospitalizations;

  // Setup toasts

  const TOAST_DURATION = 6000;

  // success
  useToast({
    id: 'submit-surgical-history-success',
    title: 'Success',
    description: 'Successfully submitted surgical history.',
    show: isSuccessUpdateSurgeries,
    status: 'success',
    duration: TOAST_DURATION,
  });

  useToast({
    id: 'submit-hospitalization-success',
    title: 'Success',
    description: 'Successfully submitted hospitalizations.',
    show: isSuccessUpdateHospitalizations,
    status: 'success',
    duration: TOAST_DURATION,
  });

  // error

  useToast({
    id: 'isErrorGetSurgeryLookups-isErrorGetHospitalizationLookups',
    title: 'Error',
    description: 'Failed to fetch data.',
    show: isErrorGetSurgeryLookups || isErrorGetHospitalizationLookups,
    status: 'error',
    duration: TOAST_DURATION,
  });

  useToast({
    id: 'submit-surgical-history-error',
    title: 'Error',
    description: 'Failed to submit surgical history.',
    show: isErrorUpdateSurgeries,
    status: 'error',
    duration: TOAST_DURATION,
  });

  useToast({
    id: 'submit-hospitalization-error',
    title: 'Error',
    description: 'Failed to submit hospitalizations.',
    show: isErrorUpdateHospitalizations,
    status: 'error',
    duration: TOAST_DURATION,
  });

  useToast({
    id: 'isErrorGetSurgeryAndHospitaliztionHistory',
    title: 'Error',
    description: 'Failed to fetch surgeries or hospitalizations.',
    show: isErrorGetSurgeryAndHospitalizationHistory,
    status: 'error',
    duration: TOAST_DURATION,
  });

  // Fetch initial surgeries and hospitalizations
  useEffect(() => {
    if (isSuccessGetSurgeryAndHospitalizationHistory && !hasLoadedInitialData) {
      dispatch(uiActions.setHasLoadedInitialData('surgeries'));
      if (fetchedSurgeries?.length) {
        dispatch(
          uiActions.setAddedSurgeries(
            fetchedSurgeries.map((surgery) => {
              return {
                ...surgery,
                tempId: uuid(),
              };
            }),
          ),
        );
      }
      if (fetchedHospitalizations?.length) {
        dispatch(
          uiActions.setAddedHospitalizations(
            fetchedHospitalizations.map((hospitalization) => {
              return { ...hospitalization, tempId: uuid() };
            }),
          ),
        );
      }
    }
  }, [
    dispatch,
    isSuccessGetSurgeryAndHospitalizationHistory,
    fetchedSurgeries,
    fetchedHospitalizations,
  ]);

  // Handle update responses
  useEffect(() => {
    if (isSuccess) {
      markCurrentStepComplete();
      nextStep();
    }
  }, [isSuccess]);

  const surgeryOptions = dataGetSurgeryLookups?.map((item: SurgeryLookup) => {
    const name = item.name || '';
    return {
      label: name,
      value: name,
      entity: item,
    };
  });

  const hospitalizationOptions = dataGetHospitalizationLookups?.map(
    (item: HospitalizationLookup) => {
      const name = item.name || '';
      return {
        label: name,
        value: name,
        entity: item,
      };
    },
  );

  function handleUpateSurgicalHistoryAndHospitalization() {
    if (activeEncounterID && patientId && surgicalHistoryItems) {
      const surgicalHistoryDtos = surgicalHistoryItems.map((surgery) => {
        const { reason, surgeryDate } = surgery;
        return { reason: reason || '', surgeryDate };
      });
      void updateSurgeries({
        variables: {
          encounterId: activeEncounterID,
          patientId,
          events: surgicalHistoryDtos,
        },
        onError: (err) => {
          console.log('Error saving surgeries', err);
        },
        onCompleted: () => {
          setIsSuccessUpdateSurgeries(true);
        },
      });
    }
    if (activeEncounterID && patientId && hospitalizationItems) {
      const hospitalizationHistoryDtos = hospitalizationItems.map((hospitalization) => {
        const { reason, hospitalizationDate } = hospitalization;
        return { reason: reason || '', hospitalizationDate };
      });
      void updateHospitalizations({
        variables: {
          encounterId: activeEncounterID,
          patientId,
          events: hospitalizationHistoryDtos,
        },
        onError: (err) => {
          console.log('Error saving hospitalizations', err);
        },
        onCompleted: () => {
          setIsSuccessUpdateHospitalizations(true);
        },
      });
    }
  }

  function handleClickEditSurgery(tempId: TempId) {
    return () => {
      dispatch(uiActions.startEditSurgery(tempId));
      showModal({
        modalType: 'SurgeryModal',
        chakraModalProps: { size: 'xs' },
        modalProps: {
          type: 'edit',
        },
      });
    };
  }

  function handleClickEditHospitalization(tempId: TempId) {
    return () => {
      dispatch(uiActions.startEditHospitalization(tempId));
      showModal({
        modalType: 'HospitalizationModal',
        chakraModalProps: { size: 'xs' },
        modalProps: {
          type: 'edit',
        },
      });
    };
  }

  function addSurgery(reason: string) {
    const tempId = uuid();
    dispatch(uiActions.addSurgery({ reason, tempId }));
    dispatch(uiActions.startEditSurgery(tempId));
    showModal({
      modalType: 'SurgeryModal',
      chakraModalProps: { size: 'xs' },
      modalProps: {
        type: 'add',
      },
    });
  }

  function handleAddCustomSurgery(config: { inputValue: string }) {
    addSurgery(config.inputValue);
  }

  function handleSurgeryOptionSelect(option: DropdownOption<SurgeryLookup>) {
    // function handleSurgeryOptionSelect(option: DropdownOption) {
    const reason = option?.entity?.name;
    // const item = getSurgeryLookupsData?.find(
    //   (lookup) => lookup?.name === option?.value
    // );
    if (reason) {
      addSurgery(reason);
    }
  }

  function addHospitalization(reason: string) {
    const tempId = uuid();
    dispatch(uiActions.addHospitalization({ reason, tempId }));
    dispatch(uiActions.startEditHospitalization(tempId));
    showModal({
      modalType: 'HospitalizationModal',
      chakraModalProps: { size: 'xs' },
      modalProps: {
        type: 'add',
      },
    });
  }

  function handleAddCustomHospitalization(config: { inputValue: string }) {
    addHospitalization(config.inputValue);
  }

  function handleHospitalizationOptionSelect(
    option?: DropdownOption<HospitalizationLookup> | null,
  ) {
    const reason = option?.entity?.name;
    if (reason) {
      addHospitalization(reason);
    }
  }

  return (
    <MAWorkFlowContentContainer>
      <Flex direction='column' justifyContent='space-between' h='full'>
        <div>
          <HStack spacing='md' alignItems='flex-start'>
            <VStack spacing='lg' alignItems='flex-start' flex='1'>
              <MAWorkFlowHeader>Surgical History</MAWorkFlowHeader>
              <AsyncSelectField
                onAddCustomInputValueToList={handleAddCustomSurgery}
                handleOptionSelect={handleSurgeryOptionSelect}
                dropdownItems={surgeryOptions}
                inputProps={{
                  placeholder: 'Search Surgeries by Keyword or CPT Description',
                }}
                handleInputChange={(inputValue) => {
                  if (inputValue) {
                    setSurgerySearchText(inputValue);
                  }
                }}
                loading={isLoadingGetSurgeryLookups}
              />

              <VStack spacing='md' w='full'>
                {surgicalHistoryItems.map((item) => {
                  return (
                    <Card size='sm' key={item.reason}>
                      <HStack>
                        <Text variant='body2-b' flex='1'>
                          {item.reason}
                        </Text>
                        <Text fontSize='sm' flex='1'>
                          {item.surgeryDate}
                        </Text>
                        <Button variant='ghost' onClick={handleClickEditSurgery(item.tempId)}>
                          <EditIcon />
                        </Button>
                        <Button
                          variant='ghost'
                          onClick={() => {
                            dispatch(uiActions.removeSurgery(item.tempId));
                          }}>
                          Remove
                        </Button>
                      </HStack>
                    </Card>
                  );
                })}
              </VStack>
            </VStack>

            <VStack spacing='lg' alignItems='flex-start' flex='1'>
              <MAWorkFlowHeader>Hospitalization</MAWorkFlowHeader>
              <AsyncSelectField
                onAddCustomInputValueToList={handleAddCustomHospitalization}
                handleOptionSelect={handleHospitalizationOptionSelect}
                inputProps={{
                  placeholder: 'Add Hospitalization',
                }}
                dropdownItems={hospitalizationOptions}
                handleInputChange={(inputValue) => {
                  if (inputValue) {
                    setHospitalizationSearchText(inputValue);
                  }
                }}
                loading={isLoadingGetHospitalizationLookups}
              />
              <VStack spacing='md' w='full'>
                {hospitalizationItems.map((item) => {
                  return (
                    <Card size='sm' key={item.reason}>
                      <HStack>
                        <Text variant='body2-b' flex='1'>
                          {item.reason}
                        </Text>
                        <Text fontSize='sm' flex='1'>
                          {item.hospitalizationDate}
                        </Text>
                        <Button
                          variant='ghost'
                          onClick={handleClickEditHospitalization(item.tempId)}>
                          <EditIcon />
                        </Button>
                        <Button
                          variant='ghost'
                          onClick={() => {
                            dispatch(uiActions.removeHospitalization(item.tempId));
                          }}>
                          Remove
                        </Button>
                      </HStack>
                    </Card>
                  );
                })}
              </VStack>
            </VStack>
          </HStack>

          {hasNoItems && (
            <Center mt='8'>
              <VStack spacing='md'>
                <SurgeriesEmptyStateIllustration />
                <Text color='secondary'>
                  To get started, search and add items to the patient's history.
                </Text>
              </VStack>
            </Center>
          )}
        </div>
        <SaveAndWorklistButtons
          onClick={handleUpateSurgicalHistoryAndHospitalization}
          disabled={hasNoItems}
          isLoading={isLoading}>
          Save / Verify Surgical History
        </SaveAndWorklistButtons>
      </Flex>
    </MAWorkFlowContentContainer>
  );
}
