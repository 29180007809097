import { useCombobox } from 'downshift';
import { Button, InputGroup, type InputProps, InputRightElement } from '@chakra-ui/react'; // TODO: Get from components
import { Flex, Input, Box, List, ListItem, Spinner, Text } from '../../../components';
import { isEmpty } from 'lodash';
import { useMedicalHistory } from './useMedicalHistory';

interface AsyncSelectProps extends InputProps {
  dropdownItems?: MedicalHistoryViewItem[];
  handleOptionSelect: (option: MedicalHistoryViewItem | undefined | null) => void;
}

const FLEX_1 = 1;
const FLEX_2 = 3;

export function AsyncICDDescriptionSearchField({
  dropdownItems: options,
  handleOptionSelect,
}: AsyncSelectProps) {
  const {
    handleICDSearchChange,
    handleDescriptionSearchChange,
    isLoadingMedicalHistoryOptionsByCode,
  } = useMedicalHistory();

  // Combobox hook for ICD input
  const { isOpen, reset, getMenuProps, getInputProps, highlightedIndex, getItemProps, inputValue } =
    useCombobox({
      items: options != null ? options : [],
      itemToString: (item) => (item?.icdCode ? item.icdCode : ''),
      // itemToString: (item) => "",
      onSelectedItemChange: ({ selectedItem }) => {
        handleOptionSelect(selectedItem);
        reset();
        resetDescription();
      },
      onInputValueChange: ({ inputValue, selectedItem }) => {
        // prevent wasteful API call when input val has already been added. No need to fetch it again
        if (selectedItem?.icdCode !== inputValue) {
          handleICDSearchChange(inputValue || '');
        }
      },
    });

  // Combobox hook for Description name input
  const {
    isOpen: isOpenDescription,
    reset: resetDescription,
    getMenuProps: getMenuPropsDescription,
    getInputProps: getInputPropsDescription,
    highlightedIndex: highlightedIndexDescription,
    getItemProps: getItemPropsDescription,
    inputValue: inputValueDescripton,
  } = useCombobox({
    items: options != null ? options : [],
    itemToString: (item) => (item != null ? item.name : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      handleOptionSelect(selectedItem);
      reset();
      resetDescription();
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      // prevent wasteful API call when input val has already been added. No need to fetch it again
      if (selectedItem?.name !== inputValue) {
        handleDescriptionSearchChange(inputValue || '');
      }
    },
  });
  // const showDropDown = ((isOpen || isOpenDescription) && options) || loading;
  const showDropDownICD = !!(isOpen && options != null) || isLoadingMedicalHistoryOptionsByCode;
  const showDropDownDescription =
    !!(isOpenDescription && options != null) || isLoadingMedicalHistoryOptionsByCode;
  const showDropDown = showDropDownICD || showDropDownDescription;
  const showClearButton = !!inputValue || !!inputValueDescripton;

  return (
    <Box w='full' pos='relative'>
      <Flex w='full'>
        <Box flex={FLEX_1}>
          <Input
            {...getInputProps()}
            {...{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              placeholder: 'ICD',
            }}
          />
        </Box>
        <Box flex={FLEX_2}>
          <InputGroup>
            <Input
              {...getInputPropsDescription()}
              {...{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                placeholder: 'Description',
              }}
            />
            {showClearButton && (
              <InputRightElement>
                <Box pr='lg'>
                  <Button
                    size='sm'
                    onClick={() => {
                      reset();
                      resetDescription();
                    }}
                    aria-label='clear'
                    variant='ghost'
                  >
                    Clear
                  </Button>
                </Box>
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
      </Flex>

      <DropDown
        {...{
          getMenuProps,
          showDropDown,
          loading: isLoadingMedicalHistoryOptionsByCode,
          options,
          getItemProps,
          highlightedIndex,
        }}
      />
      <DropDown
        {...{
          getMenuProps: getMenuPropsDescription,
          showDropDown: showDropDownDescription,
          loading: isLoadingMedicalHistoryOptionsByCode,
          options,
          getItemProps: getItemPropsDescription,
          highlightedIndex: highlightedIndexDescription,
        }}
      />
    </Box>
  );
}

function DropDown({
  getMenuProps,
  showDropDown,
  loading,
  options,
  getItemProps,
  highlightedIndex,
}: {
  showDropDown: boolean;
  loading: boolean;
  options: AsyncSelectProps['dropdownItems'];
  getMenuProps: () => any;
  getItemProps: (v: any) => any;
  highlightedIndex: number;
}) {
  return (
    <List
      {...getMenuProps()}
      sx={
        showDropDown
          ? {
              pos: 'absolute',
              zIndex: 'dropdown',
              bg: 'white',
              shadow: 'sm',
              rounded: 'md',
              p: 'md',
              mt: 'sm',
              w: 'full',
              maxH: '360px',
              overflow: 'auto',
              borderWidth: 1,
            }
          : {}
      }
    >
      {!showDropDown ? null : loading ? (
        <Spinner />
      ) : isEmpty(options) ? (
        <Text textAlign='center' color='gray.400'>
          No results
        </Text>
      ) : (
        options?.map((option, index) => {
          return (
            <ListItem
              key={option.id}
              value={option.name}
              p='sm'
              rounded='md'
              _hover={{ cursor: 'pointer' }}
              {...getItemProps({ item: option, index })}
              sx={highlightedIndex === index ? { backgroundColor: 'gray.100' } : {}}
            >
              <Flex>
                <Box flex={FLEX_1}>{option.icdCode}</Box>
                <Box flex={FLEX_2}>{option.name}</Box>
              </Flex>
            </ListItem>
          );
        })
      )}
    </List>
  );
}
