import { Box, Flex } from '../../layout';
import { Text } from '../../typography';

interface ToggleProps {
  isChecked?: boolean;
  onPress?: () => void;
}
export function Toggle({ onPress = () => {}, isChecked }: ToggleProps) {
  // const [on, toggleOn] = React.useState(true);
  const leftStyles = {
    bg: isChecked ? 'secondaries.0' : 'secondaries.2',
    borderColor: isChecked ? 'secondaries.1' : 'secondaries.2',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    borderRightWidth: 0,
  };

  const rightStyles = {
    bg: isChecked ? 'primary' : 'secondaries.0',
    borderColor: isChecked ? 'primary' : 'secondaries.1',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeftWidth: 0,
  };

  return (
    <Box
      onClick={() => {
        onPress();
      }}
      cursor='pointer'>
      <Flex>
        <Box {...commonStyles} {...leftStyles}>
          <Text
            {...{
              color: isChecked ? 'secondary' : 'white',
              variant: 'body2-b',
            }}>
            No
          </Text>
        </Box>
        <Box {...commonStyles} {...rightStyles}>
          <Text
            {...{
              color: isChecked ? 'white' : 'secondaries.2',
              variant: 'body2-b',
            }}>
            Yes
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

// STYLES
const commonStyles = {
  p: 'sm',
  border: 'md',
  borderRadius: 'md',
  borderWidth: 1,
};

// const leftStylesUnchecked = {
//   borderColor: "secondaries.1",
//   borderTopRightRadius: "0",
//   borderBottomRightRadius: "0",
//   borderRightWidth: 0,
// };

// const leftStylesChecked = {
//   borderColor: "secondaries.1",
//   borderTopRightRadius: "0",
//   borderBottomRightRadius: "0",
//   borderRightWidth: 0,
// };

// const rightStylesChecked = {
//   bg: "primary",
//   borderTopLeftRadius: "0",
//   borderBottomLeftRadius: "0",
//   borderColor: "primary",
//   //   boxShadow: "sm",
// };

// const rightStylesUnchecked = {
//   bg: "primary",
//   borderTopLeftRadius: "0",
//   borderBottomLeftRadius: "0",
//   borderColor: "primary",
//   //   boxShadow: "sm",
// };
