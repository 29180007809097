import { sidebarActions } from '../state-management/sidebarSlice';
import { useAppDispatch, useAppSelector } from '../state-management';

export function useSidebar() {
  const dispatch = useAppDispatch();
  const sidebarState = useAppSelector((state) => state.sidebarSlice);
  return {
    sidebarState,
    moveSidebar: (position: SidebarPosition) => dispatch(sidebarActions.moveSidebar(position)),
    closeSidebar: () => dispatch(sidebarActions.closeSidebar()),
    openSidebar: (config: SidebarState) => dispatch(sidebarActions.openSidebar(config)),
  };
}
