import { useAppDispatch, uiActions } from '../state-management';

export function useAppUI() {
  const dispatch = useAppDispatch();

  //   const isOpen = useAppSelector((state) => state.ui.isLeftNavOpen);

  function markCurrentStepComplete() {
    dispatch(uiActions.markCurrentStepComplete());
  }

  return {
    markCurrentStepComplete,
  };
}
