import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { type EncounterHPIDataItem, type HPISymptom } from '../../../types';
import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { EncounterHPIDataItemModalContent } from './EncounterHPIDataItemModalContent';
import { Box } from '../../layout';
import _ from 'lodash';

interface EncounterHPIDataItemModalProps {
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  categoryName: string;
  symptom: HPISymptom;
  onCloseModal: () => void;
  saveSymptom: (encounterHPIDataItem: EncounterHPIDataItem, symptom: HPISymptom) => void;
  nextHandler: (next: boolean, symptom: HPISymptom) => void;
  enableNext: boolean;
  enableBack: boolean;
}

export function EncounterHPIDataItemModal({
  encounterHPIDataItem: encounterHPIDataItemProp,
  symptom,
  categoryName,
  onCloseModal,
  saveSymptom,
  nextHandler,
  enableBack,
  enableNext,
}: EncounterHPIDataItemModalProps) {
  const [encounterHPIDataItem, setEncounterHPIDataItem] = useState<
    EncounterHPIDataItem | undefined
  >(encounterHPIDataItemProp || undefined);
  if (encounterHPIDataItem?.hpiSymptomId !== encounterHPIDataItemProp?.hpiSymptomId) {
    setEncounterHPIDataItem(encounterHPIDataItemProp);
  }
  return (
    <>
      <ModalHeader>
        {categoryName} {'>'} {symptom.name}
      </ModalHeader>
      <ModalCloseButton />
      {/* overflow visible allows the duration drops down to not be cut off by the modal, any other better ways (maybe portal)? */}
      <ModalBody sx={{ overflow: 'visible' }}>
        <EncounterHPIDataItemModalContent
          encounterHPIDataItem={encounterHPIDataItem}
          setEncounterHPIDataItem={setEncounterHPIDataItem}
          symptomOptions={symptom.symptomOptions}
        />
      </ModalBody>
      <ModalFooter justifyContent={enableNext || enableBack ? 'space-between' : 'flex-end'}>
        {(enableNext || enableBack) && (
          <Box>
            <Button
              isDisabled={!enableBack}
              mr='sm'
              variant='outline'
              onClick={() => {
                if (
                  encounterHPIDataItem &&
                  !_.isEqual(encounterHPIDataItem, encounterHPIDataItemProp)
                ) {
                  saveSymptom(encounterHPIDataItem, symptom);
                }
                nextHandler(false, symptom);
              }}>
              Previous
            </Button>
            <Button
              isDisabled={!enableNext}
              variant='outline'
              onClick={() => {
                if (
                  encounterHPIDataItem &&
                  !_.isEqual(encounterHPIDataItem, encounterHPIDataItemProp)
                ) {
                  saveSymptom(encounterHPIDataItem, symptom);
                }
                nextHandler(true, symptom);
              }}>
              Next
            </Button>
          </Box>
        )}
        <Box>
          <Button variant='ghost' onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (encounterHPIDataItem) {
                saveSymptom(encounterHPIDataItem, symptom);
                onCloseModal();
              }
            }}>
            Save Note
          </Button>
        </Box>
      </ModalFooter>
    </>
  );
}
