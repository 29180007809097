import _ from 'lodash';

interface Sortable {
  [key: string]: any;
  symptomNotes?: string | null;
  complainsDenies?: boolean | null;
  hpiSymptomId?: number | null;
}

export function symptomSortLogic(a: Sortable, b: Sortable) {
  const sortToTopA = !!a.symptomNotes || !_.isNil(a.complainsDenies) || !_.isNil(a.duration);
  const sortToTopB = !!b.symptomNotes || !_.isNil(b.complainsDenies) || !_.isNil(b.duration);

  if (sortToTopA && !sortToTopB) return -1;
  if (!sortToTopA && sortToTopB) return 1;

  if ((a.hpiSymptomId || -1) > (b.hpiSymptomId || -1)) return -1;
  if ((a.hpiSymptomId || -1) < (b.hpiSymptomId || -1)) return 1;

  return 0;
}
