import {
  Box,
  KnownPatientIssues,
  Page,
  Tabs,
  UserInfo,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
} from '../../components';
import {
  OrderHistoryTabPanel,
  EncountersTabPanel,
  MedHistoryTabPanel,
  PatientDocsPanel,
} from './components';

export function PatientViewScreen() {
  return (
    <Page>
      <SimpleGrid paddingBottom='md' alignItems='center' columns={2}>
        <UserInfo />
        <Box>
          <KnownPatientIssues />
        </Box>
      </SimpleGrid>
      <Tabs
        h='full'
        isFitted
        colorScheme='brand'
        display='flex'
        flexDirection='column'
        overflow='auto'>
        <TabList>
          <Tab>Encounters</Tab>
          <Tab>Patient Docs</Tab>
          <Tab>Order History</Tab>
          <Tab>Med. History</Tab>
        </TabList>

        <TabPanels h='full' overflow='auto'>
          <TabPanel>
            <EncountersTabPanel />
          </TabPanel>
          <TabPanel h='full'>
            <PatientDocsPanel />
          </TabPanel>
          <TabPanel>
            <OrderHistoryTabPanel />
          </TabPanel>
          <TabPanel>
            <MedHistoryTabPanel />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
}
