// INVESTIGATE THIS SLICE AND REMOVE AS IT APPEARS UNUSED
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type AuthState } from '../types';

type ForgotPasswordStatus = 'idle' | 'forgot_password_send_email_success';

export interface LoginState {
  status: AuthState;
  isNewUser: boolean;
  errorMessage: string;
  username: string;
  user?: UserUI;
  forgotPassword: {
    status: ForgotPasswordStatus;
  };
}

const initialState: LoginState = {
  // status: "signedIn",
  status: 'signIn',
  isNewUser: false,
  errorMessage: '',
  username: '',
  forgotPassword: {
    status: 'idle',
  },
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserUI>) {
      state.user = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setUsername(state, action: PayloadAction<string>) {
      state.username = action.payload.toLocaleLowerCase();
    },
    setForgotPasswordStatus(state, action: PayloadAction<ForgotPasswordStatus>) {
      state.forgotPassword.status = action.payload;
    },
    changeAuthStatus(state, action: PayloadAction<AuthState>) {
      state.status = action.payload;
    },
    clearErrorMessage(state) {
      state.errorMessage = '';
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
