import { useMedicalHistory } from '../../screens/MAWorkFlowScreen/MedicalHistoryContent/useMedicalHistory';
import { useAppSelector } from '../../state-management';
import { useEffect } from 'react';
import { usePanelHandler } from './usePanelHandler';
import { useUpdateVitalsDirty } from './useVitalsDirty';
import { useSetDirty } from './useSetDirty';

/*
  this hook watches for state changes in the UI of our app and
  attempts to compute if a panel's state is "dirty" (unsaved changes)
  the ui state is passed into a useEffect for each panel
  once a state is marked dirty that will persist until the user saves the changes
  dirty state is held in the ui state as a property of Step[]
  we try to detect if the state is reset to the initial state and if so, we mark it as not dirty
  however, in some instances that is prohibitively complex

  in most components we can watch for the completion of the query that panel's state depends on and then
  then we know that the initialstate is set so we can compare the updated state against the intial state
  this watches for the hasLoadedInitialData property for each of the relevant panels
  panels are only evaluated when they are the current step

  however, there are several panels where that pattern does not hold up. vitals, for example, is a form
  and does not use a query
*/
export function useWatchDirtySteps() {
  const state = useAppSelector((state) => state.ui.orderFlowNavigation);
  const setDirty = useSetDirty();

  // VITALS
  useUpdateVitalsDirty({
    state,
    setDirty,
    isCurrentStep: state.currentStep === 'vitals',
  });

  // COMPLAINTS
  useEffect(() => {
    // chief complaints are only specific to this encounter
    // so we can simply check if there are any added chief complaints
    const val = !(state.addedChiefComplaints.ids.length === 0);
    setDirty('chief-complaint', val);
  }, [state.addedChiefComplaints, state.enteredChiefComplaint]);

  // MEDICATIONS
  usePanelHandler({
    state: [state.medications.addedMedications.entities],
    step: 'medications',
  });

  // MEDICAL HISTORY
  const { noPendingChanges } = useMedicalHistory();
  useEffect(() => {
    /*
      medical history has a pending changes property that's already computed
      and used within the panel to disable/enable the save button
      we can just use that value here for the dirty state
      since there's no point in showing dirty if we can't save
    */
    setDirty('medical-history', !noPendingChanges);
  }, [noPendingChanges]);

  // ALLERGIES
  usePanelHandler({
    state: [state.addedAllergies.entities],
    step: 'allergies',
  });

  // SURGICAL HISTORY
  usePanelHandler({
    state: [
      state.surgeries.addedSurgeries.entities,
      state.hospitalizations.addedHospitalizations.entities,
    ],
    step: 'surgeries',
  });

  // FAMILY HISTORY
  usePanelHandler({
    state: [state.familyHistory as unknown as Record<string, unknown>],
    step: 'family-history',
  });

  // SOCIAL HISTORY
  usePanelHandler({
    state: [state.socialHistory.socialHistoryState as unknown as Record<string, unknown>],
    step: 'social-history',
  });

  // OBGYN HISTORY
  usePanelHandler({
    state: [state.obgyn.gynHistory.entities, state.obgyn.obHistory.entities],
    step: 'obgyn',
  });

  // ROS
  usePanelHandler({
    state: [state.ros],
    step: 'ros',
  });

  // HPI
  usePanelHandler({
    state: [state.hpi],
    step: 'hpi',
  });
}
