import { Textarea } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

export function InternalNotesInput() {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  return (
    <Textarea
      rows={2}
      placeholder='Notes'
      value={globalLabForm?.comments ?? undefined}
      onChange={(e) => {
        handleUpdateGlobalLabForm({
          comments: e.target.value,
        });
      }}
    />
  );
}
