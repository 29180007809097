import {
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Text,
  Tbody,
  Button,
  WaitUntilLoaded,
} from '../../../components';
import React, { useEffect } from 'react';
import { uiActions, useAppDispatch, useAppSelector } from '../../../state-management';
import { useMAWorkflow, useModal, usePatient, useToast } from '../../../hooks';
import { type g } from '../../../api';
import { TableHeader } from './TableHeader';
import { useGetObHistoryAndLookupsQuery } from '../../../__generated__/graphql';

const ERROR_TOAST_DURATION = 8000;

export function OBHistoryPanel() {
  const { patientId } = usePatient();
  const {
    data: dataGetOBHistoryAndInitialLookup, // dataGYNLookup,
    loading: isLoadingGetOBHistoryAndInitialLookup,
    error: errorGetOBHistoryAndInitialLookup,
    refetch: refetchObHistory,
  } = useGetObHistoryAndLookupsQuery({ variables: { patientId } });
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);
  const isErrorGetOBHistoryAndInitialLookup = !(errorGetOBHistoryAndInitialLookup == null);

  const obLookups = dataGetOBHistoryAndInitialLookup?.getObLookups;
  const fetchedObHistory = dataGetOBHistoryAndInitialLookup?.getObHistory;

  const dispatch = useAppDispatch();
  const { maWorkFlowState } = useMAWorkflow();
  const { obHistory } = maWorkFlowState.obgyn;
  const { showModal } = useModal();

  useEffect(() => {
    void refetchObHistory();
  }, [lastUpdate]);

  // Re-hydrate the redux state with user's items from the server
  useEffect(() => {
    if (fetchedObHistory != null) {
      // Remove all null values
      const userObHistory = fetchedObHistory.filter((x) => x !== null) as g.ObHistory[];
      dispatch(uiActions.addManyObHistoryItems(userObHistory));
    }
  }, [JSON.stringify(fetchedObHistory)]);

  // Setup toasts
  useToast({
    id: 'isErrorGetOBHistoryAndInitialLookup',
    title: 'Error',
    description: 'Failed to get OB History',
    show: isErrorGetOBHistoryAndInitialLookup,
    status: 'error',
    duration: ERROR_TOAST_DURATION,
  });

  return (
    <WaitUntilLoaded
      loading={isLoadingGetOBHistoryAndInitialLookup}
      showErrorMessage={isErrorGetOBHistoryAndInitialLookup}>
      <TableContainer>
        <Table variant='simple' size='xs'>
          <Thead>
            <Tr>
              <TableHeader>Symptoms</TableHeader>
              <TableHeader>Notes</TableHeader>
            </Tr>
          </Thead>
          <Tbody>
            {obLookups?.map((lookup) => {
              const symptomID = lookup?.symptomID;

              if (!symptomID) return null;

              const userObDataItem = Object.values(obHistory.entities).find(
                (selectedItem) => selectedItem?.obSymptomID === symptomID,
              );
              const updateBase: g.ObHistory =
                userObDataItem != null
                  ? {
                      // pre-existing user entry
                      ...userObDataItem,
                    }
                  : {
                      // new entry
                      obSymptomID: symptomID,
                      obHistoryStructuredData: null,
                      sourcePatientId: null,
                      symptomNotes: null,
                    };

              return (
                <Tr key={symptomID}>
                  <Td>
                    <Text>{lookup.symptomName}</Text>
                  </Td>
                  <Td>
                    <HStack alignItems='center'>
                      <Button
                        alignSelf='flex-end'
                        variant='ghost'
                        margin='auto 0'
                        onClick={() => {
                          showModal({
                            modalType: 'EditOBSymptomNoteModal',
                            chakraModalProps: { size: '5xl' },
                            modalProps: {
                              obHistoryItem: updateBase,
                              symptomOptions: lookup?.symptomOptions,
                            },
                          });
                        }}>
                        {updateBase.symptomNotes ? 'Edit' : 'Add'}
                      </Button>
                      <Text ml='12px' whiteSpace='initial'>
                        {updateBase.symptomNotes}
                      </Text>
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </WaitUntilLoaded>
  );
}
