export const getOrders = () => [
  {
    alert: {
      alertID: 'A123',
      lastOrdered: '2021-11-03T18:07:57.048Z',
      shortDescription: 'A1C',
      longDescription: `Patient between 10 - 110 years, has "Diabetes" group diagnosis, and has not had A1C in the past 3 months.`,
    },
    declineReason: {
      code: 'ALREADY_ORDERED',
      dateTimeOrdered: '2021-11-03T18:07:57.048Z',
      location: 'Michigan',
      name: 'Already Ordered',
      additionalDetails: 'I already got it.',
    },
    orderType: 'DECLINED',
  },
  {
    alert: {
      alertID: 'A124',
      lastOrdered: '2021-11-03T18:07:57.048Z',
      shortDescription: 'A1C',
      longDescription: `Patient between 10 - 110 years, has "Diabetes" group diagnosis, and has not had A1C in the past 3 months.`,
    },
    diagnosis: {
      diagnosisID: 'D1',
      diagnosisName: 'Diabetes',
    },
    orderType: 'ACCEPTED',
  },
];
