import { useCombobox } from 'downshift';
import {
  Input,
  Button,
  InputRightElement,
  InputGroup,
  VStack,
  IconButton,
  Flex,
  Divider,
  Box,
  List,
  ListItem,
  Spinner,
  Text,
  Icon,
} from '../../components';
import { isEmpty } from 'lodash';
import { useDebounce } from '../../hooks';

import {
  BiX as CloseIcon,
  BiChevronDown as ChevronDownIcon,
  BiChevronUp as ChevronUpIcon,
} from 'react-icons/bi';
import { forwardRef, type Ref, useImperativeHandle, useState } from 'react';

function _AsyncSelectField(
  {
    dropdownItems,
    inputProps,
    loading,
    handleInputChange,
    handleOptionSelect,
    onAddCustomInputValueToList,
    initialInputValue,
    variant = 'combobox',
    openOnFocus,
    isDisabled,
    hideAddItem,
    inputRightElement,
  }: AsyncSelectProps,
  ref: Ref<{ reset: () => void } | undefined>,
) {
  const isSearchSelect = variant === 'search-select';

  const formattedInitialInputValue = initialInputValue?.trim();
  const [addedInput, setAddedInput] = useState(formattedInitialInputValue);

  // Ensure options are unique to prevent bugs with drop down
  const optionsObj =
    dropdownItems?.reduce((acc: Record<string, DropdownOption>, item) => {
      const formatted = item?.label.trim();
      if (formatted) {
        acc[item.label] = item;
      }
      return acc;
    }, {} satisfies Record<string, DropdownOption>) || {};
  const options: DropdownOption[] = Object.values(optionsObj);
  const {
    isOpen,
    toggleMenu,
    openMenu,
    reset,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    closeMenu,
  } = useCombobox({
    initialInputValue: formattedInitialInputValue,
    items: options || [],
    itemToString: (item?: DropdownOption | null) => (item != null ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (handleOptionSelect != null) {
        handleOptionSelect(selectedItem);
      }
    },
    onInputValueChange: ({ inputValue, selectedItem }) => {
      // prevent wasteful API call when input val has already been added. No need to fetch it again
      if (selectedItem?.value !== inputValue) {
        handleInputChange(inputValue);
      }
    },
  });
  useImperativeHandle(ref, () => ({
    reset() {
      reset();
    },
  }));
  const debouncedSearchText = useDebounce(inputValue, 500);
  const showDropDown = !!(isOpen && options);
  const showClearButton = !!inputValue;
  const searchText = debouncedSearchText; // Debounce so we don't show "Add custom entry to list" prompt
  // Strictly for search-select variant
  const isInputValueInOptions = options.some((o) => o.label === inputValue);

  return (
    <Box w='full' pos='relative'>
      {/* Input field */}
      <div>
        <InputGroup>
          <Input
            {...getInputProps({
              onKeyDown: (event) => {
                switch (event.key) {
                  case 'Enter': {
                    if (onAddCustomInputValueToList != null && searchText.trim() !== '') {
                      onAddCustomInputValueToList?.({ inputValue: searchText });
                      reset();
                      closeMenu();
                    }
                  }
                }
              },
            })}
            onClick={() => {
              handleInputChange('');
              openMenu();
            }}
            {...inputProps}
            paddingEnd={showClearButton ? '80px' : ''}
            onFocus={() => {
              if (openOnFocus && !isOpen) toggleMenu();
            }}
            isDisabled={isDisabled}
          />
          {/* Handle search-select variant. TODO: Breakout into another component */}
          {isSearchSelect ? (
            <InputRightElement>
              <Flex ml={showClearButton ? '-40px' : ''}>
                {showClearButton && (
                  <IconButton
                    aria-label='clear'
                    onClick={() => {
                      reset();
                    }}
                    variant='ghost'
                    _hover={{ bg: 'transparent' }}
                    icon={<Icon boxSize={6} color='gray.500' as={CloseIcon} />}
                  />
                )}
                <Divider orientation='vertical' h='40px' color='chakra-border-color' />
                <IconButton
                  aria-label='toggle'
                  onClick={toggleMenu}
                  variant='ghost'
                  _hover={{ bg: 'transparent' }}
                  icon={
                    <Icon
                      boxSize={6}
                      color='gray.500'
                      as={isOpen ? ChevronUpIcon : ChevronDownIcon}
                    />
                  }
                />
              </Flex>
            </InputRightElement>
          ) : (
            showClearButton && (
              <InputRightElement>
                <Box mr='lg'>
                  <Button
                    size='sm'
                    onClick={() => {
                      reset();
                    }}
                    aria-label='clear'
                    variant='ghost'>
                    Clear
                  </Button>
                </Box>
              </InputRightElement>
            )
          )}
          {!showClearButton && !!inputRightElement && (
            <InputRightElement pointerEvents='none'>{inputRightElement}</InputRightElement>
          )}
        </InputGroup>
      </div>
      {/* Dropdown  */}
      <List
        {...getMenuProps()}
        sx={
          showDropDown
            ? {
                pos: 'absolute',
                zIndex: 'dropdown',
                bg: 'white',
                shadow: 'md',
                rounded: 'md',
                p: 'md',
                mt: 'sm',
                w: 'full',
                maxH: '360px',
                overflow: 'auto',
                borderWidth: 1,
              }
            : {}
        }>
        {!showDropDown ? null : loading ? (
          <Spinner />
        ) : isSearchSelect &&
          !hideAddItem &&
          inputValue?.trim() &&
          !isInputValueInOptions &&
          // This check is to prevent "Add custom input" prompt from showing up AFTER use has already clicked "Add" once.
          // Otherwise, we will never see the drop down unless the input field is empty. (Check how MUI Autocomplete works)
          addedInput !== inputValue ? (
          <Button
            w='full'
            variant='outline'
            onClick={() => {
              setAddedInput(inputValue);
              closeMenu();
            }}>
            {/* Hopefully this works out for everyonesksk */}
            <Text noOfLines={1}>Add '{inputValue}'</Text>
          </Button>
        ) : isEmpty(options) ? (
          onAddCustomInputValueToList != null && searchText ? (
            <VStack>
              <Text textAlign='center' color='gray.900'>
                We couldn't find a match. Would you like to add '{searchText}' to the list?
              </Text>
              <Button
                w='full'
                onClick={() => {
                  onAddCustomInputValueToList({
                    inputValue: searchText,
                  });
                  reset();
                  closeMenu();
                }}>
                Add '{searchText}' To The List
              </Button>
            </VStack>
          ) : (
            <Text textAlign='center' color='gray.400'>
              No results
            </Text>
          )
        ) : (
          options?.map((option, index) => {
            return (
              <ListItem
                zIndex={10000}
                key={option.value}
                value={option.value}
                p='sm'
                rounded='md'
                _hover={{ cursor: 'pointer' }}
                {...getItemProps({ item: option, index })}
                sx={highlightedIndex === index ? { backgroundColor: 'gray.100' } : {}}>
                {option.label}
              </ListItem>
            );
          })
        )}
      </List>
    </Box>
  );
}
export const AsyncSelectField = forwardRef(_AsyncSelectField);
