const templateOrdersKey = (encounterId: number) => `${encounterId}-templateOrderSubmitted`;
const encounterLastPageKey = (encounterId: number) => `${encounterId}-lastPage`;

export const localStorageService = {
  getIsTemplateOrdersReviewed: (encounterId: number): boolean => {
    return JSON.parse(localStorage.getItem(templateOrdersKey(encounterId)) || 'false');
  },
  setIsTemplateOrdersReviewed: (encounterId: number, isReviewed: boolean): void => {
    localStorage.setItem(templateOrdersKey(encounterId), String(isReviewed));
  },
  getEncounterLastActivePage: (encounterId: number): OrderFlowSteps | null => {
    const lastPage = localStorage.getItem(encounterLastPageKey(encounterId));
    if (lastPage) {
      return lastPage as OrderFlowSteps;
    }
    return null;
  },
  setEncounterLastActivePage: (encounterId: number, page: OrderFlowSteps) => {
    localStorage.setItem(encounterLastPageKey(encounterId), String(page));
  },
};
