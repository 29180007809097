/* eslint-disable */
import { Box, Button, Checkbox, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { DatePicker } from '../../../components';
import { Notes } from '../EncounterScreen/components/Notes/Notes';
import { useEffect, useState } from 'react';
import { Facilities } from '../components/ComboBoxes';
import {
  useAddTelephoneEncounterMutation,
  type TelephoneEncounter,
  useGetPatientBasicDetailsQuery,
} from '../../../__generated__/graphql';
import { ReasonInput } from './components/ReasonInput';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import { useProviderEncounterDetails } from '../hooks/useProviderEncounterDetails';
import { ProviderAndStaffComboBox } from '../../../features';
import { isNil } from 'lodash';

interface Props {
  patientId?: number;
  refetch: () => void;
}

function BoxWithLabel({ label, children }: { label: string; children: React.ReactNode }) {
  return (
    <Box position='relative'>
      <Text
        bg='white'
        color='gray'
        zIndex={10}
        px={1}
        position='absolute'
        top={-3}
        left={3}
        fontSize='sm'>
        {label}
      </Text>
      {children}
    </Box>
  );
}

export function NewTEForm({ patientId, refetch }: Props) {
  const { user } = useAuth();
  const [addTE, { loading }] = useAddTelephoneEncounterMutation();
  const params = new URLSearchParams(window.location.search);
  const encounterId = params.get('encounterId');
  const patient = useGetPatientBasicDetailsQuery({
    variables: {
      patientID: patientId ?? 0,
    },
    skip: !patientId,
  });

  const patientName = `${patient?.data?.getPatientBasicDetails?.firstName} ${patient?.data?.getPatientBasicDetails?.lastName}`;

  const encDetails = useProviderEncounterDetails({
    encounterId: encounterId ?? undefined,
  });

  const items = encDetails?.items;
  const baseEncounter = encDetails?.baseEncounter;

  const [newTE, setNewTE] = useState<Partial<TelephoneEncounter>>({
    highPriority: false,
    encounterStartDateTime: `${new Date()}`,
    assignedToUserId: undefined!,
    providerId: undefined!,
    facilityId: undefined!,
    reason: '',
    message: '',
    actionTaken: '',
  });

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;
    if (!items?.length && !baseEncounter) return;

    const notes = items?.map((e) => e.notes?.trim()).filter(Boolean);

    const noteSet = new Set(notes);
    const updatedNotes = Array.from(noteSet).join('\n\n--------------------------------\n\n');

    const facilityId = baseEncounter?.facilityId;
    const providerId = baseEncounter?.providerId || Number(user?.ecwId);

    const newTeVals = {
      ...newTE,
      message: updatedNotes,
      // default to Test,Results per Josh
      assignedToUserId: 535997,
      facilityId,
      providerId,
      reason: 'Test Results',
    };

    setNewTE(newTeVals);
    setInit(true);
  }, [items, user, init, baseEncounter]);

  const { actionTaken, ...requiredFields } = newTE;
  const valid = Object.values(requiredFields).every((v) => !isNil(v) && v !== '');

  const nav = useNavigate();

  return (
    <Box>
      <Stack position='absolute' top={-12} right={0} spacing={5} direction='row'>
        <Checkbox
          colorScheme='brand'
          size='lg'
          isChecked={!!newTE.highPriority}
          onChange={(e) => {
            const highPriority = e.target.checked;
            setNewTE({
              ...newTE,
              highPriority,
            });
          }}
        />
        <Text fontSize='sm'>High Priority</Text>
      </Stack>
      <SimpleGrid key={JSON.stringify(newTE)} columns={4} gap={4}>
        <BoxWithLabel label='Date'>
          <DatePicker
            value={
              newTE.encounterStartDateTime ? new Date(newTE.encounterStartDateTime) : undefined
            }
            onChange={(encounterStartDateTime) => {
              setNewTE({
                ...newTE,
                encounterStartDateTime: encounterStartDateTime?.toISOString() ?? '',
              });
            }}
          />
        </BoxWithLabel>
        <BoxWithLabel label='Assigned To'>
          <ProviderAndStaffComboBox
            selectedId={newTE.assignedToUserId ? Number(newTE.assignedToUserId) : undefined}
            selectionCallback={(assignee) => {
              setNewTE({
                ...newTE,
                assignedToUserId: assignee ? parseInt(assignee?.value) : undefined,
              });
            }}
          />
        </BoxWithLabel>
        <BoxWithLabel label='Provider'>
          <ProviderAndStaffComboBox
            placeholder=''
            selectedId={newTE.providerId}
            selectionCallback={(providerId) => {
              setNewTE({
                ...newTE,
                providerId: providerId ? parseInt(providerId?.value) : undefined,
              });
            }}
          />
        </BoxWithLabel>
        <BoxWithLabel label='Facilities'>
          <Facilities
            placeholder=''
            selectedId={newTE.facilityId}
            selectionCallback={(facilityId) => {
              setNewTE({
                ...newTE,
                facilityId,
              });
            }}
          />
        </BoxWithLabel>
      </SimpleGrid>

      {/* set the key here or else it will not re-render when
      the 'initialval' is updated */}
      <Box key={`${init}`} marginTop='12px'>
        <ReasonInput
          initialValue={newTE.reason ?? ''}
          onChange={(reason) => {
            setNewTE({
              ...newTE,
              reason,
            });
          }}
        />
      </Box>

      <Box margin='12px 0'>
        <Notes
          popupDisclaimer='Visible to Patient'
          popupTitle={`Message - ${patientName}`}
          disclaimer='This is visible on the Patient Portal!'
          disclaimerStyling={{
            color: 'brand.500',
            fontWeight: 'bold',
          }}
          keynameCategory='TelEncounterMsg'
          placeholder='Message'
          maxRows={8}
          value={newTE.message ?? ''}
          onChange={(e) => {
            const message = e.target.value;
            setNewTE({
              ...newTE,
              message,
            });
          }}
          suggestionCallback={(message) => {
            setNewTE({
              ...newTE,
              message,
            });
          }}
        />
      </Box>

      <Box margin='12px 0'>
        <Notes
          popupDisclaimer=''
          popupTitle={`Action Taken - ${patientName}`}
          keynameCategory='TelEncounterNotes'
          placeholder='Action Taken'
          maxRows={8}
          value={newTE.actionTaken ?? ''}
          onChange={(e) => {
            const actionTaken = e.target.value;
            setNewTE({
              ...newTE,
              actionTaken,
            });
          }}
          suggestionCallback={(actionTaken) => {
            setNewTE({
              ...newTE,
              actionTaken,
            });
          }}
        />
      </Box>

      <Button
        isDisabled={!valid}
        margin='12px 0 24px 0'
        width='100%'
        isLoading={loading}
        onClick={() => {
          if (!valid) return;

          addTE({
            variables: {
              telephoneEncounter: {
                ...newTE,
                assignedToUserId: Number(newTE.assignedToUserId),
                providerId: Number(newTE.providerId),
                facilityId: Number(newTE.facilityId),
                encounterStartDateTime: new Date(newTE.encounterStartDateTime!).toISOString(),
                sourceEncounterId: 0,
                sourcePatientId: Number(patientId),
                enteredByUserId: Number(user?.ecwId),
                reviewed: false,
              },
            },
            onCompleted: (res) => {
              refetch();
              nav(`/providers`);
            },
          });
        }}>
        Create TE
      </Button>
    </Box>
  );
}
