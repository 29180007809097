import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Tag,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
} from '../..';
import { useModal } from '../../../hooks';
import { uiActions, useAppDispatch } from '../../../state-management';
import { useForm } from 'react-hook-form';
import { type g } from '../../../api';

interface GYNModalProps {
  symptomOptions: g.GynSymptomOption[];
  gynDataItem: UpsertGYNHistoryItem; // TODO: Gyn update input
}

interface FormValues extends UpsertGYNHistoryItem {}

export function EditGynSymptomNoteModal({ gynDataItem, symptomOptions }: GYNModalProps) {
  const dispatch = useAppDispatch();

  const { handleSubmit, register, getValues, setValue } = useForm<FormValues>({
    defaultValues: gynDataItem,
  });
  const { hideModal } = useModal();

  const onSubmit = handleSubmit(handleCompleteEdit);

  function handleCompleteEdit(values: FormValues) {
    if (values) {
      dispatch(uiActions.upsertGynDataItem(values));
      hideModal();
    }
  }

  const handleTagClick = (tagName: string) => {
    const note = getValues('symptomNotes');
    const updatedSymptomNotes = note ? `${note}, ${tagName}` : tagName;
    setValue('symptomNotes', updatedSymptomNotes);
  };
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing='lg' alignItems='flex-start'>
          <Textarea
            rows={6}
            placeholder='Notes'
            autoFocus
            {...register('symptomNotes', {
              //   maxLength: {
              //     value: INPUT_MAX_LEN,
              //     message: `The max length is ${INPUT_MAX_LEN}`,
              //   },
            })}
          />

          <VStack w='full' alignItems='flex-start'>
            <Text>{symptomOptions.length > 0 ? 'Suggestions' : 'No suggestions'}</Text>

            <Wrap spacing='sm'>
              {symptomOptions.map((x) => {
                const { gynHistoryDetailValue } = x ?? {};

                return (
                  <WrapItem key={gynHistoryDetailValue}>
                    <Tag
                      onClick={() => {
                        handleTagClick(gynHistoryDetailValue ?? '');
                      }}
                      variant='outline'
                      borderRadius='full'
                      colorScheme='brand'
                      sx={{ cursor: 'pointer' }}
                    >
                      {gynHistoryDetailValue}
                    </Tag>
                  </WrapItem>
                );
              })}
            </Wrap>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save Note</Button>
      </ModalFooter>
    </>
  );
}
