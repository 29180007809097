import { ChakraProvider } from '@chakra-ui/react';
import { Provider as ReduxProvider } from 'react-redux';
import { persistor, store } from './state-management';
// import { withLDProvider } from "launchdarkly-react-client-sdk";

import { ApolloProvider } from '@apollo/client';

// Routing
import { BrowserRouter } from 'react-router-dom';

// Configure Amplify
import './auth';
import { Authenticator } from '@aws-amplify/ui-react';

import { GlobalStylesToHideFocusRings } from './GlobalStylesToHideFocusRings';
import { theme } from './theme';
import { ModalRoot, Spinner } from './components';
import { AppRoutesWithSideBar } from './routes';
import { DevToolsAndAppVersion, FullStory, ScrollToTop } from './features';

import { PersistGate } from 'redux-persist/integration/react';
import { apolloClient } from './api/apollo-api';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { LaunchDarklyInit } from './LaunchDarklyInit';

function App() {
  return (
    <Authenticator.Provider>
      <FullStory />
      <LaunchDarklyInit />
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <BrowserRouter>
              <ScrollToTop />
              <ChakraProvider theme={theme}>
                <GlobalStylesToHideFocusRings />
                <AppRoutesWithSideBar />
                <ModalRoot />
                <DevToolsAndAppVersion />
              </ChakraProvider>
            </BrowserRouter>
          </PersistGate>
        </ReduxProvider>
      </ApolloProvider>
    </Authenticator.Provider>
  );
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID ?? '',
  options: {
    bootstrap: 'localStorage',
  },
})(App);
