import {
  Card,
  type CardVariant,
  Toggle,
  Flex,
  Box,
  Text,
  SelectDiagnosis,
  HStack,
  Badge,
} from '../../../../components';
import { SelectOrderableItem } from '../../../../features';
import {
  appointmentsActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../../state-management';
import { type AlertId, type DeclineReasonConfig } from '../../../../types';
import { isAcceptingEditedOrder, isRecommendationInjectionOrImmunization } from '../../../../utils';
import { DeclineOrderButton } from './DeclineOrderButton';
import { OrderDeclineReasonCode } from '../../../../__generated__/graphql';

// TODO: Type props
interface AlertCardProps {
  alertId: AlertId;
}

// This data would be from API call. Available without deploy
const declineReasonConfigs: DeclineReasonConfig[] = [
  {
    id: 'ALREADY_ORDERED',
    uiDeclineReasonCode: 'ALREADY_ORDERED',
    serverDeclineReasonCode: OrderDeclineReasonCode.AlreadyOrdered,
    name: 'Already Performed',
  },
  {
    id: 'PATIENT_DECLINE',
    uiDeclineReasonCode: 'PATIENT_DECLINE',
    serverDeclineReasonCode: OrderDeclineReasonCode.PatientDecision,
    name: 'Patient Declined',
  },
];

export function AddingOrderLineItemCard({ alertId }: AlertCardProps) {
  // const [cardMode, setCardMode] = React.useState<CardMode>(DEFAULT_CARD_MODE);
  const dispatch = useAppDispatch();
  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const alert = editedOrder.alert;

  const editedOrderStatus = editedOrder.status;
  const isAcceptingOrder = isAcceptingEditedOrder(editedOrder);
  let cardVariant: CardVariant = 'reject';
  let renderContent;

  if (isAcceptingOrder) {
    cardVariant = 'accept';
  }

  const handleToggleOrderType = () => {
    dispatch(appointmentsActions.toggleOrderType({ alertId }));
  };

  if (isAcceptingOrder) {
    renderContent = (
      <HStack spacing='md'>
        <SelectOrderableItem alertId={alertId} />
        <SelectDiagnosis alertId={alertId} />
      </HStack>
    );
  }

  if (editedOrderStatus === 'NO_ADDING_DECLINE_REASON') {
    renderContent = (
      <Box>
        <Box py={2} border='md' borderColor='secondary' borderRadius='sm'>
          <Text variant='body2-b'>Reason for not ordering</Text>
        </Box>

        <Flex mt='3' flexWrap='wrap'>
          {declineReasonConfigs.map((declineReasonConfig) => {
            const config = { ...declineReasonConfig };
            if (
              isRecommendationInjectionOrImmunization(editedOrder) &&
              config.uiDeclineReasonCode === 'PATIENT_DECLINE'
            ) {
              config.serverDeclineReasonCode = OrderDeclineReasonCode.PatientDecision;
            }

            return (
              <Box mr='2' mb='2' key={config.id}>
                <DeclineOrderButton declineReasonConfig={config} alertId={alertId} />
              </Box>
            );
          })}
        </Flex>
      </Box>
    );
  }

  return (
    <Card variant={cardVariant}>
      <Flex justifyContent='space-between' width='full' mb='md'>
        <Box>
          <Text variant='h6-b'>{alert?.shortDescription}</Text>
        </Box>
        <Box>
          <Box ml='auto'>
            {alert.required ? (
              <Badge colorScheme='orange' rounded='full' px='sm'>
                Required
              </Badge>
            ) : (
              <Toggle isChecked={isAcceptingOrder} onPress={handleToggleOrderType} />
            )}
          </Box>
        </Box>
      </Flex>
      <Box>{renderContent}</Box>
    </Card>
  );
}
