import { Box, Text, WaitUntilLoaded } from '../../../components';
import { Heading } from '@chakra-ui/react';
import { type HPICategoryDataItem } from '../../../types';
import { useGetHpiCategoryDetailsQuery } from '../../../__generated__/graphql';
import _ from 'lodash';
import { useMemo } from 'react';
import { useMAWorkflow } from '../../../hooks';
import { symptomSortLogic } from './utils/symptomSortLogic';

export const HPIExistingEncounterSymptomsPanel = ({
  dataItems,
  clickHandler,
}: {
  dataItems?: HPICategoryDataItem[] | null;
  clickHandler?: (encounterCategory: HPICategoryDataItem, symptomId?: number) => void;
}) => {
  const {
    hpi: { selectedCategoryId },
  } = useMAWorkflow();
  return (
    <Box>
      {dataItems
        ?.filter((cat) => !!cat?.name)
        .map((item) => {
          return (
            <Category
              key={item.hpiCategoryId}
              isActive={item.hpiCategoryId === selectedCategoryId}
              clickHandler={clickHandler}
              categoryItem={item}
            />
          );
        })}
    </Box>
  );
};

const Category = ({
  isActive,
  clickHandler,
  categoryItem,
}: {
  isActive?: boolean;
  clickHandler?: (encounterCategory: HPICategoryDataItem, symptomId?: number) => void;
  categoryItem: HPICategoryDataItem;
}) => {
  const { data: hpiCategoryDetailsData, loading: isLoading } = useGetHpiCategoryDetailsQuery({
    variables: { categoryId: categoryItem.hpiCategoryId || -1 },
    skip: _.isNil(categoryItem.hpiCategoryId),
  });
  const hpiCategoryDetails = hpiCategoryDetailsData?.getHPICategoryDetails;
  const symptomIdToNameMapping = useMemo(() => {
    return hpiCategoryDetails?.hpiSymptoms?.reduce<Record<number, string>>((accum, symptom) => {
      if (symptom.hpiSymptomID) {
        accum[symptom.hpiSymptomID] = symptom.name || '';
      }
      return accum;
    }, {});
  }, [categoryItem, hpiCategoryDetails]);
  const note = categoryItem.hpiCategoryNotesHeader;
  return (
    <WaitUntilLoaded loading={isLoading}>
      <Box mb='md'>
        <Heading
          cursor='pointer'
          fontSize='lg'
          textDecoration='underline'
          color={isActive ? 'brand.500' : 'inherit'}
          onClick={() => {
            clickHandler?.(categoryItem);
          }}>
          {categoryItem.name}
        </Heading>
        {note && (
          <Box>
            <Text fontSize='.9rem'>Note: {note}</Text>
          </Box>
        )}
        <Box>
          {[...(categoryItem.encounterHPIDataItems || [])]?.sort(symptomSortLogic).map((item) => {
            const complains = item.complainsDenies;
            let note = '';
            if (item.duration) {
              note += item.duration;
            }
            if (item.symptomNotes) {
              note += ' ' + item.symptomNotes;
            }
            return (
              <Text
                key={item.hpiSymptomId}
                as='span'
                noOfLines={1}
                onClick={() => {
                  clickHandler?.(categoryItem, item.hpiSymptomId || undefined);
                }}
                cursor='pointer'>
                {complains === null ? '' : complains ? 'C:' : 'D:'}{' '}
                <Text as='span' color='custom.green'>
                  {symptomIdToNameMapping?.[item.hpiSymptomId || -1]}
                  {note && ': '}
                </Text>
                <Text as='span' fontSize='.9rem'>
                  {note}
                </Text>
              </Text>
            );
          })}
        </Box>
      </Box>
    </WaitUntilLoaded>
  );
};
