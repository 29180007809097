export const immunizationInjectionDetails = {
  administrationRoutes: [
    {
      id: 1,
      routeCode: 'ID ',
      routeDescription: 'Intradermal',
    },
    {
      id: 2,
      routeCode: 'IM ',
      routeDescription: 'Intramuscular',
    },
    {
      id: 3,
      routeCode: 'NS ',
      routeDescription: 'Nasal',
    },
    {
      id: 4,
      routeCode: 'IV ',
      routeDescription: 'Intravenous',
    },
    {
      id: 5,
      routeCode: 'PO ',
      routeDescription: 'Oral',
    },
    {
      id: 6,
      routeCode: 'OTH',
      routeDescription: 'Other/Miscellaneous',
    },
    {
      id: 7,
      routeCode: 'SC ',
      routeDescription: 'Subcutaneous',
    },
    {
      id: 8,
      routeCode: 'TD ',
      routeDescription: 'Transdermal',
    },
  ],
  injectionSites: [
    {
      code: 'LA',
      location: 'Left Arm',
    },
    {
      code: 'LD',
      location: 'Left Deltoid',
    },
    {
      code: 'LG',
      location: 'Left Gluteus Medius',
    },
    {
      code: 'LLFA',
      location: 'Left Lower Forearm',
    },
    {
      code: 'LT',
      location: 'Left Thigh',
    },
    {
      code: 'LVL',
      location: 'Left Vastus Lateralis',
    },
    {
      code: 'RA',
      location: 'Right Arm',
    },
    {
      code: 'RD',
      location: 'Right Deltoid',
    },
    {
      code: 'RG',
      location: 'Right Gluteus Medius',
    },
    {
      code: 'RLFA',
      location: 'Right Lower Forearm',
    },
    {
      code: 'RT',
      location: 'Right Thigh',
    },
    {
      code: 'RVL',
      location: 'Right Vastus Lateralis',
    },
  ],
};
