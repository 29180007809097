import { VStack, Box, WaitUntilLoaded, Page } from '../../../components';
import { useGetEncounterOrders } from './useGetEncounterOrders';
import { TreatmentPlanOrderCard } from './TreatmentPlanOrderCard';
import { TreatmentPlanImmunizationOrderCard } from './TreatmentPlanImmunizationOrderCard';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import { WorklistButton } from '../../components/SaveAndChecklistButtons/WorklistButton';
import React from 'react';
import { CircleNumber } from './CircleNumber';
import { isEmpty } from 'lodash';

const ACCORDION_INDEX = {
  LAB: 0,
  IMAGING: 1,
  PROCEDURE: 2,
  IMMUNIZATION: 3,
};

export enum OrderableItemTypeId {
  Lab = 0,
  Imaging = 1,
  Procedure = 3,
}

function TreatmentPlanAccordionItem({
  content,
  title,
}: {
  title: React.ReactNode;
  content: React.ReactNode;
}) {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <HStack>
            <AccordionIcon color={'orange.500'} boxSize={8} />
            <Text fontWeight={'bold'}>{title}</Text>
          </HStack>
        </AccordionButton>
      </h2>
      <AccordionPanel>
        <VStack spacing='md' alignItems='stretch'>
          {content}
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

function TreatmentPlanAccordionTitle({
  text,
  numberOfOrders,
}: {
  text: string;
  numberOfOrders: number;
}) {
  return (
    <HStack>
      <Text fontWeight={'bold'}>{text}</Text>
      {numberOfOrders ? <CircleNumber value={numberOfOrders} /> : null}
    </HStack>
  );
}

function ExistingOrders() {
  const { regularOrders, immunizationOrders, loading, numberOfOrders } = useGetEncounterOrders();
  const renderNoItems = <Text textAlign={'center'}>No items.</Text>;
  const labOrders = regularOrders?.filter(
    (order) => order?.orderableItemTypeId === OrderableItemTypeId.Lab,
  );
  const imagingOrders = regularOrders?.filter(
    (order) => order?.orderableItemTypeId === OrderableItemTypeId.Imaging,
  );
  const procedureOrders = regularOrders?.filter(
    (order) => order?.orderableItemTypeId === OrderableItemTypeId.Procedure,
  );

  const defaultOpenIndexes = [];
  if (!isEmpty(labOrders)) {
    defaultOpenIndexes.push(ACCORDION_INDEX.LAB);
  }
  if (!isEmpty(imagingOrders)) {
    defaultOpenIndexes.push(ACCORDION_INDEX.IMAGING);
  }
  if (!isEmpty(procedureOrders)) {
    defaultOpenIndexes.push(ACCORDION_INDEX.PROCEDURE);
  }
  if (!isEmpty(immunizationOrders)) {
    defaultOpenIndexes.push(ACCORDION_INDEX.IMMUNIZATION);
  }

  return (
    <WaitUntilLoaded loading={loading}>
      {numberOfOrders === 0 ? (
        <Box w='full'>
          <Text textAlign='center'>No items.</Text>
        </Box>
      ) : (
        <Accordion allowMultiple w='full' defaultIndex={defaultOpenIndexes}>
          <TreatmentPlanAccordionItem
            title={
              <TreatmentPlanAccordionTitle text='Labs' numberOfOrders={labOrders?.length ?? 0} />
            }
            content={
              isEmpty(labOrders)
                ? renderNoItems
                : labOrders.map((order) => {
                    return <TreatmentPlanOrderCard key={order?.orderableReportID} order={order} />;
                  })
            }
          />
          <TreatmentPlanAccordionItem
            title={
              <TreatmentPlanAccordionTitle
                text='Imaging'
                numberOfOrders={imagingOrders?.length ?? 0}
              />
            }
            content={
              isEmpty(imagingOrders)
                ? renderNoItems
                : imagingOrders.map((order) => {
                    return <TreatmentPlanOrderCard key={order?.orderableReportID} order={order} />;
                  })
            }
          />

          <TreatmentPlanAccordionItem
            title={
              <TreatmentPlanAccordionTitle
                text='Procedures'
                numberOfOrders={procedureOrders?.length ?? 0}
              />
            }
            content={
              isEmpty(procedureOrders)
                ? renderNoItems
                : procedureOrders.map((order) => {
                    return <TreatmentPlanOrderCard key={order?.orderableReportID} order={order} />;
                  })
            }
          />

          <TreatmentPlanAccordionItem
            title={
              <TreatmentPlanAccordionTitle
                text='Immunizations / T. Injections'
                numberOfOrders={immunizationOrders?.length ?? 0}
              />
            }
            content={
              isEmpty(immunizationOrders)
                ? renderNoItems
                : immunizationOrders?.map((order) => {
                    return (
                      <TreatmentPlanImmunizationOrderCard
                        key={order?.immunizationId}
                        order={order}
                      />
                    );
                  })
            }
          />
        </Accordion>
      )}
    </WaitUntilLoaded>
  );
}

export function TreatmentPlan() {
  return (
    <Page>
      <VStack spacing='lg' alignItems='flex-start'>
        <Box w='full' overflow='auto'>
          <VStack spacing='lg' w='full'>
            <ExistingOrders />
            <HStack position='fixed' bottom='8px' backgroundColor='white' borderRadius='8px'>
              <WorklistButton />
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Page>
  );
}
