import { selectors, useAppSelector } from '../state-management';
import { type Gender, useGetPatientQuery } from '../__generated__/graphql';
import { NetworkStatus } from '@apollo/client';

export function usePatient() {
  const activePatientID = useAppSelector(selectors.getActivePatientID);

  const { data, networkStatus, ...rest } = useGetPatientQuery({
    variables: { patientID: activePatientID || 0 },
    skip: !activePatientID,
    notifyOnNetworkStatusChange: true,
  });

  // temporary doing `as Gender` for now
  // TODO: Update backend to define an enum type for gender so it's consistent from BE to FE.
  const patient = { ...data?.getPatient, gender: data?.getPatient?.gender as Gender };

  return {
    patient,
    refetching: NetworkStatus.refetch === networkStatus,
    patientId: activePatientID || 0,
    ...rest,
  };
}
