import { ComboBox } from '../../../../components';
import { useDebounce } from '../../../../hooks';
import {
  getPatientSearchState,
  useAppDispatch,
  patientSearchActions,
  useAppSelector,
} from '../../../../state-management';
import { useFindPatientsQuery } from '../../../../__generated__/graphql';

export const PatientSearch = () => {
  const dispatch = useAppDispatch();
  const searchState = useAppSelector(getPatientSearchState);
  const debouncedQuery = useDebounce(searchState.searchQuery);

  const res = useFindPatientsQuery({
    variables: {
      contains: debouncedQuery,
    },
  });

  const { data, loading } = res;
  const rawPatients = data?.findPatients?.data || [];
  const patients = rawPatients.map((p) => ({
    label: `${p.firstName} ${p.lastName}`,
    value: `${p.sourcePatientId}`,
  }));

  return (
    <div key={JSON.stringify(searchState.selectedIds)}>
      <ComboBox
        options={patients}
        onSelection={(opts) => {
          const updateIds = patientSearchActions.setPatients(opts ? [opts] : []);
          dispatch(updateIds);
        }}
        onInputChange={(query) => {
          const updateQuery = patientSearchActions.setSearch(query);
          dispatch(updateQuery);
        }}
        key={JSON.stringify(searchState.selectedIds)}
        placeholder='Patient name, ID #, or date of birth (YYYY-MM-DD)'
        defaultValue={searchState.selectedIds?.[0]}
        isLoading={loading}
        filterOption={() => true}
      />
    </div>
  );
};
