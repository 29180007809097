import { Icon } from '@chakra-ui/react';
import { type IconProps } from '@chakra-ui/icon';

export const EditIcon = (props: Omit<IconProps, 'orientation'>) => (
  <Icon viewBox='0 0 30 30' {...props}>
    <path
      fill='currentColor'
      d='M22.1875 21.3281H7.8125C7.4668 21.3281 7.1875 21.6074 7.1875 21.9531V22.6562C7.1875 22.7422 7.25781 22.8125 7.34375 22.8125H22.6562C22.7422 22.8125 22.8125 22.7422 22.8125 22.6562V21.9531C22.8125 21.6074 22.5332 21.3281 22.1875 21.3281ZM10.0332 19.6875C10.0723 19.6875 10.1113 19.6836 10.1504 19.6777L13.4355 19.1016C13.4746 19.0938 13.5117 19.0762 13.5391 19.0469L21.8184 10.7676C21.8365 10.7495 21.8508 10.728 21.8606 10.7044C21.8704 10.6808 21.8755 10.6555 21.8755 10.6299C21.8755 10.6043 21.8704 10.579 21.8606 10.5553C21.8508 10.5317 21.8365 10.5103 21.8184 10.4922L18.5723 7.24414C18.5352 7.20703 18.4863 7.1875 18.4336 7.1875C18.3809 7.1875 18.332 7.20703 18.2949 7.24414L10.0156 15.5234C9.98633 15.5527 9.96875 15.5879 9.96094 15.627L9.38477 18.9121C9.36577 19.0167 9.37255 19.1244 9.40454 19.2258C9.43654 19.3273 9.49276 19.4193 9.56836 19.4941C9.69727 19.6191 9.85938 19.6875 10.0332 19.6875Z'
    />
  </Icon>
);
