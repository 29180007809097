import React from 'react';
import { Icon } from '../../general';
import { HStack } from '../../layout';
import { Text } from '../../typography';
import { BsFillExclamationTriangleFill as ExclamationIcon } from 'react-icons/bs';

export function ErrorMessage({ text }: { text: string }) {
  if (!text) {
    return null;
  }

  return (
    <HStack spacing='sm' justifyContent='center'>
      <Icon as={ExclamationIcon} color='red' />
      <Text variant='body2'>{text}</Text>
    </HStack>
  );
}
