import {
  Box,
  Button,
  Checkbox,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DatePicker, Flex } from '../../../../components';
import { Notes } from '../../EncounterScreen/components/Notes/Notes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateTelephoneEncounterMutation,
  useGetTelephoneEncounterBySourceEncounterIdQuery,
  type TelephoneEncounter,
  useGetPatientBasicDetailsQuery,
} from '../../../../__generated__/graphql';
import { formatDate, formatTime } from '../../../../utils';
import { Facilities } from '../../components/ComboBoxes';
import { ReasonInput } from './ReasonInput';
import { ProviderAndStaffComboBox } from '../../../../features';

interface Props {
  onClose: () => void;
  onSave: () => void;
  selectedTEId?: string;
}

export function EditPanel({ onClose, onSave, selectedTEId }: Props) {
  const [update, { loading }] = useUpdateTelephoneEncounterMutation();
  const { data, loading: selectedTELoading } = useGetTelephoneEncounterBySourceEncounterIdQuery({
    skip: !selectedTEId,
    variables: { sourceEncounterId: Number(selectedTEId) },
  });

  const { data: patientData } = useGetPatientBasicDetailsQuery({
    variables: { patientID: data?.getTelephoneEncounterBySourceEncounterId?.sourcePatientId ?? 0 },
  });

  const [telephoneEncounter, setTelephoneEncounter] = useState<TelephoneEncounter>();
  const patientName = `${patientData?.getPatientBasicDetails?.firstName} ${patientData?.getPatientBasicDetails?.lastName}`;

  const nav = useNavigate();

  useEffect(() => {
    if (!selectedTELoading && data) {
      setTelephoneEncounter(data.getTelephoneEncounterBySourceEncounterId!);
    }
  }, [selectedTELoading, data]);

  return (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          style={{
            padding: 0,
          }}>
          <Box height='100%' width='100%'>
            <Flex>
              <Box minHeight='100vh' overflowY='auto' width='20%' backgroundColor='#ED4924' />

              {telephoneEncounter && (
                <Box width='80%' marginTop='50px'>
                  <Flex justifyContent='center'>
                    <Box width='95%'>
                      <Flex justifyContent='space-between' alignItems='flex-start'>
                        <Flex flex={1}>
                          <Text fontSize='xl' as='b'>
                            Open TE - {formatDate(telephoneEncounter?.encounterStartDateTime || '')}{' '}
                            {formatTime(telephoneEncounter?.encounterStartDateTime || '')} -{' '}
                            {patientData?.getPatientBasicDetails?.firstName}{' '}
                            {patientData?.getPatientBasicDetails?.lastName}
                          </Text>
                          <Text color='white'>
                            SourceEncounterId: {telephoneEncounter.sourceEncounterId}
                          </Text>
                        </Flex>

                        <Flex alignItems='center'>
                          <Stack spacing={2} direction='row' marginLeft='16px'>
                            <Text fontSize='xs'>High Priority</Text>

                            <Checkbox
                              colorScheme='brand'
                              size='lg'
                              isChecked={!!telephoneEncounter.highPriority}
                              onChange={(e) => {
                                const highPriority = e.target.checked;
                                setTelephoneEncounter({
                                  ...telephoneEncounter,
                                  highPriority,
                                });
                              }}
                            />
                          </Stack>
                        </Flex>
                      </Flex>

                      <SimpleGrid columns={4} gap={4} marginTop='12px'>
                        <DatePicker
                          value={
                            telephoneEncounter.encounterStartDateTime
                              ? new Date(telephoneEncounter.encounterStartDateTime)
                              : undefined
                          }
                          onChange={(encounterStartDateTime) => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              encounterStartDateTime: encounterStartDateTime?.toUTCString() ?? '',
                            });
                          }}
                          placeholder='Date'
                        />
                        <ProviderAndStaffComboBox
                          selectedId={telephoneEncounter.assignedToUserId || undefined}
                          selectionCallback={(assignee) => {
                            if (!assignee?.value) return;
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              assignedToUserId: parseInt(assignee.value),
                              assignedToUserName: assignee?.label,
                            });
                          }}
                          placeholder='Assigned To'
                        />
                        <ProviderAndStaffComboBox
                          placeholder='Provider'
                          selectionCallback={(providerId) => {
                            if (!providerId?.value) return;
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              providerId: Number(providerId.value),
                            });
                          }}
                          selectedId={telephoneEncounter.providerId}
                        />
                        <Facilities
                          selectionCallback={(facilityId) => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              facilityId,
                            });
                          }}
                          selectedId={telephoneEncounter.facilityId}
                        />
                      </SimpleGrid>

                      <Box marginTop='12px'>
                        <ReasonInput
                          initialValue={telephoneEncounter.reason ?? ''}
                          onChange={(reason) => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              reason,
                            });
                          }}
                        />
                      </Box>

                      <Box margin='12px 0'>
                        <Notes
                          popupTitle={`Message - ${patientName}`}
                          popupDisclaimer='Visible to Patient'
                          keynameCategory='TelEncounterMsg'
                          placeholder='Message'
                          value={telephoneEncounter.message ?? ''}
                          disclaimer='This is Visible on the Patient Portal!'
                          disclaimerStyling={{
                            color: 'brand.500',
                          }}
                          onChange={(e) => {
                            const message = e.target.value;
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              message,
                            });
                          }}
                          suggestionCallback={(message) => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              message,
                            });
                          }}
                        />
                      </Box>

                      <Box marginBottom='12px'>
                        <Notes
                          popupDisclaimer=''
                          popupTitle={`Action Taken - ${patientName}`}
                          keynameCategory='TelEncounterNotes'
                          placeholder='Action Taken'
                          value={telephoneEncounter.actionTaken ?? ''}
                          onChange={(e) => {
                            const actionTaken = e.target.value;
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              actionTaken,
                            });
                          }}
                          suggestionCallback={(actionTaken) => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              actionTaken,
                            });
                          }}
                        />
                      </Box>

                      <Flex
                        width='100%'
                        border='1px solid #7E90CF'
                        borderRadius='3px'
                        marginTop='12px'>
                        <Flex
                          width='50%'
                          justifyContent='center'
                          alignItems='center'
                          padding='8px 0'
                          backgroundColor={!telephoneEncounter.reviewed ? '#7E90CF' : 'white'}
                          borderRight='1px solid #7E90CF'
                          cursor='pointer'
                          transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                          onClick={() => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              reviewed: false,
                            });
                          }}>
                          <Text
                            fontSize='md'
                            textColor={telephoneEncounter.reviewed ? '#7E90CF' : 'white'}>
                            Open
                          </Text>
                        </Flex>

                        <Flex
                          width='50%'
                          justifyContent='center'
                          alignItems='center'
                          padding='8px 0'
                          cursor='pointer'
                          backgroundColor={telephoneEncounter.reviewed ? '#7E90CF' : 'white'}
                          transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                          onClick={() => {
                            setTelephoneEncounter({
                              ...telephoneEncounter,
                              reviewed: true,
                            });
                          }}>
                          <Text
                            fontSize='md'
                            textColor={!telephoneEncounter.reviewed ? '#7E90CF' : 'white'}>
                            Addressed
                          </Text>
                        </Flex>
                      </Flex>

                      <Button
                        margin='12px 0 0 0'
                        width='100%'
                        variant='outline'
                        onClick={() => {
                          nav(`/providers/${telephoneEncounter.sourceEncounterId}/open/labs`);
                        }}>
                        Order Labs/DI
                      </Button>

                      <Box
                        // Extra space for button
                        paddingBottom='48px'
                      />

                      <Flex
                        justifyContent='space-between'
                        position='absolute'
                        bottom='0'
                        marginBottom='12px'
                        width='76%'>
                        <Button
                          width='45%'
                          backgroundColor='#7E90CF'
                          _hover={{ backgroundColor: '#4C5E9B' }}
                          onClick={onClose}>
                          Cancel
                        </Button>

                        <Button
                          isLoading={loading}
                          width='45%'
                          onClick={() => {
                            update({
                              variables: {
                                telephoneEncounter: {
                                  sourceEncounterId: Number(telephoneEncounter.sourceEncounterId),
                                  sourcePatientId: Number(telephoneEncounter.sourcePatientId),
                                  providerId: Number(telephoneEncounter.providerId),
                                  encounterStartDateTime: telephoneEncounter.encounterStartDateTime,
                                  reason: telephoneEncounter.reason,
                                  message: telephoneEncounter.message,
                                  facilityId: Number(telephoneEncounter.facilityId),
                                  enteredByUserId: Number(telephoneEncounter.enteredByUserId),
                                  ticketId: Number(telephoneEncounter.ticketId),
                                  answeredByUserId: Number(telephoneEncounter.answeredByUserId),
                                  caller: telephoneEncounter.caller,
                                  assignedToUserId: Number(telephoneEncounter.assignedToUserId),
                                  actionTaken: telephoneEncounter.actionTaken,
                                  highPriority: !!telephoneEncounter.highPriority,
                                  reviewed: telephoneEncounter.reviewed,
                                },
                              },
                              onCompleted() {
                                onSave();
                                onClose();
                              },
                            });
                          }}>
                          Save TE
                        </Button>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </>
  );
}
