import { Select } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

const options = [
  { id: 'mL', value: 'milliliters' },
  { id: 'cL', value: 'centiliters' },
  { id: 'mg', value: 'milligrams' },
  { id: 'spray', value: 'spray' },
  { id: 'grm', value: 'grams' },
  { id: 'cc', value: 'cubic centimeters' },
  { id: 'ug', value: 'microgram' },
  { id: 'uL', value: 'microliter' },
  { id: 'ng', value: 'nanogram' },
  { id: 'U', value: 'units' },
  { id: 'mcg', value: 'microgram' },
  { id: 'g', value: 'grams' },
  { id: 'mCi', value: 'millicuries' },
];

export function CollectionUnitSelect() {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  return (
    <Select
      placeholder='Unit'
      value={globalLabForm.collectionUnit ?? undefined}
      onChange={(e) => {
        handleUpdateGlobalLabForm({ collectionUnit: e.target.value });
      }}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.value}
        </option>
      ))}
    </Select>
  );
}
