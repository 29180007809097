import {
  Box,
  DrawerBody,
  DrawerContent,
  GridItem,
  Input,
  SimpleGrid,
  Text,
} from '../../../../../components';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useGetPatientBasicDetailsQuery,
  type GetProviderEncounterDetailsQuery,
  useGetPatientDocumentsByOrderableItemQuery,
  useGetSignedDocumentUrlQuery,
} from '../../../../../__generated__/graphql';
import { Card, DrawerCloseButton, Stack, Tooltip } from '@chakra-ui/react';

type Item = NonNullable<
  NonNullable<GetProviderEncounterDetailsQuery['getProviderEncounterDetails']>['items'][0]
>;
interface Props {
  orderableItem: Item;
}

export const DocumentsPanel = ({ orderableItem }: Props) => {
  return (
    <DrawerContent height='100%'>
      <DrawerBody height='100%' display='flex' padding='0'>
        <DrawerCloseButton />

        <Box height='100%' width='300px' bg='primary' />

        <Box p={8} height='100%' overflow='auto' width='100%'>
          <Documents orderableItem={orderableItem} />
        </Box>
      </DrawerBody>
    </DrawerContent>
  );
};

export const Documents = ({ orderableItem }: Props) => {
  const [activeDoc, setActiveDoc] = useState<Doc | undefined>();
  const { data } = useGetPatientBasicDetailsQuery({
    variables: { patientID: orderableItem.sourcePatientId ?? 0 },
  });

  const oid = orderableItem.orderableReportID ? Number(orderableItem.orderableReportID) : 0;

  const docUrl = useGetSignedDocumentUrlQuery({
    variables: {
      documentId: activeDoc?.documentUUID ?? '',
    },
    skip: !activeDoc,
  });

  const getDocs = useGetPatientDocumentsByOrderableItemQuery({
    variables: {
      orderableItemId: oid,
    },
    skip: false,
  });

  type Doc = NonNullable<
    NonNullable<NonNullable<(typeof getDocs)['data']>['getPatientDocumentsByOrderableItem']>[0]
  >;

  const patient = data?.getPatientBasicDetails;
  const name = `${patient?.firstName} ${patient?.lastName}`;

  const docs = useMemo(() => {
    return getDocs?.data?.getPatientDocumentsByOrderableItem ?? [];
  }, [getDocs.data?.getPatientDocumentsByOrderableItem?.length]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!activeDoc && docs?.[0]) {
      setActiveDoc(docs?.[0]);
    }
  }, [docs?.length]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box height='100%' overflow='auto' width='100%'>
      <Box>
        <Text fontSize='xl' fontWeight='bold'>
          {orderableItem.orderableItemName}
          {patient && ` - ${name}`}
        </Text>
      </Box>
      <SimpleGrid py={6} height='95%' columns={3}>
        <GridItem px={4} colSpan={1}>
          <Stack spacing={4}>
            <Input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder='Search'
            />
            <Text fontSize='xl' fontWeight='bold'>
              Document List
            </Text>
            {docs
              .filter((doc) => doc?.documentName?.toLowerCase()?.includes(search))
              .map((doc) => (
                <Tooltip key={doc?.documentUUID} shouldWrapChildren label={doc?.documentName}>
                  <Card
                    cursor='pointer'
                    onClick={() => {
                      if (doc?.fileName) {
                        setActiveDoc(doc);
                      }
                    }}
                    p={3}>
                    <Text noOfLines={1}>{doc?.documentName}</Text>
                    <SimpleGrid columns={2}>
                      <GridItem>{doc?.scannedByUserName}</GridItem>
                      <GridItem>{new Date(doc?.scannedDate ?? '')?.toLocaleDateString()}</GridItem>
                    </SimpleGrid>
                  </Card>
                </Tooltip>
              ))}
          </Stack>
        </GridItem>
        <GridItem height='100%' p={3} pt={0} colSpan={2}>
          <Box
            ref={ref}
            bg='gray.100'
            w='full'
            overflow='auto'
            height={document.body.scrollHeight - 130}
            borderRadius={12}>
            <iframe
              style={{ height: '100%', width: '100%' }}
              src={docUrl.data?.getDocumentSignedUrl ?? ''}
            />
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};
