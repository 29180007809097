import { extendTheme } from '@chakra-ui/react';
import { FloatingLabels } from './chakra-overrides/FloatingLabels';
import { Text, Button, InputRightElement, Checkbox } from './chakra-overrides';

const chakraOranges = {
  50: '#FFFAF0',
  100: '#FEEBC8',
  200: '#FBD38D',
  300: '#F6AD55',
  400: '#ED8936',
  500: '#DD6B20',
  600: '#C05621',
  700: '#9C4221',
  800: '#7B341E',
  900: '#652B19',
};

const chakraBlues = {
  50: '#EBF8FF',
  100: '#BEE3F8',
  200: '#90CDF4',
  300: '#63B3ED',
  400: '#4299E1',
  500: '#3182CE',
  600: '#2B6CB0',
  700: '#2C5282',
  800: '#2A4365',
  900: '#1A365D',
};

export const theme = extendTheme({
  fonts: {
    body: 'Poppins',
    heading: 'Poppins',
  },
  components: {
    Text,
    Button,
    ...FloatingLabels,
    InputRightElement,
    Checkbox,
    Switch: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
  },
  colors: {
    // Used chakra colors https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/colors.ts
    brand: {
      ...chakraOranges,
      100: '#FFF0ED', // ex. menu hover background
      150: '#FF866F',
      200: '#FF9900',
      400: '#F26628',
      500: '#ED4924', // ex. button surface
      600: '#B8371A', // ex. button hover / tab surface
    },
    blue: {
      ...chakraBlues,
      500: '#4299E1',
    },
    custom: {
      green: '#38A194',
    },
    peach: '#FEF2F0',
    black: '#000',
    white: '#fff',
    primary: '#ED4924', // orange
    // primary: "#46A147", // green
    secondaries: ['rgba(126, 144, 207, 0.06)', 'rgba(126, 144, 207, 0.5)', '#7E90CF'],
    secondary: '#7E90CF',
    border: '#E2E8F0',
    muted: '#f6f6f6',
    warning: '#FFB800',
  },
  space: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '32px',
    xl: '64px',
  },
  zIndex: {
    pageHeader: 100,
  },
  shadows: {
    primary: '3px 5px 5px rgba(237, 73, 36, .50)',
    inputShadow: '0 0 0 3px rgba(237, 73, 36, .50)',
  },
});
