import { useGetKeywordsQuery } from '../../../../__generated__/graphql';
import { AutocompleteInput } from '../../../../components';

interface Props {
  selectionCallback: (providerId: any) => void;
}

export function LabReasons({ selectionCallback }: Props) {
  const { data } = useGetKeywordsQuery({
    variables: {
      keynameCategory: 'LabReasons',
    },
  });

  const options = data?.getKeywords?.keywords?.map((keyword) => keyword.name);

  return (
    <AutocompleteInput
      label='Reason'
      options={options ?? []}
      initialValue={''}
      onChange={(reason: string) => {
        const details = data?.getKeywords?.keywords
          ?.find((val) => val.name === reason)
          ?.details?.trim();
        selectionCallback(details || reason?.trim());
      }}
    />
  );
}
