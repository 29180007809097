import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ComboBoxOption } from '../types';

export interface OpenTEsFilterState {
  date: number | null;
  callers: ComboBoxOption[];
  assignees: ComboBoxOption[];
  facilities: ComboBoxOption[];
}

const initialState: OpenTEsFilterState = {
  date: null,
  callers: [],
  assignees: [],
  facilities: [],
};

export const openTEsFilterSlice = createSlice({
  name: 'openTEsFilter',
  initialState,
  reducers: {
    onFilterStateChange(state, action: PayloadAction<Partial<OpenTEsFilterState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const openTEsFilterActions = openTEsFilterSlice.actions;

export default openTEsFilterSlice.reducer;
