import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { modalActions, useAppDispatch, useAppSelector } from '../../state-management';
import { MODAL_COMPONENTS } from './modalRegistry';
import _ from 'lodash';

const BlankComponent = () => <></>;

export const ModalRoot = () => {
  const { modalType, chakraModalProps, modalProps } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const isOpen = !!modalType;

  function onCloseModal() {
    dispatch(modalActions.hideModal());
    if (_.has(modalProps, 'onModalClose')) {
      modalProps.onModalClose();
    }
  }

  const SpecificModal = modalType ? MODAL_COMPONENTS[modalType] : BlankComponent;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
      scrollBehavior='inside'
      size='xl'
      // Modifying trapFocus to allow sidebar, which displays in modal to be interacted with e.g. typing in an input field in the sidebar.
      trapFocus={false}
      // Modifying blockScrollOnMount to allow sidebar, which displays in modal to be scrollable.
      blockScrollOnMount={false}
      {...chakraModalProps}>
      <ModalOverlay />
      <ModalContent p='md' backgroundColor={chakraModalProps?.backgroundColor}>
        <SpecificModal {...modalProps} {...{ onCloseModal }} />
      </ModalContent>
    </Modal>
  );
};
