import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useMAWorkflow, useModal, useToast } from '../../../hooks';
import { hospitalizationsSelectors, uiActions, useAppDispatch } from '../../../state-management';
import { ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { useForm } from 'react-hook-form';

interface FormInputs extends HospitalizationUI {}

const INPUT_MAX_LEN = 5000;

enum ModalTypes {
  add = 'add',
  edit = 'edit',
}

interface HospitalizationModalProps {
  type: ModalTypes;
}

export function HospitalizationModal({ type }: HospitalizationModalProps) {
  const dispatch = useAppDispatch();
  const { hideModal } = useModal();
  const { maWorkFlowState } = useMAWorkflow();
  const { hospitalizations } = maWorkFlowState;
  const { editedHospitalizationId } = hospitalizations;

  const editedHospitalization = hospitalizationsSelectors.selectById(
    hospitalizations.addedHospitalizations,
    editedHospitalizationId || '',
  );

  const isHospitalizationModalError = !editedHospitalizationId || editedHospitalization == null;

  useToast({
    id: 'isErrorGetHospitalizationById',
    title: 'Error',
    description: 'Failed to fetch options',
    show: isHospitalizationModalError,
    status: 'error',
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: editedHospitalization });

  const onSubmit = handleSubmit(handleCompleteEdit);

  function handleCompleteEdit(values: FormInputs) {
    if (editedHospitalizationId) {
      dispatch(uiActions.setEditedHospitalization({ ...values }));
      hideModal();
    }
  }

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box mb='md'>
          <Heading fontSize='xl' textAlign='center'>
            {`${type === ModalTypes.add ? 'Add' : 'Edit'} Hospitalization`}
          </Heading>
        </Box>
        <VStack spacing='md'>
          <Box flex='1'>
            <FormControl isInvalid={!!errors.reason}>
              <FormLabel>Hospitalization Name</FormLabel>

              <Input
                placeholder='Hospitalization Name'
                {...register('reason', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />

              <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box flex='1'>
            <FormControl isInvalid={!!errors.hospitalizationDate}>
              <FormLabel>Hospitalization Date</FormLabel>

              <Input
                placeholder='Hospitalization Date'
                {...register('hospitalizationDate', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />

              <FormErrorMessage>{errors.hospitalizationDate?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </ModalFooter>
    </>
  );
}
