import { type QueryHookOptions } from '@apollo/client';
import { useGetTelephoneEncountersQuery } from '../../../__generated__/graphql';
import { PROVIDER_WORKFLOW_ENCOUNTER_PAGE_SIZE } from '../../../constants';
import {
  getEncounterFilterState,
  getOpenTEsFilterState,
  getPatientSearchState,
  useAppSelector,
} from '../../../state-management';

// putting this in a hook since both tables and tab components need access to it
export default function useOpenTEsQuery(onCompleted?: () => void) {
  const teFilterState = useAppSelector(getOpenTEsFilterState);
  const filterState = useAppSelector(getEncounterFilterState);
  const patientSearchState = useAppSelector(getPatientSearchState);
  const date = teFilterState.date;

  const options: QueryHookOptions = {
    variables: {
      assigneeIds: filterState?.assignees?.map((x) => parseInt(x.value)) || [],
      patientIds: patientSearchState.selectedIds?.map((x) => parseInt(x.value)),
      facilityIds: teFilterState.facilities.map((x) => parseInt(x.value)),
      callers: teFilterState.callers.map((x) => x.label),
      status: 'open',
      limit: PROVIDER_WORKFLOW_ENCOUNTER_PAGE_SIZE,
      offset: 0,
      date: date ? new Date(date).toISOString() : undefined,
    },
  };

  if (onCompleted) {
    options.onCompleted = onCompleted;
  }

  const res = useGetTelephoneEncountersQuery(options);

  return res;
}
