import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useGetMedicationsQuery } from '../../../../__generated__/graphql';

const headers = ['Medication', 'Assessment', 'Processed', 'Last Action'];

interface Props {
  patientId: number;
}

export function MedLogTab({ patientId }: Props) {
  const { data } = useGetMedicationsQuery({
    variables: {
      patientId,
    },
  });

  type Meds = NonNullable<typeof data>['getMedications'];
  const meds: Record<string, Meds> = {};

  data?.getMedications?.forEach((med) => {
    const key = med.medicationStartDate;
    if (!key) return;
    if (meds[key]) {
      meds[key]?.push(med);
    } else {
      meds[key] = [med];
    }
  });

  return (
    <Flex flexDirection='column' alignItems='flex-start'>
      <TableContainer margin='24px 0' width='100%'>
        <Table fontSize='sm'>
          <Thead>
            <Tr>
              {headers.map((header) => {
                return (
                  <Th key={header}>
                    <Text>{header}</Text>
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {Object.entries(meds).map(([startDate, meds]) => {
              return (
                <>
                  <Tr>
                    <Td colSpan={8}>
                      <Text fontWeight='bold' marginTop='24px'>
                        {new Date(startDate)?.toLocaleDateString()}
                      </Text>
                    </Td>
                  </Tr>

                  {meds?.map((med, i) => {
                    return (
                      <Tr key={i}>
                        <Td>
                          <Text fontSize='sm' as='b'>
                            {med.medicationItem}
                          </Text>
                          <br />
                          <Text fontSize='sm'>
                            {med.medicationTake} {med.medicationFrequency}, {med.medicationRoute}
                          </Text>
                        </Td>

                        <Td>{med.rxNotes}</Td>

                        <Td>
                          {med.medicationStartDate &&
                            new Date(med.medicationStartDate)?.toLocaleDateString()}
                        </Td>

                        <Td>
                          {med.rxSource?.split(',').join(', ')}
                          <br />
                          {/* {med.medicationStopDate} */}
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
