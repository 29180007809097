import { differenceInYears, format, subYears } from 'date-fns';
import { DateTime } from 'luxon';

// UTILS
// "1975-11-03T18:07:57.048Z" -> 46
export const calculateAge = (dobUTC: string): number => {
  const age = differenceInYears(new Date(), new Date(dobUTC));
  return age;
};
/**
 * "1975-11-03T18:07:57.048Z" -> "11/03/1975"
 * @param dateUTC
 * @returns
 */
export function formatDate(dateUTC?: string | null) {
  if (dateUTC) {
    return format(new Date(dateUTC), 'MM/dd/yyyy');
  }
}
/**
 * Handle a datestring wihtout changing date based on timezone
 * "1946-04-03T00:00:00.000Z" -> "04/03/1946"
 * @param dateUTC
 * @returns
 */
export function formatDateNoUTC(dateNoUTC?: string | null) {
  if (dateNoUTC) {
    const date = new Date(dateNoUTC);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() + userTimezoneOffset);
    return format(new Date(localDate), 'MM/dd/yyyy');
  }
}
/**
 * "1975-11-03" -> "11/03/1975"
 * Works regardless of Timezone
 * @param isoDateString
 * @returns
 */
export function formatISODate(isoDateString?: string | null) {
  if (!isoDateString) {
    return null;
  }
  return DateTime.fromISO(isoDateString).toLocaleString();
}

/**
 * "1975-11-03T18:07:57.048Z" -> "11/03/1975"
 * @param dateUTC
 * @returns
 */
export function formatUTCDate(dateUTC?: string) {
  if (dateUTC) {
    const d = new Date(dateUTC);
    return `${d.getUTCMonth() + 1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
  }
}

/**
 * This function will take a JS Date object from local browser timezone and convert it to UTC
 * @param dateUTC
 * @returns JS Date Object in UTC
 */
export const convertJSDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};

/**
 * "1975-11-03T18:07:57.048Z" -> "11/03/1975"
 * @param dateUTC
 * @returns
 */
export function convertISODateToJSDate(isoDate?: string) {
  if (!isoDate) return undefined;

  const jsDate = new Date(isoDate);
  return convertJSDateToUTC(jsDate);
}

/**
 * @description Creates new date string which matches back-end
 * Date (without Time) format: "YYYY-MM-DD".  Works regardless of Timezone
 * @returns New ISO 8601 Date string -> "1975-01-30"
 */
export function nowISODate() {
  return DateTime.now().toISODate();
}

/**
 * @description Creates new date string which matches back-end
 * DateTime (i.e. Timestamp) format: "YYYY-MM-DDTHH:MM:SSZ" through
 * "YYYY/MM/DDTHH:MM:SS.SSSZ" in UTC-Z Timezone
 * @returns New ISO 8601 DateTime -> "1975-01-30T08:30:22.456Z"
 */
export function nowISODateTime() {
  return DateTime.now().toISO();
}

/**
 * "2021-03-19T12:00:00.000+00:00" -> "7:00 AM"
 * @param dateUTC
 * @returns
 */
export function formatTime(dateUTC?: string | null): string | null {
  if (!dateUTC) {
    return null;
  }
  return format(new Date(dateUTC), 'h:mm aa');
}

export function subtractYears(date: Date, years: number | string) {
  const days = Number(years);
  return subYears(new Date(), days);
}

/**
 *
 * @param value
 * @description Accepts a JS Date, Luxon DateTime, ISO 8601 DateTime or Date String of any TZ, or Number of Seconds as input
 * WARNING: If incoming timezone is not UTC, the date will be converted to UTC
 * @returns valid ISO 8601 Date String `YYYY-MM-DD` if input is valid, otherwise undefined
 */
export const convertToISODate = (
  value: Date | string | DateTime | number | undefined | null,
): string | undefined => {
  // const isoDateRegEx = /^\d{4}-\d{2}-\d{2}$/;

  // Null or Undefined
  if (!value) {
    return undefined;
  }

  if (typeof value === 'string') {
    return DateTime.fromISO(value, { zone: 'utc' }).toISODate() || undefined;
  }

  if (typeof value === 'number') {
    return DateTime.fromSeconds(value, { zone: 'utc' }).toISODate() || undefined;
  }

  if (value instanceof Date) {
    return DateTime.fromJSDate(value, { zone: 'utc' }).toISODate() || undefined;
  }

  if (value instanceof DateTime) {
    return value.setZone('utc').toISODate() || undefined;
  }

  throw Error(
    `convertToISODate does not accept ${value}. Input must be valid JS Date, Luxon DateTime, ISO 8601 DateTime or Date String of any TZ, or Number of Seconds`,
  );
};

/**
 *
 * @param value
 * @description Accepts a JS Date, Luxon DateTime, or ISO 8601 DateTime or Date String of any TZ as input
 * @returns valid Locale Date String if input is valid, otherwise null
 * WARNING: This function will always convert extract date value while ignoring timestamps and Timezones
 */
export const convertDateToLocaleDate = (
  value: Date | string | DateTime | undefined | null,
): string | null => {
  // Null or Undefined
  if (!value) {
    return null;
  }

  const isoDateRegEx = /^\d{4}-\d{2}-\d{2}$/;

  if (typeof value === 'string') {
    if (isoDateRegEx.test(value)) {
      return DateTime.fromISO(value).toLocaleString();
    } else {
      const date = value.slice(0, 10);
      return DateTime.fromISO(date).toLocaleString();
    }
  }

  // TODO: This will not work for times with offset later in the day due to UTC conversion
  if (value instanceof Date) {
    return DateTime.fromJSDate(value, { zone: 'utc' }).toLocaleString();
  }

  // TODO: This will not work for times with offset later in the day due to UTC conversion
  if (value instanceof DateTime) {
    return value.toLocaleString();
  }

  throw Error(
    `convertDateToLocaleDate does not accept ${value}. Input must be valid JS Date, Luxon DateTime, or ISO 8601 DateTime or Date String of any TZ`,
  );
};
