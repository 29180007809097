import { useEffect } from 'react';
import { type UIState } from '../../state-management';

// the props used by the panel state handlers
// in the panels dir
export interface VitalsProps {
  state: UIState['orderFlowNavigation'];
  setDirty: (id: OrderFlowSteps, isDirty: boolean) => void;
  isCurrentStep: boolean;
}

export function useUpdateVitalsDirty({ state, setDirty, isCurrentStep }: VitalsProps) {
  useEffect(() => {
    if (!isCurrentStep) return;
    // check the properties in the vitals object for any values
    // if there are values, then we know to mark as dirty
    // since values are cleared on save
    const hasVals = Object.values(state.vitals).some((vital) => {
      if ('length' in vital) {
        return !!vital.some((v) => !!v.value);
      }
      // bmi can be infinity if height and weight are reset to empty values
      return !!vital?.value && vital?.value !== 'Infinity';
    });
    setDirty('vitals', hasVals);
  }, [state.vitals, isCurrentStep]);
}
