import { HStack, Icon, IconButton, Tag, Td, Text, Th, VStack } from '@chakra-ui/react';
import { Flex } from '../../../../../components';
import { useNavigate } from 'react-router-dom';
import { AiOutlineExclamation } from 'react-icons/ai';
import { FiX } from 'react-icons/fi';
import { type EncounterTableHeadCell, type EncounterSort } from '../../../../../types';
import { providerWorkflowActions, useAppDispatch } from '../../../../../state-management';

export function OpenTECell({
  val,
  hovered,
  setHovered,
  patientId,
  highPriority,
}: {
  val: number;
  hovered: boolean;
  setHovered: (hovered: boolean) => void;
  patientId: number;
  highPriority: boolean;
}) {
  const nav = useNavigate();

  if (!val)
    return (
      <Td>{highPriority && <Icon as={AiOutlineExclamation} fontSize='20px' color='#db5635' />}</Td>
    );
  // hardcode this for testing
  // since mock encounter patientIds
  // are different than the mock patients
  return (
    <Td>
      <Flex cursor='pointer' fontSize='xs' gap={3} alignItems='center'>
        <Tag
          onClick={(e) => {
            nav(`/providers/${patientId}/open`);
            e.stopPropagation();
            e.preventDefault();
          }}
          borderRadius={24}
          bg={hovered ? '#fc8262' : '#db5635'}
          minWidth={50}
          padding='sm'
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}>
          <Text textColor='white'>{val?.toString()} Open TE's</Text>
        </Tag>
        {highPriority && <Icon as={AiOutlineExclamation} fontSize='20px' color='#db5635' />}
      </Flex>
    </Td>
  );
}

export function ChipCell({
  total,
  outstanding,
  delinquent,
}: {
  total: number;
  delinquent: number;
  outstanding: number;
}) {
  let color = '#5f9f52';
  if (outstanding > 0) color = '#8190ca';
  if (delinquent > 0) color = '#db5635';

  return (
    <Td>
      <Flex gap={3}>
        <Tag
          borderRadius={24}
          textAlign='center'
          backgroundColor={color}
          minWidth={16}
          padding='sm'>
          <Text width='100%' textColor='white'>
            {total}
          </Text>
        </Tag>
        <Flex alignItems='center'>
          <Text color={color}>
            {outstanding > 0 && `${outstanding} Outstanding`}
            {delinquent > 0 && `${outstanding > 0 ? ', ' : ''} ${delinquent} delinquent`}
          </Text>
        </Flex>
      </Flex>
    </Td>
  );
}

export function UserCell({
  patientName,
  dateOfBirth,
  patientId,
  openTECount,
  highPriority,
  tagHovered,
  setTagHovered,
}: {
  patientName: string;
  dateOfBirth: string;
  patientId: number;
  openTECount: number;
  highPriority: boolean;
  tagHovered: boolean;
  setTagHovered: (hovered: boolean) => void;
}) {
  const nav = useNavigate();

  return (
    <Td>
      <Flex justifyContent='space-between' alignItems='center'>
        <VStack gap={0}>
          <Text width='full'>{patientName}</Text>
          <Text style={{ margin: 0 }} fontSize='xs' width='full'>
            {patientId}
          </Text>
          <Text style={{ margin: 0 }} fontSize='xs' width='full'>
            {dateOfBirth?.split('T')?.[0]}
          </Text>
        </VStack>

        <HStack>
          {!!openTECount && (
            <Tag
              onClick={(e) => {
                nav(`/providers/${patientId}/open`);
                e.stopPropagation();
                e.preventDefault();
              }}
              borderRadius={24}
              bg={tagHovered ? '#fc8262' : '#db5635'}
              minWidth={50}
              height='40px'
              padding='sm'
              onMouseEnter={() => {
                setTagHovered(true);
              }}
              onMouseLeave={() => {
                setTagHovered(false);
              }}>
              <Text textColor='white'>{openTECount?.toString()} Open TE's</Text>
            </Tag>
          )}

          {highPriority && <Icon as={AiOutlineExclamation} fontSize='20px' color='#db5635' />}
        </HStack>
      </Flex>
    </Td>
  );
}

export function HeadCell({
  cell,
  encounterSort,
}: {
  cell: EncounterTableHeadCell;
  encounterSort: EncounterSort | null;
}) {
  const dispatch = useAppDispatch();

  const isSorted = encounterSort && encounterSort.orderByColumn === cell.value;

  function renderArrows() {
    if (!isSorted) {
      // Double arrow initial direction indicates default behavior
      // (ASC sort for names, DESC for numbers & dates)
      return cell.label === 'Patient Name' ? '\u2191\u2193' : '\u2193\u2191';
    } else {
      // Show current state of sort
      return (
        <Flex>
          <Text>{encounterSort.orderDirection === 'ASC' ? '\u2191' : '\u2193'}</Text>
          {/* Invisible arrow to preserve component width */}
          <Text textColor='brand.150'>{'\u2191'}</Text>
        </Flex>
      );
    }
  }

  return (
    <Th
      position='relative'
      _hover={{
        background: !isSorted && 'gray.100',
      }}
      background={isSorted ? 'brand.150' : undefined}
      onClick={() => {
        isSorted
          ? dispatch(providerWorkflowActions.toggleSortDirection())
          : dispatch(
              providerWorkflowActions.setSort({
                orderByColumn: cell.value,
                orderDirection: cell.label === 'Patient Name' ? 'ASC' : 'DESC',
              }),
            );
      }}>
      <Flex justifyContent='space-between' alignItems='center'>
        <HStack>
          <Text fontSize='sm' textColor={isSorted ? 'white' : 'gray.600'}>
            {cell.label}
          </Text>
          <Text textColor={isSorted ? 'white' : 'brand.500'}>{renderArrows()}</Text>
        </HStack>

        {isSorted && (
          <IconButton
            color='white'
            _hover={{
              background: 'white',
              color: 'brand.600',
            }}
            position='absolute'
            right={2}
            size='xs'
            variant='ghost'
            onClick={() => {
              dispatch(providerWorkflowActions.setSort(null));
            }}
            colorScheme='brand'
            aria-label='sorter-exit'
            icon={<Icon as={FiX} />}
          />
        )}
      </Flex>
    </Th>
  );
}
