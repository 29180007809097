import {
  Input as ChakraInput,
  type InputProps as ChakraInputProps,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { Spinner } from '../../data-display';

const Input = React.forwardRef(
  ({ ...props }: ChakraInputProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <InputGroup>
        <ChakraInput {...props} ref={ref} />
        {props._loading && (
          <InputRightElement>
            <Spinner />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

Input.displayName = 'Input';

export { Input };
