import {
  Button,
  Center,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { modalActions, useAppDispatch } from '../../../state-management';
import { isEmpty } from 'lodash';

import { BiPlus as PlusIcon } from 'react-icons/bi';
import { HStack } from '../../../components';
import { useSocialHistory } from '../../../hooks';

export function SocialHistoryPanel() {
  const dispatch = useAppDispatch();
  const { currentCategoryId, userSocialHistoryList, currentSocialHistoryCategory } =
    useSocialHistory();
  function handleEditItem(socialInfoId?: number) {
    dispatch(
      modalActions.showModal({
        modalType: 'SocialHistoryEditModal',
        chakraModalProps: { size: '3xl' },
      }),
    );
  }
  const currentUserSocialHistoryList = userSocialHistoryList?.filter(
    (sh) => sh.categoryId === currentCategoryId,
  );
  const mappedUserSocialHistory = currentUserSocialHistoryList?.reduce<
    Record<number, SocialHistory>
  >((accum, obj) => {
    return { ...accum, [(obj.observationId || '').toString()]: obj };
  }, {});

  const infoItems = currentSocialHistoryCategory?.socialInfoItems.map((info) => {
    return {
      ...info,
      options: info.options?.split(',').filter((val) => val.length > 0),
    };
  });
  return isEmpty(currentUserSocialHistoryList) ? (
    <Center>
      <VStack>
        <Text>To get started, add social history items.</Text>
        <Button
          onClick={() => {
            handleEditItem();
          }}
          variant='outline'
          leftIcon={<Icon as={PlusIcon} />}
        >
          Add social history items
        </Button>
      </VStack>
    </Center>
  ) : (
    <TableContainer>
      <Table variant='simple' size='xs'>
        <Thead>
          <Tr>
            <Th>Social Info</Th>
            <Th>Options</Th>
            <Th>Details</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {infoItems?.map((info) => {
            const sh = mappedUserSocialHistory[info.socialInfoID || -1];
            return (
              <Tr key={info.socialInfoID}>
                <Td>{info.name}</Td>
                <Td>
                  {info.options?.length ? (
                    <HStack>
                      <Text textTransform='capitalize'>{sh?.option}</Text>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          handleEditItem(info.socialInfoID || -1);
                        }}
                      >
                        Edit
                      </Button>
                    </HStack>
                  ) : null}
                </Td>
                <Td maxW='420px'>
                  {!info.options?.length ? (
                    <HStack>
                      <Text noOfLines={1} isTruncated>
                        {sh?.details}
                      </Text>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          handleEditItem(info.socialInfoID || -1);
                        }}
                      >
                        Edit
                      </Button>
                    </HStack>
                  ) : null}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
