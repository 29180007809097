import { DatePicker } from '../../../../../components';
import {
  openTEsFilterActions,
  getOpenTEsFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';

export function DateFilter() {
  const filterState = useAppSelector(getOpenTEsFilterState);
  const dispatch = useDispatch();
  const value = filterState.date ? new Date(filterState.date) : undefined;

  return (
    <DatePicker
      onChange={(date) => {
        const update = openTEsFilterActions.onFilterStateChange({
          date: date?.getTime(),
        });
        dispatch(update);
      }}
      value={value}
      placeholder='Date'
    />
  );
}
