import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  VStack,
  Textarea,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useMAWorkflow, useModal, useToast } from '../../../hooks';
import { uiActions, useAppDispatch } from '../../../state-management';
import { DatePicker } from '../../form';
import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { useForm, Controller } from 'react-hook-form';
import { AsyncSelectField } from '../../../features';
import { Flex } from '../../layout';
import { useGetMedicationByDrugNameIdQuery } from '../../../__generated__/graphql';
import { useGetRxData } from '../../../screens/MAWorkFlowScreen/MedicationsContent/PastRx/hooks/useGetRxData';

interface FormInputs extends MedicationItemUI {}

const INPUT_MAX_LEN = 5000;

export function EditMedicationsModal() {
  const dispatch = useAppDispatch();
  const { hideModal } = useModal();
  const { maWorkFlowState } = useMAWorkflow();
  const { medications } = maWorkFlowState;
  const editedMedicationId = medications.editedMedicationId;
  const { encounters } = useGetRxData();

  // we need to check both the addedMedications and the rxData for the medication
  // if it's an rxData that does not exist in the addedMedications, we need to add it
  // to addedMedications when we save
  const allMeds = {
    ...medications.addedMedications.entities,
  };

  encounters.forEach((encounter) => {
    encounter.meds.forEach((m) => {
      if (!m.uiMed.id) return;
      if (allMeds[m.uiMed.id]) return;
      allMeds[m.uiMed?.id] = m.uiMed;
    });
  });

  const editedMedication = allMeds[editedMedicationId ?? ''];

  const drugNameID = editedMedication?.drugNameId ? editedMedication?.drugNameId : 0;

  const {
    data: dataGetMedicationByDrugData,
    error: isErrorGetMedicationByDrugId,
    loading: isLoadingGetMedicationByDrugId,
  } = useGetMedicationByDrugNameIdQuery({
    variables: { drugNameID },
    skip: !drugNameID,
  });
  const dataGetMedicationByDrug = dataGetMedicationByDrugData?.getMedicationByDrugNameID;
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: editedMedication });

  const onSubmit = handleSubmit(handleCompleteEdit);

  function handleCompleteEdit(values: FormInputs) {
    if (editedMedicationId) {
      dispatch(uiActions.upsertEditedMedication([{ ...values }]));
      hideModal();
    }
  }

  const options = dataGetMedicationByDrug?.reduce(
    (acc, med) => {
      acc.strength.push({ label: med.strength || '', value: med.strength || '' });
      acc.frequency.push({ label: med.frequency || '', value: med.frequency || '' });
      acc.take.push({ label: med.take || '', value: med.take || '' });
      acc.route.push({ label: med.route || '', value: med.route || '' });
      return acc;
    },
    {
      strength: [] as DropdownOption[],
      take: [] as DropdownOption[],
      route: [] as DropdownOption[],
      frequency: [] as DropdownOption[],
    },
  );

  // Setup toasts
  useToast({
    id: 'isErrorGetMedicationByDrugId',
    title: 'Error',
    description: 'Failed to fetch options',
    show: !!isErrorGetMedicationByDrugId,
    status: 'error',
  });

  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <Box mb='md'>
          <Heading fontSize='xl' textAlign='center'>
            {editedMedication?.medicationItem}
          </Heading>
        </Box>
        <VStack alignItems='stretch' spacing='md'>
          <HStack spacing='md'>
            <Box flex='1'>
              <Controller
                render={({ field }) => (
                  <Flex flexDirection='column'>
                    <Text>Start Date</Text>

                    <DatePicker
                      value={getDate(field.value)}
                      onChange={(date) => {
                        field.onChange(date?.toISOString());
                      }}
                    />
                  </Flex>
                )}
                control={control}
                name='medicationStartDate'
              />
            </Box>
            <Box flex='1'>
              <Controller
                render={({ field }) => (
                  <Flex flexDirection='column'>
                    <Text>End Date</Text>

                    <DatePicker
                      value={getDate(field.value)}
                      onChange={(date) => {
                        field.onChange(date?.toISOString());
                      }}
                    />
                  </Flex>
                )}
                control={control}
                name='medicationStopDate'
              />
            </Box>
          </HStack>
          <SimpleGrid spacing='md' columns={2}>
            <FormControl isInvalid={!(errors.rxSource == null)}>
              <Flex flexDirection='column'>
                <Text>Source</Text>

                <Input
                  {...register('rxSource', {
                    maxLength: {
                      value: INPUT_MAX_LEN,
                      message: `The max length is ${INPUT_MAX_LEN}`,
                    },
                  })}
                />
              </Flex>
              <FormErrorMessage>{errors.rxSource?.message}</FormErrorMessage>
            </FormControl>

            <Controller
              render={({ field }) => (
                <Flex flexDirection='column'>
                  <Text>Strength</Text>

                  <AsyncSelectField
                    dropdownItems={options?.strength}
                    handleInputChange={(v) => {
                      field.onChange(v);
                    }}
                    handleOptionSelect={(o) => {
                      if (o != null) {
                        field.onChange(o.value);
                      }
                    }}
                    variant='search-select'
                    initialInputValue={editedMedication?.medicationStrength}
                    loading={isLoadingGetMedicationByDrugId}
                  />
                </Flex>
              )}
              control={control}
              name='medicationStrength'
            />
            <Controller
              render={({ field }) => (
                <Flex flexDirection='column'>
                  <Text>Take</Text>

                  <AsyncSelectField
                    dropdownItems={options?.take}
                    handleInputChange={(v) => {
                      field.onChange(v);
                    }}
                    handleOptionSelect={(o) => {
                      if (o != null) {
                        field.onChange(o.value);
                      }
                    }}
                    variant='search-select'
                    initialInputValue={editedMedication?.medicationTake}
                    loading={isLoadingGetMedicationByDrugId}
                  />
                </Flex>
              )}
              control={control}
              name='medicationTake'
            />
            <Controller
              render={({ field }) => (
                <Flex flexDirection='column'>
                  <Text>Route</Text>

                  <AsyncSelectField
                    dropdownItems={options?.route}
                    handleInputChange={(v) => {
                      field.onChange(v);
                    }}
                    handleOptionSelect={(o) => {
                      if (o != null) {
                        field.onChange(o.value);
                      }
                    }}
                    variant='search-select'
                    initialInputValue={editedMedication?.medicationRoute}
                    loading={isLoadingGetMedicationByDrugId}
                  />
                </Flex>
              )}
              control={control}
              name='medicationRoute'
            />

            <Controller
              render={({ field }) => (
                <Flex flexDirection='column'>
                  <Text>Frequency</Text>

                  <AsyncSelectField
                    dropdownItems={options?.frequency}
                    handleInputChange={(v) => {
                      field.onChange(v);
                    }}
                    handleOptionSelect={(o) => {
                      if (o != null) {
                        field.onChange(o.value);
                      }
                    }}
                    variant='search-select'
                    initialInputValue={editedMedication?.medicationFrequency}
                    loading={isLoadingGetMedicationByDrugId}
                  />
                </Flex>
              )}
              control={control}
              name='medicationFrequency'
            />
          </SimpleGrid>

          <FormControl isInvalid={!(errors.rxNotes == null)}>
            <Flex flexDirection='column'>
              <Text>Notes</Text>

              <Textarea
                {...register('rxNotes', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />
            </Flex>

            <FormErrorMessage>{errors.rxNotes?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </ModalFooter>
    </>
  );
}

function getDate(date?: string | null) {
  return date ? new Date(date) : null;
}
