import { useGetRxData } from './hooks/useGetRxData';
import { Box, Flex, Input, Stack, Text, Skeleton } from '@chakra-ui/react';
import { MedicationRow } from '../MedicationRow';
import { TakingButton } from './TakingButton';
import { useUpdateMedItems } from '../hooks/useUpdateMedItems';
import { MedStatusValues } from '../../../../types';
import { useToastHandler } from '../hooks/useToastHandler';

export function PastRX() {
  const { encounters, search, setSearch, isLoading } = useGetRxData();
  const { handleAddPreviousEncounterMeds, handleRemoveMedications } = useUpdateMedItems();
  const taking = MedStatusValues.T.toString();
  const toast = useToastHandler();

  if (isLoading)
    return (
      <Stack gap={4} h='full' w='full'>
        {Array.from(Array(6)).map((_, i) => (
          <Skeleton key={i} h={12} w='full' />
        ))}
      </Stack>
    );

  return (
    <Box w='full' h='full' px={1}>
      <Input
        w='full'
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder='Find RX'
      />
      <Stack gap='sm' mt='lg'>
        {encounters
          ?.sort((b, a) => {
            return (
              new Date(a.encounterStartDateTime).getTime() -
              new Date(b.encounterStartDateTime).getTime()
            );
          })
          .map((encounter, i) => {
            const uiMeds = encounter.meds.map((m) => m.uiMed);
            return (
              <Box key={encounter.sourceEncounterId}>
                <Flex justifyContent='space-between' mb='sm' pr={8}>
                  <Text fontSize='xl' as='b' w='full'>
                    Medications: {encounter.reason} | {encounter.dateTime}
                  </Text>
                  <TakingButton
                    onClick={() => {
                      if (encounter.allMedsAreTaking) {
                        handleRemoveMedications(encounter.meds.map((med) => med.uiMed.id));
                      } else {
                        handleAddPreviousEncounterMeds(uiMeds, MedStatusValues.T.toString());
                      }
                      const updated = encounter.meds.map((med) => ({
                        ...med,
                        uiMed: {
                          ...med.uiMed,
                          newStatus: encounter.allMedsAreTaking ? med.previousStatus : taking,
                        },
                      }));
                      toast(updated);
                    }}
                    active={encounter.allMedsAreTaking}
                    label='Mark All'
                  />
                </Flex>
                {encounter.meds.map((med, i) => {
                  const key = `${med.uiMed.drugNameId}-${encounter.sourceEncounterId}-${i}`;
                  return (
                    <MedicationRow key={key} item={med.uiMed}>
                      <TakingButton
                        onClick={() => {
                          const isTaking = med.uiMed.newStatus === taking;
                          // toggle "Taking".  If the current val is "Taking" revert to the
                          // original/saved status
                          const status = isTaking ? med.previousStatus ?? taking : taking;
                          if (isTaking) {
                            handleRemoveMedications([med.uiMed.id]);
                          } else {
                            handleAddPreviousEncounterMeds(med.uiMed, status);
                          }
                          toast([
                            {
                              ...med,
                              uiMed: {
                                ...med.uiMed,
                                newStatus: status,
                              },
                            },
                          ]);
                        }}
                        active={med.uiMed.newStatus === taking}
                      />
                    </MedicationRow>
                  );
                })}
              </Box>
            );
          })}
      </Stack>
    </Box>
  );
}
