import { useGetLocationsQuery } from '../../../../__generated__/graphql';
import { ComboBox } from '../../../../components';
import { type ComboBoxOption } from '../../../../types';

interface Props {
  selectedId?: string | number | null;
  selectionCallback: (providerId: any) => void;
  singleSelected?: ComboBoxOption;
  placeholder?: string;
}

export function Facilities({
  selectionCallback,
  singleSelected,
  selectedId,
  placeholder = 'Facility',
}: Props) {
  const { data, loading } = useGetLocationsQuery();
  const selected = selectedId
    ? data?.getLocations?.locations?.find((x) => x.locationID === Number(selectedId))
    : undefined;

  return (
    <ComboBox
      key={JSON.stringify(selected)}
      isLoading={loading}
      defaultValue={
        selected && {
          label: selected.locationName,
          value: `${selected.locationID}`,
        }
      }
      placeholder={placeholder}
      options={data?.getLocations?.locations?.map((l) => ({
        label: `${l.locationName}`,
        value: l.locationID.toString(),
      }))}
      onSelection={(selection) => {
        selectionCallback(selection?.value);
      }}
    />
  );
}
