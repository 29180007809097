import {
  HStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Td,
  Text,
  Tbody,
  Button,
  Radio,
  Center,
  WaitUntilLoaded,
} from '../../../components';
import { useEffect } from 'react';
import { uiActions, useAppDispatch, useAppSelector } from '../../../state-management';
import { useMAWorkflow, useModal, usePatient } from '../../../hooks';
import { type g } from '../../../api';
import { isEmpty } from 'lodash';
import { TableHeader } from './TableHeader';
import { useGetGynHistoryAndLookupsQuery } from '../../../__generated__/graphql';

export function GYNHistoryPanel() {
  const { patientId } = usePatient();
  const { maWorkFlowState } = useMAWorkflow();
  const { gynHistory } = maWorkFlowState.obgyn;
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);

  const {
    data: dataGetGYNHistoryAndInitialLookup, // dataGYNLookup,
    loading: isLoadingGetGYNHistoryAndInitialLookup,
    error: errorGetGYNHistoryAndInitialLookup,
    refetch: refetchGynHistory,
  } = useGetGynHistoryAndLookupsQuery({
    variables: {
      patientId,
    },
    onCompleted() {
      dispatch(uiActions.setHasLoadedInitialData('obgyn'));
    },
  });

  const isErrorGetGYNHistoryAndInitialLookup = !(errorGetGYNHistoryAndInitialLookup == null);

  const gynLookups = dataGetGYNHistoryAndInitialLookup?.getGynLookups;
  const fetchedGynHistory = dataGetGYNHistoryAndInitialLookup?.getGynHistory;
  const dispatch = useAppDispatch();
  const { showModal } = useModal();

  useEffect(() => {
    void refetchGynHistory();
  }, [lastUpdate]);

  // Re-hydrate the redux state with user's gynHistory items from the server
  useEffect(() => {
    if (fetchedGynHistory != null) {
      // Remove all null values
      const userGynHistory = fetchedGynHistory.filter((x) => x !== null) as g.GynHistory[];
      dispatch(uiActions.addManyGynHistoryItems(userGynHistory));
    }
  }, [JSON.stringify(fetchedGynHistory)]);

  return (
    <WaitUntilLoaded
      loading={isLoadingGetGYNHistoryAndInitialLookup}
      showErrorMessage={isErrorGetGYNHistoryAndInitialLookup}>
      <TableContainer>
        <Table variant='simple' size='xs'>
          <Thead>
            <Tr>
              <TableHeader>Symptoms</TableHeader>
              <TableHeader>Notes</TableHeader>
              <TableHeader>Details</TableHeader>
              <TableHeader textAlign='center'>History Of?</TableHeader>
              <TableHeader textAlign='center'>Denies</TableHeader>
            </Tr>
          </Thead>
          <Tbody>
            {gynLookups?.map((lookup) => {
              const symptomID = lookup?.symptomID;

              if (!symptomID) return null;

              const userItem = Object.values(gynHistory.entities).find(
                (item) => item?.gynSymptomID === symptomID,
              );
              const isNew = userItem == null;
              const updateBase: UpsertGYNHistoryItem =
                isNew || !userItem
                  ? {
                      // new entry
                      gynSymptomID: symptomID,
                      isNew: true,
                      denies: null,
                      gynHistoryStructuredData: null,
                      history: null,
                      sourcePatientId: null,
                      symptomNotes: null,
                    }
                  : {
                      // pre-existing user entry
                      ...userItem,
                    };

              return (
                <Tr key={symptomID}>
                  <Td>
                    <Text>{lookup.symptomName}</Text>
                  </Td>
                  <Td w='380px' maxW='380px'>
                    <HStack>
                      <Text noOfLines={1}>{updateBase.symptomNotes}</Text>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          showModal({
                            modalType: 'EditGynSymptomNoteModal',
                            chakraModalProps: { size: '5xl' },
                            modalProps: {
                              gynDataItem: updateBase,
                              symptomOptions: lookup?.symptomOptions,
                            },
                          });
                        }}>
                        {updateBase.symptomNotes ? 'Edit' : 'Add'}
                      </Button>
                    </HStack>
                  </Td>
                  <Td>
                    {isEmpty(lookup.structuredData) ? null : (
                      <Button
                        variant='ghost'
                        onClick={() => {
                          showModal({
                            modalType: 'GYNStructedDataFormModal',
                            modalProps: {
                              gynStructuredData: lookup.structuredData,
                              userGynHistoryItem: updateBase,
                            },
                          });
                        }}>
                        {isEmpty(updateBase.gynHistoryStructuredData) ? 'Add' : 'Edit'}
                      </Button>
                    )}
                  </Td>
                  <Td>
                    <Center>
                      <Radio
                        // value={"history-of"}
                        isChecked={updateBase.denies === null ? false : updateBase.denies}
                        onClick={() => {
                          // If you click the radio button while it is already true, uncheck it without altering the other radio
                          const shouldUncheck = updateBase.denies === true;

                          dispatch(
                            uiActions.upsertGynDataItem({
                              ...updateBase,
                              denies: !updateBase.denies,
                              history: shouldUncheck ? false : updateBase.denies,
                            }),
                          );
                        }}
                        colorScheme='brand'
                      />
                    </Center>
                  </Td>
                  <Td>
                    <Center>
                      <Radio
                        // value={"denies"}
                        isChecked={updateBase.history === null ? false : updateBase.history}
                        onClick={() => {
                          const shouldUncheck = updateBase.history === true;

                          dispatch(
                            uiActions.upsertGynDataItem({
                              ...updateBase,
                              history: !updateBase.history,
                              denies: shouldUncheck ? false : updateBase.history,
                            }),
                          );
                        }}
                        colorScheme='brand'
                      />
                    </Center>
                  </Td>
                  {/* </RadioGroup> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {/* <Textarea noOfLines={5} /> */}
    </WaitUntilLoaded>
  );
}
