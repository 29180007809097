import {
  Box,
  Button,
  Card,
  Center,
  ErrorMessage,
  FlatList,
  WaitUntilLoaded,
  Text,
} from '../../../components';
import { type Appointment, type EncounterCardInfo, type GetPatientPatient } from '../../../types';
import { formatDate } from '../../../utils';
import { useNavigation, usePatient } from '../../../hooks';
import { Flex } from '@chakra-ui/react';
import _ from 'lodash';
import { appointmentsActions, useAppDispatch } from '../../../state-management';
import { useGetEncountersQuery } from '../../../__generated__/graphql';
import { useEffect, useState } from 'react';

interface RenderItemProps {
  item: Appointment;
  index: number;
}

function getDateFromEncounter(encounter: Appointment) {
  return formatDate(encounter.startTime);
}

function getVisitReasonFromEncounter(encounter: Appointment) {
  return encounter?.visitReason;
}

function getEncounterCardInfoFromEncounter(encounter: Appointment): Partial<EncounterCardInfo> {
  return {
    encounterID: encounter.encounterID,
    date: getDateFromEncounter(encounter),
    visitReason: getVisitReasonFromEncounter(encounter),
  };
}

function sortEncountersDesc(orders: GetPatientPatient['encounters'] | undefined) {
  if (orders != null) {
    // Copy object because can't manipulate read-only state object, or sort fails
    const ordersPendingSort = [...orders];

    return ordersPendingSort?.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return 1;
      }
      if (a.startTime > b.startTime) {
        return -1;
      }
      return 0;
    });
  }
}

export function EncounterCardList() {
  const [encounterId, setEncounterId] = useState<number>();
  const { patient, error, loading: isLoading } = usePatient();
  const { toPastEncounterDetailPage, toOrderFlowPage } = useNavigation();
  const dispatch = useAppDispatch();
  const encounters = sortEncountersDesc(patient?.encounters);
  const { data: encounterData, loading: isLoadingEncounter } = useGetEncountersQuery({
    variables: {
      encounterIDs: [encounterId || -1],
      includeLabs: false,
      includeCompleted: true,
      limit: 1,
    },
    skip: !encounterId,
  });

  useEffect(() => {
    if (encounterData?.getEncounters?.encounters?.length) {
      const encounter = encounterData?.getEncounters?.encounters[0];
      if (encounter) {
        dispatch(appointmentsActions.addActiveEncounter(encounter as Appointment));
        toOrderFlowPage();
      }
    }
  }, [encounterData]);

  /**
   * Note: Leaving this error logic here for now since it needs a custom
   * margin top. For now WaitUntilLoaded is just showing the default
   * ErrorMessage.
   */
  if (error != null) {
    return (
      <Box mt='md'>
        <ErrorMessage text='Something went wrong' />
      </Box>
    );
  }

  function renderItem({ item }: RenderItemProps) {
    if (encounters != null) {
      const enc = getEncounterCardInfoFromEncounter(item);

      return (
        <Card variant='accept' mt='2'>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text>Visit reason: {enc.visitReason}</Text>

            <Flex alignItems='center'>
              <Text mr='md'>{enc.date}</Text>
              <Button
                onClick={
                  !_.isNil(item.progressNoteLockedDateTime)
                    ? () => {
                        toPastEncounterDetailPage(item.encounterID);
                      }
                    : () => {
                        setEncounterId(item.encounterID);
                      }
                }
                w='175px'
                textColor='white'
                boxShadow='primary'>
                View Encounter
              </Button>
            </Flex>
          </Flex>
        </Card>
      );
    }
  }

  return (
    <WaitUntilLoaded loading={isLoading || isLoadingEncounter}>
      {encounters?.length ? (
        <Box
          as={FlatList}
          data={encounters}
          renderItem={renderItem}
          keyExtractor={(item: Appointment) => item.encounterID.toString()}
        />
      ) : (
        <Box mt='md'>
          <Center>
            <Text>There are no encounters.</Text>
          </Center>
        </Box>
      )}
    </WaitUntilLoaded>
  );
}

export function EncountersTabPanel() {
  return (
    <Box>
      <EncounterCardList />
    </Box>
  );
}
