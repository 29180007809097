import { IconButton } from '../../form';
import { useNavigation } from '../../../hooks';
import { ButtonGroup, Tooltip } from '@chakra-ui/react';
import { AddTemplateIcon } from '../../svgs/AddTemplateIcon/AddTemplateIcon';
import { CPEConvertIcon } from '../../svgs/CPEConvertIcon/CPEConvertIcon';
import { OrderSetsIcon } from '../../svgs/OrderSetsIcon/OrderSetsIcon';

// disable until the Order Sets feature is implemented
const disableOS = true;
const yPadding = '22px';
const boxSize = '36px';

export const EllipseMenu = () => {
  const navigation = useNavigation();

  return (
    <ButtonGroup
      size='sm'
      isAttached
      variant='outline'
      borderColor='secondary'
      width={disableOS ? '94px' : '140px'}>
      <Tooltip label='Apply Template'>
        <IconButton
          height='45px'
          paddingY={yPadding}
          aria-label='Apply Template'
          icon={<AddTemplateIcon mt='-1.5px' mr='-4px' boxSize={boxSize} />}
          borderColor='brand.500'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          borderLeftRadius='50%'
          w='100%'
          onClick={() => {
            navigation.toEncounterTemplatePage();
          }}
        />
      </Tooltip>
      <Tooltip label='Convert to CPE'>
        <IconButton
          height='45px'
          aria-label='Convert to CPE'
          icon={<CPEConvertIcon boxSize={'32px'} />}
          paddingY={yPadding}
          borderColor='brand.500'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRightRadius={disableOS ? '50%' : '0'}
          w='100%'
          onClick={() => {
            navigation.toEncounterTemplatePage({ q: 'CPE, Aylo Medicare, Aylo PEDI' });
          }}
        />
      </Tooltip>
      {!disableOS && (
        <Tooltip label='Order Sets'>
          <IconButton
            height='45px'
            borderColor='brand.500'
            aria-label='Order Sets'
            icon={<OrderSetsIcon boxSize={boxSize} />}
            borderRightRadius='50%'
            paddingRight='5px'
            paddingY={yPadding}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );
};
