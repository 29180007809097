import { useEffect } from 'react';
import { useGetMedicationsByEncounterQuery } from '../../../../__generated__/graphql';
import { useEncounter, useMAWorkflow, usePatient } from '../../../../hooks';
import { medicationsSelectors, uiActions, useAppDispatch } from '../../../../state-management';
import { convertMedsDataToUiData } from '../utils/convert';
import _ from 'lodash';

// the current medications query so we can pass them into both the submit handler hook
// and the MedicationContent component
export function useMedicationState() {
  const { patientId = 0 } = usePatient();
  const { getHasLoadedInitialData, maWorkFlowState } = useMAWorkflow();
  const hasLoadedInitialData = getHasLoadedInitialData('medications');
  const { activeEncounterID } = useEncounter();
  const dispatch = useAppDispatch();

  const medsByEncounterQuery = useGetMedicationsByEncounterQuery({
    variables: {
      patientId,
      encounterId: activeEncounterID,
    },
    skip: !patientId || !activeEncounterID,
  });

  const medications = medsByEncounterQuery.data?.getMedicationsByPatientByEncounter ?? [];
  const loaded = !medsByEncounterQuery.loading;
  const hasErrors = medsByEncounterQuery.error;

  // convert the medication query data into ui data and add it to the store
  useEffect(() => {
    const queriesAreValid = loaded && !hasErrors;
    if (!queriesAreValid || hasLoadedInitialData) return;
    dispatch(uiActions.setHasLoadedInitialData('medications'));
    if (medications.length) {
      const meds = medications?.map((m) => ({
        ...m,
        sourceEncounterId: m.sourceEncounterId ?? undefined,
      }));

      const allMeds = convertMedsDataToUiData(meds);

      const deduped = uiActions.setAddedMedications(_.uniqBy(allMeds, (data) => data.rxId));

      dispatch(deduped);
    }
  }, [medications, loaded, hasErrors, hasLoadedInitialData]);

  const addedMedications = medicationsSelectors.selectAll(
    maWorkFlowState.medications.addedMedications,
  );

  return {
    medsQuery: medsByEncounterQuery,
    medsByEncounterQuery,
    medications,
    activeEncounterID,
    patientId,
    addedMedications,
  };
}
