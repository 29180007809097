import { Circle, Text } from '@chakra-ui/react';

export function CircleNumber({ value }: { value: number }) {
  return (
    <Circle aspectRatio={1} bg='primary' size={6}>
      <Text variant='h6-b' fontSize='xs' color='white'>
        {value}
      </Text>
    </Circle>
  );
}
