import { Text } from '../typography';
import { Button } from '../form';
import { Box, Center } from '../layout';
import { FlatList, ModalBody, ModalCloseButton, ModalHeader, ModalFooter } from '../general';
import { type LocationUI } from '../../types';
import { locationActions, useAppDispatch } from '../../state-management';
import { useGetLocationsQuery } from '../../__generated__/graphql';

interface LocationsProps {
  onCloseModal: () => void;
}

function Locations({ onCloseModal }: LocationsProps) {
  const dispatch = useAppDispatch();
  const { data } = useGetLocationsQuery();
  const locations = data?.getLocations?.locations;

  function renderItem({ item }: { item: LocationUI }) {
    return (
      <Box mt='sm'>
        <Center justifyContent='space-between'>
          <Text>{item.locationName}</Text>
          <Button
            variant='outline'
            onClick={() => {
              dispatch(
                locationActions.onLocationChange({
                  selectedLocationID: item.locationID,
                }),
              );
              if (onCloseModal) {
                onCloseModal();
              }
            }}
          >
            Select
          </Button>
        </Center>
      </Box>
    );
  }

  return (
    <FlatList
      data={locations || undefined}
      renderItem={renderItem}
      keyExtractor={(item: LocationUI) => item.locationID.toString()}
    />
  );
}

export function LocationSelectorModal(props: any) {
  return (
    <>
      <ModalHeader>Pick Location</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Locations {...props} />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
}
