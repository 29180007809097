import { Box, type BoxProps, Flex, HStack, VStack } from '../../layout';
import { Text } from '../../typography';
import { Tag } from '../Tag';

interface PercentBarProps extends Omit<BoxProps, 'children'> {
  value: number;
}

export function PercentBar({ value }: PercentBarProps) {
  const barHeight: string = '20px';
  const borderRadius: string = barHeight;

  const flexGrowValue: number = Math.round(value); // round to the nearest integer so we don't have weird looking tiny slices of %

  return (
    <VStack alignItems='stretch' w='full'>
      <HStack>
        <Flex flexBasis={`${value}%`} justifyContent='flex-end'>
          <Tag borderRadius='full' variant='solid' bgColor='primary'>
            <Text variant='body2-b'>{value}%</Text>
          </Tag>
        </Flex>

        <Box flexBasis={`${100 - value}%`}></Box>
      </HStack>

      <HStack w='full' spacing={0} borderRadius={borderRadius} overflow='hidden'>
        <Box flexGrow={flexGrowValue} backgroundColor='primary' height={barHeight}></Box>

        <Box flexGrow={100 - flexGrowValue} backgroundColor='secondary' height={barHeight}></Box>
      </HStack>
    </VStack>
  );
}
