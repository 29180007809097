import { Text, DrawerBody, DrawerContent, Card, WaitUntilLoaded } from '../../components';
import { Button, DrawerFooter, HStack } from '@chakra-ui/react';
import React from 'react';
import { type EncounterTemplateMetaDataUI } from '../../types';
import { useGetEncounterTemplatePreviewQuery } from '../../__generated__/graphql';

type Props = {
  templateMetaData?: EncounterTemplateMetaDataUI;
  onClose: () => void;
  onUse: (id: number) => void;
  isApplyingTemplate: boolean;
};

export const EncounterTemplatePreview = ({
  templateMetaData,
  onUse,
  onClose,
  isApplyingTemplate,
}: Props) => {
  const { data: resp, loading: loadingHtml } = useGetEncounterTemplatePreviewQuery({
    variables: {
      templateId: templateMetaData?.templateId || -1,
    },
  });
  const html = resp?.getEncounterTemplatePreview.html || '';
  return (
    <DrawerContent overflow='hidden'>
      <DrawerBody overflow='hidden'>
        <Text variant='h6-b' mb='md'>
          {templateMetaData?.name}
        </Text>
        <Text color='brand.500' mb='md'>
          Template Preview
        </Text>
        <Card mb='md' height='90%'>
          <WaitUntilLoaded loading={loadingHtml}>
            <iframe srcDoc={html} style={{ height: '100%', width: '100%' }}></iframe>
          </WaitUntilLoaded>
        </Card>
      </DrawerBody>
      <DrawerFooter height={65}>
        <HStack
          position='fixed'
          bottom='8px'
          w='90%'
          left='5%'
          height='40px'
          backgroundColor='white'
          borderRadius='8px'>
          <Button
            w='full'
            size='lg'
            backgroundColor='secondary'
            onClick={() => {
              onClose();
            }}
            isDisabled={isApplyingTemplate}>
            Close Preview
          </Button>
          <Button
            w='full'
            size='lg'
            onClick={() => {
              onUse(templateMetaData?.templateId || -1);
            }}
            isLoading={isApplyingTemplate}
            loadingText='Applying Template..'>
            Use Template
          </Button>
        </HStack>
      </DrawerFooter>
    </DrawerContent>
  );
};
