import { isEmpty } from 'lodash';
import {
  Center,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WaitUntilLoaded,
} from '../../../components';
import { usePatient } from '../../../hooks';
import { Gender, type Vital, type VitalValue } from '../../../__generated__/graphql';
import { formatISODate } from '../../../utils';

function getVitalValue(vitalRecord?: VitalValue | null) {
  if (!vitalRecord?.value) return '-';
  // a little formatting to make values with quallifiers look nice
  if (vitalRecord.value.includes(':')) return vitalRecord.value.split(':').join(': ');
  return vitalRecord.value;
}

export function VitalsHistoryPanel({ vitals, isLoading }: { vitals: Vital[]; isLoading: boolean }) {
  const { patient } = usePatient();
  const isFemale = patient?.gender === Gender.Female;
  return (
    <WaitUntilLoaded loading={isLoading}>
      {isEmpty(vitals) ? (
        <Center>No vitals history</Center>
      ) : (
        <TableContainer>
          <Table variant='simple' size='md'>
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Temp</Th>
                <Th>Height (in.)</Th>
                <Th>Blood Pressure</Th>
                <Th>BMI</Th>
                <Th>Pain Scale</Th>
                <Th>Heart Rate</Th>
                <Th>Weight (lbs)</Th>
                <Th>Waist Circum. (in.)</Th>
                <Th>O2 Sat (%)</Th>
                {isFemale && <Th>LMP</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {vitals?.map((item, i) => {
                const subVitals = item.vitals;
                return (
                  // add the inex to the key since there can be
                  // multiple responses with the same encounter
                  <Tr key={`${item.encounter.toString()}-${i}`}>
                    <Td textAlign='left'>{formatISODate(item.date)}</Td>
                    <Td>{getVitalValue(subVitals?.TEMP)}</Td>
                    <Td>{getVitalValue(subVitals?.HEIGHT)}</Td>
                    <Td>{getVitalValue(subVitals?.BLOOD_PRESS)}</Td>
                    <Td>{getVitalValue(subVitals?.BMI)}</Td>
                    <Td>{getVitalValue(subVitals?.PAIN)}</Td>
                    <Td>{getVitalValue(subVitals?.HEART_RATE)}</Td>
                    <Td>{getVitalValue(subVitals?.WEIGHT)}</Td>
                    <Td>{getVitalValue(subVitals?.WAIST_CIRC)}</Td>
                    <Td>{getVitalValue(subVitals?.O2)}</Td>
                    {isFemale && <Td>{getVitalValue(subVitals?.LMP)}</Td>}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </WaitUntilLoaded>
  );
}
