import { Text } from '../typography';
import { Button } from '../form';
import { Box, Center, WaitUntilLoaded } from '../layout';
import { FlatList, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '../general';
import { providerActions, useAppDispatch } from '../../state-management';
import { type Provider, useGetProvidersQuery } from '../../__generated__/graphql';

interface ProvidersProps {
  onCloseModal: () => void;
}

function sortDesc(a: Pick<Provider, 'lastName'>, b: Pick<Provider, 'lastName'>) {
  const aVal = a?.lastName || 'z';
  const bVal = b?.lastName || 'z';

  if (aVal > bVal) {
    return 1;
  }
  if (aVal < bVal) {
    return -1;
  }
  return 0;
}

function Providers({ onCloseModal }: ProvidersProps) {
  const dispatch = useAppDispatch();
  const { data, loading: isLoading, error } = useGetProvidersQuery();
  const rawProviders = data?.getProviders?.providers;
  let sortedProviders;
  if (rawProviders != null) {
    sortedProviders = [...rawProviders]?.sort(sortDesc);
  }

  let providers;
  if (sortedProviders != null) {
    providers = [{ providerID: 'ALL_PROVIDERS', lastName: 'All Providers' }, ...sortedProviders];
  }

  function renderItem({ item }: { item: Provider }) {
    return (
      <Box mt='sm'>
        <Center justifyContent='space-between'>
          <Text>
            {item.lastName}, {item.firstName}
          </Text>
          <Button
            variant='outline'
            onClick={() => {
              dispatch(
                providerActions.setSelectedProviderIds({
                  selectedProviderIDs: [item.providerID],
                }),
              );
              if (onCloseModal) {
                onCloseModal();
              }
            }}>
            Select
          </Button>
        </Center>
      </Box>
    );
  }

  return (
    <WaitUntilLoaded
      loading={isLoading}
      showErrorMessage={!(error == null)}
      errorMessage='Cannot load providers'>
      {providers != null ? (
        <FlatList
          data={providers}
          renderItem={renderItem}
          keyExtractor={(item: Provider) => item.providerID.toString()}
        />
      ) : (
        <Text>No providers</Text>
      )}
    </WaitUntilLoaded>
  );
}

export function ProviderSelectorModal(props: any) {
  return (
    <>
      <ModalHeader>Pick Provider</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Providers {...props} />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </>
  );
}
