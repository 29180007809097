import { FamilyMemberType } from '../../../types';
import { encodeToBase64 } from '../../../utils';
import { familyMembersAdapter } from '../../../state-management';
import {
  type FamilyHistory,
  type FamilyHistoryIcdCode,
  type FamilyMembers,
} from '../../../__generated__/graphql';

export enum FamilyMemberStatusOption {
  Alive = 'Alive',
  Deceased = 'Deceased',
  Unknown = 'Unknown',
}

export const orderedMemeberTypes = {
  [FamilyMemberType.Father.toString()]: 0,
  [FamilyMemberType.Mother.toString()]: 1,
  [FamilyMemberType.Siblings.toString()]: 2,
  [FamilyMemberType.Children.toString()]: 3,
  NA: 4,
};

export function icdCodeSorter(
  a: Pick<FamilyHistoryIcdCode, 'displayIndex'>,
  b: Pick<FamilyHistoryIcdCode, 'displayIndex'>,
) {
  return (a.displayIndex || Number.MAX_VALUE) - (b.displayIndex || Number.MAX_VALUE);
}

export function transformFormFamilyHistoryToFamilyHistoryUI(
  familyHistory?: FamilyHistory | null,
): FamilyHistoryUI | undefined {
  if (familyHistory) {
    // Add Default FMSC if do not exist
    const existingFamilyMembers = familyHistory.familyMembers || [];
    const accumulatedFamilyMembers: FamilyMembers[] = [];

    // Iterate Through Default Family Members & add when needed
    defaultFamilyMemberNames.forEach((name) => {
      const existingFamilyMember = existingFamilyMembers.find((fm) => fm.familyMemberType === name);
      accumulatedFamilyMembers.push(existingFamilyMember || createFamilyMember(name));
    });

    // Add any existing Custom Family Members
    existingFamilyMembers.forEach((fm) => {
      if (fm.familyMemberType && !defaultFamilyMemberNames.includes(fm.familyMemberType)) {
        accumulatedFamilyMembers.push(fm);
      }
    });

    const familyMembersUI: FamilyMemberUI[] = accumulatedFamilyMembers.map((familyMember) => {
      const familyMemberDiagnosisList = familyMember.familyMemberDiagnosisList?.map((d) => {
        return {
          isChecked: d.value === '1',
          icdItemId: d.icdItemId || '',
        };
      });

      const tempId = encodeToBase64(familyMember);

      return {
        ...familyMember,
        tempId,
        familyMemberDiagnosisList: familyMemberDiagnosisList || [],
      };
    });

    const initialFamilyMembers = familyMembersAdapter.upsertMany(
      familyMembersAdapter.getInitialState(),
      familyMembersUI,
    );

    const familyHistoryUI = {
      ...familyHistory,
      familyMembers: familyMembersUI,
      familyMembersHistory: initialFamilyMembers,
    };
    return familyHistoryUI;
  }
}

export const defaultFamilyMemberNames = ['Father', 'Mother', 'Siblings', 'Children'];

function createFamilyMember(type: string): FamilyMembers {
  const custom = !defaultFamilyMemberNames.includes(type);
  return {
    familyMemberType: type,
    status: FamilyMemberStatusOption.Unknown,
    yearOfBirth: '',
    age: '',
    notes: '',
    customFamilyMemberFlag: custom,
    familyMemberDiagnosisList: [],
  };
}

export const createDefaultFamilyHistoryForPatient = (patientId: number) => {
  return {
    sourcePatientId: patientId,
    numberOfBrothers: 0,
    numberOfSisters: 0,
    numberOfSons: 0,
    numberOfDaughters: 0,
    siblingHealthy: false,
    childrenHealthy: false,
    nonContributory: false,
    familyHistoryNotes: null,
    additionalNotes: null,
    familyMembers: defaultFamilyMemberNames.map((name) => createFamilyMember(name)),
  };
};
