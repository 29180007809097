import { useState } from 'react';
import {
  type AoeAnswerInput,
  type LabSubmissionInput,
  useSubmitLabsMutation,
  useAddAoeAnswersMutation,
  LabStatus,
} from '../../../__generated__/graphql';
import { useCustomToast, useModal, usePatient } from '../../../hooks';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';
import {
  isGlobalFormInputValid,
  isLabSubmissionInputValid,
} from './lab-collection-form-modal.helpers';
import { formatDate } from '../../../utils';
import { isEmpty } from 'lodash';

export function useLabCollectionForm() {
  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  const globalLabForm = useAppSelector((state) => state.worklistTodo.labGlobalForm) ?? {};
  const { collectionDateTime: labDate } = globalLabForm;
  const { showModal } = useModal();

  const formValues = useAppSelector((state) => state.worklistTodo.labForms);
  const labSubmissionInputs = formValues
    ?.map((v) => v?.labSubmissionInputs)
    .flat()
    .map((v) => {
      return v as LabSubmissionInput;
    })
    .filter((v): v is NonNullable<typeof v> => isLabSubmissionInputValid(v))
    // modify notes
    .map((v) => {
      // Use format: userlastname, userfirstname MM/dd/YYYY hhh:mm;ss AM timezone > note goes here [04/29/2024 9:37:49 AM CDT > my notes]
      const date = new Date();
      const time = `${formatDate(date.toISOString())} ${date.toLocaleTimeString(undefined, {
        timeZoneName: 'short',
      })}`;
      const notePrefix = `${globalLabForm.labReviewedByLastName}, ${globalLabForm.labReviewedByFirstName} ${time} > `;

      const comments = `${notePrefix}${v?.comments}`;
      const clinicalInformation = `${notePrefix}${v?.clinicalInformation}`;

      return {
        ...v,
        comments,
        clinicalInformation,
      };
    });
  const hasAtLeastOneValidLabSubmissionInput = labSubmissionInputs?.some((v) => {
    return isLabSubmissionInputValid(v);
  });

  const isGlobalFormValid = isGlobalFormInputValid(globalLabForm);

  const [submitState, setSubmitState] = useState<{
    isLoading?: boolean;
    isSubmitError?: boolean;
    isSubmitSuccess?: boolean;
  }>({ isLoading: false, isSubmitError: false, isSubmitSuccess: false });

  const { patientId } = usePatient();

  const [submitLabs] = useSubmitLabsMutation();
  const [addAOEAnswers] = useAddAoeAnswersMutation();

  function handleUpdateForm(updateValues: LabItem) {
    if (updateValues) {
      dispatch(worklistTodoActions.updateLabItem(updateValues));
    }
  }

  function getLabItem(id: LabItemId) {
    return formValues.find((v) => v.orderableReportID === id);
  }

  function handleUpdateGlobalLabForm(form: LabGlobalForm) {
    dispatch(worklistTodoActions.updateGlobalLabForm(form));
  }

  function handleBatchUpdateLabItems(updatedLabItems: LabItem[]) {
    dispatch(worklistTodoActions.batchUpdateLabItems(updatedLabItems));
  }

  function handleApply() {
    // Take the global form items and apply them to the selected lab items

    // Remove the UI only fields from the global form, before applying to the lab items
    const {
      sendTolabCompanyName,
      labReviewedByUserId,
      labReviewedByFirstName,
      labReviewedByLastName,
      ...globalForm
    } = globalLabForm;
    // Prepare each lab item to be updated
    const updatedLabItems = formValues?.map((labItem) => {
      return {
        ...labItem,
        // Apply the global form to the selected lab items only
        labSubmissionInputs: labItem.isSelected
          ? {
              ...labItem.labSubmissionInputs,
              reportId: labItem.orderableReportID, // TODO: set this where this is initialized
              specimenCollected: true,
              // Update the labSubmissionInputs with the global form
              ...globalForm,
              comments: globalForm.comments ?? '',
              clinicalInformation: globalForm.clinicalInformation ?? '',
              specimenSource: globalForm.specimenSource ?? '',
              actualFasting: globalForm.actualFasting ?? 2,
            }
          : labItem.labSubmissionInputs,
      };
    });

    handleBatchUpdateLabItems(updatedLabItems);
    toast({
      id: 'apply-lab-form',
      title: 'Success',
      description: 'Applied lab form to selected lab items',
      status: 'success',
    });
  }

  function handleToggleLabItemSelection(id: LabItemId) {
    dispatch(worklistTodoActions.toggleLabItemSelection(id));
  }

  function handleToggleSelectAllLabItems() {
    // Set every lab item isSelected state to true
    dispatch(worklistTodoActions.toggleSelectAllLabItems());
  }

  function handleUpdateAOEAnswer(
    val: Partial<AoeAnswerInput> & Pick<AoeAnswerInput, 'reportId' | 'questionId'>,
  ) {
    dispatch(worklistTodoActions.updateAOEAnswer(val));
  }

  async function handleSubmit() {
    const aoeAnswers: AoeAnswerInput[] = formValues
      .map((v) => {
        if (v?.aoeAnswers) {
          return v.aoeAnswers.map((v) => {
            const {
              questionId,
              answer,
              answerCode,
              answeredByUserId,
              answeredDateTime,
              labCompanyId,
              reportId,
            } = v;

            const canSubmit =
              !!questionId &&
              !!answer &&
              !!answerCode &&
              !!answeredByUserId &&
              !!answeredDateTime &&
              !!labCompanyId &&
              !!reportId;

            if (canSubmit) {
              const item: AoeAnswerInput = {
                questionId,
                answer,
                answerCode,
                answeredByUserId,
                answeredDateTime,
                labCompanyId,
                reportId,
              };

              return item;
            }

            return undefined;
          });
        }

        return undefined;
      })
      .flat()
      .filter((v): v is NonNullable<typeof v> => v !== undefined); // Remove all undefineds in the array

    try {
      // show loader
      setSubmitState({ isLoading: true });
      const result = await addAOEAnswers({
        variables: {
          sourcePatientId: patientId,
          answers: aoeAnswers,
        },
        onError: (error) => {
          toast({
            id: 'aoe-submit-error',
            title: 'Error',
            description: `Error saving AOE answers\n${error.message}`,
            status: 'error',
          });

          setSubmitState({
            isLoading: false,
            isSubmitSuccess: false,
            isSubmitError: true,
          });
        },
      });

      const hasAOEErrors = !!(result?.errors && result.errors.length);

      if (!hasAOEErrors) {
        // Submit labs, AOE answers, and lab instructions
        await submitLabs({
          variables: {
            labSubmissions: labSubmissionInputs,
          },
          onError: (error) => {
            toast({
              id: 'lab-collection-error',
              title: 'Error',
              description: `Error submitting labs\n${error.message}`,
              status: 'error',
            });
            setSubmitState({
              isLoading: false,
              isSubmitSuccess: false,
              isSubmitError: true,
            });
          },
          onCompleted: (res) => {
            const results = res.submitLabs;
            const submissionsWithErrors = results?.filter(
              (lab) => lab?.labStatus !== LabStatus.Submitted,
            );
            const successfulSubmissions = results?.filter(
              (lab) => lab?.labStatus === LabStatus.Submitted,
            );
            const hasLabSubmitError = !isEmpty(submissionsWithErrors);

            // Remove all successful labs from Redux
            if (successfulSubmissions?.length) {
              // Ensure report ID is not undefined
              const filteredSuccessSubmissions = successfulSubmissions.reduce(
                (acc: LabItemId[], curr) => {
                  if (curr?.reportId) {
                    acc.push(curr.reportId);
                  }
                  return acc;
                },
                [],
              );

              dispatch(worklistTodoActions.removeLabItems(filteredSuccessSubmissions));
            }

            if (hasLabSubmitError) {
              submissionsWithErrors?.forEach((lab) => {
                if (lab?.labStatus !== LabStatus.Submitted) {
                  const labName = formValues.find(
                    (v) => v.orderableReportID === lab?.reportId,
                  )?.orderableItemName;

                  toast({
                    id: `lab-collection-error-${labName}`,
                    title: 'Error',
                    description: `Error submitting ${labName}`,
                    status: 'error',
                    duration: null,
                  });
                  setSubmitState({
                    isLoading: false,
                    isSubmitSuccess: false,
                    isSubmitError: true,
                  });
                }
              });
            } else {
              // Treat all submissions as success
              toast({
                id: 'lab-collection-success',
                title: 'Success',
                description: 'Submitted successfully',
                status: 'success',
              });
              setSubmitState({
                isLoading: false,
                isSubmitSuccess: true,
                isSubmitError: false,
              });

              showModal({
                modalType: 'WorklistModal',
                chakraModalProps: { size: '6xl' },
              });
              dispatch(worklistTodoActions.markLabCollectionComplete());
            }
          },
        });
      }
    } catch (e) {
      toast({
        id: 'submit-lab-order-error',
        title: 'Error',
        status: 'error',
        description: 'Something went wrong',
        duration: null,
      });
      setSubmitState({ isLoading: false, isSubmitSuccess: false, isSubmitError: true });
    }
  }

  return {
    formValues,
    labDate,
    handleSubmit,
    handleApply,
    hasAtLeastOneValidLabSubmissionInput,
    isGlobalFormValid,
    globalLabForm,
    handleBatchUpdateLabItems,
    handleUpdateGlobalLabForm,
    handleToggleSelectAllLabItems,
    handleToggleLabItemSelection,
    handleUpdateForm,
    handleUpdateAOEAnswer,
    getLabItem,
    submitState,
    hasAtLeastOneSubmittedLabOrder: !!formValues.length,
  };
}
