import React from 'react';
import {
  IconButton,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { BsInfoCircleFill } from 'react-icons/bs';

function LabelValue({ label, value }: { label: string; value?: string | number | null }) {
  return (
    <HStack>
      <Text fontWeight={'bold'} color={'secondary'}>
        {label}:
      </Text>
      <Text fontWeight={'bold'}>{value}</Text>
    </HStack>
  );
}

export function LabInfo({ lab }: { lab: LabItem }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // TODO: Should this be fetched from OI?
  const labSubmissionInputs = lab?.labSubmissionInputs;

  return (
    <>
      <IconButton
        icon={<Icon boxSize={5} color='secondary' as={BsInfoCircleFill} />}
        aria-label='info'
        isRound
        variant={'ghost'}
        colorScheme='blue'
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{lab.orderableItemName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing='md' align='flex-start'>
              {/* <LabelValue label='Assigned To' value={''} /> */}
              <LabelValue label='Specimen Source' value={labSubmissionInputs?.specimenSource} />
              <LabelValue label='Collection Volume' value={labSubmissionInputs?.collectionVolume} />
              <LabelValue label='Collection Unit' value={labSubmissionInputs?.collectionUnit} />
              <LabelValue label='Clinical Info' value={labSubmissionInputs?.clinicalInformation} />
              {/* 
              <LabelValue label='CC Results To' value={''} />
              <LabelValue label='Pregnant' value={''} />
              <LabelValue label='Account Number' value={''} /> */}
              <LabelValue label='Comment' value={labSubmissionInputs?.comments} />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
