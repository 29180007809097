import React from 'react';

interface FlatListProps {
  data?: any[];
  renderItem: (args: any) => React.ReactNode;
  keyExtractor: (args: any) => any;
}

export function FlatList({ data, renderItem, keyExtractor }: FlatListProps) {
  return (
    <div>
      {data?.map((item, index) => (
        <div key={keyExtractor(item)}>{renderItem({ item, index })}</div>
      ))}
    </div>
  );
}
