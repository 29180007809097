import { ReactComponent as StandingIcon } from './standing.svg';
import { ReactComponent as SittingIcon } from './sitting.svg';
import { ReactComponent as SupineIcon } from './supine.svg';
import { ReactComponent as LeftIcon } from './left.svg';
import { ReactComponent as RightIcon } from './right.svg';
import { ReactComponent as RepeatIcon } from './repeat.svg';
import { BiChevronDown as ChevronDownIcon } from 'react-icons/bi';
import { IoIosAdd as AddIcon } from 'react-icons/io';
import {
  Box,
  Input,
  Text,
  CloseButton,
  List,
  ListItem,
  HStack,
  StackDivider,
  Divider,
  Select,
  IconButton,
  type TextProps,
  Flex,
  ButtonGroup,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  InputGroup,
} from '../../components';
import { type QualifierConfig, type VitalQualifier } from '../../types';
import React, { useEffect, useState } from 'react';
import { defaultQualifiers } from './util';
import _ from 'lodash';
import { type InputProps as ChakraInputProps } from '@chakra-ui/input/dist/input';
import { FormControl } from '@chakra-ui/react';
import { type VitalValidation } from '../../screens/MAWorkFlowScreen/VitalsContent/VitalValidation';

interface DropDownProps extends Omit<ChakraInputProps, 'onChange'> {
  defaultQualifier?: VitalQualifier;
  defaultPlaceholder?: string;
  qualifiers?: VitalQualifier[];
  onChange: (qualifiers: QualifierConfig[]) => void;
  savedQualifiers: QualifierConfig[];
  labelElement?: React.ReactElement;
  validationResult?: VitalValidation;
  onAddedHandler?: (value: string) => void;
}
interface DropDownItemProps extends QualifierConfig {
  onDelete: () => void;
  validationResult?: VitalValidation;
}

const valueCheck = /[^0-9/.]/;

function renderQualifierIcon(qualifier: VitalQualifier) {
  switch (qualifier) {
    case 'sitting': {
      return <SittingIcon />;
    }
    case 'standing': {
      return <StandingIcon />;
    }
    case 'supine': {
      return <SupineIcon />;
    }
    case 'left': {
      return <LeftIcon />;
    }
    case 'right': {
      return <RightIcon />;
    }
    case 'repeat': {
      return <RepeatIcon />;
    }
  }
}

const FIRST_COL_WIDTH_PIXELS = 194;
const SECOND_COL_WIDTH_PIXELS = 90;

function DropDownItem({ value, qualifier, onDelete, validationResult }: DropDownItemProps) {
  const validationForValue = validationResult?.getValidationResultWithGivenValue(value);
  return (
    <ListItem minW='xs'>
      <Box px='md' py='sm'>
        <HStack divider={<StackDivider borderColor='gray.200' />}>
          <Box w={`${FIRST_COL_WIDTH_PIXELS}px`}>
            <Flex alignItems='center'>
              <Box w='28px'>{renderQualifierIcon(qualifier)}</Box>
              <Flex justifyContent='space-between' w='full'>
                <Text fontSize='14px'>{qualifier}</Text>
              </Flex>
            </Flex>
          </Box>
          <Box w={`${SECOND_COL_WIDTH_PIXELS}px`}>
            <Text
              textAlign='center'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              color={validationForValue ? validationForValue.color : 'none'}
              borderRadius='4px'
              overflow='hidden'>
              {value}
            </Text>
          </Box>
          <Box>
            <CloseButton
              onClick={() => {
                onDelete();
              }}
            />
          </Box>
        </HStack>
      </Box>
      <Divider borderColor='gray.200' />
    </ListItem>
  );
}

function DropDownHeaderText(props: TextProps) {
  return (
    <Text
      {...props}
      color='secondary'
      fontSize='xs'
      textTransform='uppercase'
      fontWeight='bold'
      letterSpacing='0.05em'
      textAlign='center'
    />
  );
}

const initialQualifier: QualifierConfig = {
  qualifier: '',
  value: '',
  isDefault: true,
};

export function VitalQualifierInput({
  defaultQualifier = defaultQualifiers[0],
  qualifiers = defaultQualifiers,
  defaultPlaceholder = '99',
  savedQualifiers,
  onChange,
  labelElement,
  validationResult,
  onAddedHandler,
  ...rest
}: DropDownProps) {
  const [allQualifiers, setAllQualifiers] = useState<QualifierConfig[]>([initialQualifier]);
  const addedQualifiers = allQualifiers.slice(1);

  useEffect(() => {
    if (savedQualifiers.length > 0) {
      if (savedQualifiers[0].qualifier) {
        // ensure that the first qualifier is the default qualifier
        setAllQualifiers([initialQualifier, ...savedQualifiers]);
      } else {
        setAllQualifiers(savedQualifiers);
      }
    } else {
      setAllQualifiers([initialQualifier]);
    }
  }, [savedQualifiers]);

  const [editedQualifier, setEditedQualifier] = useState<VitalQualifier>(defaultQualifier);
  const [editedValue, setEditedValue] = useState<string>('');

  function handleQualifierChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newQualifier = e.target.value as VitalQualifier;
    setEditedQualifier(newQualifier);
  }

  function handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    if (valueCheck.test(val)) return;
    setEditedValue(val);
  }

  function handleSetAllQualifiers(qualifiers: QualifierConfig[]) {
    const q = [...qualifiers];
    setAllQualifiers(q);
    const finalVitals = q.filter(({ value }) => value !== '');
    if (finalVitals.length > 0 && !_.some(finalVitals, (v) => v.isDefault)) {
      // reassign the object to avoid getting 'isDefault' is 'read-only'
      finalVitals[0] = {
        ...finalVitals[0],
        isDefault: true,
      };
    }
    onChange(finalVitals);
  }

  function handleAddQualifier() {
    if (editedValue) {
      const newQualifier = {
        isDefault: false,
        qualifier: editedQualifier,
        value: editedValue,
      };
      const newQualifiers = [...allQualifiers, newQualifier];
      handleSetAllQualifiers(newQualifiers);
      setEditedValue('');
      onAddedHandler?.(editedValue);
    }
  }

  return (
    <Popover>
      <ButtonGroup isAttached w='full' pos='relative'>
        <HStack w='100%'>
          <HStack spacing='xs' w='100%'>
            <FormControl>
              {labelElement}
              <InputGroup w={'100%'}>
                <Input
                  {...rest}
                  placeholder={defaultPlaceholder}
                  isInvalid={rest.isInvalid && !!allQualifiers[0].value}
                  value={allQualifiers[0].value ?? ''}
                  onChange={(e) => {
                    if (valueCheck.test(e.target.value)) return;
                    const updatedDefaultQualifier = { ...allQualifiers[0] };
                    updatedDefaultQualifier.value = e.target.value;
                    const otherQualifiers = allQualifiers.slice(1);
                    otherQualifiers.forEach((q, i) => {
                      // reassign the object to avoid getting 'isDefault' is 'read-only'
                      otherQualifiers[i] = {
                        ...q,
                        isDefault: false,
                      };
                    });
                    const finalQualifiers = [updatedDefaultQualifier, ...otherQualifiers];
                    handleSetAllQualifiers(finalQualifiers);
                  }}
                />
              </InputGroup>
            </FormControl>
            <PopoverTrigger>
              <IconButton
                aria-label='add qualifier'
                icon={<Icon boxSize={6} color='secondary' as={ChevronDownIcon} />}
                variant='outline'
                borderColor='gray.200'
              />
            </PopoverTrigger>
          </HStack>
        </HStack>
        {addedQualifiers.length > 0 && (
          <Box>
            <Box
              pos='absolute'
              bottom='-12px'
              right='0'
              rounded='full'
              bgColor='secondary'
              py='0px'
              px='4px'
              zIndex='dropdown'>
              <Text color='white' fontWeight='bold' fontSize='xs'>
                +{addedQualifiers.length}
              </Text>
            </Box>
          </Box>
        )}
      </ButtonGroup>

      <PopoverContent w='fit-content'>
        <Box pt='xs'>
          <HStack>
            <Box w={`${FIRST_COL_WIDTH_PIXELS + 24}px`}>
              <DropDownHeaderText>Qualifier</DropDownHeaderText>
            </Box>
            <Box w={`${SECOND_COL_WIDTH_PIXELS}px`}>
              <DropDownHeaderText>Value</DropDownHeaderText>
            </Box>
          </HStack>
          <List>
            {addedQualifiers.map((entry, idx) => {
              return (
                <DropDownItem
                  validationResult={validationResult}
                  key={`${idx}_${entry.qualifier}`}
                  {...entry}
                  onDelete={() => {
                    const newQualifiers = allQualifiers.filter((_, curIdx) => curIdx !== idx + 1);
                    handleSetAllQualifiers(newQualifiers);
                  }}
                />
              );
            })}
          </List>
          <HStack spacing='sm' p='sm'>
            <Box w={`${FIRST_COL_WIDTH_PIXELS + 14}px`}>
              <Select onChange={handleQualifierChange} value={editedQualifier}>
                {qualifiers.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </Box>
            <Box w={`${SECOND_COL_WIDTH_PIXELS + 8}px`}>
              <Input
                placeholder={defaultPlaceholder}
                value={editedValue}
                onChange={handleValueChange}
              />
            </Box>
            <IconButton
              aria-label='Add heart rate'
              colorScheme='brand'
              onClick={handleAddQualifier}
              icon={<Icon boxSize={8} color='white' as={AddIcon} />}
            />
          </HStack>
        </Box>
      </PopoverContent>
    </Popover>
  );
}
