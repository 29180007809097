import React, { useState } from 'react';
import { Box, Checkbox } from '../../../components';
import { useMAWorkflow, useToast } from '../../../hooks';
import { noop } from 'lodash';

export function NKDA() {
  const { maWorkFlowState, patientAllergyItems, onToggleNKDA } = useMAWorkflow();
  const isDisabled = !(patientAllergyItems.length === 0);
  const [showMsg, setShowMsg] = useState(false);
  useToast({
    id: 'nkda-disabled-msg',
    title: 'Note',
    description: 'Please remove all allergies to enable checkbox.',
    show: showMsg,
    status: 'info',
  });
  return (
    <Box
      onMouseDown={
        isDisabled
          ? () => {
              setShowMsg(true);
            }
          : noop
      }
      onTouchStart={
        isDisabled
          ? () => {
              setShowMsg(true);
            }
          : noop
      }
      onTouchEnd={
        isDisabled
          ? () => {
              setShowMsg(false);
            }
          : noop
      }
      onMouseUp={
        isDisabled
          ? () => {
              setShowMsg(false);
            }
          : noop
      }
    >
      <Checkbox
        isDisabled={isDisabled}
        size='lg'
        isChecked={maWorkFlowState.isNKDA}
        onChange={onToggleNKDA}
      >
        NKDA
      </Checkbox>
    </Box>
  );
}
