// import { differenceInYears, format } from "date-fns";
import { createSelector } from '@reduxjs/toolkit';
import { calculateAge, formatISODate, formatUTCDate } from '../utils';
import { type EditedOrder } from './appointmentsSlice';
import { type RootState } from './hooks';

export { medicalHistorySelectors } from './uiSlice';

// APPOINTMENTS / ENCOUNTERS SELECTORS

// Slice root selectors
const getAppointments = (state: RootState) => state?.appointments;
const getLogin = (state: RootState) => state?.login;
const getLocation = (state: RootState) => state?.location;
const getNote = (state: RootState) => state?.note;
const getEncounterFilter = (state: RootState) => state?.encounterFilter;
const getOpenTEsFilter = (state: RootState) => state?.openTEsFilter;
const getPatientSearch = (state: RootState) => state?.searchedPatients;
const getProvider = (state: RootState) => state?.provider;
const getProviderWorkflow = (state: RootState) => state?.providerWorkflow;
const getOrderLabsDI = (state: RootState) => state?.orderLabsDI;
const getUi = (state: RootState) => state?.ui;

// TODO: Might break into its own slice for new alerts. Ordered alerts will still be associated with past appointment.
// export const getNewAlerts = (state: RootState) => state?.appointments?.alerts;
export const getEditedOrders = (state: RootState) => state?.appointments?.editedOrders;
export const getAcceptedOrders = (state: RootState) =>
  Object.values(state?.appointments?.editedOrders).filter(
    (editedOrder) => editedOrder.orderType === 'ACCEPTED',
  );
export const getDeclinedOrders = (state: RootState) =>
  Object.values(state?.appointments?.editedOrders).filter(
    (editedOrder) => editedOrder.orderType === 'DECLINED',
  );

// Composed Selectors
export const getVitalsPanelState = (state: RootState) => state?.ui.orderFlowNavigation.vitals;
export const getLastSavedVitalsState = (state: RootState) =>
  state?.ui.orderFlowNavigation.lastSaved.vitals;

// ENCOUNTER SELECTORS
const getActiveEncounterID = createSelector(
  getAppointments,
  (appointments) => appointments?.activeAppointment?.encounterID,
);

const getActiveAppointment = createSelector(
  getAppointments,
  (appointments) => appointments.activeAppointment,
);

export const getActiveProviderID = createSelector(
  getActiveAppointment,
  (appointment) => appointment?.providerID,
);

export const encounterSelectors = {
  getActiveEncounterID,
  getActiveAppointment,
};

// EditedOrder selectors (TODO: Transform to RTKs Adapter https://redux-toolkit.js.org/api/createEntityAdapter)
const _editedOrdersSelectAll = createSelector(getEditedOrders, (editedOrders) =>
  Object.values(editedOrders),
);
export const getOrderByAlertId = createSelector(
  [getEditedOrders, (_: RootState, alertID: number) => alertID],
  (editedOrders, alertID) => editedOrders?.[alertID],
);
const _getDiagnosisByAlertId = createSelector([getOrderByAlertId], (order) => order?.diagnosis);
export const getDiagnosisByAlertId = (alertId: number) => (state: RootState) =>
  _getDiagnosisByAlertId(state, alertId);
// EDITED ORDERS SELECTORS
export const editedOrdersSelectors = {
  selectAll: _editedOrdersSelectAll,
  selectById:
    (alertId: number) =>
    (state: RootState): EditedOrder =>
      getOrderByAlertId(state, alertId),
};

// PATIENT SELECTORS. TODO: Remove the unused!
export const getActivePatient = createSelector(
  getActiveAppointment,
  (activeAppointment) => activeAppointment?.patient,
);
export const getActivePatientID = createSelector(
  getAppointments,
  (appointments) => appointments?.activeAppointment?.patient?.patientID,
);

export const getPatientAllergies = createSelector(
  getActivePatient,
  (patient) => patient?.allergies,
);

// E.g. "John Doe 123456"
export const getPatientFullNameAndID = createSelector(
  getActivePatient,
  (patient) => `${patient?.firstName} ${patient?.lastName} ${patient?.patientID}`,
);
// John Doe
export const getPatientFullName = createSelector(
  getActivePatient,
  (patient) => `${patient?.firstName} ${patient?.lastName}`,
);
// id
export const getPatientId = createSelector(getActivePatient, (patient) => `${patient?.patientID}`);
// "41 F 01/01/1980"
export const getAgeGenderBirthday = createSelector(
  getActivePatient,
  (patient) =>
    `${patient?.dateOfBirth && calculateAge(patient.dateOfBirth)} ${patient?.gender} ${
      patient?.dateOfBirth && formatUTCDate(patient.dateOfBirth)
    }`,
);
// "M" | "F"
export const getGender = createSelector(getActivePatient, (patient) => patient?.gender);

// 01/01/1
export const getBirthday = createSelector(
  getActivePatient,
  (patient) => patient?.dateOfBirth && formatISODate(patient.dateOfBirth),
);

// "Stomach Ache"
export const getVisitReason = createSelector(
  getActiveAppointment,
  (appointment) => `${appointment?.visitReason}`,
);
// "xx/xx/xxxx xx:xx"
export const getVisitDate = createSelector(
  getActiveAppointment,
  (appointment) => appointment?.startTime,
);

// John Doe will be "JD"
export const getPatientInitials = createSelector(
  getActivePatient,
  (patient) => `${patient?.firstName[0]}${patient?.lastName[0]}`,
);

// export const getAlertById = createSelector(
//   [getNewAlerts, (_: RootState, alertID: string) => alertID],
//   (alerts, alertID) => alerts?.find((alert) => alert.alertId === alertID)
// );

// LOGIN SELECTORS
export const loginSelectors = {
  getUsername: createSelector(getLogin, (login) => login.username),
  getUser: createSelector(getLogin, (login) => login.user),
  isSignedIn: createSelector(
    getLogin,
    // (login) => login.status === "signedIn" || !!login.user
    (login) => !(login.user == null),
  ),
  isNewUser: createSelector(getLogin, (login) => login.isNewUser),
};

// LOCATION SELECTOR
export const getSelectedLocationID = createSelector(
  getLocation,
  (location) => location?.selectedLocationID,
);

// PROVIDER SELECTOR

export const getSelectedProviderIDs = createSelector(
  getProvider,
  (provider) => provider?.selectedProviderIDs,
);

// UI SELECTORS
export const getPatientSearchScreenActiveTab = createSelector(
  getUi,
  (ui) => ui.patientSearchScreen.currentTab,
);

export const getPatientSearchScreenProviderIds = createSelector(
  getUi,
  (ui) => ui.patientSearchScreen.providerIds,
);

export const getOpenDrawer = createSelector(getUi, (ui) => ui.orderFlowNavigation.openDrawer);
export const getDrawerLocation = createSelector(
  getUi,
  (ui) => ui.orderFlowNavigation.drawerLocation,
);

// ENCOUNTER FILTER SELECTOR
export const getEncounterFilterState = createSelector(
  getEncounterFilter,
  (encounterFilter) => encounterFilter,
);

// OPEN TES FILTER SELECTOR
export const getOpenTEsFilterState = createSelector(
  getOpenTEsFilter,
  (openTEsFilter) => openTEsFilter,
);

// PATIENT SEARCH SELECTOR
export const getPatientSearchState = createSelector(
  getPatientSearch,
  (patientSearchState) => patientSearchState,
);
export const getOrderFlow = createSelector(getUi, (ui) => ui.orderFlowNavigation);

// NOTE SELECTOR
export const getProviderEncounterState = createSelector(getNote, (note) => note);

// PROVIDER WORKFLOW SELECTORS
export const getSelectedTab = createSelector(
  getProviderWorkflow,
  (providerWorkflow) => providerWorkflow?.selectedTab,
);
export const getEncounterSort = createSelector(
  getProviderWorkflow,
  (providerWorkflow) => providerWorkflow?.encounterSort,
);

// ORDER LABS/DI SELECTOR
export const getLabsDI = createSelector(getOrderLabsDI, (labsDI) => labsDI);
