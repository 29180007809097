import _ from 'lodash';
import { type MedicationLookup } from '../../../../__generated__/graphql';
import { useMAWorkflow, useModal } from '../../../../hooks';
import { medicationsSelectors, uiActions, useAppDispatch } from '../../../../state-management';
import { useMedicationState } from './useMedicationState';
import { v4 } from 'uuid';

export function useUpdateMedItems() {
  const dispatch = useAppDispatch();
  const medicationState = useMedicationState();
  const { maWorkFlowState } = useMAWorkflow();
  const addedMedications = medicationsSelectors.selectAll(
    maWorkFlowState.medications.addedMedications,
  );
  const { showModal } = useModal();

  return {
    handleUpdateAllMedicationStatus(medications: MedicationItemUI[], status: MedStatus) {
      if (medicationState.activeEncounterID && medicationState.patientId) {
        const meds = medications.map((med) => {
          return {
            ...med,
            newStatus: status,
          };
        });
        meds.forEach((med) => {
          dispatch(uiActions.setEditedMedication(med));
        });
      }
    },

    handleSetEditedMedication(values: MedicationItemUI) {
      if (values) {
        dispatch(uiActions.setEditedMedication(values));
      }
    },

    // used for forcing a rerender after refetching the data
    // since refetching the data does not update the state
    handleResetAddedMedications() {
      const added = medicationState.addedMedications.map((med) => ({
        ...med,
        status: med.newStatus,
      }));
      dispatch(uiActions.setAddedMedications(added));
    },

    handleRemoveMedication(id: string) {
      dispatch(uiActions.removeMedication(id));
    },

    handleRemoveMedications(ids: string[]) {
      dispatch(uiActions.removeMedications(ids));
    },

    handleClickEditMedication(id: string) {
      dispatch(uiActions.startEditMedication(id));
      showModal({
        modalType: 'EditMedicationsModal',
        chakraModalProps: { size: 'full' },
      });
    },

    handleAddPreviousEncounterMeds(
      _medsToAdd: MedicationItemUI[] | MedicationItemUI,
      newStatus: string,
    ) {
      const medsToAdd = Array.isArray(_medsToAdd) ? _medsToAdd : [_medsToAdd];
      const updated = medsToAdd.map((med) => ({
        ...med,
        status: newStatus,
        newStatus,
      }));

      const combined = [...updated, ...addedMedications];
      const unique = _.uniqBy(combined, (data) => data.rxId);
      dispatch(uiActions.setAddedMedications(unique));
    },

    handleOptionSelect(
      addedMedications: MedicationItemUI[],
      callback: () => void,
      option?: DropdownOption<MedicationLookup> | null,
    ) {
      const m = option?.entity;
      if (m) {
        const newMedication: MedicationItemUI = {
          id: v4(),
          drugNameId: m.drugNameID,
          medicationItemId: m.rxid,
          medicationItem: m.name,
          medicationStrength: m.strength,
          medicationFormulation: m.formulation,
          medicationTake: m.take,
          medicationRoute: m.route,
          rxId: m.rxid,
          medicationFrequency: m.frequency,
          medicationDuration: m.duration?.toString() || null, // TODO: Change type to match in API
          medicationDispense: m.dispense?.toString() || null, // TODO: Change type to match in API

          status: 'Taking', // Default to Taking on select
          newStatus: 'Taking',
        };

        dispatch(uiActions.setAddedMedications([newMedication, ...addedMedications]));
        callback();
      }
    },

    handleUpsertMeds(values: MedicationItemUI[]) {
      dispatch(uiActions.upsertEditedMedication(values));
    },
  };
}
