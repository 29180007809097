import React, { useState } from 'react';
import { Box, HStack, useRadio, type UseRadioProps } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Flex } from '../../layout';

type RadioOption = {
  name: string;
  value: string;
};

type CustomRadioGroupProps = {
  options: RadioOption[];
  as: 'vertical' | 'horizontal';
  defaultValue?: string;
  onChange?: (value?: string) => void;
};

const CustomRadio: React.FC<UseRadioProps & { name: string; onDeselect?: () => void }> = (
  props,
) => {
  const { name, ...radioProps } = props;
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        onClick={() => {
          if (props.isChecked && props.onDeselect) {
            props.onDeselect();
          }
        }}
        width='23px'
        height='23px'
        borderRadius='50%'
        cursor='pointer'
        borderWidth='1px'
        boxShadow='md'
        display='flex'
        justifyContent='center'
        pt='5.5px'
        _checked={{
          bg: 'brand.500',
          color: 'white',
        }}
        _focus={{
          boxShadow: 'outline',
        }}>
        {props.isChecked && <CheckIcon boxSize={2.5} />}
      </Box>
    </Box>
  );
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
  options,
  as,
  defaultValue,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue);

  const handleChange = (value?: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  return (
    <Box>
      {as === 'vertical' ? (
        <HStack align='start'>
          {options.map((option) => {
            return (
              <Flex key={option.value} flexDirection='column' alignItems='center'>
                <Box mb='1px'>{option.name}</Box>
                <CustomRadio
                  isChecked={selectedValue === option.value}
                  name={option.name}
                  onChange={() => {
                    handleChange(option.value);
                  }}
                  onDeselect={() => {
                    // if I set it immediately, the `onChagne` fun triggers.
                    setTimeout(() => {
                      handleChange(undefined);
                    }, 1);
                  }}
                />
              </Flex>
            );
          })}
        </HStack>
      ) : (
        <HStack>
          {options.map((option) => {
            return (
              <Box key={option.value} display='flex' alignItems='center'>
                <Box mr='sm'>{option.name}</Box>
                <CustomRadio
                  isChecked={selectedValue === option.value}
                  name={option.name}
                  onChange={() => {
                    handleChange(option.value);
                  }}
                />
              </Box>
            );
          })}
        </HStack>
      )}
    </Box>
  );
};

export default CustomRadioGroup;
