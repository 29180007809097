import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useMAWorkflow, useModal, useToast } from '../../../hooks';
import { surgeriesSelectors, uiActions, useAppDispatch } from '../../../state-management';
import { ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { useForm } from 'react-hook-form';

interface FormInputs extends SurgeryUI {}

const INPUT_MAX_LEN = 5000;

enum ModalTypes {
  add = 'add',
  edit = 'edit',
}

interface SurgeryModalProps {
  type: ModalTypes;
}

export function SurgeryModal({ type }: SurgeryModalProps) {
  const dispatch = useAppDispatch();
  const { hideModal } = useModal();
  const { maWorkFlowState } = useMAWorkflow();
  const { surgeries } = maWorkFlowState;
  const { editedSurgeryId } = surgeries;

  const editedSurgery = surgeriesSelectors.selectById(
    surgeries.addedSurgeries,
    editedSurgeryId || '',
  );

  const isSurgeryModalError = !editedSurgeryId || editedSurgery == null;

  useToast({
    id: 'isErrorGetSurgeryById',
    title: 'Error',
    description: 'Failed to fetch options',
    show: isSurgeryModalError,
    status: 'error',
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: editedSurgery });

  const onSubmit = handleSubmit(handleCompleteEdit);

  function handleCompleteEdit(values: FormInputs) {
    if (editedSurgeryId) {
      dispatch(uiActions.setEditedSurgery({ ...values }));
      hideModal();
    }
  }

  return (
    <>
      <ModalCloseButton />

      <ModalBody>
        <Box mb='md'>
          <Heading fontSize='xl' textAlign='center'>
            {`${type === ModalTypes.add ? 'Add' : 'Edit'} Surgery`}
          </Heading>
        </Box>
        <VStack spacing='md'>
          <Box flex='1'>
            <FormControl isInvalid={!!errors.reason}>
              <FormLabel>Surgery Name</FormLabel>

              <Input
                placeholder='Surgery Name'
                {...register('reason', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />

              <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box flex='1'>
            <FormControl isInvalid={!!errors.surgeryDate}>
              <FormLabel>Surgery Date</FormLabel>

              <Input
                placeholder='Surgery Date'
                {...register('surgeryDate', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />

              <FormErrorMessage>{errors.surgeryDate?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </ModalFooter>
    </>
  );
}
