export const getRecommendations = () => [
  {
    defaultAssessmentItemId: 474233,
    defaultIcdCode: 'Z11.59',
    defaultDiagnosisName: 'Encounter for screening for other viral diseases',
    orderableItemTypeId: 0,
    orderableItemId: 303868,
    orderableItemName: 'Hep C Screen',
    description:
      'Hepatitis C virus is a blood test that indicates if a patient has hepatitis C now or had it in the past. The test can indicate the type and severity of hepatitis C.                                                                                                 ',
    recommendationType: 'LAB',
    futureOrder: false,
  },
  {
    defaultAssessmentItemId: 470074,
    defaultIcdCode: 'Z23',
    defaultDiagnosisName: 'Encounter for immunization',
    orderableItemTypeId: 485,
    orderableItemId: 285235,
    orderableItemName: 'Immunization - Fluarix (Quad) Prefill syringe',
    description:
      'Influenza (flu) vaccines are vaccines that protect against the four influenza viruses that research indicates most common during the upcoming season. The Influenza virus (commonly known as "the flu") is a serious disease caused by a virus easily transmitted through respiratory droplets via air or contact.',
    recommendationType: 'IMMUNIZATION',
    futureOrder: false,
    orderableItemOptions: [
      {
        orderableItemId: 285235,
        orderableItemName: 'Fluarix (Quad)',
      },
      {
        orderableItemId: 518742,
        orderableItemName: 'Flucelvax (Quad)',
      },
      {
        orderableItemId: 563546,
        orderableItemName: 'Flulaval (Quad)',
      },
      {
        orderableItemId: 285239,
        orderableItemName: 'Fluzone (High Dose)',
      },
      {
        orderableItemId: 400387,
        orderableItemName: 'Fluzone (Quad)',
      },
    ],
  },
  {
    isOrderable: false,
    orderableItemTypeId: 0,
    orderableItemId: 229159,
    orderableItemName: 'Lab - Occult Blood, Fecal, IA (IFOBT, Hemoccult, Fecal Immunochemistry)',
    description:
      "The hemoccult test detects the presence of blood in a patient's stool that can be a sign of colorectal cancer.",
    recommendationType: 'LAB',
    futureOrder: false,
  },
  {
    required: true,
    orderableItemTypeId: 0,
    orderableItemId: 229160,
    orderableItemName: 'TEST REQUIRED state',
    description:
      "The hemoccult test detects the presence of blood in a patient's stool that can be a sign of colorectal cancer.",
    recommendationType: 'LAB',
    futureOrder: false,
  },
];
