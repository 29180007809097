import { useEffect } from 'react';
import {
  type ToastPosition,
  useToast as useToastChakraUI,
  type UseToastOptions,
} from '@chakra-ui/react';

interface UseToastProps extends UseToastOptions {
  id: string;
  show: boolean;
}
export function useToast({ show, ...toastProps }: UseToastProps) {
  const toast = useToastChakraUI();

  useEffect(() => {
    if (show && !toast.isActive(toastProps.id)) {
      toast({
        ...toastOptions(toastProps),
      });
    }
  }, [show]);
}

export function useCustomToast() {
  const toast = useToastChakraUI();
  return (options: Omit<UseToastProps, 'show'>) => {
    // Ensure only one toast with a particular id shows
    if (!toast.isActive(options.id)) {
      toast(toastOptions(options));
    }
  };
}

const toastOptions = (options: Omit<UseToastProps, 'show'>) => {
  return {
    position: 'top' as ToastPosition,
    duration: 2000,
    isClosable: true,
    ...options,
  };
};
