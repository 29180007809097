import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState: SidebarState = {
  sidebarContent: null,
  sidebarHeader: null,
  open: false,
  sidebarPosition: 'right',
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    moveSidebar(state, action: PayloadAction<SidebarPosition>) {
      state.sidebarPosition = action.payload;
    },
    openSidebar(state, action: PayloadAction<SidebarState>) {
      const { sidebarContent, sidebarHeader } = action.payload;
      state.sidebarContent = sidebarContent;
      state.sidebarHeader = sidebarHeader;
      state.open = true;
    },
    closeSidebar(state) {
      state.open = false;
    },
  },
});

export const sidebarActions = sidebarSlice.actions;

export default sidebarSlice.reducer;
