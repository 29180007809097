import { problems } from './problems';
import { getRecommendations } from './recommendations';
import { orderableItemHistory } from './orderableItemHistory';
import { getOrders } from './orders';
import { immunizationInjectionDetails } from './immunizationDetails';
import { immunizationInjectionLotDetails } from './immunizationInjectionLotDetails';
import { getEncounterOrders } from './encounterOrders';
import { getChiefComplaints } from './getChiefComplaints';
import { getMedicalHistory } from './getMedicalHistory';
import { getAllergyTypes } from './getAllergyTypes';
import { getAllergyReactions } from './getAllergyReactions';
import { getAllergySources } from './getAllergySources';
import { getVitals } from './getVitals';
import { getMedication } from './getMedication';
import { getMedicationList } from './getMedicationList';
// UTIL DATA

const ACTIVE_ENCOUNTER_ID = 5532833;

// API RESPONSE DATA
const getPatient = {
  patientID: 404184,
  // dateOfBirth: "1953-06-24T00:00:00.000+00:00",
  // dateOfBirth: "1960-12-14T00:00:00",
  dateOfBirth: '1960-12-14T00:00:00.000Z',
  firstName: 'Brandi',
  lastName: 'Smith',
  gender: 'Female',
  insurance: 'Some long insurance name for this going on and on',
  recommendations: getRecommendations(),
  allergies: [
    {
      allergyName: 'shellfish',
      reaction: 'dizziness',
      severity: 'Unknown',
    },
    {
      allergyName: 'Losartan Potassium',
      reaction: 'gave her the shakes',
      severity: 'Unknown',
    },
  ],
  problems,
  orderableItemHistory,
  encounters: [
    {
      encounterID: ACTIVE_ENCOUNTER_ID,
      startTime: '2021-03-19T12:00:00.000+00:00',
      endTime: '2021-03-19T12:15:00.000+00:00',
      visitReason: 'Follow Up',
      orders: getOrders(),
    },
    {
      encounterID: 5532834,
      startTime: '2021-03-19T12:00:00.000+00:00',
      endTime: '2021-03-19T12:15:00.000+00:00',
      visitReason: 'Follow Up 2',
      orders: getOrders(),
    },
  ],
};
const getLocations = {
  locations: [
    { locationID: 9, locationName: 'Texas' },
    { locationID: 11, locationName: 'Chicago' },
  ],
};

const getEncounters = {
  encounters: [
    {
      encounterID: ACTIVE_ENCOUNTER_ID,
      facilityID: 6,
      providerID: 404116,
      duration: '15 minutes (EMINEM)',
      encounterType: null,
      startTime: '2021-03-19T12:00:00.000+00:00',
      endTime: '2021-03-19T12:15:00.000+00:00',
      visitReason: 'Follow Up',
      visitStatus: 'PEN',
      patient: getPatient,
      orders: getOrders(),
    },
    {
      encounterID: ACTIVE_ENCOUNTER_ID + 1,
      facilityID: 6,
      providerID: 404116,
      duration: '15 minutes',
      encounterType: null,
      startTime: '2021-03-19T12:00:00.000+00:00',
      endTime: '2021-03-19T12:15:00.000+00:00',
      visitReason: 'Follow Up',
      visitStatus: 'PEN',
      patient: getPatient,
      orders: getOrders(),
    },
  ],
  nextToken: null,
};

const getICDCodes = {
  codes: [
    {
      name: 'hypercalcemia',
      itemID: 1,
      icdCode: '100.81',
      icd10Flag: false,
    },
    {
      name: 'hypercholesterolemia',
      itemID: 2,
      icdCode: '100.2',
      icd10Flag: false,
    },
    {
      name: 'hyperplastic colon polyp',
      itemID: 3,
      icdCode: '100.3',
      icd10Flag: false,
    },
  ],
};

export const SAMPLE_API_RESPONSE_DATA = {
  data: {
    getMedication,
    getVitals,
    getICDCodes,
    getChiefComplaints,
    getMedicalHistory,
    getPatient,
    getLocations,
    getEncounters,
    getEncounterOrders,
    getAllergyTypes,
    getAllergySources,
    getAllergyReactions,
    getImmunizationInjectionDetails: immunizationInjectionDetails,
    getImmunizationInjectionLotDetails: immunizationInjectionLotDetails,
    addOrderableImmunizationInjection: { encounterID: 123 },
    addOrderableItem: {
      assessmentItemId: 468425,
      assignedToUserEmail: 'tdale@elfp.com',
      assignedToUserID: 605664,
      encounterID: 6374528,
      facilityID: 9,
      labItemId: 230585,
      orderableItemTypeId: 0,
      orderableReportID: 10766387,
      orderDate: '2022-06-12T15:48:50.007Z',
      orderingProviderID: 569374,
      patientId: 516947,
    },

    // MEDICATIONS
    getMedicationList,
    getMedicationByDrugNameID: [
      {
        strength: '3.5 %',
        take: 'Apply 1 application as needed',
        route: 'External',
        frequency: '3',
      },
      {
        strength: '0.2-3.5 %',
        // take: "   ",
        take: 'A take',
        route: 'External',
        frequency: '',
      },
      // {
      //   strength: "3.5 %",
      //   take: "   ",
      //   route: "External",
      //   frequency: "",
      // },
    ],

    // updateEncounterVitals: {
    //   added: 0,
    //   encounterID: 6256189,
    //   errors: ["BP - Vital Item Value 90 is not within a valid range."],
    //   failed: 1,
    //   notes: "",
    //   patientID: 300974,
    // },
    getSurgeryLookups: [
      { name: 'Ankle surgery', notes: 'It went well' },
      { name: 'Hand surgery', notes: 'It went well' },
    ],
    getSurgeries: [
      {
        reason: 'Sinus Surgery -- Deviated Septum',
        surgeryDate: '',
      },
      {
        reason: 'dvs for brain',
        surgeryDate: 'july 2020',
      },
      {
        reason: 'Hernia Repair',
        surgeryDate: '2014',
      },
    ],
    getHospitalizations: [
      {
        reason: 'cardiac arrest',
        hospitalizationDate: '',
      },
    ],
    // FAMILY HISTORY
    getFamilyHistory: {
      sourcePatientId: 280197,
      numberOfBrothers: 2,
      numberOfSisters: 1,
      numberOfSons: 1,
      numberOfDaughters: 0,
      siblingHealthy: false,
      nonContributory: true,
      childrenHealthy: true,
      familyHistoryNotes: 'Some family History note',
      additionalNotes: 'Some additional note',
      familyMembers: [
        {
          familyMemberType: 'Father',
          status: 'deceased',
          yearOfBirth: '',
          age: '',
          notes: 'spinalsteosis, passed from renal failure',
          customFamilyMemberFlag: false,
          familyMemberDiagnosisList: [
            {
              value: '1',
              icdItemId: '252883',
            },
            {
              value: '1',
              icdItemId: '249058',
            },
          ],
        },
        {
          familyMemberType: 'Mother',
          status: 'alive',
          yearOfBirth: '',
          age: '',
          notes: 'hx of breast cancer,hx of cervical cancer ',
          customFamilyMemberFlag: false,
          familyMemberDiagnosisList: [
            {
              value: '1',
              icdItemId: '285404',
            },
            {
              value: '1',
              icdItemId: '248242',
            },
          ],
        },
        {
          familyMemberType: 'Siblings',
          status: 'alive',
          yearOfBirth: '',
          age: '',
          notes: 'sister-diabetes\nolder brother-diabetes',
          customFamilyMemberFlag: false,
          familyMemberDiagnosisList: [
            {
              value: '1',
              icdItemId: '249058',
            },
          ],
        },
        {
          familyMemberType: 'Children',
          status: 'alive',
          yearOfBirth: '',
          age: '',
          notes: '',
          customFamilyMemberFlag: false,
          familyMemberDiagnosisList: [],
        },
      ],
    },
    getFamilyHistoryICDCodes: [
      {
        icdCode: '401.9',
        icdDescription: 'Hypertension',
        snomedCode: '59621000',
        customName: 'HTN',
        displayIndex: 0,
        fhItemID: 285404,
      },
      {
        icdCode: '429.9',
        icdDescription: 'Unspecified heart disease',
        snomedCode: '56265001',
        customName: 'Hrt Dz',
        displayIndex: 1,
        fhItemID: 252829,
      },
      {
        icdCode: '434.91',
        icdDescription: 'Unspecified cerebral artery occlusion with cerebral infarction',
        snomedCode: '432504007',
        customName: 'CVA',
        displayIndex: 2,
        fhItemID: 252883,
      },
      {
        icdCode: '250.00',
        icdDescription: 'DM II (diabetes mellitus, type II), controlled',
        snomedCode: '73211009',
        customName: 'DM',
        displayIndex: 3,
        fhItemID: 249058,
      },
      {
        icdCode: 'V18.3',
        icdDescription: 'Family history of mixed hyperlipidemia',
        snomedCode: '281666001',
        customName: 'High Lipids',
        displayIndex: 4,
        fhItemID: 394724,
      },
      {
        icdCode: 'V17.2',
        icdDescription: 'Family history of dementia',
        snomedCode: '429961000',
        customName: 'Dementia',
        displayIndex: 5,
        fhItemID: 386175,
      },
      {
        icdCode: '310.9',
        icdDescription: 'Unspecified nonpsychotic mental disorder following organic brain damage',
        snomedCode: '192069009',
        customName: 'Mental Illness',
        displayIndex: 6,
        fhItemID: 250011,
      },
      {
        icdCode: 'V17.0',
        icdDescription: 'Family history of depression',
        snomedCode: '160329001',
        customName: 'Depression',
        displayIndex: 7,
        fhItemID: 387823,
      },
      {
        icdCode: '199.1',
        icdDescription: 'Other malignant neoplasm of unspecified site',
        snomedCode: '363346000',
        customName: 'Cancer',
        displayIndex: 8,
        fhItemID: 248242,
      },
      {
        icdCode: 'V19.8',
        icdDescription: 'Family history of autoimmune disorder',
        snomedCode: '300933005',
        customName: 'Autoimmune',
        displayIndex: 9,
        fhItemID: 334433,
      },
      {
        icdCode: 'V18.19',
        icdDescription: 'Family history of thyroid disease',
        snomedCode: '160302006',
        customName: 'Thyroid',
        displayIndex: 10,
        fhItemID: 329806,
      },
      {
        icdCode: 'V49.89',
        icdDescription: 'Other specified conditions influencing health status',
        snomedCode: '',
        customName: 'Other',
        displayIndex: 11,
        fhItemID: 264516,
      },
      {
        icdCode: 'Z80.42',
        icdDescription: 'Family hx of prostate cancer',
        snomedCode: '414205003',
        customName: 'HX CaP',
        displayIndex: 12,
        fhItemID: 474493,
      },
    ],
    getFamilyMembers: [
      {
        name: 'Children',
      },
      {
        name: 'Father',
      },
      {
        name: 'Mother',
      },
      {
        name: 'Siblings',
      },
    ],
    getFamilyHistoryItems: [
      {
        name: 'cancer, gastric',
        notes: 'gastric cancer',
      },
      {
        name: 'cancer, liver',
        notes: 'liver cancer',
      },
      {
        name: 'cancer, lung',
        notes: 'lung cancer',
      },
      {
        name: 'cancer, mouth',
        notes: 'mouth cancer',
      },
      {
        name: 'cancer, ovarian',
        notes: 'ovarian cancer',
      },
      {
        name: 'cancer, pancreatic',
        notes: 'pancreatic cancer',
      },
      {
        name: 'cancer, prostate',
        notes: 'prostate cancer',
      },
      {
        name: 'cancer, renal cell',
        notes: 'renal cell cancer',
      },
      {
        name: 'cancer, skin',
        notes: 'skin cancer',
      },
      {
        name: 'cancer, stomach',
        notes: 'stomach cancer',
      },
    ],
    // SOCIAL HISTORY
    getSocialHistoryCategories: [
      {
        socialHistoryCategoryID: 237358,
        name: 'Drugs/Alcohol:',
        socialInfoItems: [
          {
            socialInfoID: 269071,
            parentCategoryID: 237358,
            name: 'Drugs',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 1367,
                name: 'Have you used drugs other than those for medical reasons in the past 12 months?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 2,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1376,
                name: 'Heroin',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 1,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1379,
                name: 'Cocaine?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 1,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1382,
                name: 'How many months ago did you last use?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 1,
                structuredDataOptions: [
                  {
                    optionId: 3588,
                    name: '6-12 months',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3589,
                    name: '12-24 months',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3590,
                    name: 'more than 24 months',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 3959,
                name: 'Molly',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 2,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1368,
                name: 'Methamphetamine?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 3,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1369,
                name: 'Crack?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 4,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1370,
                name: 'LSD?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 5,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1371,
                name: 'Ecstacy?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 6,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1372,
                name: 'Prescription opiates?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 7,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1373,
                name: 'Marijuana?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 8,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1374,
                name: 'Ketamine?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 9,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1375,
                name: 'PCP?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 10,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1392,
                name: 'Heroin?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 11,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1394,
                name: 'Is there a minor (18 years or younger) at risk at home?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 11,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1396,
                name: 'Are you still using?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 13,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1387,
                name: 'How many months ago did you last use?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 17,
                structuredDataOptions: [
                  {
                    optionId: 3591,
                    name: '6-12 months',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3592,
                    name: '12-24 months',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3593,
                    name: 'more than 24 months',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1400,
                name: 'Have ever injected drugs?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 17,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1402,
                name: 'Are you in a treatment program?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 19,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1406,
                name: 'How many months ago did you last use?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 23,
                structuredDataOptions: [
                  {
                    optionId: 3597,
                    name: '6-12 months',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3598,
                    name: '12-24 months',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3599,
                    name: 'more than 24 months',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1409,
                name: 'Cocaine?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 26,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1367,
                shStructuredDataID: 1412,
                name: 'Heroin?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 29,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1376,
                shStructuredDataID: 1389,
                name: 'How many months ago did you last use?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 1,
                structuredDataOptions: [
                  {
                    optionId: 3594,
                    name: '6-12 months',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3595,
                    name: '12-24 months',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3596,
                    name: 'more than 24 months',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1376,
                shStructuredDataID: 1377,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 12,
                structuredDataOptions: [
                  {
                    optionId: 3582,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3583,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3584,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1379,
                shStructuredDataID: 1380,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 1,
                structuredDataOptions: [
                  {
                    optionId: 3585,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3586,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3587,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1382,
                shStructuredDataID: 1383,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 3588,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 18,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1382,
                shStructuredDataID: 1385,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 3588,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 18,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1382,
                shStructuredDataID: 1386,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 3589,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 19,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1382,
                shStructuredDataID: 1384,
                name: 'relapse prevention discussed',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 3589,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 19,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1387,
                shStructuredDataID: 1388,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 3592,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 18,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1392,
                shStructuredDataID: 1393,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 12,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1394,
                shStructuredDataID: 1395,
                name: 'How many children?',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 12,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1394,
                shStructuredDataID: 1395,
                name: 'How many children?',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 12,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1397,
                name: 'Do you want treatment?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 14,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1397,
                name: 'Do you want treatment?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 14,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1398,
                name: 'Do you want to enroll in a needle exchange/ESAP Program?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 15,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1398,
                name: 'Do you want to enroll in a needle exchange/ESAP Program?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 15,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1399,
                name: 'Do you need a prescription for clean needles?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 16,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1396,
                shStructuredDataID: 1399,
                name: 'Do you need a prescription for clean needles?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 16,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1400,
                shStructuredDataID: 1401,
                name: 'Are you currently injecting drugs?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 18,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1400,
                shStructuredDataID: 1401,
                name: 'Are you currently injecting drugs?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 18,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1403,
                name: 'Type of program:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 20,
                structuredDataOptions: [
                  {
                    optionId: 3607,
                    name: 'Detox',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3608,
                    name: 'Methadone',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3609,
                    name: 'Residential treatment',
                    displayIndex: 3,
                  },
                  {
                    optionId: 3610,
                    name: 'Outpatient',
                    displayIndex: 4,
                  },
                  {
                    optionId: 3611,
                    name: '12 Step',
                    displayIndex: 5,
                  },
                  {
                    optionId: 3612,
                    name: 'Other',
                    displayIndex: 6,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1403,
                name: 'Type of program:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 20,
                structuredDataOptions: [
                  {
                    optionId: 3607,
                    name: 'Detox',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3608,
                    name: 'Methadone',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3609,
                    name: 'Residential treatment',
                    displayIndex: 3,
                  },
                  {
                    optionId: 3610,
                    name: 'Outpatient',
                    displayIndex: 4,
                  },
                  {
                    optionId: 3611,
                    name: '12 Step',
                    displayIndex: 5,
                  },
                  {
                    optionId: 3612,
                    name: 'Other',
                    displayIndex: 6,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1404,
                name: 'Name of program:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 21,
                structuredDataOptions: [
                  {
                    optionId: 3606,
                    name: 'use notes section',
                    displayIndex: 1,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1404,
                name: 'Name of program:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 21,
                structuredDataOptions: [
                  {
                    optionId: 3606,
                    name: 'use notes section',
                    displayIndex: 1,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1405,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 22,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1402,
                shStructuredDataID: 1405,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 22,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1406,
                shStructuredDataID: 1407,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3598,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 24,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1406,
                shStructuredDataID: 1407,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3598,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 24,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1406,
                shStructuredDataID: 1408,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3597,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 25,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1406,
                shStructuredDataID: 1408,
                name: 'relapse prevention discussed?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3597,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 25,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1409,
                shStructuredDataID: 1410,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 27,
                structuredDataOptions: [
                  {
                    optionId: 3600,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3601,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3602,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1409,
                shStructuredDataID: 1410,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 27,
                structuredDataOptions: [
                  {
                    optionId: 3600,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3601,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3602,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1410,
                shStructuredDataID: 1411,
                name: 'Discussed safer injection/needle exchange?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3600,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 28,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1410,
                shStructuredDataID: 1411,
                name: 'Discussed safer injection/needle exchange?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3600,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 28,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1412,
                shStructuredDataID: 1413,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 30,
                structuredDataOptions: [
                  {
                    optionId: 3603,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3604,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3605,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1412,
                shStructuredDataID: 1413,
                name: 'Route?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 30,
                structuredDataOptions: [
                  {
                    optionId: 3603,
                    name: 'Injected',
                    displayIndex: 1,
                  },
                  {
                    optionId: 3604,
                    name: 'Intranasal',
                    displayIndex: 2,
                  },
                  {
                    optionId: 3605,
                    name: 'Smoked',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 1413,
                shStructuredDataID: 1414,
                name: 'Discussed safer injection/needle exchange?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3603,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 31,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 1413,
                shStructuredDataID: 1414,
                name: 'Discussed safer injection/needle exchange?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 3603,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 31,
                structuredDataOptions: [],
              },
            ],
          },
          {
            socialInfoID: 272677,
            parentCategoryID: 237358,
            name: 'Alcohol Screen',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2925,
                name: 'Did you have a drink containing alcohol in the past year?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 5,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2929,
                name: 'Points',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 9,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2930,
                name: 'Interpretation',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 10,
                structuredDataOptions: [
                  {
                    optionId: 13036,
                    name: 'Positive',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13037,
                    name: 'Negative',
                    displayIndex: 2,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2925,
                shStructuredDataID: 2926,
                name: 'How often did you have 6 or more drinks on one occasion in the past year?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 6,
                structuredDataOptions: [
                  {
                    optionId: 13038,
                    name: 'Never (0 point)',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13039,
                    name: 'Less than monthly (1 point)',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13040,
                    name: 'Monthly (2 points)',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13041,
                    name: 'Weekly (3 points)',
                    displayIndex: 4,
                  },
                  {
                    optionId: 13042,
                    name: 'Daily or almost daily (4 points)',
                    displayIndex: 5,
                  },
                  {
                    optionId: 24023,
                    name: 'Declined to specify (0 points)',
                    displayIndex: 6,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2925,
                shStructuredDataID: 2927,
                name: 'How many drinks did you have on a typical day when you were drinking in the past year?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 7,
                structuredDataOptions: [
                  {
                    optionId: 13043,
                    name: '1 or 2 drinks (0 point)',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13044,
                    name: '3 or 4 drinks (1 point)',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13045,
                    name: '5 or 6 drinks (2 points)',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13046,
                    name: '7 to 9 drinks (3 points)',
                    displayIndex: 4,
                  },
                  {
                    optionId: 13047,
                    name: '10 or more drinks (4 points)',
                    displayIndex: 5,
                  },
                  {
                    optionId: 24022,
                    name: 'Declined to specify (0 points)',
                    displayIndex: 6,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2925,
                shStructuredDataID: 2928,
                name: 'How often did you have a drink containing alcohol in the past year?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 237358,
                displayIndex: 8,
                structuredDataOptions: [
                  {
                    optionId: 13048,
                    name: 'Never (0 point)',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13049,
                    name: 'Monthly or less (1 point)',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13050,
                    name: '2 to 4 times a month (2 points)',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13051,
                    name: '2 to 3 times a week (3 points)',
                    displayIndex: 4,
                  },
                  {
                    optionId: 13052,
                    name: '4 or more times a week (4 points)',
                    displayIndex: 5,
                  },
                  {
                    optionId: 24021,
                    name: 'Declined to specify (0 points)',
                    displayIndex: 6,
                  },
                ],
              },
            ],
          },
          {
            socialInfoID: 285182,
            parentCategoryID: 237358,
            name: 'Notes:',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 560146,
            parentCategoryID: 237358,
            name: 'Alcohol Screen Interpretation',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
        ],
      },
      {
        socialHistoryCategoryID: 237359,
        name: 'Household:',
        socialInfoItems: [],
      },
      {
        socialHistoryCategoryID: 269073,
        name: 'Tobacco Use:',
        socialInfoItems: [
          {
            socialInfoID: 491310,
            parentCategoryID: 269073,
            name: 'Smoking Status',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4105,
                name: 'Are you a:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 18924,
                    name: 'Current smoker',
                    displayIndex: 1,
                  },
                  {
                    optionId: 18925,
                    name: 'Former smoker',
                    displayIndex: 2,
                  },
                  {
                    optionId: 18926,
                    name: 'Non-smoker',
                    displayIndex: 3,
                  },
                  {
                    optionId: 18927,
                    name: 'Other tobacco user',
                    displayIndex: 4,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4109,
                name: 'Are you interested in quitting?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 18932,
                    name: 'Ready to quit',
                    displayIndex: 1,
                  },
                  {
                    optionId: 18933,
                    name: 'Thinking about quitting',
                    displayIndex: 2,
                  },
                  {
                    optionId: 18934,
                    name: 'Not ready to quit',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4799,
                name: 'How many packs per day do you/ did you smoke?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 23736,
                    name: '1/2 pack',
                    displayIndex: 1,
                  },
                  {
                    optionId: 23737,
                    name: '1 pack',
                    displayIndex: 2,
                  },
                  {
                    optionId: 23738,
                    name: '2 packs',
                    displayIndex: 3,
                  },
                  {
                    optionId: 23739,
                    name: '>2 packs',
                    displayIndex: 4,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4800,
                name: 'How many years have you smoked?',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4801,
                name: 'How long ago did you quit smoking?',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4802,
                name: 'Pack years',
                structuredDataType: 'Numeric',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [],
              },
            ],
          },
          {
            socialInfoID: 560143,
            parentCategoryID: 269073,
            name: 'Smoking Status Notes:',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 276485,
            parentCategoryID: 269073,
            name: 'Tobacco use other than smoking:',
            options: '',
            structuredFlag: false,
            displayIndex: 3,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 3912,
                name: 'Are you an other tobacco user?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 1,
                structuredDataOptions: [],
              },
            ],
          },
          {
            socialInfoID: 397080,
            parentCategoryID: 269073,
            name: 'Screening not performed',
            options: '',
            structuredFlag: false,
            displayIndex: 4,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 4048,
                name: 'Reason:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 18496,
                    name: 'Medical Reason',
                    displayIndex: 1,
                  },
                  {
                    optionId: 18497,
                    name: 'Limited Life Expectancy',
                    displayIndex: 2,
                  },
                  {
                    optionId: 18498,
                    name: 'No reason specified',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 4048,
                shStructuredDataID: 4049,
                name: 'Type of Medical Reason:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 18504,
                    name: 'Absent response to treatment',
                    displayIndex: 1,
                  },
                  {
                    optionId: 18519,
                    name: 'Adverse reaction to drug',
                    displayIndex: 2,
                  },
                  {
                    optionId: 18508,
                    name: 'Complication of medical care',
                    displayIndex: 3,
                  },
                  {
                    optionId: 18513,
                    name: 'Contraindicated',
                    displayIndex: 4,
                  },
                  {
                    optionId: 18514,
                    name: 'Drug allergy',
                    displayIndex: 5,
                  },
                  {
                    optionId: 18520,
                    name: 'Drug interaction',
                    displayIndex: 6,
                  },
                  {
                    optionId: 18518,
                    name: 'Drug intolerance',
                    displayIndex: 7,
                  },
                  {
                    optionId: 18507,
                    name: 'Drug resistance',
                    displayIndex: 8,
                  },
                  {
                    optionId: 18506,
                    name: 'Drug therapy discontinued',
                    displayIndex: 9,
                  },
                  {
                    optionId: 18502,
                    name: 'Drug treatment not indicated',
                    displayIndex: 10,
                  },
                  {
                    optionId: 18503,
                    name: 'Failure in dosage',
                    displayIndex: 11,
                  },
                  {
                    optionId: 18499,
                    name: 'History of - drug allergy',
                    displayIndex: 12,
                  },
                  {
                    optionId: 18505,
                    name: 'Late effect of medical and surgical care complication',
                    displayIndex: 13,
                  },
                  {
                    optionId: 18510,
                    name: 'Medical contraindication',
                    displayIndex: 14,
                  },
                  {
                    optionId: 18512,
                    name: 'Not indicated',
                    displayIndex: 15,
                  },
                  {
                    optionId: 18500,
                    name: 'Procedure contraindicated',
                    displayIndex: 16,
                  },
                  {
                    optionId: 18515,
                    name: 'Procedure discontinued',
                    displayIndex: 17,
                  },
                  {
                    optionId: 18516,
                    name: 'Procedure not indicated',
                    displayIndex: 18,
                  },
                  {
                    optionId: 18517,
                    name: 'Treatment changed',
                    displayIndex: 19,
                  },
                  {
                    optionId: 18509,
                    name: 'Treatment modification',
                    displayIndex: 20,
                  },
                  {
                    optionId: 18501,
                    name: 'Treatment not indicated',
                    displayIndex: 21,
                  },
                  {
                    optionId: 18511,
                    name: 'Treatment not tolerated',
                    displayIndex: 22,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 4048,
                shStructuredDataID: 4050,
                name: 'Details:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 0,
                defaultOption: '',
                parentSHStructuredDataID: 269073,
                displayIndex: 0,
                structuredDataOptions: [
                  {
                    optionId: 18524,
                    name: 'Patient status determination, pre-terminal',
                    displayIndex: 1,
                  },
                  {
                    optionId: 18523,
                    name: 'Prognosis bad',
                    displayIndex: 2,
                  },
                  {
                    optionId: 18525,
                    name: 'Terminal illness',
                    displayIndex: 3,
                  },
                  {
                    optionId: 18521,
                    name: 'Terminal illness - early stage',
                    displayIndex: 4,
                  },
                  {
                    optionId: 18522,
                    name: 'Terminal illness - late stage',
                    displayIndex: 5,
                  },
                ],
              },
            ],
          },
          {
            socialInfoID: 285179,
            parentCategoryID: 269073,
            name: 'Notes:',
            options: '',
            structuredFlag: false,
            displayIndex: 5,
            hasOptions: null,
            structuredDataItems: [],
          },
        ],
      },
      {
        socialHistoryCategoryID: 269075,
        name: 'Miscellaneous:',
        socialInfoItems: [
          {
            socialInfoID: 269090,
            parentCategoryID: 269075,
            name: 'Children:',
            options: 'yes,no',
            structuredFlag: false,
            displayIndex: 2,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269079,
            parentCategoryID: 269075,
            name: 'Exercise:',
            options: 'no,yes',
            structuredFlag: false,
            displayIndex: 5,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269097,
            parentCategoryID: 269075,
            name: 'Living with:',
            options: '',
            structuredFlag: false,
            displayIndex: 9,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269081,
            parentCategoryID: 269075,
            name: 'Marital status:',
            options: '',
            structuredFlag: false,
            displayIndex: 10,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269082,
            parentCategoryID: 269075,
            name: 'New since last visit:',
            options: 'none,',
            structuredFlag: false,
            displayIndex: 12,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269088,
            parentCategoryID: 269075,
            name: 'Occupation:',
            options: 'weeks/months/years',
            structuredFlag: false,
            displayIndex: 13,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269083,
            parentCategoryID: 269075,
            name: 'Occupational exposure:',
            options: 'none,',
            structuredFlag: false,
            displayIndex: 14,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269092,
            parentCategoryID: 269075,
            name: 'Pets:',
            options: 'none,',
            structuredFlag: false,
            displayIndex: 16,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269085,
            parentCategoryID: 269075,
            name: 'Sexually active:',
            options: 'no,yes',
            structuredFlag: false,
            displayIndex: 18,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 269089,
            parentCategoryID: 269075,
            name: 'Travel outside of the United States:',
            options: 'Yes,No',
            structuredFlag: false,
            displayIndex: 19,
            hasOptions: null,
            structuredDataItems: [],
          },
        ],
      },
      {
        socialHistoryCategoryID: 538968,
        name: 'Occupation:',
        socialInfoItems: [
          {
            socialInfoID: 538969,
            parentCategoryID: 538968,
            name: 'Current Occupation',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 538970,
            parentCategoryID: 538968,
            name: 'Occupational History',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
          {
            socialInfoID: 541012,
            parentCategoryID: 538968,
            name: 'Occupational Exposure',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
        ],
      },
      {
        socialHistoryCategoryID: 269074,
        name: 'Sexual History:',
        socialInfoItems: [
          {
            socialInfoID: 272678,
            parentCategoryID: 269074,
            name: 'Sexual History',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2906,
                name: 'Had sex in the past 12 months (vaginal, oral, or anal)?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 1,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2913,
                name: 'Last menstrual period',
                structuredDataType: 'Date',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 2,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 0,
                shStructuredDataID: 2914,
                name: 'Have you ever had a Sexually transmitted disease?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: -1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 3,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2906,
                shStructuredDataID: 2909,
                name: 'Present symptoms?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 1,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2906,
                shStructuredDataID: 2907,
                name: 'Prevention strategies discussed:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 3,
                structuredDataOptions: [
                  {
                    optionId: 13021,
                    name: 'Abstinence',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13022,
                    name: 'Condoms',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13023,
                    name: 'Other',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2906,
                shStructuredDataID: 2908,
                name: 'Adherence barriers identified:',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 4,
                structuredDataOptions: [
                  {
                    optionId: 13015,
                    name: 'Patient non-adherence',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13016,
                    name: 'Language',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13017,
                    name: 'Culture',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13018,
                    name: 'Religion',
                    displayIndex: 4,
                  },
                  {
                    optionId: 13019,
                    name: 'Economic',
                    displayIndex: 5,
                  },
                  {
                    optionId: 13020,
                    name: 'Other',
                    displayIndex: 6,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2906,
                shStructuredDataID: 2910,
                name: 'with',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 6,
                structuredDataOptions: [
                  {
                    optionId: 13012,
                    name: 'Men only',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13013,
                    name: 'Women only',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13014,
                    name: 'Both Men and Women',
                    displayIndex: 3,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2906,
                shStructuredDataID: 2911,
                name: 'Use protection?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 7,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2909,
                shStructuredDataID: 2916,
                name: 'Syphilis?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 12,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2911,
                shStructuredDataID: 2912,
                name: 'How often?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 8,
                structuredDataOptions: [
                  {
                    optionId: 13024,
                    name: 'All of the time',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13025,
                    name: 'Most of the time',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13026,
                    name: 'Half the time',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13027,
                    name: 'Some of the time',
                    displayIndex: 4,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2911,
                shStructuredDataID: 2912,
                name: 'How often?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: true,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 8,
                structuredDataOptions: [
                  {
                    optionId: 13024,
                    name: 'All of the time',
                    displayIndex: 1,
                  },
                  {
                    optionId: 13025,
                    name: 'Most of the time',
                    displayIndex: 2,
                  },
                  {
                    optionId: 13026,
                    name: 'Half the time',
                    displayIndex: 3,
                  },
                  {
                    optionId: 13027,
                    name: 'Some of the time',
                    displayIndex: 4,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2911,
                shStructuredDataID: 2921,
                name: 'On the last sexual encounter, what prevented you from using protection?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 16,
                structuredDataOptions: [
                  {
                    optionId: 13035,
                    name: 'use notes',
                    displayIndex: 1,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2911,
                shStructuredDataID: 2921,
                name: 'On the last sexual encounter, what prevented you from using protection?',
                structuredDataType: 'Structured Text',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 16,
                structuredDataOptions: [
                  {
                    optionId: 13035,
                    name: 'use notes',
                    displayIndex: 1,
                  },
                ],
              },
              {
                parentSHSocialInfoID: 2914,
                shStructuredDataID: 2915,
                name: 'Other?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 11,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2914,
                shStructuredDataID: 2917,
                name: 'Syphilis?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 12,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2914,
                shStructuredDataID: 2918,
                name: 'Herpes?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 13,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2914,
                shStructuredDataID: 2919,
                name: 'GC?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 14,
                structuredDataOptions: [],
              },
              {
                parentSHSocialInfoID: 2914,
                shStructuredDataID: 2920,
                name: 'Chlamydia?',
                structuredDataType: 'Boolean',
                multiSelectFlag: false,
                mandatoryFlag: false,
                parentTriggerFlag: 1,
                defaultOption: '',
                parentSHStructuredDataID: 269074,
                displayIndex: 15,
                structuredDataOptions: [],
              },
            ],
          },
          {
            socialInfoID: 285181,
            parentCategoryID: 269074,
            name: 'Notes:',
            options: '',
            structuredFlag: false,
            displayIndex: 1,
            hasOptions: null,
            structuredDataItems: [],
          },
        ],
      },
    ],
    getSocialHistory: [
      {
        option: '',
        details: ' Are you a:: Non-smoker , Pack years: 0',
        categoryId: 269073,
        observationId: 491310,
        encounterSocialHistoryStructuredDataList: [
          {
            notes: '',
            value: 'Non-smoker',
            socialHistoryStructuredDataDetailId: 4105,
          },
          {
            notes: '',
            value: '0',
            socialHistoryStructuredDataDetailId: 4802,
          },
        ],
      },
      // {
      //   option: "",
      //   details:
      //     "How long ago did you quit? 2008, How many pack per day did you smoke? 2 packs a day, smoked x 25 yrs",
      //   categoryId: 269073,
      //   observationId: 285179,
      //   encounterSocialHistoryStructuredDataList: [],
      // },
      // {
      //   option: "",
      //   details:
      //     " Did you have a drink containing alcohol in the past year?: No, Points: 0, Interpretation: Negative",
      //   categoryId: 237358,
      //   observationId: 272677,
      //   encounterSocialHistoryStructuredDataList: [
      //     {
      //       notes: "",
      //       value: "No",
      //       socialHistoryStructuredDataDetailId: 2925,
      //     },
      //     {
      //       notes: "",
      //       value: "0",
      //       socialHistoryStructuredDataDetailId: 2929,
      //     },
      //     {
      //       notes: "",
      //       value: "Negative",
      //       socialHistoryStructuredDataDetailId: 2930,
      //     },
      //   ],
      // },
    ],

    // ROS
    getROSCategoryLookups: [
      {
        rosCategoryID: 507847,
        name: 'General',
      },
      {
        rosCategoryID: 270100,
        name: 'General/Constitutional',
      },
      {
        rosCategoryID: 270101,
        name: 'Allergy/Immunology',
      },
      {
        rosCategoryID: 270102,
        name: 'Ophthalmologic',
      },
      {
        rosCategoryID: 270103,
        name: 'ENT',
      },
      {
        rosCategoryID: 270104,
        name: 'Endocrine',
      },
      {
        rosCategoryID: 270105,
        name: 'Respiratory',
      },
      {
        rosCategoryID: 275304,
        name: 'Breast',
      },
      {
        rosCategoryID: 270106,
        name: 'Cardiovascular',
      },
      {
        rosCategoryID: 270107,
        name: 'Gastrointestinal',
      },
      {
        rosCategoryID: 270108,
        name: 'Hematology',
      },
      {
        rosCategoryID: 270109,
        name: 'Women Only',
      },
      {
        rosCategoryID: 270110,
        name: 'Men Only',
      },
      {
        rosCategoryID: 270111,
        name: 'Genitourinary',
      },
      {
        rosCategoryID: 270112,
        name: 'Musculoskeletal',
      },
      {
        rosCategoryID: 270113,
        name: 'Peripheral Vascular',
      },
      {
        rosCategoryID: 275273,
        name: 'Podiatric',
      },
      {
        rosCategoryID: 270114,
        name: 'Skin',
      },
      {
        rosCategoryID: 270115,
        name: 'Neurologic',
      },
      {
        rosCategoryID: 270116,
        name: 'Psychiatric',
      },
      {
        rosCategoryID: 270117,
        name: 'Health Education',
      },
      {
        rosCategoryID: 270118,
        name: 'Cancer Screening',
      },
      {
        rosCategoryID: 286286,
        name: 'Reviewed by provider',
      },
    ],
    getROSCategoryById: {
      rosCategoryID: 270100,
      name: 'General/Constitutional',
      gender: '',
      displayIndex: 1,
      rosSymptoms: [
        {
          symptomID: 270119,
          symptomName: 'Patient denies',
          symptomOptions: '',
          rosObservations: [
            {
              observationID: 8314,
              observationName: 'change in appetite',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8315,
              observationName: 'chills',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8316,
              observationName: 'fatigue',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8317,
              observationName: 'fever',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8318,
              observationName: 'headache',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8319,
              observationName: 'lightheadedness',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 10130,
              observationName: 'night sweats',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 8320,
              observationName: 'sleep disturbance',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8321,
              observationName: 'weight gain',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 8322,
              observationName: 'weight loss',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
          ],
        },
        {
          symptomID: 534192,
          symptomName: 'malaise',
          symptomOptions: 'sample option 1,sample option 2',
          rosObservations: [
            {
              observationID: 11261,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 11262,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
          ],
        },
        {
          symptomID: 270120,
          symptomName: 'Patient complaining of',
          symptomOptions: '',
          rosObservations: [
            {
              observationID: 8323,
              observationName: 'change in appetite',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8324,
              observationName: 'chills',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8325,
              observationName: 'fatigue',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8326,
              observationName: 'fever',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8327,
              observationName: 'headache',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8328,
              observationName: 'lightheadedness',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 10131,
              observationName: 'night sweats',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 8329,
              observationName: 'sleep disturbance',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8330,
              observationName: 'weight gain',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 8331,
              observationName: 'weight loss',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
          ],
        },
        {
          symptomID: 270121,
          symptomName: 'Comments',
          symptomOptions: '',
          rosObservations: [
            {
              observationID: 8332,
              observationName: 'See HPI for details',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 1,
            },
          ],
        },
        {
          symptomID: 270122,
          symptomName: 'Recent Weight Changes',
          symptomOptions: 'denies',
          rosObservations: [
            {
              observationID: 11301,
              observationName: 'yes',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 11302,
              observationName: 'no',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
          ],
        },
        {
          symptomID: 270123,
          symptomName: 'Change in appetite',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8311,
              observationName: '*denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8312,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8313,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8953,
              observationName: 'c/o',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9023,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8954,
              observationName: 'complains of',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9028,
              observationName: 'that is associated with weight loss',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9029,
              observationName: 'that is not associated with weight loss',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9027,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 9024,
              observationName: 'that is mild',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9025,
              observationName: 'that is moderate',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 9026,
              observationName: 'that is severe',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9030,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9031,
              observationName: 'that is improving',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 9032,
              observationName: 'that is worsening',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 9033,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
          ],
        },
        {
          symptomID: 270124,
          symptomName: 'Chills',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8335,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8336,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8955,
              observationName: 'complains of',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8956,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8957,
              observationName: 'intermittently',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8959,
              observationName: 'associated with fever',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8960,
              observationName: 'not associated with fever',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8958,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8961,
              observationName: '...',
              defaultFlag: false,
              color: 'FFFFFF',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 8966,
              observationName: 'that occur every night',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 8967,
              observationName: 'that occur throughout the day',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8965,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 8962,
              observationName: 'that is mild',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 8963,
              observationName: 'that is moderate',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 8964,
              observationName: 'that is severe',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 8968,
              observationName: '_____',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
          ],
        },
        {
          symptomID: 270125,
          symptomName: 'Fatigue',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8337,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8338,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8969,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8970,
              observationName: 'that is constant',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8971,
              observationName: 'that is intermittent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8972,
              observationName: 'it comes and goes',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 8973,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 8975,
              observationName: 'in the evening',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 8976,
              observationName: 'in the late afternoon',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8974,
              observationName: 'feels tired all day',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8977,
              observationName: 'that is making it hard to work',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 8978,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 8983,
              observationName: 'that is worse at the end of the day',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 8984,
              observationName: 'that is worse in the morning',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 8985,
              observationName: 'that is worsening',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 8982,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
            {
              observationID: 8979,
              observationName: 'that is mild',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 15,
            },
            {
              observationID: 8980,
              observationName: 'that is moderate',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
            {
              observationID: 8981,
              observationName: 'that is severe',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 17,
            },
            {
              observationID: 8986,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 18,
            },
          ],
        },
        {
          symptomID: 270126,
          symptomName: 'Fever',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8339,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8340,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 8987,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 8988,
              observationName: 'up to 99 degrees',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 8989,
              observationName: 'up to 101 degrees',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 8990,
              observationName: 'up to 102 degrees',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 8991,
              observationName: 'up to 103 degrees',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 8992,
              observationName: 'up to 104 degrees',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 8993,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 8994,
              observationName: 'high grade',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 8995,
              observationName: 'low grade',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 8996,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 8997,
              observationName: 'with chills',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 8998,
              observationName: 'without chills',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 8999,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
            {
              observationID: 9001,
              observationName: 'that is mild',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 15,
            },
            {
              observationID: 9002,
              observationName: 'that is moderate',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
            {
              observationID: 9000,
              observationName: 'is subjective',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
            {
              observationID: 9003,
              observationName: 'that is worsening',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 17,
            },
            {
              observationID: 9004,
              observationName: '_____',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 18,
            },
          ],
        },
        {
          symptomID: 270127,
          symptomName: 'Headache',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8341,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8342,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 9005,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9006,
              observationName: 'bitemporal',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9007,
              observationName: 'frontal',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9008,
              observationName: 'occipital',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 9022,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9009,
              observationName: 'is diffuse',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 9010,
              observationName: 'is throbbing',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9011,
              observationName: 'is a dull ache',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9012,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 9013,
              observationName: 'is associated with nausea',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 9014,
              observationName: 'is associated with photophobia',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 9015,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
            {
              observationID: 9016,
              observationName: 'is preceded by aura',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 15,
            },
            {
              observationID: 9017,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
            {
              observationID: 9018,
              observationName: 'that is daily',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 17,
            },
            {
              observationID: 9019,
              observationName: 'that is weekly',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 18,
            },
            {
              observationID: 9020,
              observationName: 'that is frequent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 19,
            },
            {
              observationID: 9021,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 20,
            },
          ],
        },
        {
          symptomID: 270128,
          symptomName: 'Lightheadedness',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8333,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8334,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 9067,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9068,
              observationName: 'after exercise',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9069,
              observationName: 'during exercise',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9070,
              observationName: 'at rest',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 9071,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9072,
              observationName: 'often ',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 9073,
              observationName: 'several times',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9074,
              observationName: 'rarely',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9075,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
          ],
        },
        {
          symptomID: 270361,
          symptomName: 'Night sweats',
          symptomOptions: '',
          rosObservations: [
            {
              observationID: 10111,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 10112,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 10113,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 10114,
              observationName: 'are frequent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 10115,
              observationName: 'are infrequent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 10116,
              observationName: 'are intermittent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 10117,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 10118,
              observationName: 'mild',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 10119,
              observationName: 'moderate',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 10120,
              observationName: 'severe',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 10121,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 10122,
              observationName: 'new onset',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 10123,
              observationName: 'occur every night',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 10124,
              observationName: 'occur most nights',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
            {
              observationID: 10125,
              observationName: 'occur occasionally',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 15,
            },
            {
              observationID: 10126,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
            {
              observationID: 10127,
              observationName: 'that drench my nightclothes',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 17,
            },
            {
              observationID: 10128,
              observationName: 'that drench my pillowcase and sheets',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 18,
            },
            {
              observationID: 10129,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 19,
            },
          ],
        },
        {
          symptomID: 270129,
          symptomName: 'Sleep disturbance',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8343,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8344,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 9058,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9060,
              observationName: 'every night',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9059,
              observationName: 'early morning awakening',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9061,
              observationName: 'is unable to sleep after waking up',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 9062,
              observationName: 'wakes up frequently at night',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9063,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 9064,
              observationName: 'has trouble initiating sleep',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9065,
              observationName: 'has trouble maintaining sleep',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9066,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
          ],
        },
        {
          symptomID: 270130,
          symptomName: 'Weight gain',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8345,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8346,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 1,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 9034,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9035,
              observationName: 'maybe',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9036,
              observationName: 'is minimal',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9037,
              observationName: 'is not significant',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 10353,
              observationName: 'is recent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9039,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 9038,
              observationName: '[  ] pounds',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9040,
              observationName: 'over a period of  [  ]',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9041,
              observationName: '  days',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 9042,
              observationName: '  weeks',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 9043,
              observationName: '  months',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 9044,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
          ],
        },
        {
          symptomID: 270131,
          symptomName: 'Weight loss',
          symptomOptions: '*Denies,*Admits',
          rosObservations: [
            {
              observationID: 8347,
              observationName: 'denies',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 1,
            },
            {
              observationID: 8348,
              observationName: 'admits',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 2,
            },
            {
              observationID: 9045,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 3,
            },
            {
              observationID: 9046,
              observationName: 'is intentional',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 4,
            },
            {
              observationID: 9047,
              observationName: 'is unintentional',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 5,
            },
            {
              observationID: 9048,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 6,
            },
            {
              observationID: 9049,
              observationName: 'is minimal',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 7,
            },
            {
              observationID: 9050,
              observationName: 'is significant',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 8,
            },
            {
              observationID: 10466,
              observationName: 'is recent',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 9,
            },
            {
              observationID: 9051,
              observationName: '...',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 10,
            },
            {
              observationID: 9052,
              observationName: '[  ] pounds',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 11,
            },
            {
              observationID: 9053,
              observationName: 'over a period of [  ]',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 12,
            },
            {
              observationID: 9054,
              observationName: '  days',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 13,
            },
            {
              observationID: 9055,
              observationName: '  weeks',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 14,
            },
            {
              observationID: 9056,
              observationName: '  months',
              defaultFlag: false,
              color: '',
              bold: 0,
              underline: 0,
              displayIndex: 15,
            },
            {
              observationID: 9057,
              observationName: '______',
              defaultFlag: false,
              color: 'FF0000',
              bold: 0,
              underline: 0,
              displayIndex: 16,
            },
          ],
        },
      ],
    },
  },
};
