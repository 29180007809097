import { UserInfo, VStack, Page } from '../../components';
import { CDSSContent } from '../MAWorkFlowScreen/CDSSContent';

export function ReviewOrderScreen() {
  return (
    <Page>
      <VStack spacing='lg' alignItems='stretch'>
        <UserInfo />
        <CDSSContent />
      </VStack>
    </Page>
  );
}
