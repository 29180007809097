export const clinicalStatuses = [
  'ABI',
  'Bathroom',
  'BEG DIL',
  'BP',
  'CDE',
  'DOC IN',
  'DONE',
  'DRET',
  'EAR',
  'EKG',
  'EMS',
  'H.PYLORI',
  'INJ',
  'IV',
  'Lab v CDE',
  'LAB',
  'NEB',
  'NEXT',
  'Not yet',
  'NUR',
  'Ready',
  'SPIRO',
  'ST',
  'U/S',
  'XR Done',
  'XRAY',
];

export default clinicalStatuses;
