import React from 'react';
import { Select } from '../../form';
import {
  appointmentsActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { Text } from '../../typography';
import { FormControl, FormLabel } from '../../general';
import { getDefaultDiagnosis } from '../../../utils';
import { type AlertId, type DiagnosisUI } from '../../../types';
import { isEmpty, isNil } from 'lodash';
import { WaitUntilLoaded } from '../../layout';
import { useEncounter, usePatient } from '../../../hooks';
import { useAssessmentsQuery } from '../../../__generated__/graphql';

function sortDesc(a: any, b: any) {
  if (a.diagnosisName > b.diagnosisName) {
    return 1;
  }
  if (a.diagnosisName < b.diagnosisName) {
    return -1;
  }
  return 0;
}

interface SelectDiagnosisProps {
  value?: number;
  alertId?: AlertId;
  onChange?: (d: DiagnosisUI) => void;
  isDisabled?: boolean;
  showLabel?: boolean;
}

export function SelectDiagnosis({
  alertId = -1,
  value,
  onChange,
  isDisabled = false,
  showLabel = true,
}: SelectDiagnosisProps) {
  const dispatch = useAppDispatch();
  const { activeEncounterID } = useEncounter();
  const diagnosis = useAppSelector(selectors.getDiagnosisByAlertId(alertId));
  const { patient: data, patientId, loading: isLoading } = usePatient();
  const { data: dataAssessments } = useAssessmentsQuery({
    variables: {
      filter: {
        patientId,
        encounterId: activeEncounterID,
      },
    },
  });

  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const problems = data?.problems ?? [];
  const assessments = dataAssessments?.getAssessments?.assessments || [];
  const selectedDiagnosis = value || diagnosis?.assessmentItemId || -1;
  let diagnosisList: DiagnosisUI[] = [];
  let diagnosisSelectItems = [];
  const defaultDiagnosis = getDefaultDiagnosis(editedOrder);

  // Allows us pick the default diagnosis from the drop down, even if it isn't part of problems
  if (defaultDiagnosis != null) {
    diagnosisList = [defaultDiagnosis];
  } else if (isEmpty(problems) && isEmpty(assessments)) {
    return <Text>No diagnosis</Text>;
  } else {
    const diagnosisListFromAssessments = assessments.map((a) => {
      return {
        diagnosisName: a.assessmentName ?? '',
        icdCode: a.assessmentCode ?? '',
        assessmentItemId: a.assessmentItemId,
      };
    });

    const diagnosisListFromProblems = problems.map((p) => {
      return {
        diagnosisName: p.problemName || undefined,
        icdCode: p.icdCode || undefined,
        assessmentItemId: p.assessmentItemId || undefined,
      };
    });

    const combinedDiagnosisList = [...diagnosisListFromAssessments, ...diagnosisListFromProblems];
    // remove duplicates where assessmentItemId is the same
    const diagnosisMap: Record<number, DiagnosisUI> = combinedDiagnosisList.reduce(
      (acc: Record<number, DiagnosisUI>, curr) => {
        if (curr.assessmentItemId) {
          acc[curr.assessmentItemId] = curr;
        }
        return acc;
      },
      {},
    );

    diagnosisList = Object.values(diagnosisMap).sort(sortDesc);
  }

  diagnosisSelectItems = diagnosisList?.map((diagnosis) => ({
    label: `${diagnosis.icdCode} ${diagnosis.diagnosisName}`,
    value: diagnosis.assessmentItemId,
  }));

  const onEditedOrderDiagnosisChange = (diagnosis: DiagnosisUI) => {
    dispatch(appointmentsActions.changeDiagnosis({ alertId, diagnosis }));
  };

  const handleDiagnosisChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(e.target.value);
    const diagnosis = diagnosisList?.find((d) => d.assessmentItemId === id);
    if (!isNil(diagnosis)) {
      if (onChange) {
        onChange(diagnosis);
      } else {
        onEditedOrderDiagnosisChange(diagnosis);
      }
    }
  };

  return (
    <WaitUntilLoaded loading={isLoading}>
      <FormControl>
        {showLabel && <FormLabel>Diagnosis</FormLabel>}
        <Select
          isDisabled={isDisabled}
          placeholder='Select diagnosis'
          value={selectedDiagnosis}
          onChange={handleDiagnosisChange}>
          {diagnosisSelectItems.map((diagnosis) => {
            const val = diagnosis.value;
            const key = `${val} | ${diagnosis.label}`;
            return (
              <option key={key} value={val}>
                {diagnosis.label}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </WaitUntilLoaded>
  );
}
