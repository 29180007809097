import { chiefComplaintSelectors, useAppSelector } from '../../../state-management';

export function useChiefComplaints() {
  const complaintNames = useAppSelector((state) => {
    return chiefComplaintSelectors.selectAll(state);
  });

  return {
    complaintNames,
  };
}
