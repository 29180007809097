import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useGetPatientQuery } from '../../../../__generated__/graphql';

const headers = ['Problem', 'ICD', 'Added', 'Resolved'];

interface Props {
  patientId: number;
}

export function ProblemListTab({ patientId }: Props) {
  const res = useGetPatientQuery({
    variables: {
      patientID: patientId,
    },
  });
  const { data } = res;
  const problems = data?.getPatient?.problems || [];

  return (
    <Flex flexDirection='column' alignItems='flex-start'>
      <TableContainer margin='24px 0' width='100%'>
        <Table fontSize='sm'>
          <Thead>
            <Tr>
              {headers.map((header) => {
                return (
                  <Th key={header}>
                    <Text>{header}</Text>
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {problems.map((data, index) => {
              return (
                <Tr key={index}>
                  <Td maxW={300} whiteSpace='pre-wrap'>
                    {data.problemName}
                  </Td>
                  <Td>{data.icdCode}</Td>
                  <Td>
                    {!data.problemAddedDate
                      ? 'NA'
                      : new Date(data.problemAddedDate).toLocaleDateString()}
                  </Td>
                  <Td>
                    {!data.resolvedOn ? 'NA' : new Date(data.resolvedOn).toLocaleDateString()}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
