export function getPharmacies() {
  return {
    pharmacies: [
      {
        pharmacyId: 9,
        pharmacyName: 'CVS Pharmacy #0079',
        pharmacyAddress: '2098 HIGHWAY 20 W',
        pharmacyAddress2: '',
        pharmacyCity: 'MCDONOUGH',
        pharmacyState: 'GA',
        pharmacyZip: '30253',
        pharmacyPhoneNumber: '770-898-7356',
      },
      {
        pharmacyId: 13,
        pharmacyName: 'CVS Pharmacy #8934',
        pharmacyAddress: '1870 HWY 81 EAST',
        pharmacyAddress2: '',
        pharmacyCity: 'MCDONOUGH',
        pharmacyState: 'GA',
        pharmacyZip: '30252',
        pharmacyPhoneNumber: '678-583-0097',
      },
      {
        pharmacyId: 16,
        pharmacyName: 'KROGER ATLANTA 433',
        pharmacyAddress: '5900 E LAKE PKWY',
        pharmacyAddress2: '',
        pharmacyCity: 'MCDONOUGH',
        pharmacyState: 'GA',
        pharmacyZip: '30252',
        pharmacyPhoneNumber: '770-954-4389',
      },
      {
        pharmacyId: 17,
        pharmacyName: 'Moyes Health Mart Pharmacy',
        pharmacyAddress: '85 Claire Ct',
        pharmacyAddress2: '',
        pharmacyCity: 'MCDonough',
        pharmacyState: 'GA',
        pharmacyZip: '30252',
        pharmacyPhoneNumber: '770-957-1852',
      },
      {
        pharmacyId: 18,
        pharmacyName: 'Moyes Pharmacy Inc 8334',
        pharmacyAddress: '62 KEYS FERRY ST',
        pharmacyAddress2: '',
        pharmacyCity: 'McDonough',
        pharmacyState: 'GA',
        pharmacyZip: '30253',
        pharmacyPhoneNumber: '770-957-1851',
      },
    ],
    nextToken: 18,
  };
}
