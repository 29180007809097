import {
  Box,
  Button,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  TabIndicator,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { EncounterTab } from './EncounterTab';
import { AllTabs } from './AllTabs';
import { AllergiesTab } from './AllergiesTab';
import { ProblemListTab } from './ProblemListTab';
import { MedLogTab } from './MedLogTab';

interface Props {
  initialSelected: number;
  onClose: () => void;
  patientId?: number;
}

export function EncounterPanel({ initialSelected, onClose, patientId }: Props) {
  return (
    <>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
          }}>
          <Box height='100%' width='100%'>
            <Flex>
              <Box minHeight='100vh' overflowY='auto' width='20%' backgroundColor='#ED4924' />

              <Box width='80%' marginTop='50px'>
                <Flex justifyContent='center'>
                  <Box width='90%'>
                    <Tabs defaultIndex={initialSelected} variant='unstyled'>
                      <AllTabs />

                      <TabIndicator bg='primary' mt='-1.5px' height='4px' borderRadius='1px' />

                      <TabPanels
                        maxHeight='90vh'
                        overflowY='scroll'
                        // Extra space for button
                        paddingBottom='48px'>
                        <TabPanel>{patientId && <EncounterTab patientId={patientId} />}</TabPanel>
                        <TabPanel>{patientId && <AllergiesTab patientId={patientId} />}</TabPanel>
                        <TabPanel>{patientId && <ProblemListTab patientId={patientId} />}</TabPanel>
                        <TabPanel>{patientId && <MedLogTab patientId={patientId} />}</TabPanel>
                      </TabPanels>
                    </Tabs>

                    <Button
                      color='primary'
                      textColor='white'
                      width='72%'
                      position='absolute'
                      bottom='0'
                      marginBottom='12px'
                      onClick={onClose}>
                      Done
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </>
  );
}
