import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { LotSearch } from './LotSearch';

export const LotSearchButton = ({ disabled }: { disabled?: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        size='md'
        isDisabled={disabled}
        onClick={onOpen}
        variant='outline'
        colorScheme='brand'>
        Select Lot #
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size='6xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <LotSearch
              onSelectLot={() => {
                onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
