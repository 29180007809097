import { type QualifierConfig, type VitalQualifier } from '../../types';
import { VitalQualifierInput } from './VitalsPicker';
import { type EncounterVital, type VitalType } from '../../__generated__/graphql';
import { type InputProps as ChakraInputProps } from '@chakra-ui/input/dist/input';
import { type VitalValidation } from '../../screens/MAWorkFlowScreen/VitalsContent/VitalValidation';

interface HeartRatePickerProps extends Omit<ChakraInputProps, 'value' | 'onChange'> {
  defaultPlaceholder?: string;
  value: EncounterVital[];
  onChange: (config: EncounterVital[]) => void;
  labelElement?: React.ReactElement;
  validationResult?: VitalValidation;
  onAddedHandler?: (value: string) => void;
}

const qualifiers = ['sitting', 'standing', 'supine', 'repeat'] as VitalQualifier[];

export function HeartRatePicker({
  onChange,
  defaultPlaceholder,
  value,
  errorBorderColor,
  ...rest
}: HeartRatePickerProps) {
  function handleChangeQualifier(qualifierConfigs: QualifierConfig[]) {
    const heartRateVitals = qualifierConfigs?.map((config) => ({
      ...config,
      type: 'HEART_RATE' as VitalType,
    }));
    onChange(heartRateVitals);
  }

  return (
    <VitalQualifierInput
      {...rest}
      errorBorderColor={errorBorderColor}
      defaultPlaceholder={defaultPlaceholder}
      defaultQualifier={qualifiers[0]}
      qualifiers={qualifiers}
      onChange={handleChangeQualifier}
      savedQualifiers={value as QualifierConfig[]}
    />
  );
}
