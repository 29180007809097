import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import {
  useGetLocationsQuery,
  type GetEncountersQuery,
  useGetProvidersQuery,
} from '../../../../../__generated__/graphql';

interface Props {
  data: NonNullable<GetEncountersQuery['getEncounters']>['encounters'];
  isLoading: boolean;
  label: string;
  headers: string[];
}

export function EncounterTable({ data, isLoading, label, headers }: Props) {
  const hasEncounters = !data?.length && !isLoading;
  const { data: locData } = useGetLocationsQuery();
  const facilities = locData?.getLocations?.locations ?? [];
  const { data: provData } = useGetProvidersQuery();
  const providers = provData?.getProviders?.providers || [];

  return (
    <>
      <Text fontSize='2xl' margin='24px 0'>
        {label}
      </Text>

      {hasEncounters && <Box mb={12}>No {label}</Box>}

      {!hasEncounters && (
        <TableContainer width='100%'>
          <Table fontSize='sm'>
            <Thead>
              <Tr>
                {headers?.map((header) => {
                  return (
                    <Th key={header}>
                      <Text>{header}</Text>
                    </Th>
                  );
                })}
              </Tr>
            </Thead>

            <Tbody>
              {data?.map((data, index) => {
                const facillity = facilities?.find(
                  (f) => f.locationID === data.facilityID,
                )?.locationName;
                const provider = providers?.find((p) => p.providerID === data.providerID);

                return (
                  <Tr key={index}>
                    <Td>{new Date(data.startTime).toLocaleDateString()}</Td>
                    <Td>{facillity}</Td>
                    <Td>{data.visitType}</Td>
                    <Td>
                      {provider?.firstName} {provider?.lastName}
                    </Td>
                    {headers.length > 4 && <Td>{data.visitReason}</Td>}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
