export function getAOEQuestionsAndOptions() {
  return {
    aoe: sampleAOE(),
    labs: sampleLabs(),
  };
}

function sampleLabs() {
  return [
    {
      orderableLabItemId: 229926,
      orderableLabItemName: 'Pap IG, HPV-h+lr ( ages 30-64)',
      orderableLabItemType: 'Lab',
      showPathologyDetail: false,
      supplierId: 0,
      billable: false,
      publishToPortal: true,
      approvalRequired: false,
      isActive: true,
      labCompanyName: 'LabcorpOG,Quest',
      displayOrder: 343,
      orderableLabCategoryId: 44,
      orderableLabCategoryName: 'Pap Smears',
      labInstructions:
        'MUST OBTAIN A STD CONSENT FORM \nAND PAP INFORMATION SHEET!!!!!!!!!\n\nALSO GET TRICARE WAIVER IF TRICARE PATIENT!!!!!!!!!!!!',
      labReferenceRanges: [],
      attributes: [
        {
          itemId: '581820',
          itemName: 'Please note',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 87,
        },
        {
          itemId: '551173',
          itemName: 'PDF',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 86,
        },
        {
          itemId: '532666',
          itemName: 'COMMENT',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 2,
        },
        {
          itemId: '478412',
          itemName: 'Pathologist provided ICD10:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 6,
        },
        {
          itemId: '475079',
          itemName: 'Clinician provided ICD10:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 3,
        },
        {
          itemId: '471884',
          itemName: 'HPV Aptima',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 4,
        },
        {
          itemId: '396500',
          itemName: 'Specimen Status Report',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 8,
        },
        {
          itemId: '389872',
          itemName: 'Previous history:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 7,
        },
        {
          itemId: '381137',
          itemName: 'HPV mRNA E6/E7',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 5,
        },
        {
          itemId: '379830',
          itemName: 'Please note',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 9,
        },
        {
          itemId: '378847',
          itemName: 'Request Problem',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 10,
        },
        {
          itemId: '327451',
          itemName: 'Specimen adequacy:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 40,
        },
        {
          itemId: '327448',
          itemName: 'Clinician provided ICD9:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 38,
        },
        {
          itemId: '327447',
          itemName: 'Clinical history:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 37,
        },
        {
          itemId: '327446',
          itemName: 'Recommendation:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 36,
        },
        {
          itemId: '327445',
          itemName: 'Additional comment:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 35,
        },
        {
          itemId: '327444',
          itemName: 'DIAGNOSIS:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 34,
        },
        {
          itemId: '327443',
          itemName: 'Addendum:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 33,
        },
        {
          itemId: '327442',
          itemName: 'Amended report:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 32,
        },
        {
          itemId: '327441',
          itemName: 'Adequacy:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 31,
        },
        {
          itemId: '327436',
          itemName: 'Test ordered:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 29,
        },
        {
          itemId: '327435',
          itemName: 'Maturation index:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 28,
        },
        {
          itemId: '327434',
          itemName: 'Diagnosis provided by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 27,
        },
        {
          itemId: '327433',
          itemName: 'Category:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 26,
        },
        {
          itemId: '327432',
          itemName: 'Photomicrograph',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 25,
        },
        {
          itemId: '327431',
          itemName: 'Patient Counseling Report',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 24,
        },
        {
          itemId: '327430',
          itemName: 'Performed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 23,
        },
        {
          itemId: '327428',
          itemName: 'HPV, low-risk',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 21,
        },
        {
          itemId: '327426',
          itemName: 'Test Methodology:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 20,
        },
        {
          itemId: '327425',
          itemName: 'Note:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 19,
        },
        {
          itemId: '327422',
          itemName: 'QA comment:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 16,
        },
        {
          itemId: '327421',
          itemName: 'Cytology history:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 15,
        },
        {
          itemId: '327420',
          itemName: 'Special procedure:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 14,
        },
        {
          itemId: '327419',
          itemName: 'Electronically signed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 13,
        },
        {
          itemId: '327416',
          itemName: 'QC reviewed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 12,
        },
        {
          itemId: '327415',
          itemName: 'Pathologist provided ICD9:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 11,
        },
        {
          itemId: '306670',
          itemName: 'INFECTION:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 55,
        },
        {
          itemId: '306669',
          itemName: 'PATHOLOGIST:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 54,
        },
        {
          itemId: '306668',
          itemName: 'REVIEW CYTOTECHNOLOGIST:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 53,
        },
        {
          itemId: '306667',
          itemName: 'CYTOTECHNOLOGIST:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 52,
        },
        {
          itemId: '306666',
          itemName: 'COMMENT:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 51,
        },
        {
          itemId: '306665',
          itemName: 'INTERPRETATION/RESULT:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 50,
        },
        {
          itemId: '306664',
          itemName: 'GENERAL CATEGORIZATION:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 49,
        },
        {
          itemId: '306663',
          itemName: 'STATEMENT OF ADEQUACY:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 48,
        },
        {
          itemId: '306662',
          itemName: 'PREV. BX:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 47,
        },
        {
          itemId: '306661',
          itemName: 'PREV. PAP:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 46,
        },
        {
          itemId: '306660',
          itemName: 'LMP:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 45,
        },
        {
          itemId: '306659',
          itemName: 'CLINICAL INFORMATION:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 44,
        },
        {
          itemId: '306658',
          itemName: 'REPORT STATUS:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 43,
        },
        {
          itemId: '306657',
          itemName: 'SOURCE:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 42,
        },
        {
          itemId: '306656',
          itemName: 'HPV DNA (HIGH RISK)',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 41,
        },
        {
          itemId: '229954',
          itemName: 'IGLBP CPT Code Automation',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 84,
        },
        {
          itemId: '229953',
          itemName: 'HPV, high-risk',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 83,
        },
        {
          itemId: '229952',
          itemName: 'HPV, low-risk',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 82,
        },
        {
          itemId: '229951',
          itemName: 'Interpretation',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 81,
        },
        {
          itemId: '229950',
          itemName: 'Clinician provided ICD9:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 80,
        },
        {
          itemId: '229947',
          itemName: 'Pathologist provided ICD9:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 77,
        },
        {
          itemId: '229946',
          itemName: 'Source:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 76,
        },
        {
          itemId: '229945',
          itemName: 'Diagnosis provided by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 75,
        },
        {
          itemId: '229944',
          itemName: 'QA comment:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 74,
        },
        {
          itemId: '229943',
          itemName: 'Special procedure:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 73,
        },
        {
          itemId: '229942',
          itemName: 'Cytology history:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 72,
        },
        {
          itemId: '229941',
          itemName: 'QC reviewed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 71,
        },
        {
          itemId: '229940',
          itemName: 'Addendum:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 70,
        },
        {
          itemId: '229939',
          itemName: 'Amended report:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 69,
        },
        {
          itemId: '229938',
          itemName: 'Maturation index:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 68,
        },
        {
          itemId: '229937',
          itemName: 'Test ordered:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 67,
        },
        {
          itemId: '229936',
          itemName: 'Electronically signed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 66,
        },
        {
          itemId: '229935',
          itemName: 'Performed by:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 65,
        },
        {
          itemId: '229934',
          itemName: 'Recommendation:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 64,
        },
        {
          itemId: '229933',
          itemName: 'Additional comment:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 63,
        },
        {
          itemId: '229932',
          itemName: 'Specimen adequacy:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 62,
        },
        {
          itemId: '229931',
          itemName: 'DIAGNOSIS:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 61,
        },
        {
          itemId: '229930',
          itemName: 'Clinical history:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 60,
        },
        {
          itemId: '229928',
          itemName: 'Note:',
          itemType: 'I',
          parentLabId: 229926,
          displayOrder: 58,
        },
      ],
    },
  ];
}

function sampleAOE() {
  return [
    {
      questionID: 19660,
      obrCode: '292536',
      obxCode: '85997862',
      question: 'PRIOR THERAPY',
      questionTip: '',
      abn: '',
      labId: 13,
      fieldType: 'FT',
      isRequired: false,
      itemId: 292536,
      maximumLength: 0,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [],
      questionOptions: [],
    },
    {
      questionID: 20225,
      obrCode: '229926',
      obxCode: '',
      question: 'Last Menstrual Period',
      questionTip: '',
      abn: '',
      labCompanyId: 13,
      fieldType: 'FT',
      isRequired: false,
      itemId: null,
      maximumLength: 99,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [
        {
          sourceAOEId: 721,
          questionID: 20225,
          fieldFormat: 'DT',
          details: 'mm/dd/yyyy',
        },
      ],
      questionOptions: [],
    },
    {
      questionID: 20223,
      obrCode: '229926',
      obxCode: '',
      question: 'Gynecological source?',
      questionTip: '',
      abn: '',
      labCompanyId: 13,
      fieldType: 'DD',
      isRequired: false,
      itemId: null,
      maximumLength: 0,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [],
      questionOptions: [
        {
          sourceAOEId: 20955,
          questionID: 20223,
          options: 'Cervical',
          optionCode: 'Cervical Yes',
          isOptionDefault: 0,
        },
        {
          sourceAOEId: 20956,
          questionID: 20223,
          options: 'Endocervical',
          optionCode: 'Endocervical Yes',
          isOptionDefault: 0,
        },
        {
          sourceAOEId: 20957,
          questionID: 20223,
          options: 'Vaginal',
          optionCode: 'Vanginal Yes',
          isOptionDefault: 0,
        },
      ],
    },
    {
      questionID: 20230,
      obrCode: '229926',
      obxCode: '',
      question: 'Post Menopausal?',
      questionTip: '',
      abn: '',
      labCompanyId: 13,
      fieldType: 'RB',
      isRequired: false,
      itemId: null,
      maximumLength: 0,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [],
      questionOptions: [
        {
          sourceAOEId: 20977,
          questionID: 20230,
          options: 'No',
          optionCode: 'N',
          isOptionDefault: 0,
        },
        {
          sourceAOEId: 20978,
          questionID: 20230,
          options: 'Yes',
          optionCode: 'Y',
          isOptionDefault: 0,
        },
      ],
    },
    {
      questionID: 20227,
      obrCode: '229926',
      obxCode: '',
      question: 'Date of previous cytology',
      questionTip: '',
      abn: '',
      labCompanyId: 13,
      fieldType: 'ST',
      isRequired: false,
      itemId: null,
      maximumLength: 25,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [
        {
          sourceAOEId: 722,
          questionID: 20227,
          fieldFormat: 'DT',
          details: 'mm/dd/yyyy',
        },
      ],
      questionOptions: [],
    },
    {
      questionID: 20225,
      obrCode: '229926',
      obxCode: '',
      question: 'Last Menstrual Period',
      questionTip: '',
      abn: '',
      labCompanyId: 13,
      fieldType: 'FT',
      isRequired: false,
      itemId: null,
      maximumLength: 99,
      defaultValue: '',
      isDeleted: false,
      groupId: 0,
      isActive: true,
      questionDetails: [
        {
          sourceAOEId: 721,
          questionID: 20225,
          fieldFormat: 'DT',
          details: 'mm/dd/yyyy',
        },
      ],
      questionOptions: [],
    },
  ];
}
