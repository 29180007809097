import { ComboBox } from '../../../../../components';
import {
  encounterFilterActions,
  getEncounterFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../../hooks';
import { useEffect, useMemo } from 'react';
import { useGetProvidersQuery } from '../../../../../__generated__/graphql';

export function Assignees() {
  const { data, loading } = useGetProvidersQuery();
  const assignees = data?.getProviders?.providers?.map((assignee) => ({
    label: `${assignee.providerName}`,
    value: `${assignee.providerID}`,
  }));

  const filterState = useAppSelector(getEncounterFilterState);
  const dispatch = useDispatch();

  const { user } = useAuth();

  useEffect(() => {
    // this let's us toggle back and forth between /providers/open and /encounters
    // else it will reset everytime we toggle, but if the user has dismissed their default
    // assigneId then we don't won't to add it back in
    if (user.ecwId && !filterState.assignees) {
      dispatch(
        encounterFilterActions.onFilterStateChange({
          assignees: [
            {
              label: `${user.firstName} ${user.lastName} - Me`,
              value: user.ecwId ? `${user.ecwId}` : '',
            },
          ],
        }),
      );
    }
  }, []);

  const assigneesData = useMemo(
    () => [
      {
        label: `${user.firstName} ${user.lastName} - Me`,
        value: user.ecwId ? `${user.ecwId}` : '',
      },
      ...(assignees ?? []),
    ],
    [assignees, user],
  );

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Assignees'
      options={assigneesData}
      onSelection={(assignees) => {
        const update = encounterFilterActions.onFilterStateChange({ assignees });
        dispatch(update);
      }}
      defaultValue={filterState.assignees}
      key={JSON.stringify(filterState.assignees)}
      isMulti
    />
  );
}
