// import { ModalProps } from "@chakra-ui/react";
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
  modalType: ModalTypes | null;
  // chakraModalProps?: Partial<ModalProps>;
  chakraModalProps?: any;
  modalProps?: any;
  // modalProps?: ModalProps;
}

const initialState: ModalState = {
  modalType: null,
  chakraModalProps: { size: '6xl' },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<ModalState>) {
      const { modalProps, modalType, chakraModalProps } = action.payload;
      state.modalType = modalType;
      state.modalProps = modalProps;
      state.chakraModalProps = chakraModalProps;
    },
    hideModal(state) {
      // We keep chakraModalProps to prevent choppy close of modal. E.g. 5x modal closed, will briefly flash as default smaller size before closing.
      state.modalType = null;
      state.modalProps = null;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
