import { ComboBox, type SingleSelectProps } from '../../components';
import { useGetLocationsQuery } from '../../__generated__/graphql';

// TODO: Make this a reusable component
export function FacilitySelect({
  placeholder = 'Facilities',
  defaultValueId,
  ...rest
}: Pick<SingleSelectProps, 'placeholder' | 'onSelection'> & {
  defaultValueId: number;
}) {
  const { data, loading } = useGetLocationsQuery({});

  const locations = data?.getLocations?.locations?.map((l) => ({
    label: l.locationName,
    value: l.locationID.toString(),
  }));

  return (
    <ComboBox
      isMulti={false}
      isLoading={loading}
      placeholder={placeholder}
      options={locations}
      defaultValue={locations?.find((l) => l.value === defaultValueId.toString())}
      {...rest}
    />
  );
}
