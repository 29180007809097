import { getOrderFlow, useAppSelector } from '../state-management';

const initialState: Record<OrderFlowSteps, Step> = {
  vitals: {
    completed: false,
    id: 'vitals',
  },
  'chief-complaint': {
    completed: false,
    id: 'chief-complaint',
  },
  hpi: {
    completed: false,
    id: 'hpi',
  },
  medications: {
    completed: false,
    id: 'medications',
  },
  'medical-history': {
    completed: false,
    id: 'medical-history',
  },
  allergies: {
    completed: false,
    id: 'allergies',
  },
  obgyn: {
    completed: false,
    id: 'obgyn',
  },
  surgeries: {
    completed: false,
    id: 'surgeries',
  },
  'family-history': {
    completed: false,
    id: 'family-history',
  },
  'social-history': {
    completed: false,
    id: 'social-history',
  },

  ros: {
    completed: false,
    id: 'ros',
  },
  cdss: {
    completed: false,
    id: 'cdss',
  },
};

export function useSteps() {
  const orderFlow = useAppSelector(getOrderFlow);
  const steps = orderFlow.steps.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item,
    };
  }, initialState);

  return {
    steps,
  };
}
