import { Box, Button, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

interface Props {
  xAxis: string | number;
  yAxis: string | number;
  data: Array<string[] | null>;
  isBaby: boolean;
}

export function GrowthChartTable({ xAxis, yAxis, data, isBaby }: Props) {
  return (
    <Flex
      width='25%'
      height='100%'
      flexDirection='column'
      justifyContent='space-between'
      sx={{ '@media print': { display: 'none' } }}>
      <Box overflowY='scroll' height='85%'>
        <Table size='sm'>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>{xAxis}</Th>
              <Th>{yAxis}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, rowIndex) => {
              return (
                <Tr key={`table-row-${rowIndex}`}>
                  {row?.map((cell, cellIndex) => {
                    // Parse months into years and months when >2yo
                    return (
                      <Td key={`table-cell-${rowIndex}-${cellIndex}`}>
                        {cellIndex === 1 && !isBaby
                          ? `${Math.floor((parseInt(cell) + 24) / 12)}yr. ${
                              (parseInt(cell) + 24) % 12
                            }mo.`
                          : cell}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Button
        colorScheme='brand'
        variant='solid'
        onClick={() => {
          window.print();
        }}>
        Print
      </Button>
    </Flex>
  );
}
