import { useCustomToast } from '../../../../hooks';
import { type useGetRxData } from '../PastRx/hooks/useGetRxData';

type Meds = ReturnType<typeof useGetRxData>['encounters'][0]['meds'];

export function useToastHandler() {
  const toast = useCustomToast();
  return (meds: Meds) => {
    const now = new Date();

    const someMedsAreReset = meds.some((med) => med.uiMed.newStatus === med.previousStatus);

    if (someMedsAreReset) {
      toast({
        title: 'Statuses reset',
        status: 'success',
        id: `reset ${now}`,
      });
    }

    const someMedsAreUpdated = meds.some((med) => med.previousStatus !== med.uiMed.newStatus);

    if (someMedsAreUpdated) {
      toast({
        title: 'Marked as "Taking"',
        status: 'success',
        id: `updated ${now}`,
      });
    }
  };
}
