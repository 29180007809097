import React from 'react';
import { usePatient } from '../../../hooks';
import { Box, WaitUntilLoaded } from '../../layout';
import { Text } from '../../typography';
import { formatAllergies } from './util';

export function KnownPatientIssues() {
  // TODO: Remove unsused selectors!
  // const allergies = useSelector(selectors.getPatientAllergies);
  const { patient, loading: isLoading } = usePatient();
  const allergies = patient?.allergies;
  const formattedAllergies = allergies != null ? formatAllergies(allergies) : '';

  return (
    <WaitUntilLoaded loading={isLoading}>
      <Box p='md' borderWidth='1px' borderRadius='md' borderColor='secondaries.1' w='full'>
        <Text variant='body2-b'>{formattedAllergies}</Text>
      </Box>
    </WaitUntilLoaded>
  );
}
