import { useEffect, useMemo } from 'react';
import { type HpiSmartFormEntity, type HpiStructuredData } from '../../../../__generated__/graphql';
import { Phq9ScoreCalc } from './calculators/phq9Score';
import { Phq9ScoreInterpretation } from './calculators/phq9ScoreInterpretation';
import { Phq2ScoreCalc } from './calculators/phq2Score';

interface Props {
  smartForms: HpiSmartFormEntity[];
  responses: HpiStructuredData[];
  changeHandler: (response: Partial<HpiStructuredData>) => void;
}

export const useScoring = ({ smartForms, responses, changeHandler }: Props) => {
  // get the calculators that apply to the provided smartForms
  const appliedCalculators = useMemo(() => {
    const calcs = [
      new Phq9ScoreCalc(smartForms),
      new Phq9ScoreInterpretation(smartForms),
      new Phq2ScoreCalc(smartForms),
    ];
    const applied = calcs.filter((c) => c.isActive());
    return applied;
    // just run once when we get our smartForms
  }, [!!smartForms]);

  useEffect(() => {
    appliedCalculators.forEach((calc) => {
      const { hasUpdate, score } = calc.getValue(responses);
      if (hasUpdate) {
        changeHandler({
          hpiStructuredDataDetailId: calc.computedField,
          value: `${score}`,
        });
      }
    });
    // check appliedCalculators.length to just invoke when we initially
    // get our calculators. update when all responses change
  }, [responses, appliedCalculators?.length]);

  return {
    // computed fields should not be manually updated
    disabledFields: appliedCalculators.map((c) => c.computedField),
  };
};
