import { type ImmunizationInjectionDetails } from '../../../__generated__/graphql';
import { Select } from '@chakra-ui/react';
import { useAdministerVaccineForm } from './useAdministerVaccineForm';

const administrationRoutes: ImmunizationInjectionDetails['administrationRoutes'] = [
  {
    id: 1,
    routeCode: 'ID ',
    routeDescription: 'Intradermal',
  },
  {
    id: 2,
    routeCode: 'IM ',
    routeDescription: 'Intramuscular',
  },
  {
    id: 3,
    routeCode: 'NS ',
    routeDescription: 'Nasal',
  },
  {
    id: 4,
    routeCode: 'IV ',
    routeDescription: 'Intravenous',
  },
  {
    id: 5,
    routeCode: 'PO ',
    routeDescription: 'Oral',
  },
  {
    id: 6,
    routeCode: 'OTH',
    routeDescription: 'Other/Miscellaneous',
  },
  {
    id: 7,
    routeCode: 'SC ',
    routeDescription: 'Subcutaneous',
  },
  {
    id: 8,
    routeCode: 'TD ',
    routeDescription: 'Transdermal',
  },
];

export function RouteSelect({
  isDisabled,
  value,
}: {
  isDisabled?: boolean;
  value?: string | null;
}) {
  const { formValues, handleUpdateForm } = useAdministerVaccineForm();

  return (
    <Select
      isDisabled={isDisabled}
      placeholder='Route'
      value={value || (formValues?.route ?? '')}
      onChange={(e) => {
        handleUpdateForm({ route: e.target.value });
      }}>
      {administrationRoutes?.map((route) => (
        <option key={route?.routeDescription ?? ''} value={route?.routeDescription}>
          {route?.routeDescription}
        </option>
      ))}
    </Select>
  );
}
