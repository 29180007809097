import React, { useState } from 'react';
import { type g } from '../../../api';
import {
  Box,
  Button,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '../..';
import { isString, isUndefined } from 'lodash';
import { useModal } from '../../../hooks';
import { uiActions, useAppDispatch } from '../../../state-management';

type FormStateItem = Omit<g.GynHistoryStructuredData, '__typename'>;
type FormState = Record<string, FormStateItem>;
type OnStructuredDataChange = (param: {
  value?: string;
  structuredDataItem: g.GynStructuredData;
}) => void;

interface GYNStructedDataFormProps {
  gynStructuredData: g.GynStructuredData[];
  userGynHistoryItem: UpsertGYNHistoryItem;
}
export function GYNStructedDataFormModal({
  gynStructuredData,
  userGynHistoryItem,
}: GYNStructedDataFormProps) {
  const defaultFormValues = userGynHistoryItem.gynHistoryStructuredData?.reduce(
    (acc: FormState, x) => {
      const id = x?.gynStructuredDataDetailID;
      if (id) {
        acc[id] = x;
      }
      return acc;
    },
    {} satisfies FormState,
  );

  const [formState, setFormState] = useState<FormState>(defaultFormValues ?? {});
  const formFieldData = gynStructuredData;
  const { hideModal } = useModal();
  const dispatch = useAppDispatch();

  const onStructuredDataChange: OnStructuredDataChange = ({ structuredDataItem, value }) => {
    setFormState((state) => {
      const newFormState = { ...state };

      // If state exists
      const gynStructuredDataDetailID = structuredDataItem.id;

      if (gynStructuredDataDetailID) {
        newFormState[gynStructuredDataDetailID] = {
          gynStructuredDataDetailID,
          value: value || '',
          notes: null, // TODO: Are we supporting this in UI?
        };
      }

      // Remove all children of a parent component with a "No" Boolean selection
      // Start by getting all children
      const sdChildren = formFieldData.filter(
        (item) => item.parentId === gynStructuredDataDetailID,
      );
      // Remove each child from the form state so it's values aren't saved, when the parent in "No" or the children are in hidden state
      sdChildren.forEach((child) => {
        // remove child info from form state
        if (child.id) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete newFormState[child.id];
        }
      });

      return newFormState;
    });
  };

  const onSubmit = () => {
    const enteredGynHistoryStructuredData = Object.values(formState);

    dispatch(
      uiActions.upsertGynDataItem({
        ...userGynHistoryItem,
        gynHistoryStructuredData: enteredGynHistoryStructuredData,
      }),
    );
    hideModal();
  };

  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <Tree
          treeData={formFieldData}
          onStructuredDataChange={onStructuredDataChange}
          formState={formState}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>Save</Button>
      </ModalFooter>
    </>
  );
}

function getUserInputValue(
  id: g.GynStructuredData['id'],
  formState?: FormState,
): g.GynHistoryStructuredData['value'] {
  let userInputValue = id && formState?.[id]?.value;
  if (!(isUndefined(userInputValue) || isString(userInputValue))) {
    userInputValue = '';
  }

  return userInputValue || '';
}

// Recursive component: https://betterprogramming.pub/recursive-rendering-with-react-components-10fa07c45456
function Tree({
  treeData,
  parentId = 0, // TODO: How to guarantee this is a good starting point? If it changes, no data may be rendered.
  level = 1,
  formState,
  onStructuredDataChange,
}: {
  treeData: g.GynStructuredData[];
  parentId?: g.GynStructuredData['parentId'];
  formState: FormState;
  level?: number;
  onStructuredDataChange: OnStructuredDataChange;
}) {
  const items = treeData.filter((item) => item.parentId === parentId);

  if (items.length === 0) return null;

  return (
    <>
      {items.map((item) => {
        const id = item?.id;
        const userInputValue = getUserInputValue(id, formState) ?? undefined;
        const showChildren = userInputValue === 'Yes'; // TODO: Confirm value used for parents with Boolean types. Saw "No" in a payload for social history, so used "Yes", "No"

        return (
          <Box key={id} mt='sm'>
            <GynStructuredDataField
              {...{
                userInputValue,
                structuredDataItem: item,
                onStructuredDataChange,
              }}
            />
            {showChildren && (
              <Box pl={`${level * 24}px`}>
                <Tree
                  formState={formState}
                  treeData={treeData}
                  parentId={id}
                  {...{
                    level: level + 1,
                    onStructuredDataChange,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
}

function GynStructuredDataField({
  structuredDataItem,
  userInputValue,
  onStructuredDataChange,
}: {
  userInputValue?: string;
  structuredDataItem: g.GynStructuredData;
  onStructuredDataChange: OnStructuredDataChange;
}) {
  const { dataType } = structuredDataItem;
  let renderStructuredData;

  if (dataType === 'Numeric') {
    renderStructuredData = (
      <Input
        type='number'
        value={userInputValue}
        onChange={(e) => {
          const value = e.target.value;

          onStructuredDataChange({
            value,
            structuredDataItem,
          });
        }}
      />
    );
  } else if (dataType === 'Boolean') {
    renderStructuredData = (
      <RadioGroup
        colorScheme='brand'
        value={userInputValue}
        onChange={(value) => {
          onStructuredDataChange({
            value,
            structuredDataItem,
          });
        }}
      >
        <VStack alignItems='flex-start'>
          <Radio value='No'>No</Radio>
          <Radio value='Yes'>Yes</Radio>
        </VStack>
      </RadioGroup>
    );
  }

  return (
    <VStack alignItems='flex-start' spacing='sm'>
      <Text>{structuredDataItem.name}</Text>
      {renderStructuredData}
    </VStack>
  );
}
