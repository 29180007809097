import { ModalBody, ModalFooter, ModalHeader, ModalCloseButton, FormControl } from '../general';
import { Box, HStack } from '../layout';
import { Button, Textarea } from '../form';
import { AsyncSelectField } from '../../features';
import { useDebounce, useMAWorkflow } from '../../hooks';
import { useForm } from 'react-hook-form';
import { FormErrorMessage } from '@chakra-ui/react';
import { DEBOUNCE_MILLISECONDS } from '../../constants';
import { useState } from 'react';
import { useGetFamilyHistoryItemsQuery } from '../../__generated__/graphql';

// const sampleNotes = `ADHD is one of the most common neurodevelopmental disorders of childhood.
// It is usually first diagnosed in childhood and often lasts into adulthood.
// Children with ADHD may have trouble paying attention, controlling impulsive
// behaviors (may act without thinking about what the result will be), or be overly active.
// `;

const INPUT_MAX_LEN = 10000;
interface FormInputs {
  notes: string;
}
export function FamilyHistoryModal({
  onCloseModal,
  familyMember,
}: SpecificModalProps & { familyMember: FamilyMemberUI }) {
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchText = useDebounce(searchText, DEBOUNCE_MILLISECONDS);
  const { data: dataFamilyHistoryItemsData, loading: isLoadingFamilyHistoryItem } =
    useGetFamilyHistoryItemsQuery({
      variables: { contains: debouncedSearchText },
      skip: !debouncedSearchText,
    });

  const { familyHistory } = useMAWorkflow();
  const { handleSetFamilyMember } = familyHistory;
  const { notes } = familyMember;
  const dataFamilyHistoryItems = dataFamilyHistoryItemsData?.getFamilyHistoryItems;
  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: { notes: notes || '' } });

  function handleSubmitNote(values: FormInputs) {
    handleSetFamilyMember({
      familyMember: {
        ...familyMember,
        notes: values.notes,
      },
    });
    onCloseModal();
  }
  const onSubmit = handleSubmit(handleSubmitNote);

  return (
    <>
      <ModalHeader />
      <ModalCloseButton onClick={onCloseModal} />
      <ModalBody>
        <HStack alignItems='flex-start'>
          {/* <Icon as={CheckIcon} boxSize="50px" color="primary" /> */}
          <Box flex='1'>
            <AsyncSelectField
              handleOptionSelect={(option) => {
                const val = option?.value;
                if (val) {
                  const famHistoryItem = dataFamilyHistoryItems?.find((item) => item.name === val);
                  const newNotes = famHistoryItem?.notes;
                  if (newNotes) {
                    const oldNotes = getValues('notes');
                    setValue('notes', `${oldNotes}\n${newNotes}`);
                  }
                }
              }}
              inputProps={{
                placeholder: 'Search Keyword',
              }}
              dropdownItems={dataFamilyHistoryItems?.map(({ name }) => {
                return {
                  label: name || '',
                  value: name || '',
                };
              })}
              handleInputChange={(inputValue) => {
                if (inputValue) {
                  setSearchText(inputValue);
                }
              }}
              loading={isLoadingFamilyHistoryItem}
            />
          </Box>
          <Box flex='1'>
            <FormControl isInvalid={!(errors.notes == null)}>
              <Textarea
                rows={20}
                {...register('notes', {
                  maxLength: {
                    value: INPUT_MAX_LEN,
                    message: `The max length is ${INPUT_MAX_LEN}`,
                  },
                })}
              />
              <FormErrorMessage>{errors.notes?.message}</FormErrorMessage>
            </FormControl>
            {/* <SimpleGrid mt="md" columns={2} spacing="sm">
              <Button onClick={() => {}} rightIcon={<Icon as={CheckIcon} />}>
                Father
              </Button>
              <Button onClick={() => {}} variant="outline">
                Mother
              </Button>
              <Button onClick={() => {}} variant="outline">
                Siblings
              </Button>
              <Button onClick={() => {}} variant="outline">
                Children
              </Button>
            </SimpleGrid> */}
          </Box>
        </HStack>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button variant='ghost' onClick={onCloseModal}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>Save Note</Button>
        </HStack>
      </ModalFooter>
    </>
  );
}
