import { useParams } from 'react-router-dom';
import { useGetPastEncounterDetailsQuery } from '../../__generated__/graphql';
import { Divider, useCallbackRef } from '@chakra-ui/react';
import { Page, UserInfo, WaitUntilLoaded } from '../../components';
import { IFrame } from '../../components/general/IFrame/IFrame';

export function PastEncounterDetailsScreen() {
  const { encounterId } = useParams();
  const { data, loading } = useGetPastEncounterDetailsQuery({
    variables: { encounterId: parseInt(encounterId ?? '-1') },
  });

  // This ref fixes <img> elements in returned HTML
  const htmlRef = useCallbackRef((node) => {
    if (node) {
      const iFrame = document.getElementById('details-iframe') as any;
      const logo = iFrame.contentWindow.document.getElementById('logo');
      if (logo) {
        logo.src = '/aylo-logo.jpg';
      }

      // Signature PNGs are not acquirable currently, so they must be hidden
      // Select all elements starting with 'signature', there may be multiple
      const signatures = iFrame.contentWindow.document.querySelectorAll('[id^="signature"]');
      // Hide PNGs
      signatures.forEach((signature: any) => {
        signature.style.display = 'none';
      });
    }
  });

  return (
    <Page>
      <UserInfo />
      <Divider pt='sm' />
      <WaitUntilLoaded
        loading={loading}
        showErrorMessage={data?.getEncounterTemplatePreview.html === null}
        errorMessage='Cannot load Encounter record'>
        <IFrame
          props={{
            style: { height: '100vh', width: '100%', padding: '0 48px' },
            id: 'details-iframe',
          }}>
          {/* Inject XML into a DOM element */}
          <div
            dangerouslySetInnerHTML={{ __html: data?.getEncounterTemplatePreview.html ?? '' }}
            ref={htmlRef}
          />
        </IFrame>
      </WaitUntilLoaded>
    </Page>
  );
}
