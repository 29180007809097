import { useGetKeywordsQuery } from '../../../../__generated__/graphql';
import { Box, ComboBox } from '../../../../components';

interface Props {
  selectionCallback: (providerId: any) => void;
  isDisabled?: boolean;
  selectedId?: string;
}

export function LabResults({ selectionCallback, selectedId, isDisabled }: Props) {
  let selected;

  const { data, loading } = useGetKeywordsQuery({
    variables: {
      keynameCategory: 'LabResults',
    },
  });

  const results = data?.getKeywords?.keywords?.map((keyword, index) => ({
    label: `${keyword.name}`,
    value: `${keyword.details?.trim() || keyword.name?.trim()}`,
  }));

  if (selectedId) {
    selected = results?.find((a) => a.value === selectedId);
  }

  return (
    <Box key={JSON.stringify(selected)} width='100%'>
      <ComboBox
        isDisabled={isDisabled}
        isLoading={loading}
        placeholder='Result (Append To)'
        options={results}
        onSelection={(selection) => {
          selectionCallback(selection?.value);
        }}
        defaultValue={selected}
      />
    </Box>
  );
}
