import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalCloseButton,
  Button,
  ModalFooter,
  Text,
  useClipboard,
  Code,
} from '@chakra-ui/react';
import { Spinner } from '../components';
// import fs from "fs";
// const fs = require("fs");

// Got query from Network tab in browser. Apollo makes the query
const query = gql(`
query IntrospectionQuery {
    __schema {
      queryType {
        name
        __typename
      }
      mutationType {
        name
        __typename
      }
      subscriptionType {
        name
        __typename
      }
      types {
        ...FullType
        __typename
      }
      directives {
        name
        description
        locations
        args {
          ...InputValue
          __typename
        }
        __typename
      }
      __typename
    }
  }
  
  fragment FullType on __Type {
    kind
    name
    description
    fields(includeDeprecated: true) {
      name
      description
      args {
        ...InputValue
        __typename
      }
      type {
        ...TypeRef
        __typename
      }
      isDeprecated
      deprecationReason
      __typename
    }
    inputFields {
      ...InputValue
      __typename
    }
    interfaces {
      ...TypeRef
      __typename
    }
    enumValues(includeDeprecated: true) {
      name
      description
      isDeprecated
      deprecationReason
      __typename
    }
    possibleTypes {
      ...TypeRef
      __typename
    }
    __typename
  }
  
  fragment InputValue on __InputValue {
    name
    description
    type {
      ...TypeRef
      __typename
    }
    defaultValue
    __typename
  }
  
  fragment TypeRef on __Type {
    kind
    name
    ofType {
      kind
      name
      ofType {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`);

// https://www.apollographql.com/blog/backend/schema-design/three-ways-to-represent-your-graphql-schema/#graphql-introspection-query-result
function useIntrospection() {
  return useLazyQuery(query);
}

export function Introspection() {
  const [getIntrospection, { data, loading }] = useIntrospection();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const { onCopy, setValue, hasCopied } = useClipboard('');
  const instropspectionJSONString = JSON.stringify(data);

  const [show, setShow] = React.useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (instropspectionJSONString) {
      setValue(instropspectionJSONString);
    }
  }, [instropspectionJSONString]);

  return (
    <>
      <Button
        colorScheme='blue'
        variant='solid'
        onClick={() => {
          getIntrospection();
          onOpen();
        }}
      >
        Fetch introspection data
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Instrospection Query Result</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              w='full'
              onClick={onCopy}
              variant={hasCopied ? 'outline' : 'solid'}
              isLoading={loading}
            >
              {hasCopied ? 'Copied!' : 'Copy'}
            </Button>

            <Text textAlign='center' mt='sm' mb='lg'>
              (paste this in <Code>introspection.json</Code> for <Code>codegen.ts</Code> to generate
              the latest TS types)
            </Text>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Button
                  w='full'
                  mb='md'
                  variant='outline'
                  colorScheme='blue'
                  onClick={handleToggle}
                >
                  Show {show ? 'less' : 'full results'}
                </Button>
                {show ? (
                  <Text>{instropspectionJSONString}</Text>
                ) : (
                  <Text noOfLines={10}>{instropspectionJSONString}</Text>
                )}
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose} colorScheme='gray'>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
