import { selectors, useAppSelector } from '../state-management';

export function useActiveEncounter() {
  const activeEncounter = useAppSelector(selectors.encounterSelectors.getActiveAppointment);
  //   const { data, ...rest } = ensomataApi.useGetPatientQuery(
  //     {
  //       patientID: activePatientID,
  //     },
  //     { refetchOnMountOrArgChange: 30 }
  //   );
  return {
    activeEncounter,
    activeEncounterId: activeEncounter?.encounterID,
  };
}
