import { Box, Card, Flex, Text, VStack, WaitUntilLoaded } from '../../../components';
import { type GetPatientPatient } from '../../../types';
import { formatDate } from '../../../utils';
import { usePatient } from '../../../hooks';

interface OrderCardProps {
  orderableItemName?: string | null;
  primaryDiagnosis?: string | null;
  orderDate?: string;
}

function OrderCard({ orderableItemName, primaryDiagnosis, orderDate }: OrderCardProps) {
  return (
    <VStack spacing='sm' w='full'>
      <Card variant='accept'>
        {orderDate && <Text>{formatDate(orderDate)}</Text>}
        <Flex justifyContent='space-between' width='full' mb='md'>
          <Text variant='h6-b'>{orderableItemName}</Text>
        </Flex>
        {primaryDiagnosis && (
          <Box>
            <Text variant='body2'>Diagnosis: {primaryDiagnosis}</Text>
          </Box>
        )}
      </Card>
    </VStack>
  );
}

// TODO: USe Flatlist
export function OrderHistory({ data }: { data?: GetPatientPatient['orderableItemHistory'] }) {
  return (
    <VStack spacing='lg'>
      {data?.map((order, idx) => {
        // TODO: Use key
        return (
          <OrderCard
            key={`${order.orderableItemName} ${order.orderDate} ${order.primaryDiagnosis} ${idx}`}
            {...order}
          />
        );
      })}
    </VStack>
  );
}

function OrderableImmunizationInjectionHistory({
  data,
}: {
  data?: GetPatientPatient['orderableImmunizationInjectionHistory'];
}) {
  return (
    <VStack spacing='lg'>
      {data?.map((order) => {
        // TODO: Use key
        return (
          <OrderCard
            key={order.immunizationID}
            orderableItemName={order.immunizationName}
            // primaryDiagnosis={}
            // orderDate={order.dateTimeAdministered} // TODO: We need proper orderDate
          />
        );
      })}
    </VStack>
  );
}

export function sortOrders(orders: GetPatientPatient['orderableItemHistory'] | undefined) {
  if (orders != null) {
    // Copy object because can't manipulate read-only state object, or sort fails
    const ordersPendingSort = [...orders];

    return ordersPendingSort?.sort((a, b) => {
      if (a.orderDate < b.orderDate) {
        return 1;
      }
      if (a.orderDate > b.orderDate) {
        return -1;
      }
      return 0;
    });
  }
}

export function OrderHistoryTabPanel() {
  const { patient, loading: isLoading, error } = usePatient();
  const orderHistoryData = sortOrders(patient?.orderableItemHistory);
  return (
    <WaitUntilLoaded
      loading={isLoading}
      showErrorMessage={!(error == null)}
      errorMessage='Something went wrong'>
      {orderHistoryData?.length ? (
        <>
          <OrderHistory data={orderHistoryData} />
          <Box mt='lg'>
            <OrderableImmunizationInjectionHistory
              data={patient?.orderableImmunizationInjectionHistory}
            />
          </Box>
        </>
      ) : (
        <Text textAlign='center'>No order history</Text>
      )}
    </WaitUntilLoaded>
  );
}
