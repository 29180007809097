import { useMemo, useState } from 'react';
import { useGetSingleCumulativeReportQuery } from '../../../__generated__/graphql';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Spinner } from '../Spinner';
import { FiArrowLeft } from 'react-icons/fi';
import { OrderableItemTable } from '../../../screens/ProviderWorkflowScreens/EncounterScreen/components/OrderableItemTable';
import { DetailedReport } from '../../../screens/ProviderWorkflowScreens/EncounterScreen/components/ResultsContainer/ReportPanel';
import { Documents } from '../../../screens/ProviderWorkflowScreens/EncounterScreen/components/DocumentsPanel';

interface Props {
  orderableReportID: number;
  setSelectedLab: (lab: UILabItem | null) => void;
}

export function SingleLab({ orderableReportID, setSelectedLab }: Props) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const { data } = useGetSingleCumulativeReportQuery({ variables: { orderableReportID } });

  const orderableItem = useMemo(() => {
    return data?.getSingleCumulativeReport;
  }, [data]);

  return orderableItem ? (
    <Box maxHeight='90vh' overflowY='clip'>
      <IconButton
        aria-label='back'
        size='sm'
        variant='ghost'
        colorScheme='brand'
        icon={<Icon boxSize={5} as={FiArrowLeft} />}
        onClick={() => {
          setSelectedLab(null);
        }}
        sx={{ '@media print': { display: 'none' } }}
      />
      <Text fontSize='xl' as='b' marginLeft='4px' sx={{ '@media print': { display: 'none' } }}>
        {orderableItem?.orderableItemName}
      </Text>

      <Tabs defaultIndex={0} variant='unstyled'>
        {(orderableItem.detailedReport ||
          (orderableItem.documentIds && orderableItem.documentIds.length > 0)) && (
          <TabList sx={{ '@media print': { display: 'none' } }}>
            <TabButton
              title='Results Summary'
              onClick={() => {
                setTabIndex(0);
              }}
            />
            {orderableItem.detailedReport && (
              <TabButton
                title='Detailed Report'
                onClick={() => {
                  setTabIndex(1);
                }}
              />
            )}
            {orderableItem.documentIds && orderableItem.documentIds.length > 0 && (
              <TabButton
                title='Scanned Docs'
                onClick={() => {
                  setTabIndex(2);
                }}
              />
            )}
          </TabList>
        )}

        <TabIndicator bg='primary' mt='-2px' height='4px' borderRadius='1px' />

        <Flex
          maxHeight='80vh'
          overflowY='scroll'
          width='100%'
          pt='md'
          sx={{ '@media print': { width: '100%' } }}>
          {tabIndex === 2 ? (
            <Documents orderableItem={orderableItem} />
          ) : tabIndex === 1 ? (
            <DetailedReport xml={orderableItem.detailedReport ?? ''} />
          ) : (
            <OrderableItemTable orderableItem={orderableItem} />
          )}
        </Flex>
      </Tabs>
    </Box>
  ) : (
    <Spinner colorScheme='brand' />
  );
}

function TabButton({ title, onClick }: { title: string; onClick: () => void }) {
  return (
    <Tab _selected={{ color: 'primary' }} paddingLeft='40px' paddingRight='40px' onClick={onClick}>
      <Text fontSize='lg'>{title}</Text>
    </Tab>
  );
}
