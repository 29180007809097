import { Box } from '@chakra-ui/react';
import { Chart as ChartJS, registerables, type TooltipItem } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import { range } from 'lodash';

ChartJS.register(...registerables, annotationPlugin);

interface Props {
  WHOData: Record<string, number[]> | undefined;
  growthChartData: Array<number | null>;
  isBaby: boolean;
  max: string | number;
  min: string | number;
  xAxis: string | number;
  xAxisUnit: string | number;
  annotationXOffset: string | number;
  yAxis: string | number;
  yAxisUnit: string | number;
  annotationPadding: string | number;
}

export function GrowthChartLineChart({
  WHOData,
  growthChartData,
  isBaby,
  max,
  min,
  xAxis,
  xAxisUnit,
  annotationXOffset,
  yAxis,
  yAxisUnit,
  annotationPadding,
}: Props) {
  return (
    <Box width='75%' height='100%'>
      <Chart
        id='growth-chart'
        type='line'
        options={{
          maintainAspectRatio: false,
          spanGaps: true,
          scales: {
            y: {
              title: {
                text: `${yAxis} ${yAxis !== 'BMI' ? `(${yAxisUnit})` : ''}`,
                display: true,
              },
            },
            x: {
              title: {
                text: `${xAxis} (${xAxisUnit})`,
                display: true,
              },
              ticks: {
                callback: (value) => {
                  value = parseInt(`${value}`) + 24;
                  return value % 12 === 0 ? value / 12 : null;
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              usePointStyle: true,
              callbacks: {
                title: (tooltipItems: Array<TooltipItem<'line'>>) => {
                  return tooltipItems.map((tooltipItem) =>
                    isBaby
                      ? `${xAxis} ${tooltipItem.label}`
                      : `${Math.floor(parseInt(`${tooltipItem.label}`) / 12)}yr. ${
                          parseInt(`${tooltipItem.label}`) % 12
                        }mo.`,
                  );
                },
                label: (tooltipItem: TooltipItem<'line'>) => {
                  return `${tooltipItem.raw} ${yAxisUnit}`;
                },
                labelColor: () => {
                  return {
                    borderColor: 'rgba(87, 159, 148, 1)',
                    backgroundColor: 'rgba(87, 159, 148, 1)',
                  };
                },
                labelPointStyle: () => {
                  return {
                    pointStyle: 'circle',
                    rotation: 0,
                  };
                },
              },
            },
            annotation: {
              annotations: WHOData
                ? Object.keys(WHOData).map((percentile) => {
                    return {
                      type: 'label',
                      xValue: (max as number) - (min as number) + (annotationXOffset as number),
                      padding: 2,
                      yValue: WHOData[percentile][WHOData[percentile].length - 1],
                      content: percentile,
                      z: 3,
                    };
                  })
                : [],
            },
          },
        }}
        data={{
          labels: [
            // Only label years when patient is >2yo
            ...range(min as number, (max as number) + 1),
            ...new Array(annotationPadding as number).fill(null),
          ],
          datasets: [
            {
              data: growthChartData ?? [],
              borderColor: 'rgba(87, 159, 148, 1)',
            },
            ...(WHOData
              ? Object.keys(WHOData).map((percentile) => {
                  return {
                    data: WHOData[percentile],
                    borderColor: 'rgba(96, 108, 152, 0.35)',
                    pointRadius: 0,
                    tension: 0.5,
                  };
                })
              : []),
          ],
        }}
      />
    </Box>
  );
}
