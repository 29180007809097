// https://adamhannigan81.medium.com/the-dos-and-don-ts-of-testing-apollo-in-react-9f8211a6ac91
import { setupWorker, rest } from 'msw';
import { gql } from '@apollo/client';

import { buildClientSchema, execute } from 'graphql';
import { addMocksToSchema } from '@graphql-tools/mock';

import introspection from '../__generated__/introspection.json';
import { getProviders, getStaff } from './fixtures/getProvidersAndStaff';
import { submitImmunizationInjection } from './fixtures/submitImmunizationInjection';
import { getImmunizationInjectionPatient } from './fixtures/getImmunizationInjectionPatient';
import { getEncounterOrders } from './fixtures/getEncounterOrders';
import { getEncounterChiefComplaints } from './fixtures/getEncounterChiefComplaints';
import { getVitals } from './fixtures/getVitals';
import { getAOEQuestionsAndOptions } from './fixtures/getAOEQuestionsAndOptions';
import { getLocations } from './fixtures/getLocations';
import { submitLabs } from './fixtures/submitLabs';
import { getPharmacies } from './fixtures/getPharmacies';
import { getPatientPharmacies } from './fixtures/getPatientPharmacies';
import { getAssessments } from './fixtures/getAssessments';

// Build a schema using the introspection
const schema = buildClientSchema(introspection);

// Stub out our schema with fake data
const mockedSchema = addMocksToSchema({
  schema,
  mocks: {
    AWSDateTime() {
      // Prevent the error "No mock defined for type "AWSDateTime""
      return new Date();
    },
  },
  resolvers: (store) => {
    // store is your `MockStore`
    return {
      Mutation: {
        submitImmunizationInjection,
        submitLabs,
        updateGynHistory() {
          return [
            {
              gynSymptomID: 9999,
            },
          ];
        },
      },
      Query: {
        // ... your custom resolvers
        getPharmacies,
        getPatientPharmacies,
        getStaff,
        getProviders,
        getImmunizationInjectionPatient,
        getEncounterOrders,
        getEncounterChiefComplaints,
        getVitals,
        getAOEQuestionsAndOptions,
        getLocations,
        getAssessments,
        getPatient() {
          return {
            patientID: 283784,
            dateOfBirth: '1954-09-02T00:00:00.000Z',
            firstName: 'Somto',
            lastName: 'Doe',
            gender: 'FEMALE',
            insurance: 'BlueCross Medicare',
            encounters: [
              {
                encounterID: 99433,
                visitReason: 'Screening/Steele',
                startTime: '2014-04-04T12:00:00.000Z',
              },
              {
                encounterID: 503154,
                visitReason: 'Dexa',
                startTime: '2014-05-27T18:00:00.000Z',
              },
              {
                encounterID: 673238,
                visitReason: 'sore throat, sinuses',
                startTime: '2014-09-09T16:00:00.000Z',
              },
            ],
            recommendations: [
              {
                defaultAssessmentItemId: 472176,
                defaultIcdCode: 'Z12.11',
                defaultDiagnosisName: 'Encounter for screening for malignant neoplasm of colon',
                orderableItemTypeId: 0,
                orderableItemId: 229159,
                orderableItemName:
                  'Lab - Occult Blood, Fecal, IA (IFOBT, Hemoccult, Fecal Immunochemistry)',
                description:
                  'A group of tests including colonoscopy, flexible sigmoidoscopy, CT colonography, Cologuard test, or fecal occult blood test that can help prevent or detect colon cancer if performed at the recommended frequency.   For example, a colonoscopy can detect polyps (growths) so they can be removed before they become cancerous and identify colorectal cancer at an early stage which increases life expectancy.',
                recommendationType: 'LAB',
                futureOrder: true,
                isOrderable: null,
                displayOrder: 18,
                required: false,
                orderableItemOptions: null,
              },
              {
                defaultAssessmentItemId: 11111,
                defaultIcdCode: 'test_lab_defaultIcdCode',
                defaultDiagnosisName: 'Test defaultDiagnosisName',
                orderableItemTypeId: 0,
                orderableItemId: 44444,
                orderableItemName: '(Future Order Test) Lab - Hemoglobin A1C',
                description:
                  "The A1C test—also known as the hemoglobin A1C or HbA1c test—is a simple blood test that measures your average blood sugar levels over the past 3 months. It's one of the commonly used tests to diagnose prediabetes and diabetes, and is also the main test to help you and your health care team manage diabetes.",
                recommendationType: 'LAB',
                futureOrder: true,
                isOrderable: true,
                displayOrder: 2,
                required: true,
                orderableItemOptions: null,
              },
              {
                defaultAssessmentItemId: 11111,
                defaultIcdCode: 'test_lab_defaultIcdCode',
                defaultDiagnosisName: 'Test defaultDiagnosisName',
                orderableItemTypeId: 0,
                orderableItemId: 529171,
                orderableItemName: '(Non-Future Order Test) Lab - Hemoglobin A1C',
                description:
                  "The A1C test—also known as the hemoglobin A1C or HbA1c test—is a simple blood test that measures your average blood sugar levels over the past 3 months. It's one of the commonly used tests to diagnose prediabetes and diabetes, and is also the main test to help you and your health care team manage diabetes.",
                recommendationType: 'LAB',
                futureOrder: false,
                isOrderable: true,
                displayOrder: 2,
                required: null,
                orderableItemOptions: null,
              },
              {
                defaultAssessmentItemId: 11111,
                defaultIcdCode: 'test_lab_defaultIcdCode',
                defaultDiagnosisName: 'Test defaultDiagnosisName',
                orderableItemTypeId: 0,
                orderableItemId: 582403,
                orderableItemName:
                  '(Constellation TEST) Rapid COVID Antigen -Coronavirus, (COVID-19) (SARS, CoV-2) - In Clinic',
                description:
                  "The A1C test—also known as the hemoglobin A1C or HbA1c test—is a simple blood test that measures your average blood sugar levels over the past 3 months. It's one of the commonly used tests to diagnose prediabetes and diabetes, and is also the main test to help you and your health care team manage diabetes.",
                recommendationType: 'LAB',
                futureOrder: true,
                isOrderable: true,
                displayOrder: 2,
                required: null,
                orderableItemOptions: null,
              },
              // {
              //   defaultAssessmentItemId: 11112,
              //   defaultIcdCode: 'test_lab_defaultIcdCode2',
              //   defaultDiagnosisName: 'Test defaultDiagnosisName2',
              //   orderableItemTypeId: 0,
              //   orderableItemId: 529171_0,
              //   orderableItemName: 'Lab - Hemoglobin A1C 2',
              //   description:
              //     "The A1C test—also known as the hemoglobin A1C or HbA1c test—is a simple blood test that measures your average blood sugar levels over the past 3 months. It's one of the commonly used tests to diagnose prediabetes and diabetes, and is also the main test to help you and your health care team manage diabetes.",
              //   recommendationType: 'LAB',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 2,
              //   required: null,
              //   orderableItemOptions: null,
              // },
              // {
              //   defaultAssessmentItemId: 470074,
              //   defaultIcdCode: 'Z23',
              //   defaultDiagnosisName: 'Encounter for immunization',
              //   orderableItemTypeId: 485,
              //   orderableItemId: 529944,
              //   orderableItemName: 'Shingrix Vaccine Protocol Incomplete',
              //   description:
              //     'Shingles, also known as herpes zoster, is a painful skin rash caused by reactivation of the varicella zoster virus (the same virus that causes chickenpox). 1 in every 3 people in the US will get shingles in their lifetime. Shingles may lead to long-term complications such as debilitating pain that lasts for months or even years. In rare cases, serious, long-term eye or hearing issues can occur.',
              //   recommendationType: 'IMMUNIZATION',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 7,
              //   required: null,
              //   orderableItemOptions: null,
              // },
              // {
              //   defaultAssessmentItemId: 470074,
              //   defaultIcdCode: 'Z23',
              //   defaultDiagnosisName: 'Encounter for immunization',
              //   orderableItemTypeId: 485,
              //   orderableItemId: 529944_1,
              //   orderableItemName: ' Shingrix Vaccine Protocol Incomplete - TEST 2',
              //   description:
              //     'Shingles, also known as herpes zoster, is a painful skin rash caused by reactivation of the varicella zoster virus (the same virus that causes chickenpox). 1 in every 3 people in the US will get shingles in their lifetime. Shingles may lead to long-term complications such as debilitating pain that lasts for months or even years. In rare cases, serious, long-term eye or hearing issues can occur.',
              //   recommendationType: 'IMMUNIZATION',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 7,
              //   required: null,
              //   orderableItemOptions: null,
              // },
              // {
              //   defaultAssessmentItemId: 470074,
              //   defaultIcdCode: 'Z23',
              //   defaultDiagnosisName: 'Encounter for immunization',
              //   orderableItemTypeId: 485,
              //   orderableItemId: 285228,
              //   orderableItemName: 'Immunization - Pneumonia Vaccine',
              //   description:
              //     'Patients age 65 and older that have never received Prevnar 13 or Pneumovax vaccination.',
              //   recommendationType: 'IMMUNIZATION',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 10,
              //   required: null,
              //   orderableItemOptions: [
              //     {
              //       orderableItemId: 285243,
              //       orderableItemName: 'Pneumovax (PPSV23)',
              //     },
              //     {
              //       orderableItemId: 577435,
              //       orderableItemName: 'Vaxneuvance (PCV15)',
              //     },
              //   ],
              // },
              // {
              //   defaultAssessmentItemId: 470074,
              //   defaultIcdCode: 'Z23',
              //   defaultDiagnosisName: 'Encounter for immunization',
              //   orderableItemTypeId: 485,
              //   orderableItemId: 285235,
              //   orderableItemName: 'Immunization - Flu Vaccine',
              //   description:
              //     'Influenza (flu) vaccines are vaccines that protect against the four influenza viruses that research indicates most common during the upcoming season. The Influenza virus (commonly known as "the flu") is a serious disease caused by a virus easily transmitted through respiratory droplets via air or contact.',
              //   recommendationType: 'IMMUNIZATION',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 9,
              //   required: null,
              //   orderableItemOptions: [
              //     {
              //       orderableItemId: 285235,
              //       orderableItemName: 'Fluarix (Quad)',
              //     },
              //     {
              //       orderableItemId: 518742,
              //       orderableItemName: 'Flucelvax (Quad)',
              //     },
              //     {
              //       orderableItemId: 563546,
              //       orderableItemName: 'Flulaval (Quad)',
              //     },
              //     {
              //       orderableItemId: 285239,
              //       orderableItemName: 'Fluzone (High Dose)',
              //     },
              //     {
              //       orderableItemId: 400387,
              //       orderableItemName: 'Fluzone (Quad)',
              //     },
              //   ],
              // },
              // {
              //   defaultAssessmentItemId: null,
              //   defaultIcdCode: null,
              //   defaultDiagnosisName: null,
              //   orderableItemTypeId: 3,
              //   orderableItemId: 268821,
              //   orderableItemName: 'Procedure - SPIROMETRY',
              //   description:
              //     'Spirometry (spy-ROM-uh-tree) is a test used to assess lung function by measuring the amount and/or speed of air that can be inhaled and exhaled.  .\n                                                                                                     \n      Spirometry is used to diagnose asthma, chronic obstructive pulmonary disease (COPD) and other conditions that affect breathing or It may be used to  check whether a treatment for a chronic lung condition is working.',
              //   recommendationType: 'PROCEDURE',
              //   futureOrder: false,
              //   isOrderable: true,
              //   displayOrder: 11,
              //   required: null,
              //   orderableItemOptions: null,
              // },
            ],
            orderableItemHistory: [
              {
                encounterID: 756155,
                orderDate: '2015-05-07T04:00:00.000Z',
                orderableItemName: 'EKG (Electrocardiogram)',
                primaryDiagnosis: null,
              },
              {
                encounterID: 756155,
                orderDate: '2015-05-07T04:00:00.000Z',
                orderableItemName: '-SPIROMETRY',
                primaryDiagnosis: null,
              },
              {
                encounterID: 1054014,
                orderDate: '2016-04-08T04:00:00.000Z',
                orderableItemName: 'Mammogram : Screening Bilateral',
                primaryDiagnosis: null,
              },
            ],
            orderableImmunizationInjectionHistory: [],
            problems: [
              {
                itemID: '401038',
                icdCode: 'E55.9',
                diagnosisCode: 'ICD: E55.9 SNOMED: 34713006',
                problemName: 'Vitamin D deficiency, unspecified',
                assessmentItemId: 401038,
                snomedCode: '34713006',
              },
              {
                itemID: '471477',
                icdCode: 'B00.9',
                diagnosisCode: 'ICD: B00.9 SNOMED: 407451003',
                problemName: 'HSV-1 (herpes simplex virus 1) infection',
                assessmentItemId: 471477,
                snomedCode: '407451003',
              },
              {
                itemID: '471062',
                icdCode: 'B00.9',
                diagnosisCode: 'ICD: B00.9 SNOMED: 407450002',
                problemName: 'HSV-2 (herpes simplex virus 2) infection',
                assessmentItemId: 471062,
                snomedCode: '407450002',
              },
              {
                itemID: '468474',
                icdCode: 'F17.200',
                diagnosisCode: 'ICD: F17.200 SNOMED: 77176002',
                problemName: 'Nicotine dependence, unspecified, uncomplicated',
                assessmentItemId: 468474,
                snomedCode: '77176002',
              },
              {
                itemID: '479386',
                icdCode: 'E78.2',
                diagnosisCode: 'ICD: E78.2 SNOMED: 267434003',
                problemName: 'Hyperlipidemia, mixed',
                assessmentItemId: 479386,
                snomedCode: '267434003',
              },
              {
                itemID: '471354',
                icdCode: 'I51.9',
                diagnosisCode: 'ICD: I51.9 SNOMED: 3545003',
                problemName: 'Diastolic dysfunction',
                assessmentItemId: 471354,
                snomedCode: '3545003',
              },
              {
                itemID: '472575',
                icdCode: 'D36.9',
                diagnosisCode: 'ICD: D36.9 SNOMED: 444408007',
                problemName: 'Tubular adenoma',
                assessmentItemId: 472575,
                snomedCode: '444408007',
              },
              {
                itemID: '511969',
                icdCode: 'K62.1',
                diagnosisCode: 'ICD: K62.1 SNOMED: 721691004',
                problemName: 'Hyperplastic rectal polyp',
                assessmentItemId: 511969,
                snomedCode: '721691004',
              },
              {
                itemID: '530997',
                icdCode: 'I51.7',
                diagnosisCode: 'ICD: I51.7 SNOMED: 55827005',
                problemName: 'Mild concentric left ventricular hypertrophy',
                assessmentItemId: 530997,
                snomedCode: '55827005',
              },
              {
                itemID: '409111',
                icdCode: 'I11.9',
                diagnosisCode: 'ICD: I11.9 SNOMED: 64715009',
                problemName: 'Hypertensive heart disease without heart failure',
                assessmentItemId: 409111,
                snomedCode: '64715009',
              },
              {
                itemID: '506211',
                icdCode: 'J30.89',
                diagnosisCode: 'ICD: J30.89 SNOMED: 61582004',
                problemName: 'Non-seasonal allergic rhinitis due to other allergic trigger',
                assessmentItemId: 506211,
                snomedCode: '61582004',
              },
              {
                itemID: '514175',
                icdCode: 'J30.9',
                diagnosisCode: 'ICD: J30.9 SNOMED: 61582004',
                problemName: 'Acute allergic rhinitis',
                assessmentItemId: 514175,
                snomedCode: '61582004',
              },
              {
                itemID: '559617',
                icdCode: 'E11.9',
                diagnosisCode: 'ICD: E11.9 SNOMED: 313436004',
                problemName:
                  'Type 2 diabetes mellitus without complication, without long-term current use of insulin',
                assessmentItemId: 559617,
                snomedCode: '313436004',
              },
            ],
            allergies: [],
          };
        },

        getSocialHistoryCategories() {
          return [
            {
              socialHistoryCategoryID: 269073,
              name: 'Tobacco Use:',
              socialInfoItems: [
                {
                  socialInfoID: 491310,
                  parentCategoryID: 269073,
                  name: 'Smoking Status',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4105,
                      name: 'Are you a:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 18924,
                          name: 'Current smoker',
                          displayIndex: 1,
                        },
                        {
                          optionId: 18925,
                          name: 'Former smoker',
                          displayIndex: 2,
                        },
                        {
                          optionId: 18926,
                          name: 'Non-smoker',
                          displayIndex: 3,
                        },
                        {
                          optionId: 18927,
                          name: 'Other tobacco user',
                          displayIndex: 4,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4109,
                      name: 'Are you interested in quitting?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 18932,
                          name: 'Ready to quit',
                          displayIndex: 1,
                        },
                        {
                          optionId: 18933,
                          name: 'Thinking about quitting',
                          displayIndex: 2,
                        },
                        {
                          optionId: 18934,
                          name: 'Not ready to quit',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4799,
                      name: 'How many packs per day do you/ did you smoke?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 23736,
                          name: '1/2 pack',
                          displayIndex: 1,
                        },
                        {
                          optionId: 23737,
                          name: '1 pack',
                          displayIndex: 2,
                        },
                        {
                          optionId: 23738,
                          name: '2 packs',
                          displayIndex: 3,
                        },
                        {
                          optionId: 23739,
                          name: '>2 packs',
                          displayIndex: 4,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4800,
                      name: 'How many years have you smoked?',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4801,
                      name: 'How long ago did you quit smoking?',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4802,
                      name: 'Pack years',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [],
                    },
                  ],
                },
                {
                  socialInfoID: 560143,
                  parentCategoryID: 269073,
                  name: 'Smoking Status Notes:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 276485,
                  parentCategoryID: 269073,
                  name: 'Tobacco use other than smoking:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 3,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 3912,
                      name: 'Are you an other tobacco user?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 1,
                      structuredDataOptions: [],
                    },
                  ],
                },
                {
                  socialInfoID: 397080,
                  parentCategoryID: 269073,
                  name: 'Screening not performed',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 4,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 4048,
                      name: 'Reason:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 18496,
                          name: 'Medical Reason',
                          displayIndex: 1,
                        },
                        {
                          optionId: 18497,
                          name: 'Limited Life Expectancy',
                          displayIndex: 2,
                        },
                        {
                          optionId: 18498,
                          name: 'No reason specified',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 4048,
                      shStructuredDataID: 4049,
                      name: 'Type of Medical Reason:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 18504,
                          name: 'Absent response to treatment',
                          displayIndex: 1,
                        },
                        {
                          optionId: 18519,
                          name: 'Adverse reaction to drug',
                          displayIndex: 2,
                        },
                        {
                          optionId: 18508,
                          name: 'Complication of medical care',
                          displayIndex: 3,
                        },
                        {
                          optionId: 18513,
                          name: 'Contraindicated',
                          displayIndex: 4,
                        },
                        {
                          optionId: 18514,
                          name: 'Drug allergy',
                          displayIndex: 5,
                        },
                        {
                          optionId: 18520,
                          name: 'Drug interaction',
                          displayIndex: 6,
                        },
                        {
                          optionId: 18518,
                          name: 'Drug intolerance',
                          displayIndex: 7,
                        },
                        {
                          optionId: 18507,
                          name: 'Drug resistance',
                          displayIndex: 8,
                        },
                        {
                          optionId: 18506,
                          name: 'Drug therapy discontinued',
                          displayIndex: 9,
                        },
                        {
                          optionId: 18502,
                          name: 'Drug treatment not indicated',
                          displayIndex: 10,
                        },
                        {
                          optionId: 18503,
                          name: 'Failure in dosage',
                          displayIndex: 11,
                        },
                        {
                          optionId: 18499,
                          name: 'History of - drug allergy',
                          displayIndex: 12,
                        },
                        {
                          optionId: 18505,
                          name: 'Late effect of medical and surgical care complication',
                          displayIndex: 13,
                        },
                        {
                          optionId: 18510,
                          name: 'Medical contraindication',
                          displayIndex: 14,
                        },
                        {
                          optionId: 18512,
                          name: 'Not indicated',
                          displayIndex: 15,
                        },
                        {
                          optionId: 18500,
                          name: 'Procedure contraindicated',
                          displayIndex: 16,
                        },
                        {
                          optionId: 18515,
                          name: 'Procedure discontinued',
                          displayIndex: 17,
                        },
                        {
                          optionId: 18516,
                          name: 'Procedure not indicated',
                          displayIndex: 18,
                        },
                        {
                          optionId: 18517,
                          name: 'Treatment changed',
                          displayIndex: 19,
                        },
                        {
                          optionId: 18509,
                          name: 'Treatment modification',
                          displayIndex: 20,
                        },
                        {
                          optionId: 18501,
                          name: 'Treatment not indicated',
                          displayIndex: 21,
                        },
                        {
                          optionId: 18511,
                          name: 'Treatment not tolerated',
                          displayIndex: 22,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 4048,
                      shStructuredDataID: 4050,
                      name: 'Details:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 0,
                      defaultOption: '',
                      parentSHStructuredDataID: 269073,
                      displayIndex: 0,
                      structuredDataOptions: [
                        {
                          optionId: 18524,
                          name: 'Patient status determination, pre-terminal',
                          displayIndex: 1,
                        },
                        {
                          optionId: 18523,
                          name: 'Prognosis bad',
                          displayIndex: 2,
                        },
                        {
                          optionId: 18525,
                          name: 'Terminal illness',
                          displayIndex: 3,
                        },
                        {
                          optionId: 18521,
                          name: 'Terminal illness - early stage',
                          displayIndex: 4,
                        },
                        {
                          optionId: 18522,
                          name: 'Terminal illness - late stage',
                          displayIndex: 5,
                        },
                      ],
                    },
                  ],
                },
                {
                  socialInfoID: 285179,
                  parentCategoryID: 269073,
                  name: 'Notes:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 5,
                  hasOptions: null,
                  structuredDataItems: [],
                },
              ],
            },
            {
              socialHistoryCategoryID: 237358,
              name: 'Drugs/Alcohol:',
              socialInfoItems: [
                {
                  socialInfoID: 269071,
                  parentCategoryID: 237358,
                  name: 'Drugs',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 1367,
                      name: 'Have you used drugs other than those for medical reasons in the past 12 months?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 2,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1376,
                      name: 'Heroin',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 1,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1379,
                      name: 'Cocaine?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 1,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1382,
                      name: 'How many months ago did you last use?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 1,
                      structuredDataOptions: [
                        {
                          optionId: 3588,
                          name: '6-12 months',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3589,
                          name: '12-24 months',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3590,
                          name: 'more than 24 months',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 3959,
                      name: 'Molly',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 2,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1368,
                      name: 'Methamphetamine?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 3,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1369,
                      name: 'Crack?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 4,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1370,
                      name: 'LSD?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 5,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1371,
                      name: 'Ecstacy?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 6,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1372,
                      name: 'Prescription opiates?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 7,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1373,
                      name: 'Marijuana?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 8,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1374,
                      name: 'Ketamine?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 9,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1375,
                      name: 'PCP?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 10,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1392,
                      name: 'Heroin?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 11,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1394,
                      name: 'Is there a minor (18 years or younger) at risk at home?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 11,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1396,
                      name: 'Are you still using?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 13,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1387,
                      name: 'How many months ago did you last use?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 17,
                      structuredDataOptions: [
                        {
                          optionId: 3591,
                          name: '6-12 months',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3592,
                          name: '12-24 months',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3593,
                          name: 'more than 24 months',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1400,
                      name: 'Have ever injected drugs?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 17,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1402,
                      name: 'Are you in a treatment program?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 19,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1406,
                      name: 'How many months ago did you last use?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 23,
                      structuredDataOptions: [
                        {
                          optionId: 3597,
                          name: '6-12 months',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3598,
                          name: '12-24 months',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3599,
                          name: 'more than 24 months',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1409,
                      name: 'Cocaine?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 26,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1367,
                      shStructuredDataID: 1412,
                      name: 'Heroin?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 29,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1376,
                      shStructuredDataID: 1389,
                      name: 'How many months ago did you last use?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 1,
                      structuredDataOptions: [
                        {
                          optionId: 3594,
                          name: '6-12 months',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3595,
                          name: '12-24 months',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3596,
                          name: 'more than 24 months',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1376,
                      shStructuredDataID: 1377,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 12,
                      structuredDataOptions: [
                        {
                          optionId: 3582,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3583,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3584,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1379,
                      shStructuredDataID: 1380,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 1,
                      structuredDataOptions: [
                        {
                          optionId: 3585,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3586,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3587,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1382,
                      shStructuredDataID: 1383,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 3588,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 18,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1382,
                      shStructuredDataID: 1385,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 3588,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 18,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1382,
                      shStructuredDataID: 1386,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 3589,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 19,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1382,
                      shStructuredDataID: 1384,
                      name: 'relapse prevention discussed',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 3589,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 19,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1387,
                      shStructuredDataID: 1388,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 3592,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 18,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1392,
                      shStructuredDataID: 1393,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 12,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1394,
                      shStructuredDataID: 1395,
                      name: 'How many children?',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 12,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1394,
                      shStructuredDataID: 1395,
                      name: 'How many children?',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 12,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1397,
                      name: 'Do you want treatment?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 14,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1397,
                      name: 'Do you want treatment?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 14,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1398,
                      name: 'Do you want to enroll in a needle exchange/ESAP Program?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 15,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1398,
                      name: 'Do you want to enroll in a needle exchange/ESAP Program?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 15,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1399,
                      name: 'Do you need a prescription for clean needles?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 16,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1396,
                      shStructuredDataID: 1399,
                      name: 'Do you need a prescription for clean needles?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 16,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1400,
                      shStructuredDataID: 1401,
                      name: 'Are you currently injecting drugs?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 18,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1400,
                      shStructuredDataID: 1401,
                      name: 'Are you currently injecting drugs?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 18,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1403,
                      name: 'Type of program:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 20,
                      structuredDataOptions: [
                        {
                          optionId: 3607,
                          name: 'Detox',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3608,
                          name: 'Methadone',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3609,
                          name: 'Residential treatment',
                          displayIndex: 3,
                        },
                        {
                          optionId: 3610,
                          name: 'Outpatient',
                          displayIndex: 4,
                        },
                        {
                          optionId: 3611,
                          name: '12 Step',
                          displayIndex: 5,
                        },
                        {
                          optionId: 3612,
                          name: 'Other',
                          displayIndex: 6,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1403,
                      name: 'Type of program:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 20,
                      structuredDataOptions: [
                        {
                          optionId: 3607,
                          name: 'Detox',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3608,
                          name: 'Methadone',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3609,
                          name: 'Residential treatment',
                          displayIndex: 3,
                        },
                        {
                          optionId: 3610,
                          name: 'Outpatient',
                          displayIndex: 4,
                        },
                        {
                          optionId: 3611,
                          name: '12 Step',
                          displayIndex: 5,
                        },
                        {
                          optionId: 3612,
                          name: 'Other',
                          displayIndex: 6,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1404,
                      name: 'Name of program:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 21,
                      structuredDataOptions: [
                        {
                          optionId: 3606,
                          name: 'use notes section',
                          displayIndex: 1,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1404,
                      name: 'Name of program:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 21,
                      structuredDataOptions: [
                        {
                          optionId: 3606,
                          name: 'use notes section',
                          displayIndex: 1,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1405,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 22,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1402,
                      shStructuredDataID: 1405,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 22,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1406,
                      shStructuredDataID: 1407,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3598,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 24,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1406,
                      shStructuredDataID: 1407,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3598,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 24,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1406,
                      shStructuredDataID: 1408,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3597,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 25,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1406,
                      shStructuredDataID: 1408,
                      name: 'relapse prevention discussed?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3597,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 25,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1409,
                      shStructuredDataID: 1410,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 27,
                      structuredDataOptions: [
                        {
                          optionId: 3600,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3601,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3602,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1409,
                      shStructuredDataID: 1410,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 27,
                      structuredDataOptions: [
                        {
                          optionId: 3600,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3601,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3602,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1410,
                      shStructuredDataID: 1411,
                      name: 'Discussed safer injection/needle exchange?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3600,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 28,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1410,
                      shStructuredDataID: 1411,
                      name: 'Discussed safer injection/needle exchange?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3600,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 28,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1412,
                      shStructuredDataID: 1413,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 30,
                      structuredDataOptions: [
                        {
                          optionId: 3603,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3604,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3605,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1412,
                      shStructuredDataID: 1413,
                      name: 'Route?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 30,
                      structuredDataOptions: [
                        {
                          optionId: 3603,
                          name: 'Injected',
                          displayIndex: 1,
                        },
                        {
                          optionId: 3604,
                          name: 'Intranasal',
                          displayIndex: 2,
                        },
                        {
                          optionId: 3605,
                          name: 'Smoked',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 1413,
                      shStructuredDataID: 1414,
                      name: 'Discussed safer injection/needle exchange?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3603,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 31,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 1413,
                      shStructuredDataID: 1414,
                      name: 'Discussed safer injection/needle exchange?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 3603,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 31,
                      structuredDataOptions: [],
                    },
                  ],
                },
                {
                  socialInfoID: 272677,
                  parentCategoryID: 237358,
                  name: 'Alcohol Screen',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2925,
                      name: 'Did you have a drink containing alcohol in the past year?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 5,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2929,
                      name: 'Points',
                      structuredDataType: 'Numeric',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 9,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2930,
                      name: 'Interpretation',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 10,
                      structuredDataOptions: [
                        {
                          optionId: 13036,
                          name: 'Positive',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13037,
                          name: 'Negative',
                          displayIndex: 2,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2925,
                      shStructuredDataID: 2926,
                      name: 'How often did you have 6 or more drinks on one occasion in the past year?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 6,
                      structuredDataOptions: [
                        {
                          optionId: 13038,
                          name: 'Never (0 point)',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13039,
                          name: 'Less than monthly (1 point)',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13040,
                          name: 'Monthly (2 points)',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13041,
                          name: 'Weekly (3 points)',
                          displayIndex: 4,
                        },
                        {
                          optionId: 13042,
                          name: 'Daily or almost daily (4 points)',
                          displayIndex: 5,
                        },
                        {
                          optionId: 24023,
                          name: 'Declined to specify (0 points)',
                          displayIndex: 6,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2925,
                      shStructuredDataID: 2927,
                      name: 'How many drinks did you have on a typical day when you were drinking in the past year?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 7,
                      structuredDataOptions: [
                        {
                          optionId: 13043,
                          name: '1 or 2 drinks (0 point)',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13044,
                          name: '3 or 4 drinks (1 point)',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13045,
                          name: '5 or 6 drinks (2 points)',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13046,
                          name: '7 to 9 drinks (3 points)',
                          displayIndex: 4,
                        },
                        {
                          optionId: 13047,
                          name: '10 or more drinks (4 points)',
                          displayIndex: 5,
                        },
                        {
                          optionId: 24022,
                          name: 'Declined to specify (0 points)',
                          displayIndex: 6,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2925,
                      shStructuredDataID: 2928,
                      name: 'How often did you have a drink containing alcohol in the past year?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 237358,
                      displayIndex: 8,
                      structuredDataOptions: [
                        {
                          optionId: 13048,
                          name: 'Never (0 point)',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13049,
                          name: 'Monthly or less (1 point)',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13050,
                          name: '2 to 4 times a month (2 points)',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13051,
                          name: '2 to 3 times a week (3 points)',
                          displayIndex: 4,
                        },
                        {
                          optionId: 13052,
                          name: '4 or more times a week (4 points)',
                          displayIndex: 5,
                        },
                        {
                          optionId: 24021,
                          name: 'Declined to specify (0 points)',
                          displayIndex: 6,
                        },
                      ],
                    },
                  ],
                },
                {
                  socialInfoID: 285182,
                  parentCategoryID: 237358,
                  name: 'Notes:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 560146,
                  parentCategoryID: 237358,
                  name: 'Alcohol Screen Interpretation',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
              ],
            },
            {
              socialHistoryCategoryID: 269074,
              name: 'Sexual History:',
              socialInfoItems: [
                {
                  socialInfoID: 272678,
                  parentCategoryID: 269074,
                  name: 'Sexual History',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2906,
                      name: 'Had sex in the past 12 months (vaginal, oral, or anal)?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 1,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2913,
                      name: 'Last menstrual period',
                      structuredDataType: 'Date',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 2,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 0,
                      shStructuredDataID: 2914,
                      name: 'Have you ever had a Sexually transmitted disease?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: -1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 3,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2906,
                      shStructuredDataID: 2909,
                      name: 'Present symptoms?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 1,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2906,
                      shStructuredDataID: 2907,
                      name: 'Prevention strategies discussed:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 3,
                      structuredDataOptions: [
                        {
                          optionId: 13021,
                          name: 'Abstinence',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13022,
                          name: 'Condoms',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13023,
                          name: 'Other',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2906,
                      shStructuredDataID: 2908,
                      name: 'Adherence barriers identified:',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 4,
                      structuredDataOptions: [
                        {
                          optionId: 13015,
                          name: 'Patient non-adherence',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13016,
                          name: 'Language',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13017,
                          name: 'Culture',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13018,
                          name: 'Religion',
                          displayIndex: 4,
                        },
                        {
                          optionId: 13019,
                          name: 'Economic',
                          displayIndex: 5,
                        },
                        {
                          optionId: 13020,
                          name: 'Other',
                          displayIndex: 6,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2906,
                      shStructuredDataID: 2910,
                      name: 'with',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 6,
                      structuredDataOptions: [
                        {
                          optionId: 13012,
                          name: 'Men only',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13013,
                          name: 'Women only',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13014,
                          name: 'Both Men and Women',
                          displayIndex: 3,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2906,
                      shStructuredDataID: 2911,
                      name: 'Use protection?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 7,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2909,
                      shStructuredDataID: 2916,
                      name: 'Syphilis?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 12,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2911,
                      shStructuredDataID: 2912,
                      name: 'How often?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 8,
                      structuredDataOptions: [
                        {
                          optionId: 13024,
                          name: 'All of the time',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13025,
                          name: 'Most of the time',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13026,
                          name: 'Half the time',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13027,
                          name: 'Some of the time',
                          displayIndex: 4,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2911,
                      shStructuredDataID: 2912,
                      name: 'How often?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: true,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 8,
                      structuredDataOptions: [
                        {
                          optionId: 13024,
                          name: 'All of the time',
                          displayIndex: 1,
                        },
                        {
                          optionId: 13025,
                          name: 'Most of the time',
                          displayIndex: 2,
                        },
                        {
                          optionId: 13026,
                          name: 'Half the time',
                          displayIndex: 3,
                        },
                        {
                          optionId: 13027,
                          name: 'Some of the time',
                          displayIndex: 4,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2911,
                      shStructuredDataID: 2921,
                      name: 'On the last sexual encounter, what prevented you from using protection?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 16,
                      structuredDataOptions: [
                        {
                          optionId: 13035,
                          name: 'use notes',
                          displayIndex: 1,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2911,
                      shStructuredDataID: 2921,
                      name: 'On the last sexual encounter, what prevented you from using protection?',
                      structuredDataType: 'Structured Text',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 16,
                      structuredDataOptions: [
                        {
                          optionId: 13035,
                          name: 'use notes',
                          displayIndex: 1,
                        },
                      ],
                    },
                    {
                      parentSHSocialInfoID: 2914,
                      shStructuredDataID: 2915,
                      name: 'Other?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 11,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2914,
                      shStructuredDataID: 2917,
                      name: 'Syphilis?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 12,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2914,
                      shStructuredDataID: 2918,
                      name: 'Herpes?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 13,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2914,
                      shStructuredDataID: 2919,
                      name: 'GC?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 14,
                      structuredDataOptions: [],
                    },
                    {
                      parentSHSocialInfoID: 2914,
                      shStructuredDataID: 2920,
                      name: 'Chlamydia?',
                      structuredDataType: 'Boolean',
                      multiSelectFlag: false,
                      mandatoryFlag: false,
                      parentTriggerFlag: 1,
                      defaultOption: '',
                      parentSHStructuredDataID: 269074,
                      displayIndex: 15,
                      structuredDataOptions: [],
                    },
                  ],
                },
                {
                  socialInfoID: 285181,
                  parentCategoryID: 269074,
                  name: 'Notes:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
              ],
            },
            {
              socialHistoryCategoryID: 269075,
              name: 'Miscellaneous:',
              socialInfoItems: [
                {
                  socialInfoID: 269090,
                  parentCategoryID: 269075,
                  name: 'Children:',
                  options: 'yes,no',
                  structuredFlag: false,
                  displayIndex: 2,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269079,
                  parentCategoryID: 269075,
                  name: 'Exercise:',
                  options: 'no,yes',
                  structuredFlag: false,
                  displayIndex: 5,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269097,
                  parentCategoryID: 269075,
                  name: 'Living with:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 9,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269081,
                  parentCategoryID: 269075,
                  name: 'Marital status:',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 10,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269082,
                  parentCategoryID: 269075,
                  name: 'New since last visit:',
                  options: 'none,',
                  structuredFlag: false,
                  displayIndex: 12,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269088,
                  parentCategoryID: 269075,
                  name: 'Occupation:',
                  options: 'weeks/months/years',
                  structuredFlag: false,
                  displayIndex: 13,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269083,
                  parentCategoryID: 269075,
                  name: 'Occupational exposure:',
                  options: 'none,',
                  structuredFlag: false,
                  displayIndex: 14,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269092,
                  parentCategoryID: 269075,
                  name: 'Pets:',
                  options: 'none,',
                  structuredFlag: false,
                  displayIndex: 16,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269085,
                  parentCategoryID: 269075,
                  name: 'Sexually active:',
                  options: 'no,yes',
                  structuredFlag: false,
                  displayIndex: 18,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 269089,
                  parentCategoryID: 269075,
                  name: 'Travel outside of the United States:',
                  options: 'Yes,No',
                  structuredFlag: false,
                  displayIndex: 19,
                  hasOptions: null,
                  structuredDataItems: [],
                },
              ],
            },
            {
              socialHistoryCategoryID: 538968,
              name: 'Occupation:',
              socialInfoItems: [
                {
                  socialInfoID: 538969,
                  parentCategoryID: 538968,
                  name: 'Current Occupation',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 538970,
                  parentCategoryID: 538968,
                  name: 'Occupational History',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
                {
                  socialInfoID: 541012,
                  parentCategoryID: 538968,
                  name: 'Occupational Exposure',
                  options: '',
                  structuredFlag: false,
                  displayIndex: 1,
                  hasOptions: null,
                  structuredDataItems: [],
                },
              ],
            },
            {
              socialHistoryCategoryID: 237359,
              name: 'Household:',
              socialInfoItems: [],
            },
          ];
        },
        getSocialHistory() {
          return [
            {
              option: '',
              details:
                'Have you used drugs other than those for medical reasons in the past 12 months? Yes Cigars, Chew',
              categoryId: 237358,
              observationId: 269071,
              encounterSocialHistoryStructuredDataList: [
                {
                  notes: 'Cigars, Chew',
                  value: 'Yes',
                  socialHistoryStructuredDataDetailId: 1367,
                },
              ],
            },
            {
              option: '',
              details:
                ' Did you have a drink containing alcohol in the past year?: Yes, How often did you have a drink containing alcohol in the past year?: 2 to 3 times a week (3 points), How many drinks did you have on a typical day when you were drinking in the past year?: 1 or 2 drinks (0 point), How often did you have 6 or more drinks on one occasion in the past year?: Daily or almost daily (4 points), Points: 7',
              categoryId: 237358,
              observationId: 272677,
              encounterSocialHistoryStructuredDataList: [],
            },
            {
              option: '',
              details:
                ' Are you a: current smoker , How often do you smoke cigarettes?: every day, How many cigarettes a day do you smoke?: 11-20, How soon after you wake up do you smoke your first cigarette?: after 60 minutes, Are you interested in quitting?: Not ready to quit',
              categoryId: 269073,
              observationId: 272676,
              encounterSocialHistoryStructuredDataList: [],
            },
            {
              option: '',
              details: 'spouse',
              categoryId: 269075,
              observationId: 269097,
              encounterSocialHistoryStructuredDataList: [],
            },
            {
              option: '',
              details: 'works full-time',
              categoryId: 269075,
              observationId: 269088,
              encounterSocialHistoryStructuredDataList: [],
            },
            {
              option: '',
              details: 'dogs',
              categoryId: 269075,
              observationId: 269092,
              encounterSocialHistoryStructuredDataList: [],
            },
            {
              option: '',
              details: 'travels to South America',
              categoryId: 269075,
              observationId: 269089,
              encounterSocialHistoryStructuredDataList: [],
            },
          ];
        },

        getHPICategories() {
          return [
            {
              hpiCategoryID: 198759,
              name: '24 Hour Recall',
            },
            {
              hpiCategoryID: 198752,
              name: '24 Hour Recall',
            },
            {
              hpiCategoryID: 199005,
              name: 'Abdomen',
            },
          ];
        },
        getEncounterHPI() {
          return {
            hpiCategoryData: [
              {
                hpiCategoryId: 199005,
                name: 'Abdomen',
                encounterHPIDataItems: [
                  {
                    hpiCategoryId: 199005,
                    hpiSymptomId: 16131,
                    complainsDenies: true,
                    duration: '',
                    symptomNotes: 'YOUTUBER',
                  },
                  {
                    hpiCategoryId: 199005,
                    hpiSymptomId: 16132,
                    complainsDenies: false,
                    duration: '',
                    symptomNotes: '',
                  },
                ],
              },
            ],
          };
        },
        getHPICategoryDetails() {
          return {
            hpiCategoryID: 199005,
            name: 'Abdomen',
            hpiSymptoms: [
              {
                hpiSymptomID: 16131,
                name: 'Abdominal pain',
                symptomOptions: [
                  {
                    optionID: 184066,
                    name: '______',
                  },
                ],
              },
              {
                hpiSymptomID: 16132,
                name: 'Appetite',
                symptomOptions: [
                  {
                    optionID: 36623,
                    name: 'none',
                  },
                  {
                    optionID: 36620,
                    name: 'normal',
                  },
                  {
                    optionID: 184067,
                    name: '...',
                  },
                  {
                    optionID: 36621,
                    name: 'increased',
                  },
                  {
                    optionID: 36622,
                    name: 'decreased',
                  },
                  {
                    optionID: 184068,
                    name: '_____',
                  },
                ],
              },
              {
                hpiSymptomID: 16135,
                name: 'Diarrhea',
                symptomOptions: [
                  {
                    optionID: 36635,
                    name: 'yes',
                  },
                  {
                    optionID: 36636,
                    name: 'no',
                  },
                  {
                    optionID: 36640,
                    name: 'bloody',
                  },
                  {
                    optionID: 36638,
                    name: 'loose',
                  },
                  {
                    optionID: 36642,
                    name: 'mucousy',
                  },
                  {
                    optionID: 36637,
                    name: 'watery',
                  },
                  {
                    optionID: 36639,
                    name: 'frequent',
                  },
                  {
                    optionID: 36641,
                    name: 'non-bloody',
                  },
                  {
                    optionID: 184069,
                    name: '______',
                  },
                ],
              },
              {
                hpiSymptomID: 16133,
                name: 'Nausea',
                symptomOptions: [
                  {
                    optionID: 36624,
                    name: 'yes',
                  },
                  {
                    optionID: 36625,
                    name: 'no',
                  },
                ],
              },
              {
                hpiSymptomID: 16136,
                name: 'Stomach ache',
                symptomOptions: [],
              },
              {
                hpiSymptomID: 16134,
                name: 'Vomiting',
                symptomOptions: [
                  {
                    optionID: 36626,
                    name: 'accompanied by headache',
                  },
                  {
                    optionID: 36627,
                    name: 'after an injury',
                  },
                  {
                    optionID: 36628,
                    name: 'bilious',
                  },
                  {
                    optionID: 36629,
                    name: 'non-bilious',
                  },
                  {
                    optionID: 36630,
                    name: 'projectile',
                  },
                  {
                    optionID: 36631,
                    name: 'blood',
                  },
                  {
                    optionID: 36632,
                    name: 'no blood',
                  },
                  {
                    optionID: 36633,
                    name: 'post-tussive',
                  },
                  {
                    optionID: 36634,
                    name: 'post-prandial',
                  },
                ],
              },
            ],
          };
        },
        getGynHistory() {
          // return [];

          return [
            {
              gynSymptomID: 269100,
              gynHistoryStructuredData: [
                {
                  gynStructuredDataDetailID: 1440,
                  value: 'Yes',
                },
                {
                  gynStructuredDataDetailID: 1441,
                  value: '66',
                },
                {
                  gynStructuredDataDetailID: 1442,
                  value: '2',
                },
              ],
              denies: true,
              symptomNotes: 'hhhhhmmmm',
            },
          ];
        },
        getGynLookups() {
          return [
            {
              symptomID: 138224,
              symptomName: 'SOMTO!!! Periods :',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 1,
                  gynHistoryDetailValue: 'regular',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 2,
                  gynHistoryDetailValue: 'every month',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 5,
                  gynHistoryDetailValue: 'irregular',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 3,
                  gynHistoryDetailValue: 'every 20-25 days',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 81,
                  gynHistoryDetailValue: 'none',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 4,
                  gynHistoryDetailValue: 'every 35- 40 days',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 6,
                  gynHistoryDetailValue: '...',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 7,
                  gynHistoryDetailValue: 'normal blood loss',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 60,
                  gynHistoryDetailValue: 'heavy blood loss',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 61,
                  gynHistoryDetailValue: 'lasting 3-5 days',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 62,
                  gynHistoryDetailValue: 'lasting 5-7 days',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 63,
                  gynHistoryDetailValue: 'lasting >7 days',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 64,
                  gynHistoryDetailValue: '...',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 65,
                  gynHistoryDetailValue: 'associated with mild cramping',
                  defaultFlag: false,
                  displayIndex: 11,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 66,
                  gynHistoryDetailValue: 'associated with severe cramping',
                  defaultFlag: false,
                  displayIndex: 12,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 67,
                  gynHistoryDetailValue: 'associated with nausea/vomiting',
                  defaultFlag: false,
                  displayIndex: 13,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 68,
                  gynHistoryDetailValue: 'associated with mood changes',
                  defaultFlag: false,
                  displayIndex: 14,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 138225,
              symptomName: 'Sexual activity',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 8,
                  gynHistoryDetailValue: 'currently sexually active',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 74,
                  gynHistoryDetailValue: 'currently active with one partner',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 75,
                  gynHistoryDetailValue: 'currently active with multiple partners',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 9,
                  gynHistoryDetailValue: 'not currently sexually active',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 10,
                  gynHistoryDetailValue: 'number of sex partners in life',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 11,
                  gynHistoryDetailValue: 'sexual abuse',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 12,
                  gynHistoryDetailValue: 'sexually transmitted diseases',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 54,
                  gynHistoryDetailValue: '...',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 50,
                  gynHistoryDetailValue: 'with men',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 51,
                  gynHistoryDetailValue: 'with women',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 52,
                  gynHistoryDetailValue: 'with both men and women',
                  defaultFlag: false,
                  displayIndex: 11,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 53,
                  gynHistoryDetailValue: 'with neither',
                  defaultFlag: false,
                  displayIndex: 12,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 138226,
              symptomName: 'Last pap smear date',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 57,
                  gynHistoryDetailValue: 'within last 1-2 years',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 58,
                  gynHistoryDetailValue: '>2 years',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 59,
                  gynHistoryDetailValue: 'no previous pap smear',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 138227,
              symptomName: 'Last mammogram date',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 55,
                  gynHistoryDetailValue: 'within 1-2 yrs',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 56,
                  gynHistoryDetailValue: '>2 years ago',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 83,
                  gynHistoryDetailValue: 'no previous mammogram',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 138228,
              symptomName: 'Abnormal pap smear',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 85,
                  gynHistoryDetailValue: 'Yes',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 86,
                  gynHistoryDetailValue: 'No',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 84,
                  gynHistoryDetailValue: '......',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 13,
                  gynHistoryDetailValue:
                    'atypical squamous cell changes of undetermined significance',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 14,
                  gynHistoryDetailValue: 'CIS',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 15,
                  gynHistoryDetailValue: 'Low Grade Squamous Intraepitheliel Lesion (LGSIL)',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 16,
                  gynHistoryDetailValue: 'High Grade Squamous Intraepitheliel Lesion (HGSIL)',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 87,
                  gynHistoryDetailValue: '------',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 17,
                  gynHistoryDetailValue: 'treated with cryo',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 18,
                  gynHistoryDetailValue: 'treated with laser',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 19,
                  gynHistoryDetailValue:
                    'treated with loop electrosurgical excision procedure (LEEP)',
                  defaultFlag: false,
                  displayIndex: 11,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 138438,
              symptomName: 'Date of Last Period',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 187593,
              symptomName: 'Sexually Transmitted Diseases (STDs)',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 20,
                  gynHistoryDetailValue: 'None',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 23,
                  gynHistoryDetailValue: 'Chlamydia',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 24,
                  gynHistoryDetailValue: 'Gonorrhea',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 76,
                  gynHistoryDetailValue: 'Hep C',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 22,
                  gynHistoryDetailValue: 'Herpes simplex virus (HSV)',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 25,
                  gynHistoryDetailValue: 'Human immunodeficiency virus (HIV)',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 21,
                  gynHistoryDetailValue: 'Human papilloma virus (HPV)/condyloma',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 27,
                  gynHistoryDetailValue: 'Herpes',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 77,
                  gynHistoryDetailValue: 'Syphilis',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 26,
                  gynHistoryDetailValue: 'Trichomoniasis (Trich)',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 82,
                  gynHistoryDetailValue: 'Unsure',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 187594,
              symptomName: 'Birth control',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 36,
                  gynHistoryDetailValue: 'none',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 34,
                  gynHistoryDetailValue: 'bilateral tubal ligation',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 28,
                  gynHistoryDetailValue: 'condoms',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 39,
                  gynHistoryDetailValue: 'Depo',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 32,
                  gynHistoryDetailValue: 'diaphram',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 30,
                  gynHistoryDetailValue: 'Mirena intrauterine device',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 33,
                  gynHistoryDetailValue: 'Nuva Ring',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 29,
                  gynHistoryDetailValue: 'oral contraceptive pill',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 31,
                  gynHistoryDetailValue: 'Paragard intrauterine device',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 41,
                  gynHistoryDetailValue: 'spermicide',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 35,
                  gynHistoryDetailValue: 'vasectomy',
                  defaultFlag: false,
                  displayIndex: 11,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 37,
                  gynHistoryDetailValue: 'Ortho Tri-Cyclen',
                  defaultFlag: false,
                  displayIndex: 12,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 38,
                  gynHistoryDetailValue: 'Ortho Tri-Cyclen Lo',
                  defaultFlag: false,
                  displayIndex: 13,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 40,
                  gynHistoryDetailValue: 'Ortho Evra patch',
                  defaultFlag: false,
                  displayIndex: 14,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 269100,
              symptomName: 'Menarche:',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [],
              structuredData: [
                {
                  id: 1440,
                  name: 'Past the age of menarche:',
                  dataType: 'Boolean',
                  mandatory: false,
                  triggerflag: -1,
                  defaultOption: '',
                  parentId: 0,
                  nodeLevel: 0,
                  multiSelect: false,
                  displayIndex: 2,
                  __typename: 'GynStructuredData',
                },
                {
                  id: 1442,
                  name: 'Age of onset of maternal menarche:',
                  dataType: 'Numeric',
                  mandatory: false,
                  triggerflag: -1,
                  defaultOption: '',
                  parentId: 0,
                  nodeLevel: 0,
                  multiSelect: false,
                  displayIndex: 4,
                  __typename: 'GynStructuredData',
                },
                {
                  id: 1441,
                  name: 'Age of menarche:',
                  dataType: 'Numeric',
                  mandatory: false,
                  triggerflag: 1,
                  defaultOption: '',
                  parentId: 1440,
                  nodeLevel: 1,
                  multiSelect: false,
                  displayIndex: 3,
                  __typename: 'GynStructuredData',
                },
              ],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 269103,
              symptomName: 'Menopause:',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [],
              structuredData: [
                {
                  id: 1449,
                  name: 'Began at age:',
                  dataType: 'Numeric',
                  mandatory: false,
                  triggerflag: -1,
                  defaultOption: '',
                  parentId: 0,
                  nodeLevel: 0,
                  multiSelect: false,
                  displayIndex: 2,
                  __typename: 'GynStructuredData',
                },
              ],
              __typename: 'GynSymptom',
            },
            {
              symptomID: 269449,
              symptomName: 'Hysterectomy',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  gynHistoryDetailID: 46,
                  gynHistoryDetailValue: 'Date:',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 80,
                  gynHistoryDetailValue: 'Total',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 79,
                  gynHistoryDetailValue: 'Partial',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 78,
                  gynHistoryDetailValue: ' ',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 69,
                  gynHistoryDetailValue: 'due to heavy cycles',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 70,
                  gynHistoryDetailValue: 'due to endometriosis',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 71,
                  gynHistoryDetailValue: 'due to fibroids',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 72,
                  gynHistoryDetailValue: 'due to cancer',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'GynSymptomOption',
                },
                {
                  gynHistoryDetailID: 73,
                  gynHistoryDetailValue: 'other/specifiy reason',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'GynSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'GynSymptom',
            },
          ];
        },
        getObLookups() {
          return [
            {
              symptomID: 138213,
              symptomName: 'Total pregnancies',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 13,
                  obHistoryDetailValue: '0',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 1,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 2,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 3,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 4,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 5,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 137,
                  obHistoryDetailValue: '6',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 138,
                  obHistoryDetailValue: '7',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 139,
                  obHistoryDetailValue: '8',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 140,
                  obHistoryDetailValue: '9',
                  defaultFlag: false,
                  displayIndex: 10,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 141,
                  obHistoryDetailValue: '10',
                  defaultFlag: false,
                  displayIndex: 11,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 142,
                  obHistoryDetailValue: '11',
                  defaultFlag: false,
                  displayIndex: 12,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 143,
                  obHistoryDetailValue: '12',
                  defaultFlag: false,
                  displayIndex: 13,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 138214,
              symptomName: 'Total living children',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 6,
                  obHistoryDetailValue: '0',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 7,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 8,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 9,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 10,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 11,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 12,
                  obHistoryDetailValue: 'ages of children',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 138215,
              symptomName: 'Stillbirth(s)',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 14,
                  obHistoryDetailValue: '0',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 15,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 16,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 17,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 18,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 19,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 138216,
              symptomName: 'Miscarriage(s)',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 20,
                  obHistoryDetailValue: '0',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 21,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 22,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 23,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 24,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 25,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 138217,
              symptomName: 'Abortion(s)',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 26,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 27,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 28,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 29,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 30,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 138218,
              symptomName: 'C section(s)',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 31,
                  obHistoryDetailValue: '1',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 32,
                  obHistoryDetailValue: '2',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 33,
                  obHistoryDetailValue: '3',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 34,
                  obHistoryDetailValue: '4',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 35,
                  obHistoryDetailValue: '5',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 187595,
              symptomName: 'Complications during pregnancy:',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 36,
                  obHistoryDetailValue: 'spontaneous abortion',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 45,
                  obHistoryDetailValue: 'no complications',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 125,
                  obHistoryDetailValue: 'ectopic',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 37,
                  obHistoryDetailValue: 'normal spontaneous vaginal delivery (NSVD)',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 38,
                  obHistoryDetailValue: 'Primary cesarean',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 126,
                  obHistoryDetailValue: 'hypertension',
                  defaultFlag: false,
                  displayIndex: 3,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 127,
                  obHistoryDetailValue: 'gestational diabetes',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 39,
                  obHistoryDetailValue: 'Repeat cesarean',
                  defaultFlag: false,
                  displayIndex: 4,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 40,
                  obHistoryDetailValue: 'Birth Weight:',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 128,
                  obHistoryDetailValue: 'blood disorder',
                  defaultFlag: false,
                  displayIndex: 5,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 129,
                  obHistoryDetailValue: 'emergency cesarean',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 41,
                  obHistoryDetailValue: 'elective terminations of pregnancy (ETOP)',
                  defaultFlag: false,
                  displayIndex: 6,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 42,
                  obHistoryDetailValue: 'dilatation and curettage (D&C)',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 130,
                  obHistoryDetailValue: 'blood clots',
                  defaultFlag: false,
                  displayIndex: 7,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 43,
                  obHistoryDetailValue: 'vaginal birth after cesarean (VBAC)',
                  defaultFlag: false,
                  displayIndex: 8,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 44,
                  obHistoryDetailValue: 'ectopic',
                  defaultFlag: false,
                  displayIndex: 9,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 199644,
              symptomName: 'Full-term delivery',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 131,
                  obHistoryDetailValue: 'yes',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 132,
                  obHistoryDetailValue: 'no',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 199646,
              symptomName: 'NVD',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 133,
                  obHistoryDetailValue: 'yes',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 134,
                  obHistoryDetailValue: 'no',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 199647,
              symptomName: 'Pre-term delivery',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 135,
                  obHistoryDetailValue: 'yes',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
                {
                  obHistoryDetailID: 136,
                  obHistoryDetailValue: 'no',
                  defaultFlag: false,
                  displayIndex: 2,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
            {
              symptomID: 308135,
              symptomName: 'Gestational Diabetes',
              structuredFlag: false,
              displayIndex: 1,
              symptomOptions: [
                {
                  obHistoryDetailID: 144,
                  obHistoryDetailValue: 'denies',
                  defaultFlag: false,
                  displayIndex: 1,
                  __typename: 'ObSymptomOption',
                },
              ],
              structuredData: [],
              __typename: 'ObSymptom',
            },
          ];
        },
        getObHistory() {
          // return [];
          return [
            {
              __typename: 'ObHistory',
              sourcePatientId: -30,
              obSymptomID: 138213,
              symptomNotes: 'I think so',
              obHistoryStructuredData: [
                {
                  __typename: 'ObHistoryStructuredData',
                  obStructuredDataDetailID: 14,
                  value: 'Some value',
                  notes: 'Some note',
                },
              ],
            },
          ];
        },
      },
    };
  },
});

// Set up a server that reads a GraphQL document and returns the data for it
export const serverWorker = setupWorker(
  ...[
    rest.post('https://api.dev.ensomata.io/graphql', async (req, res, ctx) => {
      const result = await execute({
        schema: mockedSchema,
        document: gql`
          ${req.body.query}
        `,
        variableValues: req.body.variables,
      });

      return res(ctx.json(result));
    }),
  ],
);
