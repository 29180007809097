import { Heading } from '@chakra-ui/react';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
// import { Icon, IconButton, Box, Button, SimpleGrid } from "../..";
import { Box, Flex, SimpleGrid } from '../../layout';
import { Button } from '../../form';
import { Icon } from '../../general';
import { useNavigation } from '../../../hooks';
import { theme } from '../../../theme';

interface PageProps {
  children: React.ReactNode;
  variant?: 'scrollable' | 'fixed';
  p?: string | number;
  pb?: string | number;
  headerTitle?: string;
  hideBackButton?: boolean;
}

export function Page({ children, p = 4, pb, headerTitle, hideBackButton = false }: PageProps) {
  const navigation = useNavigation();

  // const styles = {
  //   p,
  //   flex: 1, // Flex 1 fixes issue where Tabs don't appear in SafeAreaView
  // };

  const renderHeader = () => {
    return (
      <Box
        position='sticky'
        top='0'
        display='flex'
        p={3}
        borderBottomWidth='1px'
        backgroundColor='white'
        alignItems='center'
        zIndex={theme.zIndex.pageHeader} // this is needed otherwise it would get covered by certain elements like dropdown
      >
        <SimpleGrid columns={3} width='100%' alignItems='center'>
          <Box>
            {!hideBackButton && (
              <Button
                variant='ghost'
                leftIcon={<Icon boxSize={10} as={BiChevronLeft} />}
                onClick={() => {
                  navigation.back();
                }}
                // color="secondary"
                // colorScheme="blue"
              >
                Back
              </Button>
            )}
          </Box>
          <Box textAlign='center'>
            <Heading as='h5' size='sm'>
              {headerTitle}
            </Heading>
          </Box>
        </SimpleGrid>
      </Box>
    );
  };

  return (
    <Box position='relative' bg='white' height='100vh' display='flex' flexDirection='column'>
      {headerTitle && renderHeader()}

      <Flex
        flexDirection='column'
        p={p}
        pb={pb}
        // overflow="auto" // TODO: For MA workflow, e.g in Medications with a lot of added entries, there is a wierd 2nd scroll that allows user scroll to emptiness at page bottom
        height='100%'>
        {children}
      </Flex>
    </Box>
  );
}
