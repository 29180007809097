import { Auth } from 'aws-amplify';
import * as FullStory from '@fullstory/browser';
import { IS_PROD } from '../constants';

/**
 * This sends error string to "ensomata-api-errors" channel in Slack.
 * @param text error string to be sent to Slack
 */
export async function sendTextToEnsomataApiErrorsChannelInSlack(text: string) {
  if (IS_PROD) {
    const userStoryURL = FullStory.getCurrentSessionURL(true);
    const authData = await Auth.currentAuthenticatedUser();
    const userEmail = authData?.getSignInUserSession()?.getIdToken()?.payload?.email;
    const errorStringForSlack = `${userEmail}\n${text}\n${userStoryURL}`;

    fetch('https://hooks.slack.com/services/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', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: JSON.stringify({
        text: errorStringForSlack,
      }),
    });
  }
}
