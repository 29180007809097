import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import { USE_LOCAL_MOCK } from '../constants';
import { useToast } from './useToast';
import { useState } from 'react';

interface UseAuthReturnValue {
  handleLogout: () => void;
  handleLogin: () => void;
  isAuthenticated?: boolean;
  user: UserUI;
  userId: UserUI['userId'];
  authStatus: string;
  isLoading: boolean;
  groups: string[];
  roles: Role[];
}

// // https://ui.docs.amplify.aws/components/authenticator#access-auth-state
// type CognitoAuthState =
//   | "idle"
//   | "setup"
//   | "signIn"
//   | "signUp"
//   | "confirmSignIn"
//   | "confirmSignUp"
//   | "setupTOTP"
//   | "forceNewPassword"
//   | "resetPassword"
//   | "confirmResetPassword"
//   | "verifyUser"
//   | "confirmVerifyUser"
//   | "signOut"
//   | "authenticated";

// TODO: Type as AmplifyUser, but how to import?
function transformCognitoUserToUser(user: any): UserUI {
  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;
  // get auth token from user
  const authToken = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();

  return {
    authToken,
    userId: user?.getUsername(),
    email: payload?.email,
    firstName: payload?.given_name,
    lastName: payload?.family_name,
    ecwId: payload?.ecwid,
    cognito: {
      /**
        AD SECURITY GROUPS / ROLES
        'e34c38ec-4c22-4f0e-b7c4-3b6ebfb7f810': 'provider',
        '0d33860c-2b4d-4514-b2da-9082cf69827b': 'devs',
        '17b4f823-e62b-4dec-83e4-0120ef0f8980': 'rad-techs',
        '340f7efe-9b5c-407d-b568-11452a5b5e1b': 'legacy-ma-user',
        '7a476a00-aa17-4338-81db-c3dc6017da5c': 'ma-workflow',
       */
      groups: payload?.['cognito:groups'],
      roles: payload?.['cognito:roles'],
    },
  };
}

export function useAuth(): UseAuthReturnValue {
  const [errorMsg, setErrorMsg] = useState('');

  const {
    // route, // CognitoAuthState
    authStatus,
    user: cognitoUser,
    isPending,
    // toFederatedSignIn,
    signOut,
  } = useAuthenticator((context) => [context.route]);
  const isAuthenticated = authStatus === 'authenticated' || USE_LOCAL_MOCK;
  // While signing out isPending works.
  const isLoading = isPending || authStatus === 'configuring';

  const handleLogout = signOut;
  const handleLogin = async () => await Auth.federatedSignIn();
  const user = transformCognitoUserToUser(cognitoUser);

  useToast({
    id: 'authenticationErrorMsg',
    title: 'Error',
    description: errorMsg,
    show: !!errorMsg,
    status: 'error',
    duration: 999999,
  });

  Hub.listen('auth', (data) => {
    if (data.payload.event === 'signIn_failure') {
      setErrorMsg(data.payload.data.message);
    }
  });

  return {
    isLoading,
    handleLogin,
    handleLogout,
    isAuthenticated,
    authStatus,
    user,
    userId: user.userId,
    groups: user?.cognito?.groups ?? [],
    roles: user?.cognito?.roles ?? [],
  };
}
