import { Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useGetPatientAllergiesQuery } from '../../../../__generated__/graphql';
import { formatISODate } from '../../../../utils';

const headers = ['Allergy', 'Reaction', 'Type', 'Status', 'Criticality', 'Onset Date'];

interface Props {
  patientId: number;
}

export function AllergiesTab({ patientId }: Props) {
  const { data } = useGetPatientAllergiesQuery({
    variables: {
      patientId,
    },
  });

  return (
    <Flex flexDirection='column' alignItems='flex-start'>
      <TableContainer margin='24px 0' width='100%'>
        <Table fontSize='sm'>
          <Thead>
            <Tr>
              {headers.map((header) => {
                return (
                  <Th key={header}>
                    <Text>{header}</Text>
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          <Tbody>
            {data?.getAllergies?.allergies?.map((data, index) => {
              return (
                <Tr key={index}>
                  <Td>{data.agentSubstance}</Td>
                  <Td>{data.reaction}</Td>
                  <Td>{data.type}</Td>
                  <Td>{data.active ? 'Active' : 'Inactive'}</Td>
                  <Td>{data.criticality}</Td>
                  <Td>{formatISODate(data.onset)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
