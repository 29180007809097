import { ComboBox } from '../../../../../components';
import {
  encounterFilterActions,
  getEncounterFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../../../hooks';
import { useGetOrderableItemsLookupQuery } from '../../../../../__generated__/graphql';

export function Procedures() {
  const filterState = useAppSelector(getEncounterFilterState);
  const dispatch = useDispatch();

  const { data, loading } = useGetOrderableItemsLookupQuery({
    variables: {
      filter: {
        name: filterState.procedureQuery,
      },
    },
  });

  const procedures = data?.orderableItemsLookup || [];

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  useEffect(() => {
    const update = encounterFilterActions.onFilterStateChange({
      procedureQuery: debouncedSearch,
    });
    dispatch(update);
  }, [debouncedSearch]);

  return (
    <ComboBox
      key={JSON.stringify(filterState.procedures)}
      isLoading={loading}
      placeholder='Labs/Imaging/Procedures'
      onSelection={(procedures) => {
        const update = encounterFilterActions.onFilterStateChange({ procedures });
        dispatch(update);
      }}
      isMulti
      defaultValue={filterState.procedures}
      onInputChange={setSearch}
      options={
        procedures?.map((labImagingProcedure) => ({
          label: `${labImagingProcedure.name}`,
          value: `${labImagingProcedure.sourceId}`,
        })) ?? []
      }
    />
  );
}
