import {
  appointmentsActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { type AlertId } from '../../../types';
import { Button, Radio, RadioGroup } from '../../form';
import { Box, Stack, VStack } from '../../layout';
import { Text } from '../../typography';
import { OrderDeclineReasonCode } from '../../../__generated__/graphql';

interface RadioOptonType {
  label: string;
  value: OrderDeclineReasonCode;
}

const radioButtonsData: RadioOptonType[] = [
  {
    label: 'Parental Decision',
    value: OrderDeclineReasonCode.ParentalDecision,
  },
  {
    label: 'Patient Decision',
    value: OrderDeclineReasonCode.PatientDecision,
  },
  {
    label: 'Religious Exemption',
    value: OrderDeclineReasonCode.ReligiousExemption,
  },
];

interface Props {
  onCloseModal: () => void;
  alertId: AlertId;
}

export function DeclineReasonPatientDecline({ onCloseModal, alertId }: Props) {
  const dispatch = useAppDispatch();
  const editedOrder = useAppSelector(selectors.editedOrdersSelectors.selectById(alertId));
  const declineReason = editedOrder?.declineReason;
  const selectedServerDeclineReasonCode = declineReason?.serverDeclineReasonCode;
  const editing = editedOrder.status === 'NO_EDITING_DECLINE_REASON';

  function handleAddDeclineReason() {
    onCloseModal();
    if (declineReason != null) {
      dispatch(appointmentsActions.addDeclineReasonComplete({ alertId }));
    }
  }

  function handleCancel() {
    onCloseModal();

    if (editing) {
      dispatch(appointmentsActions.cancelOrderLineItemEdit({ alertId }));
    }
  }

  const handleDeclineReasonChange = (value: OrderDeclineReasonCode) => {
    dispatch(
      appointmentsActions.changeDeclineReason({
        alertId,
        serverDeclineReasonCode: value,
      }),
    );
  };

  return (
    <Box p='md' minWidth='450px'>
      <VStack spacing='lg'>
        <Text variant='h6-b' textAlign='center'>
          {declineReason?.name}
        </Text>
        <VStack spacing='sm'>
          <RadioGroup onChange={handleDeclineReasonChange} value={selectedServerDeclineReasonCode}>
            <Stack direction='column'>
              {radioButtonsData.map((radioData) => {
                return (
                  <Radio key={radioData.value} value={radioData.value}>
                    {radioData.label}
                  </Radio>
                );
              })}
            </Stack>
          </RadioGroup>
        </VStack>
        <VStack spacing='md' w='full'>
          <Button onClick={handleAddDeclineReason} w='full'>
            Add this reason
          </Button>
          <Button variant='outline' onClick={handleCancel} w='full'>
            Cancel
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
}
