export const getAllergySources = [
  {
    id: 206334,
    agentSubstance: 'Abilify Discmelt',
  },
  {
    id: 276943,
    agentSubstance: 'Ascensia Autodisc Test',
  },
  {
    id: 211151,
    agentSubstance: 'Disc Complex',
  },
  {
    id: 211153,
    agentSubstance: 'DisCoVisc',
  },
  {
    id: 267657,
    agentSubstance: 'Discus Compositum',
  },
  {
    id: 397721,
    agentSubstance: 'Exu-Dry Disc 3"',
  },
  {
    id: 397724,
    agentSubstance: 'Exu-Dry Slit Disc 3"',
  },
  {
    id: 572053,
    agentSubstance: 'Omni-Biotic AB 10 Discharge',
  },
  {
    id: 221995,
    agentSubstance: 'Throat Discs',
  },
];
