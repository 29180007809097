export const getVitals = [
  {
    date: '2023-02-02T00:00:00.000Z',
    encounter: 7242615,
    vitals: {
      WEIGHT: {
        value: '157.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.1',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '83',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '132/74',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.84',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.3',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-11-01T00:00:00.000Z',
    encounter: 7196394,
    vitals: {
      WEIGHT: {
        value: '158.8',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.9',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '130/78',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.13',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '99',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.03',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-08-29T00:00:00.000Z',
    encounter: 7024068,
    vitals: {
      WEIGHT: {
        value: '162',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '80',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.69',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '95',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '73.48',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-08-03T00:00:00.000Z',
    encounter: 6792496,
    vitals: {
      WEIGHT: {
        value: '157',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '78',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '112/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.81',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.21',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-07-03T00:00:00.000Z',
    encounter: 6924959,
    vitals: {
      WEIGHT: {
        value: '162.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '79',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '112/66',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.76',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '96',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '73.66',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-05-03T00:00:00.000Z',
    encounter: 6782426,
    vitals: {
      WEIGHT: {
        value: '156.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.9',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '83',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '110/80',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.67',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '99',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.85',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2022-02-04T00:00:00.000Z',
    encounter: 6331452,
    vitals: {
      WEIGHT: {
        value: '157.8',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '100',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '112/76',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.95',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.58',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-11-03T00:00:00.000Z',
    encounter: 6087260,
    vitals: {
      WEIGHT: {
        value: '157.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.1',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '84',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/64',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.84',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.3',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-10-13T00:00:00.000Z',
    encounter: 6263389,
    vitals: {
      WEIGHT: {
        value: '155.6',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '76',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '118/76',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.56',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.58',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-08-03T00:00:00.000Z',
    encounter: 5849965,
    vitals: {
      WEIGHT: {
        value: '153.6',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.3',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '106/72',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.21',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.67',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-06-14T00:00:00.000Z',
    encounter: 5961014,
    vitals: {
      WEIGHT: {
        value: '155.6',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '90',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '110/50',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.56',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.58',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-05-11T00:00:00.000Z',
    encounter: 5871658,
    vitals: {
      WEIGHT: {
        value: '154.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '81',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '114/62',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.35',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '12',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '96',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.04',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-05-03T00:00:00.000Z',
    encounter: 5844018,
    vitals: {
      WEIGHT: {
        value: '152.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.4',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '89',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '118/60',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.96',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.04',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2021-02-02T00:00:00.000Z',
    encounter: 5377989,
    vitals: {
      WEIGHT: {
        value: '156.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.5',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '73',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '124/62',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.70',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '99',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.94',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2020-11-02T00:00:00.000Z',
    encounter: 5138938,
    vitals: {
      WEIGHT: {
        value: '155',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '96.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '102/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.45',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.31',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2020-08-01T00:00:00.000Z',
    encounter: 5113618,
    vitals: {
      WEIGHT: {
        value: '156.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.2',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '83',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '122/76',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.70',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '97',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.94',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2020-04-28T00:00:00.000Z',
    encounter: 4884244,
    vitals: {
      WEIGHT: {
        value: '155.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.7',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '80',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '122/80',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.52',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.49',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2020-04-18T00:00:00.000Z',
    encounter: 4869510,
    vitals: {
      WEIGHT: {
        value: '155',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.45',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.31',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2020-02-04T00:00:00.000Z',
    encounter: 4647121,
    vitals: {
      WEIGHT: {
        value: '153',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '83',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '130/80',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.10',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.4',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2019-10-23T00:00:00.000Z',
    encounter: 4201661,
    vitals: {
      WEIGHT: {
        value: '153.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '81',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '130/74',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.14',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '99',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.49',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2019-07-23T00:00:00.000Z',
    encounter: 4197631,
    vitals: {
      WEIGHT: {
        value: '155.8',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '62',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '130/82',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.60',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.67',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2019-04-15T00:00:00.000Z',
    encounter: 3949509,
    vitals: {
      WEIGHT: {
        value: '152',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '85',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '116/74',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.92',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '68.95',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2019-01-11T00:00:00.000Z',
    encounter: 3681674,
    vitals: {
      WEIGHT: {
        value: '154.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.0',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '94',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '124/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.35',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.04',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2018-10-18T00:00:00.000Z',
    encounter: 3512827,
    vitals: {
      WEIGHT: null,
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.2',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: null,
      BLOOD_PRESS: null,
      WAIST_CIRC: null,
      BMI: null,
      RESPIRATORY_RATE: null,
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: null,
    },
  },
  {
    date: '2018-10-12T00:00:00.000Z',
    encounter: 3481877,
    vitals: {
      WEIGHT: {
        value: '152.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.5',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '65',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '116/68',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.96',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.04',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2018-04-12T00:00:00.000Z',
    encounter: 2669401,
    vitals: {
      WEIGHT: {
        value: '149.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '60',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '108/60',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.46',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '67.77',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2018-02-13T00:00:00.000Z',
    encounter: 2925591,
    vitals: {
      WEIGHT: {
        value: '151.8',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.3',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '72',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '124/78',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.89',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '68.86',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2018-01-08T00:00:00.000Z',
    encounter: 2855250,
    vitals: {
      WEIGHT: {
        value: '151',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.0',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '81',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '122/66',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.75',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '99',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '68.49',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2017-12-06T00:00:00.000Z',
    encounter: 2791603,
    vitals: {
      WEIGHT: {
        value: '153',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.0',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '74',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/80',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.10',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.4',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2017-10-10T00:00:00.000Z',
    encounter: 2664988,
    vitals: {
      WEIGHT: {
        value: '152.2',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.7',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '54',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '112/78',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.96',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.04',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2017-05-26T00:00:00.000Z',
    encounter: 2390073,
    vitals: {
      WEIGHT: {
        value: '155',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.4',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.45',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '18',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.31',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2017-02-17T00:00:00.000Z',
    encounter: 2177896,
    vitals: {
      WEIGHT: {
        value: '158.8',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.6',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '88',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '130/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.13',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.03',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2017-01-27T00:00:00.000Z',
    encounter: 2151281,
    vitals: {
      WEIGHT: {
        value: '160.4',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '63',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.7',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '82',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '128/86',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.41',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.76',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2016-07-27T00:00:00.000Z',
    encounter: 1807404,
    vitals: {
      WEIGHT: {
        value: '159',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.4',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '76',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '122/84',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.16',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.12',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2016-01-27T00:00:00.000Z',
    encounter: 1472979,
    vitals: {
      WEIGHT: {
        value: '157',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: null,
      HEART_RATE: {
        value: '73',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/80',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.81',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.21',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2015-11-10T00:00:00.000Z',
    encounter: 1358179,
    vitals: {
      WEIGHT: {
        value: '162',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.3',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '76',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '128/76',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.69',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: {
        value: 'T. Hysterectomy',
        isDefault: false,
        qualifier: '',
      },
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '73.48',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2015-09-11T00:00:00.000Z',
    encounter: 1250087,
    vitals: {
      WEIGHT: {
        value: '159',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: null,
      HEART_RATE: null,
      BLOOD_PRESS: {
        value: '120/90',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.16',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: null,
      PAIN: null,
      LMP: {
        value: 'T. Hysterectomy',
        isDefault: false,
        qualifier: '',
      },
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.12',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2015-04-22T00:00:00.000Z',
    encounter: 1028202,
    vitals: {
      WEIGHT: {
        value: '154',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.7',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '80',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '128/74',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.28',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '69.85',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2015-03-10T00:00:00.000Z',
    encounter: 949875,
    vitals: {
      WEIGHT: {
        value: '158',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.7',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '78',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '142/90',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.99',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.67',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2014-11-25T00:00:00.000Z',
    encounter: 791769,
    vitals: {
      WEIGHT: {
        value: '160',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.2',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '72',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '110/78',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.34',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.58',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2014-09-03T00:00:00.000Z',
    encounter: 660012,
    vitals: {
      WEIGHT: {
        value: '159',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.9',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '68',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '100/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '28.16',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '72.12',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2014-06-06T00:00:00.000Z',
    encounter: 527757,
    vitals: {
      WEIGHT: {
        value: '158',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.9',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '74',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '176/86',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.99',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '12',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: {
        value: 'comp hysto',
        isDefault: false,
        qualifier: '',
      },
      O2: null,
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.67',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2014-02-21T00:00:00.000Z',
    encounter: 355530,
    vitals: {
      WEIGHT: {
        value: '158',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.5',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '79',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '140/92',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.99',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '16',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '71.67',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2013-12-18T00:00:00.000Z',
    encounter: 246789,
    vitals: {
      WEIGHT: {
        value: '156',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '97.8',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '91',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '118/70',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '27.63',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: {
        value: 'T. Hysterectomy',
        isDefault: false,
        qualifier: '',
      },
      O2: {
        value: '96',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '70.76',
        isDefault: false,
        qualifier: '',
      },
    },
  },
  {
    date: '2013-10-23T00:00:00.000Z',
    encounter: 140430,
    vitals: {
      WEIGHT: {
        value: '152',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT: {
        value: '5 ft 3 in',
        isDefault: false,
        qualifier: '',
      },
      TEMP: {
        value: '98.3',
        isDefault: false,
        qualifier: '',
      },
      HEART_RATE: {
        value: '67',
        isDefault: false,
        qualifier: '',
      },
      BLOOD_PRESS: {
        value: '120/82',
        isDefault: false,
        qualifier: '',
      },
      WAIST_CIRC: null,
      BMI: {
        value: '26.92',
        isDefault: false,
        qualifier: '',
      },
      RESPIRATORY_RATE: {
        value: '14',
        isDefault: false,
        qualifier: '',
      },
      PAIN: null,
      LMP: null,
      O2: {
        value: '98',
        isDefault: false,
        qualifier: '',
      },
      HEIGHT_CM: {
        value: '160.02',
        isDefault: false,
        qualifier: '',
      },
      WEIGHT_KG: {
        value: '68.95',
        isDefault: false,
        qualifier: '',
      },
    },
  },
];
