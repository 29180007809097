import { Input, InputRightElement, Spinner } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { EditIcon } from '../../svgs/Edit';
import { InputGroup } from '../../form';
import React, { useState } from 'react';
import { type BoxProps } from '../../layout';
import { useCustomToast } from '../../../hooks';

interface PatientRoomNumberProps extends BoxProps {
  encounterID: number;
  roomNumber: string;
  updateRoomNumber: (encounterId: number, roomNumber: string) => Promise<void>;
}

export function PatientRoomNumber({
  roomNumber,
  encounterID,
  updateRoomNumber,
  ...rest
}: PatientRoomNumberProps) {
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [roomNum, setRoomNum] = useState<string>(roomNumber);
  const toast = useCustomToast();
  return (
    <InputGroup
      mt='sm'
      size='xs'
      w={28}
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Input
        ref={(id) => {
          setRef(id);
        }}
        fontWeight={700}
        color='secondary'
        value={roomNum}
        borderRadius={rest.borderRadius ?? 'md'}
        placeholder={rest.placeholder}
        onChange={(e) => {
          setRoomNum(e.target.value.toUpperCase());
        }}
        onFocus={() => {
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
          if (roomNum !== roomNumber) {
            setIsUpdating(true);
            updateRoomNumber(encounterID, roomNum)
              .then(() => {
                toast({
                  id: 'encounter-roomNumber',
                  title: 'Update Success',
                  description: 'Update room number success',
                  status: 'success',
                  duration: 3000,
                });
              })
              .catch(() => {
                toast({
                  id: 'encounter-roomNumber',
                  title: 'Update Error',
                  status: 'error',
                  duration: 3000,
                });
              })
              .finally(() => {
                setIsUpdating(false);
              });
          }
        }}
      />
      <InputRightElement>
        {isUpdating ? (
          <Spinner color='secondary' boxSize={3} />
        ) : isActive ? (
          <CheckIcon color='secondary' />
        ) : (
          <EditIcon
            boxSize={5}
            color='secondary'
            onClick={(e) => {
              ref?.focus();
            }}
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
}
