import { Td, Text, Tr } from '@chakra-ui/react';
import {
  type GetCumulativeReportQuery,
  type OrderableItem,
} from '../../../../__generated__/graphql';
import { Flex } from '../../../../components';

export function TestResults({
  orderableItems,
}: {
  orderableItems: GetCumulativeReportQuery['getCumulativeReport'];
}) {
  type Placeholder = Record<string, any>;
  const allResults: Record<
    string,
    Array<NonNullable<OrderableItem['testResults']>[0] | Placeholder>
  > = {};

  // instantiate allResults with all possible keys
  // we need to know all possible keys before we assign values
  orderableItems.forEach((item) => {
    item.testResults?.forEach((testResult) => {
      if (!testResult) return;
      const key = testResult?.attribute ?? '';
      allResults[key] = [];
    });
  });

  orderableItems.forEach((item) => {
    Object.entries(allResults).forEach(([key, value]) => {
      const testResult = item.testResults?.find((r) => r?.attribute === key) || {};
      allResults[key] = [...value, testResult];
    });
  });

  const rows = Object.entries(allResults);

  return (
    <>
      {rows.map(([key, testResults]) => {
        const range = testResults?.[0]?.range;
        const hasAbnormal = testResults?.some(
          (r) => r?.abnormalFlagValue && r?.abnormalFlagValue !== 'N',
        );

        return (
          <Tr bgColor={hasAbnormal ? 'brand.100' : ''} key={key}>
            <Td>{key}</Td>
            {testResults.map((r, i) => {
              const valCharCount = Number(r?.value?.length || 0);
              const rangeCharCount = Number(r?.range?.length || 0);
              const charCount = valCharCount + rangeCharCount;
              const goHorizontal = charCount > 15;
              const direction = goHorizontal ? 'column' : 'row';
              const showBorder = !!valCharCount && !!rangeCharCount;
              const isAbnormal = r?.abnormalFlagValue && r?.abnormalFlagValue !== 'N';
              const flagValue = isAbnormal ? `${r?.abnormalFlagValue}` : '';

              return (
                <Td
                  textColor={isAbnormal ? 'brand.500' : ''}
                  fontWeight={isAbnormal ? 'bold' : ''}
                  key={i}>
                  <Flex
                    style={{
                      whiteSpace: showBorder ? 'nowrap' : 'normal',
                    }}
                    flexDirection={direction}>
                    <Text whiteSpace='nowrap' pr={1} fontSize='sm'>
                      {r?.value} {flagValue}
                    </Text>
                  </Flex>
                </Td>
              );
            })}
            <Td whiteSpace='nowrap'>{range}</Td>
            <Td width='100%' />
          </Tr>
        );
      })}
    </>
  );
}
