import { useEditedOrders } from './useEditedOrders';
import { hasAutoPickedDiagnosis, isRecommendationInjectionOrImmunization } from '../utils';
import { type PendingVaccine, useAppSelector } from '../state-management';

export function usePendingVaccines() {
  // Get all accepted immunizations from editedOrders
  const { editedOrders } = useEditedOrders();

  // filter all editedOrders that are immunizations
  const acceptedImmunizations = editedOrders.filter((order) => {
    return (
      isRecommendationInjectionOrImmunization(order) &&
      (hasAutoPickedDiagnosis(order) || order.status === 'YES_SAVED_DIAGNOSIS')
    );
  });

  const pendingVaccines: PendingVaccine[] = [];
  const pendingTherapeuticInjections: PendingVaccine[] = [];

  // Pull pending items from state and push them to their corresponding array
  useAppSelector((state) => state.worklistTodo.pendingVaccineTodos).forEach((item) => {
    const { immunization } = item;
    if (
      immunization?.patientImmunizationInjectionType &&
      ['Theraputic Injection', 'INJECTION'].includes(immunization.patientImmunizationInjectionType)
    ) {
      pendingTherapeuticInjections.push({
        ...item,
        immunization: {
          ...immunization,
          // Aylo changes all INJECTION types to Theraputic Injection,
          // but will not update frontend until refresh, so we handle on our end
          patientImmunizationInjectionType: 'Theraputic Injection',
        },
      });
    } else {
      pendingVaccines.push(item);
    }
  });

  return {
    acceptedImmunizations,
    pendingVaccines,
    pendingTherapeuticInjections,
    hasAtLeastOneSubmittedVaccineOrder: !!pendingVaccines.length,
    hasAtLeastOneSubmittedTherapeuticInjectionOrder: !!pendingTherapeuticInjections.length,
  };
}
