export const getAllergyReactions = [
  {
    id: 12009,
    reaction: 'anaphylaxis',
  },
  {
    id: 275348,
    reaction: 'Anaphylaxis-Bakers Yeast',
  },
  {
    id: 275345,
    reaction: 'Anaphylaxis-Neomycin',
  },
  {
    id: 275347,
    reaction: 'Anaphylaxis-PolymyxinB',
  },
  {
    id: 275346,
    reaction: 'Anaphylaxis-Streptomycin',
  },
];
