import { Flex } from '@chakra-ui/react';
import { useGetEncountersQuery } from '../../../../../__generated__/graphql';
import { EncounterTable } from './EncountersTable';

const upcomingHeaders = ['Date', 'Location', 'Visit Type', 'Resource'];
const pastHeaders = [...upcomingHeaders, 'Chief Complaint'];

interface Props {
  patientId: number;
}

export function EncounterTab({ patientId }: Props) {
  const { data: past, loading: pastLoading } = useGetEncountersQuery({
    variables: {
      patientID: patientId,
      isCompleted: true,
      limit: 999,
    },
  });

  const { data: upcoming, loading: upcomingLoading } = useGetEncountersQuery({
    variables: {
      patientID: patientId,
      isCompleted: false,
      limit: 999,
    },
  });

  return (
    <Flex flexDirection='column' alignItems='flex-start'>
      <EncounterTable
        data={upcoming?.getEncounters?.encounters ?? []}
        isLoading={upcomingLoading}
        label='Upcoming Encounters'
        headers={upcomingHeaders}
      />

      <EncounterTable
        data={past?.getEncounters?.encounters ?? []}
        isLoading={pastLoading}
        label='Previous Encounters'
        headers={pastHeaders}
      />
    </Flex>
  );
}
