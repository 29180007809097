export const problems = [
  {
    icdCode: 'Z68.22',
    diagnosisCode: 'ICD: Z68.22 SNOMED: 412768003',
    problemName: 'BMI 22.0-22.9, adult',
    assessmentItemId: 516957,
  },
  {
    icdCode: 'Z78.9',
    diagnosisCode: 'ICD: Z78.9 SNOMED: 8392000',
    problemName: 'Non-Smoker - Other specified health status',
    assessmentItemId: 401078,
  },
  {
    icdCode: 'D50.8',
    diagnosisCode: 'ICD: D50.8 SNOMED: ',
    problemName: 'Other iron deficiency anemia',
    assessmentItemId: 474429,
  },
  {
    icdCode: 'Z11.3',
    diagnosisCode: 'ICD: Z11.3 SNOMED: ',
    problemName: 'Screening for STD (sexually transmitted disease)',
    assessmentItemId: 472609,
  },
  {
    icdCode: 'E55.9',
    diagnosisCode: 'ICD: E55.9 SNOMED: 34713006',
    problemName: 'Vitamin D deficiency, unspecified',
    assessmentItemId: 401038,
  },
];
// export const problems = [
//   {
//     diagnosisCode: "ICD: L40.9 SNOMED: 9014002",
//     problemName: "Psoriasis, unspecified",
//   },
//   {
//     diagnosisCode: "ICD: R79.82 SNOMED: 119971000119104",
//     problemName: "Elevated C-reactive protein (CRP)",
//   },
//   {
//     diagnosisCode: "ICD: K30 SNOMED: 162031009",
//     problemName: "Indigestion",
//   },
//   {
//     diagnosisCode: "ICD: J30.1 SNOMED: 21719001",
//     problemName: "Seasonal allergic rhinitis due to pollen",
//   },
//   {
//     diagnosisCode: "ICD: K76.0 SNOMED: 197321007",
//     problemName: "Fatty (change of) liver, not elsewhere classified",
//   },
//   {
//     diagnosisCode: "ICD: I51.7 SNOMED: 55827005",
//     problemName: "Mild concentric left ventricular hypertrophy (LVH)",
//   },
//   {
//     diagnosisCode: "ICD: N64.4 SNOMED: 12242471000119107",
//     problemName: "Breast pain, left",
//   },
//   {
//     diagnosisCode: "ICD: Z80.3 SNOMED: 430292006",
//     problemName: "Family history of breast cancer in mother",
//   },
//   {
//     diagnosisCode: "ICD: Z80.3 SNOMED: 430292006",
//     problemName: "Family history of breast cancer in first degree relative",
//   },
//   {
//     diagnosisCode: "ICD: N62 SNOMED: 372283008",
//     problemName: "Large breasts",
//   },
//   {
//     diagnosisCode: "ICD: G62.9 SNOMED: 386033004",
//     problemName: "Neuropathy",
//   },
//   {
//     diagnosisCode: "ICD: N92.0 SNOMED: 386692008",
//     problemName: "Menorrhagia with regular cycle",
//   },
//   {
//     diagnosisCode: "ICD: Z68.44 SNOMED: 238136002",
//     problemName: "BMI 60.0-69.9, adult",
//   },
//   {
//     diagnosisCode: "ICD: D50.8 SNOMED: 371315009",
//     problemName:
//       "Iron deficiency anemia secondary to inadequate dietary iron intake",
//   },
//   {
//     diagnosisCode: "ICD: R10.11 ",
//     problemName: "Right upper quadrant abdominal pain",
//   },
//   {
//     diagnosisCode: "ICD: Z90.49 SNOMED: 428882003",
//     problemName: "S/P cholecystectomy",
//   },
//   {
//     diagnosisCode: "ICD: A04.8 SNOMED: 721730009",
//     problemName: "H. pylori infection",
//   },
//   {
//     diagnosisCode: "ICD: N28.1 SNOMED: 722223000",
//     problemName: "Renal cyst, right",
//   },
//   {
//     diagnosisCode: "ICD: K76.0 SNOMED: 197321007",
//     problemName: "Fatty liver",
//   },
//   {
//     diagnosisCode: "ICD: K64.8 SNOMED: 90458007",
//     problemName: "Internal hemorrhoids",
//   },
//   {
//     diagnosisCode: "ICD: R68.89 SNOMED: 126486000",
//     problemName: "Erythematous mucosa",
//   },
//   {
//     diagnosisCode: "ICD: N94.9 ",
//     problemName: "Vaginal symptom",
//   },
//   {
//     diagnosisCode: "ICD: N83.201 SNOMED: 12238631000119108",
//     problemName: "Cyst of right ovary",
//   },
//   {
//     diagnosisCode: "ICD: R93.89 SNOMED: 169255008",
//     problemName: "Abnormal pelvic ultrasound",
//   },
//   {
//     diagnosisCode: "ICD: G56.92 SNOMED: 609599003",
//     problemName: "Neuropathy of left upper extremity",
//   },
//   {
//     diagnosisCode: "ICD: E66.01 SNOMED: 83911000119104",
//     problemName: "Morbid (severe) obesity due to excess calories",
//   },
//   {
//     diagnosisCode: "ICD: Z68.44 SNOMED: 238136002",
//     problemName: "Body mass index [BMI] 60.0-69.9, adult",
//   },
//   {
//     diagnosisCode: "ICD: R00.2 SNOMED: 80313002",
//     problemName: "Palpitations",
//   },
//   {
//     diagnosisCode: "ICD: R16.0 SNOMED: 80515008",
//     problemName: "Hepatomegaly",
//   },
//   {
//     diagnosisCode: "ICD: K76.0 SNOMED: 197321007",
//     problemName: "Hepatic steatosis",
//   },
//   {
//     diagnosisCode: "ICD: N28.1 SNOMED: 431481000124104",
//     problemName: "Parapelvic renal cyst",
//   },
//   {
//     diagnosisCode: "ICD: K76.9 SNOMED: 300331000",
//     problemName: "Liver lesion, right lobe",
//   },
//   {
//     diagnosisCode: "ICD: Z78.9 SNOMED: 8392000",
//     problemName: "Non-Smoker - Other specified health status",
//   },
//   {
//     diagnosisCode: "ICD: I10 SNOMED: 38341003",
//     problemName: "Essential (primary) hypertension, stable",
//   },
//   {
//     diagnosisCode: "ICD: R31.9 SNOMED: 34436003",
//     problemName: "Hematuria",
//   },
//   {
//     diagnosisCode: "ICD: J30.2 SNOMED: 367498001",
//     problemName: "Seasonal allergies",
//   },
//   {
//     diagnosisCode: "ICD: E66.01 SNOMED: 238136002",
//     problemName: "Morbid (severe) obesity due to excess calories",
//   },
//   {
//     diagnosisCode: "ICD: R20.2 SNOMED: 309086004",
//     problemName: "Paresthesias in right hand",
//   },
//   {
//     diagnosisCode: "ICD: R73.03 SNOMED: 714628002",
//     problemName: "Pre-diabetes",
//   },
//   {
//     diagnosisCode: "ICD: K21.9 SNOMED: 235595009",
//     problemName: "GERD (gastroesophageal reflux disease)",
//   },
//   {
//     diagnosisCode: "ICD: G44.209 SNOMED: 398057008",
//     problemName: "Tension headache",
//   },
//   {
//     diagnosisCode: "ICD: E55.9 SNOMED: 34713006",
//     problemName: "Vitamin D deficiency, unspecified",
//   },
//   {
//     diagnosisCode: "ICD: D64.9 SNOMED: 271737000",
//     problemName: "Anemia, unspecified",
//   },
// ];
