import { useCallback } from 'react';
import { selectors, useAppSelector } from '../state-management';
import { type AlertId } from '../types';

export function useEditedOrders() {
  const editedOrders = useAppSelector(selectors.editedOrdersSelectors.selectAll);
  const getEditedOrder = useCallback(
    (alertId: AlertId) => {
      return editedOrders.find((v) => v.alert.alertId === alertId);
    },
    [editedOrders],
  );

  return { editedOrders, getEditedOrder };
}
