import { Button, ButtonGroup, HStack, Tag, Text, Textarea, VStack, Wrap, WrapItem } from '../..';
import { AsyncSelectField } from '../../../features';
import { type EncounterHPIDataItem, type HPISymptom } from '../../../types';
import CustomRadio from '../../form/CustomRadio/CustomRadio';

interface EncounterHPIDataItemModalContentProps {
  encounterHPIDataItem: EncounterHPIDataItem | undefined;
  setEncounterHPIDataItem: (encounterHPIDataItem: EncounterHPIDataItem) => void;
  symptomOptions: HPISymptom['symptomOptions'] | undefined;
}

export function EncounterHPIDataItemModalContent({
  encounterHPIDataItem,
  setEncounterHPIDataItem,
  symptomOptions,
}: EncounterHPIDataItemModalContentProps) {
  // hardcode duration Options for now, until API is ready
  let durationOptions: DropdownOption[] = [
    { value: '1 day', label: '1 day' },
    { value: '1 month', label: '1 month' },
    { value: '1 week', label: '1 week' },
    { value: '2', label: '2' },
    { value: '2 months', label: '2 months' },
    { value: '2 weeks', label: '2 weeks' },
    { value: '2-3 days', label: '2-3 days' },
    { value: '3', label: '3' },
    { value: '3 months', label: '3 months' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: 'days', label: 'days' },
    { value: 'hours', label: 'hours' },
    { value: 'less than', label: 'less than' },
    { value: 'less than 6 months', label: 'less than 6 months' },
    { value: 'minutes', label: 'minutes' },
    { value: 'months', label: 'months' },
    { value: 'more than', label: 'more than' },
    { value: 'more than 10 years', label: 'more than 10 years' },
    { value: 'more than 2 years', label: 'more than 2 years' },
    { value: 'more than 5 years', label: 'more than 5 years' },
    { value: 'more than 6 months', label: 'more than 6 months' },
    { value: 'seconds', label: 'seconds' },
    { value: 'weeks', label: 'weeks' },
  ];

  const handleTagClick = (tagName: string) => {
    if (encounterHPIDataItem != null) {
      const updatedSymptomNotes = encounterHPIDataItem.symptomNotes
        ? `${encounterHPIDataItem.symptomNotes}, ${tagName}`
        : tagName;
      setEncounterHPIDataItem({
        ...encounterHPIDataItem,
        symptomNotes: updatedSymptomNotes,
      });
    }
  };

  const handleAddDurationUnit = (unit: string) => {
    if (!encounterHPIDataItem?.duration || encounterHPIDataItem?.duration.trim() === '') {
      setEncounterHPIDataItem({
        ...encounterHPIDataItem!,
        duration: `1 ${unit}`,
      });
    } else {
      const firstNumberMatch = encounterHPIDataItem?.duration.match(/\d+/);
      if (firstNumberMatch != null) {
        setEncounterHPIDataItem({
          ...encounterHPIDataItem,
          duration: `${firstNumberMatch[0]} ${unit}`,
        });
      }
    }
  };

  durationOptions =
    encounterHPIDataItem?.duration !== null && encounterHPIDataItem?.duration !== undefined
      ? durationOptions.filter((x) =>
          x.value.toLowerCase().includes((encounterHPIDataItem.duration || '').toLowerCase()),
        )
      : durationOptions;

  return (
    <VStack spacing='md' alignItems='flex-start'>
      <HStack spacing='sm' w='full'>
        <CustomRadio
          options={[
            { name: 'Complains', value: 'true' },
            { name: 'Denies', value: 'false' },
          ]}
          key={encounterHPIDataItem?.hpiSymptomId} // this is to force re-render; otherwise, stale defaultValue from one modal will be incorrectly set for everything else.
          as='vertical'
          defaultValue={String(encounterHPIDataItem?.complainsDenies)}
          onChange={(val) => {
            const res = val === undefined ? undefined : val === 'true';
            setEncounterHPIDataItem({
              ...encounterHPIDataItem,
              // complainsDenies: val ? Boolean(val) : undefined,
              complainsDenies: res,
            });
          }}
        />
        <AsyncSelectField
          openOnFocus
          handleOptionSelect={(option: DropdownOption) => {
            setEncounterHPIDataItem({
              ...encounterHPIDataItem!,
              duration: option.value,
            });
          }}
          dropdownItems={durationOptions}
          inputProps={{
            value: encounterHPIDataItem?.duration || '',
            placeholder: 'Duration',
          }}
          handleInputChange={(inputValue) => {
            setEncounterHPIDataItem({
              ...encounterHPIDataItem!,
              duration: inputValue || '',
            });
          }}
        />

        <ButtonGroup variant='outline'>
          <Button
            onClick={() => {
              handleAddDurationUnit('days');
            }}>
            Days
          </Button>
          <Button
            onClick={() => {
              handleAddDurationUnit('weeks');
            }}>
            Weeks
          </Button>
          <Button
            onClick={() => {
              handleAddDurationUnit('months');
            }}>
            Months
          </Button>
          <Button
            onClick={() => {
              handleAddDurationUnit('years');
            }}>
            Years
          </Button>
        </ButtonGroup>
      </HStack>

      {/* <Select>
        {durationOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select> */}

      <Textarea
        value={encounterHPIDataItem?.symptomNotes || ''}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          if (encounterHPIDataItem != null) {
            setEncounterHPIDataItem({
              ...encounterHPIDataItem,
              symptomNotes: e.target.value,
            });
          }
        }}
        rows={4}
        placeholder='Note'
      />

      <VStack w='full' alignItems='flex-start'>
        {symptomOptions?.length && <Text>Suggestions:</Text>}

        <Wrap spacing='sm'>
          {symptomOptions?.map((x) => (
            <WrapItem key={x.optionID || ''}>
              <Tag
                onClick={() => {
                  handleTagClick(x.name || '');
                }}
                variant='outline'
                borderRadius='full'
                colorScheme='brand'
                sx={{ cursor: 'pointer' }}>
                {x.name}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </VStack>
    </VStack>
  );
}
