import { type QualifierConfig } from '../../types';
import { defaultQualifiers } from './util';
import { VitalQualifierInput } from './VitalsPicker';
import { type EncounterVital, type VitalType } from '../../__generated__/graphql';
import { type InputProps as ChakraInputProps } from '@chakra-ui/input/dist/input';
import { type VitalValidation } from '../../screens/MAWorkFlowScreen/VitalsContent/VitalValidation';

interface Props extends Omit<ChakraInputProps, 'value' | 'onChange'> {
  value: EncounterVital[];
  onChange: (config: EncounterVital[]) => void;
  defaultPlaceholder?: string;
  labelElement?: React.ReactElement;
  validationResult?: VitalValidation;
  onAddedHandler?: (value: string) => void;
}

const qualifiers = defaultQualifiers;

export function BloodPressurePicker({
  onChange,
  defaultPlaceholder,
  value,
  errorBorderColor,
  ...rest
}: Props) {
  function handleChangeQualifier(qualifierConfigs: QualifierConfig[]) {
    const vitals = qualifierConfigs?.map((config) => ({
      ...config,
      type: 'BLOOD_PRESS' as VitalType,
    }));
    onChange(vitals);
  }

  return (
    <VitalQualifierInput
      {...rest}
      errorBorderColor={errorBorderColor}
      defaultPlaceholder={defaultPlaceholder}
      qualifiers={qualifiers}
      onChange={handleChangeQualifier}
      savedQualifiers={value as QualifierConfig[]}
    />
  );
}
