export function submitLabs() {
  return [
    {
      labStatus: 'submitted',
      reportId: 7918985,
    },
    {
      labStatus: 'error',
      reportId: 7918985_2,
    },
  ];
}
