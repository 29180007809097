import { SimpleGrid } from '@chakra-ui/react';
import { Facilities } from './Facilities';
import { Providers } from './Providers';
import { Assignees } from './Assignees';
import { Procedures } from './Procedures';

export function EncounterFilters() {
  return (
    <SimpleGrid columns={4} gap={4}>
      <Procedures />
      <Facilities />
      <Providers />
      <Assignees />
    </SimpleGrid>
  );
}
