import { FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  label: string;
  options: string[];
  initialValue: string;
  onChange: (change: string) => void;
}

// DISCLAIMER
// If helper text from options array prop is longer than Input width,
// the display will break when you type past the width of Input
// Can be fixed by making sure options aren't too long and Input width isn't too short
export function AutocompleteInput({ label, options, initialValue, onChange }: Props) {
  const [value, setValue] = useState<string>(initialValue);
  const [focused, setFocused] = useState<boolean>(false);
  const [autofillLabel, setAutofillLabel] = useState<string>('');

  return (
    <FormControl color='grey' variant='floating'>
      <FormLabel
        color='grey'
        style={value.length ? { transform: 'scale(0.85) translateY(-24px)' } : {}}>
        {label}
      </FormLabel>

      <Input
        value={value}
        textColor='black'
        focusBorderColor='brand.500'
        onChange={(e) => {
          const eventValue = e.target.value;
          setValue(eventValue);
          onChange(eventValue);

          const filtered: string[] = [];
          options.forEach((option) => {
            if (option.slice(0, eventValue.length).toUpperCase() === eventValue.toUpperCase()) {
              filtered.push(option);
            }
          });

          setAutofillLabel(
            filtered.length && eventValue.length
              ? `${eventValue}${filtered[0].slice(eventValue.length, filtered[0].length)}`
              : '',
          );
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && autofillLabel.length) {
            setValue(autofillLabel);
          }
        }}
      />

      {!!value.length && focused && (
        <Text
          position='absolute'
          bottom='2'
          left='17px'
          zIndex={0}
          textColor='gray.400'
          whiteSpace='nowrap'
          overflow='hidden'
          width='calc(100% - 17px)'>
          {autofillLabel}
        </Text>
      )}
    </FormControl>
  );
}
