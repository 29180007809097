import { Flex, Box, WaitUntilLoaded } from '../../layout';
import { Text } from '../../typography';
import { modalActions, selectors, useAppDispatch, useAppSelector } from '../../../state-management';
import { type LocationUI } from '../../../types';
import { BiChevronDown } from 'react-icons/bi';
import { Icon } from '../../general/Icon';
import { useGetLocationsQuery } from '../../../__generated__/graphql';

// Util
function getLocationTextForRender({
  locations,
  selectedLocationID,
}: {
  locations?: LocationUI[];
  selectedLocationID: number;
}) {
  if (locations != null) {
    const activeLocation = locations.find((location) => location.locationID === selectedLocationID);

    if (activeLocation?.locationName) {
      // Long text ellipses: https://stackoverflow.com/a/36013799/2213882
      return (
        <Box flex={1}>
          <Text noOfLines={1}>{activeLocation?.locationName}</Text>
        </Box>
      );
    }
  }

  return <Text color='#9ca3af'>Select location...</Text>;
}

export function LocationSelector() {
  const dispatch = useAppDispatch();
  const { data, loading: isLoading } = useGetLocationsQuery();
  const selectedLocationID = useAppSelector(selectors.getSelectedLocationID); // TODO: Why does this return string when locationID type is number?
  const renderLocationText = getLocationTextForRender({
    locations: data?.getLocations?.locations || undefined,
    selectedLocationID: Number(selectedLocationID),
  });

  function handleOpenModal() {
    dispatch(
      modalActions.showModal({
        modalType: 'LocationSelectorModal',
        chakraModalProps: {
          size: 'xl',
        },
      }),
    );
  }

  // // TODO: REMOVE DEBUGGING ONLY
  // handleOpenModal();

  return (
    <WaitUntilLoaded loading={isLoading} errorMessage='Cannot load locations'>
      <Box onClick={handleOpenModal}>
        <Flex
          borderBottomWidth='1px'
          // borderBottomColor="secondary"
          py='2'
          justifyContent='space-between'
          alignItems='center'
        >
          {renderLocationText}
          <Icon boxSize={10} color='brand.400' as={BiChevronDown} />
        </Flex>
      </Box>
    </WaitUntilLoaded>
  );
}
