import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo, useState, useEffect } from 'react';
import {
  useGetPatientDocumentsByPatientQuery,
  useGetSignedDocumentUrlLazyQuery,
} from '../../../../__generated__/graphql';
import { usePatient } from '../../../../hooks';
import { groupMap } from './groupMap';
// import { usePatient } from '../../../../hooks';

export function PatientDocsPanel() {
  const { patient } = usePatient();
  const [documentId, setDocumentId] = useState('');
  // lazy means it will trigger on demand rather than on-load
  const [getSignedUrl, { data: signedUrlData }] = useGetSignedDocumentUrlLazyQuery();

  const url = signedUrlData?.getDocumentSignedUrl;

  const [search, setSearch] = useState('');
  const patientDocsQuery = useGetPatientDocumentsByPatientQuery({
    variables: {
      patientId: patient.patientID ?? -1,
    },
  });

  const docs = patientDocsQuery.data?.getPatientDocumentsByPatient ?? [];

  const groups = useMemo(() => {
    const uniqueDocs = new Set<number>();
    docs.forEach((doc) => {
      uniqueDocs.add(doc?.documentTypeID ?? 0);
    });
    return Array.from(uniqueDocs);
  }, [docs]);

  useEffect(() => {
    if (documentId) {
      getSignedUrl({
        variables: {
          documentId,
        },
      });
    }
  }, [documentId]);

  if (!docs?.length && !patientDocsQuery.loading) {
    return (
      <Box mt='lg'>
        <Text textAlign='center'>No Patient Documents</Text>
      </Box>
    );
  }

  return (
    <HStack align='start' h='full'>
      <VStack maxW='sm' w='full' h='full'>
        <Input
          placeholder='search'
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Accordion w='full'>
          {groups.map((group, i) => {
            const groupDocs = docs.filter((doc) => {
              const isGroup = doc?.documentTypeID === group;
              const filterMatch = doc?.documentName?.toLowerCase()?.includes(search.toLowerCase());
              return isGroup && filterMatch;
            });

            if (!groupDocs.length) return null;

            return (
              <React.Fragment key={i}>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as='span' flex='1' textAlign='left'>
                        {groupMap[group]}
                      </Box>
                      <Text fontWeight='bold' color='gray.500'>
                        {groupDocs.length}
                      </Text>
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    {groupDocs.map((doc, j) => {
                      return (
                        <Button
                          w='full'
                          overflow=''
                          variant='ghost'
                          textAlign='left'
                          display='flex'
                          onClick={() => {
                            setDocumentId(doc?.documentUUID ?? '');
                          }}
                          colorScheme={doc?.documentUUID === documentId ? 'brand' : 'gray'}
                          justifyContent='flex-start'
                          key={doc?.id ?? j}>
                          <Text
                            textAlign='left'
                            isTruncated
                            textOverflow='ellipsis'
                            overflowX='hidden'>
                            {doc?.documentName}
                          </Text>
                        </Button>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </React.Fragment>
            );
          })}
        </Accordion>
      </VStack>
      <Box h='full' w='full'>
        {url && <iframe width='100%' height='100%' src={url} />}
      </Box>
    </HStack>
  );
}
