import React from 'react';
import { type GetCumulativeReportQuery } from '../../../../../__generated__/graphql';
import { Td, Tr } from '@chakra-ui/react';

type EncounterOrderableItemResult = NonNullable<
  NonNullable<NonNullable<GetCumulativeReportQuery['getCumulativeReport'][0]>['testResults']>[0]
>;

export function ResultsRows({ result }: { result: EncounterOrderableItemResult }) {
  const isAbnormal = result.abnormalFlagValue && result?.abnormalFlagValue !== 'N';
  const color = isAbnormal ? 'brand.500' : 'default';
  const flagValue = isAbnormal ? `(${result.abnormalFlagValue})` : '';

  return (
    <React.Fragment key={result.attributeID}>
      <Tr
        _hover={{ background: 'gray.50' }}
        padding={0}
        borderBottom='1.5px solid white'
        background='gray.100'
        fontWeight='semibold'>
        <Td paddingY={1.5}>{result.attribute}</Td>
        <Td paddingY={1.5} color={color}>
          {result.value} {flagValue}
        </Td>
        <Td paddingY={1.5} paddingX={24} color={color}>
          {result.range}
        </Td>
      </Tr>
    </React.Fragment>
  );
}
