import { useToast } from '@chakra-ui/react';
import {
  providerEncounterActions,
  useAppSelector,
  getProviderEncounterState,
} from '../../../../state-management';
import {
  useAddNotesToOrderableItemMutation,
  type ProviderUpdateOrderableItemReviewInput,
} from '../../../../__generated__/graphql';
import { useDispatch } from 'react-redux';
import { apolloClient } from '../../../../api/apollo-api';
import { useNavigate } from 'react-router-dom';
import { useProviderEncounterDetails } from '../../hooks/useProviderEncounterDetails';

interface Props {
  setUpdating: (updating: boolean) => void;
  encounterId?: string;
  patientId?: string | null;
}

export function useHandleBulkUpdates({ encounterId, setUpdating, patientId }: Props) {
  const toast = useToast();
  const nav = useNavigate();

  const [updateItem] = useAddNotesToOrderableItemMutation();
  const dispatch = useDispatch();
  const { items } = useProviderEncounterDetails({ encounterId });
  const encounterState = useAppSelector(getProviderEncounterState);

  const navToCreateTE = () => {
    const orderableReportIds = items.map((item) => item.orderableReportID).join(',');
    const param = `?encounterId=${encounterId}&orderableReportIds=${orderableReportIds}`;
    nav(`/providers/${patientId}/open${param}`);
  };

  const handleSave = async () => {
    try {
      setUpdating(true);
      // we're going to use the 'current values' so that we capture
      // any values that might not have been 'applied'
      const currentSidebarValues = encounterState?.sidebarState?.currentValues;
      const hasUpdatedItems = !!Object.entries(encounterState.updatedItems)?.length;
      const isOnlyReviewedItems = items?.every((item) => item.reviewed);

      const hasSidebarUpdates = !!Object.values(currentSidebarValues)?.some(
        // if a bool field is true
        // or if a string field is not null
        // or if a num field is > 0
        (val) => !!val,
      );

      // has the user updated the sidebar or
      // individually updated an orderableItem
      const hasAnyUpdates = hasUpdatedItems || hasSidebarUpdates;

      if (!hasAnyUpdates || isOnlyReviewedItems) {
        navToCreateTE();
        return;
      }

      // the goal when saving these is to apply the sidebar updates
      // along with any individual updates to the orderable items
      // however, we don't want to overwrite individaul order udpates
      // that the user may have applied during their session
      // ie we want to prefer individual updates over sidebar updates
      if (Object.entries(encounterState).length && items?.length) {
        // graphql update input args
        const updates: ProviderUpdateOrderableItemReviewInput[] = [];

        // for each item we'll first check if the individual item is updated and apply that.
        // secondarily we'll apply the sidebar updates
        // then, we'll just apply any of the item's initial values
        items.forEach((item) => {
          if (item.reviewed) return;
          const reportId = Number(item.orderableReportID || -1);
          const updated = encounterState.updatedItems[reportId] || {};

          // don't save if there are no changes to apply to the item
          if (!updated && !hasSidebarUpdates) return;

          const update: ProviderUpdateOrderableItemReviewInput = {
            assignedToUserID: Number(
              updated.assignedToUserID ||
                currentSidebarValues.assignedToUserID ||
                item.assignedToUserID ||
                0,
            ),
            highPriority:
              updated.highPriority || currentSidebarValues.highPriority || item.highPriority,
            orderableReportID: reportId,
            result: updated.result || currentSidebarValues.result || item.result,
            reviewed: !!(updated.reviewed ?? currentSidebarValues.reviewed ?? item.reviewed),
            // notes may need to concatenate an individual items notes
            // with sidebar updates
            notes: [item.notes || updated.notes, currentSidebarValues.notes]
              .map((val) => val?.trim())
              .join('\n\n')
              .trim(),
            internalNotes: [
              item.internalNotes || updated.internalNotes,
              currentSidebarValues.internalNotes,
            ]
              .map((val) => val?.trim())
              .join('\n\n')
              .trim(),
          };

          updates.push(update);
        });

        await updateItem({
          variables: {
            input: updates,
          },
        });

        dispatch(providerEncounterActions.resetSidebar());
        await apolloClient.refetchQueries({
          include: ['GetProviderEncounterListItems'],
        });
        await apolloClient.resetStore();
        await apolloClient.reFetchObservableQueries();

        navToCreateTE();
      }
    } catch (e) {
      console.error(e);
      toast({
        title: 'Error updating orderable item',
        status: 'error',
      });
    } finally {
      setUpdating(false);
    }
  };

  return {
    handleSave,
  };
}
