/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { ComboBox } from '../../form';
import {
  Box,
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  chakraComponents,
  type OptionBase,
  type GroupBase,
  type SelectComponentsConfig,
} from 'chakra-react-select';
import {
  useAddPatientPharmacyMutation,
  useGetPatientPharmaciesQuery,
  useGetPharmaciesLazyQuery,
  type Pharmacy,
} from '../../../__generated__/graphql';
import { useCustomToast, useDebounce, usePatient } from '../../../hooks';
import { CheckIcon } from '@chakra-ui/icons';
import { Scrollable, WaitUntilLoaded } from '../../layout';
import { EditIcon } from '../../svgs/Edit';
import { notEmpty } from '../../../utils';
import { isEmpty } from 'lodash';

interface PharmaOption extends OptionBase {
  label: string;
  value: string;
  pharmacyName: string;
  pharmacyId: number;
  address: string;
  phone: string;
}

const customComponents: SelectComponentsConfig<PharmaOption, true, GroupBase<PharmaOption>> = {
  Option: ({ ...props }) => (
    <chakraComponents.Option {...props}>
      <VStack gap='sm' align='left'>
        <Text>{props.data.pharmacyName}</Text>
        <Text>{props.data.address}</Text>
        <Text color='brand.500'>{props.data.phone}</Text>
      </VStack>
    </chakraComponents.Option>
  ),
};

export function PatientPharmacy() {
  const { patientId } = usePatient();
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false });
  const { data: dataPatientPharmacies, loading: loadingGetPatientPharmacies } =
    useGetPatientPharmaciesQuery({
      variables: { patientSourceId: patientId },
    });
  const [selectedPharmacy, setSelectedPharmacy] = useState<PharmaOption | null>(null);
  const [addPatientPharmacy, { loading: isLoadingAddPatientPharmacy }] =
    useAddPatientPharmacyMutation();
  const [searchText, setSearchText] = useState('');
  const toast = useCustomToast();
  const debouncedSearchText = useDebounce(searchText, 500);

  const patientPharmacies = dataPatientPharmacies?.getPatientPharmacies
    ? dataPatientPharmacies.getPatientPharmacies
    : [];
  const sortedPatientPharmacies = [...patientPharmacies] // create new object to prevent .sort error
    .sort((a) => (a.isPrimary ? -1 : 1)); // sort and place patient's primary pharmacy on top

  const primaryPatientPharmacy = patientPharmacies.find((pharmacy) => pharmacy.isPrimary);
  const defaultPharmacySelection: PharmaOption | undefined =
    formatPharmacyToOption(primaryPatientPharmacy);
  const selectedPharmacyId = selectedPharmacy?.pharmacyId;

  function handleAddPatientPharmacy() {
    if (selectedPharmacy?.pharmacyId) {
      addPatientPharmacy({
        variables: {
          isPrimary: true,
          patientSourceId: patientId,
          pharmacySourceId: selectedPharmacy.pharmacyId,
        },
        update(cache) {
          // Invalidate cache for getPatientPharmacies query, and refetch it
          cache.evict({
            fieldName: 'getPatientPharmacies',
          });
        },

        onCompleted() {
          toast({
            id: 'addPatientPharmacy',
            description: 'Updated patient pharmacy',
            status: 'success',
          });
          onClose();
        },
      });
    }
  }

  const [getPharmacies, { data: dataPharmacies, loading: loadingGetPharacies }] =
    useGetPharmaciesLazyQuery();

  const isLoading = loadingGetPharacies || loadingGetPatientPharmacies;

  useEffect(() => {
    if (debouncedSearchText) {
      getPharmacies({
        variables: {
          contains: debouncedSearchText,
          limit: 15,
          nextToken: null,
        },
      });
    }
  }, [debouncedSearchText]);

  function formatPharmacyToOption(pharmacy?: Pharmacy): PharmaOption | undefined {
    if (!pharmacy) return;

    const { pharmacyId, pharmacyName } = pharmacy;
    return {
      label: pharmacyName,
      value: pharmacyId.toString(),
      pharmacyId,
      pharmacyName,
      address: pharmacy?.pharmacyAddress ?? '',
      phone: pharmacy?.pharmacyPhoneNumber ?? '',
    };
  }

  function handleClose() {
    onClose();
  }

  // Set default patient pharmacy when it is fetched
  useEffect(() => {
    if (defaultPharmacySelection?.pharmacyId) {
      setSelectedPharmacy(defaultPharmacySelection);
    }
  }, [defaultPharmacySelection?.pharmacyId, isOpen]);

  const dropdownOptions = notEmpty(
    dataPharmacies?.getPharmacies?.pharmacies?.map(formatPharmacyToOption),
  );

  return (
    <>
      <Button
        size='sm'
        px='0'
        w='200px'
        onClick={(e) => {
          // prevent event from bubbling up to parent (UserInfo), so we don't navigate to different page.
          e.stopPropagation();
          onOpen();
        }}
        variant='link'
        justifyContent='left'
        rightIcon={<EditIcon boxSize={5} />}>
        <WaitUntilLoaded loading={loadingGetPatientPharmacies} spinnerProps={{ size: 'xs' }}>
          {/* text color specifically requested */}
          <Text color='#5E6C9B ' isTruncated fontSize='sm' lineHeight='short' textAlign='left'>
            {primaryPatientPharmacy?.pharmacyName ?? 'Add Pharmacy'}
          </Text>
        </WaitUntilLoaded>
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} size='md'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search Pharmacies</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ComboBox
              isLoading={isLoading}
              placeholder='Quick Find'
              onInputChange={setSearchText}
              onSelection={(selection: any) => {
                setSelectedPharmacy(selection);
              }}
              required={true}
              options={dropdownOptions}
              components={customComponents as any}
            />
            <Box mt='md' mb='sm'>
              <Text fontSize='lg' fontWeight='bold'>
                Patient Pharmacies
              </Text>
            </Box>
            {isEmpty(sortedPatientPharmacies) ? (
              <Text textAlign='center' mt='md'>
                No patient pharmacies.
              </Text>
            ) : (
              <Scrollable maxH='50vh'>
                <VStack spacing='0' align='flex-start'>
                  {sortedPatientPharmacies.map((pharmacy) => {
                    const isPrimaryPharmacy = selectedPharmacyId === pharmacy.pharmacyId;
                    return (
                      <HStack
                        key={pharmacy.pharmacyId}
                        w='full'
                        p='md'
                        rounded='md'
                        _hover={{
                          bg: 'brand.100',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const pharmacyOption = formatPharmacyToOption(pharmacy);
                          if (pharmacyOption) {
                            setSelectedPharmacy(pharmacyOption);
                          }
                        }}
                        border='1px solid'
                        borderColor={isPrimaryPharmacy ? 'orange.600' : 'transparent'}
                        bg={isPrimaryPharmacy ? 'brand.100' : ''}>
                        <Center flex='1'>
                          {isPrimaryPharmacy && <CheckIcon color='orange.600' />}
                        </Center>
                        <VStack flex='16' gap='xs' align='left' fontWeight='bold'>
                          <Text>{pharmacy.pharmacyName}</Text>
                          <Text>{pharmacy.pharmacyAddress}</Text>
                          <Text color='brand.500'>{pharmacy.pharmacyPhoneNumber}</Text>
                        </VStack>
                      </HStack>
                    );
                  })}
                </VStack>
              </Scrollable>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              colorScheme='brand'
              isLoading={isLoadingAddPatientPharmacy}
              onClick={handleAddPatientPharmacy}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
