import { Button } from '@chakra-ui/react';
import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { HPIFollowUpModalContent } from './HPIFollowUpModalContent';

export function HPIFollowUpModal() {
  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <HPIFollowUpModalContent />
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost'>Cancel</Button>
        <Button>Save Note</Button>
      </ModalFooter>
    </>
  );
}
