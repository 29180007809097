import { Flex, type FlexProps } from '../../components';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

/**
 * Container that accounts for space needed for "Save / Verify" button at the bottom of the MA workflow pages
 */
export function MAWorkFlowContentContainer(props: FlexProps) {
  const { height } = useWindowDimensions();
  return (
    <Flex
      direction='column'
      justifyContent='flex-start'
      h={height - 170} // Give space for fixed submit button
      overflowY='scroll'
      position='relative'
      {...props}
    />
  );
}
