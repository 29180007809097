import { useGetEncounterOrdersQuery } from '../../../__generated__/graphql';
import { useEncounter } from '../../../hooks';

export function useGetEncounterOrders() {
  const { activeEncounterID } = useEncounter();

  const { data: encounterOrdersData, ...query } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });

  const regularOrders = [
    ...(encounterOrdersData?.getEncounterOrders?.encounterOrderableItems ?? []), // create new array to avoid sort error
  ]?.sort((a, b) => {
    // Sort to show the most recently added orders first
    const aId = a?.orderableReportID ?? 0;
    const bId = b?.orderableReportID ?? 0;
    return bId - aId;
  });
  const immunizationOrders =
    encounterOrdersData?.getEncounterOrders?.encounterImmunizationInjectionItems;

  const numberOfOrders = (regularOrders?.length ?? 0) + (immunizationOrders?.length ?? 0);

  return {
    regularOrders,
    immunizationOrders,
    numberOfOrders,
    ...query,
  };
}
