import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface LocationState {
  selectedLocationID: number;
}

const initialState: LocationState = {
  // selectedLocationID: 9, // TODO: Default location ID for "All Locations"
  selectedLocationID: 11, // TODO: Default location ID for "All Locations"
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    onLocationChange(state, action: PayloadAction<LocationState>) {
      const { selectedLocationID } = action.payload;
      state.selectedLocationID = selectedLocationID;
    },
  },
});

export const locationActions = locationSlice.actions;

export default locationSlice.reducer;
