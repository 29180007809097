import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { formatDate } from '../../../../../utils';
import { type GetProviderEncounterDetailsQuery } from '../../../../../__generated__/graphql';
import React from 'react';
import { ResultsRows } from './ResultsRows';
import { AssessmentRows } from './AssessmentsRow';
import { FASTING_MAP } from '../../../../../constants';

type EncounterOrderableItem = NonNullable<
  NonNullable<GetProviderEncounterDetailsQuery['getProviderEncounterDetails']>['items'][0]
>;

interface Row {
  label: string;
  key: keyof EncounterOrderableItem;
  isDate?: boolean;
  style?: Record<string, string>;
  highlight?: boolean;
  onlyShowIf?: (item: EncounterOrderableItem) => boolean;
  map?: (item: EncounterOrderableItem) => string;
}

const data: Row[] = [
  {
    label: 'Collection Date',
    key: 'collectedDate',
    isDate: true,
    style: { fontWeight: 'bold' },
  },
  {
    label: 'Order Date',
    key: 'orderDate',
    isDate: true,
  },
  {
    label: 'Result Recd',
    key: 'resultDate',
    isDate: true,
  },
  {
    label: 'Report',
    key: 'resultDate',
    isDate: true,
  },
  {
    label: 'Result',
    key: 'result',
    onlyShowIf: (item) => !!item.result,
  },
  {
    label: 'Ordering Physician',
    key: 'orderingProviderName',
  },
  {
    label: 'Actual Fasting',
    key: 'actualFasting',
    map: (item) => FASTING_MAP[item.actualFasting ?? -1] ?? '',
  },
];

type R = Record<string, string>;

interface Props {
  orderableItem: EncounterOrderableItem;
}

export function OrderableItemTable({ orderableItem }: Props) {
  return (
    <Box fontSize='xs'>
      <Table>
        <Tbody>
          {data.map((row, i) => {
            const style = (row.style || {}) as R;
            const highlight: R = {};
            if (row.highlight) {
              highlight.textColor = 'brand.500';
            }

            let value = orderableItem[row.key];

            if (row.isDate && typeof value === 'string') {
              const formatted = formatDate(value);
              if (formatted) {
                value = formatted;
              }
            }

            if (row.onlyShowIf) {
              if (!row.onlyShowIf(orderableItem)) {
                return <React.Fragment key={i} />;
              }
            }

            if (row.map) {
              value = row.map(orderableItem);
            }

            return (
              <Tr _hover={{ background: 'gray.50' }} key={i} {...style}>
                <Td py={2}>{row.label}</Td>
                <Td {...highlight} py={2}>
                  {`${value}`}
                </Td>
              </Tr>
            );
          })}

          {orderableItem.assessments?.map((ass, i) => {
            if (!ass) return <React.Fragment key={i} />;
            return <AssessmentRows key={ass?.assessmentName} assessment={ass} />;
          })}
        </Tbody>
      </Table>

      {!!orderableItem.testResults?.length && (
        <Table>
          <Thead>
            <Tr borderBottom='1.5px solid white' background='gray.100' fontWeight='bold'>
              <Th paddingY={2.5}>Name</Th>
              <Th paddingY={2.5}>Value</Th>
              <Th paddingY={2.5} paddingX={24}>
                Reference Range
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderableItem.testResults?.map((result, i) => {
              if (!result) return <React.Fragment key={i} />;
              return <ResultsRows key={`${i}-${result.attributeID}`} result={result} />;
            })}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}
