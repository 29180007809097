import { type IcdCode10, type RecommendedOrderItem } from './constellation-recommendations.types';

const LAB_TYPE = 'Lab';
const LAB_ID = 0;
export const rapidStrepA: RecommendedOrderItem = {
  id: 245214,
  name: 'Rapid Strep A',
  itemTypeName: LAB_TYPE,
  itemTypeId: LAB_ID,
};

export const rapidCOVID: RecommendedOrderItem = {
  id: 582403,
  name: 'Rapid COVID Antigen -Coronavirus, (COVID-19) (SARS, CoV-2) - In Clinic',
  itemTypeName: LAB_TYPE,
  itemTypeId: LAB_ID,
};

export const rapidFluAB: RecommendedOrderItem = {
  id: 237287,
  name: 'Flu A/B- Rapid',
  itemTypeName: LAB_TYPE,
  itemTypeId: LAB_ID,
};

export const urinalysis: RecommendedOrderItem = {
  id: 223955,
  name: 'Urinalysis, Routine- In office',
  itemTypeName: LAB_TYPE,
  itemTypeId: LAB_ID,
};

export const mono: RecommendedOrderItem = {
  id: 224354,
  name: 'Mono - In office',
  itemTypeName: LAB_TYPE,
  itemTypeId: LAB_ID,
};

export const icdFluLikeSymptoms: IcdCode10 = {
  name: 'Flu-like symptoms',
  icd10Code: 'R68.89',
  itemId: 472641,
};
export const icdSoreThroat: IcdCode10 = {
  name: 'Sore throat',
  icd10Code: 'J02.9',
  itemId: 471161,
};
export const icdSwollenGlands: IcdCode10 = {
  name: 'Swollen Glands',
  icd10Code: 'R59.9',
  itemId: 484958,
};
export const icdCough: IcdCode10 = {
  name: 'Cough',
  icd10Code: 'R05.9',
  itemId: 572733,
};
export const icdDiarrhea: IcdCode10 = {
  name: 'Diarrhea',
  icd10Code: 'R19.7',
  itemId: 472222,
};
export const icdBodyAches: IcdCode10 = {
  name: 'Body Aches',
  icd10Code: 'R52',
  itemId: 472963,
};

export const icdUrinationProblem: IcdCode10 = {
  name: 'Urination',
  icd10Code: 'N39.9',
  itemId: 498095,
};
