import type { ComponentStyleConfig } from '@chakra-ui/react';

const bold = {
  fontWeight: 700,
};
const body2 = {
  fontSize: 16,
  lineHeight: '24px',
};

const h6 = {
  fontSize: 24,
  lineHeight: '29.26px',
  fontWeight: 400,
};

export const Text: ComponentStyleConfig = {
  variants: {
    h6,
    'h6-b': {
      ...h6,
      ...bold,
    },
    body2,
    'body2-b': {
      ...body2,
      ...bold,
    },
    'body-b': {
      ...body2,
      ...bold,
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
    },
  },
  defaultProps: {
    variant: 'body2',
  },
};
