import {
  type ConstellationRecommendation,
  type IcdCode10,
  type RecommendedOrderItem,
} from './constellation-recommendations.types';
import { type EncounterVital } from '../../__generated__/graphql';

export const createConstellationRecommendation = (
  recommendation: RecommendedOrderItem,
  icdCode: IcdCode10,
): ConstellationRecommendation => {
  return {
    ...recommendation,
    icdCode: icdCode.icd10Code,
    icdItemId: icdCode.itemId,
  };
};

/**
 * Note: Notice that we are filtering by name vs itemId or icdCode code is because when populating chief complaints
 * we only populate them by name. The API only returns names that match what is entered, therefore the frontend
 * only knows the name.
 *
 * @param source
 * @param target
 */
export function hasAnyMatch(source: string[], target: IcdCode10[]): boolean {
  return target.some((item) =>
    source.some((s) => !!s.toLowerCase().match(item.name.toLowerCase())),
  );
}

type numericTestFunctionType = (value: string, threshold: number) => boolean;

export const isEqualOrLargerThen: numericTestFunctionType = (
  value: string,
  threshold: number,
): boolean => {
  if (!isNaN(Number(value))) {
    return Number(value) >= threshold;
  }

  return false;
};

export const vitalTest = (
  vitals: EncounterVital[],
  vitalName: string,
  numericTest: numericTestFunctionType,
  thresholdValue: number,
): boolean => {
  const vitalMatchingCriteria = vitals.filter(
    (v) => v.type.toLowerCase() === vitalName && numericTest(v.value, 99),
  );
  return vitalMatchingCriteria && vitalMatchingCriteria.length > 0;
};
