import React from 'react';
import { Button } from '../../../../components';
import { appointmentsActions, modalActions, useAppDispatch } from '../../../../state-management';
import { type AlertId, type DeclineReasonConfig } from '../../../../types';

// TODO: Type decline reason. Export from appointmentSlice to global "types"
export function DeclineOrderButton({
  alertId,
  declineReasonConfig,
}: {
  alertId: AlertId;
  declineReasonConfig: DeclineReasonConfig;
}) {
  const dispatch = useAppDispatch();
  function addDeclineReason() {
    dispatch(
      appointmentsActions.addDeclineReasonStart({
        uiDeclineReasonCode: declineReasonConfig.uiDeclineReasonCode,
        declineReasonName: declineReasonConfig.name,
        alertId,
        serverDeclineReasonCode: declineReasonConfig.serverDeclineReasonCode,
      }),
    );

    dispatch(
      modalActions.showModal({
        modalProps: { alertId },
        modalType: 'DeclineReasonModal',
      }),
    );
  }

  return (
    <Button variant='outline' borderRadius='full' size='sm' onClick={addDeclineReason}>
      {declineReasonConfig?.name}
    </Button>
  );
}
