import {
  Box,
  Button,
  Card,
  Flex,
  HStack,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ComboBox } from '../form';
import { getClinicalStatusOptions } from '../data-display';
import { useState } from 'react';
import { useModal } from '../../hooks';
import { appointmentsActions, useAppDispatch } from '../../state-management';

interface ClinicalStatusSelectModalProps {
  clinicalStatusAfterCheckIn: string;
  updateClinicalStatus: (clinicalStatusAfterCheckIn: string) => Promise<void>;
  navigateModalType?: ModalTypes;
  isFullscreen?: boolean;
}

export function ClinicalStatusSelectModal(props: ClinicalStatusSelectModalProps) {
  const { clinicalStatusAfterCheckIn, updateClinicalStatus, navigateModalType, isFullscreen } =
    props;

  // Default to "Ready" when accessed via WorklistModal
  const [clinicalStatus, setClinicalStatus] = useState(
    navigateModalType === 'WorklistModal' ? 'Ready' : clinicalStatusAfterCheckIn,
  );

  const { showModal, hideModal } = useModal();
  const dispatch = useAppDispatch();

  return (
    <>
      <ModalCloseButton />
      <ModalBody backgroundColor={isFullscreen ? 'brand.500' : 'auto'}>
        <Flex height={isFullscreen ? '100vh' : 'auto'} alignItems='center' justifyContent='center'>
          <Card
            width={isFullscreen ? '50%' : '100%'}
            padding={isFullscreen ? 'lg' : 0}
            variant={isFullscreen ? 'elevated' : 'unstyled'}>
            <VStack alignItems='flex-start'>
              <Text as='b'>Clinical Status</Text>
              <Box width='100%' border='2px solid #DD6B20' borderRadius='9px'>
                <ComboBox
                  placeholder='Clinical Status'
                  options={getClinicalStatusOptions(clinicalStatus)}
                  required
                  defaultValue={{
                    label: clinicalStatus,
                    value: clinicalStatus,
                  }}
                  onSelection={(selection) => {
                    // Guarantee selection because we have set ComboBox to required, it can never be null/undefined
                    setClinicalStatus(selection!.label);
                  }}
                  isLoading={false}
                  isFixed={!isFullscreen}
                />
              </Box>

              <Flex width='100%' justifyContent='flex-end'>
                <HStack>
                  <Button
                    variant='ghost'
                    onClick={() => {
                      // Re-open WorklistModal on cancel when accessed via WorklistModal
                      navigateModalType
                        ? showModal({
                            modalType: navigateModalType,
                            chakraModalProps: { size: '6xl' },
                          })
                        : hideModal();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      // Update encounter in DB
                      updateClinicalStatus(clinicalStatus);
                      // Update encounter in Redux
                      dispatch(
                        appointmentsActions.updateClinicalStatus({
                          clinicalStatusAfterCheckIn: clinicalStatus,
                        }),
                      );

                      // Re-open WorklistModal on save when accessed via WorklistModal
                      navigateModalType
                        ? showModal({
                            modalType: navigateModalType,
                            chakraModalProps: { size: '6xl' },
                            modalProps: {
                              // Will open the confirmation child modal of WorklistModal when
                              // accessed via WorklistModal
                              showFinalize: navigateModalType === 'WorklistModal',
                            },
                          })
                        : hideModal();
                    }}>
                    Save
                  </Button>
                </HStack>
              </Flex>
            </VStack>
          </Card>
        </Flex>
      </ModalBody>
    </>
  );
}
