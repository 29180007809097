import { useStateComparison } from './useStateComparison';
import { getOrderFlow, useAppSelector } from '../../state-management';
import { useMAWorkflow } from '../useMAWorkflow';

interface GenericHandlerProps {
  state: Array<Record<string, unknown>>;
  step: OrderFlowSteps;
}

export function usePanelHandler({ step, state }: GenericHandlerProps) {
  const { getHasLoadedInitialData } = useMAWorkflow();
  const hasLoaded = getHasLoadedInitialData(step);

  const { currentStep } = useAppSelector(getOrderFlow);
  const isCurrentStep = currentStep === step;

  useStateComparison({
    state: state.map((s) => Object.values(s)),
    step,
    disabled: !hasLoaded || !isCurrentStep,
  });
}
