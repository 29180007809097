import { Box, Text } from '../../../components';
import { Heading } from '@chakra-ui/react';
import _ from 'lodash';
import { useMAWorkflow } from '../../../hooks';

export const ROSExistingEncounterSymptomsPanel = ({
  categoryEncounterData,
  clickHandler,
}: {
  categoryEncounterData?: EncounterRosDataUI | null;
  clickHandler?: (categoryId: number, symptomId?: number) => void;
}) => {
  const {
    ros: { selectedROSCategoryId, showingCategoryIds },
  } = useMAWorkflow();
  const rosEncounterCategorySymptoms = _.groupBy(
    categoryEncounterData?.encounterROSDataItems,
    (item) => item.rosCategoryID,
  );
  const notes = categoryEncounterData?.notes;
  return (
    <Box overflow='auto'>
      {notes && (
        <Box mb='md'>
          <Heading fontSize='lg'>
            <b>ROS Note</b>
          </Heading>
          <Text>{notes}</Text>
        </Box>
      )}

      {showingCategoryIds?.map(({ rosCategoryId, rosCategoryName }) => {
        return (
          <Category
            key={rosCategoryId}
            isActive={selectedROSCategoryId === rosCategoryId}
            categoryId={rosCategoryId}
            categoryName={rosCategoryName}
            symptoms={rosEncounterCategorySymptoms[rosCategoryId]?.sort((a, b) => {
              if ((a.rosSymptomID || -1) < (b.rosSymptomID || -1)) {
                return 1;
              }
              return -1;
            })}
            clickHandler={clickHandler}
          />
        );
      })}
    </Box>
  );
};

const Category = ({
  isActive,
  clickHandler,
  categoryId,
  categoryName,
  symptoms,
}: {
  isActive?: boolean;
  clickHandler?: (categoryId: number, symptomId?: number) => void;
  categoryId: number;
  categoryName?: string;
  categoryNotes?: string;
  symptoms: EncounterRosDataItemInputUI[];
}) => {
  return (
    <Box mb='md'>
      <Heading
        cursor='pointer'
        fontSize='lg'
        textDecoration='underline'
        color={isActive ? 'brand.500' : 'inherit'}
        onClick={() => {
          clickHandler?.(categoryId);
        }}>
        {categoryName}
      </Heading>
      <Box>
        {[...(symptoms || [])].map((item) => {
          const presenceOption = item.presenceOption?.toLowerCase().trim();
          const complains = presenceOption?.includes('admits');
          const note = item.symptomNote;

          return (
            <Text
              key={item.rosSymptomID}
              as='span'
              noOfLines={1}
              onClick={() => {
                clickHandler?.(categoryId, item.rosSymptomID || undefined);
              }}
              cursor='pointer'>
              {complains === undefined || !presenceOption ? '' : complains ? 'A:' : 'D:'}{' '}
              <Text as='span' color='custom.green'>
                {item.rosSymptomName}
                {note && ': '}
              </Text>
              <Text as='span' fontSize='.9rem'>
                {note}
              </Text>
            </Text>
          );
        })}
      </Box>
    </Box>
  );
};
