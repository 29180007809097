import { type QualifierConfig, type VitalQualifier } from '../../types';
import { VitalQualifierInput } from './VitalsPicker';
import { type EncounterVital, type VitalType } from '../../__generated__/graphql';
import { type InputProps as ChakraInputProps } from '@chakra-ui/input/dist/input';
import { type VitalValidation } from '../../screens/MAWorkFlowScreen/VitalsContent/VitalValidation';

interface Props extends Omit<ChakraInputProps, 'value' | 'onChange'> {
  onChange: (config: EncounterVital[]) => void;
  value: EncounterVital[];
  labelElement?: React.ReactElement;
  validationResult?: VitalValidation;
  onAddedHandler?: (value: string) => void;
}

const qualifiers = ['repeat'] as VitalQualifier[];

export function TempPicker({ onChange, value, errorBorderColor, ...rest }: Props) {
  function handleChangeQualifier(qualifierConfigs: QualifierConfig[]) {
    const vitals = qualifierConfigs?.map((config) => ({
      ...config,
      type: 'TEMP' as VitalType,
    }));
    onChange(vitals);
  }
  return (
    <VitalQualifierInput
      {...rest}
      errorBorderColor={errorBorderColor}
      defaultQualifier='repeat'
      qualifiers={qualifiers}
      onChange={handleChangeQualifier}
      savedQualifiers={value as QualifierConfig[]}
    />
  );
}
