import { HStack, Input, Radio, RadioGroup, Select, Text, VStack } from '@chakra-ui/react';
import { useGetAoeQuestionsAndOptionsQuery } from '../../../../__generated__/graphql';
import { useLabCollectionForm } from '../useLabCollectionForm';
import { DatePicker } from '../../../form';
import { Spinner } from '../../../data-display';
import { useAOEQuestionsAndLabInstructions } from '../useAOEQuestionsAndLabInstructions';

export function AOETabContent() {
  const { formValues } = useLabCollectionForm();
  const { hasAOEQuestions } = useAOEQuestionsAndLabInstructions();

  if (!hasAOEQuestions) {
    return (
      <Text textAlign={'center'} mt='lg'>
        No AOE questions
      </Text>
    );
  }

  return (
    <VStack align={'stretch'} spacing='lg' mt='lg'>
      {formValues?.map((lab) => {
        const id = lab.orderableReportID;
        const labItemId = lab.orderableItemId; // lab.labItemId;
        const name = lab.orderableItemName;

        return labItemId ? (
          <AOEQuestions
            key={id}
            labItemId={labItemId}
            reportId={id}
            formAOEAnswers={lab.aoeAnswers}
            name={name}
          />
        ) : null;
      })}
    </VStack>
  );
}

function AOEQuestions({
  labItemId,
  reportId,
  formAOEAnswers,
  name,
}: {
  labItemId: number;
  reportId: number;
  formAOEAnswers?: LabItem['aoeAnswers'];
  name?: string | null;
}) {
  const { handleUpdateAOEAnswer, globalLabForm } = useLabCollectionForm();
  const { sendToLabCompanyId } = globalLabForm;
  const { data, loading } = useGetAoeQuestionsAndOptionsQuery({
    variables: {
      labIds: [sendToLabCompanyId ?? 0], // Orchard. The only one we are using for now.
      labItemId,
    },
    skip: !sendToLabCompanyId,
  });
  const questions = data?.getAOEQuestionsAndOptions?.aoe || [];

  if (loading) {
    return <Spinner />;
  }

  if (!questions.length) {
    return null;
  }

  const renderQuestionField = questions.map((questionField) => {
    const { question, questionOptions, questionDetails } = questionField;
    const formAnswer = formAOEAnswers?.find(
      (answer) => answer.questionId === questionField.questionID,
    );

    function getAnswer(answerCode: string) {
      return questionOptions?.find((option) => option.optionCode === answerCode)?.options ?? '';
    }

    /**
     * Per Brannon, where applicable answerCode=optionCode and answer=options
     */
    function updateAnswer({ answer, answerCode }: { answer?: string; answerCode?: string }) {
      const answerForSubmission = (answerCode ? getAnswer(answerCode) : undefined) || answer;
      handleUpdateAOEAnswer({
        reportId,
        answer: answerForSubmission,
        answerCode: answerCode ?? ' ', // TODO: Brannon, it looks like answerCode is mandatory. For a case where no optionCode exists, what do we put as answerCode? For example FT where the user enters text in an input field (in some cases)), I currently use the user typed in text as answer but there's no optionCode
        questionId: questionField.questionID ?? -1,
        answeredByUserId: globalLabForm.labReviewedByUserId,
        answeredDateTime: globalLabForm.collectionDateTime,
        labCompanyId: globalLabForm.sendToLabCompanyId,
      });
    }

    const hasDateField = questionDetails?.some(
      (questionDetail) => questionDetail.fieldFormat === 'DT',
    );

    // DT - DateTime. (The DT fieldFormat is present in fieldTypes ST and FT)
    if (hasDateField) {
      return (
        <VStack key={question} spacing='sm' align={'flex-start'}>
          <Text>{question}</Text>
          {questionDetails?.map((questionDetail) => {
            if (questionDetail.fieldFormat === 'DT') {
              return (
                <DatePicker
                  key={questionDetail.questionID}
                  value={formAnswer?.answer ? new Date(formAnswer.answer) : null}
                  onChange={(isoDate) => {
                    updateAnswer({ answer: isoDate?.toISOString() ?? '' });
                  }}
                />
              );
            }

            // TODO: Are there other fieldFormats outside DT we need to handle?
            return null;
          })}
        </VStack>
      );
    }

    // DD - Drop Down
    if (questionField.fieldType === 'DD') {
      return (
        <VStack key={question} spacing='sm' align={'flex-start'}>
          <Text>{question}</Text>
          <Select
            placeholder='Select'
            value={formAnswer?.answerCode ?? ''}
            onChange={(e) => {
              updateAnswer({ answerCode: e.target.value });
            }}>
            {questionOptions?.map((option) => {
              const { options: label, optionCode: value } = option;
              return (
                <option key={value} value={value ?? ''}>
                  {label}
                </option>
              );
            })}
          </Select>
        </VStack>
      );
    }

    // FT - Free Text? (questionDetails field can be "DT" for DatePickers, so we need to check for that first)
    if (questionField.fieldType === 'FT' && !hasDateField) {
      return (
        <VStack key={question} spacing='sm' align={'flex-start'}>
          <Text>{question}</Text>
          <Input
            placeholder='Enter'
            value={formAnswer?.answer ?? ''}
            onChange={(e) => {
              updateAnswer({ answer: e.target.value });
            }}
          />
        </VStack>
      );
    }

    // RB - Radio Button
    if (questionField.fieldType === 'RB' && !hasDateField) {
      return (
        <VStack key={question} spacing='sm' align={'flex-start'}>
          <Text>{question}</Text>

          <RadioGroup
            value={formAnswer?.answerCode ?? ''}
            onChange={(optionCode) => {
              updateAnswer({ answerCode: optionCode });
            }}>
            <VStack align='flex-start'>
              {questionOptions?.map((option) => {
                const { options: label, optionCode: value } = option;
                return (
                  <Radio key={value} value={value ?? ''}>
                    {label}
                  </Radio>
                );
              })}
            </VStack>
          </RadioGroup>
        </VStack>
      );
    }

    return null;
  });

  return (
    <VStack spacing='md' align={'stretch'}>
      <HStack justifyContent={'space-between'}>
        <Text fontWeight={'bold'}>{name}</Text>
        <div>
          <Text color='secondary' fontWeight='bold' display='inline'>
            Lab:{' '}
          </Text>
          <Text fontWeight='bold' display='inline'>
            {globalLabForm.sendTolabCompanyName}
          </Text>
        </div>
      </HStack>
      <VStack spacing='md' align={'stretch'}>
        {renderQuestionField}
      </VStack>
    </VStack>
  );
}
