import { Flex, Text } from '@chakra-ui/react';
import { RadioCard } from '../MedicationStatusMarkAll';

interface Props {
  label?: string;
  onClick?: () => void;
  active?: boolean;
}

export function TakingButton({ active, label, onClick }: Props) {
  return (
    <Flex userSelect='none' w='full' gap='sm' justifyContent='end' alignItems='center'>
      {(active || label) && (
        <Text fontSize='sm' color='secondary'>
          {label || 'Taking'}
        </Text>
      )}
      <RadioCard
        label='T'
        value='T'
        onClick={() => {
          onClick?.();
        }}
        selected={active}
      />
    </Flex>
  );
}
