import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { ensomataApi } from '../api';
import { loginSlice } from './loginSlice';
import { modalSlice } from './modalSlice';
import { appointmentsSlice } from './appointmentsSlice';
import { locationSlice } from './locationSlice';
import { providerSlice } from './providerSlice';
import { uiSlice } from './uiSlice';
import { encounterFilterSlice } from './encounterFilterSlice';
import { openTEsFilterSlice } from './openTEsFilterSlice';
import { orderLabsDISlice } from './orderLabsDISlice';
import { patientSearchSlice } from './patientSearchSlice';
import { providerEncounterSlice } from './providerEncounterSlice';
import { providerWorkflowSlice } from './providerWorkflowSlice';
import { worklistTodoSlice } from './worklistTodoSlice';
import { getAppVersion } from '../constants';
import { refetchDataSlice } from './refetchDataSlice';
import { sidebarSlice } from './sidebarSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['appointments', 'location', 'provider'],
};

const rootReducer = combineReducers({
  encounterFilter: encounterFilterSlice.reducer,
  openTEsFilter: openTEsFilterSlice.reducer,
  orderLabsDI: orderLabsDISlice.reducer,
  location: locationSlice.reducer,
  provider: providerSlice.reducer,
  providerWorkflow: providerWorkflowSlice.reducer,
  modal: modalSlice.reducer,
  note: providerEncounterSlice.reducer,
  login: loginSlice.reducer,
  searchedPatients: patientSearchSlice.reducer,
  appointments: appointmentsSlice.reducer,
  ui: uiSlice.reducer,
  worklistTodo: worklistTodoSlice.reducer,
  refetchData: refetchDataSlice.reducer,
  sidebarSlice: sidebarSlice.reducer,
  [ensomataApi.reducerPath]: ensomataApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // reducer: rootReducer,
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ensomataApi.middleware),
});

export const persistor = persistStore(store);

// Persistor state can become de-synced with store, resulting in whitescreen
// errors. To fix this, we check if current app version has changed, and
// if so refresh persistor.
const localStorageVersion = window.localStorage.getItem('version');
const currentVersion = getAppVersion();

if (localStorageVersion !== currentVersion) {
  persistor.purge();
}
