import { type SelectProps } from '@chakra-ui/react';
import {
  Box,
  DatePicker,
  FormControl,
  FormLabel,
  HStack,
  Select as ChakraSelect,
  Text,
  VStack,
  Button,
  Spinner,
} from '../..';
import { modalActions, uiActions, useAppDispatch, useAppSelector } from '../../../state-management';
import { type AllergyCriticality, type AllergyStatus, type OptionItem } from '../../../types';
import { type AllergyModalProps } from './AddAllergyPromptModal';
import {
  useGetAllergyReactionsQuery,
  useGetAllergyTypesQuery,
} from '../../../__generated__/graphql';
import { convertISODateToJSDate, convertToISODate } from '../../../utils';

function useSelectedAllergyItem() {
  const selectedAllergyItem = useAppSelector(
    (state) => state.ui.orderFlowNavigation.selectedAllergyItem,
  );

  return { selectedAllergyItem };
}

function Select({ items, onChange, value }: { items: OptionItem[] } & SelectProps) {
  return (
    <ChakraSelect onChange={onChange} value={value} placeholder='Select'>
      {items.map((item) => {
        const key = item.id;
        const val = item.value;
        return (
          <option key={key} value={key}>
            {val}
          </option>
        );
      })}
    </ChakraSelect>
  );
}

function FormSpinner() {
  return (
    <Box py='sm'>
      <Spinner />
    </Box>
  );
}

function SelectAllergyReaction() {
  const { selectedAllergyItem } = useSelectedAllergyItem();
  const dispatch = useAppDispatch();

  const contains = '';
  const { data } = useGetAllergyReactionsQuery({ variables: { contains } });

  const transformedData: OptionItem[] | undefined = data?.getAllergyReactions?.map((item) => {
    return {
      id: item.id,
      value: item.reaction,
    };
  });

  const value = selectedAllergyItem?.reaction?.id
    ? selectedAllergyItem?.reaction?.id
    : transformedData?.find((option) => option.value === selectedAllergyItem?.reaction?.reaction)
        ?.id;

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const val = e.target.value;
    const id = Number(val);

    const item = transformedData?.find((d) => d.id === id);
    if (item != null) {
      dispatch(
        uiActions.editSelectedAllergyItem({
          reaction: { id: item.id, reaction: item?.value },
        }),
      );
    }
  }

  return (
    <FormControl>
      <FormLabel>Reactions(s)</FormLabel>
      {transformedData != null ? (
        <Select items={transformedData} onChange={handleChange} value={value} />
      ) : (
        <FormSpinner />
      )}
    </FormControl>
  );
}

function SelectAllergyType() {
  const { selectedAllergyItem } = useSelectedAllergyItem();
  const dispatch = useAppDispatch();

  const contains = '';
  const { data } = useGetAllergyTypesQuery({ variables: { contains } });

  const transformedData: OptionItem[] | undefined = data?.getAllergyTypes?.map((item) => {
    return {
      id: item.id,
      value: item.name,
    };
  });

  const value = selectedAllergyItem?.type?.id
    ? selectedAllergyItem?.type?.id
    : transformedData?.find((option) => option.value === selectedAllergyItem?.type?.name)?.id;

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const val = e.target.value;
    const id = Number(val);

    const item = transformedData?.find((d) => d.id === id);
    if (item != null) {
      dispatch(
        uiActions.editSelectedAllergyItem({
          type: { id: item.id, name: item?.value },
        }),
      );
    }
  }

  return (
    <FormControl>
      <FormLabel>Type</FormLabel>
      {transformedData != null ? (
        <Select items={transformedData} onChange={handleChange} value={value} />
      ) : (
        <FormSpinner />
      )}
    </FormControl>
  );
}

const allergyStatusOptions = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Inactive' },
];
function SelectAllergyStatus() {
  const { selectedAllergyItem } = useSelectedAllergyItem();
  const dispatch = useAppDispatch();

  const transformedData: OptionItem[] | undefined = allergyStatusOptions?.map((item) => {
    return {
      id: item.id,
      value: item.name,
    };
  });

  const value = selectedAllergyItem?.status?.id
    ? selectedAllergyItem?.status?.id
    : transformedData?.find((option) => option.value === selectedAllergyItem?.status?.name)?.id;

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const val = e.target.value;
    const id = Number(val);

    const item = transformedData?.find((d) => d.id === id);
    if (item != null) {
      dispatch(
        uiActions.editSelectedAllergyItem({
          status: {
            id: item.id,
            name: item?.value as AllergyStatus['name'],
          },
        }),
      );
    }
  }

  return (
    <FormControl>
      <FormLabel>Status</FormLabel>
      {transformedData ? (
        <Select items={transformedData} onChange={handleChange} value={value} />
      ) : (
        <FormSpinner />
      )}
    </FormControl>
  );
}

const criticalityOptions: AllergyCriticality[] = [
  { id: 1, name: 'Unknown' },
  { id: 2, name: 'High' },
  { id: 3, name: 'Low' },
];
function SelectCriticality() {
  const { selectedAllergyItem } = useSelectedAllergyItem();

  const dispatch = useAppDispatch();

  const transformedData: OptionItem[] | undefined = criticalityOptions?.map((item) => {
    return {
      id: item.id,
      value: item.name,
    };
  });

  const value = selectedAllergyItem?.criticality?.id
    ? selectedAllergyItem?.criticality?.id
    : transformedData?.find((option) => option.value === selectedAllergyItem?.criticality?.name)
        ?.id;

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const val = e.target.value;
    const id = Number(val);

    const item = transformedData?.find((d) => d.id === id);
    if (item != null) {
      dispatch(
        uiActions.editSelectedAllergyItem({
          criticality: {
            id: item.id,
            name: item?.value as AllergyCriticality['name'],
          },
        }),
      );
    }
  }

  return (
    <FormControl>
      <FormLabel>Criticality</FormLabel>
      {transformedData ? (
        <Select items={transformedData} onChange={handleChange} value={value} />
      ) : (
        <FormSpinner />
      )}
    </FormControl>
  );
}

function SelectOnsetDate() {
  const { selectedAllergyItem } = useSelectedAllergyItem();
  const existingOnsetDate = convertISODateToJSDate(selectedAllergyItem?.onSetDate);
  const dispatch = useAppDispatch();

  function handleChange(newDate: Date | null) {
    if (newDate != null) {
      dispatch(
        uiActions.editSelectedAllergyItem({
          onSetDate: convertToISODate(newDate),
        }),
      );
    }
  }

  return (
    <FormControl>
      <FormLabel>Onset Date</FormLabel>
      <DatePicker value={existingOnsetDate} onChange={handleChange} />
    </FormControl>
  );
}

export function AddAllergyPrompt({ isEdit }: AllergyModalProps) {
  const { selectedAllergyItem } = useSelectedAllergyItem();
  const dispatch = useAppDispatch();
  const allergySourceName = selectedAllergyItem?.source.agentSubstance;

  function handleAddAllergy() {
    dispatch(uiActions.saveSelectedAllergyItem());
    dispatch(uiActions.resetSelectedAllergy());
    dispatch(modalActions.hideModal());
  }

  function handleUpdateAllergy() {
    if (selectedAllergyItem?.id !== undefined) {
      dispatch(uiActions.replaceAllergyItem(selectedAllergyItem?.id));
      dispatch(uiActions.resetSelectedAllergy());
    }
    dispatch(modalActions.hideModal());
  }

  return (
    <VStack spacing='md'>
      <Text textAlign='center' variant='body-b'>
        {allergySourceName}
      </Text>
      <VStack w='full' spacing='md'>
        <HStack w='full'>
          <SelectAllergyReaction />
          <SelectCriticality />
        </HStack>
        <HStack w='full'>
          <Box flex='1'>
            <SelectAllergyType />
          </Box>
          <Box flex='1'>
            <SelectOnsetDate />
          </Box>
          <Box flex='1'>
            <SelectAllergyStatus />
          </Box>
        </HStack>
        <Button w='full' onClick={isEdit ? handleUpdateAllergy : handleAddAllergy}>
          {isEdit ? 'Update Allergy' : 'Add Allergy'}
        </Button>
      </VStack>
    </VStack>
  );
}
