import { BiArrowBack } from 'react-icons/bi';
import { Flex, SimpleGrid, Text } from '../../../../components';
import { PatientSearch } from '../PatientSearch';
import { Avatar, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks';

export function Header() {
  const user = useAuth();
  const name = `${user?.user?.firstName} ${user?.user?.lastName}`;

  return (
    <SimpleGrid gap={4} columns={2}>
      <Flex gap={4} alignItems='center' fontWeight='bold'>
        <Link to='/'>
          <BiArrowBack />
        </Link>
        <Text>Patient Follow-Up</Text>
      </Flex>
      <Flex alignItems='center' gap={4}>
        <Box width='100%' marginTop={2}>
          <PatientSearch />
        </Box>
        <Avatar size='md' name={name} bg='brand.500' color='white' />
      </Flex>
    </SimpleGrid>
  );
}
