import { Button, ModalHeader } from '@chakra-ui/react';
import { useModal } from '../../../hooks';
import { ModalBody, ModalFooter, ModalCloseButton } from '../../general';

export function ConfirmationModal({
  title,
  message,
  labels: { confirm, cancel } = { confirm: 'Confirm', cancel: 'Cancel' },
  onCancel,
  onConfirm,
  cancelProps,
  confirmProps,
}: ConfirmationModalProps) {
  const { hideModal } = useModal();

  return (
    <>
      <ModalCloseButton />
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={onCancel ?? hideModal} {...cancelProps}>
          {cancel}
        </Button>
        <Button onClick={onConfirm} {...confirmProps}>
          {confirm}
        </Button>
      </ModalFooter>
    </>
  );
}
