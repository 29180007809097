import { Box, WaitUntilLoaded } from '../../layout';
import {
  providerActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { type ComboBoxOption } from '../../../types';
import { BiChevronDown, BiX } from 'react-icons/bi';
import { Icon } from '../../general';
import { useGetProvidersQuery } from '../../../__generated__/graphql';
import { ComboBox } from '../../form';
import { useState } from 'react';
import {
  chakraComponents,
  type ClearIndicatorProps,
  type DropdownIndicatorProps,
  type GroupBase,
} from 'chakra-react-select';
import { type ChakraStylesConfig } from 'chakra-react-select/dist/types/types';

function sortDesc(a: ComboBoxOption, b: ComboBoxOption) {
  const aVal = a?.label;
  const bVal = b?.label;

  if (aVal > bVal) {
    return 1;
  }
  if (aVal < bVal) {
    return -1;
  }
  return 0;
}

export function ProviderSelector() {
  const possibleProviderIds = useAppSelector(selectors.getPatientSearchScreenProviderIds) || [];

  const {
    data,
    loading: isLoading,
    error,
  } = useGetProvidersQuery({ variables: { ids: possibleProviderIds } });

  const selectedProviderIDs = useAppSelector(selectors.getSelectedProviderIDs) || [];
  // TODO: REMOVE DEBUGGING ONLY
  // handleOpenModal();

  const options =
    data?.getProviders?.providers
      ?.map((p) => {
        return {
          label: p ? (p.lastName || '') + ', ' + (p.firstName || '') : '',
          value: p.providerID.toString() || '',
        };
      })
      .sort(sortDesc) || [];
  const [search, setSearch] = useState('');
  const lowercaseSearch = search.toLowerCase();
  options.filter((a) => a.label.toLowerCase().includes(lowercaseSearch)).slice(0, 10);
  const components = {
    DropdownIndicator: (
      props: DropdownIndicatorProps<ComboBoxOption, boolean, GroupBase<ComboBoxOption>>,
    ) => {
      return (
        <chakraComponents.DropdownIndicator {...props}>
          <Icon boxSize={10} color='brand.400' as={BiChevronDown} />
        </chakraComponents.DropdownIndicator>
      );
    },
    ClearIndicator: (
      props: ClearIndicatorProps<ComboBoxOption, boolean, GroupBase<ComboBoxOption>>,
    ) => (
      <chakraComponents.ClearIndicator {...props}>
        <Icon boxSize={9} color='brand.400' as={BiX} />
      </chakraComponents.ClearIndicator>
    ),
  };
  const chakraStyles: ChakraStylesConfig<ComboBoxOption, boolean, any> = {
    container: (provided) => ({
      ...provided,
      borderTop: '1px solid white',
      borderLeft: '1px solid white',
      borderRight: '1px solid white',
      borderBottomRadius: 0,
      borderRadius: 0,
      div: {
        borderBottomRadius: 0,
        _focus: {
          borderBottomRadius: 'md',
        },
        _hover: {
          borderBottomRadius: 'md',
        },
      },
    }),
  };
  const dispatch = useAppDispatch();
  return (
    <WaitUntilLoaded
      loading={isLoading}
      showErrorMessage={!(error == null)}
      errorMessage='Cannot load providers'>
      <Box mt={2}>
        <ComboBox
          size='lg'
          isLoading={isLoading}
          onInputChange={setSearch}
          placeholder={'Find a Provider' ?? ''}
          options={options}
          onSelection={(selection) => {
            if (selection) {
              dispatch(
                providerActions.setSelectedProviderIds({
                  selectedProviderIDs: selection.map((option) => parseInt(option.value)),
                }),
              );
            }
          }}
          isMulti={true}
          defaultValue={options.filter((option) =>
            selectedProviderIDs.find((id) => id === parseInt(option.value)),
          )}
          components={components}
          chakraStyles={chakraStyles}
        />
      </Box>
    </WaitUntilLoaded>
  );
}
