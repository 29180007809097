import {
  type SubmitImmunizationInjectionInput,
  useUpdateImmunizationInjectionMutation,
} from '../../../__generated__/graphql';
import { useCustomToast, useModal } from '../../../hooks';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';

export function useAdministerVaccineForm() {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector((state) => state.worklistTodo.administerVaccineForm);
  const activePendingVaccine = useAppSelector((state) => state.worklistTodo.selectedPendingVaccine);
  const { showModal } = useModal();

  const toast = useCustomToast();

  const [updateImmunization, { loading: isSubmittingForm }] =
    useUpdateImmunizationInjectionMutation();

  function handleUpdateForm(updateValues: typeof formValues) {
    if (updateValues) {
      dispatch(worklistTodoActions.updateAdministerVaccineForm(updateValues));
    }
  }

  function handleResetVaccineForm() {
    dispatch(worklistTodoActions.resetAdministerVaccineForm());
  }

  function handleMarkVaccineFormComplete(id: number) {
    dispatch(worklistTodoActions.markPendingVaccineComplete(id));
  }

  function handleSubmitForm() {
    if (formValues) {
      const { sourceEncounterId, immunizationItemId, statusId, sourcePatientId } = formValues;
      const canSubmit = sourceEncounterId && immunizationItemId && statusId && sourcePatientId;

      if (canSubmit) {
        const input: SubmitImmunizationInjectionInput = {
          ...formValues,
          lotId: formValues.lotId || 0,
          sourceEncounterId,
          immunizationItemId,
          statusId,
          sourcePatientId,
        };

        updateImmunization({
          variables: {
            input,
          },
          onError(e) {
            toast({
              id: 'vaccine-form',
              title: 'Error',
              description: e.message,
              status: 'error',
              duration: null,
            });
          },
          onCompleted() {
            toast({
              id: 'vaccine-form',
              title: 'Success',
              description: `${activePendingVaccine?.text} submitted successfully`,
              status: 'success',
              duration: 3000,
            });
            // TODO: invalidate cache to pull Past Vaccines from server

            // Mark specific item as complete
            if (activePendingVaccine?.id) {
              handleMarkVaccineFormComplete(activePendingVaccine.id);
            }

            // go to previous back containing other pending vaccines
            showModal({
              modalType: 'PendingVaccinesModal',
              chakraModalProps: { size: '6xl' },
            });
          },
        });
      } else {
        toast({
          id: 'vaccine-form',
          description: 'Please fill out form required fields',
          status: 'error',
        });
      }
    }
  }

  return {
    isSubmittingForm,
    formValues,
    activePendingVaccine,
    handleUpdateForm,
    handleResetVaccineForm,
    handleMarkVaccineFormComplete,
    handleSubmitForm,
  };
}
