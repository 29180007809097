import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Flex,
  IconButton,
  SkeletonCircle,
  SkeletonText,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { BiArrowBack } from 'react-icons/bi';
import { EncounterPanel } from '../EncounterPanel';
import { useState } from 'react';
import { Menu } from './components/Menu';
import { useNavigation } from '../../../../hooks';
import { calculateAge, formatDateNoUTC } from '../../../../utils';
import { useGetPatientBasicDetailsQuery } from '../../../../__generated__/graphql';

const panelButtonLabels = ['Encounters', 'Allergies', 'Problem List', 'Med. Log'];

interface Props {
  patientId?: number;
}

export function EncounterHeader({ patientId }: Props) {
  const navigation = useNavigation();

  const spacing = {
    style: {
      marginTop: 0,
      marginBottom: 0,
    },
    width: '100%',
    lineHeight: 1.5,
  };

  const buttonProps = {
    bg: 'white',
    color: 'brand.400',
    border: 'none',
    borderRadius: 0,
  };

  const { isOpen: panelIsOpen, onOpen: panelOnOpen, onClose: panelOnClose } = useDisclosure();
  const { isOpen: menuIsOpen, onOpen: menuOnOpen, onClose: menuOnClose } = useDisclosure();
  const [initialSelected, setInitialSelected] = useState(0);

  const { data } = useGetPatientBasicDetailsQuery({ variables: { patientID: patientId ?? 0 } });

  const patientName = `${data?.getPatientBasicDetails?.firstName} ${data?.getPatientBasicDetails?.lastName}`;

  return (
    <Flex justifyContent='space-between'>
      <Flex gap={4} alignItems='center' fontWeight='bold'>
        <Drawer
          isOpen={panelIsOpen}
          placement='right'
          onClose={panelOnClose}
          isFullHeight
          size='full'>
          <EncounterPanel
            patientId={patientId}
            initialSelected={initialSelected}
            onClose={panelOnClose}
          />
        </Drawer>

        <Drawer isOpen={menuIsOpen} placement='right' onClose={menuOnClose}>
          <Menu />
        </Drawer>
        <BiArrowBack onClick={navigation.back} />
        {data?.getPatientBasicDetails?.gender ? (
          <Avatar size='md' name={patientName} bg='brand.500' color='white' />
        ) : (
          <SkeletonCircle size='50' />
        )}
        {data?.getPatientBasicDetails ? (
          <VStack gap={0}>
            <Text {...spacing} fontWeight='bold'>
              {patientName}
            </Text>
            <Text color='gray.600' {...spacing} fontSize='sm'>
              {patientId}
            </Text>
            <Text color='gray.600' {...spacing} fontSize='sm'>
              {calculateAge(data?.getPatientBasicDetails?.dateOfBirth)}
              {data?.getPatientBasicDetails?.gender
                ? data?.getPatientBasicDetails?.gender[0]
                : ''}{' '}
              {formatDateNoUTC(data?.getPatientBasicDetails?.dateOfBirth)}
            </Text>
          </VStack>
        ) : (
          <VStack gap={1} alignItems='flex-start'>
            <SkeletonText noOfLines={1} skeletonHeight={5} width='100px' />
            <SkeletonText noOfLines={1} skeletonHeight={4} width='50px' />
            <SkeletonText noOfLines={1} skeletonHeight={4} width='100px' />
          </VStack>
        )}
      </Flex>
      <Flex alignItems='center' gap={4}>
        <Box
          borderRadius={4}
          bg='red'
          display='flex'
          borderColor='brand.400'
          borderWidth={1}
          overflow='hidden'>
          {panelButtonLabels.map((label, index) => {
            return (
              <Button
                key={index}
                {...buttonProps}
                onClick={() => {
                  setInitialSelected(index);
                  panelOnOpen();
                }}>
                {label}
              </Button>
            );
          })}
        </Box>
        <IconButton aria-label='hamburger-menu' variant='ghost' onClick={menuOnOpen}>
          <HamburgerIcon />
        </IconButton>
      </Flex>
    </Flex>
  );
}
