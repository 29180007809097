import { Select } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector, worklistTodoActions } from '../../../state-management';

// TODO: Replace with central version in /features
const options: Array<{ value: ActualFasting; label: string }> = [
  { value: 0, label: 'Fasting' },
  { value: 1, label: 'Not Fasting' },
  { value: 2, label: 'Not Recorded' },
];

export function ActualFastingSelect() {
  const dispatch = useAppDispatch();
  const actualFastingValue = useAppSelector(
    (state) => state.worklistTodo?.labGlobalForm?.actualFasting,
  );

  return (
    <Select
      value={actualFastingValue ?? undefined}
      onChange={(e) => {
        const newVal = e.target.value ? (Number(e.target.value) as ActualFasting) : 2;
        dispatch(worklistTodoActions.updateGlobalLabForm({ actualFasting: newVal }));
      }}>
      {options.map((option) => (
        <option key={option.value} value={option.value ?? undefined}>
          {option.label}
        </option>
      ))}
    </Select>
  );
}
