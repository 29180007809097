import { usePendingVaccines, useChecklistConstellationItems, useModal } from '../../../hooks';
import { useLabCollectionForm } from '../LabCollectionFormModal/useLabCollectionForm';

const chakraModalProps = { size: '6xl' };

export function useWorklistModal() {
  const { hasAtLeastOneConstellationItem } = useChecklistConstellationItems();
  const { hasAtLeastOneSubmittedVaccineOrder } = usePendingVaccines();
  const { hasAtLeastOneSubmittedLabOrder } = useLabCollectionForm();
  const hasAtLeastOneWorklistItem =
    hasAtLeastOneSubmittedVaccineOrder ||
    hasAtLeastOneSubmittedLabOrder ||
    hasAtLeastOneConstellationItem;

  const { showModal } = useModal();

  function toWorkListModal() {
    showModal({
      modalType: 'WorklistModal',
      chakraModalProps,
    });
  }
  function toLabCollectionFormModal() {
    showModal({
      modalType: 'LabCollectionFormModal',
      chakraModalProps,
    });
  }
  function toAOEQuestionsAndLabForms() {
    showModal({
      modalType: 'AOEQuestionsAndLabFormsModal',
      chakraModalProps,
    });
  }

  return {
    toAOEQuestionsAndLabForms,
    toWorkListModal,
    toLabCollectionFormModal,
    hasAtLeastOneWorklistItem,
    hasAtLeastOneSubmittedVaccineOrder,
    hasAtLeastOneSubmittedLabOrder,
    hasAtLeastOneConstellationItem,
  };
}
