import React, { type ReactNode } from 'react';
import { Box, type BoxProps } from '../../layout';

export type CardVariant = 'accept' | 'reject';
interface CardProps extends BoxProps {
  active?: boolean;
  variant?: CardVariant;
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

const pxSize = {
  sm: 'md',
  md: 'lg',
  lg: 'lg',
};

const Card = React.forwardRef(
  (
    { active, children, variant = 'accept', size = 'lg', ...rest }: CardProps,
    ref: React.Ref<any>,
  ) => {
    const styles = {
      bg: 'white',
      boxShadow: '0px 4px 10px rgba(126, 162, 255, 0.25)',
      py: size,
      px: pxSize[size],
    };
    const borderRightStyle = active
      ? {
          borderRightWidth: 4,
          borderRightColor: 'primary',
        }
      : {};

    if (variant === 'reject') {
      styles.bg = 'secondaries.0';
    }

    return (
      <Box p='lg' borderRadius='md' w='full' {...styles} {...borderRightStyle} {...rest} ref={ref}>
        {children}
      </Box>
    );
  },
);

Card.displayName = 'Card';
export { Card };
