import { useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  ModalCloseButton,
  ModalHeader,
  SelectDiagnosis,
  WaitUntilLoaded,
} from '../../index';
import { Checkbox, ModalBody, ModalFooter } from '@chakra-ui/react';
import { useModal, usePatient } from '../../../hooks';
import { AsyncSelectField } from '../../../features';
import {
  type OrderableItemLookupUI,
  type DiagnosisUI,
  type ImmunizationInjectionLookupUI,
} from '../../../types';
import { Text } from '../../typography';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import {
  convertImmunizationInjectionToAsyncDropdownOptions,
  convertOrderableItemsToAsyncDropdownOptions,
  createManualImmunizationInjection,
  createManualOrderableItem,
  useAddOrder,
} from './adhoc-order.helper';
import {
  useGetImmunizationInjectionsQuery,
  useGetOrderableItemsLookupQuery,
} from '../../../__generated__/graphql';

export function AddAdHocOrderModal() {
  const { loading: isLoading } = usePatient();
  const { isLoading: isLoadingAddOrder, handleAddOrder } = useAddOrder();
  const { hideModal } = useModal();
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [selectedOrderItem, setSelectedOrderItem] = useState<DropdownOption | undefined | null>();
  const [selectedDiagnosisItem, setSelectedProblem] = useState<DiagnosisUI | undefined | null>();
  const [isFutureOrder, setIsFutureOrder] = useState(false);
  const searchRef = useRef<{ reset: () => void }>();
  const { data: orderableItemsData, loading: loadingSearch } = useGetOrderableItemsLookupQuery({
    variables: {
      filter: { name: searchTerm },
    },
    skip: !searchTerm,
  });

  const { data: immunizationInjectionData } = useGetImmunizationInjectionsQuery({
    variables: {
      contains: searchTerm,
    },
    skip: !searchTerm,
  });

  const orderableItems = convertOrderableItemsToAsyncDropdownOptions(
    orderableItemsData?.orderableItemsLookup,
  );

  const immunizationInjections = convertImmunizationInjectionToAsyncDropdownOptions(
    immunizationInjectionData?.getImmunizationInjections,
  );

  const adHocOptions = [...orderableItems, ...immunizationInjections];

  const saveHandler = () => {
    // ImmunizationInjections need to be handled differently, so their ids are prefixed by "immunization-injection:"
    // If the prefix isn't there, we know the selection is an OrderableItem
    const isImmunizationInjection = selectedOrderItem?.value.includes('immunization-injection:');

    const selectedItem = isImmunizationInjection
      ? immunizationInjectionData?.getImmunizationInjections.find(
          (item) =>
            item.immunizationItemId ===
            parseInt(selectedOrderItem?.value.replace('immunization-injection:', '') ?? '-1'),
        )
      : orderableItemsData?.orderableItemsLookup.find(
          (item) => item.sourceId === selectedOrderItem?.value,
        );

    if (selectedItem && selectedDiagnosisItem) {
      const adHocRecommendation = isImmunizationInjection
        ? createManualImmunizationInjection(
            selectedItem as ImmunizationInjectionLookupUI,
            selectedDiagnosisItem,
            isFutureOrder,
          )
        : createManualOrderableItem(
            selectedItem as OrderableItemLookupUI,
            selectedDiagnosisItem,
            isFutureOrder,
          );

      handleAddOrder(adHocRecommendation, {
        onCompleted: () => {
          hideModal();
        },
      });
    }
  };

  return (
    <WaitUntilLoaded loading={isLoading}>
      <>
        <ModalHeader>Add Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            height={(adHocOptions.length && !selectedOrderItem) || loadingSearch ? 300 : undefined}>
            <Box mb='md'>
              <AsyncSelectField
                inputRightElement={<SearchIcon color='gray.300' />}
                handleOptionSelect={(option) => {
                  setSelectedOrderItem(option);
                }}
                inputProps={{
                  placeholder: 'Quick Find',
                  autoFocus: true,
                }}
                dropdownItems={adHocOptions}
                handleInputChange={(val) => {
                  setSearchTerm(val);
                }}
                ref={searchRef}
                loading={loadingSearch}
              />
            </Box>
            <Box mb='md' pl='md' pr='md'>
              {selectedOrderItem && (
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <Text variant='body-b'>{selectedOrderItem.label}</Text>
                  <IconButton
                    aria-label='Delete'
                    variant='ghost'
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setSelectedProblem(undefined);
                      setSelectedOrderItem(undefined);
                      searchRef.current?.reset();
                    }}
                  />
                </Box>
              )}
            </Box>
            <SelectDiagnosis
              isDisabled={!selectedOrderItem}
              value={selectedDiagnosisItem?.assessmentItemId}
              onChange={(diagnosis) => {
                setSelectedProblem(diagnosis);
              }}
            />
            <Box display='flex' flexDirection='row' justifyContent='space-between' mt='md'>
              <HStack>
                <Text>Future Order?</Text>
                <Checkbox
                  isChecked={isFutureOrder}
                  size='lg'
                  colorScheme='brand'
                  onChange={(e) => {
                    setIsFutureOrder(!isFutureOrder);
                  }}
                />
              </HStack>
            </Box>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            ml='auto'
            mr='sm'
            variant='ghost'
            onClick={() => {
              hideModal();
            }}>
            Cancel
          </Button>
          <Button
            isDisabled={!selectedOrderItem || !selectedDiagnosisItem}
            onClick={saveHandler}
            isLoading={isLoadingAddOrder}>
            Create Order
          </Button>
        </ModalFooter>
      </>
    </WaitUntilLoaded>
  );
}
