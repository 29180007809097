import { Textarea } from '@chakra-ui/react';
import { useLabCollectionForm } from './useLabCollectionForm';

export function ClinicalInfoInput() {
  const { handleUpdateGlobalLabForm, globalLabForm } = useLabCollectionForm();

  return (
    <Textarea
      placeholder='Clinical Info'
      rows={2}
      value={globalLabForm?.clinicalInformation ?? undefined}
      onChange={(e) => {
        handleUpdateGlobalLabForm({
          clinicalInformation: e.target.value,
        });
      }}
    />
  );
}
