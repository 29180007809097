import { isNumber } from 'lodash';
import {
  OrderableItemOrderType,
  type Problem,
  type AddOrderableItemMutationVariables,
  RecommendationType,
  useSubmitImmunizationInjectionMutation,
  useAddOrderableItemMutation,
  type ImmunizationInjectionLookup,
} from '../../../__generated__/graphql';
import { IS_LOCAL } from '../../../constants';
import {
  useCustomToast,
  useEncounter,
  useFacility,
  useMAWorkflow,
  usePatient,
} from '../../../hooks';
import {
  selectors,
  useAppDispatch,
  useAppSelector,
  worklistTodoActions,
  type EditedOrder,
} from '../../../state-management';
import {
  getOrderableItemReasonFromRecommendationType,
  isRecommendationInjectionOrImmunization,
} from '../../../utils';
import {
  type ImmunizationInjectionLookupUI,
  type DiagnosisUI,
  type OptionItem,
  type OrderableItemLookupUI,
} from '../../../types';

export function useAddOrder() {
  const { facilityId: facilityID } = useFacility();
  const { patientId } = usePatient();
  const { activeEncounterID: encounterID } = useEncounter();
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const orderingProviderID = useAppSelector(selectors.getActiveProviderID);
  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  // Mutations
  const [submitImmunization, { loading: isLoadingSubmitImmunization }] =
    useSubmitImmunizationInjectionMutation();
  const [handleAddOrderableItem, { loading: isLoadingAddOrderableItem }] =
    useAddOrderableItemMutation();

  function handleShowErrorToast(orderableItemName?: string | null) {
    if (orderableItemName) {
      toast({
        id: `submission-error-toast-${orderableItemName}`,
        title: 'Error',
        description: `${orderableItemName} failed to submit`,
        status: 'error',
        duration: null,
      });
    }
  }

  async function handleAddOrder(
    order: EditedOrder,
    { onCompleted }: { onCompleted?: () => void } = {},
  ) {
    const orderableItemTypeId = order?.alert?.orderableItemTypeId;
    const recommendation = order?.alert;
    const orderableItemOrderType =
      order?.alert.orderableItemOrderType || OrderableItemOrderType.Recommended;
    const futureOrder = recommendation?.futureOrder;
    const isInjectionOrImmunization = isRecommendationInjectionOrImmunization(order);
    const assignedToUserEmail = IS_LOCAL ? 'tdale@elfp.com' : 'jbaker@elfp.com'; // TODO: Real user email
    const documentingFacilityID = facilityID;
    const selectedOrderableItem = order.selectedOrderableItem;
    const orderableItemId = selectedOrderableItem?.orderableItemId
      ? selectedOrderableItem.orderableItemId
      : recommendation?.orderableItemId;
    const orderableItemName = selectedOrderableItem?.orderableItemName
      ? selectedOrderableItem.orderableItemName
      : recommendation?.orderableItemName;
    const labItemId = orderableItemId; // Normal order
    const immunizationItemID = order.alert.alertId; // Immunization order
    const immunizationItemName = order.alert.description;
    const patientID = patientId;
    const recommendationType = recommendation?.recommendationType;
    const assessmentItemId = order?.diagnosis?.assessmentItemId;

    function invalidateCache(cache: any) {
      // Invalidate cache
      cache.evict({
        fieldName: 'getEncounterOrders',
      });
    }

    // HANDLE ACCEPTED ORDERS
    if (isInjectionOrImmunization) {
      // PROCESS IMMUNIZATION ORDER
      const canAddOrder =
        assignedToUserEmail &&
        documentingFacilityID &&
        encounterID &&
        immunizationItemID &&
        patientID &&
        recommendationType;

      if (canAddOrder) {
        return await submitImmunization({
          variables: {
            input: {
              sourceEncounterId: encounterID,
              immunizationItemId: immunizationItemID,
              immunizationName: immunizationItemName,
              statusId: 3, // "Not Administered"
              sourcePatientId: patientID,
              documentingFacilityId: documentingFacilityID,
              patientImmunizationInjectionType: recommendationType,
              lotId: 0,
            },
          },
          update: invalidateCache,
          onError: () => {
            handleShowErrorToast(orderableItemName);
          },
          onCompleted: (res) => {
            if (isMAWorkflowEnabled) {
              const immunizationResponse = res.submitImmunizationInjection;
              dispatch(
                worklistTodoActions.addPendingVaccine({
                  id: immunizationItemID,
                  text: orderableItemName ?? '',
                  completed: false,
                  immunization: immunizationResponse,
                }),
              );
            }

            onCompleted?.();
          },
        });
      }
    } else {
      // PROCESS NON-IMMUNIZATION ORDER
      const canAddOrder =
        assessmentItemId &&
        labItemId &&
        patientId &&
        facilityID &&
        encounterID &&
        isNumber(orderableItemTypeId) && // Could be "0"
        orderingProviderID;

      if (canAddOrder) {
        const requestParams: AddOrderableItemMutationVariables = {
          assessmentItemId,
          labItemId,
          assignedToUserEmail,
          encounterID,
          orderDate: new Date().toISOString(), // TODO: server-side?
          orderableItemTypeId:
            orderableItemTypeId !== null && isNumber(orderableItemTypeId)
              ? orderableItemTypeId
              : -1,
          orderingProviderID,
          patientId,
          facilityID,
          futureOrder,
          orderableItemOrderType,
          reason: getOrderableItemReasonFromRecommendationType(recommendationType),
          // new
          orderableItemName: orderableItemName ?? '',
        };
        return await handleAddOrderableItem({
          variables: requestParams,
          onError: () => {
            handleShowErrorToast(orderableItemName);
          },
          update: invalidateCache,
          onCompleted: () => {
            onCompleted?.();
          },
        });
      }
    }
  }

  return {
    isLoading: isLoadingSubmitImmunization || isLoadingAddOrderableItem,
    handleAddOrder,
  };
}

export const createManualOrderableItem = (
  orderableItem: OrderableItemLookupUI,
  diagnosis: DiagnosisUI,
  isFutureOrder: boolean,
): EditedOrder => {
  const selectedItemId = parseInt(orderableItem?.sourceId || '-1');

  return {
    status: 'YES_ADDING_DIAGNOSIS',
    diagnosis: {
      diagnosisName: diagnosis?.diagnosisName || '',
      assessmentItemId: diagnosis?.assessmentItemId || -1,
    },
    alert: {
      alertId: selectedItemId,
      shortDescription: orderableItem?.name || '',
      longDescription: '',
      defaultAssessmentItemId: diagnosis?.assessmentItemId || -1,
      defaultIcdCode: diagnosis?.icdCode || '',
      defaultDiagnosisName: diagnosis?.diagnosisName || '',
      orderableItemTypeId: parseInt(orderableItem?.typeId || '-1'),
      orderableItemId: selectedItemId,
      orderableItemName: orderableItem?.name,
      orderableItemOrderType: OrderableItemOrderType.Adhoc,
      description: orderableItem?.name,
      recommendationType: convertRecommendationTypeStringToEnum(orderableItem?.type),
      futureOrder: isFutureOrder,
      isOrderable: true,
      displayOrder: 0, // setting it to 0 so it always shows up on top of CDSS page
    },
    orderType: 'ACCEPTED',
  };
};

export const createManualImmunizationInjection = (
  immunizationInjection: ImmunizationInjectionLookupUI,
  diagnosis: DiagnosisUI,
  isFutureOrder: boolean,
): EditedOrder => {
  const selectedItemId = parseInt(immunizationInjection.immunizationItemId.toString() || '-1');

  return {
    status: 'YES_ADDING_DIAGNOSIS',
    diagnosis: {
      diagnosisName: diagnosis?.diagnosisName || '',
      assessmentItemId: diagnosis?.assessmentItemId || -1,
    },
    alert: {
      alertId: selectedItemId,
      shortDescription: immunizationInjection.name || '',
      longDescription: '',
      defaultAssessmentItemId: diagnosis?.assessmentItemId || -1,
      defaultIcdCode: diagnosis?.icdCode || '',
      defaultDiagnosisName: diagnosis?.diagnosisName || '',
      description: immunizationInjection?.name,
      recommendationType: convertRecommendationTypeStringToEnum(immunizationInjection?.type),
      futureOrder: isFutureOrder,
      isOrderable: true,
      displayOrder: 0, // setting it to 0 so it always shows up on top of CDSS page
    },
    orderType: 'ACCEPTED',
  };
};

export const convertRecommendationTypeStringToEnum = (
  recommendationType: string | null,
): RecommendationType | null => {
  if (!recommendationType) return null;
  switch (recommendationType) {
    case 'Lab':
      return RecommendationType.Lab;
    case 'Diagnostic Imaging':
      return RecommendationType.Imaging;
    case 'Procedure':
      return RecommendationType.Procedure;
    case 'Immunization':
      return RecommendationType.Immunization;
    case 'Injection':
      return RecommendationType.Injection;
    default:
      return RecommendationType.Lab;
  }
};

export const convertPatientProblemsToSelectData = (patientProblems: Problem[]): OptionItem[] => {
  return patientProblems.map((i) => ({
    id: i.assessmentItemId || -1,
    value: i.problemName || '',
  }));
};

export const convertOrderableItemsToAsyncDropdownOptions = (
  orderableItemsData?: OrderableItemLookupUI[],
): DropdownOption[] => {
  return (
    orderableItemsData?.map((i) => ({
      value: i.sourceId.toString(),
      label: i.name,
    })) || []
  );
};

export const convertImmunizationInjectionToAsyncDropdownOptions = (
  immunizationInjectionData?: ImmunizationInjectionLookup[],
): DropdownOption[] => {
  return (
    immunizationInjectionData?.map((i) => ({
      value: `immunization-injection:${i.immunizationItemId.toString()}`,
      label: i.name,
    })) || []
  );
};
