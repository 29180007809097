export function getImmunizationInjectionPatient() {
  return [
    {
      sourcePatientId: 402290,
      sourceEncounterId: 5489705,
      immunizationId: 521118,
      immunizationName: 'Dex Sod 4mg per unit',
      immunizationItemId: 307368,
      injectionLocation: 'right gluteus',
      dateTimeAdministered: '2020-12-16T22:55:00.000Z',
      administeredByUserId: 518608,
      administeredByUserName: 'Rutledge, Amanda',
      route: 'IM Intramuscular',
      dose: '8 mg',
      doseNumber: null,
      statusDescription: 'Administered',
      vaccinationGivenInThePast: true,
      sourceOfHistory: '',
      documentingFacilityId: 0,
      documentingFacilityName: '',
      patientImmunizationInjectionType: 'Theraputic Injection',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 5489705,
      immunizationId: 521116,
      immunizationName: 'Toradol 15mg per unit',
      immunizationItemId: 307374,
      injectionLocation: 'left gluteus',
      dateTimeAdministered: '2020-12-16T22:55:00.000Z',
      administeredByUserId: 518608,
      administeredByUserName: 'Rutledge, Amanda',
      route: 'IM Intramuscular',
      dose: '60 mg',
      doseNumber: null,
      statusDescription: 'Administered',
      vaccinationGivenInThePast: false,
      sourceOfHistory: '',
      documentingFacilityId: 0,
      documentingFacilityName: '',
      patientImmunizationInjectionType: 'Theraputic Injection',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 7250187,
      immunizationId: 710439,
      immunizationName: 'Fluarix (Quad) Prefill syringe',
      immunizationItemId: 285235,
      injectionLocation: '',
      dateTimeAdministered: '2022-11-28T16:27:00.000Z',
      administeredByUserId: 0,
      administeredByUserName: '',
      route: '',
      dose: '',
      doseNumber: null,
      statusDescription: 'Refused',
      vaccinationGivenInThePast: false,
      sourceOfHistory: '',
      documentingFacilityId: 0,
      documentingFacilityName: '',
      patientImmunizationInjectionType: 'Immunization',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 7250187,
      immunizationId: 710440,
      immunizationName: 'Shingrix',
      immunizationItemId: 529944,
      injectionLocation: '',
      dateTimeAdministered: '2022-11-28T16:27:00.000Z',
      administeredByUserId: 0,
      administeredByUserName: '',
      route: '',
      dose: '0.5 mL',
      doseNumber: null,
      statusDescription: 'Refused',
      vaccinationGivenInThePast: false,
      sourceOfHistory: '',
      documentingFacilityId: 0,
      documentingFacilityName: '',
      patientImmunizationInjectionType: 'Immunization',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 6172411,
      immunizationId: 585644,
      immunizationName: 'Fluarix (Quad) Prefill syringe',
      immunizationItemId: 285235,
      injectionLocation: '',
      dateTimeAdministered: '2021-10-04T13:56:00.000Z',
      administeredByUserId: 0,
      administeredByUserName: '',
      route: '',
      dose: '',
      doseNumber: null,
      statusDescription: 'Refused',
      vaccinationGivenInThePast: false,
      sourceOfHistory: '',
      documentingFacilityId: 0,
      documentingFacilityName: '',
      patientImmunizationInjectionType: 'Immunization',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 7725336,
      immunizationId: 797943,
      immunizationName: null,
      immunizationItemId: 285239,
      injectionLocation: null,
      dateTimeAdministered: null,
      administeredByUserId: null,
      administeredByUserName: null,
      route: null,
      dose: null,
      doseNumber: null,
      statusDescription: null,
      vaccinationGivenInThePast: null,
      sourceOfHistory: null,
      documentingFacilityId: null,
      documentingFacilityName: null,
      patientImmunizationInjectionType: 'IMMUNIZATION',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
    {
      sourcePatientId: 402290,
      sourceEncounterId: 7725336,
      immunizationId: 797944,
      immunizationName: null,
      immunizationItemId: 529944,
      injectionLocation: null,
      dateTimeAdministered: null,
      administeredByUserId: null,
      administeredByUserName: null,
      route: null,
      dose: null,
      doseNumber: null,
      statusDescription: null,
      vaccinationGivenInThePast: null,
      sourceOfHistory: null,
      documentingFacilityId: null,
      documentingFacilityName: null,
      patientImmunizationInjectionType: 'IMMUNIZATION',
      lotId: null,
      lotNumber: null,
      vfcCode: null,
      dateVISGiven: null,
      dateOnVIS: null,
    },
  ];
}
