import { ComboBox } from '../../../../../components';
import {
  encounterFilterActions,
  getEncounterFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../../hooks';
import { useMemo } from 'react';
import { useGetProvidersQuery } from '../../../../../__generated__/graphql';

export function Providers() {
  const { data, loading } = useGetProvidersQuery();

  const providers = data?.getProviders?.providers?.map((provider) => ({
    label: `${provider.providerName}`,
    value: `${provider.providerID}`,
  }));

  const filterState = useAppSelector(getEncounterFilterState);
  const dispatch = useDispatch();

  const { userId, user } = useAuth();

  const providersData = useMemo(
    () => [
      {
        label: `${user.firstName} ${user.lastName} - Me`,
        value: user ? `${user?.ecwId}` : '',
      },
      ...(providers ?? []),
    ],
    [providers, userId],
  );

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Providers'
      options={providersData}
      onSelection={(providers) => {
        const update = encounterFilterActions.onFilterStateChange({ providers });
        dispatch(update);
      }}
      defaultValue={filterState.providers}
      isMulti
    />
  );
}
