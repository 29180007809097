import { Select } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAdministerVaccineForm } from './useAdministerVaccineForm';

const vfcCodes = [
  {
    id: 1,
    vfcCode: 'V03',
    vfcDescription: 'VFC Eligible - Uninsured',
    vfcEligible: 1,
  },
  {
    id: 2,
    vfcCode: 'V04',
    vfcDescription: 'American Indian/Alaskan Native',
    vfcEligible: 1,
  },
  {
    id: 3,
    vfcCode: 'V06',
    vfcDescription: 'State Specific Eligibility',
    vfcEligible: 0,
  },
  {
    id: 4,
    vfcCode: 'V02',
    vfcDescription: 'VFC Eligible Medicaid / Medicaid Managed Care',
    vfcEligible: 1,
  },
  {
    id: 5,
    vfcCode: 'V05',
    vfcDescription: 'VFC Eligible - Under Insured',
    vfcEligible: 1,
  },
  {
    id: 6,
    vfcCode: 'V01',
    vfcDescription: 'Not VFC Eligible',
    vfcEligible: 2,
  },
  {
    id: 7,
    vfcCode: 'V00',
    vfcDescription: 'UNKNOWN',
    vfcEligible: 0,
  },
  {
    id: 8,
    vfcCode: 'V07',
    vfcDescription: 'Local Specific Eligibility',
    vfcEligible: 0,
  },
  {
    id: 9,
    vfcCode: 'V08',
    vfcDescription: 'Not Eligible - Under Insured',
    vfcEligible: 2,
  },
  {
    id: 14,
    vfcCode: 'V22',
    vfcDescription: 'CHIP',
    vfcEligible: 0,
  },
  {
    id: 15,
    vfcCode: 'V23',
    vfcDescription: '317',
    vfcEligible: 0,
  },
  {
    id: 16,
    vfcCode: 'V24',
    vfcDescription: 'Medicare',
    vfcEligible: 0,
  },
  {
    id: 17,
    vfcCode: 'V25',
    vfcDescription: 'State Program eligibility',
    vfcEligible: 0,
  },
] as const;

export function VFCSelect() {
  const { formValues, handleUpdateForm } = useAdministerVaccineForm();

  useEffect(() => {
    if (!formValues?.vfcCode) {
      handleUpdateForm({
        vfcCode: 'V01',
      });
    }
  }, [formValues?.vfcCode]);

  return (
    <Select
      placeholder='VFC*'
      value={formValues?.vfcCode ?? undefined}
      onChange={(e) => {
        handleUpdateForm({
          vfcCode: e.target.value,
        });
      }}>
      {vfcCodes.map((code) => {
        return (
          <option key={code.vfcCode} value={code.vfcCode}>
            {code.vfcDescription}
          </option>
        );
      })}
    </Select>
  );
}
