import { Box, Button, Flex, Text } from '../../../../../components';
import { OrderableItemTable } from '../OrderableItemTable';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useAppSelector } from '../../../../../state-management';
import { type GetProviderEncounterDetailsQuery } from '../../../../../__generated__/graphql';
import { Drawer, DrawerOverlay, useDisclosure } from '@chakra-ui/react';
import { ReportPanel } from './ReportPanel';
import { DocumentsPanel } from '../DocumentsPanel';

type Item = NonNullable<
  NonNullable<GetProviderEncounterDetailsQuery['getProviderEncounterDetails']>['items'][0]
>;
interface Props {
  orderableItem: Item;
  onOpen: () => void;
  setItem: (item: Item) => void;
}

export function ResultsContainer({ orderableItem, onOpen, setItem }: Props) {
  const {
    isOpen: documentIsOpen,
    onOpen: documentOnOpen,
    onClose: documentOnClose,
  } = useDisclosure();
  const { isOpen: reportIsOpen, onOpen: reportOnOpen, onClose: reportOnClose } = useDisclosure();
  const [expanded, setExpanded] = useState(true);
  const notesState = useAppSelector((state) => state.note);
  const addedNote =
    orderableItem.orderableReportID &&
    notesState.updatedItems[Number(orderableItem.orderableReportID)];
  const hasNote = !!orderableItem.notes || !!orderableItem.internalNotes;

  return (
    <Box>
      <Drawer isOpen={reportIsOpen} onClose={reportOnClose} size='full'>
        <DrawerOverlay />

        <ReportPanel xml={orderableItem.detailedReport ?? ''} />
      </Drawer>

      <Drawer isOpen={documentIsOpen} onClose={documentOnClose} size='full'>
        <DrawerOverlay />

        <DocumentsPanel orderableItem={orderableItem} />
      </Drawer>

      <Flex alignItems='center' justify='space-between' padding='16px 16px 0 16px'>
        <Button
          onClick={() => {
            setExpanded(!expanded);
          }}
          width='100%'
          variant='outline'
          border='none'
          justifyContent='start'
          textColor='black'
          mr={2}
          pl={0}>
          <Flex gap={4} alignItems='center'>
            <Text fontSize='lg' fontWeight='bold'>
              {orderableItem.orderableItemName}
            </Text>
            <ChevronDownIcon transition='.25s' transform={expanded ? '' : 'rotate(-90deg)'} />
            {orderableItem.reviewed && (
              <Flex alignItems='center' gap={2}>
                <CheckIcon color='green.400' />
                <Text fontSize='sm'>Reviewed</Text>
              </Flex>
            )}
          </Flex>
        </Button>
        <Flex gap={4}>
          {!!orderableItem?.documentIds?.length && (
            <Button variant={'outline'} onClick={documentOnOpen}>
              Scanned Doc.
            </Button>
          )}
          {orderableItem.detailedReport && (
            <Button
              variant='solid'
              onClick={() => {
                reportOnOpen();
              }}>
              Report
            </Button>
          )}

          <Button
            variant={hasNote || addedNote ? 'solid' : 'outline'}
            onClick={() => {
              setItem(orderableItem);
              onOpen();
            }}>
            {orderableItem.reviewed
              ? 'View Note'
              : hasNote || addedNote
              ? 'Edit Notes'
              : 'Add Notes'}
          </Button>
        </Flex>
      </Flex>
      <Box height={expanded ? 'auto' : 0} overflow='hidden'>
        <OrderableItemTable orderableItem={orderableItem} />
      </Box>
    </Box>
  );
}
