import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  type ImmunizationInjectionItem,
  useDeleteImmunizationInjectionMutation,
} from '../../../__generated__/graphql';
import { useCustomToast } from '../../../hooks';
import { Card } from '../../../components';

export function TreatmentPlanImmunizationOrderCard({
  order,
}: {
  order?: ImmunizationInjectionItem | null;
}) {
  if (!order) {
    return null;
  }

  return (
    <div>
      <Card>
        <Flex justifyContent='space-between' width='full' mb='md'>
          <Box>
            <Text variant='h6-b'>{order?.immunizationName}</Text>
          </Box>
        </Flex>
        <Flex gap='md' alignItems={'center'} justifyContent={'space-between'}>
          <Text>Diagnosis: Encounter for immunization</Text>
          <DeleteButton order={order} />
        </Flex>
      </Card>
    </div>
  );
}

function DeleteButton({ order }: { order?: ImmunizationInjectionItem | null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orderName = order?.immunizationName;
  const toast = useCustomToast();

  const [deleteImmunizationOrderableItem, { loading: isLoadingDeleteImmunizationOrderableItem }] =
    useDeleteImmunizationInjectionMutation();

  function handleDelete() {
    if (!order) {
      return;
    }

    deleteImmunizationOrderableItem({
      variables: { immunizationId: order.immunizationId ?? -1 }, // verify
      update: (cache) => {
        // Invalidate cache
        cache.evict({
          fieldName: 'getEncounterOrders',
        });
      },
      onCompleted: () => {
        toast({
          id: 'delete-immunization-order-error',
          title: 'Success',
          description: 'Item Deleted',
          status: 'success',
        });
        onClose();
      },
      onError: (error) => {
        toast({
          id: 'delete-immunization-order-success',
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
        });
      },
    });
  }

  return (
    <>
      <Button variant='outline' onClick={onOpen}>
        Delete
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Remove ${orderName} From The Treatment Plan?`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{`Are you sure you want to remove ${orderName} from the Treatment Plan?`}</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={'sm'} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete} isLoading={isLoadingDeleteImmunizationOrderableItem}>
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
