import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '../../../state-management';
import { worklistTodoActions } from '../../../state-management/worklistTodoSlice';
import { useModal } from '../../../hooks';
import { useEffect } from 'react';
import { useWorklistModal } from '../WorklistModal/useWorklistModal';
import { formatDate } from '../../../utils';
import { DatePicker } from '../../form';
import { useChecklistConstellationForm } from './useChecklistConstellationForm';
import { LAB_FORM_FIELDS, RESULT_OPTIONS } from './checklist-constellation-modal.data';
import { isEmpty } from 'lodash';

export function ChecklistConstellationModal({
  constellationItem: defaultConstellationItem,
}: {
  constellationItem: ConstellationWorklistItem;
}) {
  const { hasAtLeastOneConstellationItem } = useWorklistModal();
  const dynamicFormFields = LAB_FORM_FIELDS[defaultConstellationItem.orderableItem.labItemId] ?? [];

  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  // get form values from redux
  const constellationItems = useAppSelector((state) => state.worklistTodo.constellationItems);
  const constellationItemId = defaultConstellationItem.id;

  // TODO: only pass in ID as modal prop
  const currentConstellationItem: ConstellationWorklistItem = constellationItemId
    ? constellationItems[constellationItemId]
    : ({} as any);
  const { handleSubmitForm, submitState, formFields } = useChecklistConstellationForm({
    constellationItemId,
  });

  // prevent override of existing item in redux state
  const isConstellationStatePristine = isEmpty(
    currentConstellationItem?.orderableItemResultFormFields,
  );

  // Initialize form values
  useEffect(() => {
    if (isConstellationStatePristine) {
      handleUpdateForm({
        resultDate: new Date().toISOString(),
        collectedDate: new Date().toISOString(),
        received: true,
      });
      dispatch(
        worklistTodoActions.addOrderableItemResultFormFields(
          dynamicFormFields.map((labItem) => {
            return {
              attribute: labItem.itemName,
              attributeID: labItem.itemId,
              orderableReportID: defaultConstellationItem.id,
              itemId: labItem.itemId,
              value: '',
            };
          }),
        ),
      );
    }
  }, [isConstellationStatePristine]);

  // Go back to Worklist Modal when form is successfully submitted
  useEffect(() => {
    if (submitState.isSubmitSuccess) {
      toWorkListModal();
    }
  }, [submitState.isSubmitSuccess]);

  function toWorkListModal() {
    showModal({
      modalType: 'WorklistModal',
      chakraModalProps: { size: '6xl' },
    });
  }

  function handleUpdateForm(updatedField: Partial<ConstellationWorklistItem>) {
    dispatch(
      worklistTodoActions.setConstellationItem({
        ...currentConstellationItem,
        ...updatedField,
      }),
    );
  }

  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box h='70vh'>
          <Text variant='h6-b' mb='md'>
            {defaultConstellationItem.text}
          </Text>

          <Flex justifyContent='flex-end'>
            <Checkbox
              size='lg'
              isChecked={currentConstellationItem.received}
              onChange={(e) => {
                handleUpdateForm({
                  received: e.target.checked,
                });
              }}>
              Received
            </Checkbox>
          </Flex>

          <Flex gap='md'>
            <FormControl>
              <FormLabel>Result Date</FormLabel>
              <DatePicker
                value={
                  currentConstellationItem.resultDate
                    ? new Date(currentConstellationItem.resultDate)
                    : null
                }
                onChange={(e) => {
                  if (e) {
                    handleUpdateForm({ resultDate: e.toISOString() });
                  }
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Result</FormLabel>
              <Select
                value={currentConstellationItem.result}
                onChange={(e) => {
                  handleUpdateForm({ result: e.target.value });
                }}>
                {RESULT_OPTIONS.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Flex>

          <Box my='md'>
            <Text fontWeight={'bold'}>
              Ordered {formatDate(defaultConstellationItem.orderableItem.orderDate)}
            </Text>
          </Box>

          {hasAtLeastOneConstellationItem ? (
            <VStack align={'stretch'} spacing='lg'>
              <VStack spacing='sm' align={'stretch'}>
                <SimpleGrid columns={formFields.length < 3 ? formFields.length : 3} spacing={'md'}>
                  {formFields.map((field) => {
                    return (
                      <FormControl key={field.attributeID}>
                        <FormLabel>{field.attribute}</FormLabel>
                        <Input
                          key={field.attributeID}
                          value={field.value ?? ''}
                          onChange={(e) => {
                            dispatch(
                              worklistTodoActions.onOrderableItemResultFormFieldChange({
                                ...field,
                                value: e.target.value,
                              }),
                            );
                          }}
                        />
                      </FormControl>
                    );
                  })}
                </SimpleGrid>
              </VStack>
            </VStack>
          ) : (
            <Text>No data</Text>
          )}
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={toWorkListModal}>
          Back
        </Button>
        <Button isLoading={submitState.isLoading} onClick={handleSubmitForm}>
          Done
        </Button>
      </ModalFooter>
    </>
  );
}
