import { type HpiStructuredData } from '../../../../../__generated__/graphql';
import { HpiCalculator } from './base';

export class Phq9ScoreCalc extends HpiCalculator {
  componentFields = this.range(2886, 2894);
  computedField = 2895;

  formula = (responses: HpiStructuredData[]) => {
    const answers = responses.filter((r) =>
      this.componentFields.includes(r.hpiStructuredDataDetailId!),
    );

    const hasEmpty = answers.some((a) => !a.value);
    if (hasEmpty) return;

    const answersAndPrompts = answers.map((answer) => {
      const prompt = this.smartForms.find(
        (f) => f.structureDataDetailID === answer.hpiStructuredDataDetailId,
      );
      return {
        ...prompt,
        ...answer,
      };
    });

    return answersAndPrompts.reduce((acc, a) => {
      const idxOfSelection = a.smartFormSurveyStructureOptions?.find(
        (o) => `${o.structDataOptionID}` === a.value,
      )?.displayIndex;

      // handle the index offset
      // displayIndex is base 1 but we want to score base 0
      let score = idxOfSelection ?? 1;
      score -= 1;

      return acc + score;
    }, 0);
  };
}
