export function getPatientPharmacies() {
  return {
    getPatientPharmacies: [
      {
        pharmacyId: 13,
        pharmacyName: 'CVS Pharmacy #8934',
        pharmacyAddress: '1870 HWY 81 EAST',
        pharmacyAddress2: '',
        pharmacyCity: 'MCDONOUGH',
        pharmacyState: 'GA',
        pharmacyZip: '30252',
        pharmacyPhoneNumber: '678-583-0097',
        isPrimary: false,
      },
      {
        pharmacyId: 3791,
        pharmacyName: 'Walgreens Drug Store',
        pharmacyAddress: '101 PROMINENCE POINT PKWY',
        pharmacyAddress2: '',
        pharmacyCity: 'CANTON',
        pharmacyState: 'GA',
        pharmacyZip: '30114',
        pharmacyPhoneNumber: '770-704-4045',
        isPrimary: true,
      },
    ],
  };
}
