import { Box, Heading } from '@chakra-ui/react';
import { Text } from '../../typography';
import { Button, HStack, Textarea } from '../../index';
import React from 'react';
import { Icon } from '../../general';
import { BsCheck } from 'react-icons/bs';
import { type ConstellationRecommendationUI } from '../../../types';

interface ConstellationRecommendationRowProps {
  rec: ConstellationRecommendationUI;
  orderChangeHandler: (order: ConstellationRecommendationUI) => void;
}
export const ConstellationRecommendationRow = ({
  rec,
  orderChangeHandler,
}: ConstellationRecommendationRowProps): JSX.Element => {
  return (
    <Box key={rec.id}>
      <Box mb='md'>
        <Heading fontSize='xl' mb='xs'>
          Administer {rec.name}
        </Heading>
        <Text>
          Based on this patients current info you will need to administer a{' '}
          {rec.name?.toLocaleLowerCase()}.
        </Text>
        <HStack my='md' mx={0}>
          <Button
            variant={rec.shouldDecline ? 'solid' : 'outline'}
            rightIcon={rec.shouldDecline ? <Icon boxSize={8} as={BsCheck} /> : undefined}
            onClick={() => {
              if (!rec.shouldDecline) {
                orderChangeHandler({ ...rec, shouldDecline: true });
              }
            }}
          >
            Don't Order
          </Button>
          <Button
            onClick={() => {
              if (rec.shouldDecline) {
                orderChangeHandler({ ...rec, shouldDecline: false });
              }
            }}
            variant={!rec.shouldDecline ? 'solid' : 'outline'}
            rightIcon={!rec.shouldDecline ? <Icon boxSize={8} as={BsCheck} /> : undefined}
          >
            Acknowledge & Order
          </Button>
        </HStack>
        {rec.shouldDecline && (
          <Textarea
            value={rec.declineReason}
            onChange={(e) => {
              if (rec.shouldDecline) {
                orderChangeHandler({ ...rec, declineReason: e.target.value });
              }
            }}
            rows={4}
            resize='none'
            placeholder='Please enter a reason for not ordering'
          />
        )}
      </Box>
    </Box>
  );
};
