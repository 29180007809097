import React, { useEffect } from 'react';
import { useAuth } from '../../hooks';
import { Button, useClipboard } from '@chakra-ui/react';

export function CopyCommandForRemoteCodeGen() {
  const {
    user: { authToken },
  } = useAuth();
  const { onCopy, setValue, hasCopied } = useClipboard('');
  const commandForRemoteCodegen = `ENSOMATA_API_AUTH_TOKEN=${authToken} npm run codegen:remote`;

  // copy commandForRemoteCodegen to clipboard
  useEffect(() => {
    if (authToken) {
      setValue(commandForRemoteCodegen);
      //   onCopy();
    }
  }, [authToken, onCopy, setValue]);

  return (
    <Button
      w='full'
      onClick={onCopy}
      variant={hasCopied ? 'outline' : 'solid'}
      disabled={!authToken}>
      {hasCopied ? 'Copied!' : 'Copy command for remote codegen'}
    </Button>
  );
}
