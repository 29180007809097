import { MedicationRow } from './MedicationRow';
import { Box, Flex, Text } from '../../../components';
import React from 'react';
import { MedicationStatusMarkAll } from './MedicationStatusMarkAll';
import { useUpdateMedItems } from './hooks/useUpdateMedItems';

interface Props {
  title: MedStatus;
  items: MedicationItemUI[];
}

export const MedicationGroup = ({ title, items }: Props) => {
  const { handleUpdateAllMedicationStatus } = useUpdateMedItems();
  return (
    <Box width='100%' mb='lg'>
      <Flex alignItems='center' justifyContent='space-between' marginBottom='sm'>
        <Text fontSize='2xl' as='b'>
          {title}
        </Text>
        <Flex alignItems='center' mr='lg'>
          <Text fontSize='lg' marginRight='md'>
            Mark All:
          </Text>
          <MedicationStatusMarkAll
            value={title}
            onChange={(val, groupLabel) => {
              handleUpdateAllMedicationStatus(items, val as MedStatus);
            }}
          />
        </Flex>
      </Flex>
      {items.length ? (
        items.map((item) => {
          return <MedicationRow key={item.id} item={item} />;
        })
      ) : (
        <Text textAlign='center'>None</Text>
      )}
    </Box>
  );
};
