import { type EncounterVital, VitalType } from '../../../__generated__/graphql';
import { getValidationDataMap } from './vitals.validation.data';
import { VitalValidationResult } from './VitalValidationResult';
import { isNil } from 'lodash';
import { type VitalsValidationDataMap, type VitalValidationMap } from './vitals.validation.types';
import { VitalValidation } from './VitalValidation';

export const createEmptyVitalValidationMap = (): VitalValidationMap => {
  return Object.values(VitalType).reduce<VitalValidationMap>((accum, type) => {
    accum[type] = new VitalValidation();
    return accum;
  }, {});
};

export const getVitalValidation = (
  vitals: EncounterVital[],
  age: number,
): VitalValidationMap | undefined => {
  const validationData = getVitalsValidationByAge(age);
  if (!validationData) {
    return undefined;
  }
  return vitals.reduce<VitalValidationMap>((accum, vital) => {
    const validationResult = validateVital(vital, age, validationData);
    if (validationResult) {
      accum[vital.type].addVitalValidationResult(validationResult);
    }
    return accum;
  }, createEmptyVitalValidationMap());
};

export const validateVital = (
  vital: EncounterVital,
  age: number,
  dataMap?: VitalsValidationDataMap,
): VitalValidationResult | undefined => {
  let validationData = dataMap;
  if (!validationData) {
    validationData = getVitalsValidationByAge(age);
  }
  if (!validationData) {
    return undefined;
  }
  const validationSet = validationData?.vitalItems[vital.type];
  if (validationSet) {
    const parts = vital.value.split('/').map((val) => parseInt(val));
    let isAnyBelowWarning;
    let isAnyBelowError;
    let isAnyAboveWarning;
    let isAnyAboveError;
    if (parts.length > 1) {
      isAnyBelowWarning = parts.some((val, idx) => {
        return !isNil(validationSet.low) && val <= validationSet.low[idx];
      });
      isAnyBelowError = parts.some((val, idx) => {
        return !isNil(validationSet.invalidLow) && val <= validationSet.invalidLow[idx];
      });
      isAnyAboveWarning = parts.some((val, idx) => {
        return !isNil(validationSet.high) && val >= validationSet.high[idx];
      });
      isAnyAboveError = parts.some((val, idx) => {
        return !isNil(validationSet.invalidHigh) && val >= validationSet.invalidHigh[idx];
      });
    } else {
      isAnyBelowWarning = parts.some((val) => {
        return validationSet.low?.some((low) => {
          return val <= low;
        });
      });
      isAnyBelowError = parts.some((val) => {
        return validationSet.invalidLow?.some((low) => {
          return val <= low;
        });
      });
      isAnyAboveWarning = parts.some((val) => {
        return validationSet.high?.some((high) => {
          return val >= high;
        });
      });
      isAnyAboveError = parts.some((val) => {
        return validationSet.invalidHigh?.some((high) => {
          return val >= high;
        });
      });
    }
    let result;
    if (isAnyAboveError) {
      result = new VitalValidationResult(
        vital.type,
        false,
        vital.value,
        null,
        null,
        validationSet.invalidHigh,
      );
    } else if (isAnyBelowError) {
      result = new VitalValidationResult(
        vital.type,
        false,
        vital.value,
        null,
        null,
        null,
        validationSet.invalidLow,
      );
    } else if (isAnyAboveWarning) {
      result = new VitalValidationResult(vital.type, true, vital.value, validationSet.high);
    } else if (isAnyBelowWarning) {
      result = new VitalValidationResult(vital.type, true, vital.value, null, validationSet.low);
    }
    return result;
  }
};

const getVitalsValidationByAge = (age: number): VitalsValidationDataMap | undefined => {
  const dataMap = getValidationDataMap();
  if (!dataMap) return;
  for (let i = 0; i < dataMap.length; i++) {
    const valSet = dataMap[i];
    if (age >= valSet.ageStart && age <= valSet.ageEnd) {
      return valSet;
    }
  }
};
