import { HStack } from '../../layout';
import { Text } from '../../typography';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  // PopoverCloseButton,
  PopoverBody,
  Icon,
} from '../../general';
import { IconButton } from '../../form';
import { formatDate } from '../../../utils';
import { type PatientAlert } from '../../../types';
import { BsInfoCircleFill as InfoCircleIcon } from 'react-icons/bs';
interface OrderInfoProps {
  alert: PatientAlert;
}
// TODO: Type props
export function RecommendationIcon({ alert }: OrderInfoProps) {
  return (
    <HStack w='full'>
      <Text>{alert?.shortDescription}</Text>
      {alert.longDescription && (
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label='Info'
              size='sm'
              colorScheme='transparent'
              icon={<Icon boxSize={5} color='secondary' as={InfoCircleIcon} />}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>{alert?.longDescription}</PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      {alert?.lastOrdered && (
        <Text>
          Last {alert?.shortDescription}: {formatDate(alert?.lastOrdered)}
        </Text>
      )}
    </HStack>
  );
}
