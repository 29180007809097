import { useGetKeywordsQuery } from '../../../../__generated__/graphql';
import { AutocompleteInput } from '../../../../components';

interface Props {
  initialValue: string;
  onChange: (change: string) => void;
}

export function ReasonInput({ initialValue, onChange }: Props) {
  const { data } = useGetKeywordsQuery({
    variables: {
      keynameCategory: 'TelReason',
    },
  });

  const options = data?.getKeywords?.keywords?.map((keyword) => keyword.name);

  return (
    <AutocompleteInput
      label='Reason'
      options={options ?? []}
      initialValue={initialValue}
      onChange={(name) => {
        const details = data?.getKeywords?.keywords?.find((val) => val.name === name)?.details;
        onChange(details?.trim() || name?.trim());
      }}
    />
  );
}
