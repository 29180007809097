import {
  openTEsFilterActions,
  getOpenTEsFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useGetTelephoneEncounterCallersQuery } from '../../../../../__generated__/graphql';
import { ComboBox } from '../../../../../components';

export function CallerName() {
  const [search, setSearch] = useState('');
  const filterState = useAppSelector(getOpenTEsFilterState);
  const { data, loading } = useGetTelephoneEncounterCallersQuery({
    variables: {
      contains: search,
    },
  });

  const callerNames = data?.getTelephoneEncounterCallers || [];
  const callers = callerNames.map((name) => ({ label: name, value: name }));

  const dispatch = useDispatch();

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Callers'
      options={callers}
      onInputChange={setSearch}
      isMulti
      onSelection={(callers) => {
        const update = openTEsFilterActions.onFilterStateChange({ callers });
        dispatch(update);
      }}
      defaultValue={filterState.callers}
    />
  );
}
