import { Box, Flex, Text } from '../../components';
import { IS_PROD, USE_LOCAL_MOCK, getAppMode, getAppVersion } from '../../constants';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Switch } from '@chakra-ui/react';
import { IoIosOptions } from 'react-icons/io';
import { CopyCommandForRemoteCodeGen } from './CopyCommandForRemoteCodeGen';

// Turn on MSW for mocking requests
if (USE_LOCAL_MOCK) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('../../mocks/browser');
  worker.start();
}

function DevTools() {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<IoIosOptions />}
          variant='outline'
        />
        <MenuList>
          <Text textAlign='center' fontWeight='bold'>
            Ensomata Dev Tools
          </Text>
          <MenuItem>
            <Switch
              isChecked={USE_LOCAL_MOCK}
              onChange={() => {
                if (USE_LOCAL_MOCK) {
                  localStorage.removeItem('USE_LOCAL_MOCK');
                } else {
                  localStorage.setItem('USE_LOCAL_MOCK', 'yes');
                }
                window.location.reload();
              }}>
              Use Local Mock
            </Switch>
          </MenuItem>
          <Box p={4} pb={0}>
            <CopyCommandForRemoteCodeGen />
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
}

export function DevToolsAndAppVersion() {
  const versionAndAppMode = `v${getAppVersion()} ${getAppMode()}`;
  const renderHiddenAppInfo = <input value={`App Info: ${versionAndAppMode}`} type='hidden' />;

  return IS_PROD ? (
    renderHiddenAppInfo
  ) : (
    <Flex position='fixed' bottom='7%' right='0' bg='blue.100' alignItems='center' rounded='md'>
      <Box mr='sm'>
        <DevTools />
      </Box>

      {renderHiddenAppInfo}
      <Text textAlign='center' variant='body2-b' px={1}>
        {versionAndAppMode}
      </Text>
    </Flex>
  );
}
