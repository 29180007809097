import { Stack, Text, VStack, useTheme } from '@chakra-ui/react';
import { useGetAoeQuestionsAndOptionsQuery } from '../../../../__generated__/graphql';
import { useLabCollectionForm } from '../useLabCollectionForm';
import { Spinner } from '../../../data-display';
import { useAOEQuestionsAndLabInstructions } from '../useAOEQuestionsAndLabInstructions';

export function LabInstructionsTabContent() {
  const { formValues } = useLabCollectionForm();
  const { hasLabInstructions } = useAOEQuestionsAndLabInstructions();

  if (!hasLabInstructions) {
    return (
      <Text textAlign={'center'} mt='lg'>
        No lab instructions.
      </Text>
    );
  }

  return (
    <Stack spacing={'lg'} mt='lg'>
      {formValues?.map((lab) => {
        const id = lab.orderableReportID;
        const labItemId = lab.orderableItemId; // lab.labItemId;
        const name = lab.orderableItemName;

        return labItemId ? <AOELabItem key={id} labItemId={labItemId} name={name} /> : null;
      })}
    </Stack>
  );
}

function AOELabItem({ labItemId, name }: { labItemId: number; name?: string | null }) {
  const { globalLabForm } = useLabCollectionForm();
  const { sendToLabCompanyId } = globalLabForm;
  const { data, loading } = useGetAoeQuestionsAndOptionsQuery({
    variables: {
      labIds: [sendToLabCompanyId ?? 0], // Orchard. The only one we are using for now.
      labItemId,
    },
    skip: !sendToLabCompanyId,
  });
  const theme = useTheme();
  const aoeLabs = data?.getAOEQuestionsAndOptions?.labs || [];
  const labInstructions = aoeLabs?.[0]?.labInstructions || '';

  if (loading) {
    return <Spinner />;
  }

  if (!aoeLabs.length) {
    return null;
  }

  return (
    <VStack align={'flex-start'} spacing='md'>
      <Text size='md' fontWeight={'bold'}>
        {name}
      </Text>
      <Text as={'pre'} sx={{ whiteSpace: 'pre-wrap', fontFamily: theme.fonts.body }}>
        {labInstructions}
      </Text>
    </VStack>
  );
}
