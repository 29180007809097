export const groupMap: Record<number, string> = {
  1: 'Lab Documents',
  2: 'Chart Documents',
  3: 'Imaging/X-ray Documents',
  4: 'Patient Documents',
  5: 'Specialty Consult Notes',
  7: 'Referral Notes',
  8: 'Examination Drawing',
  9: 'EOB Documents',
  11: 'Physical Exam Drawing',
  12: 'Procedures Drawing',
  15: 'AdvDirective Documents',
  21: 'Immunization Forms',
  23: 'Speciality Forms',
  84: 'Case Manager',
  86: 'Dermatology Drawing',
  100: 'Procedure Documents',
  101: 'eCW P2P Documents',
  102: 'Misc.',
  118: 'Home Health Care',
  119: 'Sleep Study Documents',
  120: 'DME',
  122: 'Workers Comp',
  123: 'Medical Records',
  125: 'Old Chart History/Progress',
  129: 'Requests',
  130: 'Care Improvement Plus',
  131: 'Prolia',
  132: 'Hospital Notes',
  136: 'Letters',
  137: 'Nurse Calls',
  139: 'Past Medical Records/ Outside Records',
  140: 'Prolia Documents',
  141: 'Medication Authorizations',
  143: 'PortalBox',
  144: 'Endocrinology',
  145: 'Glucose Readings',
  147: 'Bankruptcy Documents',
  148: 'Vision Drawing',
  149: 'Vision Category Inking',
  150: 'Vascular Documents',
  151: 'Fax Inbox',
  152: 'EOB',
  153: 'EOB',
  155: 'Notifications',
  156: 'ICD Groups',
  158: 'Requested Questionaries',
  162: 'Dispute Letter',
};
