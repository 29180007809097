import { Spinner as ChakraSpinner, type SpinnerProps } from '@chakra-ui/react';
import { Center } from '../../layout';

export function Spinner(props: SpinnerProps) {
  return (
    <Center height='100%'>
      <ChakraSpinner color='primary' {...props} />
    </Center>
  );
}
