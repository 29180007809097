import { useMemo } from 'react';
import { ComboBox } from '../../../../components';
import { useGetProvidersQuery } from '../../../../__generated__/graphql';
import { useAuth } from '../../../../hooks';

interface Props {
  selectionCallback: (providerId: any) => void;
}

export function LabProviders({ selectionCallback }: Props) {
  const { data, loading } = useGetProvidersQuery();
  const providers = data?.getProviders?.providers?.map((l) => ({
    label: `${l.providerName}`,
    value: `${l.providerID}`,
  }));

  const { userId, user } = useAuth();

  const providersData = useMemo(
    () => [
      {
        label: `${user.firstName} ${user.lastName} - Me`,
        value: user?.ecwId ? `${user.ecwId}` : '',
      },
      ...(providers ?? []),
    ],
    [providers, user, userId],
  );

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Provider'
      options={providersData}
      onSelection={(selection) => {
        selectionCallback(selection?.value);
      }}
    />
  );
}
