import React, { type PropsWithChildren } from 'react';
import { ErrorMessage, Spinner } from '../data-display';
import { type SpinnerProps } from '@chakra-ui/react';
interface WaitUntilLoadedProps {
  loading: boolean;
  showErrorMessage?: boolean;
  errorMessage?: string;
  spinnerProps?: SpinnerProps;
}

export const WaitUntilLoaded: React.FC<PropsWithChildren<WaitUntilLoadedProps>> = ({
  loading,
  showErrorMessage,
  errorMessage,
  children,
  spinnerProps,
}) => {
  if (loading) {
    return <Spinner {...spinnerProps} />;
  }

  if (showErrorMessage) {
    return <ErrorMessage text={errorMessage || 'Error loading'} />;
  }
  return <>{children}</>;
};
