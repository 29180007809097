export function getAssessments() {
  return {
    assessments: [
      {
        assessmentItemId: 401033,
        sourceEncounterId: 7872574,
        assessmentName: 'Personal history of nicotine dependence',
        assessmentCode: 'Z87.891',
        displayIndex: '1',
      },
      {
        assessmentItemId: 469872,
        sourceEncounterId: 7872574,
        assessmentName: 'Encounter for general adult medical examination without abnormal findings',
        assessmentCode: 'Z00.00',
        displayIndex: '1',
      },
      {
        assessmentItemId: 470074,
        sourceEncounterId: 7872574,
        assessmentName: 'Encounter for immunization',
        assessmentCode: 'Z23',
        displayIndex: '1',
      },
      {
        assessmentItemId: 470871,
        sourceEncounterId: 7872574,
        assessmentName: 'Acquired absence of bilateral breast and nipple',
        assessmentCode: 'Z90.13',
        displayIndex: '1',
      },
      {
        assessmentItemId: 472169,
        sourceEncounterId: 7872574,
        assessmentName: 'Screening for thyroid disorder',
        assessmentCode: 'Z13.29',
        displayIndex: '1',
      },
      {
        assessmentItemId: 472174,
        sourceEncounterId: 7872574,
        assessmentName: 'Screening for lipoid disorders',
        assessmentCode: 'Z13.220',
        displayIndex: '1',
      },
      {
        assessmentItemId: 472175,
        sourceEncounterId: 7872574,
        assessmentName: 'Special screening, prostate cancer',
        assessmentCode: 'Z12.5',
        displayIndex: '1',
      },
      {
        assessmentItemId: 472176,
        sourceEncounterId: 7872574,
        assessmentName: 'Encounter for colorectal cancer screening',
        assessmentCode: 'Z12.11',
        displayIndex: '1',
      },
    ],
  };
}
