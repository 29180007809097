import { type ReactNode } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { type BoxProps, Flex, Icon } from '../../components';

import { BsExclamationCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';

interface SimpleNavigatorItemProps extends Omit<BoxProps, 'children'> {
  children: ReactNode;
  active?: boolean;
  completed?: boolean;
  dirty?: boolean;
}

export function SimpleNavigatorItem({
  active,
  completed,
  onClick,
  children,
  dirty,
}: SimpleNavigatorItemProps) {
  return (
    <Tooltip
      shouldWrapChildren
      isDisabled={completed || !dirty}
      label='Unsaved / Unverified changes'
    >
      <Flex
        cursor='pointer'
        position='relative'
        fontSize={typeof children === 'string' ? '0.7rem' : '1.5rem'}
        borderWidth={active ? '3px' : '1px'}
        borderColor={
          dirty && !completed ? 'yellow.400' : completed || active ? 'brand.500' : undefined
        }
        aspectRatio={1}
        maxWidth={14}
        width='5.5vw'
        borderRadius='100%'
        alignItems='center'
        justifyContent='center'
        sx={{
          transform: active ? 'scale(1.05)' : 'none',
          transition: 'all 0.1s',
        }}
        onClick={onClick}
        userSelect='none'
      >
        {children}

        {completed && (
          <Flex position='absolute' bottom='-20%' bgColor='white' p={0.5}>
            <Icon boxSize={4} color='success' as={BsFillCheckCircleFill} />
          </Flex>
        )}
        {dirty && !completed && (
          <Flex position='absolute' bottom='-20%' bgColor='white' p={0.5}>
            <Icon boxSize={4} color='yellow.400' as={BsExclamationCircleFill} />
          </Flex>
        )}
      </Flex>
    </Tooltip>
  );
}
