import { type ButtonProps, Button, HStack } from '@chakra-ui/react';
import { WorklistButton } from './WorklistButton';
import { useMAWorkflow } from '../../../hooks';

export function SaveAndWorklistButtons(props: ButtonProps) {
  const { isMAWorkflowEnabled } = useMAWorkflow();
  return (
    <HStack
      position='fixed'
      bottom='8px'
      w='90%'
      left='5%'
      height='40px'
      backgroundColor='white'
      borderRadius='8px'>
      <Button w='full' {...props} size='md'>
        {props.children}
      </Button>
      {isMAWorkflowEnabled && <WorklistButton />}
    </HStack>
  );
}
