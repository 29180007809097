export const immunizationInjectionLotDetails = {
  lotDetails: [
    {
      dosage: '0.5 mL',
      dosesRemaining: 10,
      expirydate: '2023-04-29T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12035,
      itemId: 138763,
      itemName: 'DTaP younger than 7 years',
      lotNumber: '7EC55',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 10,
    },
    {
      dosage: '0.5 mL',
      dosesRemaining: 4,
      expirydate: '2023-02-07T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 11864,
      itemId: 138763,
      itemName: 'DTaP younger than 7 years',
      lotNumber: '4L9E4',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 10,
    },
    {
      dosage: '1mL',
      dosesRemaining: 8,
      expirydate: '2022-03-28T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12040,
      itemId: 138766,
      itemName: 'Hepatitis A (adult)',
      lotNumber: 'T034003',
      manufacturer: 'Merck &Co.',
      numberOfDoses: 10,
    },
    {
      dosage: '',
      dosesRemaining: 9,
      expirydate: '2022-10-22T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12550,
      itemId: 138766,
      itemName: 'Hepatitis A (adult)',
      lotNumber: 'U024378',
      manufacturer: 'Merck &Co.',
      numberOfDoses: 10,
    },
    {
      dosage: '1 mL',
      dosesRemaining: 1,
      expirydate: '2022-12-05T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 11255,
      itemId: 138768,
      itemName: 'Hepatitis B ADULT (20& Older)',
      lotNumber: '2793Z',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 10,
    },
    {
      dosage: '1 mL',
      dosesRemaining: 10,
      expirydate: '2023-04-29T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12197,
      itemId: 138768,
      itemName: 'Hepatitis B ADULT (20& Older)',
      lotNumber: '7XY23',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 10,
    },
    {
      dosage: '0.5 ML',
      dosesRemaining: 7,
      expirydate: '2023-01-02T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12037,
      itemId: 138768,
      itemName: 'Hepatitis B ADULT (20& Older)',
      lotNumber: 'N4GA4',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 10,
    },
    {
      dosage: '0.5mL',
      dosesRemaining: 14,
      expirydate: '2022-10-31T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12675,
      itemId: 138777,
      itemName: 'Meningococcal',
      lotNumber: 'AMVA689A',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 15,
    },
    {
      dosage: '0.5mL',
      dosesRemaining: 2,
      expirydate: '2022-11-30T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 12546,
      itemId: 138777,
      itemName: 'Meningococcal',
      lotNumber: 'AMVA642A',
      manufacturer: 'GlaxoSmithKline',
      numberOfDoses: 20,
    },
    {
      dosage: '0.5 mL',
      dosesRemaining: 10,
      expirydate: '2022-01-12T00:00:00.000+00:00',
      facilityId: 10,
      governmentSupplied: 'No',
      id: 11408,
      itemId: 138778,
      itemName: 'MMR',
      lotNumber: 'T001214',
      manufacturer: 'Merck &Co.',
      numberOfDoses: 20,
    },
  ],
};
