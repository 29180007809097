import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ComboBoxOption } from '../types';

export interface EncounterFilterState {
  procedureQuery: string;
  procedures: ComboBoxOption[];
  locations: ComboBoxOption[];
  providers: ComboBoxOption[];
  assignees: undefined | ComboBoxOption[];
  onlyDelinquent: boolean;
}

const initialState: EncounterFilterState = {
  procedureQuery: '',
  procedures: [],
  locations: [],
  providers: [],
  // set this to undfined initially so we can check if
  // if we need to apply the default of "user's id"
  assignees: undefined,
  onlyDelinquent: false,
};

export const encounterFilterSlice = createSlice({
  name: 'encounterFilter',
  initialState,
  reducers: {
    onFilterStateChange(state, action: PayloadAction<Partial<EncounterFilterState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const encounterFilterActions = encounterFilterSlice.actions;

export default encounterFilterSlice.reducer;
