import { ModalHeader, ModalBody, ModalFooter, ModalCloseButton } from '../../general';
import { cloneDeep, isEmpty, isNil, isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Radio,
  Text,
  VStack,
  Button,
  RadioGroup,
  Textarea,
  Input,
  DatePicker,
  Box,
} from '../../../components';
import { uiActions, useAppDispatch } from '../../../state-management';
import { type g } from '../../../api';
import { usePatient, useSocialHistory } from '../../../hooks';
import { DateTime } from 'luxon';
import { Gender } from '../../../__generated__/graphql';

// Global state
let visibleFormFields: Record<
  NonNullable<StructuredDataItem['shStructuredDataID']>,
  StructuredDataItem
> = {};

type FormStateItem = Omit<g.UpdateSocialHistoryItem, '__typename'>;
type FormState = Record<
  number, // g.SocialHistoryItem["observationId"]
  FormStateItem
>;

// CONSTANTS
const POINTS_VALUES: Record<string, number> = {
  // "How often did you have 6 or more drinks on one occasion in the past year?",
  'Never (0 point)': 0,
  'Less than monthly (1 point)': 1,
  'Monthly (2 points)': 2,
  'Weekly (3 points)': 3,
  'Daily or almost daily (4 points)': 4,
  'Declined to specify (0 points)': 0,
  // "How many drinks did you have on a typical day when you were drinking in the past year?",
  '1 or 2 drinks (0 point)': 0,
  '3 or 4 drinks (1 point)': 1,
  '5 or 6 drinks (2 points)': 2,
  '7 to 9 drinks (3 points)': 3,
  '10 or more drinks (4 points)': 4,
  // "How often did you have a drink containing alcohol in the past year?",
  'Monthly or less (1 point)': 1,
  '2 to 4 times a month (2 points)': 2,
  '2 to 3 times a week (3 points)': 3,
  '4 or more times a week (4 points)': 4,
};
const STRUCTURED_DATA_IDS_FOR_POINTS = [2926, 2927, 2928];
const SOCIAL_INFO_ID_FOR_POINTS = 272677;
const SOCIAL_INFO_ID_FOR_PACK_YEARS = 491310;

export function SocialHistoryEditModal({ onCloseModal, socialInfoId }: SpecificModalProps) {
  const dispatch = useAppDispatch();

  const { currentCategoryId, currentSocialHistoryCategory, userSocialHistoryList } =
    useSocialHistory();
  const {
    patient: { gender },
  } = usePatient();

  const [formState, setFormState] = useState<FormState>({});

  /**
   * Due to the recursive nature of the forms, when "No" is selected on a question/inquiry which is a top parent node,
   * the recursive children disappear from the UI, as they are no longer relevant. However, the children response values
   * are still in the React form state. This useEffect is to clean all the children responses from the form state whenever the
   * top parent is in a "No" state, or not expanded.
   */
  useEffect(() => {
    if (hasSocialInfo) {
      const newFormState = removeNonVisibleItemsFromFormState(formState); // compute packs with new form state before saving
      const pointsSH = formState[SOCIAL_INFO_ID_FOR_POINTS];

      if (pointsSH) {
        const newPointsSH = newFormState[SOCIAL_INFO_ID_FOR_POINTS];
        const isAnyPointsFieldVisible = newPointsSH?.encounterSocialHistoryStructuredDataList?.some(
          (sd) => {
            if (sd.socialHistoryStructuredDataDetailId) {
              return STRUCTURED_DATA_IDS_FOR_POINTS.includes(
                sd.socialHistoryStructuredDataDetailId,
              );
            }
            return false;
          },
        );

        // If there are no fields visible "points" fields, e.g. "3 or 4 drinks (1 point)", then make Points total 0
        if (!isAnyPointsFieldVisible && newPointsSH) {
          newPointsSH.encounterSocialHistoryStructuredDataList = updatePointsValue(
            '0',
            newPointsSH.encounterSocialHistoryStructuredDataList,
            gender,
          );
        }
      }

      setFormState(newFormState);
    }
  }, [JSON.stringify(formState)]);

  const [socialHistoryWithoutSocialInfoState, setSocialHistoryWithoutSocialInfoState] = useState<
    Partial<SocialHistory>
  >({});

  // Get social history items for the current category
  const socialHistoryItemsForCurrentCategory = userSocialHistoryList.filter(
    (x) => x.categoryId === currentCategoryId,
  );

  // Init default form values: TODO: Calculate once
  const defaultFormValues = socialHistoryItemsForCurrentCategory?.reduce<FormState>((acc, x) => {
    const id = x?.observationId; // socialInfoID
    if (id) {
      acc[id] = x;
    }
    return acc;
  }, {});

  const defaultSocialHistoryWithoutSocialInfoState = socialHistoryItemsForCurrentCategory[0]
    ? socialHistoryItemsForCurrentCategory[0]
    : {
        categoryId: currentCategoryId,
        encounterSocialHistoryStructuredDataList: [],
      };

  // SET INITIAL FORM VALUES
  // Set the defaultFormValues when it is available. TODO: use loader till then.
  useEffect(() => {
    if (defaultFormValues) {
      setFormState(defaultFormValues);
    }
  }, [JSON.stringify(defaultFormValues)]); // TODO: Calculate once

  // Set the default when it is available. TODO: use loader till then.
  useEffect(() => {
    if (defaultSocialHistoryWithoutSocialInfoState) {
      setSocialHistoryWithoutSocialInfoState(defaultSocialHistoryWithoutSocialInfoState);
    }
  }, [JSON.stringify(defaultSocialHistoryWithoutSocialInfoState)]); // TODO: Calculate once

  const socialInfoItems = currentSocialHistoryCategory?.socialInfoItems as SocialInfoItem[];
  // const formFieldData = socialInfoItems;
  const hasSocialInfo = !isEmpty(socialInfoItems);

  function handleAddSocialHistory() {
    let newSocialHistory: Array<Partial<SocialHistory>> = [];

    if (hasSocialInfo) {
      // Populate remove non-visible form field and get social history details text
      newSocialHistory = Object.values(formState).map((sh) => {
        const hasStructuredData = !isEmpty(sh.encounterSocialHistoryStructuredDataList);

        let details = sh.details; // Retain original details incase this is an item with no structured data

        if (hasStructuredData) {
          // avoid error where 'encounterSocialHistoryStructuredDataList' is read-only
          sh = {
            ...sh,
            encounterSocialHistoryStructuredDataList: removeNonVisibleStructuredDataFormValues(sh),
          };
          // Refresh details for items with structured data
          details = computeDetailsTextForSocialHistoryItem(details, sh, socialInfoItems);
        }

        // Populate details for items where "option" was selected
        if (sh?.option && sh?.observationId) {
          details = `${getSocialInfoNameById({
            socialInfoID: sh.observationId,
            socialInfoItems,
          })}: ${sh.option}`;
        }

        return {
          ...sh,
          details,
        };
      }) as SocialHistory[];
    } else {
      if (socialHistoryWithoutSocialInfoState) {
        newSocialHistory = [socialHistoryWithoutSocialInfoState];
      }
    }

    dispatch(uiActions.addSocialHistoryList(newSocialHistory));
    onCloseModal();
  }

  const infoItem = socialInfoItems.find((item) => item.socialInfoID === socialInfoId);

  return (
    <>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        {hasSocialInfo ? (
          <VStack spacing={'md'} alignItems='flex-start'>
            {(infoItem ? [infoItem] : socialInfoItems).map((socialInfoItem) => {
              const { structuredDataItems, options, socialInfoID: infoId } = socialInfoItem;
              const socialInfoID = infoId || -1;
              const hasStructuredData = !isEmpty(structuredDataItems);
              let renderDataItems;

              function getSocialHistoryBySocialInfoId() {
                let newFormStateItem: FormStateItem;

                const existingFormStateItem = formState[socialInfoID];
                if (existingFormStateItem) {
                  newFormStateItem = cloneDeep(existingFormStateItem);
                } else {
                  // new social history item
                  newFormStateItem = {
                    categoryId: currentCategoryId,
                    observationId: socialInfoID,
                    encounterSocialHistoryStructuredDataList: [],
                    details: '',
                    option: '',
                  };
                }
                return newFormStateItem;
              }

              function onChangeSocialHistory(
                socialHistoryUpdate: FormStateItem, // Partial<SocialHistory>
              ) {
                setFormState((state) => {
                  const newSocialHistory = cloneDeep(state);
                  newSocialHistory[socialInfoID] = socialHistoryUpdate;

                  autoUpdatePackYears(newSocialHistory);
                  autoUpdatePoints(newSocialHistory, gender);

                  // Reset visible fields
                  visibleFormFields = {};

                  return newSocialHistory;
                });
              }

              function onStructuredDataChange({
                value,
                structuredDataItem,
              }: {
                value?: string;
                structuredDataItem: StructuredDataItem;
              }) {
                const { shStructuredDataID } = structuredDataItem;
                // Update option
                const sh = getSocialHistoryBySocialInfoId();
                const newStruturedDataItem = {
                  value,
                  socialHistoryStructuredDataDetailId: shStructuredDataID,
                };

                const index = sh.encounterSocialHistoryStructuredDataList?.findIndex((item) => {
                  return item?.socialHistoryStructuredDataDetailId === shStructuredDataID;
                });

                if (isNumber(index) && index > -1) {
                  sh.encounterSocialHistoryStructuredDataList?.splice(
                    index,
                    1,
                    newStruturedDataItem,
                  );
                } else {
                  sh.encounterSocialHistoryStructuredDataList?.push(newStruturedDataItem);
                }

                onChangeSocialHistory(sh);
              }

              // Structured Data
              if (hasStructuredData) {
                const formattedStructuredData = formatStructuredDataItems(
                  structuredDataItems || [],
                );
                renderDataItems = (
                  <Tree
                    treeData={formattedStructuredData}
                    onStructuredDataChange={onStructuredDataChange}
                    currentStructedDataItems={
                      formState?.[socialInfoID]?.encounterSocialHistoryStructuredDataList
                    }
                  />
                );
              }
              // TODO: This part needs verification from product team
              else if (options) {
                // Handle 'yes,no', 'none,'
                let optionsArr = options?.split(',');

                // Handle 'weeks/months/years' scenario
                if (options.includes('/')) {
                  optionsArr = options?.split('/');
                }

                // Handle ['none', ''] by removing empty indexes
                optionsArr = optionsArr.filter((item) => {
                  return !!item;
                });

                const sh = getSocialHistoryBySocialInfoId();
                const userInputValue = sh?.option;

                renderDataItems = (
                  <RadioGroup
                    colorScheme={'brand'}
                    // handle option change
                    onChange={(value) => {
                      const newSh = cloneDeep(sh);
                      newSh.option = value;

                      onChangeSocialHistory(newSh);
                    }}
                    value={userInputValue === null ? undefined : userInputValue}>
                    <VStack alignItems={'flex-start'}>
                      {optionsArr?.map((o) => {
                        return (
                          <Radio key={o} value={o}>
                            {o}
                          </Radio>
                        );
                      })}
                    </VStack>
                  </RadioGroup>
                );
              } else {
                // Non-structured Data / Notes
                const sh = getSocialHistoryBySocialInfoId();
                const userInputValue = sh?.details ?? undefined;

                renderDataItems = (
                  <Textarea
                    value={userInputValue}
                    onChange={(e) => {
                      const newSh = cloneDeep(sh);
                      newSh.details = e.target.value;
                      onChangeSocialHistory(newSh);
                    }}
                  />
                );
              }

              return (
                <VStack
                  w='100%'
                  key={socialInfoItem.socialInfoID}
                  alignItems='flex-start'
                  spacing='sm'>
                  <Text fontSize='lg' fontWeight='bold'>
                    {socialInfoItem.name}
                  </Text>
                  <VStack w='100%' alignItems='flex-start' spacing='md'>
                    {renderDataItems}
                  </VStack>
                </VStack>
              );
            })}
          </VStack>
        ) : (
          // The form has no social info items. We just collect info with a text form (TODO: confirm with business that this is correct)
          <Textarea
            value={socialHistoryWithoutSocialInfoState.details || ''}
            onChange={(e) => {
              setSocialHistoryWithoutSocialInfoState((state) => {
                const newState = { ...state };
                newState.details = e.target.value;
                return newState;
              });
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant='ghost' onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant={'solid'}
          onClick={handleAddSocialHistory}
          // isLoading={isLoadingUpdateSocialHistory}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
}

function computeDetailsTextForSocialHistoryItem(
  details: g.InputMaybe<string> | undefined,
  sh: FormStateItem,
  socialInfoItems: SocialInfoItem[],
) {
  details = '';

  // Populate details field for items with structured data
  sh.encounterSocialHistoryStructuredDataList?.forEach((x) => {
    let structuredDataName = '';

    if (x?.socialHistoryStructuredDataDetailId) {
      structuredDataName = getStructuredDataNameById({
        structuredDataID: x?.socialHistoryStructuredDataDetailId,
        socialInfoItems,
      });
    }

    if (structuredDataName) {
      const questionAndAnswer = `${structuredDataName}: ${x?.value}`;
      details =
        details && structuredDataName ? `${details}, ${questionAndAnswer}` : questionAndAnswer;
    }
  });
  return details;
}

function removeNonVisibleItemsFromFormState(formState: FormState) {
  let newFormState: FormState = cloneDeep(formState);

  newFormState = Object.values(formState).reduce((acc, sh) => {
    const hasStructuredData = !isEmpty(sh.encounterSocialHistoryStructuredDataList);

    if (hasStructuredData && sh.observationId) {
      const newSh = {
        ...sh,
        encounterSocialHistoryStructuredDataList: removeNonVisibleStructuredDataFormValues(sh),
      };

      acc[sh.observationId] = newSh;
    }

    return acc;
  }, newFormState);

  return newFormState;
}

/**
 * Structured data can be hidden/exposed depending on if the parent is "Yes"/"No".
 * For all children fields that have been answered previously, if they are currently not
 * displayed in the UI, we want to remove their values from the formState
 */
function removeNonVisibleStructuredDataFormValues(sh: FormStateItem) {
  const visibleFormFieldsList = Object.values(visibleFormFields);
  return sh.encounterSocialHistoryStructuredDataList?.filter((sd) => {
    return visibleFormFieldsList.some(
      (visibleField) => visibleField.shStructuredDataID === sd?.socialHistoryStructuredDataDetailId,
    );
  });
}

function getStructuredDataNameById({
  structuredDataID,
  socialInfoItems,
}: {
  structuredDataID: number;
  socialInfoItems: SocialInfoItem[];
}): string {
  let structuredDataName = '';
  socialInfoItems.some((socialInfoItem) => {
    const foundItem = socialInfoItem?.structuredDataItems?.find(
      (sd) => sd.shStructuredDataID === structuredDataID,
    );

    if (foundItem?.name) {
      structuredDataName = foundItem?.name;
    }

    return !!structuredDataName;
  });

  return structuredDataName;
}

function getSocialInfoNameById({
  socialInfoID,
  socialInfoItems,
}: {
  socialInfoID: number;
  socialInfoItems: SocialInfoItem[];
}): string {
  let structuredDataName = '';
  socialInfoItems.some((socialInfoItem) => {
    const foundItem = socialInfoItem.socialInfoID === socialInfoID;

    if (foundItem) {
      structuredDataName = socialInfoItem.name || '';
    }

    return !!structuredDataName;
  });

  return structuredDataName;
}

function getUserInputValue(
  id: g.SocialHistoryOptionItem['shStructuredDataID'], // StructuredDataItem["shStructuredDataID"],
  encounterSocialHistoryStructuredDataList?: g.UpdateSocialHistoryItem['encounterSocialHistoryStructuredDataList'],
): g.SocialHistoryStructuredData['value'] {
  if (id) {
    const userSelectedSD = encounterSocialHistoryStructuredDataList?.find(
      (s) => s?.socialHistoryStructuredDataDetailId === id,
    );
    const userInputValue = userSelectedSD?.value;
    return userInputValue || '';
  }
  return '';
}

// COMPONENTS
// Recursive component: https://betterprogramming.pub/recursive-rendering-with-react-components-10fa07c45456
function Tree({
  treeData,
  parentId = 0, // TODO: How to guarantee this is a good starting point? If it changes, no data may be rendered.
  level = 1,
  currentStructedDataItems,
  onStructuredDataChange,
}: {
  treeData: StructuredDataItem[];
  parentId?: StructuredDataItem['parentSHSocialInfoID'];
  level?: number;
  currentStructedDataItems?: g.UpdateSocialHistoryItem['encounterSocialHistoryStructuredDataList']; // g.SocialHistoryStructuredData | null;
  onStructuredDataChange: (param: {
    value?: string | undefined; // structuredDataOptions[].name
    structuredDataItem: StructuredDataItem;
  }) => void;
}) {
  const items = treeData.filter((item) => item.parentSHSocialInfoID === parentId);
  if (items.length === 0) return null;

  return (
    <>
      {items.map((item) => {
        const id = item?.shStructuredDataID || -1;
        const userInputValue = getUserInputValue(id, currentStructedDataItems) ?? undefined;
        const showChildren = userInputValue && userInputValue !== 'No'; // TODO: Confirm value used for parents with Boolean types. Saw "No" in a payload for social history, so used "Yes", "No"

        // Set visible field
        visibleFormFields[id] = item;

        return (
          <Box key={id} mt='sm'>
            <StructuredDataItem
              {...{
                userInputValue,
                structuredDataItem: item,
                onStructuredDataChange,
              }}
            />
            {showChildren && (
              <Box pl={`${level * 24}px`}>
                <Tree
                  treeData={treeData}
                  parentId={item.shStructuredDataID}
                  {...{
                    level: level + 1,
                    currentStructedDataItems,
                    onStructuredDataChange,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
}

function StructuredDataItem({
  structuredDataItem,
  userInputValue,
  onStructuredDataChange,
}: {
  structuredDataItem: StructuredDataItem;
  userInputValue?: string;
  onStructuredDataChange: (param: {
    value?: string | undefined; // structuredDataOptions[].name
    structuredDataItem: StructuredDataItem;
  }) => void;
}) {
  const { structuredDataType, shStructuredDataID } = structuredDataItem;
  let renderStructuredData;

  /**
   * Some inputs are supposed to be handled differently. For example,
   * "Pack Years" is a Numeric input which should be disabled/auto-populated
   */
  const isPackYearsInput = shStructuredDataID === 4802;
  const isPointsInput = shStructuredDataID === 2929;
  const disableNumericInput = isPackYearsInput || isPointsInput;

  if (structuredDataType === 'Structured Text') {
    renderStructuredData = (
      <RadioGroup
        colorScheme={'brand'}
        onChange={(e) => {
          onStructuredDataChange({
            value: e,
            structuredDataItem,
          });
        }}
        value={userInputValue}>
        <VStack alignItems={'flex-start'}>
          {structuredDataItem?.structuredDataOptions?.map((structuredDataOption, idx) => {
            const id = structuredDataOption.name;
            // structuredDataOption.optionId.toString();
            return (
              <Radio key={id} value={id || `social_history_${idx}`}>
                {structuredDataOption.name}{' '}
              </Radio>
            );
          })}
        </VStack>
      </RadioGroup>
    );
  } else if (structuredDataType === 'Numeric') {
    /**
     * If the input is "Pack years", we need to compute the value using this
     * "How many packs per day do you/ did you smoke?" * "How many years have you smoked"
     */
    renderStructuredData = (
      <Input
        type='number'
        min='0'
        value={userInputValue}
        isDisabled={disableNumericInput}
        onChange={(e) => {
          const value = e.target.value;

          onStructuredDataChange({
            value,
            structuredDataItem,
          });
        }}
      />
    );
  } else if (structuredDataType === 'Boolean') {
    // const hasOptions = structuredDataType.
    renderStructuredData = (
      <RadioGroup
        colorScheme={'brand'}
        value={userInputValue}
        onChange={(value) => {
          onStructuredDataChange({
            value,
            structuredDataItem,
          });
        }}>
        <VStack alignItems={'flex-start'}>
          <Radio value={'No'}>No</Radio>
          <Radio value={'Yes'}>Yes</Radio>
        </VStack>
      </RadioGroup>
    );
  } else if (structuredDataType === 'Date') {
    const parsedDate = DateTime.fromISO(userInputValue || '');
    if (userInputValue && !parsedDate.isValid) {
      renderStructuredData = (
        <Input
          value={userInputValue}
          isDisabled={disableNumericInput}
          onChange={(e) => {
            const value = e.target.value;
            onStructuredDataChange({
              value,
              structuredDataItem,
            });
          }}
        />
      );
    } else {
      renderStructuredData = (
        <DatePicker
          // TODO: Can we guarantee default date from server is date? Otherwise, use input field
          value={userInputValue ? new Date(userInputValue) : null}
          onChange={(date) => {
            const value = date?.toISOString();
            onStructuredDataChange({
              value,
              structuredDataItem,
            });
          }}
        />
      );
    }
  }

  return (
    <VStack alignItems='flex-start' spacing='sm'>
      {/* <Text>
        {structuredDataItem.name} -- {structuredDataItem.shStructuredDataID}{" "}
        (Parent {structuredDataItem.parentSHSocialInfoID})
      </Text> */}
      <Text>{structuredDataItem.name}</Text>
      {renderStructuredData}
    </VStack>
  );
  // return <>{renderDataItems}</>;
}

// UTIL
function formatStructuredDataItems(structuredDataItems: StructuredDataItem[]) {
  // TODO: Re-validate this removal.
  //  Remove duplicate results
  const structuredDataMap = structuredDataItems.reduce<Record<number, StructuredDataItem>>(
    (acc, item) => {
      const id = item.shStructuredDataID || -1;
      acc[id] = item;
      return acc;
    },
    {},
  );

  const filteredStructuredData = Object.values(structuredDataMap);

  return filteredStructuredData.sort((a, b) => {
    if (!isNil(a.displayIndex) && !isNil(b.displayIndex)) {
      if (a.displayIndex > b.displayIndex) {
        return 1;
      }

      if (a.displayIndex < b.displayIndex) {
        return -1;
      }
    }
    return 0;
  });
}

function autoUpdatePackYears(newSocialHistory: FormState) {
  /**
   * Some fields have to be auto-populated to fit business logic.
   * If the input is "Pack years", we need to compute the value using this formula:
   * "How many packs per day do you/ did you smoke?" * "How many years have you smoked"
   *
   * We check for the IDs of both input values above, and perform the calculation for Pack Years input.
   */
  // shStructuredDataID: 4800,
  // name: "How many years have you smoked?"

  // shStructuredDataID: 4799,
  // name: "How many packs per day do you/ did you smoke?"

  const packYearsSH = newSocialHistory[SOCIAL_INFO_ID_FOR_PACK_YEARS];

  if (packYearsSH) {
    const packYearsSDList = packYearsSH.encounterSocialHistoryStructuredDataList;

    const howManyYearsHaveYouSmokedUserInput = packYearsSDList?.find(
      (x) => x?.socialHistoryStructuredDataDetailId === 4800,
    );
    const howManyPacksPerDayUserInput = packYearsSDList?.find(
      (x) => x?.socialHistoryStructuredDataDetailId === 4799,
    );
    const userHasAnsweredQuestionsToComputePackYears =
      !(howManyYearsHaveYouSmokedUserInput == null) && !(howManyPacksPerDayUserInput == null);

    if (userHasAnsweredQuestionsToComputePackYears) {
      const howManyPacksPerDayUserInputValue = howManyPacksPerDayUserInput.value;
      const howManyYearsHaveYouSmokedUserInputValue = howManyYearsHaveYouSmokedUserInput.value;

      if (howManyPacksPerDayUserInputValue && howManyYearsHaveYouSmokedUserInputValue) {
        const PACKS_PER_DAY_VALUES: Record<string, number> = {
          '1/2 pack': 0.5,
          '1 pack': 1,
          '2 packs': 2,
          '>2 packs': 3,
        };

        // TODO: handle case where packsPerDay is undefined due to bad input from server
        const packsPerDay: number = PACKS_PER_DAY_VALUES[howManyPacksPerDayUserInputValue];

        const packYears = packsPerDay * Number(howManyYearsHaveYouSmokedUserInputValue);
        updatePackYearsValue(packYears.toString());
      } else {
        // There is an undefined in either of these howManyPacksPerDayUserInputValue OR howManyYearsHaveYouSmokedUserInputValue, hence, reset Pack Years value
        updatePackYearsValue('0');
      }
    }
  }

  function updatePackYearsValue(newPackYears: string) {
    const packYearsStructuredDataId = 4802;
    const newSocialHistorySDList = packYearsSH.encounterSocialHistoryStructuredDataList ?? [];

    // Upsert the new pack years value
    const index = newSocialHistorySDList.findIndex(
      (x) => x?.socialHistoryStructuredDataDetailId === packYearsStructuredDataId,
    );

    const packYearsUpdate = newSocialHistorySDList[index];
    const newPackYearsValue = newPackYears.toString();

    if (index !== undefined && index > -1 && packYearsUpdate != null) {
      // Update existing pack years entry
      packYearsUpdate.value = newPackYearsValue;
    } else {
      // Create new pack years entry
      newSocialHistorySDList.push({
        notes: '',
        socialHistoryStructuredDataDetailId: packYearsStructuredDataId,
        value: newPackYearsValue,
      });
    }
  }
}

function autoUpdatePoints(formState: FormState, gender: Gender) {
  /**
   * Some fields have to be auto-populated to fit business logic.
   * If the input is "Points", we need to compute the value using this formula:
   * Add up all the points that are selected as options by the user.
   *
   */
  const pointsSH = formState[SOCIAL_INFO_ID_FOR_POINTS];

  if (pointsSH) {
    const newSocialHistorySDList = pointsSH.encounterSocialHistoryStructuredDataList ?? [];
    const pointsSDList = pointsSH.encounterSocialHistoryStructuredDataList;

    const totalPoints = STRUCTURED_DATA_IDS_FOR_POINTS.reduce((acc, itemId) => {
      const userInput = pointsSDList?.find(
        (x) => x?.socialHistoryStructuredDataDetailId === itemId,
      );
      const userInputValue = userInput?.value;
      if (userInputValue) {
        const numberVal = POINTS_VALUES[userInputValue] ?? 0;
        acc += numberVal;
      }
      return acc;
    }, 0);

    pointsSH.encounterSocialHistoryStructuredDataList = updatePointsValue(
      totalPoints.toString(),
      newSocialHistorySDList,
      gender,
    );
  }
}

function getInterpretation(value: number, gender: Gender): string {
  if (gender === Gender.Male) {
    return value > 3 ? 'Positive' : 'Negative';
  } else {
    return value > 2 ? 'Positive' : 'Negative';
  }
}

const POINTS_STRUCTURED_DATA_ID = 2929;
const INTERPRETATION_STRUCTURED_DATA_ID = 2930;
function updatePointsValue(
  newInputValue: string,
  socialHistorySDList: FormStateItem['encounterSocialHistoryStructuredDataList'],
  gender: Gender,
) {
  const newSocialHistorySDList = cloneDeep(socialHistorySDList);

  if (newSocialHistorySDList) {
    // Find indices for upsert
    const pointsIndex = newSocialHistorySDList.findIndex(
      (x) => x?.socialHistoryStructuredDataDetailId === POINTS_STRUCTURED_DATA_ID,
    );
    const interpretationIndex = newSocialHistorySDList.findIndex(
      (x) => x?.socialHistoryStructuredDataDetailId === INTERPRETATION_STRUCTURED_DATA_ID,
    );

    const pointsUpdate = newSocialHistorySDList[pointsIndex];
    const pointsValue = newInputValue.toString();
    const interpretationUpdate = newSocialHistorySDList[interpretationIndex];
    const interpretationValue = getInterpretation(parseInt(pointsValue), gender);

    // Upsert points
    if (pointsIndex > -1 && !isNil(pointsIndex)) {
      // Update value entry
      pointsUpdate.value = pointsValue;
    } else {
      // Create new value entry
      newSocialHistorySDList.push({
        notes: '',
        socialHistoryStructuredDataDetailId: POINTS_STRUCTURED_DATA_ID,
        value: pointsValue,
      });
    }

    // Upsert interpretation
    if (interpretationIndex > -1 && !isNil(interpretationIndex)) {
      interpretationUpdate.value = interpretationValue;
    } else {
      newSocialHistorySDList.push({
        notes: '',
        socialHistoryStructuredDataDetailId: INTERPRETATION_STRUCTURED_DATA_ID,
        value: interpretationValue,
      });
    }
  }

  return newSocialHistorySDList;
}
