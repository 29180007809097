import { Box, Text, VStack } from '../../index';
import { AddItemIcon } from '../../svgs/AddItemIcon/AddItemIcon';
import { Center } from '@chakra-ui/react';

export const AddItemEmptyStatePlaceholderLeft = ({ text }: { text: string }) => {
  return (
    <Center>
      <VStack mt='15%' justifyContent='center' ml='-10%'>
        <AddItemIcon />
        <Box width='400px' ml='90px' mt='lg'>
          <Text
            fontSize='30px'
            lineHeight='35px'
            textAlign='center'
            color='secondary'
            fontWeight='600'>
            {text}
          </Text>
        </Box>
      </VStack>
    </Center>
  );
};
