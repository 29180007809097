import { Box, Button, ButtonGroup, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { EncounterHeader } from '../components';
import { ComboBox, DatePicker } from '../../../components';
import { useMemo, useState } from 'react';
import { type ComboBoxOption } from '../../../types';
import {
  useGetCumulativeReportQuery,
  type GetCumulativeReportQuery,
} from '../../../__generated__/graphql';
import { OrderableItemsContainer } from './Components/OrderableItemsContainer';
import { useParams } from 'react-router-dom';

const lookupDateTypeOptions: ComboBoxOption[] = [
  {
    label: 'Collection Date',
    value: 'collectedDate',
  },
  {
    label: 'Order Date',
    value: 'orderDate',
  },
  {
    label: 'Results Date',
    value: 'resultDate',
  },
];

interface InitialState {
  maxDate?: string;
  minDate?: string;
  lookupDateType: 'collectedDate' | 'orderDate' | 'resultDate';
  activeLab?: ComboBoxOption;
  categories: ComboBoxOption[];
}

const initialState: InitialState = {
  maxDate: undefined,
  minDate: undefined,
  lookupDateType: 'collectedDate',
  activeLab: undefined,
  categories: [],
};

function filterData(
  data: GetCumulativeReportQuery,
  filter: InitialState,
): GetCumulativeReportQuery {
  const filteredData = data.getCumulativeReport.filter((orderableItem) => {
    if (filter.activeLab && filter.activeLab.label !== orderableItem.orderableItemName)
      return false;
    if (filter.minDate && new Date(filter.minDate) > new Date(orderableItem[filter.lookupDateType]))
      return false;
    if (filter.maxDate && new Date(filter.maxDate) < new Date(orderableItem[filter.lookupDateType]))
      return false;
    if (
      filter.categories.length &&
      (!orderableItem.categoryID ||
        !filter.categories
          .map((category) => parseInt(category.value))
          .includes(orderableItem.categoryID))
    ) {
      return false;
    }

    return true;
  });

  return {
    ...data,
    getCumulativeReport: filteredData,
  };
}

export function CumulativeReportScreen() {
  const [filter, setFilter] = useState(initialState);
  const { patientId } = useParams();

  const { data, loading } = useGetCumulativeReportQuery({
    variables: {
      input: {
        patientId: Number(patientId)!,
        dateType: 'collection',
      },
    },
    skip: !patientId,
  });

  const [localData, setLocalData] = useState(data);

  // These must change as filter changes to reflect possible options
  const [categories, labNames] = useMemo(() => {
    if (!loading && data) {
      setLocalData(filterData(data, filter));

      const categoryList: ComboBoxOption[] = [];
      const labNameList: string[] = [];

      // Grab all labNames and categories that exist on queried orderable items
      data.getCumulativeReport.forEach((orderableItem) => {
        if (orderableItem.orderableItemName) {
          labNameList.push(orderableItem.orderableItemName);
        }

        if (orderableItem.categoryID && orderableItem.categoryName) {
          categoryList.push({
            value: orderableItem.categoryID.toString(),
            label: orderableItem.categoryName,
          });
        }
      });

      // Filter out duplicates
      const categoryIds: string[] = [];

      return [
        categoryList.filter((category) => {
          if (!categoryIds.includes(category.value)) {
            categoryIds.push(category.value);
            return true;
          } else return false;
        }),
        Array.from(new Set(labNameList)).map((labName, index) => ({
          label: labName,
          value: index.toString(),
        })),
      ];
    }
    return [[], []];
  }, [data, loading, filter]);

  return (
    <Box padding={5} height='100vh' overflow='scroll'>
      <EncounterHeader patientId={!patientId ? undefined : Number(patientId)} />

      <Box margin='24px 0 12px 0'>
        <Text fontSize='lg' as='b'>
          Cumulative Report
        </Text>
      </Box>

      <Flex alignItems='center'>
        <Flex flexDir='column' width='85%'>
          <SimpleGrid columns={3} spacing={4} flex={1} marginBottom={4}>
            <DatePicker
              value={filter.minDate ? new Date(filter.minDate) : undefined}
              onChange={(minDate) => {
                if (!minDate || new Date(minDate).toLocaleDateString() === 'Invalid Date') {
                  setFilter({
                    ...filter,
                    minDate: undefined,
                  });
                  return;
                }

                setFilter({
                  ...filter,
                  minDate: minDate.toUTCString(),
                });
              }}
              placeholder='From'
            />

            <DatePicker
              value={filter.maxDate ? new Date(filter.maxDate) : undefined}
              onChange={(maxDate) => {
                if (!maxDate || new Date(maxDate).toLocaleDateString() === 'Invalid Date') {
                  setFilter({
                    ...filter,
                    maxDate: undefined,
                  });
                  return;
                }

                setFilter({
                  ...filter,
                  maxDate: maxDate.toUTCString(),
                });
              }}
              placeholder='To'
            />

            <ComboBox
              isLoading={false}
              placeholder='Lookup Date'
              defaultValue={{
                label: 'Collection Date',
                value: 'collectedDate',
              }}
              options={lookupDateTypeOptions}
              onSelection={(selection) => {
                setFilter({
                  ...filter,
                  lookupDateType: selection!.value as InitialState['lookupDateType'],
                });
              }}
              required
            />
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} flex={1}>
            <ComboBox
              // Key is only way to force component to re-render on reset click
              key={`chakra-select-categories-${filter.categories.length}`}
              defaultValue={filter.categories}
              isMulti
              isLoading={loading}
              placeholder='Categories'
              options={categories}
              onSelection={(selection) => {
                setFilter({
                  ...filter,
                  categories: selection ?? [],
                });
              }}
            />

            <ComboBox
              key={`chakra-select-lab-name-${filter.activeLab?.value}`}
              defaultValue={filter.activeLab}
              isLoading={loading}
              placeholder='Lab Name'
              options={labNames}
              onSelection={(selection) => {
                setFilter({
                  ...filter,
                  activeLab: selection,
                });
              }}
            />
          </SimpleGrid>
        </Flex>

        <ButtonGroup padding='0 24px' height='100%' alignItems='center'>
          <Button
            variant='outline'
            onClick={() => {
              setFilter(initialState);
            }}>
            Reset
          </Button>
        </ButtonGroup>
      </Flex>

      {loading && (
        <>
          {Array.from(Array(12)).map((_, i) => (
            <Skeleton mb={4} bg='gray.200' height={8} key={i} width='100%' />
          ))}
        </>
      )}

      <OrderableItemsContainer {...{ data: localData }} />
    </Box>
  );
}
