import { Box, DrawerCloseButton, DrawerContent, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SingleLab } from './SingleLab';
import { AllLabs } from './AllLabs';

export function Labs() {
  const [selectedLab, setSelectedLab] = useState<UILabItem | null>(null);

  return (
    <DrawerContent>
      <DrawerCloseButton sx={{ '@media print': { display: 'none' } }} />
      <Box height='100%' width='100%'>
        <Flex>
          <Box
            minHeight='100vh'
            width='20%'
            backgroundColor='#ED4924'
            sx={{ '@media print': { display: 'none' } }}
          />
          <Box width='80%' marginTop='50px' sx={{ '@media print': { width: '100%' } }}>
            <Flex justifyContent='center'>
              <Box width='95%'>
                {selectedLab ? (
                  <SingleLab
                    orderableReportID={selectedLab.orderableReportID}
                    setSelectedLab={setSelectedLab}
                  />
                ) : (
                  <AllLabs setSelectedLab={setSelectedLab} />
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </DrawerContent>
  );
}
