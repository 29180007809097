import { createSearchParams, useNavigate } from 'react-router-dom';
import { type NavigateOptions } from 'react-router/lib/hooks';

export function useNavigation() {
  const nav = useNavigate();

  const back = () => {
    nav(-1);
  };
  const toEncountersPage = () => {
    nav('/');
  };
  const toReviewOrderPage = () => {
    nav('/review-order');
  };
  const toConfirmOrderPage = () => {
    nav('/confirm-order');
  };
  const toPatientViewPage = () => {
    nav('/patient-view');
  };
  const toFeedbackPage = () => {
    nav('/feedback');
  };
  const toPastOrderDetailPage = () => {
    nav('/past-order');
  };
  const toPastEncounterDetailPage = (encounterId: number) => {
    nav(`/past-encounter/${encounterId}`);
  };
  const toOrderFlowPage = (path?: OrderFlowSteps, options?: NavigateOptions) => {
    nav(`/order-flow${path ? `/${path}` : ''}`, options);
  };

  const toEncounterTemplatePage = (query?: Record<string, string>) => {
    nav({ pathname: `/encounter-template`, search: createSearchParams(query).toString() });
  };

  const toProviderWorkflowPage = () => {
    nav('/providers');
  };

  return {
    navigate: (args: any) => {},
    back,
    toFeedbackPage,
    toReviewOrderPage,
    toConfirmOrderPage,
    toPatientViewPage,
    toEncountersPage,
    toPastOrderDetailPage,
    toPastEncounterDetailPage,
    toOrderFlowPage,
    toProviderWorkflowPage,
    toEncounterTemplatePage,
  };
}
