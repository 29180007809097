import { ComboBox } from '../../../../../components';
import {
  encounterFilterActions,
  getEncounterFilterState,
  useAppSelector,
} from '../../../../../state-management';
import { useDispatch } from 'react-redux';
import { useGetLocationsQuery } from '../../../../../__generated__/graphql';

export function Facilities() {
  const { data, loading } = useGetLocationsQuery();
  const locations = data?.getLocations?.locations?.map((location) => ({
    label: `${location.locationName}`,
    value: `${location.locationID}`,
  }));

  const filterState = useAppSelector(getEncounterFilterState);
  const dispatch = useDispatch();

  return (
    <ComboBox
      isLoading={loading}
      placeholder='Facilities'
      options={locations}
      onSelection={(locations) => {
        const update = encounterFilterActions.onFilterStateChange({ locations });
        dispatch(update);
      }}
      defaultValue={filterState.locations}
      isMulti
    />
  );
}
